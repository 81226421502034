import classNames from 'classnames';

import { useDashboardStyleUpdate } from './hooks';
import styles from './styles.module.scss';

export type DashboardFrameProps = {
	url: string;
	customClasses?: string;
};

export const DashboardFrame = ({ url, customClasses }: DashboardFrameProps) => {
	useDashboardStyleUpdate(url);

	return (
		<div className={classNames(styles.FrameContainer, customClasses)}>
			<iframe src={url} width="100%" height="100%"></iframe>
		</div>
	);
};
