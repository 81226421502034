import { z } from 'zod';

import { EGuardrailCraftWizardValueType, EGuardrailValueType } from '../types';

import { isPercentageValueValid, transformValue } from './utils';

export const GUARDRAIL_NUMBER_VALUE_SCHEMA = z.object({
	value: z.number().or(z.string()).transform(transformValue),
	inclusive: z.boolean()
});

export const GUARDRAIL_DATASTREAM_VALUE_SCHEMA = z.object({
	value: z.string(),
	inclusive: z.boolean()
});

const GUARDRAIL_PERCENTAGE_VALUE_SCHEMA = z.object({
	value: z.number().or(z.string()).transform(transformValue).refine(isPercentageValueValid, {
		message: 'Value must be between 0 and 100'
	}),
	inclusive: z.boolean()
});

const GUARDRAIL_OPTIONAL_NUMBER_VALUE_SCHEMA = GUARDRAIL_NUMBER_VALUE_SCHEMA.extend({
	value: GUARDRAIL_NUMBER_VALUE_SCHEMA.shape.value.optional()
});

const GUARDRAIL_OPTIONAL_PERCENTAGE_VALUE_SCHEMA = GUARDRAIL_PERCENTAGE_VALUE_SCHEMA.extend({
	value: GUARDRAIL_PERCENTAGE_VALUE_SCHEMA.shape.value.optional()
});

const GUARDRAIL_VALUE_SCHEMA = z.object({
	number: GUARDRAIL_NUMBER_VALUE_SCHEMA.partial().optional(),
	datastream: GUARDRAIL_DATASTREAM_VALUE_SCHEMA.partial().optional(),
	percentage: GUARDRAIL_OPTIONAL_PERCENTAGE_VALUE_SCHEMA.partial().optional(),
	type: z.union([
		z.literal(EGuardrailValueType.Number),
		z.literal(EGuardrailValueType.Datastream),
		z.literal(EGuardrailValueType.Percentage)
	])
});

const GUARDRAIL_NUMBER_SCHEMA = GUARDRAIL_VALUE_SCHEMA.extend({
	number: GUARDRAIL_NUMBER_VALUE_SCHEMA,
	type: z.literal(EGuardrailValueType.Number)
});

const GUARDRAIL_DATASTREAM_SCHEMA = GUARDRAIL_VALUE_SCHEMA.extend({
	datastream: GUARDRAIL_DATASTREAM_VALUE_SCHEMA,
	type: z.literal(EGuardrailValueType.Datastream)
});

const GUARDRAIL_OPTIONAL_PERCENTAGE_SCHEMA = GUARDRAIL_VALUE_SCHEMA.extend({
	percentage: GUARDRAIL_OPTIONAL_PERCENTAGE_VALUE_SCHEMA,
	type: z.literal(EGuardrailValueType.Percentage)
});

const GUARDRAIL_OPTIONAL_NUMBER_SCHEMA = GUARDRAIL_NUMBER_SCHEMA.extend({
	number: GUARDRAIL_OPTIONAL_NUMBER_VALUE_SCHEMA
});

export const GUARDRAIL_MIN_MAX_SCHEMA = z.discriminatedUnion('type', [
	GUARDRAIL_NUMBER_SCHEMA,
	GUARDRAIL_DATASTREAM_SCHEMA
]);

export const GUARDRAIL_INCREASE_DECREASE_MIN_MAX_SCHEMA = z.discriminatedUnion('type', [
	GUARDRAIL_OPTIONAL_NUMBER_SCHEMA,
	GUARDRAIL_OPTIONAL_PERCENTAGE_SCHEMA
]);

export const GUARDRAIL_INCREASE_DECREASE_SCHEMA = z.object({
	min: GUARDRAIL_INCREASE_DECREASE_MIN_MAX_SCHEMA,
	max: GUARDRAIL_INCREASE_DECREASE_MIN_MAX_SCHEMA
});

export const NUMERIC_GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA = z.object({
	asset: z.string(),
	datastream: z.string(),
	control: z.boolean(),
	type: z.literal(EGuardrailCraftWizardValueType.Numeric),
	value: z
		.object({
			min: GUARDRAIL_MIN_MAX_SCHEMA,
			max: GUARDRAIL_MIN_MAX_SCHEMA,
			increase: GUARDRAIL_INCREASE_DECREASE_SCHEMA,
			decrease: GUARDRAIL_INCREASE_DECREASE_SCHEMA
		})
		.superRefine((data, context) => {
			if (
				data.min.type === EGuardrailValueType.Number &&
				data.max.type === EGuardrailValueType.Number
			) {
				const {
					min: {
						number: { value: minValue }
					},
					max: {
						number: { value: maxValue }
					}
				} = data;

				if (minValue !== undefined && maxValue !== undefined && maxValue <= minValue) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Value must be greater than mininum value (${minValue})`,
						fatal: true,
						path: ['max', 'number']
					});
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Value must be lesser than maximum value (${maxValue})`,
						fatal: true,
						path: ['min', 'number']
					});
				}
			}
		})
});

export const NON_NUMERIC_GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA = z.object({
	asset: z.string(),
	datastream: z.string(),
	control: z.boolean(),
	type: z.literal(EGuardrailCraftWizardValueType.NonNumeric)
});

export const GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA = z.union([
	NON_NUMERIC_GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA,
	NUMERIC_GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA
]);
