import { ETooltipPosition, KvTooltip } from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import { ReactNode } from 'react';

import { Skeleton } from '../Skeleton';

import styles from './styles.module.scss';

import { getItemValue } from './utils';

type InfoLabelListProps = {
	items?: Record<string, ReactNode | undefined>;
	customClasses?: ClassNamesProp;
};

type TooltipInfoLabelProps = {
	labelTitle: string;
	labelValue: ReactNode;
	tooltipValue?: string;
};

const TooltipInfoLabel = ({ labelTitle, labelValue, tooltipValue }: TooltipInfoLabelProps) => {
	return (
		<div className={styles.InfoLabel}>
			<KvTooltip position={ETooltipPosition.Bottom} text={labelTitle} truncate={true}>
				<div className={styles.Title}>{labelTitle}</div>
			</KvTooltip>
			<KvTooltip
				position={ETooltipPosition.Bottom}
				text={tooltipValue}
				truncate={tooltipValue === labelValue}>
				<div className={styles.Description}>{labelValue}</div>
			</KvTooltip>
		</div>
	);
};
export const InfoLabelListSkeleton = ({
	itemCount = 3,
	customClasses
}: {
	itemCount?: number;
	customClasses?: ClassNamesProp;
}) => {
	return (
		<div className={classNames(styles.DescriptionItemsContainer, customClasses)}>
			{Array(itemCount)
				.fill({})
				.map((_item, index) => (
					<div key={index} className={styles.Skeleton}>
						<Skeleton height={18} width={100} />
						<Skeleton height={18} width={70} />
					</div>
				))}
		</div>
	);
};

export const InfoLabelList = ({ items = {}, customClasses }: InfoLabelListProps) => {
	return (
		<div className={classNames(styles.DescriptionItemsContainer, customClasses)}>
			{Object.entries(items).map(([itemKey, value]) => {
				const { label, tooltip } = getItemValue(value);

				return (
					<TooltipInfoLabel
						key={itemKey}
						labelTitle={itemKey}
						labelValue={label}
						tooltipValue={tooltip}
					/>
				);
			})}
		</div>
	);
};
