export enum SchemaFieldType {
	String = 'string',
	Number = 'number',
	Integer = 'integer',
	Boolean = 'boolean'
}

export interface ICSVMap {
	path: string;
	title: string;
	type: SchemaFieldType;
	position?: number;
}
