import { FunctionComponent } from '@kelvininc/types';
import {
	Context,
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useState
} from 'react';

import { IBaseTData } from '../../../../../core/components/AgGridTable/types';
import { ITableSettings } from '../../types';

import { ITableResetContextValues } from './types';

const TableResetContext: Context<null | ITableResetContextValues> =
	createContext<null | ITableResetContextValues>(null);

export type TableResetProviderProps<TData extends IBaseTData> = {
	onResetSettings?: () => Promise<ITableSettings<TData>>;
};

export const TableResetProvider = <TData extends IBaseTData>({
	onResetSettings = () => Promise.resolve({}),
	children
}: PropsWithChildren<TableResetProviderProps<TData>>) => {
	const [timestamp, setTimestamp] = useState<number>(Date.now());
	const [isResetting, setResetting] = useState<boolean>(false);

	const updateTimestamp = useCallback(() => setTimestamp(Date.now()), []);

	const reset = useCallback(() => {
		setResetting(true);
		onResetSettings()
			.then(updateTimestamp)
			.finally(() => setResetting(false));
	}, [onResetSettings, updateTimestamp]);

	return (
		<TableResetContext.Provider
			key={timestamp}
			value={{ reset, timestamp: [timestamp, setTimestamp], isResetting }}>
			{children}
		</TableResetContext.Provider>
	);
};

export const useTableResetContext = (): ITableResetContextValues => {
	const context = useContext(TableResetContext);

	if (!context) {
		throw new Error('Missing table reset context');
	}

	return context;
};

export const withResetProvider = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>,
	providerProps: ITableResetContextValues
) => {
	return function TableResetProviderWrapper(componentProps: PropsWithChildren<ComponentProps>) {
		return (
			<TableResetProvider {...providerProps}>
				<Component {...componentProps} />
			</TableResetProvider>
		);
	};
};
