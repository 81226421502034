import { getInstanceSetting } from '@kelvininc/shared-ui';
import { IClosedLoopSettings } from '@kelvininc/types';
import { selector, selectorFamily } from 'recoil';

import {
	APPLICATION_HIDDEN_PARAMETERS_DEFAULT,
	APPLICATION_HIDDEN_PARAMETERS_KEY,
	APPLICATION_PARAMETER_COMMENT_REQUIRED_DEFAULT,
	APPLICATION_PARAMETER_COMMENT_REQUIRED_KEY,
	APPLICATION_PARAMETER_ORDER_DEFAULT,
	APPLICATION_PARAMETER_ORDER_KEY,
	ASSETS_LIST_ASSET_TYPE_INLINE_FILTERS_DEFAULT,
	ASSET_DETAIL_SETTINGS_SETTING_DEFAULT,
	ASSET_DETAIL_SETTINGS_SETTING_KEY,
	ASSET_TYPE_GROUPS_INSTANCE_SETTING_DEFAULT,
	ASSET_TYPE_GROUPS_INSTANCE_SETTING_KEY,
	CLOSED_LOOP_SETTINGS_INSTANCE_SETTING_DEFAULT,
	CLOSED_LOOP_SETTINGS_INSTANCE_SETTING_KEY,
	SMART_APPS_CATALOG_INSTANCE_SETTING_KEY,
	SMART_APPS_CATALOG_SETTING_DEFAULT,
	SUPPORT_ITEMS_MENU_INSTANCE_SETTING_KEY,
	SUPPORT_ITEMS_MENU_SETTING_DEFAULT
} from './config';

import {
	IAssetDetailSettings,
	IAssetTypeGroupSetting,
	IInlineFilterSetting,
	ISupportItemsSetting,
	IToggleFeatureSetting
} from './types';

import { getAssetsListAssetTypeInlineFiltersKey } from './utils';

export const assetTypeGroupsInstanceSettingSelector = selector<
	Record<string, IAssetTypeGroupSetting[]>
>({
	key: 'assetTypeGroupsInstanceSettingSelector',
	get: () =>
		getInstanceSetting(
			ASSET_TYPE_GROUPS_INSTANCE_SETTING_KEY,
			ASSET_TYPE_GROUPS_INSTANCE_SETTING_DEFAULT
		)
});

export const applicationParametersOrderInstanceSettingSelector = selector<Record<string, string[]>>(
	{
		key: 'applicationParametersOrderInstanceSettingSelector',
		get: () =>
			getInstanceSetting(APPLICATION_PARAMETER_ORDER_KEY, APPLICATION_PARAMETER_ORDER_DEFAULT)
	}
);

export const applicationHiddenParametersInstanceSettingSelector = selector<
	Record<string, string[]>
>({
	key: 'applicationHiddenParametersInstanceSettingSelector',
	get: () =>
		getInstanceSetting(APPLICATION_HIDDEN_PARAMETERS_KEY, APPLICATION_HIDDEN_PARAMETERS_DEFAULT)
});

export const closedLoopInstanceSettingSelector = selector<IClosedLoopSettings>({
	key: 'closedLoopInstanceSettingSelector',
	get: () =>
		getInstanceSetting(
			CLOSED_LOOP_SETTINGS_INSTANCE_SETTING_KEY,
			CLOSED_LOOP_SETTINGS_INSTANCE_SETTING_DEFAULT
		)
});

export const assetDetailSettingsInstanceSettingSelector = selector<IAssetDetailSettings>({
	key: 'assetDetailSettingsInstanceSettingSelector',
	get: () =>
		getInstanceSetting(ASSET_DETAIL_SETTINGS_SETTING_KEY, ASSET_DETAIL_SETTINGS_SETTING_DEFAULT)
});

export const assetsListAssetTypeInlineFiltersInstanceSettingSelectorFamily = selectorFamily<
	IInlineFilterSetting,
	string | undefined
>({
	key: 'assetsListAssetTypeInlineFiltersInstanceSettingSelectorFamily',
	get: (assetType) => () =>
		getInstanceSetting(
			getAssetsListAssetTypeInlineFiltersKey(assetType),
			ASSETS_LIST_ASSET_TYPE_INLINE_FILTERS_DEFAULT
		)
});

export const applicationParameterCommentRequiredInstanceSettingSelector =
	selector<IToggleFeatureSetting>({
		key: 'applicationParameterCommentRequiredSelector',
		get: () =>
			getInstanceSetting(
				APPLICATION_PARAMETER_COMMENT_REQUIRED_KEY,
				APPLICATION_PARAMETER_COMMENT_REQUIRED_DEFAULT
			)
	});

export const smartAppsCatalogInstanceSettingSelector = selector<IToggleFeatureSetting>({
	key: 'smartAppsCatalogInstanceSettingSelector',
	get: () =>
		getInstanceSetting(
			SMART_APPS_CATALOG_INSTANCE_SETTING_KEY,
			SMART_APPS_CATALOG_SETTING_DEFAULT
		)
});

export const supportItemsInstanceSettingSelector = selector<ISupportItemsSetting>({
	key: 'supportItemsInstanceSettingSelector',
	get: () =>
		getInstanceSetting(
			SUPPORT_ITEMS_MENU_INSTANCE_SETTING_KEY,
			SUPPORT_ITEMS_MENU_SETTING_DEFAULT
		)
});
