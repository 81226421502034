'use client';
import { KvLoader } from '@kelvininc/react-ui-components';
import classNames from 'classnames';

import { ValidationStatusIcon } from '../RequirementValidationList';

import styles from './styles.module.scss';

import { EValidationStep, IValidationItem } from '@/src/types';

type ValidationStateIconProps = {
	state: EValidationStep;
};

const ValidationStateIcon = ({ state }: ValidationStateIconProps) => {
	if ([EValidationStep.Pending, EValidationStep.Validating].includes(state)) {
		return (
			<div
				className={classNames(styles.ValidationState, {
					[styles.Pending]: state === EValidationStep.Pending
				})}>
				<KvLoader isLoading />
			</div>
		);
	}

	return <ValidationStatusIcon state={state} />;
};

type ApplicationTourValidationListProps = {
	finalRequirementsState: Record<string, IValidationItem[]>;
};

export const ApplicationTourValidationList = ({
	finalRequirementsState
}: ApplicationTourValidationListProps) => {
	return (
		<div className={styles.ValidationItemsContainer}>
			{Object.entries(finalRequirementsState).map(
				([validationItemKey, validationItems]) =>
					validationItems.filter((item) => !item.hidden).length > 0 && (
						<div className={styles.ValidationItems} key={validationItemKey}>
							{validationItems.map((item, index) => (
								<div
									key={`${validationItemKey}${index}`}
									className={styles.ItemWrapper}>
									<ValidationStateIcon state={item.state} />
									<div
										className={classNames(styles.Description, {
											[styles.DescriptionInactive]: true
										})}>
										<div className={styles.Requirement}>
											<div
												className={classNames(styles.Title, {
													[styles.TitleInactive]:
														item.state === EValidationStep.Pending
												})}>
												{item.itemKey}
											</div>
											<div
												className={classNames(styles.Description, {
													[styles.DescriptionInactive]:
														item.state === EValidationStep.Pending
												})}>
												{item.description}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					)
			)}
		</div>
	);
};
