import { isBooleanish, isNumberish } from '@kelvininc/tsutils';
import { RJSFSchema } from '@rjsf/utils';
import { get, isEmpty, toNumber, uniq } from 'lodash-es';

import { NAME_MAX_LENGTH_INPUT_CHARACTERS } from '@/src/config';
import { ICSVMap, SchemaFieldType } from '@/src/types';

export const buildCSVHeaderMapping = (schema: RJSFSchema): ICSVMap[] => {
	if (schema.type !== 'array') {
		return [];
	}
	const schemaProperties = get(schema, ['items', 'properties'], {});
	const flattenProperties = Object.keys(schemaProperties).reduce((acc, prop) => {
		acc = {
			...acc,
			...flattenSchema(prop, schemaProperties[prop])
		};
		return acc;
	}, {});
	return Object.keys(flattenProperties).map((path) => ({
		path,
		title: get(flattenProperties, [path, 'title'], path),
		type: get(flattenProperties, [path, 'type'], 'string')
	}));
};

const flattenSchema = (key: string, value: RJSFSchema, path = ''): Record<string, RJSFSchema> => {
	if (value.type !== 'object') {
		return {
			[`${path ? `${path}.` : ''}${key}`]: value
		};
	} else if (value.properties) {
		return Object.keys(value.properties).reduce(
			(acc, propKey) => {
				acc = {
					...acc,
					...flattenSchema(propKey, get(value, ['properties', propKey]), key)
				};
				return acc;
			},
			{} as Record<string, RJSFSchema>
		);
	}

	return {};
};

export const createCSVBlobFile = (csvMap: ICSVMap[], filePrefix: string, data: string[] = []) => {
	return {
		data: new Blob([[csvMap.map((map) => map.title).join(), ...data].join('\n')], {
			type: 'text/csv;charset=utf-8'
		}),
		fileName: `${filePrefix}.csv`
	};
};

export const removeEmptyTrailLinesFromCsvFile = (fileContent: string[]): string[] => {
	let index = fileContent.length - 1;

	while (isEmpty(fileContent[index]) && index >= 0) {
		fileContent.pop();
		index = index - 1;
	}

	return fileContent;
};

export const parseValueToFieldType = (
	value: string,
	fieldType: SchemaFieldType
): string | number | boolean | undefined => {
	if (isEmpty(value)) {
		return undefined;
	}

	switch (fieldType) {
		case SchemaFieldType.Boolean:
			return isBooleanish(value) ? value === 'true' : value;
		case SchemaFieldType.Number:
		case SchemaFieldType.Integer:
			return isNumberish(value) ? toNumber(value) : value;
		default:
			return value;
	}
};

export const isValidCSVFile = (csvData: string, csvMap: ICSVMap[]) => {
	const fileCSVLines = csvData.split(/\r?\n/);
	const fileHeaders = fileCSVLines[0].split(',').map((header) => header.trim());

	return validadeCSVHeaders(fileHeaders, csvMap);
};

export const validadeCSVHeaders = (headers: string[], csvMap: ICSVMap[]) => {
	return {
		isValidCSVHeader: isValidCSVHeader(headers, csvMap),
		isValidAdditionalPropertiesNames: isValidAdditionalPropertiesNames(headers, csvMap),
		noDuplicatedColumns: uniq(headers).length === headers.length
	};
};

export const isValidCSVHeader = (headers: string[], csvMap: ICSVMap[]): boolean => {
	const expectedHeaders = csvMap.map((columnHeader) => columnHeader.title);

	return expectedHeaders.every((headerTitle) => headers.includes(headerTitle));
};

export const isValidAdditionalPropertiesNames = (headers: string[], csvMap: ICSVMap[]): boolean => {
	const expectedHeaders = csvMap.map((columnHeader) => columnHeader.title);
	return headers.every((headerTitle) => {
		if (!expectedHeaders.includes(headerTitle)) {
			if (headerTitle.length > NAME_MAX_LENGTH_INPUT_CHARACTERS) {
				return false;
			}
		}
		return true;
	});
};

export const buildSchemaCsvTemplateFile = (
	schema: RJSFSchema,
	filePrefix = 'template',
	fileData?: string[]
): File => {
	const headerMap = buildCSVHeaderMapping(schema);
	const { data, fileName } = createCSVBlobFile(headerMap, filePrefix, fileData);
	return new File([data], fileName);
};
