'use client';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './styles.module.scss';

type ParametersCardProps = {
	title?: string;
	headerActions?: JSX.Element;
	customClass?: ClassNamesProp;
};

export const ParametersCard = ({
	title,
	headerActions,
	customClass,
	children
}: PropsWithChildren<ParametersCardProps>) => {
	return (
		<div className={classNames(styles.ParametersCardContainer, customClass)}>
			<div className={styles.CardHeader}>
				{title && <div className={styles.Title}>{title}</div>}
				{headerActions}
			</div>
			{children}
		</div>
	);
};
