import { buildUrl } from '@kelvininc/tsutils';
import { URLSearchParamsInit } from '@kelvininc/types';

import { ORCHESTRATION_ROUTE_BASE_PATH } from '../config';

const ROUTE_PATH = `${ORCHESTRATION_ROUTE_BASE_PATH}/clusters`;

export const buildClustersRoute = (query?: URLSearchParamsInit): string =>
	buildUrl(ROUTE_PATH, query);

export const buildClusterViewWorkloadsRoute = (
	clusterId: string,
	query?: URLSearchParamsInit
): string => buildUrl([ROUTE_PATH, clusterId, 'workloads'], query);

export const buildClusterViewServicesRoute = (
	clusterId: string,
	query?: URLSearchParamsInit
): string => buildUrl([ROUTE_PATH, clusterId, 'services'], query);

export const buildClusterViewNodesRoute = (
	clusterId: string,
	query?: URLSearchParamsInit
): string => buildUrl([ROUTE_PATH, clusterId, 'nodes'], query);

export const buildClusterViewConfigurationRoute = (
	clusterId: string,
	query?: URLSearchParamsInit
): string => buildUrl([ROUTE_PATH, clusterId, 'configuration'], query);

export const buildNodeViewWorkloadsRoute = (
	clusterId: string,
	nodeName: string,
	query?: URLSearchParamsInit
): string => buildUrl([ROUTE_PATH, clusterId, 'nodes', nodeName, 'workloads'], query);

export const buildNodeViewInfoRoute = (
	clusterId: string,
	nodeName: string,
	query?: URLSearchParamsInit
): string => buildUrl([ROUTE_PATH, clusterId, 'nodes', nodeName, 'info'], query);

// TODO: remove when app registry page is removed

const APP_REGISTRY_ROUTE_PATH = `${ORCHESTRATION_ROUTE_BASE_PATH}/app-registry`;

export const buildAppRegistryRoute = (query?: URLSearchParamsInit): string =>
	buildUrl(APP_REGISTRY_ROUTE_PATH, query);

export const buildAppRegistryWorkloadsRoute = (
	appRegistryId: string,
	query?: URLSearchParamsInit
): string => buildUrl([APP_REGISTRY_ROUTE_PATH, appRegistryId, 'workloads'], query);

export const buildAppRegistryDetailsRoute = (
	appRegistryId: string,
	query?: URLSearchParamsInit
): string => buildUrl([APP_REGISTRY_ROUTE_PATH, appRegistryId, 'details'], query);
