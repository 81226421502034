import {
	EActionButtonType,
	EComponentSize,
	EIconName,
	KvActionButtonIcon
} from '@kelvininc/react-ui-components';
import { Dropdown, useDropdown, withDropdownProvider } from '@kelvininc/shared-ui';

type AdvancedFilterFormOptionsDropdownProps = {
	onDelete: () => void;
	onDuplicate: () => void;
	isFormEmpty: boolean;
};

const Component = ({
	onDelete,
	onDuplicate,
	isFormEmpty
}: AdvancedFilterFormOptionsDropdownProps) => {
	const { toggle, isOpen } = useDropdown();

	const items = [
		{
			id: 'delete',
			text: 'Remove',
			leftIcon: EIconName.Delete,
			onClick: onDelete,
			disabled: isFormEmpty
		},
		{
			id: 'duplicate',
			text: 'Duplicate',
			leftIcon: EIconName.Add,
			onClick: onDuplicate
		}
	];

	return (
		<Dropdown items={items}>
			<KvActionButtonIcon
				type={EActionButtonType.Ghost}
				icon={EIconName.More}
				size={EComponentSize.Small}
				onClickButton={toggle}
				active={isOpen}
			/>
		</Dropdown>
	);
};

export const AdvancedFilterFormOptionsDropdown =
	withDropdownProvider<AdvancedFilterFormOptionsDropdownProps>(Component);
