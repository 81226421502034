import {
	WizardFormContext,
	closeModal,
	withRecoilRoot,
	withWizardBoundaries
} from '@kelvininc/shared-ui';
import { compose } from '@kelvininc/tsutils';

import { IMPORT_GUARDRAILS_WIZARD_STEPS } from './config';
import { useOnSubmitForm, withImportGuardrailsWizardFormProvider } from './hooks';
import { ImportGuardrailsWizardFormValues } from './types';
import { isImportStepValid } from './utils';

import { IConnectionFormValues } from '@/src/page-components/ConnectionsPage/ConnectionWizard/types';

export type ImportGuardrailsWizardProps = {
	submit: (form: ImportGuardrailsWizardFormValues) => Promise<void>;
	close?: () => void;
	connection?: IConnectionFormValues;
};

const Component = ({ submit, close = closeModal }: ImportGuardrailsWizardProps) => {
	const onSubmitForm = useOnSubmitForm(submit);

	return (
		<WizardFormContext
			completeBtnLabel="Import"
			steps={IMPORT_GUARDRAILS_WIZARD_STEPS}
			isStepValid={isImportStepValid}
			onCancelClick={close}
			onCompleteClick={onSubmitForm}
		/>
	);
};

export const ImportGuardrailsWizard = compose(
	withWizardBoundaries,
	withRecoilRoot,
	withImportGuardrailsWizardFormProvider
)(Component);
