import { AssetInsightsItem } from '@kelvininc/node-client-sdk';
import { ITableApi } from '@kelvininc/table';

import { IApplicationTutorial } from '@/src/page-components/SmartAppsCatalogPage/hooks';

export interface IApplicationTutorialCallbacks {
	[EAppTutorialStep.ImportData]: {
		onOpenModal: () => void;
		onCloseModal: () => void;
		onImportData: () => void;
	};
	[EAppTutorialStep.ActOnRecommendation]: {
		onRecommendationArrived: (recommendationId?: string) => void;
		onOpenModal: () => void;
		onCloseModal: () => void;
		onActOnRecommendation: () => void;
	};
	[EAppTutorialStep.AssessConsequencesOfChoices]: {
		onTabClick: () => void;
	};
}

export interface IApplicationTutorialContextValues {
	assetsTableRef?: React.RefObject<ITableApi<AssetInsightsItem>>;
	tutorial?: IApplicationTutorial;
	callbacks: IApplicationTutorialCallbacks;
	setIsSkipped: (skipped: boolean) => void;
	setIsVisible: (visible: boolean) => void;
	startTour: (stepId?: EAppTutorialStep) => void;
}

export enum EAppTutorialStep {
	ImportData = 'import-data',
	ActOnRecommendation = 'act-on-recommendation',
	AssessConsequencesOfChoices = 'assess-consequences-of-choices'
}

export enum ETipStep {
	ImportData = 'ImportData',
	UploadData = 'UploadData',
	Recommendations = 'Recommendations',
	RecommendationActions = 'RecommendationActions',
	Impact = 'Impact'
}
