import { FunctionComponent } from '@kelvininc/types';
import { Context, PropsWithChildren, Provider, createContext, useContext, useState } from 'react';

import { IBaseTData, IColumnDef } from '../../../AgGridTable/types';

import { ITableManagementEditColumnContextValues } from './types';

const TableManagementEditColumnContext: Context<null | ITableManagementEditColumnContextValues<IBaseTData>> =
	createContext<null | ITableManagementEditColumnContextValues<IBaseTData>>(null);

export const TableManagementEditColumnProvider = <TData extends IBaseTData>({
	children
}: PropsWithChildren<{}>) => {
	const editColumn = useState<IColumnDef<TData>>();
	const [hasEditColumnChanges, setHasEditColumnChanges] = useState<boolean>(false);
	const ContextProvider = useEditColumnContextProvider<TData>();

	return (
		<ContextProvider
			value={{
				editColumn,
				hasEditColumnChanges: [hasEditColumnChanges, setHasEditColumnChanges]
			}}>
			{children}
		</ContextProvider>
	);
};

export const useTableManagementEditColumnContext = <
	TData extends IBaseTData
>(): ITableManagementEditColumnContextValues<TData> => {
	const context = useContext(
		TableManagementEditColumnContext as unknown as Context<null | ITableManagementEditColumnContextValues<TData>>
	);

	if (!context) {
		throw new Error('Missing table management edit column context');
	}

	return context;
};

export const useEditColumnContextProvider = <
	TData extends IBaseTData
>(): Provider<ITableManagementEditColumnContextValues<TData> | null> => {
	return TableManagementEditColumnContext.Provider as Provider<ITableManagementEditColumnContextValues<TData> | null>;
};

export const withTableManagementEditColumnProvider = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>
) => {
	return function TableManagementEditColumnProviderWrapper(
		componentProps: PropsWithChildren<ComponentProps>
	) {
		return (
			<TableManagementEditColumnProvider>
				<Component {...componentProps} />
			</TableManagementEditColumnProvider>
		);
	};
};
