'use client';
import { AppVersion, IWorkloadDeployData } from '@kelvininc/node-client-sdk';

import { ICodeTranslatorEditorChangeData } from '@kelvininc/shared-ui';

import { getApplicationKey } from '@kelvininc/tsutils';
import { get } from 'lodash-es';

import { DeployWorkloadData } from '../types';

export const buildIDeployWorkloadData = (
	data: DeployWorkloadData,
	cluster_name: string
): IWorkloadDeployData => ({
	cluster_name,
	app_name: data.appName,
	app_version: data.appVersion,
	name: data.name,
	payload: data.payload,
	title: data.title,
	staged: data.staged,
	instantly_apply: data.instantlyApply
});

export const getSelectedApplicationKey = (
	isEditMode: boolean,
	data: Partial<DeployWorkloadData>
) =>
	!isEditMode && data.appName && data.appVersion
		? getApplicationKey(data.appName, data.appVersion)
		: undefined;

export const getAppPayload = (
	isSelectedAppBridgeType: boolean,
	appPayload: AppVersion | undefined
): Partial<ICodeTranslatorEditorChangeData> => {
	const payload = isSelectedAppBridgeType
		? get(appPayload, 'payload.app.bridge')
		: appPayload?.payload;

	return {
		object: payload,
		valid: true
	};
};

export const getErrorMessage = (error: unknown): string => {
	return get(error, 'httpResponse.errors[0].message[0]', '');
};
