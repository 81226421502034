'use client';
import { ControlChangeGet, Recommendation } from '@kelvininc/node-client-sdk';
import { EComponentSize, EIconName, KvIcon } from '@kelvininc/react-ui-components';
import { Link } from '@kelvininc/shared-ui';
import { getTimestampFormat, isLastStateFailed } from '@kelvininc/tsutils';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { MouseEvent, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { useControlChangeCreatorLink, useControlChangeCreatorTitle } from './hooks';

import styles from './styles.module.scss';

type ControlChangeProps = {
	controlChange: ControlChangeGet;
	customClasses?: ClassNamesProp;
	onClickStatus?: () => void;
	footerSize?: EComponentSize;
	creator?: Recommendation;
};

export const ControlChange = ({
	controlChange,
	customClasses,
	onClickStatus,
	footerSize = EComponentSize.Small,
	creator,
	children
}: PropsWithChildren<ControlChangeProps>) => {
	const [isMessageCollapsed, setMessageCollapsed] = useState(true);

	const link = useControlChangeCreatorLink(controlChange);
	const title = useControlChangeCreatorTitle(controlChange, creator);
	const isStateExpandable = useMemo(
		() => isLastStateFailed(controlChange) && !isEmpty(controlChange.lastMessage),
		[controlChange]
	);

	const toggleMessage = useCallback((event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		setMessageCollapsed((previousState) => !previousState);
	}, []);

	return (
		<div
			className={classNames(
				styles.ControlChange,
				styles[controlChange.lastState],
				customClasses
			)}>
			<div className={styles.Content}>
				{children}
				<div className={styles.Label}>
					<span>Requested at </span>
					<span className={styles.RequestedAt}>
						{getTimestampFormat(controlChange.created)}
					</span>
					{title && (
						<>
							<span> by </span>
							<span className={styles.UserLink}>
								<Link href={link} target="_blank">
									{title}
								</Link>
							</span>
						</>
					)}
				</div>
			</div>
			<div
				className={classNames(styles.Footer, styles[`Size-${footerSize}`], {
					[styles.Clickable]: onClickStatus !== undefined,
					[styles.Expanded]: !isMessageCollapsed
				})}
				onClick={onClickStatus}>
				{!isEmpty(controlChange.lastState) && (
					<>
						<div className={styles.Label}>
							<div className={styles.State}>
								<span className={styles.LastState}>{controlChange.lastState}</span>
								<span> - at </span>
								<span>{getTimestampFormat(controlChange.updated)}</span>
							</div>
							{isStateExpandable && (
								<div className={styles.ExpandButton} onClick={toggleMessage}>
									<KvIcon
										customClass={classNames({
											'rotate-180': !isMessageCollapsed
										})}
										name={EIconName.Collapse}
									/>
								</div>
							)}
						</div>
						{!isEmpty(controlChange.lastMessage) && (
							<div className={styles.Reason}>{controlChange.lastMessage}</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};
