import { ParamtersAppVersionSchemaGetPlain } from '@kelvininc/node-client-sdk';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { applicationHiddenParametersInstanceSettingSelector } from '@/src/recoil/instance-settings/selectors';

export const useAssetParametersHidden = (
	appName: string,
	appSchema?: ParamtersAppVersionSchemaGetPlain
) => {
	const appHiddenParameters = useRecoilValue(applicationHiddenParametersInstanceSettingSelector)[
		appName
	];

	const hiddenParameters = useMemo(() => {
		if (!appSchema || !appHiddenParameters) return {};

		return appHiddenParameters.reduce<Record<string, { 'ui:widget': string }>>(
			(acc, hiddenParameter) => {
				acc[hiddenParameter] = { 'ui:widget': 'hidden' };
				return acc;
			},
			{}
		);
	}, [appHiddenParameters, appSchema]);

	return hiddenParameters;
};
