import { EActionButtonType, EAlertType, EIconName } from '@kelvininc/react-ui-components';

import { EDensityLevel } from './types';

export const EXPAND_COLUMN_ID = 'expand-column';
export const EXPAND_COLUMN_TITLE = 'Expand';
export const SELECTION_COLUMN_ID = 'selection-column';
export const SELECTION_COLUMN_TITLE = 'Checkbox';
export const FAVOURITE_COLUMN_ID = 'favourite-column';
export const FAVOURITE_COLUMN_TITLE = 'Frozen';
export const COMMON_FEATURE_COLUMNS_DEF = {
	resizable: false,
	draggable: false,
	sortable: false,
	pinned: true,
	lockPinned: true,
	inalterable: true,
	feature: true
};
export const TABLE_SIDEPANEL_KEY = 'table-widget-sidepanel-key';
export const DEFAULT_COLUMNS_ORDER = [];
export const DEFAULT_COLUMNS_PINNED = {};
export const DEFAULT_COLUMNS_HIDDEN = {};
export const DEFAULT_SEARCH_PLACEHOLDER = 'Search';
export const DEFAULT_PAGE_SIZE = 8;
export const DEFAULT_PAGE_SIZE_OPTIONS: number[] = [8, 16, 32, 64];
export const DEFAULT_NUMBER_OF_PAGE_SHORTCUT = 2;

export const DEFAULT_TABLE_DENSITY = EDensityLevel.Medium;

export const DEFAULT_TABLE_DENSITY_OPTIONS: Partial<Record<EDensityLevel, number>> = {
	[EDensityLevel.Low]: 64,
	[EDensityLevel.Medium]: 56
};

export const DEFAULT_MANAGEMENT_TABLE_ALERT = {
	[EAlertType.Info]: {
		alertConfig: {
			type: EAlertType.Warning,
			label: 'Do you want to leave?',
			description: 'By leaving, all the changes you made below will not be saved.'
		}
	},
	[EAlertType.Error]: {
		alertConfig: {
			type: EAlertType.Error,
			label: 'Delete column?',
			description:
				'Are you sure you want to delete this column? This action is not reversible.'
		},
		actionsConfig: {
			confirmLabel: 'Delete',
			confirmButtonConfig: {
				icon: EIconName.Delete,
				type: EActionButtonType.Danger
			}
		}
	},
	[EAlertType.Warning]: {
		alertConfig: {
			type: EAlertType.Warning,
			description: 'There is no content available to display for this option.'
		}
	}
} as const;
