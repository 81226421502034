import {
	Asset,
	DataStream,
	EDataType,
	EKrnResource,
	Guardrail,
	KvKRNParser
} from '@kelvininc/node-client-sdk';
import { isDataStreamDataTypeNumber } from '@kelvininc/tsutils';
import { FieldValidation, FormValidation, createErrorHandler } from '@rjsf/utils';
import { isFunction, isNil } from 'lodash-es';

import { EGuardrailValueType } from '../../../GuardrailCraftWizard/types';
import { ImportGuardrailFileSchema } from '../../types';

import {
	NUMERIC_DATASTREAM_REQUIRED_PROPS,
	VALIDATION_CONTENT_INVALID_MAX_TYPE,
	VALIDATION_CONTENT_INVALID_MAX_VALUE_RANGE,
	VALIDATION_CONTENT_INVALID_MIN_TYPE,
	VALIDATION_CONTENT_INVALID_MIN_VALUE_RANGE,
	VALIDATION_CONTENT_INVALID_NON_NUMERIC_FIELDS,
	VALIDATION_CONTENT_MISSING_REQUIRED_FIELDS_FOR_DATASTREAM_TYPE,
	VALIDATION_CONTENT_RESOURCE_NOT_IN_METRICS_MAP,
	VALIDATION_SYSTEM_DUPLICATED_RESOURCE_NAME,
	VALIDATION_SYSTEM_RESOURCE_ALREADY_EXITS,
	VALIDATION_SYSTEM_UNEXISTING_ASSET_NAME,
	VALIDATION_SYSTEM_UNEXISTING_DATASTREAM_NAME
} from './config';

import {
	IConnectionFormValues,
	IPublisherMapping
} from '@/src/page-components/ConnectionsPage/ConnectionWizard/types';

const addPropertyError = (
	property: string,
	error: string,
	errors: FormValidation<ImportGuardrailFileSchema>
): FormValidation<ImportGuardrailFileSchema> => {
	const key = property as keyof FormValidation<ImportGuardrailFileSchema>;
	const message = isFunction(error) ? error(property) : error;

	const fieldValidation = (errors[key] ??
		createErrorHandler({ [property]: undefined })) as FieldValidation;
	(errors[key] as FieldValidation) = fieldValidation;

	const propertyError = errors[key] as FieldValidation;
	propertyError.addError(message);

	return errors;
};

const validateGuardrailLimits = (
	guardrail: ImportGuardrailFileSchema,
	errors: FormValidation<ImportGuardrailFileSchema>
): FormValidation<ImportGuardrailFileSchema> => {
	// Check if the max value and minimum value are valid
	if (
		guardrail.min_value_type === EGuardrailValueType.Number &&
		guardrail.max_value_type === EGuardrailValueType.Number &&
		Number(guardrail.max_value) <= Number(guardrail.min_value)
	) {
		errors.max_value?.addError(VALIDATION_CONTENT_INVALID_MAX_VALUE_RANGE);
		errors.min_value?.addError(VALIDATION_CONTENT_INVALID_MIN_VALUE_RANGE);
	}

	return errors;
};

const validateDataStream = (
	dataType: EDataType,
	guardrail: ImportGuardrailFileSchema,
	errors: FormValidation<ImportGuardrailFileSchema>
): FormValidation<ImportGuardrailFileSchema> => {
	if (isDataStreamDataTypeNumber(dataType)) {
		let hasErrors = false;

		// Check if required properties are defined
		for (const prop of NUMERIC_DATASTREAM_REQUIRED_PROPS) {
			if (isNil(guardrail[prop])) {
				addPropertyError(
					prop as keyof FormValidation<ImportGuardrailFileSchema>,
					VALIDATION_CONTENT_MISSING_REQUIRED_FIELDS_FOR_DATASTREAM_TYPE,
					errors
				);
				hasErrors = true;
			}
		}

		if (hasErrors) {
			throw Error(VALIDATION_CONTENT_MISSING_REQUIRED_FIELDS_FOR_DATASTREAM_TYPE);
		}

		return errors;
	}

	// Check if any of the blocked properties are defined
	const hasBlockedProps = NUMERIC_DATASTREAM_REQUIRED_PROPS.some(
		(prop) => !isNil(guardrail[prop])
	);
	if (hasBlockedProps) {
		errors.addError(VALIDATION_CONTENT_INVALID_NON_NUMERIC_FIELDS);
		throw Error(VALIDATION_CONTENT_INVALID_NON_NUMERIC_FIELDS);
	}

	if (guardrail.min_value_type === EGuardrailValueType.Number) {
		errors.min_value_type?.addError(VALIDATION_CONTENT_INVALID_MIN_TYPE);
		throw Error(VALIDATION_CONTENT_INVALID_MIN_TYPE);
	}

	if (guardrail.max_value_type === EGuardrailValueType.Number) {
		errors.max_value_type?.addError(VALIDATION_CONTENT_INVALID_MAX_TYPE);
		throw Error(VALIDATION_CONTENT_INVALID_MAX_TYPE);
	}

	validateGuardrailLimits(guardrail, errors);

	return errors;
};

const validateConnectionGuardrail = (
	connection: IConnectionFormValues,
	guardrail: ImportGuardrailFileSchema,
	errors: FormValidation<ImportGuardrailFileSchema>
): FormValidation<ImportGuardrailFileSchema> => {
	const metricsMap = connection.payload.metrics_map as IPublisherMapping[];
	const mapping = metricsMap.find(
		({ asset_name, name }) => asset_name === guardrail.asset && name === guardrail.datastream
	);

	if (!mapping) {
		errors.asset?.addError(VALIDATION_CONTENT_RESOURCE_NOT_IN_METRICS_MAP);
		errors.datastream?.addError(VALIDATION_CONTENT_RESOURCE_NOT_IN_METRICS_MAP);

		throw Error(VALIDATION_CONTENT_RESOURCE_NOT_IN_METRICS_MAP);
	}

	validateDataStream(mapping?.data_type as EDataType, guardrail, errors);

	return errors;
};

export const validateContent = (
	data: ImportGuardrailFileSchema[] | undefined,
	systemGuardrails: Record<string, Guardrail>,
	systemAssets: Record<string, Asset>,
	systemDatastreams: Record<string, DataStream>,
	errors: FormValidation<ImportGuardrailFileSchema[]>,
	connection?: IConnectionFormValues
): FormValidation<ImportGuardrailFileSchema[]> => {
	const newGuardrails: string[] = [];

	if (!data) {
		return errors;
	}

	for (const [index, newGuardrail] of data.entries()) {
		const resource = KvKRNParser.buildKRN<EKrnResource.AssetDatastream>(
			EKrnResource.AssetDatastream,
			{ asset: newGuardrail.asset, datastream: newGuardrail.datastream }
		);
		const error = errors[index];
		if (!error) {
			continue;
		}

		// Check if already exists in the file a guardrail for this resource
		if (newGuardrails.includes(resource)) {
			error.asset?.addError(VALIDATION_SYSTEM_DUPLICATED_RESOURCE_NAME);
			error.datastream?.addError(VALIDATION_SYSTEM_DUPLICATED_RESOURCE_NAME);
			continue;
		} else {
			newGuardrails.push(resource);
		}

		// Check if already exists in the system a guardrail for this resource
		if (systemGuardrails[resource]) {
			error.asset?.addError(VALIDATION_SYSTEM_RESOURCE_ALREADY_EXITS);
			error.datastream?.addError(VALIDATION_SYSTEM_RESOURCE_ALREADY_EXITS);
			continue;
		}

		if (connection) {
			try {
				validateConnectionGuardrail(connection, newGuardrail, error);
			} catch (error) {
				continue;
			}
		} else {
			// Check if this asset does not exist in the system
			const asset = systemAssets[newGuardrail.asset];
			if (!asset) {
				error.asset?.addError(VALIDATION_SYSTEM_UNEXISTING_ASSET_NAME);
			}

			// Check if this data stream does not exist in the system
			const datastream = systemDatastreams[newGuardrail.datastream];
			if (!datastream) {
				error.datastream?.addError(VALIDATION_SYSTEM_UNEXISTING_DATASTREAM_NAME);
				continue;
			}

			try {
				validateDataStream(datastream.dataTypeName, newGuardrail, error);
			} catch (error) {
				continue;
			}
		}
	}

	return errors;
};
