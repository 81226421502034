import {
	AppKRN,
	AssetInsightsItem,
	AssetInsightsService,
	DataStream,
	EControlChangeState,
	EDatastreamExtraFieldComputationDataAgg,
	EParameterType,
	EParametersScheduleExtraFieldDataScheduleType,
	EPropertyType,
	ERecommendationState,
	IAssetInsightsExtraFieldsData,
	IAssetInsightsSortByData,
	IAssetPropertyExtraFieldData,
	IDatastreamExtraFieldComputationData,
	IParameterExtraFieldData,
	KvNodeHttpRequestOptions,
	ParameterDefinitionItem,
	Property,
	Unit
} from '@kelvininc/node-client-sdk';

import {
	Datasource,
	ECustomColumnType,
	ETableAdvancedFilterType,
	ETableSettingKey,
	IAbortableDatasourcePlugin,
	IAdvancedFiltersConfig,
	IApplicationParameterColumnConfigMetadata,
	IApplicationParameterColumnData,
	IAssetPropertyColumnConfigMetadata,
	IAssetPropertyColumnData,
	IColumnDef,
	IColumnOptions,
	ICustomColumnMetadata,
	IDatastreamColumnConfigMetadata,
	IDatastreamColumnData,
	ILastControlChangeColumnConfigMetadata,
	ILastControlChangeColumnData,
	IRecommendationColumnConfigMetadata,
	IRecommendationColumnData,
	IRecommendationModalCallbacks,
	IScheduleApplicationParametersColumnConfigMetadata,
	IScheduleApplicationParametersColumnData,
	ITableAdvancedFilters,
	ScheduleParametersCellRendererParams,
	isValidApplicationParameterFilter,
	isValidAssetPropertyFilter,
	isValidDatastreamFilter,
	withAbortRequestController,
	withPreviousPageFallback
} from '@kelvininc/table';
import {
	buildUniqueName,
	compose,
	convertToValidName,
	formatDatastreamValue,
	getAbsoluteTimeRange,
	getControlChangeStatusName,
	getDatastreamAggregationName,
	getDatastreamUnitSymbol,
	getDefaultTimezone,
	getParameterTitle,
	getRecommendationStatusName,
	getRelativeTimeRange,
	getTimezoneOffset,
	isDataStreamDataTypeNumber,
	isParameterPrimitiveTypeNumber,
	isPropertyPrimitiveTypeNumber,
	isPropertyPrimitiveTypeTimestamp
} from '@kelvininc/tsutils';
import {
	ClassAttributes,
	DataStreamAggregationFunction,
	ECalendarAdvanceTimeType,
	EDataStreamCustomAgg,
	ERelativeTimeRangeKey,
	ESortDirection,
	IApplicationParameterDefinition,
	ICalendarAdvanceTime,
	ICalendarAdvanceTimeChange,
	ICalendarTimezone
} from '@kelvininc/types';
import { get, isEmpty, isNil, keyBy } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { openAssetScheduledParametersModal } from '@/src/components/client/AssetScheduledParametersModal/utils';
import {
	DEFAULT_LAST_CONTROL_CHANGE_COLUMN_OPTIONS,
	DEFAULT_NON_NUMERIC_COLUMN_OPTIONS,
	DEFAULT_NUMERIC_COLUMN_OPTIONS,
	DEFAULT_PARAMETER_COLUMN_OPTIONS,
	DEFAULT_RECOMMENDATION_COLUMN_OPTIONS,
	DEFAULT_SCHEDULE_APPLICATION_PARAMETERS_COLUMN_OPTIONS,
	DEFAULT_TIMESTAMP_COLUMN_OPTIONS
} from '@/src/hooks/useAssetInsightsTableSettings/config';

import { ISerializedAssetInsightsTableSettings } from '@/src/hooks/useAssetInsightsTableSettings/types';
import { CLOSED_LOOP_SETTINGS_INSTANCE_SETTING_DEFAULT } from '@/src/recoil/instance-settings/config';
import {
	IAssetInsightsCustomColumnsConfigMetadata,
	IAssetInsightsFilters
} from '@/src/types/asset-insights';

const buildTimeRange = (
	time: ICalendarAdvanceTimeChange,
	timezone: ICalendarTimezone = {
		name: getDefaultTimezone(),
		offset: getTimezoneOffset()
	}
): [string | undefined, string | undefined] => {
	let startTime: string | undefined, endTime: string | undefined;
	const { type, payload } = time;
	const { key, range } = payload as ICalendarAdvanceTime;

	if (type === ECalendarAdvanceTimeType.Relative) {
		[startTime, endTime] = getRelativeTimeRange(key as ERelativeTimeRangeKey);
	} else {
		[startTime, endTime] = getAbsoluteTimeRange(range, timezone.offset);
	}

	return [startTime, endTime];
};

const buildDatastreamComputation = (
	metadata: IDatastreamColumnData
): IDatastreamExtraFieldComputationData | undefined => {
	if (metadata.aggregation === EDataStreamCustomAgg.Last) {
		return;
	}

	if (!metadata.range) {
		return {
			agg: metadata.aggregation
		};
	}

	const [startTime, endTime] = buildTimeRange(metadata.range.time, metadata.range.timezone);

	return {
		agg: metadata.aggregation,
		start_time: startTime,
		end_time: endTime
	};
};

const buildRecommendationStatus = (
	statuses: ERecommendationState[] | undefined
): ERecommendationState[] | undefined => {
	if (!statuses) {
		return;
	}

	const newStatuses = [...statuses];
	if (newStatuses.includes(ERecommendationState.Accepted)) {
		newStatuses.push(ERecommendationState.AutoAccepted, ERecommendationState.Error);
	}

	return newStatuses;
};

export const getCustomAccessor = (columnId: string, columnTitle?: string): string =>
	`extraFields.${formatExtraFieldName(columnId, columnTitle)}`;

const getColumnsTitles = (columnDefs: IColumnDef<AssetInsightsItem>[]): string[] =>
	columnDefs.reduce<string[]>((accumulator, { title }) => {
		if (title?.length) {
			accumulator.push(title);
		}

		return accumulator;
	}, []);

const isAggregationDataTypeAbsolute = (aggregation: DataStreamAggregationFunction): boolean => {
	return (
		aggregation === EDatastreamExtraFieldComputationDataAgg.Count ||
		aggregation === EDatastreamExtraFieldComputationDataAgg.Sum
	);
};

const getDatastreamValueFormatter = (
	value: string | number | undefined,
	datastreamUnits: Record<string, Unit>,
	datastream?: DataStream,
	aggregation?: DataStreamAggregationFunction
): string => {
	if (!datastream || !aggregation) {
		return value?.toString() || 'N/A';
	}

	return formatDatastreamValue(
		value,
		datastream,
		getDatastreamUnitSymbol(datastream, datastreamUnits),
		!isAggregationDataTypeAbsolute(aggregation),
		'N/A'
	);
};

const getDefaultAssetPropertyOptions = (property?: Property): IColumnOptions<AssetInsightsItem> => {
	if (property) {
		if (isPropertyPrimitiveTypeNumber(property.primitiveType)) {
			return DEFAULT_NUMERIC_COLUMN_OPTIONS;
		}

		if (isPropertyPrimitiveTypeTimestamp(property.primitiveType)) {
			return DEFAULT_TIMESTAMP_COLUMN_OPTIONS;
		}
	}

	return DEFAULT_NON_NUMERIC_COLUMN_OPTIONS;
};

const getDefaultApplicationParameterOptions = (
	parameter?: ParameterDefinitionItem
): IColumnOptions<AssetInsightsItem> => {
	if (parameter) {
		if (isParameterPrimitiveTypeNumber(parameter.primitiveType)) {
			return DEFAULT_NUMERIC_COLUMN_OPTIONS;
		}
	}

	return DEFAULT_PARAMETER_COLUMN_OPTIONS;
};

const getDefaultDatastreamOptions = (
	datastream?: DataStream,
	aggregation?: DataStreamAggregationFunction
): IColumnOptions<AssetInsightsItem> => {
	if (datastream && aggregation) {
		if (isDataStreamDataTypeNumber(datastream.dataTypeName)) {
			return DEFAULT_NUMERIC_COLUMN_OPTIONS;
		}

		if (isAggregationDataTypeAbsolute(aggregation)) {
			return DEFAULT_NUMERIC_COLUMN_OPTIONS;
		}
	}

	return DEFAULT_NON_NUMERIC_COLUMN_OPTIONS;
};

const getStatusesTitle = <T>(
	subset: T[],
	dataset: T[],
	getName: (name: T) => string
): string | undefined => {
	if (subset.length === 0 || subset.length === dataset.length) {
		return;
	}

	return subset.length < 3 ? subset.map(getName).join('/') : 'Filtered';
};

export const getExtraFieldAccessor = (columnId: string): string => `extraFields.${columnId}`;

export const formatExtraFieldName = (id: string, title?: string): string =>
	`${title ? `${convertToValidName(title).toLocaleLowerCase()}-` : ''}${id}`;

export const buildExtraFieldsData = <T extends ClassAttributes<AssetInsightsItem>>({
	columnDefs,
	properties,
	appParameters,
	filters = {}
}: {
	columnDefs: IColumnDef<T>[];
	properties: Record<string, Property>;
	appParameters: IApplicationParameterDefinition;
	filters?: IAssetInsightsFilters;
}): IAssetInsightsExtraFieldsData => {
	const appliedFilters = {
		assetProperties: [] as string[],
		appParameters: [] as string[]
	};

	const result = columnDefs.reduce<IAssetInsightsExtraFieldsData>(
		(accumulator, columnDef) => {
			const { id, metadata, title } = columnDef;
			const name = formatExtraFieldName(id, title);

			if (isNil(metadata)) {
				return accumulator;
			}

			const {
				datastreams = [],
				asset_properties = [],
				control_changes = [],
				recommendations = [],
				parameters = [],
				parameters_schedule = []
			} = accumulator;

			switch (metadata.type) {
				case ECustomColumnType.Datastream:
					datastreams.push({
						name,
						datastream_name: metadata.data.datastream,
						computation: buildDatastreamComputation(metadata.data)
					});
					break;

				case ECustomColumnType.AssetProperty:
					if (metadata.data.property) {
						const propFilters: IAssetPropertyExtraFieldData | undefined = get(
							filters,
							['assetProperties', metadata.data.property],
							undefined
						);

						if (propFilters) {
							appliedFilters.assetProperties.push(metadata.data.property);
						}

						asset_properties.push({
							name,
							property_name: metadata.data.property,
							primitive_type:
								properties[metadata.data.property]?.primitiveType ??
								EPropertyType.String,
							filters: propFilters?.filters
						});
					}
					break;

				case ECustomColumnType.LastControlChange:
					control_changes.push({
						name,
						datastreams: metadata.data.datastreams,
						statuses: metadata.data.statuses
					});
					break;

				case ECustomColumnType.Recommendation:
					const [since] = metadata.data.range
						? buildTimeRange(metadata.data.range.time, metadata.data.range.timezone)
						: [];
					const states = buildRecommendationStatus(metadata.data.statuses);
					const source = metadata.data.application
						? AppKRN.toKRN({ app: metadata.data.application })
						: undefined;
					recommendations.push({
						name,
						since,
						states,
						types: metadata.data.recommendationTypes,
						source
					});
					break;

				case ECustomColumnType.ApplicationParameter:
					if (metadata.data.application && metadata.data.parameter) {
						const propFilters: IParameterExtraFieldData | undefined = get(
							filters,
							['appParameters', metadata.data.parameter],
							undefined
						);

						if (propFilters) {
							appliedFilters.appParameters.push(metadata.data.parameter);
						}

						parameters.push({
							name,
							parameter_name: metadata.data.parameter,
							app_name: metadata.data.application,
							primitive_type:
								appParameters[metadata.data.application][metadata.data.parameter]
									?.primitiveType ?? EParameterType.String,
							filters: propFilters?.filters
						});
					}
					break;

				case ECustomColumnType.ScheduleApplicationParameters:
					const { type, range } = metadata.data;
					const [, maxDate] = range ? buildTimeRange(range.time, range.timezone) : [];

					parameters_schedule.push({
						name,
						schedule_type: type,
						max_date: maxDate
					});
					break;

				default:
					break;
			}

			return {
				datastreams,
				asset_properties,
				control_changes,
				recommendations,
				parameters,
				parameters_schedule
			};
		},
		{
			datastreams: [],
			asset_properties: [],
			control_changes: [],
			recommendations: [],
			parameters: [],
			parameters_schedule: []
		}
	);

	result.asset_properties?.push(
		...Object.values(filters.assetProperties || {}).filter(
			(filter) => !appliedFilters.assetProperties.includes(filter.property_name as string)
		)
	);
	result.parameters?.push(...Object.values(filters.appParameters || {}));
	result.datastreams?.push(...Object.values(filters.datastreams || {}));

	return result;
};

export const buildSortBy = <T extends ClassAttributes<AssetInsightsItem>>(
	columnsDef: IColumnDef<T>[],
	sortBy?: string[],
	sortDirection?: ESortDirection
): IAssetInsightsSortByData[] | undefined => {
	if (!sortBy?.length) {
		return;
	}

	return sortBy.reduce<IAssetInsightsSortByData[]>((accumulator, field) => {
		const fieldColumnDef = columnsDef.find(({ id }) => id === field);
		if (!fieldColumnDef) {
			return accumulator;
		}

		const { metadata, id, title } = fieldColumnDef;

		const isFieldExtraField = !isNil(metadata);
		const name = isFieldExtraField ? formatExtraFieldName(id, title) : field;

		accumulator.push({
			field: name,
			direction: sortDirection,
			sort_by_extra_field: isFieldExtraField
		});

		return accumulator;
	}, []);
};

export const buildAssetInsightsCustomColumnDef = (
	id: string,
	title: string,
	metadata: ICustomColumnMetadata,
	options: IColumnOptions<AssetInsightsItem>,
	configMetadata: IAssetInsightsCustomColumnsConfigMetadata = {}
): IColumnDef<AssetInsightsItem> => {
	switch (metadata.type) {
		case ECustomColumnType.Datastream:
			return getDatastreamColumnBuilder(
				id,
				title,
				metadata.data,
				configMetadata[ECustomColumnType.Datastream],
				options
			);
		case ECustomColumnType.AssetProperty:
			return getAssetPropertyColumnBuilder(
				id,
				title,
				metadata.data,
				configMetadata[ECustomColumnType.AssetProperty],
				options
			);
		case ECustomColumnType.LastControlChange:
			return getLastControlChangeColumnBuilder(
				id,
				title,
				metadata.data,
				configMetadata[ECustomColumnType.LastControlChange],
				options
			);
		case ECustomColumnType.Recommendation:
			return getRecommendationColumnBuilder(
				id,
				title,
				metadata.data,
				configMetadata[ECustomColumnType.Recommendation],
				options
			);
		case ECustomColumnType.ApplicationParameter:
			return getApplicationParameterColumnBuilder(
				id,
				title,
				metadata.data,
				configMetadata[ECustomColumnType.ApplicationParameter],
				options
			);
		case ECustomColumnType.ScheduleApplicationParameters:
			return getScheduleApplicationParametersColumnBuilder(
				id,
				title,
				metadata.data,
				configMetadata[ECustomColumnType.ScheduleApplicationParameters],
				options
			);
	}
};

export const getRowId = ({ name }: AssetInsightsItem): string => name;

export const getColumnIdBuilder = (): string => {
	return uuidv4();
};

export const getDatastreamColumnBuilder = (
	id: string,
	title: string,
	data: IDatastreamColumnData,
	metadata: IDatastreamColumnConfigMetadata = { datastreams: {}, datastreamUnits: {} },
	customOptions?: IColumnOptions<AssetInsightsItem>
): IColumnDef<AssetInsightsItem> => {
	const { datastream: datastreamName } = data;
	const { datastreams, datastreamUnits } = metadata;

	const datastream = datastreamName ? datastreams[datastreamName] : undefined;

	return {
		...getDefaultDatastreamOptions(datastream, data.aggregation),
		id,
		title,
		accessor: getCustomAccessor(id, title),
		...customOptions,
		metadata: {
			type: ECustomColumnType.Datastream,
			data
		},
		valueFormatter: (value: string | number | undefined) =>
			getDatastreamValueFormatter(value, datastreamUnits, datastream, data.aggregation)
	};
};

export const getDatastreamColumnTitleBuilder = (
	columnDefs: IColumnDef<AssetInsightsItem>[],
	data: IDatastreamColumnData,
	metadata: IDatastreamColumnConfigMetadata
): string => {
	const { datastream: datastreamName, aggregation } = data;
	const { datastreams } = metadata;
	const columnsTitles = getColumnsTitles(columnDefs);
	let initialTitle = 'Data Stream';

	if (datastreamName) {
		// Find the selected datastream
		const datastream = datastreams[datastreamName];
		if (datastream) {
			const { title } = datastream;
			initialTitle = title;
		}
	}

	if (aggregation) {
		initialTitle = `${getDatastreamAggregationName(aggregation)} ${initialTitle}`;
	}

	return buildUniqueName(initialTitle, columnsTitles);
};

export const getDatastreamDataValidator = (data: IDatastreamColumnData): boolean => {
	if (!data.datastream || !data.aggregation) {
		return false;
	}

	if (data.range === undefined && data.aggregation !== EDataStreamCustomAgg.Last) {
		return false;
	}

	return true;
};

export const getAssetPropertyColumnBuilder = (
	id: string,
	title: string,
	data: IAssetPropertyColumnData,
	metadata: IAssetPropertyColumnConfigMetadata = { properties: {} },
	customOptions?: IColumnOptions<AssetInsightsItem>
): IColumnDef<AssetInsightsItem> => {
	const { property: propertyName } = data;
	const { properties } = metadata;

	const property = propertyName ? properties[propertyName] : undefined;

	return {
		...getDefaultAssetPropertyOptions(property),
		id,
		title,
		accessor: getCustomAccessor(id, title),
		...customOptions,
		metadata: {
			type: ECustomColumnType.AssetProperty,
			data
		},
		valueFormatter: (value: string | number | undefined) => value?.toString() || 'N/A'
	};
};

export const getAssetPropertyColumnTitleBuilder = (
	columnDefs: IColumnDef<AssetInsightsItem>[],
	data: IAssetPropertyColumnData,
	metadata: IAssetPropertyColumnConfigMetadata
): string => {
	const { properties } = metadata;
	const { property: propertyName } = data;
	const columnsTitles = getColumnsTitles(columnDefs);
	let initialTitle = 'Property';

	// Check if a property is selected
	if (propertyName) {
		const property = properties[propertyName];
		if (property) {
			const { title } = property;
			initialTitle = `${title} Property`;
		}
	}

	return buildUniqueName(initialTitle, columnsTitles);
};

export const getAssetPropertyDataValidator = (data: IAssetPropertyColumnData): boolean => {
	return !isNil(data.property);
};

export const getLastControlChangeColumnBuilder = (
	id: string,
	title: string,
	data: ILastControlChangeColumnData,
	metadata: ILastControlChangeColumnConfigMetadata = { datastreams: {}, datastreamUnits: {} },
	customOptions?: IColumnOptions<AssetInsightsItem>
): IColumnDef<AssetInsightsItem> => ({
	...DEFAULT_LAST_CONTROL_CHANGE_COLUMN_OPTIONS,
	id,
	title,
	accessor: getCustomAccessor(id, title),
	...customOptions,
	metadata: {
		type: ECustomColumnType.LastControlChange,
		data
	},
	cellComponentParams: {
		...DEFAULT_LAST_CONTROL_CHANGE_COLUMN_OPTIONS.cellComponentParams,
		datastreams: metadata.datastreams,
		datastreamUnits: metadata.datastreamUnits
	}
});

export const getLastControlChangeColumnTitleBuilder = (
	columnDefs: IColumnDef<AssetInsightsItem>[],
	data: ILastControlChangeColumnData,
	metadata: ILastControlChangeColumnConfigMetadata
): string => {
	const { datastreams: datastreamNames = [], statuses = [] } = data;
	const { datastreams } = metadata;
	const columnsTitles = getColumnsTitles(columnDefs);
	let initialTitle = 'CC';

	// Check if any datastream is selected and not all datastreams are selected
	if (datastreamNames.length > 0 && datastreamNames.length < Object.keys(datastreams).length) {
		const datastreamsMap = keyBy(datastreams, 'name');
		const datastreamsTitles = datastreamNames.reduce<string[]>((accumulator, name) => {
			const datastream = datastreamsMap[name];
			if (datastream) {
				accumulator.push(datastream.title);
			}

			return accumulator;
		}, []);

		const title = datastreamsTitles.length < 3 ? datastreamsTitles.join('/') : 'Data streams';
		initialTitle = `${title} ${initialTitle}`;
	}

	const statusesTitle = getStatusesTitle(
		statuses,
		Object.keys(EControlChangeState) as EControlChangeState[],
		getControlChangeStatusName
	);
	if (statusesTitle) {
		initialTitle = `${statusesTitle} ${initialTitle}`;
	}

	return buildUniqueName(`Last ${initialTitle}`, columnsTitles);
};

export const getLastControlChangeDataValidator = (data: ILastControlChangeColumnData): boolean => {
	const { datastreams = [], allSetpoints } = data;

	return allSetpoints || datastreams.length > 0;
};

export const getRecommendationColumnBuilder = (
	id: string,
	title: string,
	data: IRecommendationColumnData,
	metadata: IRecommendationColumnConfigMetadata = {
		recommendationTypes: {},
		applications: {},
		closedLoopSettings: CLOSED_LOOP_SETTINGS_INSTANCE_SETTING_DEFAULT,
		permissions: undefined
	},
	customOptions: IColumnOptions<AssetInsightsItem> = {},
	callbacks: IRecommendationModalCallbacks = {}
): IColumnDef<AssetInsightsItem> => ({
	...DEFAULT_RECOMMENDATION_COLUMN_OPTIONS,
	id,
	title,
	accessor: getCustomAccessor(id, title),
	...customOptions,
	metadata: {
		type: ECustomColumnType.Recommendation,
		data
	},
	cellComponentParams: {
		...DEFAULT_RECOMMENDATION_COLUMN_OPTIONS.cellComponentParams,
		recommendationTypes: metadata.recommendationTypes,
		closedLoopSettings: metadata.closedLoopSettings,
		permissions: metadata.permissions,
		callbacks
	}
});

export const getRecommendationColumnTitleBuilder = (
	columnDefs: IColumnDef<AssetInsightsItem>[],
	data: IRecommendationColumnData,
	metadata: IRecommendationColumnConfigMetadata
): string => {
	const { statuses = [], recommendationTypes: recommendationTypesNames = [], application } = data;
	const { recommendationTypes, applications } = metadata;
	const columnsTitles = getColumnsTitles(columnDefs);
	let initialTitle = 'Recs';

	// Check if a recommendation type is selected
	if (
		recommendationTypesNames.length > 0 &&
		recommendationTypesNames.length < Object.keys(recommendationTypes).length
	) {
		const recommendationTypesTitles = recommendationTypesNames.reduce<string[]>(
			(accumulator, recommendationTypeName) => {
				const recommendationType = recommendationTypes[recommendationTypeName];
				if (recommendationType) {
					accumulator.push(recommendationType.title);
				}

				return accumulator;
			},
			[]
		);

		if (recommendationTypesTitles.length < 3) {
			const recommendationTypesTitle = recommendationTypesTitles.join('/');
			initialTitle = `${recommendationTypesTitle} ${initialTitle}`;
		}
	}

	const statusesTitle = getStatusesTitle(
		statuses,
		Object.keys(ERecommendationState) as ERecommendationState[],
		getRecommendationStatusName
	);
	if (statusesTitle) {
		initialTitle = `${statusesTitle} ${initialTitle}`;
	}

	const appDisplayName =
		application && !isEmpty(application) && applications[application]
			? applications[application].title
			: undefined;

	if (appDisplayName) {
		initialTitle = `${appDisplayName} ${initialTitle}`;
	}

	return buildUniqueName(initialTitle, columnsTitles);
};

export const getRecommendationDataValidator = (data: IRecommendationColumnData): boolean => {
	const { range } = data;

	return !isNil(range);
};

export const getApplicationParameterColumnBuilder = (
	id: string,
	title: string,
	data: IApplicationParameterColumnData,
	metadata: IApplicationParameterColumnConfigMetadata = {
		parameters: {},
		applications: {},
		closedLoopSettings: CLOSED_LOOP_SETTINGS_INSTANCE_SETTING_DEFAULT
	},
	customOptions?: IColumnOptions<AssetInsightsItem>
): IColumnDef<AssetInsightsItem> => {
	const { parameter: parameterName, application: applicationName } = data;
	const { parameters } = metadata;

	const parameter =
		parameterName && applicationName ? parameters[applicationName][parameterName] : undefined;

	const options = getDefaultApplicationParameterOptions(parameter);

	return {
		...options,
		id,
		title,
		accessor: getCustomAccessor(id, title),
		...customOptions,
		metadata: {
			type: ECustomColumnType.ApplicationParameter,
			data
		},
		cellComponentParams: {
			...options.cellComponentParams,
			closedLoopSettings: metadata.closedLoopSettings,
			parameter: parameterName
		},
		valueFormatter: (value: string | number | undefined) => value?.toString() || 'N/A'
	};
};

export const getApplicationParameterColumnTitleBuilder = (
	columnDefs: IColumnDef<AssetInsightsItem>[],
	data: IApplicationParameterColumnData,
	metadata: IApplicationParameterColumnConfigMetadata
): string => {
	const { parameters, closedLoopSettings } = metadata;
	const { parameter: parameterName, application: applicationName } = data;
	const columnsTitles = getColumnsTitles(columnDefs);
	let initialTitle = 'Parameter';
	// Check if a application and parameter are selected
	if (applicationName && parameterName) {
		const parameter = parameters[applicationName]?.[parameterName];
		if (parameter) {
			const title = getParameterTitle(parameter, closedLoopSettings);

			initialTitle = `${title} Parameter`;
		}
	}

	return buildUniqueName(initialTitle, columnsTitles);
};

export const getApplicationParameterDataValidator = (
	data: IApplicationParameterColumnData
): boolean => {
	return !isNil(data.parameter) && !isNil(data.application);
};

export const getScheduleApplicationParametersColumnBuilder = (
	id: string,
	title: string,
	data: IScheduleApplicationParametersColumnData,
	metadata: IScheduleApplicationParametersColumnConfigMetadata = {
		parameters: {},
		closedLoopSettings: CLOSED_LOOP_SETTINGS_INSTANCE_SETTING_DEFAULT
	},
	customOptions?: IColumnOptions<AssetInsightsItem>
): IColumnDef<AssetInsightsItem> => ({
	...DEFAULT_SCHEDULE_APPLICATION_PARAMETERS_COLUMN_OPTIONS,
	id,
	title,
	accessor: getCustomAccessor(id, title),
	...customOptions,
	metadata: {
		type: ECustomColumnType.ScheduleApplicationParameters,
		data
	},
	cellComponentParams: {
		...DEFAULT_SCHEDULE_APPLICATION_PARAMETERS_COLUMN_OPTIONS.cellComponentParams,
		parameters: metadata.parameters,
		closedLoopSettings: metadata.closedLoopSettings,
		onClickScheduleDetails: ({ assetName }) => openAssetScheduledParametersModal({ assetName })
	} satisfies ScheduleParametersCellRendererParams
});

export const getScheduleApplicationParametersColumnTitleBuilder = (
	columnDefs: IColumnDef<AssetInsightsItem>[],
	data: IScheduleApplicationParametersColumnData,
	_metadata: IScheduleApplicationParametersColumnConfigMetadata
): string => {
	const { type } = data;
	const columnsTitles = getColumnsTitles(columnDefs);
	let title = 'Next Changes';

	if (type === EParametersScheduleExtraFieldDataScheduleType.Temporary) {
		title = 'Next Temporary Changes';
	}

	return buildUniqueName(title, columnsTitles);
};

export const getScheduleApplicationParametersDataValidator = (
	data: IScheduleApplicationParametersColumnData
): boolean => {
	return !isNil(data.range);
};

export const getAssetInsightsDatasource: (
	properties: Record<string, Property>,
	appParameters: IApplicationParameterDefinition
) => Datasource<AssetInsightsItem, IAbortableDatasourcePlugin> = (
	properties: Record<string, Property>,
	appParameters: IApplicationParameterDefinition
) =>
	compose<Datasource<AssetInsightsItem, IAbortableDatasourcePlugin>>(
		withPreviousPageFallback,
		withAbortRequestController
	)(({ sortBy, sortDirection, filters, search, page, pageSize, pinned, columnDefs, signal }) =>
		AssetInsightsService.getAssetInsights(
			{
				assetInsightsGetData: {
					asset_names: filters?.asset_names as string[] | undefined,
					asset_types: filters?.asset_types as string[] | undefined,
					extra_fields: buildExtraFieldsData({ columnDefs, properties, appParameters }),
					sort_by: buildSortBy(columnDefs, sortBy, sortDirection),
					pinned_assets: pinned,
					search
				},
				page,
				pageSize
			},
			{ signal } as KvNodeHttpRequestOptions<RequestInit>
		).toPromise()
	);

export const filterValidAdvancedFiltersSettings = (
	settings: ISerializedAssetInsightsTableSettings,
	config: IAdvancedFiltersConfig
): ISerializedAssetInsightsTableSettings => {
	const { [ETableSettingKey.AdvancedFilters]: advancedFilters = {}, ...otherSettings } = settings;

	const validAdvancedFilters = Object.values(advancedFilters)
		.flat()
		.reduce<Required<ITableAdvancedFilters>>(
			(accumulator, filter) => {
				switch (filter.type) {
					case ETableAdvancedFilterType.AssetProperty:
						if (
							isValidAssetPropertyFilter(
								filter,
								config[ETableAdvancedFilterType.AssetProperty]!.metadata
							)
						) {
							accumulator[ETableAdvancedFilterType.AssetProperty].push(filter);
						}
						break;

					case ETableAdvancedFilterType.Datastream:
						if (
							isValidDatastreamFilter(
								filter,
								config[ETableAdvancedFilterType.Datastream]!.metadata
							)
						) {
							accumulator[ETableAdvancedFilterType.Datastream].push(filter);
						}
						break;

					case ETableAdvancedFilterType.ApplicationParameter:
						if (
							isValidApplicationParameterFilter(
								filter,
								config[ETableAdvancedFilterType.ApplicationParameter]!.metadata
							)
						) {
							accumulator[ETableAdvancedFilterType.ApplicationParameter].push(filter);
						}
						break;
				}

				return accumulator;
			},
			{
				[ETableAdvancedFilterType.AssetProperty]: [],
				[ETableAdvancedFilterType.ApplicationParameter]: [],
				[ETableAdvancedFilterType.Datastream]: []
			}
		);

	return {
		...otherSettings,
		[ETableSettingKey.AdvancedFilters]: validAdvancedFilters
	};
};
