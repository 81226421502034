import { Asset, AssetService, AssetType, EPaginatorType } from '@kelvininc/node-client-sdk';

import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';

export const getAssetRequester = (id: string): Promise<Asset> => {
	return AssetService.getAsset({ assetName: id })
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};

export const getAssetsRequester = (): Promise<Asset[]> =>
	AssetService.listAssets({ paginationType: EPaginatorType.Stream })
		.pipe(catchHttpError(throwHttpError))
		.toPromise();

export const getAssetTypesRequester = (): Promise<AssetType[]> =>
	AssetService.listAssetTypes({ paginationType: EPaginatorType.Stream })
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
