import { AgGridReact } from '@ag-grid-community/react';
import { useUpdate } from '@kelvininc/shared-ui';

import { IBaseTData } from '../../components/AgGridTable';
import { getPinnedData } from '../../components/AgGridTable/utils';

export const useRowHeightSync = <TData extends IBaseTData>(
	table: AgGridReact<TData> | null,
	rowHeight: number
) => {
	useUpdate(() => {
		if (table?.api) {
			const { api } = table;

			api.forEachNode((rowNode) => rowNode.setRowHeight(rowHeight));
			api.onRowHeightChanged();
			api.updateGridOptions({ pinnedTopRowData: getPinnedData(api) });
		}
	}, [rowHeight]);
};
