import { EValidationStep, IValidationItem } from '@/src/types';

export const buildInitialRequirementsState = (
	finalRequirementsState: Record<string, IValidationItem[]>
): Record<string, IValidationItem[]> => {
	return Object.keys(finalRequirementsState).reduce<Record<string, IValidationItem[]>>(
		(acc, reqKey) => {
			acc[reqKey] = finalRequirementsState[reqKey].map((item) => ({
				...item,
				state: EValidationStep.Pending
			}));
			return acc;
		},
		{}
	);
};
