import { SuspenseFallback, withSuspense } from '@kelvininc/shared-ui';
import { keyBy } from 'lodash-es';
import { Controller } from 'react-hook-form';

import { RadioButtons } from '../../../RadioButtons';
import { useGuardrailCraftWizardFormContext } from '../../hooks';

import { useAssetAvailableDatastreams } from '../../hooks/useAssetAvailableDatastreams';
import { GuardrailValuesForm } from '../GuardrailValuesForm';

import { CONTROL_RADIO_BUTTONS } from './config';
import styles from './styles.module.scss';

const Component = () => {
	const form = useGuardrailCraftWizardFormContext();
	const { asset, datastream } = form.getValues();
	const availableDatastreams = useAssetAvailableDatastreams(asset, datastream);
	const datastreamsMap = keyBy(availableDatastreams, 'name');

	const { control } = form;

	return (
		<div className={styles.GuardrailValuesStep}>
			<div className={styles.ControlField}>
				<Controller
					name="control"
					control={control}
					render={({ field: { value, onChange } }) => (
						<RadioButtons
							label="Control"
							value={value}
							buttons={CONTROL_RADIO_BUTTONS}
							onChange={onChange}
						/>
					)}
				/>
			</div>
			<div className={styles.GuardrailValuesForm}>
				<GuardrailValuesForm datastreamsMap={datastreamsMap} form={form} />
			</div>
		</div>
	);
};

export const GuardrailValuesStep = withSuspense(Component, <SuspenseFallback />);
