import { Asset, AssetType } from '@kelvininc/node-client-sdk';
import { atom } from 'recoil';

import { syncAssetTypeEffect, syncAssetsEffect } from './effects';

export const assetsListAtom = atom<Asset[]>({
	key: 'assetsListAtom',
	effects: [syncAssetsEffect]
});

export const assetTypesListAtom = atom<AssetType[]>({
	key: 'assetTypesListAtom',
	effects: [syncAssetTypeEffect]
});
