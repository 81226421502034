import { PropsWithForwardedRef } from '@kelvininc/types';
import { ForwardedRef, forwardRef, useImperativeHandle } from 'react';

import { IBaseTData } from '../../../AgGridTable';
import { useApiRef } from '../../contexts/TableApiRefContext';

import { ITableApi } from '../../types';

const Component = <TData extends IBaseTData>({
	forwardedRef
}: PropsWithForwardedRef<{}, ITableApi<TData>>) => {
	const apiRef = useApiRef();

	useImperativeHandle(forwardedRef, () => ({
		refreshDatasource: () => apiRef.current?.refreshDatasource(),
		setPinnedRows: (rows: TData[]) => apiRef.current?.setPinnedRows(rows),
		clearPinnedRows: () => apiRef.current?.clearPinnedRows(),
		setSelectedRows: (rows: TData[]) => apiRef.current?.setSelectedRows(rows),
		clearSelectedRows: () => apiRef.current?.clearSelectedRows()
	}));

	return null;
};

export const TableApi = forwardRef(function TableApiWithRef<TData extends IBaseTData>(
	_props: {},
	ref: ForwardedRef<ITableApi<TData>>
) {
	return <Component forwardedRef={ref} />;
}) as <TData extends IBaseTData>(
	props: {} & { ref?: ForwardedRef<ITableApi<TData>> }
) => ReturnType<typeof Component<TData>>;
