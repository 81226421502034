import { SidepanelProvider } from '@kelvininc/shared-ui';
import { FunctionComponent, PropsWithForwardedRef } from '@kelvininc/types';
import { PropsWithChildren } from 'react';

import { IBaseTData } from '../../../AgGridTable';
import {
	DEFAULT_PAGE_SIZE,
	DEFAULT_TABLE_DENSITY,
	DEFAULT_TABLE_DENSITY_OPTIONS
} from '../../config';
import { TableApiRefProvider } from '../../contexts/TableApiRefContext';
import { TableDensityProvider } from '../../contexts/TableDensityContext';
import { TableParamsProvider } from '../../contexts/TableParamsContext';
import { TableResetProvider } from '../../contexts/TableResetContext';
import { TableSelectionProvider } from '../../contexts/TableSelectionContext';
import { TableStateProvider } from '../../contexts/TableStateContext';

import { ITableApi, TableProps } from '../../types';

export const TableProviders = <TData extends IBaseTData>(
	props: PropsWithChildren<TableProps<TData>>
) => {
	const {
		searchInitial,
		filtersInitial,
		onFiltersChange,
		advancedFiltersInitial,
		onAdvancedFiltersChange,
		densityOptions = DEFAULT_TABLE_DENSITY_OPTIONS,
		densityInitial = DEFAULT_TABLE_DENSITY,
		densityDisabledOptions,
		onDensityChange,
		pageInitial = 1,
		pageSizeInitial = DEFAULT_PAGE_SIZE,
		onPaginationChange,
		onPaginationTotalChange,
		sortByInitial,
		sortDirectionInitial,
		onSortChange,
		checkboxSelectable = false,
		rowSelectable = false,
		selectedRowsInitial,
		selectionActions,
		onResetSettings,
		children
	} = props;

	const resetProps = {
		onResetSettings
	};
	const densityProps = {
		densityInitial,
		densityOptions,
		densityDisabledOptions,
		onDensityChange
	};
	const paramsProps = {
		advancedFiltersInitial,
		filtersInitial,
		searchInitial,
		pageInitial,
		pageSizeInitial,
		sortByInitial,
		sortDirectionInitial,
		onFiltersChange,
		onAdvancedFiltersChange,
		onPaginationChange,
		onPaginationTotalChange,
		onSortChange
	};
	const selectionProps = {
		checkboxSelectable,
		rowSelectable,
		selectedRowsInitial,
		selectionActions
	};

	return (
		<TableResetProvider {...resetProps}>
			<TableApiRefProvider>
				<TableParamsProvider {...paramsProps}>
					<TableDensityProvider {...densityProps}>
						<TableSelectionProvider {...selectionProps}>
							<TableStateProvider>
								<SidepanelProvider>{children}</SidepanelProvider>
							</TableStateProvider>
						</TableSelectionProvider>
					</TableDensityProvider>
				</TableParamsProvider>
			</TableApiRefProvider>
		</TableResetProvider>
	);
};

export const withTableProviders = <TData extends IBaseTData>(
	Component: FunctionComponent<TableProps<TData>>
) => {
	return function TableProvidersWrapper(
		componentProps: PropsWithForwardedRef<TableProps<TData>, ITableApi<TData>>
	) {
		return (
			<TableProviders {...componentProps}>
				<Component {...componentProps} />
			</TableProviders>
		);
	};
};
