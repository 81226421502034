import { EValidationState } from '@kelvininc/react-ui-components';
import { isEmpty } from 'lodash-es';

import { ValidatedTextFieldMessages } from './types';

export const validateNameId = (
	value: string,
	errorMessages?: ValidatedTextFieldMessages,
	regex?: string,
	existingNames?: string[],
	ignoreValidation?: boolean
): {
	state: EValidationState;
	helpText?: string;
} => {
	if (ignoreValidation || !value || (!regex && isEmpty(existingNames))) {
		return {
			state: EValidationState.None
		};
	}
	if (regex && !value.match(regex)) {
		return {
			state: EValidationState.Invalid,
			helpText: errorMessages?.notMatchRegex
		};
	}

	if (existingNames && existingNames.includes(value)) {
		return {
			state: EValidationState.Invalid,
			helpText: errorMessages?.duplicatedValue
		};
	}
	return {
		state: EValidationState.None
	};
};
