import { useUpdate } from '@kelvininc/shared-ui';
import { FunctionComponent, ReactState } from '@kelvininc/types';
import { Context, PropsWithChildren, createContext, useContext, useState } from 'react';

import { EDensityLevel } from '../../types';

import { ITableDensityContextValues } from './types';

import { getRowHeight } from './utils';

const TableDensityContext: Context<null | ITableDensityContextValues> =
	createContext<null | ITableDensityContextValues>(null);

export type TableDensityProviderProps = {
	densityInitial?: EDensityLevel;
	densityOptions?: Partial<Record<EDensityLevel, number>>;
	densityDisabledOptions?: Record<string, boolean>;
	onDensityChange?: (newDensity: EDensityLevel | undefined) => void;
};

export const TableDensityProvider = ({
	children,
	densityOptions,
	densityDisabledOptions,
	densityInitial,
	onDensityChange: onDensityChangeCallback
}: PropsWithChildren<TableDensityProviderProps>) => {
	const [level, setLevel] = useState<EDensityLevel | undefined>(densityInitial);

	useUpdate(() => {
		onDensityChangeCallback?.(level);
	}, [level]);

	return (
		<TableDensityContext.Provider
			value={{
				level: [level, setLevel],
				options: densityOptions,
				disabledOptions: densityDisabledOptions
			}}>
			{children}
		</TableDensityContext.Provider>
	);
};

export function useDensityContext(): ITableDensityContextValues {
	const context = useContext(TableDensityContext);

	if (!context) {
		throw new Error('Missing table density context');
	}

	return context;
}

export const useDensityLevel = (): ReactState<EDensityLevel | undefined> => {
	const { level } = useDensityContext();

	return level;
};

export const useDensityOptions = (): Partial<Record<EDensityLevel, number>> | undefined => {
	const { options } = useDensityContext();

	return options;
};

export const useDisabledOptions = (): Record<string, boolean> | undefined => {
	const { disabledOptions } = useDensityContext();

	return disabledOptions;
};

export const useRowHeight = (): number | undefined => {
	const [level] = useDensityLevel();
	const options = useDensityOptions();

	return getRowHeight(level, options);
};

export const withDensityProvider = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>,
	providerProps: TableDensityProviderProps
) => {
	return function TableDensityProviderWrapper(componentProps: PropsWithChildren<ComponentProps>) {
		return (
			<TableDensityProvider {...providerProps}>
				<Component {...componentProps} />
			</TableDensityProvider>
		);
	};
};
