import { EIconName, KvIcon } from '@kelvininc/react-ui-components';
import {
	Expandable,
	ExpandableProvider,
	IExpandableApi,
	useExpandable
} from '@kelvininc/shared-ui';
import classNames from 'classnames';

import { ForwardedRef, PropsWithChildren, forwardRef, useCallback } from 'react';

import styles from './styles.module.scss';

type ColumnFormSectionExpandableActionProps = {
	label?: string;
	disabled?: boolean;
	defaultState?: boolean;
};

const ColumnFormSectionExpandableAction = ({
	label,
	disabled = false
}: ColumnFormSectionExpandableActionProps) => {
	const { isExpanded, toggle } = useExpandable();

	const useToggle = useCallback(() => !disabled && toggle(), [disabled, toggle]);

	return (
		<div
			className={classNames(styles.Label, {
				[styles.LabelDisabled]: disabled,
				[styles.LabelExpanded]: isExpanded
			})}
			onClick={useToggle}>
			<span>{label}</span>
			<KvIcon name={EIconName.Expand} />
		</div>
	);
};

export const ColumnFormSection = forwardRef(function ColumnFormSection(
	{
		children,
		defaultState = false,
		...otherProps
	}: PropsWithChildren<ColumnFormSectionExpandableActionProps>,
	ref: ForwardedRef<IExpandableApi>
) {
	return (
		<ExpandableProvider defaultState={defaultState}>
			<Expandable.Forward
				overflowOnExpanded
				ExpandableAction={<ColumnFormSectionExpandableAction {...otherProps} />}
				ref={ref}>
				<div className={styles.ColumnFormContent}>{children}</div>
			</Expandable.Forward>
		</ExpandableProvider>
	);
});
