import { EColumnDataType } from '../../../core/components/AgGridTable/types';

export const SORTING_ASCENDING_LABEL_SUFFIXS: Record<EColumnDataType, string> = {
	[EColumnDataType.Text]: 'A ➞ Z',
	[EColumnDataType.Numeric]: 'in ascending order',
	[EColumnDataType.Time]: 'oldest ➞ newest'
};

export const SORTING_DESCENDING_LABEL_SUFFIXS: Record<EColumnDataType, string> = {
	[EColumnDataType.Text]: 'Z ➞ A',
	[EColumnDataType.Numeric]: 'in descending order',
	[EColumnDataType.Time]: 'newest ➞ oldest'
};

export const UNSORTED_LABEL_PREFIX = 'Sort';
export const SORTED_LABEL_PREFIX = 'Sorted';
