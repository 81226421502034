import {
	EKrnResource,
	EPaginatorType,
	Guardrail,
	GuardrailsService,
	KvKRNParser
} from '@kelvininc/node-client-sdk';

import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { atomFamily } from 'recoil';

import { getRecoilKey } from './utils';

export const assetGuardrailsAtomFamily = atomFamily<Guardrail[], string>({
	key: getRecoilKey('assetGuardrailsAtomFamily'),
	effects: (assetName) => [
		({ setSelf }) => {
			setSelf(
				(async () => {
					// TODO: Change this to filter by the asset through the API
					const guardrails = await GuardrailsService.listGuardrails({
						guardrailsListData: {},
						paginationType: EPaginatorType.Stream
					})
						.pipe(catchHttpError(throwHttpError))
						.toPromise();

					return guardrails.filter((guardrail) => {
						const {
							resourceContent: { asset: resourceAsset }
						} = KvKRNParser.parseKRN<EKrnResource.AssetDatastream>(
							guardrail.resource,
							EKrnResource.AssetDatastream
						);

						return resourceAsset === assetName;
					});
				})()
			);
		}
	]
});
