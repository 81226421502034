import { App, AppItem, AppRegistryService, EPaginatorType } from '@kelvininc/node-client-sdk';

import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';

export const getAppRequester = (id: string): Promise<App> => {
	return AppRegistryService.getAppRegistryApp({ appName: id })
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};

export const getAppRegistryAppsListRequester = (): Promise<AppItem[]> =>
	AppRegistryService.listAppRegistryApps({
		paginationType: EPaginatorType.Stream
	})
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
