import { buildRelativeTimeRangeDropdownOptions } from '@kelvininc/tsutils';
import { ERelativeTimeRangeKey, IUIDropdownOptions } from '@kelvininc/types';

export const RECOMMENDATION_COLUMN_APPLICATION_LABEL = 'SmartApp';
export const RECOMMENDATION_COLUMN_APPLICATION_PLACEHOLDER = 'Select an option';
export const RECOMMENDATION_COLUMN_RECOMMENDATION_TYPE_LABEL = 'Recommendation Types';
export const RECOMMENDATION_COLUMN_RECOMMENDATION_TYPE_PLACEHOLDER = 'Select an option';
export const RECOMMENDATION_COLUMN_STATUSES_LABEL = 'Status';
export const RECOMMENDATION_COLUMN_STATUSES_PLACEHOLDER = 'Select an option';
export const RECOMMENDATION_COLUMN_RANGE_COLUMN_LABEL = 'Range';
export const RECOMMENDATION_COLUMN_RANGE_COLUMN_PLACEHOLDER = 'Select an option';

const RECOMMENDATION_COLUMN_TIME_RANGE_OPTIONS: ERelativeTimeRangeKey[] = [
	ERelativeTimeRangeKey.Last_5_M,
	ERelativeTimeRangeKey.Last_10_M,
	ERelativeTimeRangeKey.Last_15_M,
	ERelativeTimeRangeKey.Last_30_M,
	ERelativeTimeRangeKey.Last_1_H,
	ERelativeTimeRangeKey.Last_12_H,
	ERelativeTimeRangeKey.Last_24_H,
	ERelativeTimeRangeKey.Last_48_H,
	ERelativeTimeRangeKey.Last_72_H,
	ERelativeTimeRangeKey.Last_7_D
];
export const RECOMMENDATION_COLUMN_TIME_RANGE_DROPDOWN_OPTIONS: IUIDropdownOptions =
	buildRelativeTimeRangeDropdownOptions(RECOMMENDATION_COLUMN_TIME_RANGE_OPTIONS);
