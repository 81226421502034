import { IRowNode } from '@ag-grid-community/core';

import { IBaseTData } from '../../../core/components/AgGridTable/types';

export const isRowPinned = <TData extends IBaseTData>(
	rowsPinned: TData[],
	getRowId: (data: TData) => string,
	node?: IRowNode<TData>
): boolean => {
	if (node?.data === undefined) {
		return false;
	}

	return rowsPinned.some((rowData) => node.data && getRowId(rowData) === getRowId(node.data));
};
