import { IOpenModalConfig } from '../../../Modal';
import { DeleteEntityModal } from '../../DeleteEntityModal';
import styles from '../../styles.module.scss';
import { IDeleteEntityModalProps } from '../../types';

export const buildDeleteModalConfig = (props: IDeleteEntityModalProps): IOpenModalConfig => ({
	ContentComponent: <DeleteEntityModal {...props} />,
	config: {
		customClass: styles.DeleteContainer
	}
});
