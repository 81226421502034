export const DATASTREAM_SCHEMA_FORM_PROPERTIES = ['timeRange', 'operator', 'value'] as const;

export const SELECT_AGGREGATION_PLACEHOLDER = 'Aggregation';

export const SELECT_OPERATOR_PLACEHOLDER = 'Operator';

export const SELECT_TIMERANGE_PLACEHOLDER = 'Time Range';

export const SETPOINT_SEMANTIC_TYPE_NAMES = ['state', 'flag'];

export const UPDATE_FILTER_DEBOUNCE_TIME = 500;

export const ALERT_INFO_MESSAGE =
	'Due to the large data volume and complex filters, loading results may take some time.';
