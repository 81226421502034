import { ISettingStorageConfig, useSetting } from '@kelvininc/shared-ui';
import {
	EDensityLevel,
	ETableSettingKey,
	IBaseTData,
	IColumnDef,
	IPaginationInfo,
	IRowsSettingsConfig,
	ISortInfo,
	ITableAdvancedFilters,
	ITableFilters,
	ITableSettings
} from '@kelvininc/table';
import { isFunction, merge, pick } from 'lodash-es';
import { SetStateAction, useCallback, useMemo } from 'react';

import { RecoilState } from 'recoil';

import { IUseTableSettings } from './types';
import {
	buildColumnsHidden,
	buildColumnsOrder,
	buildColumnsPinned,
	filterCustomColumns
} from './utils';

import { getTableSettingsSettingKey } from '@/src/recoil/settings/utils';

export const useTableSettings = <TData extends IBaseTData = IBaseTData, TSerialized = TData>(
	key: string,
	defaultValue: ITableSettings<TData> = {},
	recoilState: RecoilState<ITableSettings<TData>>,
	config: ISettingStorageConfig<ITableSettings<TData>, TSerialized> = {}
): IUseTableSettings<TData> => {
	const {
		value: setting = defaultValue,
		setValue: setSetting,
		resetValue
	} = useSetting<ITableSettings<TData>, TSerialized>(
		getTableSettingsSettingKey(key),
		defaultValue,
		recoilState,
		config
	);

	const settings = useMemo(
		() => pick(merge({}, defaultValue, setting), Object.values(ETableSettingKey)),
		[defaultValue, setting]
	);

	const setSettings = useCallback(
		(newValue: SetStateAction<ITableSettings<TData>>) =>
			setSetting((previousSettings) => {
				const newSettingValue = isFunction(newValue)
					? newValue(previousSettings)
					: newValue;

				return newSettingValue;
			}),
		[setSetting]
	);

	const setSettingsValue = useCallback(
		<SettingKey extends keyof ITableSettings<TData>>(
			settingKey: SettingKey,
			newValue: ITableSettings<TData>[SettingKey]
		) =>
			setSettings((previousSettings) => ({
				...previousSettings,
				[settingKey]: newValue
			})),
		[setSettings]
	);

	const onColumnsChange = useCallback(
		(newColumns: IColumnDef<TData>[]) =>
			setSettings((previousSettings) => ({
				...previousSettings,
				[ETableSettingKey.ColumnsOrder]: buildColumnsOrder(newColumns),
				[ETableSettingKey.ColumnsPinned]: buildColumnsPinned(newColumns),
				[ETableSettingKey.ColumnsHidden]: buildColumnsHidden(newColumns),
				[ETableSettingKey.ColumnsCustom]: filterCustomColumns(newColumns)
			})),
		[setSettings]
	);
	const onPaginationChange = useCallback(
		(newPageInfo: IPaginationInfo = {}) =>
			setSettingsValue(ETableSettingKey.PageSize, newPageInfo.pageSize),
		[setSettingsValue]
	);
	const onDensityChange = useCallback(
		(newDensity?: EDensityLevel) => setSettingsValue(ETableSettingKey.Density, newDensity),
		[setSettingsValue]
	);
	const onSortChange = useCallback(
		(newSort: ISortInfo = {}) =>
			setSettings((previousSettings) => ({
				...previousSettings,
				[ETableSettingKey.SortBy]: newSort.sortBy,
				[ETableSettingKey.SortDirection]: newSort.sortDirection
			})),
		[setSettings]
	);
	const onRowsSettingsChange = useCallback(
		(newRowsSettings: IRowsSettingsConfig) =>
			setSettingsValue(ETableSettingKey.RowsSettings, newRowsSettings),
		[setSettingsValue]
	);
	const onRowsPinnedChange = useCallback(
		(newRowsPinned: TData[]) => setSettingsValue(ETableSettingKey.RowsPinned, newRowsPinned),
		[setSettingsValue]
	);
	const onFiltersChange = useCallback(
		(newFilters?: ITableFilters) => setSettingsValue(ETableSettingKey.Filters, newFilters),
		[setSettingsValue]
	);
	const onAdvancedFiltersChange = useCallback(
		(newFilters?: ITableAdvancedFilters) =>
			setSettingsValue(ETableSettingKey.AdvancedFilters, newFilters),
		[setSettingsValue]
	);
	const onResetSettings = useCallback(() => resetValue(), [resetValue]);

	const callbacks = useMemo(
		() => ({
			onColumnsChange,
			onPaginationChange,
			onDensityChange,
			onSortChange,
			onRowsSettingsChange,
			onRowsPinnedChange,
			onFiltersChange,
			onAdvancedFiltersChange,
			onResetSettings
		}),
		[
			onColumnsChange,
			onPaginationChange,
			onDensityChange,
			onSortChange,
			onRowsSettingsChange,
			onRowsPinnedChange,
			onFiltersChange,
			onAdvancedFiltersChange,
			onResetSettings
		]
	);

	return {
		settings,
		callbacks
	};
};
