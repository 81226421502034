import { EValidationStep, IValidationItem } from '@/src/types';

export const VALIDATION_ITEMS_STRUCTURE_KEY = 'Structure:';
export const VALIDATION_ITEMS_CONTENT_KEY = 'Content:';
export const VALIDATION_ITEMS_SYSTEM_KEY = 'System:';

export const VALIDATION_ITEMS: Record<string, IValidationItem[]> = {
	[VALIDATION_ITEMS_STRUCTURE_KEY]: [
		{
			description: 'File has content',
			state: EValidationStep.Pending,
			isValidationBlocker: true
		},
		{
			description: 'All required columns',
			state: EValidationStep.Pending,
			isValidationBlocker: true
		},
		{
			description: 'Columns naming',
			state: EValidationStep.Pending,
			isValidationBlocker: true
		}
	],
	[VALIDATION_ITEMS_CONTENT_KEY]: [
		{
			description: 'All required fields',
			state: EValidationStep.Pending,
			isValidationBlocker: false
		},
		{
			description: 'All patterns match',
			state: EValidationStep.Pending,
			isValidationBlocker: false
		},
		{
			description: 'No duplicated content',
			state: EValidationStep.Pending,
			isValidationBlocker: false
		},
		{
			description: 'All min and max values are valid',
			state: EValidationStep.Pending,
			isValidationBlocker: false
		}
	],
	[VALIDATION_ITEMS_SYSTEM_KEY]: [
		{
			description: 'No duplicated guardrails',
			state: EValidationStep.Pending,
			isValidationBlocker: false
		},
		{
			description: 'All assets exist in the system',
			state: EValidationStep.Pending,
			isValidationBlocker: false
		},
		{
			description: 'All datastreams exist in the system',
			state: EValidationStep.Pending,
			isValidationBlocker: false
		}
	]
};
