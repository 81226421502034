import {
	DataStream,
	GuardrailRelativeValue as KvGuardrailRelativeValue,
	Unit
} from '@kelvininc/node-client-sdk';

import { KvTooltip } from '@kelvininc/react-ui-components';

import { formatRelativeGuardrailValue } from '@kelvininc/tsutils';

import { GuardrailValue } from '../GuardrailValue';

import styles from './styles.module.scss';

export type GuardrailRelativeValueProps = {
	guardrail?: KvGuardrailRelativeValue;
	datastream?: DataStream;
	unit?: Unit;
};

export const GuardrailRelativeValue = ({
	guardrail,
	datastream,
	unit
}: GuardrailRelativeValueProps) => {
	if (!guardrail) {
		return <KvTooltip text="No value available to display">N/A</KvTooltip>;
	}

	const { value, inclusive, type } = guardrail;
	const label = formatRelativeGuardrailValue(type, value, datastream, unit);

	return (
		<div className={styles.GuardrailRelativeValue}>
			<GuardrailValue value={label} inclusive={inclusive} />
		</div>
	);
};
