import { EColumnPinAlignment, IBaseTData, IColumnDef, IColumnOptions } from '../../core';
import {
	COMMON_FEATURE_COLUMNS_DEF,
	SELECTION_COLUMN_ID,
	SELECTION_COLUMN_TITLE
} from '../../core/components/Table/config';
import { HeaderSelectionCellRenderer, SelectionCellRenderer } from '../../renderers';

export const getCheckboxSelectionColumnDef = <TData extends IBaseTData>(): IColumnDef<TData> => ({
	id: SELECTION_COLUMN_ID,
	title: SELECTION_COLUMN_TITLE,
	cellComponent: SelectionCellRenderer,
	headerComponent: HeaderSelectionCellRenderer,
	cellComponentParams: {
		displayEmptyState: false
	},
	...COMMON_FEATURE_COLUMNS_DEF
});

export const getNameColumnOptions = <TData extends IBaseTData>(): IColumnOptions<TData> => ({
	pinned: EColumnPinAlignment.Left,
	inalterable: true,
	lockPinned: true
});

export const getActionColumnOptions = <TData extends IBaseTData>(): IColumnOptions<TData> => ({
	headerComponent: () => null,
	...COMMON_FEATURE_COLUMNS_DEF,
	pinned: EColumnPinAlignment.Right
});
