import { EInputFieldType, EValidationState, KvTextField } from '@kelvininc/react-ui-components';

import { ComponentProps } from 'react';

import { transformStringToNumber } from '../../utils';
import { GuardrailRadioValueField } from '../GuardrailRadioValueField';

import { buildHelpText } from './utils';

type GuardrailNumberValueFieldProps = {
	placeholder: string;
	value: string | number | undefined;
	error?: string;
	inclusive?: boolean;
	onChangeValue: (value: string | number | undefined) => void;
	onBlur: () => void;
} & Omit<ComponentProps<typeof GuardrailRadioValueField>, 'label' | 'inclusive'>;

export const GuardrailNumberValueField = ({
	placeholder,
	inclusive = false,
	value,
	checked,
	disabled,
	error,
	onChangeValue,
	onBlur,
	...otherProps
}: GuardrailNumberValueFieldProps) => {
	const showError = !!error && checked && value !== undefined;

	const onChange = (newValue: string) => {
		try {
			onChangeValue(transformStringToNumber(newValue));
		} catch (error) {
			onChangeValue(newValue || undefined);
		}
	};

	return (
		<GuardrailRadioValueField
			label="A static value"
			inclusive={inclusive}
			checked={checked}
			disabled={disabled}
			{...otherProps}>
			<KvTextField
				type={EInputFieldType.Number}
				placeholder={placeholder}
				value={value}
				onTextChange={({ detail: newValue }) => onChange(newValue)}
				state={showError ? EValidationState.Invalid : undefined}
				helpText={showError ? error : buildHelpText(inclusive)}
				inputDisabled={!checked || disabled}
				onTextFieldBlur={() => onBlur()}
			/>
		</GuardrailRadioValueField>
	);
};
