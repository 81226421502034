import { EParametersScheduleExtraFieldDataScheduleType } from '@kelvininc/node-client-sdk';
import { buildRelativeTimeRangeDropdownOptions } from '@kelvininc/tsutils';
import { ERelativeTimeRangeKey, IUIDropdownOptions } from '@kelvininc/types';

export const SCHEDULE_TYPE_LABEL = 'Schedule Type';
export const SCHEDULE_TYPE_PLACEHOLDER = 'Select an option';
export const TIME_RANGE_LABEL = 'Time Range';
export const TIME_RANGE_PLACEHOLDER = 'Select an option';

const TIME_RANGE_OPTIONS: ERelativeTimeRangeKey[] = [
	ERelativeTimeRangeKey.Next_1_H,
	ERelativeTimeRangeKey.Next_12_H,
	ERelativeTimeRangeKey.Next_24_H,
	ERelativeTimeRangeKey.Next_48_H,
	ERelativeTimeRangeKey.Next_72_H,
	ERelativeTimeRangeKey.Next_7_D,
	ERelativeTimeRangeKey.Next_2_W,
	ERelativeTimeRangeKey.Next_1_M
];
export const TIME_RANGE_DROPDOWN_OPTIONS: IUIDropdownOptions =
	buildRelativeTimeRangeDropdownOptions(TIME_RANGE_OPTIONS);

export const SCHEDULE_TYPE_ALL_OPTION_VALUE = 'all';
export const SCHEDULE_TYPE_DROPDOWN_OPTIONS: IUIDropdownOptions = {
	[SCHEDULE_TYPE_ALL_OPTION_VALUE]: { label: 'All', value: SCHEDULE_TYPE_ALL_OPTION_VALUE },
	[EParametersScheduleExtraFieldDataScheduleType.Permanent]: {
		label: 'Change Once',
		value: EParametersScheduleExtraFieldDataScheduleType.Permanent
	},
	[EParametersScheduleExtraFieldDataScheduleType.Temporary]: {
		label: 'Change and Revert',
		value: EParametersScheduleExtraFieldDataScheduleType.Temporary
	}
};
