import {
	ECustomColumnType,
	ETableAdvancedFilterType,
	IAdvancedFiltersConfig
} from '@kelvininc/table';
import { selector } from 'recoil';

import { kelvinApplicationsMapSelector } from '@/src/recoil/app-registry/selectors';
import {
	applicationParameterDefinitionsMapSelector,
	applicationParameterValuesMapSelector
} from '@/src/recoil/application-parameters/selectors';
import {
	assetPropertyDefinitionsMapSelector,
	assetPropertyValuesMapSelector
} from '@/src/recoil/asset-properties/selectors';
import {
	datastreamsMapSelector,
	datastreamsUnitMapSelector,
	nativeDataStreamsMapSelector
} from '@/src/recoil/data-streams/selectors';
import { closedLoopInstanceSettingSelector } from '@/src/recoil/instance-settings/selectors';
import { recommendationTypesMapSelector } from '@/src/recoil/recommendations/selectors';
import { appUserPermissionsMapSelector } from '@/src/recoil/users/selectors';
import { IAssetInsightsCustomColumnsConfigMetadata } from '@/src/types/asset-insights';
import { filterAcceptedDatastreams } from '@/src/utils/asset-insights';

export const assetsListTableCustomColumnsConfigMetadataSelector =
	selector<IAssetInsightsCustomColumnsConfigMetadata>({
		key: 'assetsListTableCustomColumnsConfigMetadataSelector',
		get: ({ get }) => {
			const properties = get(assetPropertyDefinitionsMapSelector);
			const recommendationTypes = get(recommendationTypesMapSelector);
			const applications = get(kelvinApplicationsMapSelector);
			const datastreams = get(nativeDataStreamsMapSelector);
			const datastreamUnits = get(datastreamsUnitMapSelector);
			const parameters = get(applicationParameterDefinitionsMapSelector);
			const closedLoopSettings = get(closedLoopInstanceSettingSelector);
			const permissions = get(appUserPermissionsMapSelector);

			return {
				[ECustomColumnType.AssetProperty]: { properties },
				[ECustomColumnType.Datastream]: { datastreams, datastreamUnits },
				[ECustomColumnType.LastControlChange]: { datastreams, datastreamUnits },
				[ECustomColumnType.Recommendation]: {
					recommendationTypes,
					applications,
					closedLoopSettings,
					permissions
				},
				[ECustomColumnType.ApplicationParameter]: {
					parameters,
					applications,
					closedLoopSettings
				},
				[ECustomColumnType.ScheduleApplicationParameters]: {
					parameters,
					closedLoopSettings
				}
			};
		}
	});

export const assetsListTableAdvancedFiltersConfigSelector = selector<IAdvancedFiltersConfig>({
	key: 'assetsListTableAdvancedFiltersConfigSelector',
	get: ({ get }) => {
		const propertyDefinitions = get(assetPropertyDefinitionsMapSelector);
		const propertyValues = get(assetPropertyValuesMapSelector);
		const applications = get(kelvinApplicationsMapSelector);
		const parametersDefinitions = get(applicationParameterDefinitionsMapSelector);
		const parametersValues = get(applicationParameterValuesMapSelector);
		const closedLoopSettings = get(closedLoopInstanceSettingSelector);
		const datastreams = get(datastreamsMapSelector);

		return {
			[ETableAdvancedFilterType.AssetProperty]: {
				type: ETableAdvancedFilterType.AssetProperty,
				metadata: {
					properties: {
						definitions: propertyDefinitions,
						values: propertyValues
					}
				}
			},
			[ETableAdvancedFilterType.ApplicationParameter]: {
				type: ETableAdvancedFilterType.ApplicationParameter,
				metadata: {
					applications: {
						definitions: applications,
						parameters: parametersDefinitions,
						values: parametersValues
					},
					closedLoopSettings
				}
			},
			[ETableAdvancedFilterType.Datastream]: {
				type: ETableAdvancedFilterType.Datastream,
				metadata: {
					datastreams: {
						definitions: filterAcceptedDatastreams(datastreams)
					}
				}
			}
		};
	}
});
