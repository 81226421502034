import { BridgeService, WorkloadService } from '@kelvininc/node-client-sdk';

import { mapTo } from 'rxjs/operators';

import { AppDeploymentService } from './AppDeploymentService';
import { AppNameParameter, IAppDeploymentActionProps, IBridgeAppParameters } from './types';
import { buildIDeployBridgeData } from './utils';

export class BridgeAppDeployment implements AppDeploymentService {
	deploy({ parameters }: IAppDeploymentActionProps<IBridgeAppParameters>): Promise<boolean> {
		return BridgeService.deployBridge({
			bridgeDeployData: buildIDeployBridgeData(parameters)
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	start({ parameters }: IAppDeploymentActionProps<AppNameParameter>): Promise<boolean> {
		return BridgeService.startBridge({
			bridgeName: parameters.name
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	stop({ parameters }: IAppDeploymentActionProps<AppNameParameter>): Promise<boolean> {
		return BridgeService.stopBridge({
			bridgeName: parameters.name
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	apply({ parameters }: IAppDeploymentActionProps<AppNameParameter>): Promise<boolean> {
		return WorkloadService.applyWorkload({
			workloadApplyData: {
				workload_names: [parameters.name]
			}
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	undeploy({ parameters }: IAppDeploymentActionProps<AppNameParameter>): Promise<boolean> {
		return BridgeService.deleteBridge({
			bridgeName: parameters.name
		})
			.pipe(mapTo(true))
			.toPromise();
	}
}
