import { AssetType } from '@kelvininc/node-client-sdk';

export const getListSettingsSettingKey = (key: string): string => `${key}.list.settings`;
export const getTableSettingsSettingKey = (key: string): string => `${key}.table.settings`;
export const getTablesSettingsSettingKey = (key: string): string => `${key}.tables.settings`;

export const isAssetTypeSettingValid = (
	setting: string | undefined,
	assetTypes: AssetType[]
): boolean => {
	return !setting || assetTypes.some(({ name }) => name === setting);
};
