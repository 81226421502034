'use client';

import { BreadcrumbItem, Breadcrumbs } from '@kelvininc/shared-ui';

type EntityModelTopbarProps = {
	items: BreadcrumbItem[];
};

export const EntityModelTopbar = ({ items }: EntityModelTopbarProps) => {
	return <Breadcrumbs items={items} />;
};
