import { EPaginatorType, WorkloadItem, WorkloadService } from '@kelvininc/node-client-sdk';
import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { atom } from 'recoil';

export const preparingWorkloadImagesAtom = atom<WorkloadItem[]>({
	key: 'preparingWorkloadImagesAtom'
});

export const workloadListAtom = atom<WorkloadItem[]>({
	key: 'workloadListAtom',
	effects: [
		({ setSelf }) => {
			setSelf(
				WorkloadService.listWorkloads({
					paginationType: EPaginatorType.Stream
				})
					.pipe(catchHttpError(throwHttpError))
					.toPromise()
			);
		}
	]
});
