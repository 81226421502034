import { useRecoilAtomPolling } from '@kelvininc/shared-ui';

import { useMemo } from 'react';

import { refreshAssetParametersSchedulesCallback } from '../../recoil/callbacks';

export const useAssetsParametersSchedulesPolling = (assetName: string): void => {
	const args = useMemo<[string]>(() => [assetName], [assetName]);

	return useRecoilAtomPolling(args, refreshAssetParametersSchedulesCallback);
};
