import {
	AppItem,
	EDataType,
	EParameterType,
	EPropertyType,
	Property
} from '@kelvininc/node-client-sdk';
import {
	convertToParameterPrimitiveType,
	convertToPropertyPrimitiveType,
	isParameterPrimitiveTypeNumber,
	isPropertyPrimitiveTypeNumber
} from '@kelvininc/tsutils';
import {
	EInlineFilterType,
	IApplicationParameterDefinition,
	IApplicationParameterValues,
	IClosedLoopSettings,
	IInlineFilterConfig
} from '@kelvininc/types';
import { get, isArray, isEmpty, isNil } from 'lodash-es';

import {
	ETableAdvancedFilterType,
	IApplicationParameterAdvancedFilterConfigMetadata,
	IApplicationParameterTableAdvancedFilter,
	IAssetPropertyAdvancedFilterConfigMetadata,
	IAssetPropertyTableAdvancedFilter,
	IDatastreamAdvancedFilterConfigMetadata,
	IDatastreamTableAdvancedFilter,
	ITableFiltersSchema,
	TableFilterValue
} from '../../core';

import { DraftAdvancedFilter } from '../../core/components/Table/contexts/TableAdvancedFiltersContext/types';

import { DATASTREAM_TIME_RANGES_FILTER } from './config';
import { buildEntityFilter, buildParameterFilter, buildPropertyFilter } from './utils';

export const RELATIONAL_OPERATORS_OPTIONS = [
	{
		const: '>'
	},
	{
		const: '>='
	},
	{
		const: '<'
	},
	{
		const: '<='
	},
	{
		const: '=='
	},
	{
		const: '!='
	}
];

export const EXISTENCE_OPERATORS_OPTIONS = [
	{
		title: 'is empty',
		const: '!has'
	},
	{
		title: 'is not empty',
		const: 'has'
	}
];

export const EXISTENCE_OPERATORS_OPERATORS = EXISTENCE_OPERATORS_OPTIONS.map(
	({ const: name }) => name
);

export const STRING_OPERATORS_OPTIONS = [
	{ title: 'contains', const: 'contains' },
	{ title: 'does not contain', const: '!contains' },
	{ title: 'starts with', const: 'starts_with' },
	{ title: 'ends with', const: 'ends_with' }
];

export const EQUALITY_OPTIONS = [
	{
		title: 'is',
		const: '=='
	},
	{
		title: 'is not',
		const: '!='
	}
];

export const EQUALITY_OPERATORS = EQUALITY_OPTIONS.map(({ const: name }) => name);

export const RELATIONAL_OPERATORS: string[] = [
	...RELATIONAL_OPERATORS_OPTIONS.map(({ const: name }) => name),
	...STRING_OPERATORS_OPTIONS.map(({ const: name }) => name)
];

export const isRelationalOperator = (operator: string): boolean =>
	RELATIONAL_OPERATORS.includes(operator);

export const checkRelationalOperator = (operator: string, value?: TableFilterValue): boolean => {
	if (isRelationalOperator(operator)) {
		if (isArray(value)) {
			return !isEmpty(value);
		}

		return !isNil(value);
	}

	return true;
};

export const isValidAssetPropertyFilter = (
	filter: DraftAdvancedFilter<IAssetPropertyTableAdvancedFilter>,
	metadata: IAssetPropertyAdvancedFilterConfigMetadata
): filter is IAssetPropertyTableAdvancedFilter => {
	const { definitions } = metadata.properties;
	if (!filter.property || !filter.operator || !definitions[filter.property]) {
		return false;
	}

	return checkRelationalOperator(filter.operator, filter.value);
};

export const isValidApplicationParameterFilter = (
	filter: DraftAdvancedFilter<IApplicationParameterTableAdvancedFilter>,
	metadata: IApplicationParameterAdvancedFilterConfigMetadata
): filter is IApplicationParameterTableAdvancedFilter => {
	const { definitions, parameters } = metadata.applications;
	if (
		!filter.application ||
		!filter.parameter ||
		!filter.operator ||
		!definitions[filter.application] ||
		!parameters[filter.application]?.[filter.parameter]
	) {
		return false;
	}

	return checkRelationalOperator(filter.operator, filter.value);
};

export const isValidDatastreamFilter = (
	filter: DraftAdvancedFilter<IDatastreamTableAdvancedFilter>,
	metadata: IDatastreamAdvancedFilterConfigMetadata
): filter is IDatastreamTableAdvancedFilter => {
	const { definitions } = metadata.datastreams;
	if (
		!filter.datastream ||
		!filter.aggregation ||
		!filter.operator ||
		!definitions[filter.datastream]
	) {
		return false;
	}

	if (filter.timeRange && !DATASTREAM_TIME_RANGES_FILTER.includes(filter.timeRange)) {
		return false;
	}

	return checkRelationalOperator(filter.operator, filter.value);
};

export const convertFilterToPrimitiveType = (
	value: unknown,
	type: ETableAdvancedFilterType,
	primitiveType: EParameterType | EPropertyType | EDataType
): TableFilterValue | undefined => {
	if (value === undefined) {
		return value;
	}

	switch (type) {
		case ETableAdvancedFilterType.AssetProperty:
			if (isArray(value)) {
				return value.map((item) =>
					convertToPropertyPrimitiveType(item, primitiveType as EPropertyType)
				) as TableFilterValue;
			}

			return convertToPropertyPrimitiveType(value, primitiveType as EPropertyType);

		case ETableAdvancedFilterType.ApplicationParameter:
			if (isArray(value)) {
				return value.map((item) =>
					convertToParameterPrimitiveType(item, primitiveType as EParameterType)
				) as TableFilterValue;
			}

			return convertToParameterPrimitiveType(value, primitiveType as EParameterType);
	}
};

export const buildInlineFilterKey = (type: EInlineFilterType, name: string, appName?: string) => {
	return !isEmpty(appName) ? `${appName}@${name}@${type}` : `${name}@${type}`;
};

export const getInlineFilterKey = (filterKey: string) => {
	const [type, name, appName] = filterKey.split('@').reverse();

	return { type, name, appName };
};

export const getInlineFilterSchema = ({
	config,
	propertiesDefinitions,
	propertiesValues,
	parametersDefinitions,
	parametersValues,
	closedLoopSettings,
	applications
}: {
	config: IInlineFilterConfig;
	propertiesDefinitions: Record<string, Property>;
	propertiesValues: Record<string, string[] | boolean[]>;
	parametersDefinitions: IApplicationParameterDefinition;
	parametersValues: IApplicationParameterValues;
	closedLoopSettings: IClosedLoopSettings;
	applications: Record<string, AppItem>;
}): ITableFiltersSchema => {
	if (config.type === EInlineFilterType.Entity) {
		return buildEntityFilter(config);
	}

	if (
		config.type === EInlineFilterType.Property &&
		propertiesDefinitions[config.name] &&
		!isPropertyPrimitiveTypeNumber(propertiesDefinitions[config.name].primitiveType)
	) {
		return buildPropertyFilter(
			config,
			propertiesDefinitions[config.name],
			propertiesValues[config.name]
		);
	}

	if (
		config.type === EInlineFilterType.Parameter &&
		config.application &&
		get(parametersDefinitions, [config.application, config.name]) &&
		!isParameterPrimitiveTypeNumber(
			get(parametersDefinitions, [config.application, config.name]).primitiveType
		)
	) {
		return buildParameterFilter(
			config,
			get(parametersDefinitions, [config.application, config.name]),
			get(parametersValues, [config.application, config.name]),
			closedLoopSettings,
			applications
		);
	}

	return {
		schema: {},
		uiSchema: {}
	};
};
