import { AssetInsightsItem, Guardrail } from '@kelvininc/node-client-sdk';
import {
	IListSettings,
	getInstanceSetting,
	getSetting,
	syncSettingInitialValue
} from '@kelvininc/shared-ui';
import { IBaseTData, ITableSettings, ITablesSettings } from '@kelvininc/table';
import { atom, atomFamily } from 'recoil';

import { assetTypesListAtom } from '../assets/atoms';

import {
	ASSETS_LIST_ASSET_TYPE_SETTING_DEFAULT,
	ASSETS_LIST_ASSET_TYPE_SETTING_KEY,
	ASSET_INSIGHTS_TABLES_SETTINGS_SETTING_DEFAULT,
	ASSET_INSIGHTS_TABLE_SETTINGS_SETTING_DEFAULT,
	LIST_SETTINGS_SETTING_DEFAULT,
	OPERATIONS_OVERVIEW_ENABLED_SETTING_DEFAULT,
	OPERATIONS_OVERVIEW_ENABLED_SETTING_KEY,
	TABLES_SETTINGS_SETTING_DEFAULT,
	TABLE_SETTINGS_SETTING_DEFAULT
} from './config';

import {
	syncAppAssetsListTablesSettingsSettingInitialValue,
	syncAssetsListTableSettingsSettingInitialValue,
	syncGuardrailsListTableSettingsSettingInitialValue
} from './effects';

import {
	getListSettingsSettingKey,
	getTableSettingsSettingKey,
	getTablesSettingsSettingKey,
	isAssetTypeSettingValid
} from './utils';

import { deserializeAssetInsightsTableSettings } from '@/src/hooks/useAssetInsightsTableSettings/utils';
import { deserializeAssetInsightsTablesSettings } from '@/src/hooks/useAssetInsightsTablesSettings/utils';

export const assetsListAssetTypeSettingAtom = atom<string | undefined>({
	key: 'assetsListAssetTypeSettingAtom',
	effects: [
		({ setSelf, getPromise }) =>
			setSelf(
				(async () => {
					const assetTypes = await getPromise(assetTypesListAtom);

					return getSetting(
						ASSETS_LIST_ASSET_TYPE_SETTING_KEY,
						ASSETS_LIST_ASSET_TYPE_SETTING_DEFAULT,
						{ validator: (value) => isAssetTypeSettingValid(value, assetTypes) }
					);
				})()
			)
	]
});

export const tableSettingsSettingAtomFamily = atomFamily<
	ITableSettings<IBaseTData> | undefined,
	string
>({
	key: 'tableSettingsSettingAtomFamily',
	effects: (settingKey) => [
		syncSettingInitialValue(
			getTableSettingsSettingKey(settingKey),
			TABLE_SETTINGS_SETTING_DEFAULT
		)
	]
});

export const listSettingsSettingAtomFamily = atomFamily<IListSettings | undefined, string>({
	key: 'listSettingsSettingAtomFamily',
	effects: (settingKey) => [
		syncSettingInitialValue(
			getListSettingsSettingKey(settingKey),
			LIST_SETTINGS_SETTING_DEFAULT
		)
	]
});
export const tablesSettingsSettingAtomFamily = atomFamily<ITablesSettings<IBaseTData>, string>({
	key: 'tablesSettingsSettingAtomFamily',
	effects: (settingKey) => [
		syncSettingInitialValue(
			getTablesSettingsSettingKey(settingKey),
			TABLES_SETTINGS_SETTING_DEFAULT
		)
	]
});

export const assetInsightsTableSettingsSettingAtomFamily = atomFamily<
	ITableSettings<AssetInsightsItem> | undefined,
	string
>({
	key: 'assetInsightsTableSettingsSettingAtomFamily',
	effects: (settingKey) => [
		syncSettingInitialValue(
			getTableSettingsSettingKey(settingKey),
			ASSET_INSIGHTS_TABLE_SETTINGS_SETTING_DEFAULT,
			{ deserializer: deserializeAssetInsightsTableSettings }
		)
	]
});

export const assetInsightsTablesSettingsSettingAtomFamily = atomFamily<
	ITablesSettings<AssetInsightsItem>,
	string
>({
	key: 'assetInsightsTableSettingsSettingAtomFamily',
	effects: (settingKey) => [
		syncSettingInitialValue(
			getTablesSettingsSettingKey(settingKey),
			ASSET_INSIGHTS_TABLES_SETTINGS_SETTING_DEFAULT,
			{ deserializer: deserializeAssetInsightsTablesSettings }
		)
	]
});

export const assetsListTableSettingsSettingAtomFamily = atomFamily<
	ITableSettings<AssetInsightsItem> | undefined,
	string
>({
	key: 'assetsListTableSettingsAtomFamily',
	effects: (settingKey) => [
		syncAssetsListTableSettingsSettingInitialValue(getTableSettingsSettingKey(settingKey))
	]
});

export const appAssetsListTablesSettingsSettingAtomFamily = atomFamily<
	ITablesSettings<AssetInsightsItem>,
	string
>({
	key: 'appAssetsListTablesSettingsSettingAtomFamily',
	effects: (settingKey) => [
		syncAppAssetsListTablesSettingsSettingInitialValue(getTablesSettingsSettingKey(settingKey))
	]
});

export const operationsOverviewEnableSettingAtom = atom<{ enabled: boolean }>({
	key: 'operationsOverviewEnableSettingAtom',
	effects: [
		({ setSelf }) =>
			setSelf(
				getInstanceSetting(
					OPERATIONS_OVERVIEW_ENABLED_SETTING_KEY,
					OPERATIONS_OVERVIEW_ENABLED_SETTING_DEFAULT
				)
			)
	]
});

export const guardrailsListTableSettingsSettingAtomFamily = atomFamily<
	ITableSettings<Guardrail>,
	string
>({
	key: 'guardrailsListTableSettingsSettingAtomFamily',
	effects: (settingKey) => [
		syncGuardrailsListTableSettingsSettingInitialValue(getTableSettingsSettingKey(settingKey))
	]
});
