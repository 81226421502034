import { IBaseTData, ITableSettings } from '@kelvininc/table';
import { RecoilState } from 'recoil';

import { IUseTableSettings, useTableSettings } from '../useTableSettings';

import { getAdminTableSettingKey } from './utils';

import { tableSettingsSettingAtomFamily } from '@/src/recoil/settings/atoms';

export const useAdminTableSettings = <TData extends IBaseTData = IBaseTData>(
	tableId: string,
	defaultValue: ITableSettings<TData> = {}
): IUseTableSettings<TData> => {
	const atom = tableSettingsSettingAtomFamily(
		getAdminTableSettingKey(tableId)
	) as unknown as RecoilState<ITableSettings<TData>>;

	return useTableSettings(getAdminTableSettingKey(tableId), defaultValue, atom);
};
