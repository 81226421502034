import { merge } from 'lodash-es';

import { CSSProperties } from 'react';

import {
	ECustomColumnType,
	IApplicationParameterColumnData,
	IAssetPropertyColumnData,
	IBaseTData,
	IColumnDef,
	IColumnOptions,
	IDatastreamColumnData,
	ILastControlChangeColumnData,
	IRecommendationColumnData,
	IScheduleApplicationParametersColumnData
} from '../../../AgGridTable';
import { EManagementPanel, ICustomColumnsConfig, IPanelConfig, IPanelsConfig } from '../../types';

import { DEFAULT_PANELS_CONFIG, SIDEPANEL_WIDTH_IN_PX } from './config';

export const getPanelConfig = (
	panel: EManagementPanel,
	panelConfigs?: IPanelsConfig
): IPanelConfig => {
	const defaultPanelConfig = DEFAULT_PANELS_CONFIG[panel];

	const { [panel]: panelConfig } = panelConfigs ?? { [panel]: defaultPanelConfig };

	const { title, description, tooltip } = merge({}, defaultPanelConfig, panelConfig);

	return { title, description, tooltip };
};

export const validateData = <TData extends IBaseTData>(
	columnType: ECustomColumnType | undefined,
	config: ICustomColumnsConfig<TData>,
	assetPropertyData: IAssetPropertyColumnData,
	datastreamData: IDatastreamColumnData,
	lastControlChangeData: ILastControlChangeColumnData,
	recommendationData: IRecommendationColumnData,
	applicationParameterData: IApplicationParameterColumnData,
	scheduleApplicationParametersData: IScheduleApplicationParametersColumnData
): boolean => {
	if (!columnType) {
		return false;
	}

	const columnConfig = config[columnType];
	if (!columnConfig) {
		return false;
	}

	const { type } = columnConfig;

	if (type === ECustomColumnType.AssetProperty) {
		if (!assetPropertyData) {
			return false;
		}

		const { validator, metadata } = columnConfig;
		return validator?.(assetPropertyData, metadata) ?? true;
	}

	if (type === ECustomColumnType.Datastream) {
		if (!datastreamData) {
			return false;
		}

		const { validator, metadata } = columnConfig;
		return validator?.(datastreamData, metadata) ?? true;
	}

	if (type === ECustomColumnType.LastControlChange) {
		if (!lastControlChangeData) {
			return false;
		}

		const { validator, metadata } = columnConfig;
		return validator?.(lastControlChangeData, metadata) ?? true;
	}

	if (type === ECustomColumnType.Recommendation) {
		if (!recommendationData) {
			return false;
		}

		const { validator, metadata } = columnConfig;
		return validator?.(recommendationData, metadata) ?? true;
	}

	if (type === ECustomColumnType.ApplicationParameter) {
		if (!applicationParameterData) {
			return false;
		}

		const { validator, metadata } = columnConfig;
		return validator?.(applicationParameterData, metadata) ?? true;
	}

	if (type === ECustomColumnType.ScheduleApplicationParameters) {
		if (!scheduleApplicationParametersData) {
			return false;
		}

		const { validator, metadata } = columnConfig;
		return validator?.(scheduleApplicationParametersData, metadata) ?? true;
	}

	return false;
};

export const buildColumnDef = <TData extends IBaseTData>(
	columnType: ECustomColumnType | undefined,
	columnDefs: IColumnDef<TData>[],
	config: ICustomColumnsConfig<TData>,
	columnTitle: string | undefined,
	columnOptions: IColumnOptions<TData> = {},
	assetPropertyData: IAssetPropertyColumnData,
	datastreamData: IDatastreamColumnData,
	lastControlChangeData: ILastControlChangeColumnData,
	recommendationData: IRecommendationColumnData,
	applicationParameterData: IApplicationParameterColumnData,
	scheduleApplicationParametersData: IScheduleApplicationParametersColumnData
): IColumnDef<TData> | undefined => {
	if (!columnType) {
		return;
	}

	const columnConfig = config[columnType];
	if (!columnConfig) {
		return;
	}

	const { type } = columnConfig;

	if (type === ECustomColumnType.AssetProperty) {
		if (!assetPropertyData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, columnBuilder, metadata } = columnConfig;
		const id = columnIdBuilder(columnDefs, assetPropertyData, metadata);
		const title = columnTitle?.length
			? columnTitle
			: columnTitleBuilder?.(columnDefs, assetPropertyData, metadata) ?? id;

		return columnBuilder(id, title, assetPropertyData, metadata, columnOptions);
	}

	if (type === ECustomColumnType.Datastream) {
		if (!datastreamData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, columnBuilder, metadata } = columnConfig;
		const id = columnIdBuilder(columnDefs, datastreamData, metadata);
		const title = columnTitle?.length
			? columnTitle
			: columnTitleBuilder?.(columnDefs, datastreamData, metadata) ?? id;

		return columnBuilder(id, title, datastreamData, metadata, columnOptions);
	}

	if (type === ECustomColumnType.LastControlChange) {
		if (!lastControlChangeData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, columnBuilder, metadata } = columnConfig;
		const id = columnIdBuilder(columnDefs, lastControlChangeData, metadata);
		const title = columnTitle?.length
			? columnTitle
			: columnTitleBuilder?.(columnDefs, lastControlChangeData, metadata) ?? id;

		return columnBuilder(id, title, lastControlChangeData, metadata, columnOptions);
	}

	if (type === ECustomColumnType.Recommendation) {
		if (!recommendationData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, columnBuilder, metadata } = columnConfig;
		const id = columnIdBuilder(columnDefs, recommendationData, metadata);
		const title = columnTitle?.length
			? columnTitle
			: columnTitleBuilder?.(columnDefs, recommendationData, metadata) ?? id;

		return columnBuilder(id, title, recommendationData, metadata, columnOptions);
	}

	if (type === ECustomColumnType.ApplicationParameter) {
		if (!applicationParameterData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, columnBuilder, metadata } = columnConfig;
		const id = columnIdBuilder(columnDefs, applicationParameterData, metadata);
		const title = columnTitle?.length
			? columnTitle
			: columnTitleBuilder?.(columnDefs, applicationParameterData, metadata) ?? id;

		return columnBuilder(id, title, applicationParameterData, metadata, columnOptions);
	}

	if (type === ECustomColumnType.ScheduleApplicationParameters) {
		if (!scheduleApplicationParametersData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, columnBuilder, metadata } = columnConfig;
		const id = columnIdBuilder(columnDefs, scheduleApplicationParametersData, metadata);
		const title = columnTitle?.length
			? columnTitle
			: columnTitleBuilder?.(columnDefs, scheduleApplicationParametersData, metadata) ?? id;

		return columnBuilder(id, title, scheduleApplicationParametersData, metadata, columnOptions);
	}
};

export const isTitleValid = <TData extends IBaseTData>(
	columnTitle: string | undefined,
	columnDefs: IColumnDef<TData>[]
): boolean => {
	if (!columnTitle) {
		return true;
	}

	return columnDefs.every(
		({ title }) => title?.toLocaleLowerCase() !== columnTitle?.toLocaleLowerCase()
	);
};

export const getSidepanelWidthCssProperty = (): CSSProperties =>
	({
		'--sidepanel-width': `${SIDEPANEL_WIDTH_IN_PX}px`
	}) as CSSProperties;
