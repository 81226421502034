import { buildRelativeTimeRangeDropdownOptions } from '@kelvininc/tsutils';
import { ERelativeTimeRangeKey, IUIDropdownOptions } from '@kelvininc/types';

export const DATASTREAM_COLUMN_NAME_LABEL = 'Data Stream';
export const DATASTREAM_COLUMN_NAME_PLACEHOLDER = 'Select an option';
export const DATASTREAM_COLUMN_AGGREGATION_FUNCTION_COLUMN_LABEL = 'Aggregation Function';
export const DATASTREAM_COLUMN_AGGREGATION_FUNCTION_COLUMN_PLACEHOLDER = 'Select an option';
export const DATASTREAM_COLUMN_TIME_RANGE_COLUMN_LABEL = 'Time Range';
export const DATASTREAM_COLUMN_TIME_RANGE_COLUMN_PLACEHOLDER = 'Select an option';

const DATASTREAM_COLUMN_TIME_RANGE_OPTIONS: ERelativeTimeRangeKey[] = [
	ERelativeTimeRangeKey.Last_5_M,
	ERelativeTimeRangeKey.Last_10_M,
	ERelativeTimeRangeKey.Last_15_M,
	ERelativeTimeRangeKey.Last_30_M,
	ERelativeTimeRangeKey.Last_1_H,
	ERelativeTimeRangeKey.Last_12_H,
	ERelativeTimeRangeKey.Last_24_H,
	ERelativeTimeRangeKey.Last_48_H,
	ERelativeTimeRangeKey.Last_72_H,
	ERelativeTimeRangeKey.Last_7_D
];
export const DATASTREAM_COLUMN_TIME_RANGE_DROPDOWN_OPTIONS: IUIDropdownOptions =
	buildRelativeTimeRangeDropdownOptions(DATASTREAM_COLUMN_TIME_RANGE_OPTIONS);
