import { EDataExplorerOrigin, EDataExplorerQueryParams } from '@kelvininc/data-explorer';
import { useMatch } from '@kelvininc/nextjs-utils/client';
import { EIconName } from '@kelvininc/react-ui-components';
import { IExpandableMenuItem, useInstanceSetting } from '@kelvininc/shared-ui';

import {
	APPLICATIONS_ROUTE_ID,
	ASSETS_ROUTE_ID,
	DATA_EXPLORER_ROUTE_ID,
	OVERVIEW_ROUTE_ID
} from './config';

import { operationsOverviewEnableSettingAtom } from '@/src/recoil/settings/atoms';
import {
	OPERATIONS_OVERVIEW_ENABLED_SETTING_DEFAULT,
	OPERATIONS_OVERVIEW_ENABLED_SETTING_KEY
} from '@/src/recoil/settings/config';
import {
	buildApplicationsRoute,
	buildAssetsRoute,
	buildDataExplorerRoute,
	buildOperationsOverviewRoute
} from '@/src/utils/routes';

export const useOperationsItems = (): IExpandableMenuItem[] => {
	const {
		value: { enabled: isOperationsEnabled }
	} = useInstanceSetting(
		OPERATIONS_OVERVIEW_ENABLED_SETTING_KEY,
		OPERATIONS_OVERVIEW_ENABLED_SETTING_DEFAULT,
		operationsOverviewEnableSettingAtom
	);
	const operationsOverviewURL = buildOperationsOverviewRoute();
	const matchOperationsOverviewUrl = useMatch(`${operationsOverviewURL}/*`);

	const assetsURL = buildAssetsRoute();
	const matchAssetsUrl = useMatch(`${assetsURL}/*`);

	const dataExplorerUrl = buildDataExplorerRoute({
		[EDataExplorerQueryParams.Origin]: EDataExplorerOrigin.Menu
	});
	const [urlWithoutParams] = dataExplorerUrl.split('?');
	const matchDataExplorerUrl = useMatch(urlWithoutParams);

	const applicationsURL = buildApplicationsRoute();
	const matchApplicationsUrl = useMatch(`${applicationsURL}/*`);

	return [
		...(isOperationsEnabled
			? [
					{
						url: operationsOverviewURL,
						id: OVERVIEW_ROUTE_ID,
						text: 'Overview',
						active: !!matchOperationsOverviewUrl,
						leftIcon: EIconName.Device
					}
				]
			: []),
		{
			url: assetsURL,
			id: ASSETS_ROUTE_ID,
			text: 'Assets',
			active: !!matchAssetsUrl,
			leftIcon: EIconName.Asset
		},
		{
			url: applicationsURL,
			id: APPLICATIONS_ROUTE_ID,
			text: 'SmartApps™',
			active: !!matchApplicationsUrl,
			leftIcon: EIconName.Applications
		},
		{
			url: dataExplorerUrl,
			id: DATA_EXPLORER_ROUTE_ID,
			text: 'Data Explorer',
			active: !!matchDataExplorerUrl,
			leftIcon: EIconName.DataExplorer
		}
	];
};
