import { AgGridReact } from '@ag-grid-community/react';
import { EDataState, useUpdate } from '@kelvininc/shared-ui';

import { IBaseTData, ITableContext } from '../../components/AgGridTable';
import { executeGridAPICall, refreshDatasource } from '../../components/AgGridTable/utils';

export const useContextSync = <TData extends IBaseTData>(
	table: AgGridReact<TData> | null,
	newContext: ITableContext<TData>,
	setState: (newState: EDataState) => void
) => {
	useUpdate(() => {
		executeGridAPICall(table?.api, (api) => {
			api.updateGridOptions({ context: newContext });
			api.refreshHeader();
			api.refreshCells();
		});
	}, [newContext]);

	useUpdate(() => {
		executeGridAPICall(table?.api, (api) => {
			const rowModel = api.getModel()?.getType();
			if (rowModel === 'serverSide') {
				setState(EDataState.IsLoading);
			}

			refreshDatasource(api);
		});
	}, [
		newContext.filters,
		newContext.advancedFilters,
		newContext.searchTerm,
		newContext.page,
		newContext.pageSize,
		newContext.sortBy,
		newContext.sortDirection,
		newContext.maxRowsPinned,
		newContext.columnDefs
	]);
};
