import { FunctionComponent } from '@kelvininc/types';
import { PropsWithChildren } from 'react';

import { BaseHeaderCellRenderer } from '../../BaseHeaderCellRenderer';

export const withBaseHeaderCellRenderer = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>
) => {
	return function BaseHeaderCellRendererWrapper(
		componentProps: PropsWithChildren<ComponentProps>
	) {
		return (
			<BaseHeaderCellRenderer {...componentProps}>
				<Component {...componentProps} />
			</BaseHeaderCellRenderer>
		);
	};
};
