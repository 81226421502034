import { isNil, merge } from 'lodash-es';

import { EQUALITY_OPERATORS } from '../../../../../../../../../../../utils';
import { FILTER_UI_SCHEMA } from '../../../../config';

import { SELECT_TIMERANGE_PLACEHOLDER } from '../../config';

import { DatastreamAdvancedFilterSchemaFormData } from '../../types';

export const buildUISchema = () => {
	return merge({}, FILTER_UI_SCHEMA, {
		timeRange: {
			'ui:title': '',
			'ui:placeholder': SELECT_TIMERANGE_PLACEHOLDER,
			searchable: false
		},
		string_options_value: {
			'ui:title': '',
			searchable: false
		}
	});
};

export const isValueListPresent = ({
	valueList,
	operator
}: DatastreamAdvancedFilterSchemaFormData) =>
	!isNil(valueList) && !isNil(operator) && EQUALITY_OPERATORS.includes(operator);
