import { ColDef, GridOptions } from '@ag-grid-community/core';

import { EIllustrationName } from '@kelvininc/react-ui-components';
import { EDataState, OverlaysConfig } from '@kelvininc/shared-ui';

import { LoadingCellRenderer, TextCellRenderer } from '../../../renderers/cells';
import { HeaderTextCellRenderer } from '../../../renderers/headers';

import { ECellAlignment } from './types';

const NO_LIMIT_DATASOURCE_REQUESTS = -1;

export const DEFAULT_ROW_HEIGHT_IN_PIXELS = 56;

export const FIRST_PAGE = 1;

export const COLUMN_HORIZONTAL_PADDING = 32;

export const ELLIPSIS_THRESHOLD_PX = 1;

export const DEFAULT_MAX_ROWS_PINNED = 4;

export const DEFAULT_OVERLAY_CONFIG: Partial<OverlaysConfig> = {
	[EDataState.IsEmpty]: {
		title: 'No Content Here... Yet!',
		description:
			'Currently, the table container is empty, but rest assured, this state can be temporary. Feel free to check back later or try adding some data.',
		illustration: EIllustrationName.NoContentHere
	}
};
export const DEFAULT_COLUMN_DEF: ColDef = {
	cellRenderer: TextCellRenderer,
	headerComponent: HeaderTextCellRenderer,
	suppressMovable: true,
	sortable: false,
	minWidth: 70,
	cellDataType: false
};

export const DEFAULT_AG_GRID_OPTIONS: GridOptions = {
	serverSideSortOnServer: true,
	serverSideFilterOnServer: true,
	suppressServerSideInfiniteScroll: true,
	suppressCellFocus: true,
	suppressDragLeaveHidesColumns: true,
	suppressRowClickSelection: true,
	suppressRowDeselection: true,
	suppressGroupRowsSticky: true,
	suppressColumnVirtualisation: true,
	animateRows: false,
	loadingCellRenderer: LoadingCellRenderer,
	maxConcurrentDatasourceRequests: NO_LIMIT_DATASOURCE_REQUESTS,
	enableCellTextSelection: true,
	ensureDomOrder: true,
	enableRangeSelection: false,
	autoSizePadding: ELLIPSIS_THRESHOLD_PX
};

export const CELL_ALIGNMENT_CLASSES: Record<ECellAlignment, string> = {
	[ECellAlignment.Left]: 'ag-left-aligned-cell',
	[ECellAlignment.Center]: 'ag-center-aligned-cell',
	[ECellAlignment.Right]: 'ag-right-aligned-cell'
};

export const HEADER_ALIGNMENT_CLASSES: Record<ECellAlignment, string> = {
	[ECellAlignment.Left]: 'ag-left-aligned-header',
	[ECellAlignment.Center]: 'ag-center-aligned-header',
	[ECellAlignment.Right]: 'ag-right-aligned-header'
};
