import { PropsWithChildren, useLayoutEffect } from 'react';

import { setModalConfig } from '../../../Modal';

import { IWizardStepConfig } from './types';

type WizardStepProps = {
	stepKey: string;
	activeStep: IWizardStepConfig;
};

export const WizardStep = ({
	stepKey,
	activeStep,
	children
}: PropsWithChildren<WizardStepProps>) => {
	const isActive = activeStep.id === stepKey;

	useLayoutEffect(() => {
		if (isActive) {
			setModalConfig({
				customClass: activeStep.modalClass
			});
		}
	}, [activeStep.modalClass, isActive]);

	if (!isActive) {
		return null;
	}

	return <>{children}</>;
};
