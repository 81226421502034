import { AppItem, AppVersion } from '@kelvininc/node-client-sdk';
import { SchemaFormProps } from '@kelvininc/react-ui-components';
import { isEmpty } from 'lodash-es';

import { DeployWorkloadData, EDeployWorkloadType } from '../../types';

import { AppInfoData } from './AppInfo';

export const getWorkloadAppInfoFormSchema = (
	flowType: EDeployWorkloadType,
	apps: AppItem[],
	versions: AppVersion[]
): SchemaFormProps<AppInfoData>['schema'] => {
	const requiredItems = ['appName', 'appVersion'];

	return {
		type: 'object',
		properties: {
			appName: {
				type: 'string',
				title: 'SmartApp',
				oneOf: !isEmpty(apps)
					? apps.map((item) => ({ title: item.title, const: item.name }))
					: [],
				readOnly: flowType !== EDeployWorkloadType.Deploy
			},
			appVersion: {
				type: 'string',
				title: 'Version',
				enum: !isEmpty(versions) ? versions.map((item) => item.version) : []
			}
		},
		required: requiredItems
	};
};

export const getWorkloadAppInfoFormUiSchema = (
	flowType: EDeployWorkloadType,
	appNameReadOnly = false,
	versionsReadOnly: boolean
): SchemaFormProps<AppInfoData>['uiSchema'] => ({
	appName: {
		'ui:widget': flowType === EDeployWorkloadType.AppScopeDeploy ? 'hidden' : undefined,
		'ui:placeholder': 'Choose one SmartApp from the system',
		'ui:readonly': appNameReadOnly,
		'ui:hideError': false,
		searchable: true
	},
	appVersion: {
		'ui:placeholder': 'Choose a version',
		'ui:readonly': versionsReadOnly,
		'ui:hideError': false
	},
	'ui:hideError': true,
	'ui:submitButtonOptions': {
		norender: true
	}
});

export const checkFormFields = (formData: AppInfoData): boolean => {
	return !isEmpty(formData.appVersion) && !isEmpty(formData.appName);
};

export const buildAppInfoData = (data: Partial<DeployWorkloadData>): AppInfoData => {
	return {
		appName: data.appName,
		appVersion: data.appVersion
	};
};
