import {
	Asset,
	AssetKRN,
	ParameterScheduleResourceParametersInner,
	ParameterValue
} from '@kelvininc/node-client-sdk';
import { APPLICATION_CLOSED_LOOP_KEY, openModal } from '@kelvininc/shared-ui';

import { compareStrings } from '@kelvininc/tsutils';
import { EClosedLoopMode } from '@kelvininc/types';

import { get } from 'lodash-es';

import { getAssetResourceFromKRN } from '../../utils';
import { DetailValues, DetailValuesProps, IDetailValue } from '../DetailValues';

import styles from './styles.module.scss';

export const convertToParameterMap = (
	data: ParameterScheduleResourceParametersInner[]
): Record<string, Record<string, ParameterValue>> => {
	return data.reduce<Record<string, Record<string, ParameterValue>>>((acc, item) => {
		const resource = item.resource;
		const parameters = item.parameters;

		Object.keys(parameters).forEach((key) => {
			acc[key] = { ...acc[key], [resource]: parameters[key] as ParameterValue };
		});
		return acc;
	}, {});
};

export const getValueInfo = (
	parameterValues: Record<string, ParameterValue> = {},
	assetName?: string
) => {
	const values = Object.values(parameterValues);
	let firstValue: ParameterValue | undefined = get(values, '[0]');

	if (assetName) {
		firstValue = get(parameterValues, [AssetKRN.toKRN({ asset: assetName })]);
	}

	return { firstValue, hasDifferentValues: values.some((value) => value !== firstValue) };
};

export const hasDifferentValues = (
	initialValues: Record<string, Record<string, ParameterValue>>,
	revertValues: Record<string, Record<string, ParameterValue>>
) => {
	const hasInitialValuesDifferent = Object.entries(initialValues).some(([, values]) => {
		const { hasDifferentValues: hasDifferent } = getValueInfo(values);
		return hasDifferent;
	});

	const hasRevertValuesDifferent = Object.entries(revertValues).some(([, values]) => {
		const { hasDifferentValues: hasDifferent } = getValueInfo(values);
		return hasDifferent;
	});

	return hasInitialValuesDifferent || hasRevertValuesDifferent;
};

export const openValuesDetailModal = (props: DetailValuesProps) => {
	openModal({
		ContentComponent: <DetailValues {...props} />,
		config: {
			title: `${props.title} values`,
			customClass: styles.DetailValuesModal
		}
	});
};

export const buildDetailValuesData = (
	parameterName: string,
	valuesPerAsset: Record<string, ParameterValue>,
	closedLoopMapping: Record<EClosedLoopMode, string>,
	assetsMap: Record<string, Asset> = {}
): IDetailValue[] => {
	const isClosedLoopParameter = parameterName === APPLICATION_CLOSED_LOOP_KEY;
	const result = Object.entries(valuesPerAsset).reduce<IDetailValue[]>((acc, [asset, value]) => {
		const assetName = getAssetResourceFromKRN(asset);
		let valueString = value !== undefined ? `${value}` : undefined;
		if (isClosedLoopParameter) {
			valueString = closedLoopMapping[value as EClosedLoopMode];
		}
		acc.push({
			asset: assetsMap[assetName]?.title,
			value: valueString
		});
		return acc;
	}, []);

	return result;
};

export const buildParameterValues = (
	parameterName: string | undefined,
	initialValue: ParameterValue | undefined,
	scheduleValue: ParameterValue | undefined,
	revertValue: ParameterValue | undefined,
	mapping: Record<EClosedLoopMode, string>
): {
	initial?: string;
	scheduled?: string;
	revert?: string;
} => {
	let result = {
		initial: initialValue !== undefined ? `${initialValue}` : undefined,
		scheduled: scheduleValue !== undefined ? `${scheduleValue}` : undefined,
		revert: revertValue !== undefined ? `${revertValue}` : undefined
	};
	if (parameterName && parameterName === APPLICATION_CLOSED_LOOP_KEY) {
		result = {
			initial:
				result.initial !== undefined
					? mapping[result.initial as EClosedLoopMode]
					: undefined,
			scheduled:
				result.scheduled !== undefined
					? mapping[result.scheduled as EClosedLoopMode]
					: undefined,
			revert:
				result.revert !== undefined ? mapping[result.revert as EClosedLoopMode] : undefined
		};
	}
	return result;
};

export const sortParametersByName = (parameters: Record<string, ParameterValue>) =>
	Object.entries(parameters).sort((a, b) => compareStrings(a[0], b[0]));
