import { Expandable } from '../../../Expandable';
import { MenuExpandableAction } from '../MenuExpandableAction';
import { NavigationItem } from '../NavigationItem';

import { IExpandableMenuItem } from '../NavigationItem/types';

import styles from './styles.module.scss';

type ExpandableMenuProps = {
	title: string;
	items?: IExpandableMenuItem[];
	collapsed?: boolean;
};

export const ExpandableMenu = ({
	title = '',
	collapsed = false,
	items = []
}: ExpandableMenuProps) => {
	return (
		<div className={styles.Container}>
			<Expandable
				ExpandableAction={<MenuExpandableAction title={title} collapsed={collapsed} />}>
				<div className={styles.ItemsContainer}>
					{items.map((item) => (
						<NavigationItem key={item.id} item={item} isCollapsed={collapsed} />
					))}
				</div>
			</Expandable>
		</div>
	);
};
