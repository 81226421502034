import { EComponentSize, EIconName, KvActionButton, KvIcon } from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import { PropsWithChildren } from 'react';

import styles from './styles.module.scss';

type AdvancedFiltersFormProps = {
	onAdd: () => void;
	customClasses?: ClassNamesProp;
};

export const AdvancedFiltersForm = ({
	onAdd,
	customClasses,
	children
}: PropsWithChildren<AdvancedFiltersFormProps>) => {
	return (
		<div className={styles.AdvancedFiltersForm}>
			<div className={classNames(styles.Forms, customClasses)}>{children}</div>
			<KvActionButton
				className={styles.AddButton}
				onClickButton={onAdd}
				size={EComponentSize.Small}>
				<KvIcon name={EIconName.Add} />
				<div className={styles.Text}>Add Advanced Filter</div>
			</KvActionButton>
		</div>
	);
};
