import {
	CellClassFunc,
	GridApi,
	HeaderClass,
	ICellEditorParams,
	IHeaderParams,
	IRowNode,
	ICellRendererParams as ITableCellRendererParams
} from '@ag-grid-community/core';
import {
	EControlChangeState,
	EParametersScheduleExtraFieldDataScheduleType,
	ERecommendationState
} from '@kelvininc/node-client-sdk';
import {
	EActionButtonType,
	EIconName,
	EOtherIconName,
	ETooltipPosition
} from '@kelvininc/react-ui-components';
import { EDataState } from '@kelvininc/shared-ui';
import {
	ClassNamesProp,
	DataStreamAggregationFunction,
	ERelativeTimeRangeKey,
	ESortDirection,
	FunctionComponent,
	ITimeChange
} from '@kelvininc/types';
import { Dispatch, HTMLAttributeAnchorTarget, PropsWithChildren, SetStateAction } from 'react';

import {
	ActionsCellRendererParams,
	ControlChangeCellRendererParams,
	GroupedSelectCellEditorParams,
	GuardrailValueCellRendererParams,
	IconTextCellRendererParams,
	MultiActionCellRendererParams,
	ObjectPayloadCellRendererParams,
	ParameterCellRendererParams,
	RecommendationCellRendererParams,
	ScheduleParametersCellRendererParams,
	SchemaFormErrorCellParams,
	SelectCellEditorParams,
	SelectCellRendererParams,
	StatusCellParams,
	TextCellEditorParams,
	TextCellRendererParams,
	TimeAvatarCellParams
} from '../../../renderers';

import { ISelectionApi } from '../../hooks';

export type IBaseTData = {};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IDefaultTValue = any;

export enum ECellAlignment {
	Left = 'left',
	Center = 'center',
	Right = 'right'
}

export enum EColumnPinAlignment {
	Left = 'left',
	Right = 'right'
}

export interface ISortInfo {
	sortBy?: string[];
	sortDirection?: ESortDirection;
}

export type IColumnsPinConfig = Record<string, EColumnPinAlignment | boolean | undefined>;
export type IColumnsHiddenConfig = Record<string, boolean>;

export enum ESelectionType {
	Single = 'single',
	Multiple = 'multiple'
}

export enum ECustomColumnType {
	Datastream = 'datastream',
	AssetProperty = 'asset-property',
	LastControlChange = 'last-control-change',
	Recommendation = 'recommendation',
	ApplicationParameter = 'application-parameter',
	ScheduleApplicationParameters = 'schedule-application-parameter'
}

export type CellClass<TData extends IBaseTData> = string | string[] | CellClassFunc<TData>;

export interface ITableContext<TData extends IBaseTData> {
	filters?: ITableFilters;
	advancedFilters?: ITableAdvancedFilters;
	searchTerm?: string;
	page?: number;
	pageSize?: number;
	sortBy?: string[];
	sortDirection?: ESortDirection;
	state: EDataState;
	onStateChange: Dispatch<SetStateAction<EDataState>>;
	rowsPinned: TData[];
	maxRowsPinned: number;
	maxSelectedRows?: number;
	getRowId: (data: TData) => string;
	onRowsPinnedChange: (newRows: TData[]) => void;
	onPaginationChange?: (newPagination: IPaginationInfo | undefined) => void;
	onPaginationTotalChange?: (newPagination: IPaginationTotalInfo | undefined) => void;
	onSortChange?: (newSort: ISortInfo | undefined) => void;
	columnDefs: IColumnDef<TData>[];
	selectionApi: ISelectionApi<TData>;
	isRowMaster?: (data: TData) => boolean;
}
export type IHeaderRendererParams<TData extends IBaseTData> = Partial<
	IHeaderParams<TData, ITableContext<TData>>
> &
	IBaseHeaderParams;

export type ITableCellEditorParams<
	TData extends IBaseTData,
	TValue = IDefaultTValue
> = ICellEditorParams<TData, TValue>;

export type ICellRendererParams<TData extends IBaseTData, TValue = IDefaultTValue> = Partial<
	ITableCellRendererParams<TData, TValue>
> &
	IBaseCellParams<TData, TValue>;

export type IBaseCellParams<TData extends IBaseTData, TValue = IDefaultTValue> = {
	/** A function or expression to specify the page the link goes to */
	getLink?: GetLinkCallback<TData, TValue>;
	/** Attribute to set links target */
	target?: React.HTMLAttributeAnchorTarget;
	/** A function or expression to define the cell click */
	onClick?: GetOnClickCallback<TData, TValue>;
	/** Set to `true` if you want to show the tooltip. Header Default: `true`, Cell Default: `false` */
	hasCellTooltip?: boolean;
	/** Default tooltip cell text, will overwrite the value */
	tooltipCellField?: string;
	/** Set `true` to display tooltip only when the content is truncated */
	tooltipTruncate?: boolean;
	/** The cell tooltip position. Default: `bottom-start` */
	tooltipPosition?: ETooltipPosition;
	/** Set to `true` if you want to show the copy clipboard style. Default: `false` */
	hasCopyClipboard?: boolean;
	/** Set the tooltip suffix clipboard text, will add it to `Copy`. */
	copyClipboardSuffix?: string;
	/** Set `false` to not display the empty state "-" with tooltip `No data available to display` when there is no value */
	displayEmptyState?: boolean;
	/** Placeholder to display when there is no value */
	placeholder?: string;
	/** A function or expression to format the tooltip cell text, should return a string */
	tooltipCellFieldFormatter?: (
		value: TValue,
		data: TData,
		columnId: string,
		api?: GridApi<TData>,
		context?: ITableContext<TData>
	) => string | undefined;
	/** A set of custom classes to custom style the grid cell */
	customClasses?: ClassNamesProp;
	/** Allow set the cell state per row */
	cellState?: (value: TValue | undefined | null, data?: TData) => ICellState;
	/** Set to true to disable this cell mouse events. Default `false` */
	disabled?: boolean;
	/** Set to true to enable the router prefetch: Default `false` */
	prefetchLink?: boolean;
};

export type IBaseHeaderParams = {
	/** Column data type to be used on the tooltip with sort information */
	columnDataType?: EColumnDataType;
	/** Default tooltip header text, will overwrite the sort info */
	tooltipHeaderField?: string;
	/** The header cell tooltip position. Default: `bottom-start` */
	tooltipHeaderPosition?: ETooltipPosition;
	/** A set of custom classes to custom style the grid cell */
	customClasses?: ClassNamesProp;
};

export enum EColumnDataType {
	Text,
	Numeric,
	Time
}

export type CellComponentParams<TData extends IBaseTData, TValue = IDefaultTValue> =
	| undefined
	| IBaseCellParams<TData, TValue>
	| ActionsCellRendererParams<TData>
	| IconTextCellRendererParams
	| TextCellRendererParams<TData>
	| ParameterCellRendererParams<TData>
	| ControlChangeCellRendererParams
	| RecommendationCellRendererParams
	| MultiActionCellRendererParams<TData>
	| ObjectPayloadCellRendererParams<TData>
	| StatusCellParams<TData, TValue>
	| TimeAvatarCellParams<TData, TValue>
	| SchemaFormErrorCellParams
	| SelectCellRendererParams
	| ScheduleParametersCellRendererParams
	| ActionsCellRendererParams<TData>
	| GuardrailValueCellRendererParams;

export type CellEditorParams<TData extends IBaseTData> =
	| undefined
	| TextCellEditorParams<TData>
	| GroupedSelectCellEditorParams
	| SelectCellEditorParams;

export type HeaderCellComponentParams = undefined | IBaseHeaderParams;

export type ICellEditorSelector<TData extends IBaseTData, TValue = IDefaultTValue> = {
	component?: FunctionComponent<ITableCellEditorParams<TData, TValue> & CellEditorParams<TData>>;
	params?: PropsWithChildren<CellEditorParams<TData>>;
	/** Equivalent of setting `colDef.cellEditorPopup` */
	popup?: boolean;
	/** Equivalent of setting `colDef.cellEditorPopupPosition` */
	popupPosition?: 'over' | 'under';
};

export type ICellComponentSelector<TData extends IBaseTData, TValue = IDefaultTValue> = {
	component?: FunctionComponent<
		CellComponentParams<TData, TValue> & ICellRendererParams<TData, TValue>
	>;
	params?: PropsWithChildren<CellComponentParams<TData, TValue>>;
};
export interface IColumnOptions<TData extends IBaseTData, TValue = IDefaultTValue> {
	/** The custom body cell component to be used for edit the value. If none specified the default body cell edit is used. */
	cellEditor?: FunctionComponent<ITableCellEditorParams<TData, TValue> & CellEditorParams<TData>>;
	/** Callback to select which cell editor to be used for a given row within the same column. */
	cellEditorSelector?: (data: TData) => ICellEditorSelector<TData, TValue>;
	/** Params to be passed to the `cellEditor` component. */
	cellEditorParams?: CellEditorParams<TData>;
	/** Set to `true` to have cells under this column enter edit mode after single click. Default: `false` */
	singleClickEdit?: boolean;
	/** Set to `true` to allow this column should be editable. Default: `false` */
	editable?: boolean | ((data?: TData) => boolean);
	/** Set to `false` if you do not want this column to be movable via dragging. Default: `true` */
	draggable?: boolean;
	/** Set to `true` to allow sorting on this column. Default: `false` */
	sortable?: boolean;
	/** Set to `true` to allow this column should be resized. Default: `false` */
	resizable?: boolean;
	/** Pin a column to one side: `right` or `left`. A value of `true` is converted to `left`. */
	pinned?: boolean | EColumnPinAlignment;
	/** Set to `true` for this column to be hidden. Default: `false` */
	hide?: boolean;
	/** A function or expression to format a value, should return a string. */
	valueFormatter?: (
		value: TValue,
		data: TData,
		columnId: string,
		api?: GridApi<TData>,
		context?: ITableContext<TData>
	) => TValue;
	/** Initial width in pixels for the cell. */
	width?: number;
	/** Same as `width`, except only applied when creating a new column. Not applied when updating column definitions. */
	initialWidth?: number;
	/** Minimum width in pixels for the cell. */
	minWidth?: number;
	/** Maximum width in pixels for the cell. */
	maxWidth?: number;
	/** Set the column header horizontal alignment: `right`, `center` or `left`. Default `left`. */
	headerAlignment?: ECellAlignment;
	/** CSS class to use for the header cell. Can be a string, array of strings, or function. */
	headerClass?: HeaderClass<TData>;
	/** The custom header component to be used for rendering the component header. If none specified the default header component is used. */
	headerComponent?: FunctionComponent<HeaderCellComponentParams & IHeaderRendererParams<TData>>;
	/** The parameters to be passed to the `headerComponent`. */
	headerComponentParams?: HeaderCellComponentParams;
	/** Set the column body cells horizontal alignment: `right`, `center` or `left`. Default `left`. */
	cellAlignment?: ECellAlignment;
	/** CSS class to use for the body cells. Can be a string, array of strings, or function. */
	cellClass?: CellClass<TData>;
	/** The custom body cell component to be used for rendering the component body cell. If none specified the default body cell component is used. */
	cellComponent?: FunctionComponent<
		CellComponentParams<TData, TValue> & ICellRendererParams<TData, TValue>
	>;
	/** Callback to select which cell component to be used for a given row within the same column. */
	cellComponentSelector?: (data?: TData) => ICellComponentSelector<TData, TValue>;
	/** Params to be passed to the `cellRenderer` component. */
	cellComponentParams?: CellComponentParams<TData, TValue>;
	/** Callback to determinate if a row is selected. */
	rowSelected?: (
		value: TValue,
		data: TData,
		columnId: string,
		api?: GridApi<TData>,
		context?: ITableContext<TData>
	) => boolean;
	/** Callback to determinate if a row can be selection is disabled. */
	rowSelectionDisabled?: (
		value: TValue,
		data: TData,
		columnId: string,
		api?: GridApi<TData>,
		context?: ITableContext<TData>
	) => boolean;
	/** Set to `true` to block the user pinning the column via the UI, the column can only be pinned via definitions. Default: `false`  */
	lockPinned?: boolean;
	/** Set to `true` to block the user making column visible / hidden via the UI. Default: `false` */
	lockVisible?: boolean;
	/** Set to `true` to disable the user to edit this column properties. Default: `false` */
	inalterable?: boolean;
	/** Set to `true` to mark the column as a feature columns. Default: `false` */
	feature?: boolean;
	/** The custom column metadata. This field is helpful to attach custom data to this column. */
	metadata?: ICustomColumnMetadata;
}

export interface IGridOptions {
	/** If `true`, row selection won't happen when rows are clicked. Use when you only want checkbox selection. Default: `false` */
	suppressRowClickSelection?: boolean;
	/** Set to `true` to allow multiple rows to be selected using single click. Default: `false` */
	rowMultiSelectWithClick?: boolean;
	/** If `true`, rows will not be deselected if you hold down `Ctrl` and click the row or press `Space`. Default: `false` */
	suppressRowDeselection?: boolean;
}

export interface IColumnDef<TData extends IBaseTData> extends IColumnOptions<TData> {
	/** The unique ID to give the column. This ID is used to identify the column in the API. */
	id: string;
	/** The name to render in the column header. If not specified and field is specified, the accessor will be used instead. */
	title?: string;
	/**
	 * The field of the row object to get the cell's data from.
	 * Deep references into a row object is supported via dot notation, i.e `'address.firstLine'`.
	 */
	accessor?: string;
}

export enum ETableAdvancedFilterType {
	AssetProperty = 'asset-property',
	ApplicationParameter = 'application-parameter',
	Datastream = 'datastreams'
}

export interface ITableAdvancedFilterBase {
	id: string;
	type: ETableAdvancedFilterType;
	operator: string;
	value?: TableFilterValue;
}

export interface IAssetPropertyTableAdvancedFilter extends ITableAdvancedFilterBase {
	type: ETableAdvancedFilterType.AssetProperty;
	property: string;
}

export interface IApplicationParameterTableAdvancedFilter extends ITableAdvancedFilterBase {
	type: ETableAdvancedFilterType.ApplicationParameter;
	application: string;
	parameter: string;
}

export interface IDatastreamTableAdvancedFilter extends ITableAdvancedFilterBase {
	type: ETableAdvancedFilterType.Datastream;
	datastream: string;
	aggregation: DataStreamAggregationFunction;
	timeRange?: ERelativeTimeRangeKey;
}

export type ITableAdvancedFilter =
	| IAssetPropertyTableAdvancedFilter
	| IApplicationParameterTableAdvancedFilter
	| IDatastreamTableAdvancedFilter;

export interface ITableAdvancedFilterMap {
	[ETableAdvancedFilterType.AssetProperty]: IAssetPropertyTableAdvancedFilter;
	[ETableAdvancedFilterType.ApplicationParameter]: IApplicationParameterTableAdvancedFilter;
}

export type ITableAdvancedFilters = {
	[ETableAdvancedFilterType.AssetProperty]?: IAssetPropertyTableAdvancedFilter[];
	[ETableAdvancedFilterType.ApplicationParameter]?: IApplicationParameterTableAdvancedFilter[];
	[ETableAdvancedFilterType.Datastream]?: IDatastreamTableAdvancedFilter[];
};

export type TableFilterValue = string | number | boolean | string[] | number[] | boolean[];

export type ITableFilters = Record<string, TableFilterValue>;

export type Datasource<TData extends IBaseTData, Plugins = {}> = (
	params: IDatasourceParams<TData> & Plugins,
	api: GridApi<TData>
) => Promise<IDatasourceResult<TData>>;

export interface IDatasourceParams<TData extends IBaseTData> {
	sortBy?: string[];
	sortDirection?: ESortDirection;
	search?: string[];
	filters?: ITableFilters;
	advancedFilters?: ITableAdvancedFilters;
	page?: number;
	pageSize?: number;
	pinned?: string[];
	columnDefs: IColumnDef<TData>[];
}
export interface IAbortableDatasourcePlugin {
	signal?: AbortSignal;
}
export interface IDatasourceResult<TData extends IBaseTData> {
	data: TData[];
	pagination?: IPaginationInfo & IPaginationTotalInfo;
}

export interface IAssetPropertyColumnData {
	property?: string;
}

export interface IDatastreamColumnData {
	datastream?: string;
	aggregation?: DataStreamAggregationFunction;
	range?: Partial<ITimeChange> & Pick<ITimeChange, 'time'>;
}

export interface ILastControlChangeColumnData {
	statuses?: EControlChangeState[];
	datastreams?: string[];
	allSetpoints: boolean;
}

export interface IRecommendationColumnData {
	statuses?: ERecommendationState[];
	recommendationTypes?: string[];
	application?: string;
	range?: Partial<ITimeChange> & Pick<ITimeChange, 'time'>;
}

export interface IApplicationParameterColumnData {
	parameter?: string;
	application?: string;
}

export interface IScheduleApplicationParametersColumnData {
	type?: EParametersScheduleExtraFieldDataScheduleType;
	range?: Partial<ITimeChange> & Pick<ITimeChange, 'time'>;
}

export type ICustomColumnData =
	| IAssetPropertyColumnData
	| IDatastreamColumnData
	| ILastControlChangeColumnData
	| IRecommendationColumnData
	| IApplicationParameterColumnData
	| IScheduleApplicationParametersColumnData;

export interface IAssetPropertyColumnMetadata {
	type: ECustomColumnType.AssetProperty;
	data: IAssetPropertyColumnData;
}

export interface IDatastreamColumnMetadata {
	type: ECustomColumnType.Datastream;
	data: IDatastreamColumnData;
}

export interface ILastControlChangeColumnMetadata {
	type: ECustomColumnType.LastControlChange;
	data: ILastControlChangeColumnData;
}

export interface IRecommendationColumnMetadata {
	type: ECustomColumnType.Recommendation;
	data: IRecommendationColumnData;
}

export interface IApplicationParameterColumnMetadata {
	type: ECustomColumnType.ApplicationParameter;
	data: IApplicationParameterColumnData;
}

export interface IScheduleApplicationParametersColumnMetadata {
	type: ECustomColumnType.ScheduleApplicationParameters;
	data: IScheduleApplicationParametersColumnData;
}

export type ICustomColumnMetadata =
	| IAssetPropertyColumnMetadata
	| IDatastreamColumnMetadata
	| ILastControlChangeColumnMetadata
	| IRecommendationColumnMetadata
	| IApplicationParameterColumnMetadata
	| IScheduleApplicationParametersColumnMetadata;

export interface IAgGridTableApi<TData extends IBaseTData> {
	refreshDatasource: () => void;
	setPinnedRows: (rows: TData[]) => void;
	clearPinnedRows: () => void;
	setSelectedRows: (rows: TData[]) => void;
	clearSelectedRows: () => void;
}

export interface IPaginationInfo {
	page?: number;
	pageSize?: number;
}

export interface IPaginationTotalInfo {
	totalItems?: number;
	totalPages?: number;
}

export interface ITableAction {
	id: string;
	type?: EActionButtonType;
	text?: string;
	icon?: EIconName | EOtherIconName;
	disabled?: boolean;
	active?: boolean;
	onClick?: () => void;
	href?: string;
	prefetch?: boolean;
	download?: string;
	target?: HTMLAttributeAnchorTarget;
	tooltip?: string;
	tooltipPosition?: ETooltipPosition;
}

export interface ICellState {
	valid: boolean;
	message?: string;
}

export type GetLinkCallback<TData extends IBaseTData, TValue = IDefaultTValue> = (
	value: TValue,
	data: TData,
	columnId: string,
	api?: GridApi<TData>,
	context?: ITableContext<TData>
) => string | undefined;

export type GetOnClickCallback<TData extends IBaseTData, TValue = IDefaultTValue> = (
	value: TValue,
	data: TData,
	columnId: string,
	api?: GridApi<TData>,
	context?: ITableContext<TData>
) => void;

export type GetDetailRowData<TData extends IBaseTData, TDetail extends IBaseTData = IBaseTData> = (
	data: TData
) => TDetail[];

export type DetailCellRendererParams<TData extends IBaseTData> = {
	data: TData;
	node: IRowNode<TData>;
	api: GridApi<TData>;
};
