import { WizardFormContextProvider, useWizardFormContext } from '@kelvininc/shared-ui';
import { FunctionComponent } from '@kelvininc/types';

import { ImportGuardrailsWizardProps } from '../../ImportGuardrailsWizard';
import { ImportGuardrailsWizardFormValues } from '../../types';

export const useImportGuardrailsWizardFormContext = () => {
	return useWizardFormContext<ImportGuardrailsWizardFormValues>();
};

export const withImportGuardrailsWizardFormProvider = (
	Component: FunctionComponent<ImportGuardrailsWizardProps & JSX.IntrinsicAttributes>
) => {
	return function ImportGuardrailsWizardFormProviderWrapper(
		props: ImportGuardrailsWizardProps & JSX.IntrinsicAttributes
	) {
		const { connection } = props;

		return (
			<WizardFormContextProvider defaultValues={{ connection }}>
				<Component {...props} />
			</WizardFormContextProvider>
		);
	};
};
