import { EActionButtonType } from '@kelvininc/react-ui-components';
import { ITableAction } from '@kelvininc/table';

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { buildDisabledTooltip } from './utils';

import { assetsListAtom } from '@/src/recoil/assets/atoms';
import { connectionsListSelector } from '@/src/recoil/connections/selectors';
import { datastreamsListAtom } from '@/src/recoil/data-streams/atoms';
import {
	EGuardrailOperationType,
	GuardrailOperation
} from '@/src/services/guardrail-service/types';

export const useActions = (
	executeOperation: (operation: GuardrailOperation) => Promise<void>
): ITableAction[] => {
	const assets = useRecoilValue(assetsListAtom);
	const datastreams = useRecoilValue(datastreamsListAtom);
	const connections = useRecoilValue(connectionsListSelector);

	return useMemo<ITableAction[]>(() => {
		const tooltip = buildDisabledTooltip(datastreams, assets, connections);

		return [
			{
				id: 'create',
				text: 'Create Guardrail',
				type: EActionButtonType.Tertiary,
				onClick: () =>
					executeOperation({
						type: EGuardrailOperationType.Create
					}),
				disabled: !!tooltip,
				tooltip
			},
			{
				id: 'import',
				text: 'Import Guardrails',
				type: EActionButtonType.Primary,
				onClick: () =>
					executeOperation({
						type: EGuardrailOperationType.Import
					}),
				disabled: !!tooltip,
				tooltip
			}
		];
	}, [assets, connections, datastreams, executeOperation]);
};
