import {
	EPaginatorType,
	RecommendationService,
	RecommendationType
} from '@kelvininc/node-client-sdk';
import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { keyBy } from 'lodash-es';
import { selector } from 'recoil';

export const recommendationTypesMapSelector = selector<Record<string, RecommendationType>>({
	key: 'recommendationTypesMapSelector',
	get: ({ get }) => keyBy(get(recommendationTypesListSelector), 'name')
});

export const recommendationTypesListSelector = selector<RecommendationType[]>({
	key: 'recommendationTypesListSelector',
	get: () => {
		return RecommendationService.listRecommendationTypes({
			paginationType: EPaginatorType.Stream
		})
			.pipe(catchHttpError(throwHttpError))
			.toPromise();
	}
});
