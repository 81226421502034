import {
	AppItem,
	DataStream,
	Property,
	RecommendationType,
	Unit
} from '@kelvininc/node-client-sdk';

import { EIconName, SchemaFormProps } from '@kelvininc/react-ui-components';
import {
	ESortDirection,
	IApplicationParameterDefinition,
	IApplicationParameterValues,
	IClosedLoopSettings,
	ICoreAppPermissions,
	NonEmptyObject
} from '@kelvininc/types';

import { AgGridTableProps } from '../AgGridTable';

import {
	ECustomColumnType,
	ETableAdvancedFilterType,
	IApplicationParameterColumnData,
	IAssetPropertyColumnData,
	IBaseTData,
	IColumnDef,
	IColumnOptions,
	IColumnsHiddenConfig,
	IColumnsPinConfig,
	ICustomColumnData,
	IDatastreamColumnData,
	ILastControlChangeColumnData,
	IRecommendationColumnData,
	IScheduleApplicationParametersColumnData,
	ITableAction,
	ITableAdvancedFilters,
	ITableFilters
} from '../AgGridTable/types';

type BaseTableProps<TData extends IBaseTData, TDetail extends IBaseTData = IBaseTData> = {
	/** The table's header title */
	title?: string;
	/** The table's header subtitle */
	subtitle?: JSX.Element;
	/** A set of custom actions that will be displayed on the table's header */
	actions?: ITableAction[];
	/** The initial table's columns order */
	columnsOrder?: string[];
	/** The initial table's record of the columns id and pin configuration */
	columnsPinned?: IColumnsPinConfig;
	/** The initial table's record of the columns id and hidden configuration */
	columnsHidden?: IColumnsHiddenConfig;
} & Pick<
	AgGridTableProps<TData, TDetail>,
	| 'id'
	| 'maxHeight'
	| 'defaultColumnDef'
	| 'defaultDetailColumnDef'
	| 'agGridOptions'
	| 'columnDefs'
	| 'detailColumnDefs'
	| 'data'
	| 'datasource'
	| 'overlaysConfig'
	| 'overlaysComponents'
	| 'onColumnMoved'
	| 'onColumnPinned'
	| 'onColumnHidden'
	| 'onDataChange'
	| 'getRowId'
	| 'maxRowsPinned'
	| 'rowHeight'
	| 'searchTerm'
	| 'customClasses'
	| 'maxSelectedRows'
	| 'readOnlyEdit'
	| 'masterDetail'
	| 'isRowMaster'
	| 'getDetailRowData'
	| 'getDetailRowId'
	| 'detailCellRenderer'
>;

interface ISelectionActionBase<TData extends IBaseTData> extends Omit<ITableAction, 'onClick'> {
	isDisabled?: (rows: TData[]) => void;
	onClick?: (rows: TData[]) => void;
	group?: string;
}

interface ISelectionActionText<TData extends IBaseTData> extends ISelectionActionBase<TData> {
	text: string;
}

interface ISelectionActionIcon<TData extends IBaseTData> extends ISelectionActionBase<TData> {
	text?: undefined;
	icon: EIconName;
}

type DensityPickTableProps = {
	densityPickable?: boolean;
	densityOptions?: NonEmptyObject<Partial<Record<EDensityLevel, number>>>;
	densityInitial?: EDensityLevel;
	densityDisabledOptions?: Partial<Record<EDensityLevel, boolean>>;
	onDensityChange?: (newDensity: EDensityLevel | undefined) => void;
};

type SearchTableProps<TData extends IBaseTData> = {
	searchable?: boolean;
	searchInitial?: AgGridTableProps<TData>['searchTerm'];
	searchPlaceholder?: string;
	searchDebounce?: number;
	onSearchChange?: (newSearch: string | undefined) => void;
};

type FilterTableProps = {
	filterable?: boolean;
	filtersDisabled?: boolean;
	filtersInitial?: ITableFilters;
	filtersSchema?: ITableFiltersSchema;
	filters?: ITableFilters;
	unclearableFilters?: ITableFilters;
	onFiltersChange?: (newFilters: ITableFilters | undefined) => void;
	advancedFiltersDisabled?: boolean;
	advancedFiltersInitial?: ITableAdvancedFilters;
	advancedFilters?: ITableAdvancedFilters;
	advancedFiltersConfig?: IAdvancedFiltersConfig;
	onAdvancedFiltersChange?: (newFilters: ITableAdvancedFilters | undefined) => void;
};

type PaginationTableProps<TData extends IBaseTData> = {
	pagination?: boolean;
	pageInitial?: AgGridTableProps<TData>['page'];
	pageSizeInitial?: AgGridTableProps<TData>['pageSize'];
	pageSizeOptions?: number[];
	numberOfPageShortcuts?: number;
	onPaginationChange?: AgGridTableProps<TData>['onPaginationChange'];
	onPaginationTotalChange?: AgGridTableProps<TData>['onPaginationTotalChange'];
};

type SortTableProps<TData extends IBaseTData> = {
	sortable?: boolean;
	sortByInitial?: AgGridTableProps<TData>['sortByInitial'];
	sortDirectionInitial?: AgGridTableProps<TData>['sortDirectionInitial'];
	onSortChange?: AgGridTableProps<TData>['onSortChange'];
};

type SelectionTable<TData extends IBaseTData> = {
	checkboxSelectable?: boolean;
	rowSelectable?: boolean;
	selectionType?: AgGridTableProps<TData>['selectionType'];
	selectionActions?: ISelectionAction<TData>[] | ((data: TData[]) => ISelectionAction<TData>[]);
	onSelectionChange?: AgGridTableProps<TData>['onSelectionChange'];
	isRowDisabled?: AgGridTableProps<TData>['isRowDisabled'];
	selectedRowsInitial?: AgGridTableProps<TData>['selectedRowsInitial'];
};

type PinRowsTableProps<TData extends IBaseTData> = {
	rowsPinnable?: boolean;
	rowsPinnedInitial?: AgGridTableProps<TData>['rowsPinnedInitial'];
	onRowsPinnedChange?: AgGridTableProps<TData>['onRowsPinnedChange'];
};

type ManagementTableProps<TData extends IBaseTData> = {
	manageable?: boolean;
	customColumnsConfig?: ICustomColumnsConfig<TData>;
	panelsConfig?: IPanelsConfig;
	onColumnsChange?: (newColumns: IColumnDef<TData>[]) => void;
	rowsSettings?: IRowsSettingsConfig;
	onRowsSettingsChange?: (newSettings: IRowsSettingsConfig) => void;
	onResetSettings?: () => Promise<ITableSettings<TData>>;
};

export type TableProps<
	TData extends IBaseTData,
	TDetail extends IBaseTData = IBaseTData
> = BaseTableProps<TData, TDetail> &
	SearchTableProps<TData> &
	FilterTableProps &
	DensityPickTableProps &
	PaginationTableProps<TData> &
	SortTableProps<TData> &
	SelectionTable<TData> &
	PinRowsTableProps<TData> &
	ManagementTableProps<TData>;

export type ITableFiltersSchema = {
	schema: SchemaFormProps<ITableFilters>['schema'];
	uiSchema: SchemaFormProps<ITableFilters>['uiSchema'];
};

export type ISelectionAction<TData extends IBaseTData> =
	| ISelectionActionIcon<TData>
	| ISelectionActionText<TData>;

export interface ISelectionActionGroup<TData extends IBaseTData> {
	key: string;
	actions: ISelectionAction<TData>[];
}

export enum EDensityLevel {
	Low = 'low',
	Medium = 'medium',
	High = 'high'
}

export enum EManagementPanel {
	Manage = 'manage',
	Edit = 'edit',
	Add = 'add'
}

export enum EManageRowSettingKey {
	FROZEN_ROWS = 'frozen-rows'
}

export interface IAssetPropertyColumnConfigMetadata {
	properties: Record<string, Property>;
}
export interface IDatastreamColumnConfigMetadata {
	datastreams: Record<string, DataStream>;
	datastreamUnits: Record<string, Unit>;
}
export interface ILastControlChangeColumnConfigMetadata {
	datastreams: Record<string, DataStream>;
	datastreamUnits: Record<string, Unit>;
}
export interface IRecommendationColumnConfigMetadata {
	closedLoopSettings: IClosedLoopSettings;
	permissions?: ICoreAppPermissions;
	recommendationTypes: Record<string, RecommendationType>;
	applications: Record<string, AppItem>;
	application?: string;
}
export interface IApplicationParameterColumnConfigMetadata {
	closedLoopSettings: IClosedLoopSettings;
	parameters: IApplicationParameterDefinition;
	applications: Record<string, AppItem>;
	application?: string;
}
export interface IScheduleApplicationParametersColumnConfigMetadata {
	parameters: IApplicationParameterDefinition;
	closedLoopSettings: IClosedLoopSettings;
}

export type ICustomColumnConfigMetadata =
	| IAssetPropertyColumnConfigMetadata
	| IDatastreamColumnConfigMetadata
	| ILastControlChangeColumnConfigMetadata
	| IRecommendationColumnConfigMetadata
	| IApplicationParameterColumnConfigMetadata
	| IScheduleApplicationParametersColumnConfigMetadata;

export interface ICustomColumnConfig<
	TData extends IBaseTData,
	TCustomColumnData extends ICustomColumnData = ICustomColumnData,
	TCustomColumnMetadata extends ICustomColumnConfigMetadata = ICustomColumnConfigMetadata
> {
	type: ECustomColumnType;
	metadata: TCustomColumnMetadata;
	columnBuilder: (
		id: string,
		title: string,
		data: TCustomColumnData,
		metadata: TCustomColumnMetadata,
		customOptions?: IColumnOptions<TData>
	) => IColumnDef<TData>;
	columnIdBuilder: (
		columnDefs: IColumnDef<TData>[],
		data: TCustomColumnData,
		metadata: TCustomColumnMetadata
	) => string;
	columnTitleBuilder?: (
		columnDefs: IColumnDef<TData>[],
		data: TCustomColumnData,
		metadata: TCustomColumnMetadata
	) => string;
	validator?: (data: TCustomColumnData, metadata: TCustomColumnMetadata) => boolean;
}

export interface IDatastreamColumnConfig<TData extends IBaseTData>
	extends ICustomColumnConfig<TData, IDatastreamColumnData, IDatastreamColumnConfigMetadata> {
	type: ECustomColumnType.Datastream;
}

export interface IAssetPropertyColumnConfig<TData extends IBaseTData>
	extends ICustomColumnConfig<
		TData,
		IAssetPropertyColumnData,
		IAssetPropertyColumnConfigMetadata
	> {
	type: ECustomColumnType.AssetProperty;
}

export interface ILastControlChangeColumnConfig<TData extends IBaseTData>
	extends ICustomColumnConfig<
		TData,
		ILastControlChangeColumnData,
		ILastControlChangeColumnConfigMetadata
	> {
	type: ECustomColumnType.LastControlChange;
}

export interface IRecommendationColumnConfig<TData extends IBaseTData>
	extends ICustomColumnConfig<
		TData,
		IRecommendationColumnData,
		IRecommendationColumnConfigMetadata
	> {
	type: ECustomColumnType.Recommendation;
}

export interface IApplicationParameterColumnConfig<TData extends IBaseTData>
	extends ICustomColumnConfig<
		TData,
		IApplicationParameterColumnData,
		IApplicationParameterColumnConfigMetadata
	> {
	type: ECustomColumnType.ApplicationParameter;
}

export interface IScheduleApplicationParametersColumnConfig<TData extends IBaseTData>
	extends ICustomColumnConfig<
		TData,
		IScheduleApplicationParametersColumnData,
		IScheduleApplicationParametersColumnConfigMetadata
	> {
	type: ECustomColumnType.ScheduleApplicationParameters;
}

export interface ITableApi<TData extends IBaseTData> {
	refreshDatasource: () => void;
	setPinnedRows: (rows: TData[]) => void;
	clearPinnedRows: () => void;
	setSelectedRows: (rows: TData[]) => void;
	clearSelectedRows: () => void;
}

export interface IPanelConfig {
	title?: string;
	description?: string;
	tooltip?: string;
}

export interface ICustomColumnsConfig<TData extends IBaseTData> {
	[ECustomColumnType.Datastream]?: IDatastreamColumnConfig<TData>;
	[ECustomColumnType.AssetProperty]?: IAssetPropertyColumnConfig<TData>;
	[ECustomColumnType.LastControlChange]?: ILastControlChangeColumnConfig<TData>;
	[ECustomColumnType.Recommendation]?: IRecommendationColumnConfig<TData>;
	[ECustomColumnType.ApplicationParameter]?: IApplicationParameterColumnConfig<TData>;
	[ECustomColumnType.ScheduleApplicationParameters]?: IScheduleApplicationParametersColumnConfig<TData>;
}

export type IPanelsConfig = Partial<Record<EManagementPanel, IPanelConfig>>;
export type IRowsSettingsConfig = Record<EManageRowSettingKey, boolean>;

export interface IAssetPropertyAdvancedFilterConfigMetadata {
	properties: {
		definitions: Record<string, Property>;
		values: Record<string, string[] | boolean[]>;
	};
}

export interface IApplicationParameterAdvancedFilterConfigMetadata {
	applications: {
		definitions: Record<string, AppItem>;
		parameters: IApplicationParameterDefinition;
		values: IApplicationParameterValues;
	};
	application?: string;
	closedLoopSettings: IClosedLoopSettings;
}

export interface IDatastreamAdvancedFilterConfigMetadata {
	datastreams: {
		definitions: Record<string, DataStream>;
	};
}

export type IAdvancedFilterConfigMetadata =
	| IAssetPropertyAdvancedFilterConfigMetadata
	| IApplicationParameterAdvancedFilterConfigMetadata
	| IDatastreamAdvancedFilterConfigMetadata;

export interface IAdvancedFiltersConfig {
	[ETableAdvancedFilterType.AssetProperty]?: IAssetPropertyAdvancedFilterConfig;
	[ETableAdvancedFilterType.ApplicationParameter]?: IApplicationParameterAdvancedFilterConfig;
	[ETableAdvancedFilterType.Datastream]?: IDatastreamAdvancedFilterConfig;
}

export interface IAdvancedFilterConfig<
	TAdvancedFilterMetadata extends IAdvancedFilterConfigMetadata = IAdvancedFilterConfigMetadata
> {
	type: ETableAdvancedFilterType;
	metadata: TAdvancedFilterMetadata;
}

export interface IAssetPropertyAdvancedFilterConfig
	extends IAdvancedFilterConfig<IAssetPropertyAdvancedFilterConfigMetadata> {
	type: ETableAdvancedFilterType.AssetProperty;
}

export interface IApplicationParameterAdvancedFilterConfig
	extends IAdvancedFilterConfig<IApplicationParameterAdvancedFilterConfigMetadata> {
	type: ETableAdvancedFilterType.ApplicationParameter;
}

export interface IDatastreamAdvancedFilterConfig
	extends IAdvancedFilterConfig<IDatastreamAdvancedFilterConfigMetadata> {
	type: ETableAdvancedFilterType.Datastream;
}

export enum ETableSettingKey {
	ColumnsOrder = 'columnsOrder',
	ColumnsPinned = 'columnsPinned',
	ColumnsHidden = 'columnsHidden',
	ColumnsCustom = 'columnsCustom',
	Density = 'densityInitial',
	PageSize = 'pageSizeInitial',
	SortBy = 'sortByInitial',
	SortDirection = 'sortDirectionInitial',
	RowsSettings = 'rowsSettings',
	RowsPinned = 'rowsPinnedInitial',
	Filters = 'filtersInitial',
	AdvancedFilters = 'advancedFiltersInitial'
}

export interface ITableSettings<TData extends IBaseTData> {
	[ETableSettingKey.Density]?: EDensityLevel;
	[ETableSettingKey.ColumnsOrder]?: string[];
	[ETableSettingKey.ColumnsPinned]?: IColumnsPinConfig;
	[ETableSettingKey.ColumnsHidden]?: IColumnsHiddenConfig;
	[ETableSettingKey.ColumnsCustom]?: IColumnDef<TData>[];
	[ETableSettingKey.PageSize]?: number;
	[ETableSettingKey.SortBy]?: string[];
	[ETableSettingKey.SortDirection]?: ESortDirection;
	[ETableSettingKey.RowsSettings]?: IRowsSettingsConfig;
	[ETableSettingKey.RowsPinned]?: TData[];
	[ETableSettingKey.Filters]?: ITableFilters;
	[ETableSettingKey.AdvancedFilters]?: ITableAdvancedFilters;
}

export type ITablesSettings<TData extends IBaseTData> = Record<string, ITableSettings<TData>>;
