import { EIconName, EOtherIconName, ETagState, KvTagStatus } from '@kelvininc/react-ui-components';

import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export type InfoAlertProps = {
	alertMessage: string | JSX.Element;
	customClasses?: ClassNamesProp;
	icon?: EIconName | EOtherIconName;
	state?: ETagState;
};

export const InfoAlert = ({
	alertMessage,
	customClasses,
	icon = EIconName.Warning,
	state = ETagState.Warning
}: InfoAlertProps) => {
	return (
		<div className={classNames(styles.InfoAlert, customClasses)}>
			<KvTagStatus icon={icon} state={state} />
			<div className={styles.AlertMessage}>{alertMessage}</div>
		</div>
	);
};
