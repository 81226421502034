'use client';

import { useHydrateAtoms } from 'jotai/utils';
import { PropsWithChildren } from 'react';

import { appSettingsAtom, sessionAtom, userAtom } from '@/src/state';
import { SSRState } from '@/src/types';

type HydratorProps = {
	state: SSRState;
};

export const Hydrator = ({ children, state }: PropsWithChildren<HydratorProps>) => {
	useHydrateAtoms([
		[appSettingsAtom, state.appSettings],
		[userAtom, state.user],
		[sessionAtom, state.session]
	]);
	return <>{children}</>;
};
