import { Asset, AssetType } from '@kelvininc/node-client-sdk';
import { AtomEffect } from 'recoil';

import { getAssetTypesRequester, getAssetsRequester } from './requests';
import { assetsSelectorFamily } from './selectors';

export const syncAssetEffect: (id: string) => AtomEffect<Asset> =
	(id: string) =>
	({ setSelf, getPromise }) => {
		setSelf(getPromise(assetsSelectorFamily(id)));
	};

export const syncAssetsEffect: AtomEffect<Asset[]> = ({ setSelf }) => {
	setSelf(getAssetsRequester());
};

export const syncAssetTypeEffect: AtomEffect<AssetType[]> = ({ setSelf }) => {
	setSelf(getAssetTypesRequester());
};
