import {
	AppItem,
	Asset,
	ParameterDefinitionItem,
	ParameterSchedule,
	UserItem
} from '@kelvininc/node-client-sdk';
import { ClassAttributes } from '@kelvininc/types';

export type ParameterScheduleAttributes = ClassAttributes<ParameterSchedule>;

export enum EScheduleAction {
	Delete = 'delete',
	ApplyNow = 'applyNow'
}

export interface IApplicationScheduleDependencies {
	applications: Record<string, AppItem>;
	assets: Record<string, Asset>;
	users: Record<string, UserItem>;
	parameters: Record<string, ParameterDefinitionItem>;
}

export type AppVersionSchemaParams = {
	appName: string;
	appVersion: string;
};
