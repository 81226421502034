import { useMatch } from '@kelvininc/nextjs-utils/client';
import { EIconName } from '@kelvininc/react-ui-components';
import { IExpandableMenuItem } from '@kelvininc/shared-ui';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { useApplicationService } from '@/src/services/application-service';
import { appSettingsAtom } from '@/src/state';

import { buildAppRegistryRoute, buildClustersRoute } from '@/src/utils/routes';

export const useOrchestrationExpandableItems = (): IExpandableMenuItem[] => {
	const { isProfessionalEdition } = useApplicationService();

	const { grafanaLink } = useAtomValue(appSettingsAtom);

	const clusterUrl = buildClustersRoute();
	const appRegistryUrl = buildAppRegistryRoute();
	const isClusterUrl = !!useMatch(`${clusterUrl}/*`);
	const isAppRegistryUrl = !!useMatch(`${appRegistryUrl}/*`);

	return useMemo(
		() => [
			{
				id: 'clusters',
				text: 'Clusters',
				active: isClusterUrl || isAppRegistryUrl,
				url: clusterUrl,
				leftIcon: EIconName.Orchestration
			},
			{
				id: 'monitoring',
				text: 'Monitoring',
				url: !isProfessionalEdition ? grafanaLink : '',
				target: '_blank',
				leftIcon: EIconName.Monitoring,
				rightIcon: EIconName.ExternalLink,
				disabled: isProfessionalEdition
			}
		],
		[isClusterUrl, isAppRegistryUrl, clusterUrl, isProfessionalEdition, grafanaLink]
	);
};
