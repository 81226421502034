import { IWizard, KvWizard } from '@kelvininc/react-ui-components';
import { isEmpty } from 'lodash-es';
import { useCallback } from 'react';
import { FieldValues, FormState } from 'react-hook-form';

import { WizardStep } from './components';
import { IWizardStepConfig } from './components/WizardStep/types';
import {
	useCurrentStepState,
	useWizardFormContext,
	withCurrentStepContextProvider
} from './contexts';

import { useWizardCloseModalConfirmationAlert } from './hooks/useWizardCloseModalConfirmationAlert';
import styles from './styles.module.scss';
import { buildWizardSteps, getStep, getStepIndex } from './utils';

export interface WizardFormContextProps<T extends FieldValues>
	extends Omit<IWizard, 'steps' | 'currentStep' | 'currentStepState'> {
	steps: Record<string, IWizardStepConfig>;
	isStepValid: (step: IWizardStepConfig, values: T, formState?: FormState<T>) => boolean;
	onCancelClick: () => void;
	onCompleteClick: () => void;
}

const Component = <T extends FieldValues>({
	steps,
	isStepValid,
	onCancelClick,
	onCompleteClick,
	...otherProps
}: WizardFormContextProps<T>) => {
	const { clearErrors } = useWizardFormContext<T>();
	const { currentStep, currentStepState, setCurrentStep } = useCurrentStepState(isStepValid);
	const onStepChange = useCallback(
		(newStep: number) => {
			const currentStepIndex = getStepIndex(steps, currentStep);
			clearErrors();
			if (
				currentStepIndex > newStep &&
				currentStep.allowGoBack &&
				currentStep.goBackTo &&
				steps[currentStep.goBackTo]
			) {
				setCurrentStep(steps[currentStep.goBackTo]);
			} else {
				setCurrentStep(getStep(steps, newStep));
			}
		},
		[steps, currentStep, clearErrors, setCurrentStep]
	);
	useWizardCloseModalConfirmationAlert();

	return (
		<div className={styles.WizardFormContext}>
			{!isEmpty(currentStep) && (
				<KvWizard
					{...otherProps}
					steps={buildWizardSteps(steps)}
					currentStep={getStepIndex(steps, currentStep)}
					currentStepState={currentStepState}
					onGoToStep={({ detail: newStepIndex }) => onStepChange(newStepIndex)}
					onCancelClick={onCancelClick}
					onCompleteClick={onCompleteClick}>
					<div className={styles.StepContainer} slot="step-content">
						{Object.values(steps).map(({ id, Component: StepComponent }) => (
							<WizardStep key={id} stepKey={id} activeStep={currentStep}>
								<StepComponent />
							</WizardStep>
						))}
					</div>
				</KvWizard>
			)}
		</div>
	);
};

export const WizardFormContext = withCurrentStepContextProvider(Component);
