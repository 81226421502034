import { INavigationSidebarState, syncUserSettingInitialValue } from '@kelvininc/shared-ui';
import { atom } from 'recoil';

import {
	NAVIGATION_SIDEBAR_STATE_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_STATE_USER_SETTING_KEY
} from './config';

export const navigationSidebarStateUserSettingAtom = atom<INavigationSidebarState>({
	key: 'navigationSidebarStateUserSettingAtom',
	effects: [
		syncUserSettingInitialValue(
			NAVIGATION_SIDEBAR_STATE_USER_SETTING_KEY,
			NAVIGATION_SIDEBAR_STATE_USER_SETTING_DEFAULT
		)
	]
});
