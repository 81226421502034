import { useCallback } from 'react';
import { FieldValues, UseFormReturn, UseFormSetValue, useFormContext } from 'react-hook-form';

export const useWizardFormContext = <T extends FieldValues>(): UseFormReturn<T> => {
	const { setValue, ...otherProps } = useFormContext<T>();

	// We need to a custom set value since the native
	// doesn't set a value as dirty by default
	const customSetValue: UseFormSetValue<T> = useCallback(
		(...args) => {
			const [name, value, options] = args;
			setValue(name, value, { shouldDirty: true, shouldTouch: true, ...options });
		},
		[setValue]
	);

	return { ...otherProps, setValue: customSetValue };
};
