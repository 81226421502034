'use client';
import { IRange } from '@kelvininc/react-ui-components';
import { isNil } from 'lodash-es';

export const getValidInputRangeValue = (config: IRange, value?: number): number => {
	if (isNil(value) || value < config.min || value > config.max) {
		return config.min;
	}
	return value;
};
