import {
	ControlChangeGet,
	EKrnResource,
	KvKRNParser,
	Recommendation,
	Workload
} from '@kelvininc/node-client-sdk';

export const useControlChangeCreatorTitle = (
	controlChange: ControlChangeGet,
	creator?: Workload | Recommendation
): string | undefined => {
	if (!creator || creator instanceof Workload) {
		return controlChange.createdBy;
	}

	try {
		const actionLog = creator.logs.at(1);
		if (!actionLog) {
			throw new Error('No log available for the provided recommendation');
		}

		const {
			resourceContent: { user }
		} = KvKRNParser.parseKRN<EKrnResource.User>(actionLog.source);

		return user;
	} catch (error) {
		return controlChange.createdBy;
	}
};
