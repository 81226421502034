import { EActionButtonType, EIconName, KvActionButtonIcon } from '@kelvininc/react-ui-components';
import { Skeleton, useSidepanel } from '@kelvininc/shared-ui';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';

import { IBaseTData } from '../../../AgGridTable';
import { useSelectedRows, useSelectionActions } from '../../contexts/TableSelectionContext';
import { useIsStateEmptyOrError, useIsStateOnInitResults } from '../../contexts/TableStateContext';
import { TableProps } from '../../types';
import { getManagementSidepanelKey } from '../../utils';

import {
	ActionsManager,
	DensityManager,
	SearchManager,
	SelectionManager,
	TableFilters
} from './components';

import styles from './styles.module.scss';

export type TableHeaderProps<TData extends IBaseTData> = Pick<
	TableProps<TData>,
	| 'id'
	| 'title'
	| 'actions'
	| 'subtitle'
	| 'densityPickable'
	| 'searchable'
	| 'searchPlaceholder'
	| 'searchDebounce'
	| 'filterable'
	| 'filtersSchema'
	| 'filtersDisabled'
	| 'unclearableFilters'
	| 'advancedFiltersDisabled'
	| 'advancedFiltersConfig'
	| 'manageable'
	| 'onSearchChange'
	| 'checkboxSelectable'
>;

export const TableHeader = <TData extends IBaseTData>({
	id = '',
	title,
	actions = [],
	subtitle,
	densityPickable,
	searchable,
	searchPlaceholder,
	searchDebounce,
	filtersSchema,
	filtersDisabled,
	unclearableFilters,
	advancedFiltersDisabled,
	advancedFiltersConfig,
	filterable,
	manageable,
	onSearchChange,
	checkboxSelectable
}: TableHeaderProps<TData>) => {
	const { toggle } = useSidepanel();

	const [rows] = useSelectedRows();
	const isDisabled = useIsStateEmptyOrError();
	const isOnTableStart = useIsStateOnInitResults();
	const actionsGroups = useSelectionActions<TData>();
	const isFiltersDisabled = isDisabled || filtersDisabled;
	const isAdvancedFiltersDisabled = isDisabled || advancedFiltersDisabled;
	const isAnyRowSelected = useMemo(() => rows.length > 0, [rows]);
	const hasActions = useMemo(() => actions.length > 0, [actions]);

	const managementSidepanelKey = useMemo(() => getManagementSidepanelKey(id), [id]);

	const shouldRenderTableHeader =
		!isEmpty(title) ||
		!isEmpty(subtitle) ||
		searchable ||
		densityPickable ||
		filterable ||
		manageable ||
		hasActions ||
		(checkboxSelectable && !isEmpty(actionsGroups));

	const shouldRenderActions = checkboxSelectable && !isEmpty(actionsGroups) && isAnyRowSelected;

	if (!shouldRenderTableHeader) {
		return null;
	}

	if (isOnTableStart) {
		return (
			<div className={styles.TableHeader}>
				{(title || subtitle) && <Skeleton className={styles.TitleRowSkeleton} />}
				<div className={styles.ActionsRowSkeleton}>
					<Skeleton />
				</div>
			</div>
		);
	}

	return (
		<div data-test-id="e2e-table-widget-header" className={styles.TableHeader}>
			{(title || subtitle) && (
				<div className={styles.TitleWrapper}>
					<div className={styles.HeaderTitles}>
						{title && <div className={styles.Title}>{title}</div>}
						{subtitle && (
							<>
								<div className={styles.Separator} />
								<div className={styles.Subtitle}>{subtitle}</div>
							</>
						)}
					</div>
				</div>
			)}
			<div className={styles.Actions}>
				<div className={styles.LeftActions}>
					{shouldRenderActions ? (
						<div className={styles.ActionsGroup}>
							<SelectionManager />
						</div>
					) : (
						filterable && (
							<TableFilters
								filtersSchema={filtersSchema}
								unclearableFilters={unclearableFilters}
								filtersDisabled={isFiltersDisabled}
								advancedFiltersDisabled={isAdvancedFiltersDisabled}
								advancedFiltersConfig={advancedFiltersConfig}
							/>
						)
					)}
				</div>
				<div className={styles.RightActions}>
					{searchable && (
						<div className={classNames(styles.ActionsGroup, styles.SearchInput)}>
							<SearchManager
								searchPlaceholder={searchPlaceholder}
								searchDebounce={searchDebounce}
								onSearchChange={onSearchChange}
							/>
						</div>
					)}
					{densityPickable && (
						<div className={styles.ActionsGroup}>
							<DensityManager />
						</div>
					)}
					{!shouldRenderActions && (
						<>
							{manageable && (
								<div className={styles.ActionsGroup}>
									<div className={styles.ToggleButtonsGroup}>
										<KvActionButtonIcon
											icon={EIconName.SettingsSuggest}
											type={EActionButtonType.Tertiary}
											onClickButton={() => toggle(managementSidepanelKey)}
										/>
									</div>
								</div>
							)}
							{hasActions && (
								<div
									data-test-id="e2e-table-header-actions"
									className={styles.ActionsGroup}>
									<ActionsManager actions={actions} />
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
