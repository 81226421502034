import { FunctionComponent, PropsWithChildren } from 'react';
import { FieldValues, FormProvider, UseFormProps } from 'react-hook-form';

import { useWizardFormConfig } from '../../hooks/useWizardFormConfig';

export const WizardFormContextProvider = <T extends FieldValues>({
	children,
	...otherProps
}: PropsWithChildren<UseFormProps<T>>) => {
	const config = useWizardFormConfig<T>(otherProps);

	return <FormProvider {...config}>{children}</FormProvider>;
};

export const withWizardFormContextProvider = <T, R extends FieldValues>(
	Component: FunctionComponent<T>,
	config?: UseFormProps<R>
) => {
	return function WizardFormContextProviderWrapper(componentProps: PropsWithChildren<T>) {
		return (
			<WizardFormContextProvider {...config}>
				<Component {...componentProps} />
			</WizardFormContextProvider>
		);
	};
};
