import { RadioButton } from '../../../RadioButtons';

export const CONTROL_RADIO_BUTTONS = [
	{
		label: 'Enabled',
		value: true
	},
	{
		label: 'Disabled',
		value: false
	}
] as const satisfies RadioButton<boolean>[];
