import { EIconName, KvIcon } from '@kelvininc/react-ui-components';

import classNames from 'classnames';

import styles from './styles.module.scss';

type SortingIndicatorProps = {
	isSorting: boolean;
	isSortAscending: boolean;
};

export const SortingIndicator = ({ isSorting, isSortAscending }: SortingIndicatorProps) => {
	return (
		<span className="ag-sort-indicator-container">
			{isSorting ? (
				<>
					<span
						className={classNames('ag-sort-indicator-icon ag-sort-ascending-icon', {
							'ag-hidden': !isSortAscending
						})}>
						<KvIcon name={EIconName.SortAz} class={styles.Icon} />
					</span>
					<span
						className={classNames('ag-sort-indicator-icon ag-sort-descending-icon', {
							'ag-hidden': isSortAscending
						})}>
						<KvIcon name={EIconName.SortZa} class={styles.Icon} />
					</span>
				</>
			) : (
				<span className="ag-sort-indicator-icon ag-sort-hover-icon">
					<KvIcon name={EIconName.SortHover} class={styles.Icon} />
				</span>
			)}
		</span>
	);
};
