import { UserMeGet } from '@kelvininc/node-client-sdk';

import { Session } from 'next-auth';

import { CoreAppSettings } from '.';

export type SSRState = {
	appSettings: CoreAppSettings;
	user?: UserMeGet | null;
	session?: Session | null;
};

export enum EAssetControlQueryParamsName {
	Redirect = 'redirect',
	Search = 'search'
}

export enum EPlatformArea {
	Admin = 'admin',
	Operations = 'operations'
}
