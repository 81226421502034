import { UserMeGet } from '@kelvininc/node-client-sdk';
import { useAtomValue } from 'jotai';

import { userAtom } from '@/src/state';

export const useCurrentNullableUser = () => {
	const user = useAtomValue(userAtom);
	return user;
};

export const useCurrentUser = () => {
	const user = useAtomValue(userAtom);
	return user as UserMeGet;
};
