import { isEqual } from 'lodash-es';

import {
	ECustomColumnType,
	IApplicationParameterColumnData,
	IAssetPropertyColumnData,
	IBaseTData,
	IColumnDef,
	IColumnOptions,
	IDatastreamColumnData,
	ILastControlChangeColumnData,
	IRecommendationColumnData
} from '../../../../../AgGridTable';

export const hasColumnChanges = <TData extends IBaseTData>(
	column?: IColumnDef<TData>,
	newColumnTitle?: string,
	newColumnType?: ECustomColumnType,
	columnOptions?: IColumnOptions<TData>,
	newAssetPropertyData?: IAssetPropertyColumnData,
	newDatastreamData?: IDatastreamColumnData,
	newLastControlChangeData?: ILastControlChangeColumnData,
	newRecommendationData?: IRecommendationColumnData,
	newApplicationParameterData?: IApplicationParameterColumnData,
	newScheduleApplicationParametersData?: IApplicationParameterColumnData
): boolean => {
	if (
		column?.title !== newColumnTitle ||
		column?.metadata?.type !== newColumnType ||
		column?.hide !== columnOptions?.hide
	) {
		return true;
	}

	if (!column?.metadata?.type) {
		return false;
	}

	switch (column.metadata.type) {
		case ECustomColumnType.AssetProperty:
			return !isEqual(column.metadata.data, newAssetPropertyData);
		case ECustomColumnType.Datastream:
			return !isEqual(column.metadata.data, newDatastreamData);
		case ECustomColumnType.LastControlChange:
			return !isEqual(column.metadata.data, newLastControlChangeData);
		case ECustomColumnType.Recommendation:
			return !isEqual(column.metadata.data, newRecommendationData);
		case ECustomColumnType.ApplicationParameter:
			return !isEqual(column.metadata.data, newApplicationParameterData);
		case ECustomColumnType.ScheduleApplicationParameters:
			return !isEqual(column.metadata.data, newScheduleApplicationParametersData);
	}
};

export const getEditedColumnIndex = <TData extends IBaseTData>(
	columnDefs: IColumnDef<TData>[],
	columnId?: string
): number => columnDefs.findIndex(({ id }) => id === columnId);
