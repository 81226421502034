import {
	EActionButtonType,
	EIllustrationName,
	KvActionButtonText,
	KvIllustration
} from '@kelvininc/react-ui-components';

import classNames from 'classnames';

import styles from './styles.module.scss';
import { IOverlayAction } from './types';

type OverlaysConfig = {
	title?: string;
	description?: string;
	illustration?: EIllustrationName;
	action?: IOverlayAction;
	customClasses?: string;
};

export const BaseOverlay = ({
	title,
	description,
	illustration,
	action,
	customClasses
}: OverlaysConfig) => {
	return (
		<div className={classNames(styles.BaseOverlay, customClasses)}>
			{illustration && (
				<div data-test-id="e2e-overlay-illustration" className={styles.Illustration}>
					<KvIllustration name={illustration} />
				</div>
			)}
			{title && (
				<div className={styles.Title}>
					<h1>{title}</h1>
				</div>
			)}
			{description && (
				<div className={styles.Description}>
					<h3>{description}</h3>
				</div>
			)}
			{action && (
				<div className={styles.ActionButton}>
					<KvActionButtonText
						icon={action.icon}
						text={action.text}
						type={action.type ?? EActionButtonType.Tertiary}
						onClickButton={action.onClick}
					/>
				</div>
			)}
		</div>
	);
};
