import {
	EColumnPinAlignment,
	IBaseTData,
	IColumnDef,
	IColumnsHiddenConfig,
	IColumnsPinConfig
} from '@kelvininc/table';

export const buildColumnsPinned = <TData extends IBaseTData = IBaseTData>(
	columnDefs: IColumnDef<TData>[] = []
): IColumnsPinConfig =>
	columnDefs.reduce<IColumnsPinConfig>(
		(accumulator, { id, pinned }) => ({
			...accumulator,
			[id]: pinned as EColumnPinAlignment | boolean | undefined
		}),
		{}
	);

export const buildColumnsHidden = <TData extends IBaseTData = IBaseTData>(
	columnDefs: IColumnDef<TData>[] = []
): IColumnsHiddenConfig =>
	columnDefs.reduce<IColumnsHiddenConfig>(
		(accumulator, { id, hide }) => ({
			...accumulator,
			[id]: hide === true
		}),
		{}
	);

export const buildColumnsOrder = <TData extends IBaseTData = IBaseTData>(
	columnDefs: IColumnDef<TData>[] = []
): string[] => columnDefs.map(({ id }) => id);

export const filterCustomColumns = <TData extends IBaseTData = IBaseTData>(
	columnDefs: IColumnDef<TData>[]
): IColumnDef<TData>[] => columnDefs.filter(({ metadata }) => metadata !== undefined);
