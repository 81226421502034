import { ParameterDefinitionItem } from '@kelvininc/node-client-sdk';
import { EComponentSize, KvSingleSelectDropdown } from '@kelvininc/react-ui-components';
import { useDropdownSearchOptions } from '@kelvininc/shared-ui';
import { getParameterTitle } from '@kelvininc/tsutils';

import { IClosedLoopSettings, UseDropdownOptions } from '@kelvininc/types';

import { useMemo } from 'react';

import {
	DEFAULT_DROPDOWN_FILTER_MAX_WIDTH,
	DEFAULT_DROPDOWN_FILTER_MIN_WIDTH
} from '../../../../../../../../../../../config';
import {
	SELECT_APPLICATION_DROPDOWN_OPTIONS,
	SELECT_APPLICATION_PARAMETER_NO_DATA_AVAILABLE_CONFIG,
	SELECT_APPLICATION_PARAMETER_PLACEHOLDER,
	SELECT_APPLICATION_PARAMETER_SEARCH_PLACEHOLDER
} from '../../config';

import styles from './styles.module.scss';

type ApplicationParameterDropdownProps = {
	parameter?: string;
	parameters?: Record<string, ParameterDefinitionItem>;
	onChangeParameter: (newParameter: string) => void;
	closedLoopSettings: IClosedLoopSettings;
};

export const ApplicationParameterDropdown = ({
	parameter,
	parameters = {},
	onChangeParameter,
	closedLoopSettings
}: ApplicationParameterDropdownProps) => {
	const data = useMemo(() => Object.values(parameters), [parameters]);
	const dropdownOptions = useMemo<UseDropdownOptions<ParameterDefinitionItem>>(
		() => ({
			key: 'name',
			value: 'name',
			label: (item) => getParameterTitle(item, closedLoopSettings)
		}),
		[closedLoopSettings]
	);

	const { options, filteredOptions, setSearchTerm } = useDropdownSearchOptions(
		data,
		dropdownOptions
	);

	return (
		<KvSingleSelectDropdown
			className={styles.ParameterInput}
			options={options}
			dropdownOptions={SELECT_APPLICATION_DROPDOWN_OPTIONS}
			placeholder={SELECT_APPLICATION_PARAMETER_PLACEHOLDER}
			noDataAvailableConfig={SELECT_APPLICATION_PARAMETER_NO_DATA_AVAILABLE_CONFIG}
			searchPlaceholder={SELECT_APPLICATION_PARAMETER_SEARCH_PLACEHOLDER}
			filteredOptions={filteredOptions}
			selectedOption={parameter}
			onSearchChange={({ detail: newSearchTerm }) => setSearchTerm(newSearchTerm)}
			onOptionSelected={({ detail: newParameter }) => onChangeParameter(newParameter)}
			inputSize={EComponentSize.Small}
			maxWidth={DEFAULT_DROPDOWN_FILTER_MAX_WIDTH}
			minWidth={DEFAULT_DROPDOWN_FILTER_MIN_WIDTH}
		/>
	);
};
