'use client';
import {
	EActionButtonType,
	EComponentSize,
	ETooltipPosition,
	KvActionButtonText,
	KvTooltip
} from '@kelvininc/react-ui-components';
import { Link } from '@kelvininc/shared-ui';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { EEntityHeaderActionType, IEntityHeaderAction } from './types';

import { Hyperlink } from '@/src/components/client/Hyperlink';

export type EntityHeaderActionsProps = {
	actions: IEntityHeaderAction[];
};

export const EntityHeaderActions = ({ actions }: EntityHeaderActionsProps) => {
	return (
		<div className={styles.ActionContainer}>
			{actions.map(
				(
					{
						id,
						url,
						actionType,
						text,
						icon,
						target,
						onClickAction,
						buttonType = EActionButtonType.Tertiary,
						customClasses,
						disabled,
						tooltip = ''
					},
					index
				) => {
					return (
						<div id={id} key={index}>
							<KvTooltip text={tooltip} position={ETooltipPosition.Bottom}>
								{actionType === EEntityHeaderActionType.Button ? (
									<Link
										href={url}
										disabled={disabled}
										className={classNames(customClasses)}>
										<KvActionButtonText
											text={text || ''}
											icon={icon}
											size={EComponentSize.Large}
											type={buttonType}
											disabled={disabled}
											onClickButton={onClickAction}
										/>
									</Link>
								) : (
									<Hyperlink
										className={classNames(customClasses)}
										href={url}
										target={target}
										text={text}
										icon={icon}
										disabled={disabled}
										onClick={onClickAction}
									/>
								)}
							</KvTooltip>
						</div>
					);
				}
			)}
		</div>
	);
};
