'use client';
import { App, AppRegistryService, AppVersion, EKvHttpStatusCode } from '@kelvininc/node-client-sdk';
import {
	catchHttpError,
	compareVersionStrings,
	fromApplicationKey,
	throwHttpError
} from '@kelvininc/tsutils';
import { selectorFamily } from 'recoil';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

export const applicationVersionsListSelector = selectorFamily<AppVersion[], string | undefined>({
	key: 'applicationVersionsListSelector',
	get: (appName) => () => {
		if (!appName) return [];

		return AppRegistryService.getAppRegistryApp({ appName })
			.pipe(
				map(({ versions }: App) =>
					versions.sort((versionA, versionB) =>
						compareVersionStrings(versionA.version, versionB.version, false)
					)
				),
				catchHttpError((error) => {
					if (error.status === EKvHttpStatusCode.NotFound) {
						return of([]);
					}

					return throwHttpError(error);
				})
			)
			.toPromise();
	}
});

export const applicationVersionSelector = selectorFamily<
	AppVersion | undefined,
	string | undefined
>({
	key: 'applicationVersionSelector',
	get: (appVersionKey) => () => {
		if (!appVersionKey) return undefined;
		const [appName, appVersion] = fromApplicationKey(appVersionKey);
		if (!appName || !appVersion) return undefined;

		return AppRegistryService.getAppVersion({ appName, appVersion })
			.pipe(
				catchHttpError((error) => {
					if (error.status === EKvHttpStatusCode.NotFound) {
						return of(undefined);
					}

					return throwHttpError(error);
				})
			)
			.toPromise();
	}
});
