import { AssetInsightsItem } from '@kelvininc/node-client-sdk';

import {
	ControlChangeCellRenderer,
	DateTimeCellRenderer,
	ECellAlignment,
	EColumnDataType,
	IColumnOptions,
	ParameterCellRenderer,
	RecommendationCellRenderer,
	ScheduleParametersCellRenderer,
	TextCellRenderer
} from '@kelvininc/table';

export const DEFAULT_NUMERIC_COLUMN_OPTIONS: IColumnOptions<AssetInsightsItem> = {
	cellComponent: TextCellRenderer,
	cellAlignment: ECellAlignment.Right,
	headerAlignment: ECellAlignment.Right,
	headerComponentParams: {
		columnDataType: EColumnDataType.Numeric
	}
};

export const DEFAULT_TIMESTAMP_COLUMN_OPTIONS: IColumnOptions<AssetInsightsItem> = {
	cellComponent: DateTimeCellRenderer,
	headerComponentParams: {
		columnDataType: EColumnDataType.Time
	}
};

export const DEFAULT_PARAMETER_COLUMN_OPTIONS: IColumnOptions<AssetInsightsItem> = {
	cellComponent: ParameterCellRenderer
};

export const DEFAULT_NON_NUMERIC_COLUMN_OPTIONS: IColumnOptions<AssetInsightsItem> = {};

export const DEFAULT_LAST_CONTROL_CHANGE_COLUMN_OPTIONS: IColumnOptions<AssetInsightsItem> = {
	cellComponent: ControlChangeCellRenderer,
	minWidth: 240,
	headerComponentParams: {
		columnDataType: EColumnDataType.Time
	}
};

export const DEFAULT_RECOMMENDATION_COLUMN_OPTIONS: IColumnOptions<AssetInsightsItem> = {
	cellComponent: RecommendationCellRenderer,
	minWidth: 300,
	headerComponentParams: {
		columnDataType: EColumnDataType.Time
	}
};

export const DEFAULT_SCHEDULE_APPLICATION_PARAMETERS_COLUMN_OPTIONS: IColumnOptions<AssetInsightsItem> =
	{
		cellComponent: ScheduleParametersCellRenderer,
		minWidth: 290,
		headerComponentParams: {
			columnDataType: EColumnDataType.Time
		}
	};
