import { ITimePickerTimeState } from '@kelvininc/react-ui-components';

import { RelativeTimePickerRange } from './types';

export const DEFAULT_RELATIVE_TIME_KEY = 'last-30-d';
export const DEFAULT_SELECTED_TIME: ITimePickerTimeState = {
	key: DEFAULT_RELATIVE_TIME_KEY,
	range: []
};

export const DASBHBOARD_TRANSITION = 'all 0.3 ease-in';
export const DASHBOARD_BACKGROUND_COLOR = '#121212';
export const DASHBOARD_PADDING = '0 12px 0 0';

export const CUSTOMIZE_INTERVAL_KEY = 'customize-interval';

export const TIME_PICKER_RELATIVE_TIME_KEYS_RANGE: Record<string, RelativeTimePickerRange> = {
	today: {
		from: 'now/d',
		to: 'now'
	},
	yesterday: {
		from: 'now-1d/d',
		to: 'now-1d/d'
	},
	'last-24-h': {
		from: 'now-24h',
		to: 'now'
	},
	'last-48-h': {
		from: 'now-48h',
		to: 'now'
	},
	'last-7-d': {
		from: 'now-7d',
		to: 'now'
	},
	'last-30-d': {
		from: 'now-30d',
		to: 'now'
	},
	'last-90-d': {
		from: 'now-90d',
		to: 'now'
	},
	'last-6-m': {
		from: 'now-6M/M',
		to: 'now'
	},
	'last-5-m': {
		from: 'now-5m',
		to: 'now'
	},
	'last-15-m': {
		from: 'now-15m',
		to: 'now'
	},
	'last-30-m': {
		from: 'now-30m',
		to: 'now'
	},
	'last-1-h': {
		from: 'now-1h',
		to: 'now'
	},
	'last-3-h': {
		from: 'now-3h',
		to: 'now'
	},
	'last-6-h': {
		from: 'now-6h',
		to: 'now'
	},
	'last-12-h': {
		from: 'now-12h',
		to: 'now'
	},
	'last-365-d': {
		from: 'now-365d',
		to: 'now'
	},
	'last-2-y': {
		from: 'now-2y',
		to: 'now'
	},
	'this-week-so-far': {
		from: 'now/w',
		to: 'now'
	},
	'this-month-so-far': {
		from: 'now/M',
		to: 'now'
	},
	'this-quarter-so-far': {
		from: 'now/fQ',
		to: 'now'
	},
	'this-year-so-far': {
		from: 'now/y',
		to: 'now'
	}
};
