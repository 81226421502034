import { ParameterDefinitionItem } from '@kelvininc/node-client-sdk';
import { SchemaFormProps } from '@kelvininc/react-ui-components';
import {
	buildClosedLoopDefinition,
	isKelvinClosedLoopParameter,
	isParameterPrimitiveTypeBoolean,
	isParameterPrimitiveTypeNumber,
	isParameterPrimitiveTypeString
} from '@kelvininc/tsutils';
import { IClosedLoopSettings } from '@kelvininc/types';
import { isEmpty, merge } from 'lodash-es';

import {
	MINIMUM_MULTI_SELECTABLE_OPTIONS,
	PRIMITIVE_TYPE_BOOLEAN_FILTER_SCHEMA,
	PRIMITIVE_TYPE_NUMBER_FILTER_SCHEMA,
	PRIMITIVE_TYPE_STRING_ARRAY_FILTER_SCHEMA,
	PRIMITIVE_TYPE_STRING_FILTER_SCHEMA
} from '../../../../config';
import { ApplicationParameterAdvancedFilterSchemaFormData } from '../../types';

export const getParameterSchemaForm = (
	parameter: ParameterDefinitionItem,
	values: string[] | boolean[] = [],
	closedLoopSettings: IClosedLoopSettings
): SchemaFormProps<ApplicationParameterAdvancedFilterSchemaFormData>['schema'] => {
	if (isParameterPrimitiveTypeNumber(parameter.primitiveType)) {
		return PRIMITIVE_TYPE_NUMBER_FILTER_SCHEMA;
	}

	if (isParameterPrimitiveTypeBoolean(parameter.primitiveType)) {
		if (isKelvinClosedLoopParameter(parameter.name)) {
			return merge({}, PRIMITIVE_TYPE_BOOLEAN_FILTER_SCHEMA, {
				definitions: {
					boolean_value: buildClosedLoopDefinition(closedLoopSettings)
				}
			});
		}

		return PRIMITIVE_TYPE_BOOLEAN_FILTER_SCHEMA;
	}

	if (isParameterPrimitiveTypeString(parameter.primitiveType)) {
		if (isEmpty(values)) {
			return PRIMITIVE_TYPE_STRING_FILTER_SCHEMA;
		}

		if (values.length < MINIMUM_MULTI_SELECTABLE_OPTIONS) {
			return merge({}, PRIMITIVE_TYPE_STRING_ARRAY_FILTER_SCHEMA, {
				definitions: {
					string_options_value: {
						type: 'string',
						enum: values
					}
				}
			});
		}

		return merge({}, PRIMITIVE_TYPE_STRING_ARRAY_FILTER_SCHEMA, {
			definitions: {
				string_options_value: {
					type: 'array',
					uniqueItems: true,
					items: {
						type: 'string',
						enum: values
					}
				}
			}
		});
	}

	return PRIMITIVE_TYPE_STRING_FILTER_SCHEMA;
};
