import { FunctionComponent } from '@kelvininc/types';
import { Context, PropsWithChildren, Provider, createContext, useContext, useRef } from 'react';

import { IAgGridTableApi, IBaseTData } from '../../../../../core/components/AgGridTable/types';

import { ITableApiRefContextValues } from './types';

const TableApiRefContext: Context<null | ITableApiRefContextValues<IBaseTData>> =
	createContext<null | ITableApiRefContextValues<IBaseTData>>(null);

export const TableApiRefProvider = <TData extends IBaseTData>({
	children
}: PropsWithChildren<{}>) => {
	const ref = useRef<IAgGridTableApi<IBaseTData>>(null);
	const ContextProvider = useTableApiRefContextProvider<TData>();

	return <ContextProvider value={{ ref }}>{children}</ContextProvider>;
};

export const useApiRefContext = <TData extends IBaseTData>(): ITableApiRefContextValues<TData> => {
	const context = useContext(TableApiRefContext);

	if (!context) {
		throw new Error('Missing table api ref context');
	}

	return context;
};

export const useApiRef = <TData extends IBaseTData>(): React.RefObject<IAgGridTableApi<TData>> => {
	const { ref } = useApiRefContext();

	return ref;
};

export const useTableApiRefContextProvider = <
	TData extends IBaseTData
>(): Provider<ITableApiRefContextValues<TData> | null> => {
	return TableApiRefContext.Provider as Provider<ITableApiRefContextValues<TData> | null>;
};

export const withApiRefProvider = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>
) => {
	return function TableApiRefProviderWrapper(componentProps: PropsWithChildren<ComponentProps>) {
		return (
			<TableApiRefProvider>
				<Component {...componentProps} />
			</TableApiRefProvider>
		);
	};
};
