import { Property } from '@kelvininc/node-client-sdk';

import { EComponentSize, KvSchemaForm, KvTextField } from '@kelvininc/react-ui-components';

import { ETableAdvancedFilterType } from '../../../../../../../../../../../core/components/AgGridTable/types';
import { convertFilterToPrimitiveType } from '../../../../../../../../../../../utils';
import { SELECT_OPERATOR_PLACEHOLDER } from '../../../../config';

import { getFilterUiSchema } from '../../../../utils';
import { AssetPropertyAdvancedFilterSchemaFormData } from '../../types';

import styles from './styles.module.scss';

import { getPropertySchemaForm } from './utils';

type AssetPropertySchemaFormProps = {
	formData?: AssetPropertyAdvancedFilterSchemaFormData;
	property?: string;
	properties: Record<string, Property>;
	values: Record<string, string[] | boolean[]>;
	onChangeForm: (newForm: AssetPropertyAdvancedFilterSchemaFormData) => void;
};

export const AssetPropertySchemaForm = ({
	formData,
	property,
	properties,
	values,
	onChangeForm
}: AssetPropertySchemaFormProps) => {
	if (!property || !properties[property]) {
		return (
			<KvTextField
				className={styles.OperatorInput}
				placeholder={SELECT_OPERATOR_PLACEHOLDER}
				size={EComponentSize.Small}
				inputDisabled
			/>
		);
	}

	const propertyDefinition = properties[property];
	const propertyValues = values[property];

	const uiSchema = getFilterUiSchema(propertyDefinition.primitiveType, propertyValues);
	const schema = getPropertySchemaForm(propertyDefinition, propertyValues);

	return (
		<KvSchemaForm
			schema={schema}
			uiSchema={uiSchema}
			formData={formData}
			submittedData={formData}
			onChange={({ formData: { operator, value } = {} }) => {
				onChangeForm({
					operator,
					value: convertFilterToPrimitiveType(
						value ?? undefined,
						ETableAdvancedFilterType.AssetProperty,
						propertyDefinition.primitiveType
					)
				});
			}}
			customClass={styles.SchemaForm}
		/>
	);
};
