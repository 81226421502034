import { groupBy } from 'lodash-es';

import { IBaseTData } from '../../../../../AgGridTable';
import { ISelectionAction } from '../../../../types';

export const groupActionsByGroup = <TData extends IBaseTData>(
	actions: ISelectionAction<TData>[]
): Record<string, ISelectionAction<TData>[]> => {
	return groupBy(actions, ({ group }) => group ?? 'unknown');
};
