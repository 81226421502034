'use client';
import { ITabNavigationItem } from '@kelvininc/react-ui-components';
import { NavLinks } from '@kelvininc/shared-ui';
import { usePathname } from 'next/navigation';

import styles from './styles.module.scss';

import { useNavigate } from '@/src/hooks';

type EntityModelHeaderProps = {
	tabs?: ITabNavigationItem[];
};

export const EntityModelHeader = ({ tabs }: EntityModelHeaderProps) => {
	const pathname = usePathname();
	const navigate = useNavigate();

	if (!tabs?.length) {
		return null;
	}

	return (
		<div className={styles.EntityModelHeader}>
			<NavLinks
				items={tabs}
				selectedTab={decodeURIComponent(pathname)}
				onClickTab={(tab) => navigate(tab)}
			/>
		</div>
	);
};
