import { EAlertType } from '@kelvininc/react-ui-components';

import { IModalAlert } from '@kelvininc/shared-ui';

import { ValidatedTextFieldMessages } from '@/src/components/client/ValidatedTextField';
import { FORM_HELP_TEXT } from '@/src/config';

export const FORM_ID_PREFIX = 'root';
export const FORM_KEY_SEPARATOR = '/';
export const WORKLOAD_NAME_FORM_ID = `${FORM_ID_PREFIX}${FORM_KEY_SEPARATOR}workloadInfo${FORM_KEY_SEPARATOR}name`;
export const WORKLOAD_TITLE_FORM_ID = `${FORM_ID_PREFIX}${FORM_KEY_SEPARATOR}workloadInfo${FORM_KEY_SEPARATOR}title`;

export const NAME_ERROR_MESSAGES: ValidatedTextFieldMessages = {
	notMatchRegex: FORM_HELP_TEXT.name,
	duplicatedValue: 'Workload name already exists.'
};

export const NO_CLUSTERS_DATA_AVAILABLE: IModalAlert = {
	label: 'Unable to create a workload to deploy.',
	description:
		"Seems that there's no available clusters in the platform to create this workload.",
	type: EAlertType.Warning
};
