import { EIconName, ETagState, ITagStatus } from '@kelvininc/react-ui-components';

import { EValidationStep } from '@/src/types';

export const getValidationStatusIconName = (status: EValidationStep): EIconName =>
	({
		[EValidationStep.Pending]: EIconName.Building,
		[EValidationStep.Validating]: EIconName.Building,
		[EValidationStep.Success]: EIconName.Success,
		[EValidationStep.Error]: EIconName.Error,
		[EValidationStep.Warning]: EIconName.SuccessStar
	})[status];

export const getValidationTagState = (status: EValidationStep): ETagState =>
	({
		[EValidationStep.Pending]: ETagState.Unknown,
		[EValidationStep.Validating]: ETagState.Unknown,
		[EValidationStep.Success]: ETagState.Success,
		[EValidationStep.Error]: ETagState.Error,
		[EValidationStep.Warning]: ETagState.Warning
	})[status];

export const getValidationTagStatus = (status: EValidationStep): ITagStatus => ({
	state: getValidationTagState(status),
	icon: getValidationStatusIconName(status)
});
