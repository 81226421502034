import { getUserDisplayName } from '@kelvininc/tsutils';
import classNames from 'classnames';

import { Resizable, ResizeCallback, ResizeStartCallback } from 're-resizable';
import { PropsWithChildren, useCallback, useRef, useState } from 'react';

import { KelvinLogo } from '../KelvinLogo';

import { FixedButton, UserDropdown, UserDropdownFooter } from './components';
import {
	NAVIGATION_ANIMATION_IN_MILLIS,
	NAVIGATION_COLLAPSED_WIDTH,
	NAVIGATION_EXPANDED_MAX_WIDTH,
	NAVIGATION_EXPANDED_WIDTH,
	RESIZABLE_DEFAULT_STYLES,
	RIGHT_RESIZABLE_DEFAULT_STYLES
} from './config';
import { useNavigationSideBarMinWidth } from './hooks';
import { useNavigationSideBar } from './providers/NavigationSideBarContext';
import styles from './styles.module.scss';
import { NavigationSidebarProps } from './types';
import { getResizeConfig, getSideBarWidth } from './utils';

export const NavigationSidebar = ({
	user,
	userDropdown = [],
	footer,
	instanceLabel,
	children
}: PropsWithChildren<NavigationSidebarProps>) => {
	const {
		isCollapsed,
		isFixable,
		widthState: [{ width, fixed }, setWidthState],
		resizingState: [isResizing, setIsResizing]
	} = useNavigationSideBar();

	const [minWidth] = useNavigationSideBarMinWidth(fixed);
	const [resizeStart, setResizeStart] = useState(NAVIGATION_EXPANDED_WIDTH);
	const [userDropdownOpen, setUserDropdownOpen] = useState<boolean>(false);

	const sidebarAnimation = useRef<null | number>(null);
	const resizableRef = useRef<HTMLDivElement>(null);

	const collapseSideBar = useCallback(() => {
		sidebarAnimation.current && clearTimeout(sidebarAnimation.current);

		setWidthState(
			(previousState) => ({
				...previousState,
				width: NAVIGATION_COLLAPSED_WIDTH
			}),
			false
		);
	}, [setWidthState]);

	const expandSideBar = useCallback(() => {
		sidebarAnimation.current = window.setTimeout(
			() =>
				setWidthState(
					(previousState) => ({
						...previousState,
						width: NAVIGATION_EXPANDED_WIDTH
					}),
					false
				),
			NAVIGATION_ANIMATION_IN_MILLIS
		);
	}, [setWidthState]);

	const onClickFixed = useCallback(() => {
		setWidthState((previousState) => ({
			fixed: !previousState.fixed,
			width: getSideBarWidth(!previousState.fixed)
		}));
	}, [setWidthState]);

	const onMouseEnter = useCallback(() => {
		if (!fixed) {
			expandSideBar();
		}
	}, [fixed, expandSideBar]);

	const onMouseLeave = useCallback(() => {
		if (!fixed && !userDropdownOpen) {
			collapseSideBar();
		}
	}, [fixed, userDropdownOpen, collapseSideBar]);

	const onResizeStart: ResizeStartCallback = (_e, _direction, ref) => {
		setResizeStart(parseInt(ref.style.width, 10));
		ref.style.transition = '';
		setIsResizing(true);
	};

	const onResize: ResizeCallback = (_e, _direction, _ref, d) => {
		setWidthState({
			width: resizeStart + d.width,
			fixed
		});
	};

	const onResizeStop: ResizeCallback = (_e, _direction, ref) => {
		ref.style.transition = 'width 0.3s ease-in-out';
		setIsResizing(false);
	};

	const onUserDropdownStateChange = useCallback(
		(newState: boolean) => {
			setUserDropdownOpen((previousState) => {
				if (newState !== previousState) {
					if (!newState && !fixed) {
						collapseSideBar();
					}

					return newState;
				}

				return previousState;
			});
		},
		[fixed, collapseSideBar]
	);

	return (
		<Resizable
			minWidth={minWidth}
			maxWidth={NAVIGATION_EXPANDED_MAX_WIDTH}
			size={{ width: width ?? getSideBarWidth(fixed), height: '100%' }}
			style={RESIZABLE_DEFAULT_STYLES}
			className={styles.NavigationContainer}
			enable={getResizeConfig(fixed)}
			onResizeStart={onResizeStart}
			onResize={onResize}
			onResizeStop={onResizeStop}
			handleStyles={{ right: RIGHT_RESIZABLE_DEFAULT_STYLES }}
			handleClasses={{
				right: classNames(styles.RightResizable, {
					[styles.RightResizableWhileResizing]: isResizing
				})
			}}>
			{isFixable && (
				<FixedButton onClick={onClickFixed} fixed={fixed} collapsed={isCollapsed} />
			)}
			<div
				ref={resizableRef}
				className={styles.Navigation}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}>
				<KelvinLogo
					isCollapsed={isCollapsed}
					linkTo={'/'}
					extraLabel={instanceLabel}
					customClasses={styles.CustomLogo}
				/>
				{children}
				{user && (
					<UserDropdown
						displayName={getUserDisplayName(user)}
						email={user.email}
						isCollapsed={isCollapsed}
						dropdownItems={userDropdown}
						dropdownStateChange={onUserDropdownStateChange}
						Footer={footer && <UserDropdownFooter {...footer} />}
						customClasses={styles.UserDropdown}
					/>
				)}
			</div>
		</Resizable>
	);
};
