import { EGuardrailValueType, GuardrailCraftWizardFormValues } from '../GuardrailCraftWizard/types';

import { IConnectionFormValues } from '@/src/page-components/ConnectionsPage/ConnectionWizard/types';

export type ImportGuardrailsWizardFormValues = {
	file?: File;
	connection?: IConnectionFormValues;
	guardrails: GuardrailCraftWizardFormValues[];
};

export enum EImportGuardrailsWizardStep {
	Upload = 'upload',
	Summary = 'summary'
}

export type ImportGuardrailFileSchema = {
	asset: string;
	datastream: string;
	control: boolean;
	min_value: string;
	min_value_type: EGuardrailValueType.Number | EGuardrailValueType.Datastream;
	min_inclusive: boolean;
	max_value: string;
	max_value_type: EGuardrailValueType.Number | EGuardrailValueType.Datastream;
	max_inclusive: boolean;
} & (ImportGuardrailIncreaseMin | Partial<ImportGuardrailIncreaseMin>) &
	(ImportGuardrailIncreaseMax | Partial<ImportGuardrailIncreaseMax>) &
	(ImportGuardrailDecreaseMin | Partial<ImportGuardrailDecreaseMin>) &
	(ImportGuardrailDecreaseMax | Partial<ImportGuardrailDecreaseMax>);

type ImportGuardrailIncreaseMin = {
	relative_increase_min_value: string;
	relative_increase_min_value_type: EGuardrailValueType.Number | EGuardrailValueType.Percentage;
	relative_increase_min_inclusive: boolean;
};

type ImportGuardrailIncreaseMax = {
	relative_increase_max_value: string;
	relative_increase_max_value_type: EGuardrailValueType.Number | EGuardrailValueType.Percentage;
	relative_increase_max_inclusive: boolean;
};

type ImportGuardrailDecreaseMin = {
	relative_decrease_min_value: string;
	relative_decrease_min_value_type: EGuardrailValueType.Number | EGuardrailValueType.Percentage;
	relative_decrease_min_inclusive: boolean;
};

type ImportGuardrailDecreaseMax = {
	relative_decrease_max_value: string;
	relative_decrease_max_value_type: EGuardrailValueType.Number | EGuardrailValueType.Percentage;
	relative_decrease_max_inclusive: boolean;
};
