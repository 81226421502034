import { useMemo } from 'react';

import { IBaseTData, IColumnDef } from '../../../../../../../AgGridTable';
import { searchColumnDefs } from '../../utils';

export const useFilteredColumnDefs = <TData extends IBaseTData>(
	columnDefs: IColumnDef<TData>[],
	searchTerm?: string
): IColumnDef<TData>[] => {
	return useMemo(() => {
		let filteredColumnDefs = columnDefs.filter(({ feature }) => !!!feature);

		if (searchTerm?.length) {
			filteredColumnDefs = searchColumnDefs(searchTerm, filteredColumnDefs);
		}

		return filteredColumnDefs;
	}, [columnDefs, searchTerm]);
};
