import { ReactNode } from 'react';

export enum EValidationStep {
	Pending = 'pending',
	Validating = 'validating',
	Success = 'success',
	Error = 'error',
	Warning = 'warning'
}

export interface IValidationItem {
	description: ReactNode;
	itemKey?: string;
	state: EValidationStep;
	isValidationBlocker?: boolean;
	hidden?: boolean;
}
