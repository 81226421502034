import { ICellEditor } from '@ag-grid-community/core';
import {
	ISelectOption,
	ISingleSelectDropdown,
	KvSingleSelectDropdown
} from '@kelvininc/react-ui-components';
import { OptionCreate, useDropdownSearchOptions } from '@kelvininc/shared-ui';
import { PropsWithForwardedRef } from '@kelvininc/types';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import {
	ForwardedRef,
	forwardRef,
	useCallback,
	useImperativeHandle,
	useRef,
	useState
} from 'react';

import { IBaseTData, ITableCellEditorParams } from '../../../core';

import { SelectCellRenderer } from '../../cells';

import { USE_DROPDOWN_OPTIONS } from './config';
import styles from './styles.module.scss';
import { ISelectCellEditorEvents } from './types';

import { getLabel } from './utils';

type SelectCellEditorProps<TData extends IBaseTData> = ITableCellEditorParams<TData, string> &
	SelectCellEditorParams;

export type SelectCellEditorParams = {
	/* If `true`, the editor will use the provided colDef.valueFormatter to format
	the value displayed in the editor. Used when the cell value needs formatting
	prior to editing, such as when using reference data and you want to display
	text rather than code. */
	shouldFormatter?: boolean;
	values?: ISelectOption[];
	disabled?: boolean;
	selectConfig?: Partial<ISingleSelectDropdown & ISelectCellEditorEvents>;
};

const Component = <TData extends IBaseTData>(
	props: PropsWithForwardedRef<SelectCellEditorProps<TData>, ICellEditor>
) => {
	const { value, stopEditing, values = [], forwardedRef, disabled, selectConfig } = props;
	const selectedItem = useRef(value);
	const [isOpen, setIsOpen] = useState(true);
	const inputRef = useRef<HTMLInputElement>(null);

	/* Component Editor Lifecycle methods */
	useImperativeHandle(forwardedRef, () => {
		return {
			getValue: () => selectedItem.current,
			isCancelBeforeStart: () => false,
			isCancelAfterEnd: () => false,
			isPopup: () => false
		};
	});

	const { setSearchTerm, filteredOptions, options, searchTerm } = useDropdownSearchOptions(
		values,
		USE_DROPDOWN_OPTIONS
	);

	const onSelectOption = ({ detail: newValue }: CustomEvent<string>) => {
		selectedItem.current = newValue;
		setIsOpen(false);
		stopEditing();
	};
	const onSearchChange = useCallback(
		({ detail: searchedValue }: CustomEvent<string>) => setSearchTerm(searchedValue),
		[setSearchTerm]
	);
	const onOpenStateChange = useCallback(({ detail: opened }: CustomEvent<boolean>) => {
		setIsOpen(opened);
	}, []);

	return (
		<div className={classNames('ag-cell-edit-wrapper', styles.SelectCellEditor)}>
			<KvSingleSelectDropdown
				{...selectConfig}
				isOpen={!disabled && isOpen}
				disabled={disabled}
				actionElement={inputRef.current}
				options={options}
				filteredOptions={filteredOptions}
				selectedOption={selectedItem.current ?? undefined}
				onOptionSelected={onSelectOption}
				onSearchChange={onSearchChange}
				onOpenStateChange={onOpenStateChange}
				onClickOutside={() => stopEditing()}
				onDismiss={() => stopEditing()}>
				<div
					ref={inputRef}
					className={classNames('ag-cell-editor', styles.SelectInputContainer)}
					slot="dropdown-action"
					onClick={() => stopEditing()}>
					<SelectCellRenderer
						isOpen={!disabled && isOpen}
						valueFormatted={getLabel(selectedItem.current, values)}
						placeholder={selectConfig?.placeholder}
						{...props}
					/>
				</div>
				{selectConfig?.canAddItems && (
					<div slot="create-new-option">
						<OptionCreate
							initialValue={
								!isEmpty(searchTerm) && isEmpty(filteredOptions) ? searchTerm : ''
							}
							onClickCreateOption={selectConfig.onOptionCreated}
							getFormState={selectConfig.getOptionCreateFormState}
							inputConfig={selectConfig.optionCreateInputConfig}
						/>
					</div>
				)}
			</KvSingleSelectDropdown>
		</div>
	);
};

export const SelectCellEditor = forwardRef(function SelectCellEditor<TData extends IBaseTData>(
	props: SelectCellEditorProps<TData>,
	ref: ForwardedRef<ICellEditor>
) {
	return <Component {...props} forwardedRef={ref} />;
}) as <TData extends IBaseTData>(
	props: SelectCellEditorProps<TData> & { ref?: ForwardedRef<ICellEditor> }
) => ReturnType<typeof Component<TData>>;
