import { EIconName, KvTextField } from '@kelvininc/react-ui-components';
import classNames from 'classnames';

import { PropsWithChildren, useEffect } from 'react';

import { IBaseTData, ICellRendererParams } from '../../../core';
import { TableTooltip } from '../../../core/components';
import { ISelectionApi } from '../../../core/hooks';
import { CellStateContainer } from '../BaseCellRenderer/components';

import styles from './styles.module.scss';

export type SelectCellRendererParams = PropsWithChildren<{
	isOpen?: boolean;
	disabled?: boolean;
}>;

export const SelectCellRenderer = <TData extends IBaseTData>(
	props: ICellRendererParams<TData, string> & SelectCellRendererParams
) => {
	const {
		valueFormatted,
		value,
		isOpen = false,
		disabled = false,
		placeholder,
		customClasses,
		eGridCell,
		node,
		context,
		children
	} = props;
	const valueLabel = `${valueFormatted ?? value}`;
	const selectionApi = context.selectionApi as ISelectionApi<TData>;
	const isSelected = node ? selectionApi.isSelected(node) : false;

	useEffect(() => {
		const classes = classNames(customClasses);

		if (eGridCell) {
			if (classes) {
				classes.split(' ').forEach((classe) => eGridCell.classList.add(classe));
			}

			if (isSelected) {
				eGridCell.parentElement?.classList.add('ag-row-selected');
			} else {
				eGridCell.parentElement?.classList.remove('ag-row-selected');
			}
		}
	}, [customClasses, eGridCell, isSelected]);

	return (
		<CellStateContainer {...props}>
			<div
				tabIndex={-1}
				className={classNames(styles.SelectContainer, {
					[styles.IsOpen]: isOpen
				})}>
				<TableTooltip truncate text={!isOpen ? valueLabel : ''}>
					<KvTextField
						forcedFocus={isOpen}
						inputDisabled={disabled}
						placeholder={placeholder}
						value={valueLabel}
						inputReadonly
						fitContent={false}
						actionIcon={isOpen ? EIconName.ArrowDropUp : EIconName.ArrowDropDown}>
						{children && (
							<div className={styles.InputSlot} slot="input">
								{children}
							</div>
						)}
					</KvTextField>
				</TableTooltip>
			</div>
		</CellStateContainer>
	);
};
