import { BridgeItem, BridgeService, EPaginatorType } from '@kelvininc/node-client-sdk';
import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { selector } from 'recoil';

export const connectionsListSelector = selector<BridgeItem[]>({
	key: 'connectionsListSelector',
	get: () => {
		return BridgeService.listBridges({ paginationType: EPaginatorType.Stream })
			.pipe(catchHttpError(throwHttpError))
			.toPromise();
	}
});
