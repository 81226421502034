import { ExpandCellRenderer, FavouriteCellRenderer } from '../../../renderers/cells';
import { getCheckboxSelectionColumnDef } from '../../../utils';
import { IBaseTData, IColumnDef, IColumnsHiddenConfig, IColumnsPinConfig } from '../AgGridTable';
import { isDataPinned } from '../AgGridTable/utils';

import {
	COMMON_FEATURE_COLUMNS_DEF,
	EXPAND_COLUMN_ID,
	EXPAND_COLUMN_TITLE,
	FAVOURITE_COLUMN_ID,
	FAVOURITE_COLUMN_TITLE,
	TABLE_SIDEPANEL_KEY
} from './config';

const columnsOrderComparator =
	<TData extends IBaseTData>(columnsOrder: string[]) =>
	(columnA: IColumnDef<TData>, columnB: IColumnDef<TData>): number => {
		const indexA = columnsOrder.indexOf(columnA.id);
		const indexB = columnsOrder.indexOf(columnB.id);

		if (indexA === indexB) {
			return 0;
		}

		if (indexA === -1) {
			return 1;
		}

		if (indexB === -1) {
			return -1;
		}

		if (indexA > indexB) {
			return 1;
		}

		return -1;
	};

const buildTableColumnDef = <TData extends IBaseTData>(
	columnDef: IColumnDef<TData>,
	columnsPinned: IColumnsPinConfig,
	columnsHidden: IColumnsHiddenConfig
): IColumnDef<TData> => ({
	...columnDef,
	pinned: columnsPinned[columnDef.id] ?? columnDef.pinned,
	hide: columnsHidden[columnDef.id] ?? columnDef.hide
});

const addFeatureColumnnDefs = <TData extends IBaseTData>(
	columnDefs: IColumnDef<TData>[],
	checkboxSelectable: boolean,
	rowsPinnable: boolean,
	hasDetail: boolean
): IColumnDef<TData>[] => {
	let newColumnDefs = [...columnDefs];

	if (hasDetail) {
		newColumnDefs = [getExpandColumnDef(), ...newColumnDefs];
	}

	if (rowsPinnable) {
		newColumnDefs = [getFavouriteSelectionColumnDef(), ...newColumnDefs];
	}

	if (checkboxSelectable) {
		newColumnDefs = [getCheckboxSelectionColumnDef(), ...newColumnDefs];
	}

	return newColumnDefs;
};

export const buildTableColumnDefs = <TData extends IBaseTData>(
	columnDefs: IColumnDef<TData>[],
	columnsOrder: string[],
	columnsPinned: IColumnsPinConfig,
	columnsHidden: IColumnsHiddenConfig,
	checkboxSelectable: boolean,
	rowsPinnable: boolean,
	hasMasterDetail: boolean = false
): IColumnDef<TData>[] => {
	return addFeatureColumnnDefs(
		columnDefs.sort(columnsOrderComparator(columnsOrder)),
		checkboxSelectable,
		rowsPinnable,
		hasMasterDetail
	).map((colDef) => buildTableColumnDef(colDef, columnsPinned, columnsHidden));
};

export const getFiltersSidepanelKey = (key: string) => `${TABLE_SIDEPANEL_KEY}-${key}.filters`;

export const getManagementSidepanelKey = (key: string) =>
	`${TABLE_SIDEPANEL_KEY}-${key}.management`;

export const getExpandColumnDef = <TData extends IBaseTData>(): IColumnDef<TData> => ({
	id: EXPAND_COLUMN_ID,
	title: EXPAND_COLUMN_TITLE,
	cellComponent: ExpandCellRenderer,
	headerComponent: () => null,
	cellComponentParams: {
		displayEmptyState: false
	},
	...COMMON_FEATURE_COLUMNS_DEF
});

export const getFavouriteSelectionColumnDef = <TData extends IBaseTData>(): IColumnDef<TData> => ({
	id: FAVOURITE_COLUMN_ID,
	title: FAVOURITE_COLUMN_TITLE,
	headerComponent: () => null,
	valueFormatter: (_value, data, _columnId, api, context) =>
		api && context ? isDataPinned(data, api, context).toString() : 'false',
	cellComponent: FavouriteCellRenderer,
	cellComponentParams: {
		hasCellTooltip: true,
		tooltipCellFieldFormatter: (_value, data, _columnId, api, context) => {
			if (!api || !context) {
				return;
			}

			const isPinned = isDataPinned(data, api, context);
			if (isPinned) {
				return 'Unfreeze this row from top';
			}

			const { maxRowsPinned } = context;
			const rowsPinnedCount = api.getPinnedTopRowCount();
			if (rowsPinnedCount >= maxRowsPinned) {
				return '';
			}

			return 'Freeze this row on top';
		},
		displayEmptyState: false
	},
	...COMMON_FEATURE_COLUMNS_DEF
});
