import { useUpdate } from '@kelvininc/shared-ui';
import { addArrayItem, removeArrayItem, updateArrayItem } from '@kelvininc/tsutils';
import { ReactState } from '@kelvininc/types';
import { isEmpty, isEqual } from 'lodash-es';
import {
	Context,
	FunctionComponent,
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useState
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
	ETableAdvancedFilterType,
	ITableAdvancedFilter,
	ITableAdvancedFilters
} from '../../../AgGridTable';
import { IAdvancedFiltersConfig } from '../../types';

import { TABLE_ADVANCED_FILTERS_FORM_DEFAULT_VALUE } from './config';

import {
	DraftAdvancedFilter,
	IDrafAdvancedFilterMap,
	ITableAdvancedFiltersContextValues,
	ITableAdvancedFormFilters,
	ITableAdvancedFormFiltersActions
} from './types';

import { buildFormFilters, filterValidFormFilters } from './utils';

const TableAdvancedFiltersContext: Context<null | ITableAdvancedFiltersContextValues> =
	createContext<null | ITableAdvancedFiltersContextValues>(null);

type TableAdvancedFiltersContextProviderProps = {
	filters?: ITableAdvancedFilters;
	onChange: (newFilter?: ITableAdvancedFilters) => void;
	config: IAdvancedFiltersConfig;
};

export const TableAdvancedFiltersContextProvider = ({
	filters: submittedFilters,
	onChange: setSubmittedFilters,
	config,
	children
}: PropsWithChildren<TableAdvancedFiltersContextProviderProps>) => {
	const [formFilters, setFormFilters] = useState<ITableAdvancedFormFilters>(
		buildFormFilters(submittedFilters)
	);
	const [validFilters, setValidFilters] = useState<ITableAdvancedFilters>(
		filterValidFormFilters(formFilters, config)
	);

	// Sync form filters with valid form filters
	useUpdate(() => {
		const newFormFilters = filterValidFormFilters(formFilters, config);
		setValidFilters((previousFormFilters) => {
			if (isEqual(newFormFilters, previousFormFilters)) {
				return previousFormFilters;
			}

			return newFormFilters;
		});
	}, [formFilters, config]);

	// Sync valid form filters with submitted filters
	useUpdate(() => {
		if (!isEqual(submittedFilters, validFilters)) {
			setSubmittedFilters(validFilters);
		}
	}, [submittedFilters, validFilters]);

	return (
		<TableAdvancedFiltersContext.Provider
			value={{
				formFiltersState: [formFilters, setFormFilters]
			}}>
			{children}
		</TableAdvancedFiltersContext.Provider>
	);
};

export const useTableAdvancedFiltersContext = (): ITableAdvancedFiltersContextValues => {
	const context = useContext(TableAdvancedFiltersContext);

	if (!context) {
		throw new Error('Missing table advanced filters context');
	}

	return context;
};

export const useTableAdvancedFormFilters = (): ReactState<ITableAdvancedFormFilters> => {
	const { formFiltersState } = useTableAdvancedFiltersContext();
	return formFiltersState;
};

export const useTableAdvancedFormFiltersActions = (): ITableAdvancedFormFiltersActions => {
	const [, setForm] = useTableAdvancedFormFilters();

	const createFilter = useCallback(
		(type: ETableAdvancedFilterType) => {
			const newFilter: DraftAdvancedFilter<ITableAdvancedFilter> = {
				id: uuidv4(),
				type
			};

			setForm((previousForm) => ({
				...previousForm,
				[type]: [...previousForm[type], newFilter]
			}));
		},
		[setForm]
	);

	const updateFilter = useCallback(
		<T extends ETableAdvancedFilterType>(
			type: T,
			index: number,
			newFilter: IDrafAdvancedFilterMap[T]
		) =>
			setForm((previousForm) => ({
				...previousForm,
				[type]: updateArrayItem(
					previousForm[type] as IDrafAdvancedFilterMap[T][],
					index,
					newFilter
				)
			})),
		[setForm]
	);

	const deleteFilter = useCallback(
		<T extends ETableAdvancedFilterType>(type: T, index: number) =>
			setForm((previousForm) => {
				const newFilters = removeArrayItem(
					previousForm[type] as IDrafAdvancedFilterMap[T][],
					index
				);

				return {
					...previousForm,
					[type]: isEmpty(newFilters)
						? TABLE_ADVANCED_FILTERS_FORM_DEFAULT_VALUE[type]
						: newFilters
				};
			}),
		[setForm]
	);

	const duplicateFilter = useCallback(
		(type: ETableAdvancedFilterType, index: number) => {
			setForm((previousForm) => ({
				...previousForm,
				[type]: addArrayItem(previousForm[type], index + 1, {
					...previousForm[type][index],
					id: uuidv4()
				})
			}));
		},
		[setForm]
	);

	const clearFilters = useCallback(
		() => setForm(TABLE_ADVANCED_FILTERS_FORM_DEFAULT_VALUE),
		[setForm]
	);

	return {
		createFilter,
		updateFilter,
		deleteFilter,
		duplicateFilter,
		clearFilters
	};
};

export const withTableAdvancedFiltersContextProvider = <
	T extends TableAdvancedFiltersContextProviderProps
>(
	Component: FunctionComponent<T>
) => {
	return function TableAdvancedFiltersContextProviderWrapper(
		componentProps: PropsWithChildren<T>
	) {
		return (
			<TableAdvancedFiltersContextProvider {...componentProps}>
				<Component {...componentProps} />
			</TableAdvancedFiltersContextProvider>
		);
	};
};
