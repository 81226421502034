import { AnimationProps } from 'framer-motion';

export const getFadeAnimationProps = (duration = 0.5): AnimationProps => ({
	variants: {
		shown: { opacity: 1 },
		hidden: { opacity: 0 }
	},
	initial: 'hidden',
	animate: 'shown',
	exit: 'hidden',
	transition: { duration }
});
