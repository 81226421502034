import { DataStream, EDatastreamExtraFieldComputationDataAgg } from '@kelvininc/node-client-sdk';
import {
	EComponentSize,
	KvSingleSelectDropdown,
	KvSingleSelectDropdownCustomEvent
} from '@kelvininc/react-ui-components';
import {
	AGGREGATION_FUNCTION_DROPDOWN_OPTIONS,
	filterObject,
	isDataStreamDataTypeString
} from '@kelvininc/tsutils';

import { DataStreamAggregationFunction, EDataStreamCustomAgg } from '@kelvininc/types';

import { useMemo } from 'react';

import { SELECT_AGGREGATION_PLACEHOLDER } from '../../config';

import styles from './styles.module.scss';

type DatastreamAggregationDropdownProps = {
	aggregation?: DataStreamAggregationFunction;
	datastream: DataStream;
	onAggregationChange: (aggregation: DataStreamAggregationFunction) => void;
};

export const DatastreamAggregationDropdown = ({
	aggregation,
	datastream,
	onAggregationChange
}: DatastreamAggregationDropdownProps) => {
	const onSelectAggregation = ({ detail }: KvSingleSelectDropdownCustomEvent<string>) => {
		onAggregationChange(detail as DataStreamAggregationFunction);
	};

	const aggOptions = useMemo(() => {
		if (isDataStreamDataTypeString(datastream.dataTypeName)) {
			return filterObject(
				AGGREGATION_FUNCTION_DROPDOWN_OPTIONS,
				(option) =>
					option === EDatastreamExtraFieldComputationDataAgg.Count ||
					option === EDataStreamCustomAgg.Last
			);
		} else {
			return AGGREGATION_FUNCTION_DROPDOWN_OPTIONS;
		}
	}, [datastream]);

	return (
		<KvSingleSelectDropdown
			className={styles.AggregationInput}
			placeholder={SELECT_AGGREGATION_PLACEHOLDER}
			options={aggOptions}
			selectedOption={aggregation}
			onOptionSelected={onSelectAggregation}
			inputSize={EComponentSize.Small}
			searchable={false}
		/>
	);
};
