import { SchemaFormProps } from '@kelvininc/react-ui-components';
import { isParameterPrimitiveTypeString } from '@kelvininc/tsutils';
import { isEqual, merge } from 'lodash-es';

import { ITableAdvancedFilters } from '../../../../../AgGridTable';
import { TABLE_ADVANCED_FILTERS_FORM_DEFAULT_VALUE } from '../../../../contexts/TableAdvancedFiltersContext/config';

import { ITableAdvancedFormFilters } from '../../../../contexts/TableAdvancedFiltersContext/types';

import { ApplicationParameterAdvancedFilterSchemaFormData } from './components/ApplicationParameterFiltersForm/types';
import { AssetPropertyAdvancedFilterSchemaFormData } from './components/AssetPropertyFiltersForm/types';
import { FILTER_UI_SCHEMA, MINIMUM_MULTI_SELECTABLE_OPTIONS } from './config';

export const isOnPortal = (element: HTMLElement): boolean => {
	if (!element.parentElement) {
		return false;
	}

	if (element.tagName === 'KV-PORTAL') {
		return true;
	}

	return isOnPortal(element.parentElement);
};

export const areFiltersEmpty = (
	submittedFilters: ITableAdvancedFilters = {},
	formFilters: ITableAdvancedFormFilters
): boolean =>
	Object.values(submittedFilters).flat().length === 0 &&
	isEqual(formFilters, TABLE_ADVANCED_FILTERS_FORM_DEFAULT_VALUE);

export const getFilterUiSchema = (
	primitiveType: unknown,
	values: string[] | boolean[] = []
): SchemaFormProps<
	AssetPropertyAdvancedFilterSchemaFormData | ApplicationParameterAdvancedFilterSchemaFormData
>['uiSchema'] => {
	if (
		isParameterPrimitiveTypeString(primitiveType) &&
		values.length >= MINIMUM_MULTI_SELECTABLE_OPTIONS
	) {
		return merge({}, FILTER_UI_SCHEMA, {
			value: {
				selectionClearable: true
			}
		});
	}

	return FILTER_UI_SCHEMA;
};
