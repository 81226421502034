import { DataStream } from '@kelvininc/node-client-sdk';
import { EComponentSize, KvSchemaForm, KvTextField } from '@kelvininc/react-ui-components';

import { DataStreamAggregationFunction } from '@kelvininc/types';

import { IChangeEvent } from '@rjsf/core';

import { SELECT_OPERATOR_PLACEHOLDER } from '../../config';
import { useSchemaFormBuilder } from '../../hooks/useSchemaFormBuilder';
import { DatastreamAdvancedFilterSchemaFormData } from '../../types';

import styles from './styles.module.scss';
import { buildUISchema, isValueListPresent } from './utils';

type DatastreamSchemaFormProps = {
	datastream: DataStream;
	aggregation?: DataStreamAggregationFunction;
	formData: DatastreamAdvancedFilterSchemaFormData;
	onChangeForm: (newForm: DatastreamAdvancedFilterSchemaFormData) => void;
};

export const DatastreamSchemaForm = ({
	aggregation,
	datastream,
	formData,
	onChangeForm
}: DatastreamSchemaFormProps) => {
	const schema = useSchemaFormBuilder(datastream, aggregation);

	if (!aggregation || !schema) {
		return (
			<KvTextField
				className={styles.OperatorInput}
				placeholder={SELECT_OPERATOR_PLACEHOLDER}
				size={EComponentSize.Small}
				inputDisabled
			/>
		);
	}

	return (
		<KvSchemaForm
			schema={schema}
			uiSchema={buildUISchema()}
			formData={formData}
			submittedData={formData}
			onChange={({
				formData: { timeRange, operator, value, valueList } = {}
			}: IChangeEvent<DatastreamAdvancedFilterSchemaFormData>) => {
				if (isValueListPresent({ operator, valueList })) {
					value = valueList;
				}

				onChangeForm({
					timeRange,
					operator,
					value
				});
			}}
			customClass={styles.SchemaForm}
		/>
	);
};
