import { EDensityLevel, IColumnOptions } from '@kelvininc/table';

import { IFileMappingError } from './types';

export const DENSITY_CONFIG = {
	[EDensityLevel.Medium]: 40
};

export const DEFAULT_COLUMNS_DEFS: IColumnOptions<IFileMappingError> = {
	sortable: false,
	resizable: true,
	draggable: false
};
