import { AssetInsightsItem } from '@kelvininc/node-client-sdk';
import { EActionButtonType, KvActionButtonText, KvTooltip } from '@kelvininc/react-ui-components';
import { IApplicationParameterDefinition, IClosedLoopSettings } from '@kelvininc/types';

import { DEFAULT_CLOSED_LOOP_SETTINGS } from '../../../config';
import { ICellRendererParams } from '../../../core/components/AgGridTable/types';
import { withBaseCellRenderer } from '../BaseCellRenderer';

import { ScheduleDateTime } from './components';
import styles from './styles.module.scss';

import { IAssetInsightsScheduleParameters } from './types';

import { getDetailsActionLabel, getParametersLabel } from './utils';

export type ScheduleParametersCellRendererParams = {
	parameters?: IApplicationParameterDefinition;
	closedLoopSettings?: IClosedLoopSettings;
	onClickScheduleDetails?: (params: {
		assetName: string;
		schedule: IAssetInsightsScheduleParameters;
	}) => void;
};

const Component = ({
	value: scheduleParameters,
	data,
	parameters = {},
	closedLoopSettings = DEFAULT_CLOSED_LOOP_SETTINGS,
	onClickScheduleDetails
}: ICellRendererParams<AssetInsightsItem, IAssetInsightsScheduleParameters> &
	ScheduleParametersCellRendererParams) => {
	if (!data || !scheduleParameters) {
		return <>N/A</>;
	}

	const label = getParametersLabel(scheduleParameters, parameters, closedLoopSettings);

	return (
		<div className={styles.ScheduleParameters}>
			<div className={styles.Details}>
				<div className={styles.Date}>
					<ScheduleDateTime
						scheduleDate={scheduleParameters.schedule_date}
						revertDate={scheduleParameters.revert_date}
					/>
				</div>
				<KvTooltip text={label} truncate>
					<div className={styles.Parameters}>{label}</div>
				</KvTooltip>
			</div>
			{onClickScheduleDetails && (
				<div className={styles.Action}>
					<KvActionButtonText
						type={EActionButtonType.Ghost}
						text={getDetailsActionLabel(scheduleParameters)}
						onClickButton={() =>
							onClickScheduleDetails({
								assetName: data.name,
								schedule: scheduleParameters
							})
						}
					/>
				</div>
			)}
		</div>
	);
};

export const ScheduleParametersCellRenderer = withBaseCellRenderer(Component);
