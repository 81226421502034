import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { Avatar, EAvatarSize } from '../../../Avatar';
import { Dropdown, EDropdownSize, useDropdown, withDropdownProvider } from '../../../Dropdown';
import { UserInfo } from '../UserInfo';

import { OPTIONS } from './config';
import styles from './styles.module.scss';
import { UserDropdownProps } from './types';

const Component = ({
	displayName,
	email,
	dropdownItems,
	dropdownOptions = OPTIONS,
	isCollapsed = false,
	Header,
	Footer,
	customClasses,
	dropdownStateChange
}: UserDropdownProps) => {
	const { isOpen, toggle } = useDropdown();
	const actionRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		dropdownStateChange?.(isOpen);
	}, [dropdownStateChange, isOpen]);

	return (
		<Dropdown
			actionRef={actionRef}
			options={dropdownOptions}
			items={dropdownItems}
			header={Header}
			footer={Footer}
			size={EDropdownSize.Medium}>
			<div
				className={classNames(styles.MenuContainer, customClasses, {
					[styles.MenuContainerDropdownOpen]: isOpen
				})}
				onClick={toggle}
				ref={actionRef}>
				<div className={styles.MenuUserInfo}>
					<Avatar name={displayName} size={EAvatarSize.Normal} />
					{!isCollapsed && (
						<UserInfo
							displayName={displayName}
							email={email}
							customClasses={styles.MenuTextContainer}
						/>
					)}
				</div>
			</div>
		</Dropdown>
	);
};

export const UserDropdown = withDropdownProvider<UserDropdownProps>(Component);
