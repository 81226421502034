import { EKvHttpStatusCode } from '@kelvininc/node-client-sdk';

import {
	Datasource,
	IAbortableDatasourcePlugin,
	IBaseTData
} from '../../core/components/AgGridTable';

export const withAbortRequestController = <TData extends IBaseTData>(
	datasource: Datasource<TData, IAbortableDatasourcePlugin>
): Datasource<TData, IAbortableDatasourcePlugin> => {
	let abortController: AbortController;

	return (params, api) => {
		abortController?.abort({
			status: EKvHttpStatusCode.RequestClosed,
			statusText: 'Request closed'
		});
		abortController = new AbortController();
		return datasource({ ...params, signal: abortController.signal }, api);
	};
};
