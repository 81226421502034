import { useMatch } from '@kelvininc/nextjs-utils/client';
import { EIconName } from '@kelvininc/react-ui-components';
import { IExpandableMenuItem } from '@kelvininc/shared-ui';
import { useMemo } from 'react';

import {
	buildAssetManagementAssetsRoute,
	buildAssetManagementRoute,
	buildConnectionRoute,
	buildDataStreamsRoute,
	buildGuardrailsRoute
} from '@/src/utils/routes';

export const useContextualizationExpandableItems = (): IExpandableMenuItem[] => {
	const assetManagementAssetsUrl = buildAssetManagementAssetsRoute();
	const assetManagementUrl = buildAssetManagementRoute();
	const assetManagementUrlMatch = useMatch(`${assetManagementUrl}/*`);

	const dataStreamsUrl = buildDataStreamsRoute();
	const dataStreamMatch = useMatch(`${dataStreamsUrl}/*`);

	const connectionsUrl = buildConnectionRoute();
	const connectionMatch = useMatch(`${connectionsUrl}/*`);

	const guardrailsUrl = buildGuardrailsRoute();
	const isGuarldrailsUrl = !!useMatch(`${buildGuardrailsRoute()}/*`);

	return useMemo(
		() => [
			{
				id: 'asset-management',
				text: 'Asset Management',
				active: !!assetManagementUrlMatch,
				url: assetManagementAssetsUrl,
				leftIcon: EIconName.AssetsManagement
			},
			{
				id: 'data-stream',
				text: 'Data Streams',
				active: !!dataStreamMatch,
				url: dataStreamsUrl,
				leftIcon: EIconName.Datastreams
			},
			{
				id: 'connection',
				text: 'Connections',
				active: !!connectionMatch,
				isClosable: true,
				url: connectionsUrl,
				leftIcon: EIconName.Connections
			},
			{
				id: 'guardrails',
				text: 'Guardrails',
				active: isGuarldrailsUrl,
				isClosable: true,
				url: guardrailsUrl,
				leftIcon: EIconName.Guardrails
			}
		],
		[
			assetManagementUrlMatch,
			assetManagementAssetsUrl,
			dataStreamMatch,
			dataStreamsUrl,
			connectionMatch,
			connectionsUrl,
			isGuarldrailsUrl,
			guardrailsUrl
		]
	);
};
