export enum ETableColumnKey {
	Asset = 'asset',
	Datastream = 'datastream',
	Control = 'control',
	MinValue = 'min-value',
	MaxValue = 'max-value',
	IncreaseMinValue = 'increase-min-value',
	IncreaseMaxValue = 'increase-max-value',
	DecreaseMinValue = 'decrease-min-value',
	DecreaseMaxValue = 'decrease-max-value',
	Actions = 'actions'
}
