import {
	DragStart,
	DragUpdate,
	Droppable,
	DroppableProvided,
	DroppableStateSnapshot
} from '@hello-pangea/dnd';
import { PropsWithForwardedRef } from '@kelvininc/types';
import classNames from 'classnames';
import {
	CSSProperties,
	ForwardedRef,
	forwardRef,
	useImperativeHandle,
	useMemo,
	useState
} from 'react';

import {
	DraggableItem,
	DraggableItemPlaceholder,
	DraggableItemPlaceholderProps
} from '../DraggableItem';

import { DraggableZone } from '../DraggableZone';

import { DEFAULT_DROPPABLE_ZONE_TYPE } from './config';

import { useSplitListByDisabled } from './hooks';
import styles from './styles.module.scss';
import { EDraggableItemsSpacing, IDraggableItem, IDroppableZoneApi } from './types';
import {
	getDisabledDroppableType,
	getEnabledDroppableType,
	getPlaceholderStyleOnDragStart,
	getPlaceholderStyleOnDragUpdate
} from './utils';

type DroppableZoneProps = {
	id: string;
	type?: string;
	items: IDraggableItem[];
	onClick?: (index: number) => void;
	placeholderText?: string;
	CustomPlaceholder?: (props: DraggableItemPlaceholderProps) => JSX.Element | null;
	disabled?: boolean;
	dragDisabled?: boolean;
	spacing?: EDraggableItemsSpacing;
};

const Component = ({
	id,
	type = DEFAULT_DROPPABLE_ZONE_TYPE,
	items,
	onClick,
	placeholderText,
	CustomPlaceholder = DraggableItemPlaceholder,
	disabled = false,
	dragDisabled = false,
	spacing = EDraggableItemsSpacing.Normal,
	forwardedRef
}: PropsWithForwardedRef<DroppableZoneProps, IDroppableZoneApi>) => {
	const [draggableItems, disabledItems] = useSplitListByDisabled(items);
	const [placeholderStyle, setPlaceholderStyle] = useState<CSSProperties>();
	const showPlaceholder = useMemo(
		() => disabledItems.length === 0 && draggableItems.length === 0,
		[disabledItems.length, draggableItems.length]
	);

	useImperativeHandle(forwardedRef, () => ({
		onDragStart: (event: DragStart, data: IDraggableItem[]) => {
			const placeholderNewStyle = getPlaceholderStyleOnDragStart(event, data, draggableItems);
			setPlaceholderStyle(placeholderNewStyle);
		},
		onDragUpdate: (event: DragUpdate, data: IDraggableItem[]) => {
			const placeholderNewStyle = getPlaceholderStyleOnDragUpdate(
				event,
				data,
				draggableItems
			);
			setPlaceholderStyle(placeholderNewStyle);
		},
		onDragEnd: () => {
			setPlaceholderStyle(undefined);
		}
	}));

	return (
		<div className={styles.DroppableZone}>
			<Droppable droppableId={id} type={getDisabledDroppableType(type)} isDropDisabled>
				{(dropProvided: DroppableProvided) => (
					<div
						className={styles.Items}
						ref={dropProvided.innerRef}
						{...dropProvided.droppableProps}>
						{disabledItems.map((item) => (
							<DraggableZone
								key={item.id}
								onClick={() => onClick?.(item.index)}
								customClasses={classNames(styles.Item, {
									[styles.ItemSmallSpacing]:
										spacing === EDraggableItemsSpacing.Small
								})}
								{...item}
								isDragDisabled
							/>
						))}
					</div>
				)}
			</Droppable>
			<Droppable
				droppableId={id}
				type={getEnabledDroppableType(type)}
				isDropDisabled={disabled}>
				{(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
					<div
						className={styles.Items}
						ref={dropProvided.innerRef}
						{...dropProvided.droppableProps}>
						{draggableItems.map((item) => (
							<DraggableZone
								data-test-id="e2e-droppable-zone-draggable-item"
								key={item.id}
								onClick={() => onClick?.(item.index)}
								customClasses={classNames(styles.Item, {
									[styles.ItemSmallSpacing]:
										spacing === EDraggableItemsSpacing.Small
								})}
								isDragDisabled={dragDisabled}
								{...item}
							/>
						))}
						{showPlaceholder && <CustomPlaceholder text={placeholderText} />}
						{dropProvided.placeholder}
						{placeholderStyle && dropSnapshot.isDraggingOver && (
							<div className={styles.PlaceholderContainer} style={placeholderStyle}>
								<DraggableItem placeholder />
							</div>
						)}
					</div>
				)}
			</Droppable>
		</div>
	);
};

export const DroppableZone = forwardRef(function DroppableZoneWithRef(
	props: DroppableZoneProps,
	ref: ForwardedRef<IDroppableZoneApi>
) {
	return <Component {...props} forwardedRef={ref} />;
});
