'use client';

import { useLogout } from '@kelvininc/nextjs-utils/client';
import { AuthService, ESessionEventType } from '@kelvininc/node-client-sdk';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { filter, take, tap } from 'rxjs/operators';

export const useSessionRenewedListener = () => {
	const { update: updateSession } = useSession();
	const logout = useLogout();

	useEffect(() => {
		const subs = [
			AuthService.onSessionEvent()
				.pipe(
					filter((event) => event.type === ESessionEventType.RenewSuccess),
					tap(async (event) => {
						// Update the session cookie with the new config
						await fetch('/core/api/session', {
							method: 'POST',
							body: JSON.stringify(event.session),
							headers: {
								'Content-Type': 'application/json'
							}
						}).catch(() => {
							console.error('Failed to update session cookie');
						});

						updateSession();
					})
				)
				.subscribe(),
			AuthService.onSessionEvent()
				.pipe(
					filter((event) => event.type === ESessionEventType.RenewError),
					take(1),
					tap(() => logout())
				)
				.subscribe()
		];

		return () => subs.forEach((sub) => sub?.unsubscribe());
	}, [updateSession, logout]);
};
