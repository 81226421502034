'use client';

import { useLogout } from '@kelvininc/nextjs-utils/client';
import { BaseService, EKvHttpStatusCode, IKvHttpResponse } from '@kelvininc/node-client-sdk';
import { useEffect } from 'react';
import { filter, take } from 'rxjs/operators';

export const useUnauthorizedErrorListener = () => {
	const logout = useLogout();

	useEffect(() => {
		const sub = BaseService.onError()
			.pipe(
				filter((error: IKvHttpResponse) => error.status === EKvHttpStatusCode.Unauthorized),
				take(1)
			)
			.subscribe((err: IKvHttpResponse) => {
				console.warn('Invalid Session', err);
				logout();
			});

		return () => sub.unsubscribe();
	}, [logout]);
};
