import dayjs from 'dayjs';
import { upperFirst } from 'lodash-es';

const getFormattedDateTime = (scheduleDate: string): string => {
	const now = dayjs();
	const schedule = dayjs(scheduleDate);

	if (now.isSame(schedule, 'day')) {
		return `at ${schedule.format('H:mm')}`;
	}

	if (now.isSame(schedule, 'week')) {
		return `on ${schedule.format('dddd, H:mm')}`;
	}

	if (now.isSame(schedule, 'year')) {
		return `on ${schedule.format('dddd, MMM D, H:mm')}`;
	}

	return `on ${schedule.format('MMM D YYYY, H:mm')}`;
};

const getPermanentScheduleParametersFormattedDateTime = (scheduleDate: string): string => {
	return upperFirst(getFormattedDateTime(scheduleDate));
};

const getTemporaryScheduleParametersFormattedDateTime = (
	scheduleDate: string,
	revertDate: string
): string => {
	const now = dayjs();
	const schedule = dayjs(scheduleDate);

	if (schedule.isAfter(now)) {
		return `Starts ${getFormattedDateTime(scheduleDate)}`;
	}

	return `Ends ${getFormattedDateTime(revertDate)}`;
};

export const getScheduleParametersFormattedDateTime = (
	scheduleDate: string,
	revertDate?: string
): string => {
	if (!revertDate) {
		return getPermanentScheduleParametersFormattedDateTime(scheduleDate);
	}

	return getTemporaryScheduleParametersFormattedDateTime(scheduleDate, revertDate);
};
