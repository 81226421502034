import { ActionButton } from '../../../../../../../core/components/ActionButton';
import { ITableAction } from '../../../../../../../core/components/AgGridTable/types';

import styles from './styles.module.scss';

type ActionsManagerProps = {
	actions: ITableAction[];
};

export const ActionsManager = ({ actions = [] }: ActionsManagerProps) => {
	return (
		<div className={styles.ActionsManager}>
			{actions.map((action) => (
				<ActionButton key={action.id} {...action} />
			))}
		</div>
	);
};
