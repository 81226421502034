import { KvCheckbox } from '@kelvininc/react-ui-components';
import { useCallback } from 'react';

import { IBaseTData, ICellRendererParams } from '../../../core/components/AgGridTable/types';
import { ISelectionApi } from '../../../core/hooks';
import { withBaseCellRenderer } from '../BaseCellRenderer';

import styles from './styles.module.scss';

const Component = <TData extends IBaseTData>({
	node,
	api,
	context
}: ICellRendererParams<TData>) => {
	const selectionApi = context.selectionApi as ISelectionApi<TData>;
	const isSelected = node ? selectionApi.isSelected(node) : false;
	const isRowSelectable = node?.selectable ?? false;

	const onClick = useCallback(() => {
		if (api && isRowSelectable && node) {
			selectionApi.setSelected(!isSelected, node);
		}
	}, [api, isRowSelectable, isSelected, node, selectionApi]);

	return (
		<KvCheckbox
			checked={isSelected}
			disabled={!isRowSelectable}
			onClickCheckbox={onClick}
			class={styles.Checkbox}
		/>
	);
};

export const SelectionCellRenderer = withBaseCellRenderer(Component);
