import { EBadgeState, KvBadge } from '@kelvininc/react-ui-components';
import { CopyToClipboard, Link } from '@kelvininc/shared-ui';
import classNames from 'classnames';
import { isNil } from 'lodash-es';

import {
	GetLinkCallback,
	GetOnClickCallback,
	IBaseTData,
	ICellRendererParams
} from '../../../core/components/AgGridTable/types';
import { withBaseCellRenderer } from '../BaseCellRenderer';

import styles from './styles.module.scss';
import { getDescriptionValue } from './utils';

export type TextCellRendererParams<TData extends IBaseTData> = {
	/** A callback to retrieve a link to the title section */
	getTitleLink?: GetLinkCallback<TData, string>;
	/** A function or expression to define the cell click */
	onClickTitle?: GetOnClickCallback<TData, string>;
	/** Set to `true` if you want to show copy style on hover the title. Default: `false` */
	hasTitleCopyClipboard?: boolean;
	/** Set the title tooltip suffix clipboard text, will add it to `Copy`. */
	titleCopyClipboardSuffix?: string;
	/** Description to be rendered below the title. */
	description?: string | ((data: TData) => string);
	/** A callback to retrieve a link to the description section */
	getDescriptionLink?: GetLinkCallback<TData, string>;
	/** A function or expression to define the cell click */
	onClickDescription?: GetOnClickCallback<TData, string>;
	/** Set to `true` if you want to show copy style on hover the description. Default: `false` */
	hasDescriptionCopyClipboard?: boolean;
	/** Set the description tooltip suffix clipboard text, will add it to `Copy`. */
	descriptionCopyClipboardSuffix?: string;
	/** Set to true to enable the router prefetch: Default `false` */
	prefetchDescriptionLink?: boolean;
	/** A callback to retrieve a badge to be added at cell renderer right */
	getBadge?: (value: string, data: TData) => string | undefined;
	/** Adds a right slot to the cell to place buttons or other jsx elements */
	textCellActions?: JSX.Element | null;
};

const Component = <TData extends IBaseTData>(
	props: ICellRendererParams<TData, string> & TextCellRendererParams<TData>
) => {
	const {
		valueFormatted,
		value = '',
		description,
		disabled,
		getLink,
		getTitleLink,
		prefetchLink = false,
		onClickTitle: onClickTitleCallback,
		hasTitleCopyClipboard = false,
		titleCopyClipboardSuffix,
		getDescriptionLink,
		prefetchDescriptionLink = false,
		onClickDescription: onClickDescriptionCallback,
		hasDescriptionCopyClipboard = false,
		descriptionCopyClipboardSuffix,
		data,
		column,
		getBadge,
		api,
		textCellActions,
		context
	} = props;

	const titleValue = `${valueFormatted ?? value}`;
	const titleLink = getTitleLink?.(
		titleValue,
		data as TData,
		column?.getColId() as string,
		api,
		context
	);
	const cellLink = getLink?.(
		titleValue,
		data as TData,
		column?.getColId() as string,
		api,
		context
	);
	const onClickTitle = onClickTitleCallback
		? () =>
				onClickTitleCallback(
					titleValue,
					data as TData,
					column?.getColId() as string,
					api,
					context
				)
		: undefined;

	const descriptionValue = getDescriptionValue(description, props.data);
	const descriptionLink = descriptionValue
		? getDescriptionLink?.(
				descriptionValue,
				data as TData,
				column?.getColId() as string,
				api,
				context
			)
		: undefined;
	const onClickDescription =
		descriptionValue && onClickDescriptionCallback
			? () =>
					onClickDescriptionCallback(
						descriptionValue,
						data as TData,
						column?.getColId() as string,
						api,
						context
					)
			: undefined;
	const hasDescription = !isNil(descriptionValue);
	const badge = getBadge?.(value ?? '', data as TData);

	return (
		<div className={styles.TextCellRenderer}>
			<div className={styles.Details}>
				<div
					className={classNames(styles.Title, {
						[styles.HasCopy]: hasTitleCopyClipboard && !disabled,
						[styles.HasDescription]: hasDescription,
						[styles.Disabled]: disabled
					})}>
					<CopyToClipboard
						enabled={hasTitleCopyClipboard && !disabled}
						text={titleValue}
						tooltipSuffix={titleCopyClipboardSuffix}
						customClasses={styles.CopyClipboard}>
						<Link
							href={titleLink}
							onClick={onClickTitle}
							disabled={disabled}
							prefetch={prefetchLink}
							className={classNames({
								[styles.Link]: (!isNil(titleLink) || !isNil(cellLink)) && !disabled,
								[styles.Disabled]: disabled
							})}>
							{titleValue}
						</Link>
					</CopyToClipboard>
				</div>
				{hasDescription && (
					<div
						className={classNames(styles.Description, {
							[styles.HasCopy]: !disabled && hasDescriptionCopyClipboard,
							[styles.Disabled]: disabled
						})}>
						<CopyToClipboard
							enabled={hasDescriptionCopyClipboard && !disabled}
							text={descriptionValue}
							tooltipSuffix={descriptionCopyClipboardSuffix}
							customClasses={styles.CopyClipboard}>
							<Link
								href={descriptionLink}
								onClick={onClickDescription}
								disabled={disabled}
								prefetch={prefetchDescriptionLink}
								className={classNames({
									[styles.Link]: !isNil(descriptionLink) && !disabled
								})}>
								{descriptionValue}
							</Link>
						</CopyToClipboard>
					</div>
				)}
			</div>
			{badge && <KvBadge state={EBadgeState.Info}>{badge}</KvBadge>}
			{textCellActions && textCellActions}
		</div>
	);
};

export const TextCellRenderer = withBaseCellRenderer(Component, { hasCellTooltip: true });
