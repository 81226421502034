import { FunctionComponent } from '@kelvininc/types';

import { Context, PropsWithChildren, createContext, useContext, useState } from 'react';

import { ITableManagementAddColumnContextValues } from './types';

const TableManagementAddColumnContext: Context<null | ITableManagementAddColumnContextValues> =
	createContext<null | ITableManagementAddColumnContextValues>(null);

export const TableManagementAddColumnProvider = ({ children }: PropsWithChildren<{}>) => {
	const [hasAddColumnChanges, setHasAddColumnChanges] = useState<boolean>(false);

	return (
		<TableManagementAddColumnContext.Provider
			value={{
				hasAddColumnChanges: [hasAddColumnChanges, setHasAddColumnChanges]
			}}>
			{children}
		</TableManagementAddColumnContext.Provider>
	);
};

export const useTableManagementAddColumnContext = (): ITableManagementAddColumnContextValues => {
	const context = useContext(TableManagementAddColumnContext);

	if (!context) {
		throw new Error('Missing table management context');
	}

	return context;
};

export const withTableManagementAddColumnProvider = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>
) => {
	return function TableManagementAddColumnProviderWrapper(
		componentProps: PropsWithChildren<ComponentProps>
	) {
		return (
			<TableManagementAddColumnProvider>
				<Component {...componentProps} />
			</TableManagementAddColumnProvider>
		);
	};
};
