import { App, AppRegistryService, WorkloadItem } from '@kelvininc/node-client-sdk';

import { KvWizard, KvWizardCustomEvent } from '@kelvininc/react-ui-components';
import {
	AlertActions,
	closeAlertInModal,
	closeModal,
	openAlertInModal,
	openModal,
	openToaster,
	setBlockAlertInModal,
	setModalConfig,
	setModalOverlay,
	useUpdate,
	withRecoilRoot,
	withSuspense
} from '@kelvininc/shared-ui';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';

import { tap } from 'rxjs/operators';

import { buildCreateImageModalConfig } from '../../../../page-components/OrchestrationPage/WorkloadsList/components/CreateImageModal/utils';
import { DEPLOY_SUCCESS_TOASTER_CONFIG, EDIT_SUCCESS_TOASTER_CONFIG } from '../config';
import { useAppPayload } from '../hooks/useAppPayload';
import { useAppTypeModalAlert } from '../hooks/useAppTypeModalAlerts';
import styles from '../styles.module.scss';
import { DeployWorkloadData, EDeployWorkloadType, EWorkloadStep } from '../types';

import { buildDeployConfirmationAlert, getDeployErrorAlert } from '../utils';
import { WorkloadConfiguration } from '../WorkloadConfiguration';

import { AppInfo } from './AppInfo';
import { useAppPayloadLoadable } from './hooks/useAppPayloadLoadable';
import { useDeployWorkloadWizard } from './hooks/useDeployWorkloadWizard';
import { useFilteredApplications } from './hooks/useFilteredApplications';
import { IDeployWorkloadWizardProps } from './types';
import { getAppPayload, getErrorMessage, getSelectedApplicationKey } from './utils';
import { WorkloadForm } from './WorkloadForm';

import { AppDeploymentService } from '@/src/services/app-deployment-service';
import { useApplicationService } from '@/src/services/application-service';

const Component = ({
	flowType,
	initialData,
	downloadImage,
	reloadTable
}: IDeployWorkloadWizardProps) => {
	const { isProfessionalEdition } = useApplicationService();
	const { applicationsMap, applications } = useFilteredApplications();

	const {
		wizardConfig,
		modalConfig,
		data,
		stepStateHash,
		currentStep,
		currentWorkloadStep,
		onStepChange,
		onConfigurationChange,
		onDataInfoChange
	} = useDeployWorkloadWizard(flowType, { ...initialData });

	useEffect(() => {
		setModalConfig(modalConfig);
	}, [modalConfig, applications]);

	const isEditMode = useMemo(
		() => [EDeployWorkloadType.Edit, EDeployWorkloadType.UpdateWorkload].includes(flowType),
		[flowType]
	);

	useAppTypeModalAlert(applicationsMap, currentStep, isEditMode, data.appName);

	// Get application payload when change the app name or app version selected.
	const selectedApplicationKey = useMemo(
		() => getSelectedApplicationKey(isEditMode, data),
		[isEditMode, data]
	);

	const appPayload = useAppPayloadLoadable(selectedApplicationKey);

	const [isSelectedAppBridgeType, payloadObject] = useAppPayload(
		applicationsMap,
		data,
		data.appName,
		appPayload
	);

	const WorkloadStep = useMemo(
		() =>
			({
				[EWorkloadStep.AppInfo]: (
					<AppInfo
						applications={applications}
						flowType={flowType}
						onChange={(data, valid) =>
							onDataInfoChange(EWorkloadStep.AppInfo, data, valid)
						}
						{...data}
					/>
				),
				[EWorkloadStep.WorkloadInfo]: (
					<WorkloadForm
						flowType={flowType}
						applicationsMap={applicationsMap}
						onChange={(data, valid) =>
							onDataInfoChange(EWorkloadStep.WorkloadInfo, data, valid)
						}
						{...data}
					/>
				),
				[EWorkloadStep.Configuration]: (
					<WorkloadConfiguration
						isEditMode={isEditMode}
						payloadObject={payloadObject}
						onChange={onConfigurationChange}
					/>
				)
			})[currentWorkloadStep],
		[
			applications,
			flowType,
			data,
			applicationsMap,
			isEditMode,
			payloadObject,
			onConfigurationChange,
			currentWorkloadStep,
			onDataInfoChange
		]
	);

	useUpdate(() => {
		if (isEditMode) return;

		const payload = getAppPayload(isSelectedAppBridgeType, appPayload);
		onConfigurationChange(payload);
	}, [onConfigurationChange, appPayload, isSelectedAppBridgeType]);

	const onConfirmDeploy = useCallback(
		(workloadData: DeployWorkloadData, app: App) => {
			setBlockAlertInModal(true);

			return AppDeploymentService.deployApp({
				appType: app.type,
				parameters: {
					...workloadData
				}
			})
				.then(() => {
					reloadTable?.();
					closeModal();
					if (isProfessionalEdition) {
						openModal(
							buildCreateImageModalConfig({
								workloadName: workloadData.name,
								clusterName: workloadData.clusterName,
								onClickDownload: (workloadName) => {
									downloadImage?.(new WorkloadItem({ name: workloadName }));
									closeModal();
								}
							})
						);
					} else {
						openToaster(
							[EDeployWorkloadType.Edit, EDeployWorkloadType.UpdateWorkload].includes(
								flowType
							)
								? EDIT_SUCCESS_TOASTER_CONFIG
								: DEPLOY_SUCCESS_TOASTER_CONFIG
						);
					}
				})
				.catch((error) => openAlertInModal(getDeployErrorAlert(getErrorMessage(error))))
				.finally(() => {
					setBlockAlertInModal(false);
					setModalOverlay(false);
				});
		},
		[downloadImage, flowType, isProfessionalEdition, reloadTable]
	);

	const onDeploy = useCallback(() => {
		if (!data.appName) return;

		setModalOverlay(true);
		AppRegistryService.getAppRegistryApp({ appName: data.appName })
			.pipe(
				tap((app) => {
					openAlertInModal({
						...buildDeployConfirmationAlert(isProfessionalEdition),
						actions: (
							<AlertActions
								onClickCancel={() => {
									closeAlertInModal();
									setModalOverlay(false);
								}}
								onClickConfirm={() =>
									onConfirmDeploy(data as DeployWorkloadData, app)
								}
							/>
						)
					});
				})
			)
			.subscribe();
	}, [data, isProfessionalEdition, onConfirmDeploy]);

	const onStepChangeHandle = useCallback(
		({ detail }: KvWizardCustomEvent<number>) => onStepChange(detail),
		[onStepChange]
	);

	return (
		<div
			className={classNames(styles.DeployWorkloadWizardContent, {
				[styles[`Step${currentWorkloadStep}`]]: true
			})}>
			<KvWizard
				{...wizardConfig}
				currentStep={currentStep}
				currentStepState={stepStateHash[currentWorkloadStep]}
				onCancelClick={() => closeModal()}
				onCompleteClick={onDeploy}
				onGoToStep={onStepChangeHandle}>
				<div className={styles.StepContainer} slot="step-content">
					{WorkloadStep}
				</div>
			</KvWizard>
		</div>
	);
};

export const DeployWorkloadWizard = withRecoilRoot(withSuspense(Component));
