import { PanelAlertConfig, useSidepanel } from '@kelvininc/shared-ui';

import { FunctionComponent } from '@kelvininc/types';
import {
	Context,
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react';

import { getManagementSidepanelKey } from '../../utils';

import { ITableManagementStateContextValues } from './types';

const TableManagementStateContext: Context<null | ITableManagementStateContextValues> =
	createContext<null | ITableManagementStateContextValues>(null);

type TableManagementStateProviderProps = {
	id: string;
};

export const TableManagementStateProvider = ({
	id,
	children
}: PropsWithChildren<TableManagementStateProviderProps>) => {
	const sidepanelKey = useMemo(() => getManagementSidepanelKey(id), [id]);
	const [alert, setAlert] = useState<PanelAlertConfig>();
	const { open, close, toggle, isOpen } = useSidepanel();

	const openSidePanel = useCallback(() => {
		open(sidepanelKey);
	}, [open, sidepanelKey]);

	const toggleSidePanel = useCallback(() => {
		toggle(sidepanelKey);
	}, [toggle, sidepanelKey]);

	const closeSidePanel = useCallback(() => {
		close(sidepanelKey);
	}, [close, sidepanelKey]);

	return (
		<TableManagementStateContext.Provider
			value={{
				sidepanelKey,
				openSidePanel,
				toggleSidePanel,
				closeSidePanel,
				isOpen,
				alert,
				setAlert
			}}>
			{children}
		</TableManagementStateContext.Provider>
	);
};

export const useTableManagementStateContext = (): ITableManagementStateContextValues => {
	const context = useContext(TableManagementStateContext);

	if (!context) {
		throw new Error('Missing table management state context');
	}

	return context;
};

export const withTableManagementStateProvider = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>,
	providerProps: TableManagementStateProviderProps
) => {
	return function TableManagementStateProviderWrapper(
		componentProps: PropsWithChildren<ComponentProps>
	) {
		return (
			<TableManagementStateProvider {...providerProps}>
				<Component {...componentProps} />
			</TableManagementStateProvider>
		);
	};
};
