import { useCallback } from 'react';

import { ActionButton } from '../../../../../../../core/components/ActionButton';

import { IBaseTData } from '../../../../../../../core/components/AgGridTable/types';
import { useApiRef } from '../../../../../../../core/components/Table/contexts/TableApiRefContext';
import {
	useSelectedRows,
	useSelectionActions
} from '../../../../../../../core/components/Table/contexts/TableSelectionContext';
import { ISelectionAction } from '../../../../../../../core/components/Table/types';

import styles from './styles.module.scss';
import { groupActionsByGroup } from './utils';

export const SelectionManager = <TData extends IBaseTData>() => {
	const api = useApiRef();
	const [selectionRows] = useSelectedRows<TData>();
	const actions = useSelectionActions<TData>();

	const isActionDisabled = useCallback(
		(action: ISelectionAction<TData>) =>
			action.isDisabled?.(selectionRows) ?? action.disabled ?? false,
		[selectionRows]
	);
	const onClickAction = useCallback(
		(action: ISelectionAction<TData>) => {
			action.onClick?.(selectionRows);
			api.current?.clearSelectedRows();
		},
		[api, selectionRows]
	);

	const actionsGroups = Object.entries(groupActionsByGroup(actions));

	return (
		<div className={styles.SelectionManager}>
			{actionsGroups.map(([groupName, groupActions], groupIndex) => (
				<div key={groupName} className={styles.GroupContainer}>
					<div className={styles.Group}>
						{groupActions.map((action) => (
							<div key={action.id} className={styles.Action}>
								<ActionButton
									{...action}
									disabled={isActionDisabled(action)}
									onClick={() => onClickAction(action)}
								/>
							</div>
						))}
					</div>
					{groupIndex < actionsGroups.length - 1 && <div className={styles.Divider} />}
				</div>
			))}
		</div>
	);
};
