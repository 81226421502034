import { DataStream } from '@kelvininc/node-client-sdk';
import { KvSingleSelectDropdown } from '@kelvininc/react-ui-components';
import { Skeleton, useDropdownSearchOptions } from '@kelvininc/shared-ui';

import { ComponentProps, useMemo } from 'react';

import {
	DEFAULT_SELECT_DATASTREAM_LABEL,
	DEFAULT_SELECT_DATASTREAM_NO_DATA_AVAILABLE_CONFIG,
	DEFAULT_SELECT_DATASTREAM_PLACEHOLDER,
	DEFAULT_SELECT_DATASTREAM_SEARCH_PLACEHOLDER,
	USE_DROPDOWN_OPTIONS
} from './config';
import styles from './styles.module.scss';

type DatastreamSelectorProps = {
	value?: string;
	datastreams?: DataStream[];
	onValueChange?: (datastream: string) => void;
} & Omit<
	ComponentProps<typeof KvSingleSelectDropdown>,
	| 'selectedOption'
	| 'options'
	| 'filteredOptions'
	| 'selectedOption'
	| 'onOptionSelected'
	| 'onSearchChange'
>;

export const DatastreamSelectorSkeleton = () => {
	return <Skeleton className={styles.Skeleton} />;
};

export const DatastreamSelector = ({
	label = DEFAULT_SELECT_DATASTREAM_LABEL,
	placeholder = DEFAULT_SELECT_DATASTREAM_PLACEHOLDER,
	searchPlaceholder = DEFAULT_SELECT_DATASTREAM_SEARCH_PLACEHOLDER,
	noDataAvailableConfig = DEFAULT_SELECT_DATASTREAM_NO_DATA_AVAILABLE_CONFIG,
	value,
	datastreams,
	onValueChange,
	...otherProps
}: DatastreamSelectorProps) => {
	const data = useMemo(() => datastreams ?? [], [datastreams]);

	const {
		setSearchTerm: setDatastreamSearchTerm,
		filteredOptions: datastreamFilteredOptions,
		options: datastreamOptions
	} = useDropdownSearchOptions(data, USE_DROPDOWN_OPTIONS);

	return (
		<KvSingleSelectDropdown
			label={label}
			placeholder={placeholder}
			options={datastreamOptions}
			filteredOptions={datastreamFilteredOptions}
			selectedOption={value}
			onOptionSelected={({ detail: newDatastream }) => onValueChange?.(newDatastream)}
			onSearchChange={({ detail: newTerm }) => setDatastreamSearchTerm(newTerm)}
			noDataAvailableConfig={noDataAvailableConfig}
			searchPlaceholder={searchPlaceholder}
			{...otherProps}
		/>
	);
};
