import { Guardrail } from '@kelvininc/node-client-sdk';
import { EDensityLevel, ITableSettings } from '@kelvininc/table';
import { ESortDirection } from '@kelvininc/types';

import { ETableColumnKey } from '../../types';

export const GUARDRAILS_SETTINGS_KEY_PREFIX = 'core.ui.guardrails';

export const GUARDRAILS_TABLE_DEFAULT_SETTINGS: ITableSettings<Guardrail> = {
	densityInitial: EDensityLevel.Medium,
	columnsOrder: [],
	columnsPinned: {},
	columnsHidden: {},
	columnsCustom: [],
	pageSizeInitial: 32,
	rowsPinnedInitial: [],
	sortByInitial: [ETableColumnKey.Asset],
	sortDirectionInitial: ESortDirection.Asc
};
