import { useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { EApplicationEntity } from '../types';

import { assetsListAtom } from '@/src/recoil/assets/atoms';
import { clustersListSelector } from '@/src/recoil/clusters/selectors';
import { connectionsListSelector } from '@/src/recoil/connections/selectors';
import { datastreamsListAtom } from '@/src/recoil/data-streams/atoms';
import { isLoadableValueArrayWithContent } from '@/src/utils/recoil';

export interface IUseExistingEntities {
	existingEntities: Record<EApplicationEntity, boolean>;
	hasRequestErrors: boolean;
	isLoading: boolean;
}

export const useExistingEntities = (): IUseExistingEntities => {
	const assetsLoadable = useRecoilValueLoadable(assetsListAtom);
	const datastreamsLoadable = useRecoilValueLoadable(datastreamsListAtom);
	const clustersLoadable = useRecoilValueLoadable(clustersListSelector);
	const connectionsLoadable = useRecoilValueLoadable(connectionsListSelector);

	const existingEntities = useMemo(
		() => ({
			[EApplicationEntity.Assets]: isLoadableValueArrayWithContent(assetsLoadable),
			[EApplicationEntity.Datastreams]: isLoadableValueArrayWithContent(datastreamsLoadable),
			[EApplicationEntity.Clusters]: isLoadableValueArrayWithContent(clustersLoadable),
			[EApplicationEntity.Connections]: isLoadableValueArrayWithContent(connectionsLoadable)
		}),
		[assetsLoadable, clustersLoadable, connectionsLoadable, datastreamsLoadable]
	);

	const hasRequestErrors = useMemo(
		() =>
			assetsLoadable.state === 'hasError' ||
			datastreamsLoadable.state === 'hasError' ||
			clustersLoadable.state === 'hasError' ||
			connectionsLoadable.state === 'hasError',
		[assetsLoadable, clustersLoadable, connectionsLoadable, datastreamsLoadable]
	);

	const isLoading = useMemo(
		() =>
			assetsLoadable.state === 'loading' ||
			datastreamsLoadable.state === 'loading' ||
			clustersLoadable.state === 'loading' ||
			connectionsLoadable.state === 'loading',
		[assetsLoadable, clustersLoadable, connectionsLoadable, datastreamsLoadable]
	);

	return { existingEntities, hasRequestErrors, isLoading };
};
