import { useSetting } from '@kelvininc/shared-ui';

import { useCallback, useMemo } from 'react';

import {
	DEFAULT_DEPLOYED_SMART_APPS,
	DEFAULT_SMART_APPS,
	DEPLOYED_SMART_APPS_SETTING_KEY,
	SMART_APPS_SETTING_KEY
} from './config';

import { deployedSmartAppsCatalogAtom, smartAppsCatalogAtom } from '@/src/recoil/smart-apps/atoms';
import { ISmartAppSettings } from '@/src/types/smart-app';

export const useSmartAppsSettings = (): ISmartAppSettings => {
	const { value: smartApps } = useSetting(
		SMART_APPS_SETTING_KEY,
		DEFAULT_SMART_APPS,
		smartAppsCatalogAtom
	);
	const { value: deployedApps, setValue } = useSetting(
		DEPLOYED_SMART_APPS_SETTING_KEY,
		DEFAULT_DEPLOYED_SMART_APPS,
		deployedSmartAppsCatalogAtom
	);

	const deployApp = useCallback(
		(smartAppName: string, unDeploy = false) => {
			return setValue((prev) => {
				const newDeployedApps = prev.includes(smartAppName)
					? unDeploy
						? prev.filter((appName) => appName !== smartAppName)
						: prev
					: [...prev, smartAppName];

				return newDeployedApps;
			});
		},
		[setValue]
	);
	const undeployedSmartApps = useMemo(() => {
		return smartApps.apps
			.filter((app) => !deployedApps.includes(app.name))
			.map((app) => app.name);
	}, [deployedApps, smartApps.apps]);

	return {
		...smartApps,
		undeployedSmartApps,
		deploySmartApp: deployApp,
		undeploySmartApp: (smartAppName) => deployApp(smartAppName, true)
	};
};
