import { ComponentProps, FunctionComponent } from 'react';

import { BaseOverlay } from './BaseOverlay';

export enum EDataState {
	OnInit = 'on-init',
	IsEmpty = 'is-empty',
	HasError = 'has-error',
	IsLoading = 'is-loading',
	HasResults = 'has-results',
	NoResults = 'no-results',
	NoFiltersResults = 'no-filters-result'
}

export type OverlaysConfig = Record<EDataState, ComponentProps<typeof BaseOverlay>>;

export type OverlaysComponents = Record<
	Exclude<EDataState, EDataState.OnInit | EDataState.HasResults>,
	FunctionComponent<ComponentProps<typeof BaseOverlay>>
>;
