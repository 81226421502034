import {
	EActionButtonType,
	EComponentSize,
	EIconName,
	KvActionButtonText,
	KvTextField
} from '@kelvininc/react-ui-components';

import { ITableAdvancedFilters } from '../../../../../../../../../core/components/AgGridTable/types';

type AdvancedFilterDropdownActionProps = {
	isOpen: boolean;
	disabled: boolean;
	onClick: () => void;
	onClear: () => void;
	submittedFilters?: ITableAdvancedFilters;
};

export const AdvancedFilterDropdownAction = ({
	isOpen,
	disabled,
	onClick,
	onClear,
	submittedFilters = {}
}: AdvancedFilterDropdownActionProps) => {
	const appliedFiltersCount = Object.values(submittedFilters).flat().length;

	if (isOpen || appliedFiltersCount > 0) {
		return (
			<KvTextField
				forcedFocus={isOpen}
				onFieldClick={onClick}
				inputReadonly
				actionIcon={EIconName.Close}
				value="Advanced Filters"
				size={EComponentSize.Small}
				inputDisabled={disabled}
				onRightActionClick={onClear}
				badge={appliedFiltersCount > 0 ? appliedFiltersCount.toString() : ''}
			/>
		);
	}

	return (
		<KvActionButtonText
			icon={EIconName.Add}
			type={EActionButtonType.Tertiary}
			text="Add Filter"
			onClickButton={onClick}
			size={EComponentSize.Small}
			disabled={disabled}
		/>
	);
};
