import { Controller, UseFormReturn } from 'react-hook-form';

import { NumericGuardrailCraftWizardFormValues } from '../../types';
import { GuardrailAdvancedValueField } from '../GuardrailAdvancedValueField';

import styles from './styles.module.scss';

type AdvancedGuardrailValuesFormProps = {
	disabled: boolean;
	form: UseFormReturn<NumericGuardrailCraftWizardFormValues>;
};

export const AdvancedGuardrailValuesForm = ({
	disabled,
	form
}: AdvancedGuardrailValuesFormProps) => {
	const { control } = form;

	return (
		<div className={styles.AdvancedGuardrailValuesForm}>
			<div className={styles.Field}>
				<Controller
					name="value.increase.min"
					control={control}
					render={({ field: { value, onChange, onBlur }, formState: { errors } }) => (
						<GuardrailAdvancedValueField
							label="Increase Minimum is"
							disabled={disabled}
							value={value}
							errors={errors?.value?.increase?.min}
							onChangeType={(newType) => onChange({ ...value, type: newType })}
							onChangeValue={(newValue) =>
								onChange({
									...value,
									[value.type]: { ...value[value.type], value: newValue }
								})
							}
							onChangeInclusive={(newInclusive) =>
								onChange({
									...value,
									percentage: {
										...value.percentage,
										inclusive: newInclusive
									},
									number: {
										...value.number,
										inclusive: newInclusive
									}
								})
							}
							onBlur={onBlur}
						/>
					)}
				/>
			</div>
			<div className={styles.Field}>
				<Controller
					name="value.increase.max"
					control={control}
					render={({ field: { value, onChange, onBlur }, formState: { errors } }) => (
						<GuardrailAdvancedValueField
							label="Increase Maximum is"
							disabled={disabled}
							value={value}
							errors={errors?.value?.increase?.max}
							onChangeType={(newType) => onChange({ ...value, type: newType })}
							onChangeValue={(newValue) =>
								onChange({
									...value,
									[value.type]: { ...value[value.type], value: newValue }
								})
							}
							onChangeInclusive={(newInclusive) =>
								onChange({
									...value,
									percentage: {
										...value.percentage,
										inclusive: newInclusive
									},
									number: {
										...value.number,
										inclusive: newInclusive
									}
								})
							}
							onBlur={onBlur}
						/>
					)}
				/>
			</div>
			<div className={styles.Field}>
				<Controller
					name="value.decrease.min"
					control={control}
					render={({ field: { value, onChange, onBlur }, formState: { errors } }) => (
						<GuardrailAdvancedValueField
							label="Decrease Minimum is"
							disabled={disabled}
							value={value}
							errors={errors?.value?.decrease?.min}
							onChangeType={(newType) => onChange({ ...value, type: newType })}
							onChangeValue={(newValue) =>
								onChange({
									...value,
									[value.type]: { ...value[value.type], value: newValue }
								})
							}
							onChangeInclusive={(newInclusive) =>
								onChange({
									...value,
									percentage: {
										...value.percentage,
										inclusive: newInclusive
									},
									number: {
										...value.number,
										inclusive: newInclusive
									}
								})
							}
							onBlur={onBlur}
						/>
					)}
				/>
			</div>
			<div className={styles.Field}>
				<Controller
					name="value.decrease.max"
					control={control}
					render={({ field: { value, onChange, onBlur }, formState: { errors } }) => (
						<GuardrailAdvancedValueField
							label="Decrease Maximum is"
							disabled={disabled}
							value={value}
							errors={errors?.value?.decrease?.max}
							onChangeType={(newType) => onChange({ ...value, type: newType })}
							onChangeValue={(newValue) =>
								onChange({
									...value,
									[value.type]: { ...value[value.type], value: newValue }
								})
							}
							onChangeInclusive={(newInclusive) =>
								onChange({
									...value,
									percentage: {
										...value.percentage,
										inclusive: newInclusive
									},
									number: {
										...value.number,
										inclusive: newInclusive
									}
								})
							}
							onBlur={onBlur}
						/>
					)}
				/>
			</div>
		</div>
	);
};
