import { ParameterDefinitionItem } from '@kelvininc/node-client-sdk';

import { EComponentSize, KvSchemaForm, KvTextField } from '@kelvininc/react-ui-components';
import { IClosedLoopSettings } from '@kelvininc/types';

import { convertFilterToPrimitiveType } from '../../../../../../../../../../../utils';
import { ETableAdvancedFilterType } from '../../../../../../../../../AgGridTable';
import { SELECT_OPERATOR_PLACEHOLDER } from '../../../../config';
import { getFilterUiSchema } from '../../../../utils';
import { ApplicationParameterAdvancedFilterSchemaFormData } from '../../types';

import styles from './styles.module.scss';
import { getParameterSchemaForm } from './utils';

type ApplicationParameterSchemaFormProps = {
	formData?: ApplicationParameterAdvancedFilterSchemaFormData;
	parameter?: string;
	parameters?: Record<string, ParameterDefinitionItem>;
	values?: Record<string, string[] | boolean[]>;
	onChangeForm: (newForm: ApplicationParameterAdvancedFilterSchemaFormData) => void;
	closedLoopSettings: IClosedLoopSettings;
};

export const ApplicationParameterSchemaForm = ({
	formData,
	parameter,
	parameters = {},
	values = {},
	onChangeForm,
	closedLoopSettings
}: ApplicationParameterSchemaFormProps) => {
	if (!parameter || !parameters[parameter]) {
		return (
			<KvTextField
				className={styles.OperatorInput}
				placeholder={SELECT_OPERATOR_PLACEHOLDER}
				size={EComponentSize.Small}
				inputDisabled
			/>
		);
	}

	const paramDefinition = parameters[parameter];
	const paramValues = values[parameter];

	const uiSchema = getFilterUiSchema(paramDefinition.primitiveType, paramValues);

	return (
		<KvSchemaForm
			schema={getParameterSchemaForm(paramDefinition, paramValues, closedLoopSettings)}
			uiSchema={uiSchema}
			formData={formData}
			submittedData={formData}
			onChange={({ formData: { operator, value } = {} }) => {
				onChangeForm({
					operator,
					value: convertFilterToPrimitiveType(
						value ?? undefined,
						ETableAdvancedFilterType.ApplicationParameter,
						paramDefinition.primitiveType
					)
				});
			}}
			customClass={styles.SchemaForm}
		/>
	);
};
