import {
	CellClassParams,
	CellEditorSelectorFunc,
	CellRendererSelectorFunc,
	ColDef,
	ColDefField,
	Column,
	EditableCallback,
	EditableCallbackParams,
	GridApi,
	HeaderClass,
	HeaderClassParams,
	ICellEditorParams,
	ICellRendererParams,
	IRowNode,
	IServerSideDatasource,
	IServerSideGetRowsParams,
	LoadSuccessParams,
	RowModelType,
	ValueFormatterFunc,
	ValueFormatterParams
} from '@ag-grid-community/core';
import { EKvHttpStatusCode, IKvHttpResponse } from '@kelvininc/node-client-sdk';
import { EDataState } from '@kelvininc/shared-ui';
import { isHttpError } from '@kelvininc/tsutils';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { isEmpty, isFunction, isString } from 'lodash-es';
import { Dispatch, SetStateAction } from 'react';

import { CELL_ALIGNMENT_CLASSES, FIRST_PAGE, HEADER_ALIGNMENT_CLASSES } from './config';

import {
	CellClass,
	Datasource,
	ECellAlignment,
	EColumnPinAlignment,
	IBaseTData,
	ICellComponentSelector,
	ICellEditorSelector,
	IColumnDef,
	IColumnOptions,
	IColumnsHiddenConfig,
	IColumnsPinConfig,
	IDefaultTValue,
	IPaginationInfo,
	IPaginationTotalInfo,
	ITableAdvancedFilters,
	ITableContext,
	ITableFilters
} from './types';

const getDefaultHeaderClass = <TData extends IBaseTData>(
	params: HeaderClassParams<TData>
): ClassNamesProp => {
	const isDraggable = params.column?.getColDef().suppressMovable === false;

	if (isDraggable) return 'ag-header-cell-draggable';
};

const getHeaderClasses = <TData extends IBaseTData>(
	classes: HeaderClass<TData> | undefined,
	params: HeaderClassParams<TData>
): string | string[] | undefined => {
	if (isFunction(classes)) {
		return classes(params);
	}

	return classes;
};

const getCellClasses = <TData extends IBaseTData>(
	classes: CellClass<TData> | undefined,
	params: CellClassParams<TData>
): string | string[] | undefined | null => {
	if (isFunction(classes)) {
		return classes(params);
	}

	return classes;
};

const getCellAlignmentClass = (alignment?: ECellAlignment): string | undefined =>
	classNames({
		[CELL_ALIGNMENT_CLASSES[ECellAlignment.Left]]: alignment === ECellAlignment.Left,
		[CELL_ALIGNMENT_CLASSES[ECellAlignment.Center]]: alignment === ECellAlignment.Center,
		[CELL_ALIGNMENT_CLASSES[ECellAlignment.Right]]: alignment === ECellAlignment.Right
	});

const getHeaderAlignmentClass = (alignment?: ECellAlignment): string | undefined =>
	classNames({
		[HEADER_ALIGNMENT_CLASSES[ECellAlignment.Left]]: alignment === ECellAlignment.Left,
		[HEADER_ALIGNMENT_CLASSES[ECellAlignment.Center]]: alignment === ECellAlignment.Center,
		[HEADER_ALIGNMENT_CLASSES[ECellAlignment.Right]]: alignment === ECellAlignment.Right
	});

const getValueFormatter = <TData extends IBaseTData>(
	config: IColumnOptions<TData>
): string | ValueFormatterFunc<TData> | undefined => {
	if (config.valueFormatter) {
		const formatter = config.valueFormatter;

		return ({ value, data, column, api, context }: ValueFormatterParams<TData>): string =>
			formatter(value, data as TData, column.getId(), api, context);
	}
};

const buildAgGridColumnDefWithConfigs = <TData extends IBaseTData>(
	config: IColumnDef<TData>,
	sortable: boolean
) => ({
	...buildAgGridColumnDef({
		...config,
		sortable: sortable && config.sortable
	})
});

const getEditable = <TData extends IBaseTData>(
	editable: boolean | ((data?: TData) => boolean) | undefined
): boolean | EditableCallback<TData> | undefined => {
	if (isFunction(editable)) {
		return (params: EditableCallbackParams<TData>) => editable(params.data);
	}
	return editable;
};

const getCellEditorSelector = <TData extends IBaseTData, TValue = IDefaultTValue>(
	editorSelector?: (data: TData) => ICellEditorSelector<TData, TValue>
): CellEditorSelectorFunc<TData> => {
	return (params: ICellEditorParams<TData>) => editorSelector?.(params.data);
};

const getCellRenderSelector = <TData extends IBaseTData, TValue = IDefaultTValue>(
	renderSelector?: (data?: TData) => ICellComponentSelector<TData, TValue>
): CellRendererSelectorFunc<TData> => {
	return (params: ICellRendererParams<TData>) => renderSelector?.(params.data);
};

export const buildAgGridColumnOptions = <TData extends IBaseTData, TValue = IDefaultTValue>(
	config: IColumnOptions<TData, TValue> = {}
): ColDef<TData> => ({
	suppressMovable: config.draggable === undefined ? undefined : !config.draggable,
	cellEditor: config.cellEditor,
	cellEditorSelector: getCellEditorSelector(config.cellEditorSelector),
	cellEditorParams: config.cellEditorParams,
	singleClickEdit: config.singleClickEdit,
	editable: getEditable(config.editable),
	sortable: config.sortable,
	resizable: config.resizable,
	valueFormatter: getValueFormatter(config),
	headerComponent: config.headerComponent,
	headerComponentParams: config.headerComponentParams,
	cellRenderer: config.cellComponent,
	cellRendererSelector: getCellRenderSelector(config.cellComponentSelector),
	cellRendererParams: config.cellComponentParams,
	pinned: config.pinned,
	hide: config.hide,
	width: config.width,
	initialWidth: config.initialWidth,
	minWidth: config.minWidth,
	maxWidth: config.maxWidth,
	lockPinned: config.lockPinned,
	lockVisible: config.lockVisible,
	suppressSizeToFit: config.resizable === false,
	headerClass: (params: HeaderClassParams<TData>) =>
		classNames(
			getDefaultHeaderClass(params),
			getHeaderAlignmentClass(config.headerAlignment),
			getHeaderClasses(config.headerClass, params)
		),
	cellClass: (params: CellClassParams<TData>) =>
		classNames(
			getCellAlignmentClass(config.cellAlignment),
			getCellClasses(config.cellClass, params)
		)
});

export const buildAgGridColumnDef = <TData extends IBaseTData>(
	config: IColumnDef<TData>
): ColDef<TData> => ({
	colId: config.id,
	field: config.accessor as ColDefField<TData>,
	headerName: config.title,
	...buildAgGridColumnOptions(config)
});

export const buildServerDatasource = <TData extends IBaseTData>(
	datasource: Datasource<TData>
): IServerSideDatasource => ({
	getRows({ success, context, api }: IServerSideGetRowsParams<TData>) {
		const {
			filters,
			advancedFilters,
			searchTerm,
			sortBy = [],
			sortDirection,
			page,
			pageSize,
			rowsPinned,
			columnDefs,
			onRowsPinnedChange,
			onStateChange,
			onPaginationChange,
			onPaginationTotalChange,
			getRowId,
			isRowMaster
		} = (context ?? {}) as ITableContext<TData>;

		// Filter not hidden columns
		const visibleColumnDefs = columnDefs.filter(({ hide }) => hide !== true);

		// Check if the sorted column exists and is not hidden
		const isSortValid = sortBy.every((sortByColumn) =>
			visibleColumnDefs.some(({ id }) => sortByColumn === id)
		);

		datasource(
			{
				advancedFilters,
				filters,
				search: [searchTerm].filter(isString),
				sortBy: isSortValid ? sortBy : [],
				sortDirection,
				page,
				pageSize,
				pinned: rowsPinned.map(getRowId),
				columnDefs: visibleColumnDefs
			},
			api
		)
			.then(({ data, pagination }) => {
				// Search for pinned rows on the pinned data
				const [newData, newPinnedData] = data.reduce<[TData[], TData[]]>(
					(accumulator, item) => {
						if (rowsPinned.some((rowData) => getRowId(rowData) === getRowId(item))) {
							accumulator[1].push(item);
						} else {
							accumulator[0].push(item);
						}
						return accumulator;
					},
					[[], []]
				);

				handleTableState(
					onStateChange,
					newData,
					newPinnedData,
					searchTerm,
					filters,
					advancedFilters
				);

				// Set table fetched data
				success({ rowData: newData });

				// Set table top pinned columns
				onRowsPinnedChange(newPinnedData);

				if (pagination) {
					// Call pagination callback
					handlePagination(
						pagination,
						onPaginationChange,
						onPaginationTotalChange,
						newData,
						newPinnedData
					);
				}

				refreshMasterProperty(api, isRowMaster);
			})
			.catch((error: Error | IKvHttpResponse) => {
				handleError(onStateChange, onRowsPinnedChange, success, error);
			});
	}
});

const getFiltersCount = (filters: ITableFilters, advancedFilters: ITableAdvancedFilters): number =>
	Object.values(filters).reduce<number>((accumulator, values) => {
		if (isEmpty(values)) {
			return accumulator;
		}

		return accumulator + 1;
	}, Object.values(advancedFilters).flat().length);

export const areFiltersEmpty = (
	filters: ITableFilters = {},
	advancedFilters: ITableAdvancedFilters = {}
): boolean => getFiltersCount(filters, advancedFilters) === 0;

export const handleTableState = <TData extends IBaseTData>(
	onStateChange: Dispatch<SetStateAction<EDataState>>,
	data: TData[],
	pinnedData: TData[],
	searchTerm?: string,
	filters?: ITableFilters,
	advancedFilters?: ITableAdvancedFilters
) => {
	const dataCount = data.length + pinnedData.length;
	if (dataCount > 0) {
		return onStateChange(EDataState.HasResults);
	}

	if (!isEmpty(searchTerm)) {
		return onStateChange(EDataState.NoResults);
	}

	if (!areFiltersEmpty(filters, advancedFilters)) {
		return onStateChange(EDataState.NoFiltersResults);
	}

	return onStateChange(EDataState.IsEmpty);
};

export const handlePagination = <TData extends IBaseTData>(
	pagination: IPaginationInfo & IPaginationTotalInfo,
	onPaginationChange?: (newPagination: IPaginationInfo | undefined) => void,
	onPaginationTotalChange?: (newPaginationTotal: IPaginationTotalInfo | undefined) => void,
	data: TData[] = [],
	pinnedData: TData[] = []
) => {
	const totalRenderedItems = data.length + pinnedData.length;

	onPaginationChange?.({
		page:
			totalRenderedItems === 0 && pagination.page !== FIRST_PAGE
				? FIRST_PAGE
				: pagination.page,
		pageSize: pagination.pageSize
	});
	onPaginationTotalChange?.({
		totalItems: pagination.totalItems,
		totalPages: pagination.totalPages
	});
};

export const handleError = <TData extends IBaseTData>(
	onStateChange: Dispatch<SetStateAction<EDataState>>,
	onRowsPinnedChange: (newRows: TData[]) => void,
	success: (params: LoadSuccessParams) => void,
	error: Error | IKvHttpResponse
) => {
	// Ignore errors from closed requests
	if (isHttpError(error, EKvHttpStatusCode.RequestClosed)) return;

	console.error(error);

	onStateChange(EDataState.HasError);

	// Clear row data
	success({ rowData: [] });

	// Clear pinned columns
	onRowsPinnedChange([]);
};

export const getColumnsIds = <TData extends IBaseTData>(
	columnDefs: ColDef<TData>[] = []
): string[] => columnDefs.map(({ colId }) => colId as string);

export const getColumnsPinned = <TData extends IBaseTData>(
	columnDefs: ColDef<TData>[] = []
): IColumnsPinConfig =>
	columnDefs.reduce<IColumnsPinConfig>(
		(accumulator, { colId, pinned }) => ({
			...accumulator,
			[colId as string]: pinned as EColumnPinAlignment | boolean | undefined
		}),
		{}
	);

export const getColumnsHidden = <TData extends IBaseTData>(
	columnDefs: ColDef<TData>[] = []
): IColumnsHiddenConfig =>
	columnDefs.reduce<IColumnsHiddenConfig>(
		(accumulator, { colId, hide }) => ({
			...accumulator,
			[colId as string]: hide === true
		}),
		{}
	);

export const refreshDatasource = <TData extends IBaseTData>(gridApi: GridApi<TData>): void => {
	// This is to ensure that the refresh takes place on the last tick, and
	// therefor will not be called while the cells are being re-render.
	executeGridAPICall(gridApi, (api) => {
		const rowModel = api.getModel()?.getType();

		if (rowModel === 'clientSide') {
			api.refreshClientSideRowModel();
		}

		if (rowModel === 'serverSide') {
			api.refreshServerSide({ purge: false });
		}

		api.refreshHeader();
	});
};

export const isCellAligned = ({ classList }: HTMLElement): boolean =>
	Array.from(classList).some((classe) => Object.values(CELL_ALIGNMENT_CLASSES).includes(classe));

export const isHeaderAligned = ({ classList }: HTMLElement): boolean =>
	Array.from(classList).some((classe) =>
		Object.values(HEADER_ALIGNMENT_CLASSES).includes(classe)
	);

/**
 * Bridge the table widget column defintion to the ag-grid column definition.
 * Also, if the column is "selectable", a selection column is added to the column definitions.
 *
 * @param columnDefs The table widget column definitions,
 * @param selectable If `true` the table is "selectable", `false` otherwise.
 * @param sortable If `true` the table is "sortable", `false` otherwise.
 */
export const buildAgGridColumnDefs = <TData extends IBaseTData>(
	columnDefs: IColumnDef<TData>[],
	sortable: boolean = false
): ColDef<TData>[] =>
	columnDefs.map((columnDef) => buildAgGridColumnDefWithConfigs(columnDef, sortable));

export const getRowHeight = (rowHeight: number): number => {
	// We need to add one pixel to the row height
	// due to the row bottom border;
	return rowHeight + 1;
};

export const getPinnedNodes = <TData extends IBaseTData>(
	api: GridApi<TData>
): IRowNode<TData>[] => {
	const rowsPinnedCount = api.getPinnedTopRowCount();

	return Array.from(
		{ length: rowsPinnedCount },
		(_value, index) => api.getPinnedTopRow(index) as IRowNode<TData>
	);
};

export const getPinnedData = <TData extends IBaseTData>(api: GridApi<TData>): TData[] =>
	getPinnedNodes(api).map(({ data }) => data as TData);

export const isDataPinned = <TData extends IBaseTData>(
	data: TData,
	api: GridApi<TData>,
	context: ITableContext<TData>
): boolean => {
	const { getRowId } = context;
	const pinnedRows = getPinnedData(api);

	return pinnedRows.some((row) => getRowId(row) === getRowId(data));
};

export const getDisplayedNodes = (api?: GridApi): IRowNode[] => {
	if (api) {
		return api.getRenderedNodes();
	}

	return [];
};

export const getColumnOptions = <TData extends IBaseTData>({
	id: _id,
	title: _title,
	accessor: _accessor,
	...columnOptions
}: IColumnDef<TData>): IColumnOptions<TData> => columnOptions;

export const getRowModelType = <TData extends IBaseTData>(data?: TData[]): RowModelType =>
	data !== undefined ? 'clientSide' : 'serverSide';

export const getState = <TData extends IBaseTData>(data?: TData[]): EDataState => {
	const isClientSideModel = getRowModelType(data) === 'clientSide';

	if (isClientSideModel) {
		if (isEmpty(data)) {
			return EDataState.IsEmpty;
		}

		return EDataState.HasResults;
	}

	return EDataState.OnInit;
};

const getTableWidth = <TData extends IBaseTData>(
	container: HTMLElement,
	api: GridApi<TData>
): number => {
	let scrollbarWidth = 0;

	// Get scrollbar element
	const scrollbar = container.querySelector('.ag-body-vertical-scroll');
	if (scrollbar) {
		scrollbarWidth = scrollbar.clientWidth;
	}

	// Get current renderered columns width
	const columns = api.getColumns()?.filter((column) => column.isVisible()) ?? [];
	const columnsWidth = columns.reduce((accumulator, column) => {
		return accumulator + column.getActualWidth();
	}, 0);

	return columnsWidth + scrollbarWidth;
};

export const sizeColumnsToFit = <TData extends IBaseTData>({
	gridApi,
	container,
	fitContent = false
}: {
	gridApi?: GridApi<TData>;
	container: HTMLElement | null;
	fitContent?: boolean;
}) => {
	executeGridAPICall(gridApi, (api) =>
		setTimeout(() => {
			if (fitContent) {
				api.autoSizeAllColumns();
			}

			if (!container) {
				return;
			}

			const containerWidth = container.offsetWidth;

			// Get current renderered columns width
			let columnsWidth = getTableWidth(container, api);

			// Check if the rendered columns width is higher than the grid's container width
			if (columnsWidth >= containerWidth) {
				return;
			}

			// sets columns to adjust in size to fit the grid horizontally.
			api.sizeColumnsToFit();

			// Re-calculate renderered columns width
			columnsWidth = getTableWidth(container, api);

			// Check if the new columns width is higher than the grid's container width
			if (columnsWidth >= containerWidth) {
				return;
			}

			// Get last resizable column to fill the gap
			const columns = api.getColumns()?.filter((column) => column.isVisible()) ?? [];
			let lastColumn: Column | undefined;
			for (let index = columns.length - 1; index >= 0; index--) {
				const column = columns[index];
				const definition = column.getColDef();

				const isPinnedRight = definition.pinned === 'right';

				if (!isPinnedRight) {
					lastColumn = column;
					break;
				}
			}

			if (!lastColumn) {
				return;
			}

			const columnsGap = containerWidth - columnsWidth;
			api.setColumnWidth(lastColumn.getColId(), lastColumn.getActualWidth() + columnsGap);
		}, 100)
	);
};

export const executeGridAPICall = <TData extends IBaseTData>(
	api: GridApi<TData> | undefined,
	callback: (api: GridApi<TData>) => void
): void => {
	setTimeout(() => {
		if (!api || api.isDestroyed()) return;
		callback(api);
	});
};

export const refreshMasterProperty = <TData extends IBaseTData>(
	api: GridApi<TData> | undefined,
	isRowMaster: ((data: TData) => boolean) | undefined
): void => {
	executeGridAPICall(api, (gridApi) => {
		if (!isRowMaster) {
			return [];
		}

		const nodes = gridApi.getRenderedNodes();
		const changedNodes: IRowNode[] = [];
		nodes.forEach((node) => {
			if (node.stub || !node.data) {
				return;
			}

			const isNodeMaster = isRowMaster(node.data);
			if (node.master !== isNodeMaster) {
				changedNodes.push(node);
				node.master = isNodeMaster;

				if (!isNodeMaster) {
					node.setExpanded(false);
				}
			}
		});

		if (changedNodes.length) {
			gridApi.refreshCells({ rowNodes: changedNodes });
		}
	});
};
