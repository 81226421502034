import { Asset } from '@kelvininc/node-client-sdk';
import { KvSingleSelectDropdown } from '@kelvininc/react-ui-components';
import { Skeleton, useDropdownSearchOptions } from '@kelvininc/shared-ui';

import { ComponentProps, useMemo } from 'react';

import {
	DEFAULT_SELECT_ASSET_LABEL,
	DEFAULT_SELECT_ASSET_NO_DATA_AVAILABLE_CONFIG,
	DEFAULT_SELECT_ASSET_PLACEHOLDER,
	DEFAULT_SELECT_ASSET_SEARCH_PLACEHOLDER,
	USE_DROPDOWN_OPTIONS
} from './config';

import styles from './styles.module.scss';

type AssetSelectorProps = {
	value?: string;
	assets?: Asset[];
	onValueChange?: (asset: string) => void;
} & Omit<
	ComponentProps<typeof KvSingleSelectDropdown>,
	| 'selectedOption'
	| 'options'
	| 'filteredOptions'
	| 'selectedOption'
	| 'onOptionSelected'
	| 'onSearchChange'
>;

export const AssetSelectorSkeleton = () => {
	return <Skeleton className={styles.Skeleton} />;
};

export const AssetSelector = ({
	label = DEFAULT_SELECT_ASSET_LABEL,
	placeholder = DEFAULT_SELECT_ASSET_PLACEHOLDER,
	searchPlaceholder = DEFAULT_SELECT_ASSET_SEARCH_PLACEHOLDER,
	noDataAvailableConfig = DEFAULT_SELECT_ASSET_NO_DATA_AVAILABLE_CONFIG,
	value,
	assets,
	onValueChange,
	...otherProps
}: AssetSelectorProps) => {
	const data = useMemo(() => assets ?? [], [assets]);

	const {
		setSearchTerm: setAssetSearchTerm,
		filteredOptions: assetFilteredOptions,
		options: assetOptions
	} = useDropdownSearchOptions(data, USE_DROPDOWN_OPTIONS);

	return (
		<KvSingleSelectDropdown
			label={label}
			placeholder={placeholder}
			selectedOption={value}
			options={assetOptions}
			filteredOptions={assetFilteredOptions}
			onOptionSelected={({ detail: newAsset }) => onValueChange?.(newAsset)}
			onSearchChange={({ detail: newTerm }) => setAssetSearchTerm(newTerm)}
			noDataAvailableConfig={noDataAvailableConfig}
			searchPlaceholder={searchPlaceholder}
			{...otherProps}
		/>
	);
};
