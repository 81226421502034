import { EToasterType } from '@kelvininc/react-ui-components';

import { Link } from '../Link';

import styles from './styles.module.scss';

export const buildTipDescription = (documentationLink: string) => {
	return (
		<span>
			In order to upload a valid file, please consult the{' '}
			<Link className={styles.DocumentationLink} href={documentationLink} target="_blank">
				documentation
			</Link>{' '}
			to help you build a valid structure, or use the template available below.
		</span>
	);
};

export const buildFileTypeErrorToaster = (types?: string[]) => ({
	header: 'File type not supported',
	description: `Please revie w your file.${types ? ` Supported file extension: ${types.join(', ')}` : ''}`,
	type: EToasterType.Error,
	ttl: 0,
	closable: true
});

export const buildFileSizeErrorToaster = (maxSize?: number) => ({
	header: 'File size not supported',
	description: `Please review your file.${maxSize ? ` Files allowed, with size up to ${maxSize}MB` : ''}`,
	type: EToasterType.Error,
	ttl: 0,
	closable: true
});

const formatSize = (sizeInMb: number): string => {
	// Check if the size is less than 1GB
	if (sizeInMb < 1024) {
		return `${sizeInMb}MB`;
	}

	return `${sizeInMb / 1024}GB`;
};

export const getFileDescription = (types?: string[], maxSize?: number): string => {
	const items: string[] = [];
	if (types?.length) {
		items.push(`${types.map((type) => `.${type}`).join(', ')} files allowed`);
	}

	if (maxSize) {
		items.push(`maximum file size of ${formatSize(maxSize)}`);
	}

	return items.join(', with ');
};
