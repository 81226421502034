import { NAVIGATION_COLLAPSED_WIDTH } from '@kelvininc/shared-ui';
import { RefObject, useEffect, useRef } from 'react';

export const useLayoutResizer = (fixed: boolean, width: number): RefObject<HTMLDivElement> => {
	const layoutRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (layoutRef == null || layoutRef.current == null) return;

		layoutRef.current.style.marginLeft = `${fixed ? width : NAVIGATION_COLLAPSED_WIDTH}px`;
	}, [fixed, width]);

	return layoutRef;
};
