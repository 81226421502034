import { useCallback, useEffect, useState } from 'react';

import {
	DASBHBOARD_TRANSITION,
	DASHBOARD_BACKGROUND_COLOR,
	DASHBOARD_PADDING
} from '../../../config';

export const useDashboardStyleUpdate = (url: string) => {
	const [areStylesApplied, setAreStylesApplied] = useState(false);

	const applyDashboardStyles = useCallback(() => {
		const iframeDocument = document.querySelector('iframe')?.contentWindow?.document;
		const iframeElement = iframeDocument?.querySelector(
			'.scrollbar-view > div'
		) as HTMLDivElement;

		const iframeBodyElement = iframeDocument?.querySelector('body');

		if (iframeElement && iframeBodyElement) {
			iframeElement.style.padding = DASHBOARD_PADDING;
			iframeElement.style.transition = DASBHBOARD_TRANSITION;
			iframeBodyElement.style.background = DASHBOARD_BACKGROUND_COLOR;

			setAreStylesApplied(true);
		}
	}, []);

	useEffect(() => {
		if (areStylesApplied) return;

		const timerId = setInterval(() => {
			if (!areStylesApplied) {
				applyDashboardStyles();
			}
		}, 250);

		return () => {
			clearInterval(timerId);
		};
	}, [applyDashboardStyles, areStylesApplied]);

	useEffect(() => {
		setAreStylesApplied(false);
	}, [url]);
};
