'use client';
import { EIconName, EOtherIconName, KvIcon } from '@kelvininc/react-ui-components';
import { Link } from '@kelvininc/shared-ui';
import classNames from 'classnames';
import { ComponentProps } from 'react';

import styles from './styles.module.scss';

export type HyperlinkProps = {
	text?: string;
	icon?: EIconName | EOtherIconName;
};

export const Hyperlink = ({
	text,
	icon,
	...otherProps
}: HyperlinkProps & ComponentProps<typeof Link>) => {
	return (
		<Link {...otherProps} className={styles.Link}>
			<div
				className={classNames(styles.HyperlinkContent, {
					[styles.HasIcon]: !!icon,
					[styles.Disabled]: otherProps.disabled
				})}>
				{icon && <KvIcon name={icon} />}
				{text && <span className={styles.Text}>{text}</span>}
			</div>
		</Link>
	);
};
