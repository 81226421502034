import { useDebounce } from '@kelvininc/shared-ui';
import classNames from 'classnames';
import { omit } from 'lodash-es';

import {
	ETableAdvancedFilterType,
	IApplicationParameterTableAdvancedFilter
} from '../../../../../../../../../core/components/AgGridTable/types';
import {
	DraftAdvancedFilter,
	IDrafAdvancedFilterMap
} from '../../../../../../../../../core/components/Table/contexts/TableAdvancedFiltersContext/types';
import { IApplicationParameterAdvancedFilterConfigMetadata } from '../../../../../../../../../core/components/Table/types';
import { EXISTENCE_OPERATORS_OPERATORS } from '../../../../../../../../../utils';
import { AdvancedFilterForm } from '../AdvancedFilterForm';
import { AdvancedFiltersForm } from '../AdvancedFiltersForm';

import { ApplicationParameterAdvancedFilterSchemaFormData } from '../ApplicationParameterFiltersForm/types';

import { ApplicationDropdown, ApplicationParameterForm } from './components';
import { APPLICATION_PARAMETER_SCHEMA_FORM_PROPERTIES } from './config';
import styles from './styles.module.scss';

import { getSchemaFormData } from './utils';

type ApplicationParameterFiltersFormProps = {
	isFormEmpty: boolean;
	metadata: IApplicationParameterAdvancedFilterConfigMetadata;
	filters: DraftAdvancedFilter<IApplicationParameterTableAdvancedFilter>[];
	createFilter: (type: ETableAdvancedFilterType) => void;
	updateFilter: (
		type: ETableAdvancedFilterType,
		index: number,
		newFilter: IDrafAdvancedFilterMap[typeof type]
	) => void;
	deleteFilter: (type: ETableAdvancedFilterType, index: number) => void;
	duplicateFilter: (type: ETableAdvancedFilterType, index: number) => void;
};

export const ApplicationParameterFiltersForm = ({
	isFormEmpty,
	metadata: { applications, application, closedLoopSettings },
	filters,
	createFilter,
	updateFilter,
	deleteFilter,
	duplicateFilter
}: ApplicationParameterFiltersFormProps) => {
	const debouncedUpdateFilter = useDebounce(updateFilter);

	const onChange = (
		index: number,
		newFilter: DraftAdvancedFilter<IApplicationParameterTableAdvancedFilter>,
		update = updateFilter
	): void => update(ETableAdvancedFilterType.ApplicationParameter, index, newFilter);

	const onChangeApplication = (
		index: number,
		newApplication: string,
		filter: DraftAdvancedFilter<IApplicationParameterTableAdvancedFilter>
	): void =>
		onChange(index, {
			...omit(filter, APPLICATION_PARAMETER_SCHEMA_FORM_PROPERTIES),
			application: newApplication,
			parameter: undefined
		});

	const onChangeParameter = (
		index: number,
		newParameter: string,
		filter: DraftAdvancedFilter<IApplicationParameterTableAdvancedFilter>
	): void => {
		onChange(index, {
			...omit(filter, APPLICATION_PARAMETER_SCHEMA_FORM_PROPERTIES),
			application: filter.application ?? application,
			parameter: newParameter
		});
	};

	const onChangeForm = (
		index: number,
		form: ApplicationParameterAdvancedFilterSchemaFormData,
		filter: DraftAdvancedFilter<IApplicationParameterTableAdvancedFilter>
	): void => {
		const operator = form?.operator ?? '';
		const value = EXISTENCE_OPERATORS_OPERATORS.includes(operator) ? undefined : form?.value;

		onChange(
			index,
			{
				...omit(filter, APPLICATION_PARAMETER_SCHEMA_FORM_PROPERTIES),
				application: filter.application ?? application,
				operator,
				value
			},
			debouncedUpdateFilter
		);
	};

	return (
		<AdvancedFiltersForm
			onAdd={() => createFilter(ETableAdvancedFilterType.ApplicationParameter)}
			customClasses={classNames(styles.ApplicationParameterFiltersForm, {
				[styles.PreselectedApplication]: !!application
			})}>
			{filters.map((filter, index) => (
				<AdvancedFilterForm
					key={filter.id}
					index={index}
					onDuplicate={() =>
						duplicateFilter(ETableAdvancedFilterType.ApplicationParameter, index)
					}
					onDelete={() =>
						deleteFilter(ETableAdvancedFilterType.ApplicationParameter, index)
					}
					filters={filters}
					isFormEmpty={isFormEmpty}>
					{!application && (
						<ApplicationDropdown
							application={filter.application}
							parameters={applications.parameters}
							definitions={applications.definitions}
							filter={filter}
							onChangeApplication={(newApplication) =>
								onChangeApplication(index, newApplication, filter)
							}
						/>
					)}
					<ApplicationParameterForm
						application={filter.application ?? application}
						parameter={filter.parameter}
						formData={getSchemaFormData(filter)}
						parameters={applications.parameters}
						values={applications.values}
						closedLoopSettings={closedLoopSettings}
						onChangeForm={(formData) => onChangeForm(index, formData, filter)}
						onChangeParameter={(newParameter) =>
							onChangeParameter(index, newParameter, filter)
						}
					/>
				</AdvancedFilterForm>
			))}
		</AdvancedFiltersForm>
	);
};
