import { EIconName, EOtherIconName } from '@kelvininc/react-ui-components';

import { IBaseTData, IColumnDef } from '../../../../../AgGridTable';

export interface IColumnsStatistic {
	id: string;
	icon: EIconName | EOtherIconName;
	text: string;
}

export enum EColumnsStatisticKey {
	Total = 'total',
	Frozen = 'frozen',
	Locked = 'locked',
	Active = 'active',
	Hidden = 'hidden'
}

export interface IIndexedColumnDef<TData extends IBaseTData> extends IColumnDef<TData> {
	index: number;
}
