import {
	EActionButtonType,
	EIconName,
	KvActionButtonText,
	KvTextField
} from '@kelvininc/react-ui-components';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { closeModal } from '../Modal';

import { DEFAULT_CONFIRMATION_TEXT, INPUT_PLACEHOLDER } from './config';
import styles from './styles.module.scss';
import { IDeleteEntityModalProps } from './types';

export const DeleteEntityModal = ({
	title,
	description,
	confirmationText = DEFAULT_CONFIRMATION_TEXT,
	onClickDelete
}: PropsWithChildren<IDeleteEntityModalProps>) => {
	const [isValid, setValid] = useState(!confirmationText);

	const onInputTextChange = useCallback(
		({ detail }: CustomEvent<string>) => setValid(detail === confirmationText),
		[confirmationText]
	);

	const isDeleteDisable = useMemo(() => !isValid, [isValid]);

	return (
		<>
			<div className={styles.DeleteEntityModalContent}>
				{title && <div className={styles.Title}>{title}</div>}
				{description && <div className={styles.Description}>{description}</div>}
				{confirmationText && (
					<>
						<div className={styles.Description}>
							To confirm deletion, type <u>{confirmationText}</u> in the field below.
						</div>

						<KvTextField
							onTextChange={onInputTextChange}
							placeholder={INPUT_PLACEHOLDER}
						/>
					</>
				)}
			</div>
			<div data-test-id="e2e-modal-actions" className={styles.DeleteEntityModalFooter}>
				<div className={styles.RightContainer}>
					<KvActionButtonText
						key="cancel"
						text="Cancel"
						type={EActionButtonType.Tertiary}
						onClickButton={() => closeModal()}
					/>
					<KvActionButtonText
						key="delete"
						text="Delete"
						disabled={isDeleteDisable}
						icon={EIconName.Delete}
						type={EActionButtonType.Danger}
						onClickButton={onClickDelete}
					/>
				</div>
			</div>
		</>
	);
};
