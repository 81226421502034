'use client';
import { EAlertType, EToasterType, IToaster } from '@kelvininc/react-ui-components';
import { IModalAlert } from '@kelvininc/shared-ui';

export const DEPLOY_KELVIN_APP_CONFIRMATION_ALERT: IModalAlert = {
	label: '',
	description: '',
	type: EAlertType.Warning
};

export const EDIT_SUCCESS_TOASTER_CONFIG: IToaster = {
	header: `The workload was deployed successfully!`,
	type: EToasterType.Success
};

export const DEPLOY_SUCCESS_TOASTER_CONFIG: IToaster = {
	header: `A new deploy was submitted successfully!`,
	type: EToasterType.Success
};
