import { EIllustrationName } from '@kelvininc/react-ui-components';

import { BaseOverlay } from '../BaseOverlay';

import {
	ERROR_OVERLAY_DESCRIPTION,
	ERROR_OVERLAY_ILLUSTRATION,
	ERROR_OVERLAY_TITLE
} from './config';

type ErrorOverlayProps = {
	title?: string;
	description?: string;
	illustration?: EIllustrationName;
};

export const ErrorOverlay = ({
	title = ERROR_OVERLAY_TITLE,
	description = ERROR_OVERLAY_DESCRIPTION,
	illustration = ERROR_OVERLAY_ILLUSTRATION
}: ErrorOverlayProps) => {
	return <BaseOverlay title={title} description={description} illustration={illustration} />;
};
