import { EActionButtonType, EAlertType, KvActionButtonText } from '@kelvininc/react-ui-components';
import { IExpandableApi, Panel, useMount, usePanelsManager, useUpdate } from '@kelvininc/shared-ui';
import { isUndefined, merge } from 'lodash-es';
import { Dispatch, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import {
	ECustomColumnType,
	IApplicationParameterColumnData,
	IAssetPropertyColumnData,
	IBaseTData,
	IColumnDef,
	IColumnOptions,
	IDatastreamColumnData,
	ILastControlChangeColumnData,
	IRecommendationColumnData,
	IScheduleApplicationParametersColumnData
} from '../../../../../../../core/components/AgGridTable/types';
import { DEFAULT_MANAGEMENT_TABLE_ALERT } from '../../../../../../../core/components/Table/config';
import { useTableManagementAddColumnContext } from '../../../../../../../core/components/Table/contexts/TableManagementAddColumnContext';
import { useTableManagementStateContext } from '../../../../../../../core/components/Table/contexts/TableManagementStateContext';
import {
	EManagementPanel,
	ICustomColumnsConfig,
	IPanelConfig,
	TableProps
} from '../../../../../../../core/components/Table/types';
import {
	DEFAULT_APPLICATION_PARAMETER_DATA,
	DEFAULT_ASSET_PROPERTY_DATA,
	DEFAULT_DATASTREAM_DATA,
	DEFAULT_LAST_CONTROL_CHANGE_DATA,
	DEFAULT_RECOMMENDATION_DATA,
	DEFAULT_SCHEDULE_APPLICATION_PARAMETERS_DATA
} from '../../config';
import { buildColumnDef, isTitleValid, validateData } from '../../utils';
import { ColumnForm } from '../ColumnForm';

import {
	ADD_COLUMN_ACTION_BUTTON_TEXT,
	CANCEL_ACTION_BUTTON_TEXT,
	COLUMN_TITLE_UNIQUE_ERROR,
	DEFAULT_COLUMN_OPTIONS
} from './config';

import styles from './styles.module.scss';

export type AddColumnPanelProps<TData extends IBaseTData> = {
	setColumnDefs: Dispatch<React.SetStateAction<IColumnDef<TData>[]>>;
	columnsConfig: ICustomColumnsConfig<TData>;
	hasAlert?: boolean;
	panelConfig: IPanelConfig;
} & Pick<TableProps<TData>, 'columnDefs'>;

export const AddColumnPanel = <TData extends IBaseTData>({
	setColumnDefs,
	columnDefs,
	columnsConfig,
	panelConfig
}: AddColumnPanelProps<TData>) => {
	const { push } = usePanelsManager();
	const { closeSidePanel, alert, setAlert } = useTableManagementStateContext();
	const [columnType, setColumnType] = useState<ECustomColumnType>();
	const [columnOptions, setColumnOptions] =
		useState<IColumnOptions<TData>>(DEFAULT_COLUMN_OPTIONS);
	const [columnTitle, setColumnTitle] = useState<string>();
	const [assetPropertyData, setAssetPropertyData] = useState<IAssetPropertyColumnData>(
		DEFAULT_ASSET_PROPERTY_DATA
	);
	const [datastreamData, setDatastreamData] =
		useState<IDatastreamColumnData>(DEFAULT_DATASTREAM_DATA);
	const [lastControlChangeData, setLastControlChangeData] =
		useState<ILastControlChangeColumnData>(DEFAULT_LAST_CONTROL_CHANGE_DATA);
	const [recommendationData, setRecommendationData] = useState<IRecommendationColumnData>({
		...DEFAULT_RECOMMENDATION_DATA,
		application: columnsConfig[ECustomColumnType.Recommendation]?.metadata?.application
	});
	const [applicationParameterData, setApplicationParameterData] =
		useState<IApplicationParameterColumnData>({
			...DEFAULT_APPLICATION_PARAMETER_DATA,
			application:
				columnsConfig[ECustomColumnType.ApplicationParameter]?.metadata?.application
		});
	const [scheduleApplicationParametersData, setScheduleApplicationParametersData] =
		useState<IScheduleApplicationParametersColumnData>(
			DEFAULT_SCHEDULE_APPLICATION_PARAMETERS_DATA
		);

	const settingsExpandableRef = useRef<IExpandableApi>(null);
	const optionsExpandableRef = useRef<IExpandableApi>(null);

	const {
		hasAddColumnChanges: [hasAddColumnChanges, setHasAddColumnChanges]
	} = useTableManagementAddColumnContext();

	const isColumnTitleValid = useMemo(
		() => isTitleValid(columnTitle, columnDefs),
		[columnTitle, columnDefs]
	);
	const titleUniqueError = useMemo(
		() => (isColumnTitleValid ? '' : COLUMN_TITLE_UNIQUE_ERROR),
		[isColumnTitleValid]
	);

	const onAddColumn = useCallback(
		(newColumnDef: IColumnDef<TData>) => {
			// TODO-REACT18:  Remove this after upgrade to React 18.
			// On this version all react state updates performed
			// inside a callback are automatically batched.
			unstable_batchedUpdates(() => {
				setColumnDefs((previousColumns) => [...previousColumns, newColumnDef]);
				push(EManagementPanel.Manage);
			});
		},
		[push, setColumnDefs]
	);

	const onClickSubmit = useCallback(() => {
		if (!columnType) {
			return;
		}

		const newColumnDef = buildColumnDef(
			columnType,
			columnDefs,
			columnsConfig,
			columnTitle,
			columnOptions,
			assetPropertyData,
			datastreamData,
			lastControlChangeData,
			recommendationData,
			applicationParameterData,
			scheduleApplicationParametersData
		);

		if (!newColumnDef) {
			return;
		}

		onAddColumn(newColumnDef);
	}, [
		columnType,
		columnTitle,
		columnDefs,
		columnsConfig,
		columnOptions,
		assetPropertyData,
		datastreamData,
		lastControlChangeData,
		recommendationData,
		applicationParameterData,
		scheduleApplicationParametersData,
		onAddColumn
	]);

	const onRequestClose = useCallback(() => {
		if (hasAddColumnChanges) {
			setAlert(
				merge({}, DEFAULT_MANAGEMENT_TABLE_ALERT[EAlertType.Info], {
					actionsConfig: {
						onClickConfirm: () => {
							setAlert(undefined);
							closeSidePanel();
						},
						onClickCancel: () => setAlert(undefined)
					}
				})
			);
		} else {
			setAlert(undefined);
			closeSidePanel();
		}
	}, [setAlert, hasAddColumnChanges, closeSidePanel]);

	const onClickBack = useCallback(() => {
		if (hasAddColumnChanges) {
			setAlert(
				merge({}, DEFAULT_MANAGEMENT_TABLE_ALERT[EAlertType.Info], {
					actionsConfig: {
						onClickConfirm: () => {
							setAlert(undefined);
							push(EManagementPanel.Manage);
						},
						onClickCancel: () => setAlert(undefined)
					}
				})
			);
		} else {
			setAlert(undefined);
			push(EManagementPanel.Manage);
		}
	}, [hasAddColumnChanges, setAlert, push]);

	useEffect(() => {
		if (columnType !== undefined) {
			settingsExpandableRef.current?.open();
			optionsExpandableRef.current?.open();
		}
	}, [columnType]);

	useUpdate(() => {
		setColumnTitle(undefined);
	}, [columnType]);

	const isDataValid = useMemo(
		() =>
			isColumnTitleValid &&
			validateData(
				columnType,
				columnsConfig,
				assetPropertyData,
				datastreamData,
				lastControlChangeData,
				recommendationData,
				applicationParameterData,
				scheduleApplicationParametersData
			),
		[
			assetPropertyData,
			columnType,
			columnsConfig,
			isColumnTitleValid,
			lastControlChangeData,
			datastreamData,
			recommendationData,
			applicationParameterData,
			scheduleApplicationParametersData
		]
	);

	useMount(() => {
		setHasAddColumnChanges(false);
	});

	return (
		<Panel {...panelConfig} onRequestClose={onRequestClose} onBack={onClickBack} alert={alert}>
			<div>
				<ColumnForm
					hasAlert={!isUndefined(alert)}
					columnTypeState={[columnType, setColumnType]}
					columnOptionsState={[columnOptions, setColumnOptions]}
					columnTitleState={[columnTitle, setColumnTitle]}
					assetPropertyDataState={[assetPropertyData, setAssetPropertyData]}
					datastreamDataState={[datastreamData, setDatastreamData]}
					lastControlChangeDataState={[lastControlChangeData, setLastControlChangeData]}
					recommendationDataState={[recommendationData, setRecommendationData]}
					applicationParameterDataState={[
						applicationParameterData,
						setApplicationParameterData
					]}
					scheduleApplicationParametersDataState={[
						scheduleApplicationParametersData,
						setScheduleApplicationParametersData
					]}
					columnDefs={columnDefs}
					config={columnsConfig}
					titleError={titleUniqueError}
				/>
				<div
					data-test-id="e2e-manage-table-columns-add-columns-actions"
					className={styles.Actions}>
					<KvActionButtonText
						text={ADD_COLUMN_ACTION_BUTTON_TEXT}
						type={EActionButtonType.Primary}
						disabled={!isDataValid}
						onClickButton={onClickSubmit}
					/>
					<KvActionButtonText
						text={CANCEL_ACTION_BUTTON_TEXT}
						type={EActionButtonType.Tertiary}
						onClickButton={onClickBack}
					/>
				</div>
			</div>
		</Panel>
	);
};
