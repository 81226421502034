'use client';
import { ITabNavigationItem, KvTabNavigation } from '@kelvininc/react-ui-components';
import { Skeleton } from '@kelvininc/shared-ui';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { PropsWithChildren, ReactElement, useMemo } from 'react';

import styles from './styles.module.scss';

export type TabsNavigationProps = {
	customClasses?: ClassNamesProp;
	tabItems: ITabNavigationItem[];
	selectedTab: string;
	onTabClicked: (tabKey: string) => void;
	children?: ReactElement[];
};

export const TabsNavigationSkeleton = ({
	children,
	itemCount = 2
}: PropsWithChildren<{ itemCount?: number }>) => {
	return (
		<div className={styles.Navigation}>
			<div className={styles.NavigationTabsSkeleton}>
				{Array(itemCount)
					.fill({})
					.map((_item, index) => (
						<Skeleton key={index} width={100} height={24} />
					))}
			</div>
			{children}
		</div>
	);
};

export const TabsNavigation = ({
	customClasses,
	tabItems,
	selectedTab,
	children,
	onTabClicked
}: TabsNavigationProps) => {
	const filteredChildren = useMemo(
		() => children?.filter((child) => child.key === selectedTab),
		[children, selectedTab]
	);

	const onTabClickedEvent = ({ detail }: CustomEvent<string>) => {
		onTabClicked(detail);
	};

	return (
		<div className={classNames(styles.Navigation, customClasses)}>
			<KvTabNavigation
				tabs={tabItems}
				selectedTabKey={selectedTab}
				onTabChange={onTabClickedEvent}
			/>
			{filteredChildren}
		</div>
	);
};
