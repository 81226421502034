import { AppItem } from '@kelvininc/node-client-sdk';
import { isBridgeApp, isKelvinApp } from '@kelvininc/tsutils';
import { keyBy } from 'lodash-es';
import { selector } from 'recoil';

import { appRegistryAppsListAtom } from './atoms';

export const appRegistryAppsMapSelector = selector<Record<string, AppItem>>({
	key: 'appRegistryAppsMapSelector',
	get: ({ get }) => keyBy(get(appRegistryAppsListAtom), 'name')
});

export const kelvinApplicationsMapSelector = selector<Record<string, AppItem>>({
	key: 'kelvinApplicationsMapSelector',
	get: ({ get }) => keyBy(get(kelvinApplicationsListSelector), 'name')
});

export const kelvinApplicationsListSelector = selector<AppItem[]>({
	key: 'kelvinApplicationsListSelector',
	get: ({ get }) => get(appRegistryAppsListAtom).filter(isKelvinApp)
});

export const bridgeApplicationsMapSelector = selector<Record<string, AppItem>>({
	key: 'bridgeApplicationsMapSelector',
	get: ({ get }) => keyBy(get(bridgeApplicationsListSelector), 'name')
});

export const bridgeApplicationsListSelector = selector<AppItem[]>({
	key: 'bridgeApplicationsListSelector',
	get: ({ get }) => get(appRegistryAppsListAtom).filter(isBridgeApp)
});
