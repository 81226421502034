import { PropsWithChildren } from 'react';

import { TableManagementAddColumnProvider } from '../../contexts/TableManagementAddColumnContext';
import { TableManagementEditColumnProvider } from '../../contexts/TableManagementEditColumnContext';
import { TableManagementStateProvider } from '../../contexts/TableManagementStateContext';

type TableManagementProvidersProps = {
	id: string;
};

export const TableManagementProviders = ({
	id,
	children
}: PropsWithChildren<TableManagementProvidersProps>) => {
	return (
		<TableManagementStateProvider id={id}>
			<TableManagementEditColumnProvider>
				<TableManagementAddColumnProvider>{children}</TableManagementAddColumnProvider>
			</TableManagementEditColumnProvider>
		</TableManagementStateProvider>
	);
};
