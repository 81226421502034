import { get } from 'lodash-es';

import { IBaseTData, ICellRendererParams } from '../../../core/components/AgGridTable/types';

import { TooltipErrorTag } from './components';
import styles from './styles.module.scss';

export type SchemaFormErrorCellParams = {
	cellContentAccessor: string;
	cellErrorAccessor: string;
};

export const SchemaFormErrorCellRenderer = <TData extends IBaseTData>({
	data,
	cellContentAccessor,
	cellErrorAccessor
}: SchemaFormErrorCellParams & ICellRendererParams<TData>) => {
	const item = get(data, ['item', ...cellContentAccessor.split('.')], '');

	const error = get(data, ['errors', cellErrorAccessor], '');

	return (
		<div className={styles.ErrorCellContainer}>
			{error && <TooltipErrorTag message={error} />}
			{item.toString()}
		</div>
	);
};
