import { EValidationState, KvFormHelpText } from '@kelvininc/react-ui-components';
import { SuspenseFallback, UploadCSVFile, withSuspense } from '@kelvininc/shared-ui';

import { useDocsLink, useImportGuardrailsWizardFormContext } from '../../hooks';

import styles from './styles.module.scss';
import { buildTemplateFile, openReplacingModalAlert } from './utils';

const Component = () => {
	const {
		watch,
		setValue,
		formState: { errors },
		clearErrors
	} = useImportGuardrailsWizardFormContext();
	const link = useDocsLink();

	const [file, connection] = watch(['file', 'connection']);
	const error = errors.file?.message;

	const setFile = (newFile?: File) => {
		clearErrors('file');
		setValue('file', newFile);
	};
	const onUpload = (newFile?: File) => {
		if (file && newFile && !!!errors.file) {
			return openReplacingModalAlert(() => setFile(newFile));
		}

		return setFile(newFile);
	};

	return (
		<div className={styles.FileUploadStep}>
			<UploadCSVFile
				file={file}
				templateFile={buildTemplateFile(connection)}
				documentationLink={link}
				onFileChange={onUpload}
			/>
			<div className={styles.Error}>
				{error && <KvFormHelpText helpText={error} state={EValidationState.Invalid} />}
			</div>
		</div>
	);
};

export const FileUploadStep = withSuspense(Component, <SuspenseFallback />);
