import { ComputePositionConfig, flip } from '@floating-ui/dom';

import { floatingUIHelper } from '@kelvininc/react-ui-components';

import { TOOLTIP_POSITION_CONFIG } from './config';

export const buildTableTooltipPositionConfig = (
	element?: HTMLElement | HTMLKvTooltipElement | null
): Partial<ComputePositionConfig> => {
	const table = element?.closest('#table');
	if (!table) {
		return TOOLTIP_POSITION_CONFIG;
	}

	return floatingUIHelper.mergeComputePositionConfigs(TOOLTIP_POSITION_CONFIG, {
		middleware: [
			flip({
				padding: 16,
				boundary: table
			})
		]
	});
};
