import { EValidationState, ITextField, KvSelectCreateOption } from '@kelvininc/react-ui-components';
import { useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';

type OptionCreateProps = {
	initialValue?: string;
	inputConfig?: Partial<ITextField>;
	getFormState?: (newValue: string) => { error?: string; state?: EValidationState };
	onClickCreateOption?: (e: CustomEvent) => void;
};

export const OptionCreate = ({
	onClickCreateOption,
	getFormState,
	inputConfig,
	initialValue = ''
}: OptionCreateProps) => {
	const [newOption, setNewOption] = useState(initialValue);
	const { state, error } = useMemo(
		() => getFormState?.(newOption) ?? {},
		[getFormState, newOption]
	);

	useEffect(() => {
		setNewOption(initialValue);
	}, [initialValue]);

	return (
		<div className={styles.OptionCreate}>
			<div className={styles.Form}>
				<KvSelectCreateOption
					value={newOption}
					disabled={state === EValidationState.Invalid}
					onValueChanged={({ detail: newTagName }) => setNewOption(newTagName)}
					inputConfig={{
						...inputConfig,
						state,
						helpText: error,
						inputDisabled: false
					}}
					onClickCancel={() => setNewOption(initialValue)}
					onClickCreate={onClickCreateOption}
				/>
			</div>
		</div>
	);
};
