import { useMemo } from 'react';

import { GuardrailCraftWizardFormValues } from '../../types';
import { useGuardrailCraftWizardFormContext } from '../useGuardrailCraftWizardForm';

import { onSubmitForm } from './utils';

export const useOnSubmitForm = (
	submit: (form: GuardrailCraftWizardFormValues) => Promise<void>
): (() => void) => {
	const form = useGuardrailCraftWizardFormContext();

	return useMemo(
		() =>
			form.handleSubmit((values: GuardrailCraftWizardFormValues) =>
				onSubmitForm(values, submit)
			),
		[form, submit]
	);
};
