import { useLogout } from '@kelvininc/nextjs-utils/client';

import { EIconName } from '@kelvininc/react-ui-components';
import { FooterInfoProps, IDropdownItem } from '@kelvininc/shared-ui';

import { useAtomValue } from 'jotai';

import { appSettingsAtom } from '@/src/state';

export const useUserDropdownItems = (): IDropdownItem[] => {
	const appSettings = useAtomValue(appSettingsAtom);
	const logout = useLogout();

	return [
		{
			id: 'account',
			text: 'Account',
			url: appSettings.accountUrl,
			target: '_blank',
			leftIcon: EIconName.SupervisedUser,
			rightIcon: EIconName.ExternalLink
		},
		{
			id: 'sign-out',
			text: 'Sign Out',
			onClick: () => logout(),
			leftIcon: EIconName.Exit
		}
	];
};

export const useFooterInfo = (): FooterInfoProps => {
	const appSettings = useAtomValue(appSettingsAtom);

	return {
		text: 'Learn with Kelvin documentation',
		version: appSettings.versions.core,
		url: appSettings.documentationLink,
		target: '_blank'
	};
};
