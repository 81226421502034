import { EPaginatorType, Guardrail, GuardrailsService } from '@kelvininc/node-client-sdk';
import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { atom } from 'recoil';

export const guardrailsListAtom = atom<Guardrail[]>({
	key: 'guardrailsListAtom',
	effects: [
		({ setSelf }) =>
			setSelf(
				(() =>
					GuardrailsService.listGuardrails({
						guardrailsListData: {},
						paginationType: EPaginatorType.Stream
					})
						.pipe(catchHttpError(throwHttpError))
						.toPromise())()
			)
	]
});
