import { AppItem } from '@kelvininc/node-client-sdk';
import { KvSchemaForm } from '@kelvininc/react-ui-components';
import { IChangeEvent } from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import { DeployWorkloadData, EDeployWorkloadType } from '../../types';
import { WorkloadFormInfo } from '../types';

import { APP_NAME_FORM_ID, FORM_ID_PREFIX, FORM_KEY_SEPARATOR } from './config';
import { useApplicationFormProps } from './hooks/useApplicationFormProps';
import styles from './styles.module.scss';

import { buildAppInfoData, checkFormFields } from './utils';

export type AppInfoData = Pick<WorkloadFormInfo, 'appName' | 'appVersion'>;

export type AppInfoProps = Partial<DeployWorkloadData> & {
	applications: AppItem[];
	onChange: (data: Partial<DeployWorkloadData>, valid: boolean) => void;
	flowType: EDeployWorkloadType;
};

export const AppInfo = ({ applications, onChange, flowType, ...initialData }: AppInfoProps) => {
	const [formData, setFormData] = useState<AppInfoData>(buildAppInfoData(initialData));
	const { formProps, appVersionsLoading } = useApplicationFormProps(
		applications,
		flowType,
		formData.appName
	);

	const onSchemaDataChange = useCallback(
		(data: IChangeEvent<AppInfoData>, id?: string) => {
			const { formData: newFormData = {} as AppInfoData } = data;
			const newState = { ...newFormData };

			if (id === APP_NAME_FORM_ID) {
				newState.appVersion = undefined;
			}

			const { errors } = validator.validateFormData(newState, formProps.schema);

			const hasAllFields = checkFormFields(newState);

			setFormData(newState);
			onChange(newState, isEmpty(errors) && hasAllFields);
		},
		[formProps.schema, onChange]
	);

	// Validate the form state on mount the form when the flow type is Edit or UpdateWorkload and the appVersions are loaded
	useEffect(() => {
		if (
			[EDeployWorkloadType.Edit, EDeployWorkloadType.UpdateWorkload].includes(flowType) &&
			!isEmpty(initialData) &&
			!appVersionsLoading
		) {
			onSchemaDataChange({ formData: initialData } as IChangeEvent<AppInfoData>);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appVersionsLoading]);

	return (
		<div className={styles.AppInfoFormContainer}>
			<KvSchemaForm
				customClass={styles.AppInfoSchemaForm}
				{...formProps}
				showErrorList={false}
				disabled={isEmpty(applications)}
				formData={formData}
				idPrefix={FORM_ID_PREFIX}
				idSeparator={FORM_KEY_SEPARATOR}
				onChange={onSchemaDataChange}
			/>
		</div>
	);
};
