import { useBlockableRouter } from '@kelvininc/nextjs-utils/client';
import { useCallback } from 'react';

export const useNavigate = () => {
	const router = useBlockableRouter();

	return useCallback(
		(href: string, replace?: boolean, scroll?: boolean) => {
			if (replace) {
				return router.replace(href, { scroll });
			}

			return router.push(href, { scroll });
		},
		[router]
	);
};
