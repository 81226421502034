'use client';
import { EStepState } from '@kelvininc/react-ui-components';
import { ICodeTranslatorEditorChangeData } from '@kelvininc/shared-ui';
import { useCallback, useMemo, useState } from 'react';

import { DeployWorkloadData, EDeployWorkloadType, EWorkloadStep } from '../../types';
import { getInitialWizardConfig, getModalConfig, getWorkloadStep } from '../../utils';

import { useApplicationService } from '@/src/services/application-service';

export const useDeployWorkloadWizard = (
	flowType: EDeployWorkloadType,
	initialData?: Partial<DeployWorkloadData>
) => {
	const [data, setData] = useState<Partial<DeployWorkloadData>>(initialData || {});
	const [stepStateHash, setStepStateHash] = useState<
		Partial<Record<EWorkloadStep, EStepState | undefined>>
	>({});
	const [currentStep, setCurrentStep] = useState(0);

	const { isProfessionalEdition } = useApplicationService();
	const wizardConfig = useMemo(
		() =>
			getInitialWizardConfig(
				flowType,
				!!data.staged,
				!!data.instantlyApply,
				isProfessionalEdition
			),
		[flowType, data.staged, data.instantlyApply, isProfessionalEdition]
	);
	const currentWorkloadStep = useMemo(
		() => getWorkloadStep(currentStep, flowType),
		[currentStep, flowType]
	);
	const modalConfig = useMemo(() => getModalConfig(currentWorkloadStep), [currentWorkloadStep]);

	const onStepChange = useCallback((step: number) => setCurrentStep(step), []);
	const onConfigurationChange = useCallback(
		(editorData: Partial<ICodeTranslatorEditorChangeData>) => {
			const hasValidData =
				flowType === EDeployWorkloadType.Edit
					? editorData.valid && editorData.hasChanges
					: editorData.valid;

			setStepStateHash((prevState) => ({
				...prevState,
				[EWorkloadStep.Configuration]: hasValidData ? EStepState.Success : EStepState.Error
			}));
			setData((prevData) => ({
				...prevData,
				payload: editorData.object
			}));
		},
		[flowType]
	);
	const onDataInfoChange = useCallback(
		(step: EWorkloadStep, data: Partial<DeployWorkloadData>, isValid: boolean) => {
			setStepStateHash((prevState) => ({
				...prevState,
				[step]: isValid ? EStepState.Success : EStepState.Error
			}));
			setData((prevData) => ({
				...prevData,
				...data
			}));
		},
		[]
	);

	return {
		wizardConfig,
		modalConfig,
		data,
		stepStateHash,
		currentStep,
		currentWorkloadStep,
		onStepChange,
		onConfigurationChange,
		onDataInfoChange
	};
};
