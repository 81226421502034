import { EApplicationEntity } from './types';

export const checkPlaformReadiness = (existingEntities: Record<EApplicationEntity, boolean>) => {
	return (
		existingEntities[EApplicationEntity.Assets] &&
		existingEntities[EApplicationEntity.Datastreams] &&
		existingEntities[EApplicationEntity.Clusters] &&
		existingEntities[EApplicationEntity.Connections]
	);
};
