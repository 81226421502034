'use client';
import {
	EActionButtonType,
	EComponentSize,
	EIconName,
	KvActionButton,
	KvActionButtonCustomEvent,
	KvActionButtonIcon,
	KvIcon,
	KvPortal,
	KvTooltip
} from '@kelvininc/react-ui-components';
import {
	Scrollable,
	useClickOutsideAlerters,
	useUpdate,
	useWindowSize
} from '@kelvininc/shared-ui';
import classNames from 'classnames';

import { uniqueId } from 'lodash-es';
import {
	Context,
	FunctionComponent,
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState
} from 'react';

import { DEFAULT_ENTITY_DROPDOWN_Z_INDEX, OPTIONS } from './config';
import styles from './styles.module.scss';

import { IEntityDropdownDropdownItem, IEntityFilterDropdownContextValues } from './types';

const EntityFilterDropdownContext: Context<null | IEntityFilterDropdownContextValues> =
	createContext<null | IEntityFilterDropdownContextValues>(null);

type EntityFilterDropdownProps = {
	title?: string;
	items: IEntityDropdownDropdownItem[];
	selectedId?: string;
	onItemSelected: (id: string) => void;
};

type EntityFilterDropdownContentProps = {
	items: IEntityDropdownDropdownItem[];
	selectedId?: string;
	onClickItem: (event: KvActionButtonCustomEvent<MouseEvent>, itemId: string) => void;
	onClickDropdownItemAction: (
		event: KvActionButtonCustomEvent<MouseEvent>,
		callback: () => void
	) => void;
	listRef: React.RefObject<HTMLDivElement>;
};

const EntityFilterDropdownContent = ({
	items,
	selectedId,
	onClickItem,
	onClickDropdownItemAction,
	listRef
}: EntityFilterDropdownContentProps) => {
	return (
		<div className={styles.DropdownMenu} ref={listRef}>
			<div className={styles.DropdownLinks}>
				<Scrollable>
					{items?.map((item) => (
						<KvTooltip
							key={item.id}
							text={item.tooltip}
							position={item.tooltipPosition}>
							<div className={styles.Option}>
								<div className={styles.Action}>
									<KvActionButton
										size={EComponentSize.Large}
										onClickButton={(e) => onClickItem(e, item.id)}
										className={classNames(styles.OptionWrapper, {
											[styles.OptionWrapperSelected]: selectedId === item.id
										})}>
										<div className={styles.OptionDetails}>
											<div
												className={classNames(styles.Title, {
													[styles.TitleSelected]: selectedId === item.id
												})}>
												{item.title}
											</div>
											<div className={styles.Description}>
												{item.description}
											</div>
										</div>
									</KvActionButton>
								</div>
								<KvActionButton
									className={classNames(styles.ActionButtonList, {
										[styles.OptionWrapperSelected]: selectedId === item.id
									})}>
									{item.actions?.map((action, index) => (
										<KvTooltip
											key={index}
											text={action.tooltip}
											position={action.tooltipPosition}>
											<div className={styles.ActionButton}>
												<KvActionButtonIcon
													icon={action.icon}
													type={EActionButtonType.Ghost}
													disabled={action.disabled}
													onClickButton={(e) =>
														onClickDropdownItemAction(e, action.onClick)
													}
												/>
											</div>
										</KvTooltip>
									))}
								</KvActionButton>
							</div>
						</KvTooltip>
					))}
				</Scrollable>
			</div>
		</div>
	);
};

export const EntityFilterDropdown = ({
	title,
	items,
	selectedId,
	onItemSelected
}: EntityFilterDropdownProps) => {
	const portalId = useMemo(() => `entity-filter-dropdown-${uniqueId()}`, []);
	const actionChildrenRef = useRef<HTMLDivElement>(null);
	const listRef = useRef<HTMLDivElement>(null);

	const optionsRef = useRef<HTMLDivElement>(null);
	const { isOpen, close, toggle } = useEntityFilterDropdownProvider();
	const itemTitle = useMemo(
		() => items.find((item) => item.id === selectedId)?.id,
		[items, selectedId]
	);
	const windowSize = useWindowSize();
	const dropdownClickItem = isOpen ? EIconName.ArrowDropUp : EIconName.ArrowDropDown;
	const itemsCount = items.length;

	useUpdate(() => {
		close();
	}, [windowSize]);

	useClickOutsideAlerters([optionsRef, listRef], () => {
		close();
	});

	const onClickItem = useCallback(
		(event: KvActionButtonCustomEvent<MouseEvent>, itemId: string) => {
			event.stopPropagation();

			onItemSelected(itemId);
			close();
		},
		[close, onItemSelected]
	);

	const onClickDropdownItemAction = useCallback(
		(event: KvActionButtonCustomEvent<MouseEvent>, callback: () => void) => {
			event.stopPropagation();
			callback();
			close();
		},
		[close]
	);

	return (
		<div className={styles.HeaderFilterContainer}>
			{title && (
				<>
					<div className={styles.Title}>{title}</div>
					<div className={styles.DropdownSeparator}>|</div>
				</>
			)}
			<div className={styles.Options}>
				<div className={styles.FilterOption} ref={optionsRef}>
					<div
						className={classNames(styles.SelectedItemWrapper, {
							[styles.DropdownOpen]: isOpen,
							[styles.DropdownDisabled]: itemsCount <= 1
						})}
						onClick={toggle}>
						{itemTitle && <div className={styles.SelectedItem}>{itemTitle}</div>}
						{itemsCount > 1 && (
							<KvIcon name={dropdownClickItem} customClass="icon-24" />
						)}
					</div>
				</div>
				<div>
					<KvPortal
						portalId={portalId}
						animated
						show={isOpen}
						reference={actionChildrenRef?.current ?? optionsRef?.current ?? undefined}
						options={OPTIONS}
						zIndex={DEFAULT_ENTITY_DROPDOWN_Z_INDEX}>
						<EntityFilterDropdownContent
							listRef={listRef}
							items={items}
							selectedId={selectedId}
							onClickDropdownItemAction={onClickDropdownItemAction}
							onClickItem={onClickItem}
						/>
					</KvPortal>
				</div>
			</div>
		</div>
	);
};

export function EntityFilterDropdownProvider<T>({ children }: PropsWithChildren<T>) {
	const [isOpen, setOpen] = useState(false);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);
	const toggle = () => (isOpen ? close() : open());

	return (
		<EntityFilterDropdownContext.Provider value={{ isOpen, toggle, open, close, setOpen }}>
			{children}
		</EntityFilterDropdownContext.Provider>
	);
}

export function useEntityFilterDropdownProvider(): IEntityFilterDropdownContextValues {
	const context = useContext(EntityFilterDropdownContext);

	if (!context) {
		throw new Error('Missing entity filter dropdown context');
	}

	return context;
}

export const withEntityFilterDropdownProvider = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>
) => {
	return function EntityFilterDropdownProviderWrapper(
		componentProps: PropsWithChildren<ComponentProps>
	) {
		return (
			<EntityFilterDropdownProvider>
				<Component {...componentProps} />
			</EntityFilterDropdownProvider>
		);
	};
};
