import { useMatch } from '@kelvininc/nextjs-utils/client';
import { EIconName } from '@kelvininc/react-ui-components';
import { IExpandableMenuItem } from '@kelvininc/shared-ui';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { useApplicationService } from '@/src/services/application-service';
import { appSettingsAtom } from '@/src/state';
import { buildDashboardRoute, buildRolesAndPermissionsRoute } from '@/src/utils/routes';
import {
	buildUserManagementGroupsRoute,
	buildUserManagementRoute
} from '@/src/utils/routes/administration-routes/user-management-routes';

export const useAdminExpandableItems = (): IExpandableMenuItem[] => {
	const { isProfessionalEdition } = useApplicationService();
	const { auditLogsLink } = useAtomValue(appSettingsAtom);

	const administrationDashboardUrl = buildDashboardRoute();
	const administrationDashboardMatch = useMatch(`${administrationDashboardUrl}`);

	const userManagementUrl = buildUserManagementRoute();
	const userManagementGroupsUrl = buildUserManagementGroupsRoute();
	const userManagementUrlMatch = useMatch(`${userManagementUrl}/*`);

	const rolesPermissionsUrl = buildRolesAndPermissionsRoute();
	const rolesPermissionsUrlMatch = useMatch(`${rolesPermissionsUrl}/*`);

	return useMemo(
		() => [
			{
				id: 'dashboard',
				text: 'Dashboard',
				url: administrationDashboardUrl,
				active: !!administrationDashboardMatch,
				leftIcon: EIconName.Dashboard,
				prefetch: false
			},
			{
				id: 'user-management',
				text: 'User Management',
				url: userManagementGroupsUrl,
				active: !!userManagementUrlMatch,
				leftIcon: EIconName.Users,
				prefetch: false
			},
			{
				id: 'roles-permissions',
				text: 'Roles & Permissions',
				url: rolesPermissionsUrl,
				active: !!rolesPermissionsUrlMatch,
				leftIcon: EIconName.Role,
				prefetch: false
			},
			{
				id: 'audit-logs',
				text: 'Audit Logs',
				url: !isProfessionalEdition ? auditLogsLink : '',
				target: '_blank',
				leftIcon: EIconName.Log,
				rightIcon: EIconName.ExternalLink,
				disabled: isProfessionalEdition
			}
		],
		[
			auditLogsLink,
			isProfessionalEdition,
			rolesPermissionsUrl,
			rolesPermissionsUrlMatch,
			userManagementGroupsUrl,
			userManagementUrlMatch,
			administrationDashboardMatch,
			administrationDashboardUrl
		]
	);
};
