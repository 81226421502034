import { EDataState } from '@kelvininc/shared-ui';
import { FunctionComponent, ReactState } from '@kelvininc/types';
import { isNil } from 'lodash-es';
import { Context, PropsWithChildren, createContext, useContext, useState } from 'react';

import { ITableStateContextValues } from './types';

const TableStateContext: Context<null | ITableStateContextValues> =
	createContext<null | ITableStateContextValues>(null);

export const TableStateProvider = ({ children }: PropsWithChildren<{}>) => {
	const state = useState<EDataState>();

	return <TableStateContext.Provider value={{ state }}>{children}</TableStateContext.Provider>;
};

export const useStateContext = (): ITableStateContextValues => {
	const context = useContext(TableStateContext);

	if (!context) {
		throw new Error('Missing table state context');
	}

	return context;
};

export const useTableState = (): ReactState<EDataState | undefined> => {
	const { state } = useStateContext();

	return state;
};

export const useIsStateEmptyOrError = (): boolean => {
	const [state] = useTableState();

	return state === EDataState.IsEmpty || state === EDataState.HasError;
};

export const useIsStateOnInitResults = (): boolean => {
	const [state] = useTableState();

	return isNil(state) || state === EDataState.OnInit;
};

export const useIsStateHasResults = (): boolean => {
	const [state] = useTableState();

	return state === EDataState.HasResults;
};

export const useIsStateNotHasResults = (): boolean => {
	return !useIsStateHasResults();
};

export const useIsStateLoading = (): boolean => {
	const [state] = useTableState();

	return state === EDataState.IsLoading;
};

export const withStateProvider = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>
) => {
	return function TableStateProviderWrapper(componentProps: PropsWithChildren<ComponentProps>) {
		return (
			<TableStateProvider>
				<Component {...componentProps} />
			</TableStateProvider>
		);
	};
};
