import { useCallback, useState } from 'react';

import { UseExecuteGuardrailOperation } from './types';

import { GuardrailOperationService } from '@/src/services/guardrail-service';
import { GuardrailOperation } from '@/src/services/guardrail-service/types';

export const useExecuteGuardrailOperation = (
	success?: () => void,
	fail?: (error: unknown) => void
): UseExecuteGuardrailOperation => {
	const [isExecuting, setExecuting] = useState<boolean>(false);

	const execute = useCallback(
		(operation: GuardrailOperation) => {
			setExecuting(true);
			return GuardrailOperationService.executeGuardrailOperation(operation, {
				success,
				fail
			}).finally(() => setExecuting(false));
		},
		[fail, success]
	);

	return { isExecuting, execute };
};
