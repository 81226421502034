import classNames from 'classnames';
import { MouseEventHandler, useCallback, useState } from 'react';

import { MOUSE_MOVE_THRESHOLD } from './config';

import styles from './styles.module.scss';

type ColumnItemContentProps = {
	title?: string;
	onClick?: () => void;
	disabled?: boolean;
};

export const ColumnItemContent = ({ title, onClick, disabled = false }: ColumnItemContentProps) => {
	const [isDragging, setDragging] = useState(false);
	const [mousePosition, setMousePosition] = useState<number[]>();

	const onMouseDown: MouseEventHandler = useCallback(({ pageX, pageY }) => {
		setDragging(false);
		setMousePosition([pageX, pageY]);
	}, []);
	const onMouseMove: MouseEventHandler = useCallback(
		({ pageX: newPageX, pageY: newPageY }) => {
			if (!mousePosition) {
				return;
			}

			const [previousPageX, previousPageY] = mousePosition;

			const moveX = Math.abs(newPageX - previousPageX);
			const moveY = Math.abs(newPageY - previousPageY);

			if (moveX >= MOUSE_MOVE_THRESHOLD || moveY >= MOUSE_MOVE_THRESHOLD) {
				setDragging(true);
			}
		},
		[mousePosition]
	);
	const onMouseUp: MouseEventHandler = useCallback(() => {
		if (!isDragging && !disabled) {
			onClick?.();
		}
	}, [disabled, isDragging, onClick]);

	return (
		<div
			className={classNames(styles.Content, { [styles.ContentDisabled]: disabled })}
			onMouseDown={onMouseDown}
			onMouseMove={onMouseMove}
			onMouseUp={onMouseUp}>
			{title}
		</div>
	);
};
