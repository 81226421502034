import { ISelectCellEditorOption } from './types';

export const getLabel = (
	value: string | undefined | null,
	values: ISelectCellEditorOption[] = []
) => {
	if (!value) {
		return '';
	}

	return values.find((item) => item.value === value)?.label ?? value;
};
