import { EComponentSize, KvTextField } from '@kelvininc/react-ui-components';
import {
	IApplicationParameterDefinition,
	IApplicationParameterValues,
	IClosedLoopSettings
} from '@kelvininc/types';

import { SELECT_OPERATOR_PLACEHOLDER } from '../../../../config';
import { SELECT_APPLICATION_PARAMETER_PLACEHOLDER } from '../../config';
import { ApplicationParameterAdvancedFilterSchemaFormData } from '../../types';

import { ApplicationParameterDropdown } from '../ApplicationParameterDropdown';
import { ApplicationParameterSchemaForm } from '../ApplicationParameterSchemaForm';

import styles from './styles.module.scss';

type ApplicationParameterFormProps = {
	application?: string;
	parameter?: string;
	formData?: ApplicationParameterAdvancedFilterSchemaFormData;
	onChangeParameter: (newParameter: string) => void;
	onChangeForm: (newForm: ApplicationParameterAdvancedFilterSchemaFormData) => void;
	parameters: IApplicationParameterDefinition;
	values: IApplicationParameterValues;
	closedLoopSettings: IClosedLoopSettings;
};

export const ApplicationParameterForm = ({
	application,
	formData,
	parameter,
	onChangeForm,
	onChangeParameter,
	parameters,
	values,
	closedLoopSettings
}: ApplicationParameterFormProps) => {
	if (!application) {
		return (
			<>
				<KvTextField
					className={styles.ParameterInput}
					placeholder={SELECT_APPLICATION_PARAMETER_PLACEHOLDER}
					size={EComponentSize.Small}
					inputDisabled
				/>
				<KvTextField
					className={styles.OperatorInput}
					placeholder={SELECT_OPERATOR_PLACEHOLDER}
					size={EComponentSize.Small}
					inputDisabled
				/>
			</>
		);
	}

	return (
		<>
			<ApplicationParameterDropdown
				parameter={parameter}
				parameters={parameters[application]}
				onChangeParameter={onChangeParameter}
				closedLoopSettings={closedLoopSettings}
			/>
			<ApplicationParameterSchemaForm
				formData={formData}
				parameter={parameter}
				parameters={parameters[application]}
				values={values[application]}
				closedLoopSettings={closedLoopSettings}
				onChangeForm={onChangeForm}
			/>
		</>
	);
};
