import { ClassNamesProp } from '@kelvininc/types';

import classNames from 'classnames';

import styles from './styles.module.scss';

export type UserInfoProps = {
	displayName: string;
	email?: string;
	customClasses?: ClassNamesProp;
};

export const UserInfo = ({ displayName, email = '', customClasses }: UserInfoProps) => (
	<div className={classNames(customClasses)}>
		<div className={styles.MenuTextName} role="name">
			{displayName}
		</div>
		<div className={styles.MenuTextEmail} role="email">
			{email}
		</div>
	</div>
);
