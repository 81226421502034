'use client';
import { PropsWithChildren } from 'react';

import { TerminalInstruction } from '../TerminalInstruction';

import styles from './styles.module.scss';
import { ProvisionInfoCardProps } from './types';

export const ProvisionInfoCard = ({
	title,
	code,
	children
}: PropsWithChildren<ProvisionInfoCardProps>) => {
	return (
		<div className={styles.ProvisionInfoCard}>
			<div className={styles.Title}>{title}</div>
			<div className={styles.Content}>{children}</div>
			{code && <TerminalInstruction code={code} />}
		</div>
	);
};
