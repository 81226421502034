import { App, ERecommendationState, RecommendationType } from '@kelvininc/node-client-sdk';
import {
	EActionButtonType,
	ETooltipPosition,
	KvActionButtonText,
	KvTooltip
} from '@kelvininc/react-ui-components';
import { RecommendationStatus, useRecommendationModalController } from '@kelvininc/shared-ui';
import { IClosedLoopSettings, ICoreAppPermissions } from '@kelvininc/types';
import { useCallback, useMemo } from 'react';

import { DEFAULT_CLOSED_LOOP_SETTINGS } from '../../../config';
import { IBaseTData, ICellRendererParams } from '../../../core';
import { refreshDatasource } from '../../../core/components/AgGridTable/utils';
import { withBaseCellRenderer } from '../BaseCellRenderer';

import styles from './styles.module.scss';

import { IAssetInsightsRecommendation, IRecommendationModalCallbacks } from './types';

import { getRecommendationTooltip } from './utils';

export type RecommendationCellRendererParams = {
	recommendationTypes?: Record<string, RecommendationType>;
	applications?: Record<string, App>;
	closedLoopSettings?: IClosedLoopSettings;
	permissions?: ICoreAppPermissions;
	callbacks?: IRecommendationModalCallbacks;
};

const Component = <TData extends IBaseTData>({
	recommendationTypes = {},
	value: recommendation,
	closedLoopSettings = DEFAULT_CLOSED_LOOP_SETTINGS,
	permissions,
	api,
	callbacks
}: RecommendationCellRendererParams & ICellRendererParams<TData, IAssetInsightsRecommendation>) => {
	const { openModal } = useRecommendationModalController();

	const recommendationType = useMemo(
		() => (recommendation ? recommendationTypes[recommendation.type_name] : undefined),
		[recommendation, recommendationTypes]
	);

	const onClickButton = useCallback(() => {
		if (!recommendation || !recommendationType || !api) {
			return;
		}
		callbacks?.onOpen?.();
		openModal({
			recommendationId: recommendation.id,
			onAccept: () => {
				refreshDatasource(api);
				callbacks?.onAccept?.();
			},
			onReject: () => {
				refreshDatasource(api);
				callbacks?.onReject?.();
			},
			onClose: () => callbacks?.onClose?.(),
			closedLoopSettings,
			metadata: {
				permissions
			}
		});
	}, [
		recommendation,
		recommendationType,
		api,
		callbacks,
		openModal,
		closedLoopSettings,
		permissions
	]);

	if (!recommendation || !recommendationType) {
		return <>N/A</>;
	}

	return (
		<div className={styles.RecommendationContainer}>
			<div className={styles.Details}>
				<KvTooltip text={recommendationType.title} truncate>
					<div className={styles.Title}>{recommendationType.title}</div>
				</KvTooltip>
				<KvTooltip
					text={getRecommendationTooltip(recommendation)}
					position={ETooltipPosition.BottomStart}>
					<div className={styles.Status}>
						<RecommendationStatus
							status={recommendation.state}
							customClasses={styles.TagStatus}
						/>
					</div>
				</KvTooltip>
			</div>
			<div className={styles.Action}>
				{recommendation.state === ERecommendationState.Pending ? (
					<KvActionButtonText
						type={EActionButtonType.Tertiary}
						text="View more"
						onClickButton={onClickButton}
					/>
				) : (
					<KvActionButtonText
						type={EActionButtonType.Ghost}
						text="Details"
						onClickButton={onClickButton}
					/>
				)}
			</div>
		</div>
	);
};

export const RecommendationCellRenderer = withBaseCellRenderer(Component);
