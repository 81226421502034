import {
	DataStream,
	DataStreamContext,
	DataStreamDataType,
	DataStreamSemanticType,
	DatastreamsService,
	EKrnResource,
	EPaginatorType,
	KvKRNParser,
	Unit
} from '@kelvininc/node-client-sdk';

import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';

export const getDataStreamRequester = (id: string): Promise<DataStream> => {
	return DatastreamsService.getDataStream({ dataStreamName: id })
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};

export const getDatastreamUnitsRequester = (): Promise<Unit[]> => {
	return DatastreamsService.listDataStreamsUnits({
		paginationType: EPaginatorType.Stream
	})
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};

export const getDatastreamsSemanticTypes = (): Promise<DataStreamSemanticType[]> => {
	return DatastreamsService.listDataStreamsSemanticTypes({
		paginationType: EPaginatorType.Stream
	})
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};

export const getDatastreamsDataTypes = (): Promise<DataStreamDataType[]> => {
	return DatastreamsService.listDataStreamsDataTypes({
		paginationType: EPaginatorType.Stream
	})
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};

export const getDatastreamsRequester = (): Promise<DataStream[]> => {
	return DatastreamsService.listDataStreams({
		dataStreamsListData: {},
		paginationType: EPaginatorType.Stream
	})
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};

export const getDatastreamContextsRequester = (): Promise<DataStreamContext[]> =>
	DatastreamsService.listDataStreamContexts({
		dataStreamContextsListData: {},
		paginationType: EPaginatorType.Stream
	})
		.pipe(catchHttpError(throwHttpError))
		.toPromise();

export const getAssetDatastreamContextsRequester = (asset: string): Promise<DataStreamContext[]> =>
	DatastreamsService.listDataStreamContexts({
		dataStreamContextsListData: {
			resources: [KvKRNParser.buildKRN(EKrnResource.Asset, { asset })]
		},
		paginationType: EPaginatorType.Stream
	})
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
