import { useMemo } from 'react';

import { ImportGuardrailsWizardFormValues } from '../../types';

import { useImportGuardrailsWizardFormContext } from '../useImportGuardrailsWizardForm';

import { onSubmitForm } from './utils';

export const useOnSubmitForm = (
	submit: (form: ImportGuardrailsWizardFormValues) => Promise<void>
): (() => void) => {
	const form = useImportGuardrailsWizardFormContext();

	return useMemo(
		() =>
			form.handleSubmit((values: ImportGuardrailsWizardFormValues) =>
				onSubmitForm(values, submit)
			),
		[form, submit]
	);
};
