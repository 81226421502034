import { buildUrl, joinQueryParams } from '@kelvininc/tsutils';
import { URLSearchParamsInit } from '@kelvininc/types';

import { OPERATIONS_ROUTE_BASE_PATH } from '../config';

import { EAssetControlQueryParamsName } from '@/src/types';

const ROUTE_PATH = `${OPERATIONS_ROUTE_BASE_PATH}/assets`;

export const buildAssetsRoute = (query?: URLSearchParamsInit): string =>
	buildUrl(ROUTE_PATH, query);

export const buildAssetDetailsRoute = (assetName: string, query?: URLSearchParamsInit): string =>
	buildUrl([ROUTE_PATH, assetName], query);

export const buildAssetManualControlPageRoute = (
	currentPathname: string,
	assetName: string,
	query?: URLSearchParamsInit
): string => {
	const redirectParam = new URLSearchParams({
		[EAssetControlQueryParamsName.Redirect]: currentPathname.includes('/manual-controls')
			? '/'
			: currentPathname
	});

	return buildUrl(
		[ROUTE_PATH, assetName, 'manual-controls'],
		joinQueryParams(query as URLSearchParamsInit, redirectParam)
	);
};
