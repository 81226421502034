import { DataStream, EDataStreamType } from '@kelvininc/node-client-sdk';

import {
	EComponentSize,
	KvMultiSelectDropdown,
	KvSingleSelectDropdown,
	KvToggleButtonGroup
} from '@kelvininc/react-ui-components';
import { useDropdownSearchOptions } from '@kelvininc/shared-ui';
import { CONTROL_CHANGES_STATUSES_DROPDOWN_OPTIONS } from '@kelvininc/tsutils';
import { ReactState } from '@kelvininc/types';
import { useCallback, useMemo } from 'react';

import { ILastControlChangeColumnData } from '../../../../../../../AgGridTable';

import {
	ALL_SETPOINTS_KEY,
	LAST_CONTROL_CHANGE_COLUMN_DATASTREAM_LABEL,
	LAST_CONTROL_CHANGE_COLUMN_DATASTREAM_PLACEHOLDER,
	LAST_CONTROL_CHANGE_COLUMN_STATUSES_LABEL,
	LAST_CONTROL_CHANGE_COLUMN_STATUSES_PLACEHOLDER,
	LAST_CONTROL_CHANGE_COLUMN_TOGGLE_BUTTONS_DESCRIPTION,
	SETPOINTS_TOGGLE_BUTTONS,
	SINGLE_SETPOINT_KEY,
	USE_DROPDOWN_OPTIONS
} from './config';

import styles from './styles.module.scss';

import { buildStatusesList, buildStatusesOptions } from './utils';

type LastControlChangeSettingsProps = {
	state: ReactState<ILastControlChangeColumnData>;
	datastreams?: Record<string, DataStream>;
};

export const LastControlChangeSettings = ({
	state,
	datastreams = {}
}: LastControlChangeSettingsProps) => {
	const [settings, setState] = state;

	const writableDatastreams = useMemo(
		() => Object.values(datastreams).filter(({ type }) => type === EDataStreamType.Measurement),
		[datastreams]
	);
	const { options, filteredOptions, setSearchTerm } = useDropdownSearchOptions(
		writableDatastreams,
		USE_DROPDOWN_OPTIONS
	);

	const statusesOptions = useMemo(
		() => buildStatusesOptions(settings.statuses),
		[settings.statuses]
	);

	const selectedButtons = useMemo(
		() => ({
			[ALL_SETPOINTS_KEY]: settings.allSetpoints,
			[SINGLE_SETPOINT_KEY]: !settings.allSetpoints
		}),
		[settings.allSetpoints]
	);

	const onSelectDatastream = useCallback(
		({ detail: newDatastreamName }: CustomEvent<string>) =>
			setState((previousState) => ({ ...previousState, datastreams: [newDatastreamName] })),
		[setState]
	);
	const onSelectStatus = useCallback(
		({ detail: newStatuses }: CustomEvent<Record<string, boolean>>) =>
			setState((previousState) => ({
				...previousState,
				statuses: buildStatusesList(newStatuses)
			})),
		[setState]
	);
	const onSearchChange = useCallback(
		({ detail: searchedDatastream }: CustomEvent<string>) => setSearchTerm(searchedDatastream),
		[setSearchTerm]
	);

	const onClickToggle = useCallback(
		({ detail: selectedToggle }: CustomEvent<string>) => {
			if (selectedToggle === ALL_SETPOINTS_KEY) {
				setState((previousState) => ({
					...previousState,
					datastreams: [],
					allSetpoints: true
				}));
			} else {
				setState((previousState) => ({
					...previousState,
					datastreams: [],
					allSetpoints: false
				}));
			}
		},
		[setState]
	);

	return (
		<div className={styles.LastControlChangeSettings}>
			<div className={styles.SetpointsToggle}>
				<div className={styles.SetpointDescription}>
					{LAST_CONTROL_CHANGE_COLUMN_TOGGLE_BUTTONS_DESCRIPTION}
				</div>
				<KvToggleButtonGroup
					buttons={SETPOINTS_TOGGLE_BUTTONS}
					selectedButtons={selectedButtons}
					onCheckedChange={onClickToggle}
					size={EComponentSize.Large}
					withRadio
				/>
			</div>
			{!settings.allSetpoints && (
				<KvSingleSelectDropdown
					required
					placeholder={LAST_CONTROL_CHANGE_COLUMN_DATASTREAM_PLACEHOLDER}
					label={LAST_CONTROL_CHANGE_COLUMN_DATASTREAM_LABEL}
					options={options}
					filteredOptions={filteredOptions}
					selectedOption={settings.datastreams?.[0]}
					onOptionSelected={onSelectDatastream}
					onSearchChange={onSearchChange}
					searchable
				/>
			)}
			<KvMultiSelectDropdown
				placeholder={LAST_CONTROL_CHANGE_COLUMN_STATUSES_PLACEHOLDER}
				label={LAST_CONTROL_CHANGE_COLUMN_STATUSES_LABEL}
				options={CONTROL_CHANGES_STATUSES_DROPDOWN_OPTIONS}
				selectedOptions={statusesOptions}
				onOptionsSelected={onSelectStatus}
				searchable={false}
			/>
		</div>
	);
};
