import { flip, offset } from '@floating-ui/dom';
import { DataStream } from '@kelvininc/node-client-sdk';

import {
	ComputePositionConfig,
	EIllustrationName,
	IIllustrationMessage
} from '@kelvininc/react-ui-components';
import { UseDropdownOptions } from '@kelvininc/types';

export const SELECT_DATASTREAMS_DROPDOWN_OPTIONS: Partial<ComputePositionConfig> = {
	placement: 'bottom-start',
	middleware: [
		offset(8),
		flip({
			padding: 16
		})
	]
};

export const SELECT_DATASTREAMS_PLACEHOLDER = 'Datastreams';
export const SELECT_DATASTREAMS_SEARCH_PLACEHOLDER = 'Search for datastreams';
export const SELECT_DATASTREAMS_NO_DATA_AVAILABLE_CONFIG: IIllustrationMessage = {
	illustration: EIllustrationName.NoDataAvailable,
	header: 'No Data Streams Available',
	description: 'There are no data streams to display at the moment.'
};

export const USE_DROPDOWN_OPTIONS: UseDropdownOptions<DataStream> = {
	key: 'name',
	label: 'title',
	value: 'name'
};
