import { useMemo } from 'react';

import { IBaseTData, IColumnDef } from '../../../../../../../AgGridTable';
import { IIndexedColumnDef } from '../../types';
import { sortColumnDefsByLockPin, splitColumnDefsByPinAlignment } from '../../utils';

export const useSplitAndSortedColumnDefs = <TData extends IBaseTData>(
	columnDefs: IColumnDef<TData>[]
): [IIndexedColumnDef<TData>[], IIndexedColumnDef<TData>[], IIndexedColumnDef<TData>[]] => {
	return useMemo(() => {
		const splitColumnDefs = splitColumnDefsByPinAlignment(columnDefs);

		const sortedSplitColumnDefs = splitColumnDefs.map(sortColumnDefsByLockPin) as [
			IIndexedColumnDef<TData>[],
			IIndexedColumnDef<TData>[],
			IIndexedColumnDef<TData>[]
		];

		return sortedSplitColumnDefs;
	}, [columnDefs]);
};
