'use client';
import { MiddlewareState, flip, offset, size } from '@floating-ui/dom';
import {
	ComputePositionConfig,
	EComponentSize,
	EIconName,
	EInputFieldType,
	EValidationState,
	ITextField
} from '@kelvininc/react-ui-components';

export const DEFAULT_RELATIVE_TIME_INPUT_CONFIG: Partial<ITextField> = {
	placeholder: 'Select a date',
	type: EInputFieldType.Text,
	icon: EIconName.Calendar,
	state: EValidationState.None,
	size: EComponentSize.Small
};

export const DEFAULT_RELATIVE_TIME_DROPDOWN_POSITION_OPTIONS: Partial<ComputePositionConfig> = {
	placement: 'bottom-end',
	middleware: [
		offset(8),
		flip({
			padding: 16,
			fallbackPlacements: ['bottom-end', 'top-end', 'top-start', 'bottom-start']
		}),
		size({
			apply({ elements }: MiddlewareState) {
				Object.assign(elements.floating.style, {
					width: 'max-content'
				});
			}
		})
	]
};
