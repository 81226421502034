import { EActionButtonType, KvActionButtonText } from '@kelvininc/react-ui-components';
import { closeModal } from '@kelvininc/shared-ui';
import { IColumnDef, Table } from '@kelvininc/table';
import { sortArrayByGroupOfFields } from '@kelvininc/tsutils';
import { useMemo } from 'react';

import { DEFAULT_COLUMN_DEF, TABLE_ROW_HEIGHT } from './config';
import styles from './styles.module.scss';
import { IDetailValue } from './types';
import { buildDescription, buildTableColumnDefs } from './utils';

export type DetailValuesProps = {
	title: string;
	parameterTitle: string;
	data: IDetailValue[];
};

export const DetailValues = ({ parameterTitle, title, data = [] }: DetailValuesProps) => {
	const columnDefs: IColumnDef<IDetailValue>[] = useMemo(
		() => buildTableColumnDefs(title),
		[title]
	);

	const sortedData = useMemo(() => sortArrayByGroupOfFields(data, ['asset']), [data]);

	return (
		<>
			<div className={styles.DetailValues}>
				<div className={styles.Description}>{buildDescription(title, parameterTitle)}</div>
				<div className={styles.ValuesTable}>
					<Table
						customClasses={styles.Table}
						defaultColumnDef={DEFAULT_COLUMN_DEF}
						columnDefs={columnDefs}
						data={sortedData}
						getRowId={(data) => data.asset}
						rowHeight={TABLE_ROW_HEIGHT}
					/>
				</div>
			</div>
			<div className={styles.Footer} slot="footer">
				<KvActionButtonText
					text="Close"
					type={EActionButtonType.Primary}
					onClickButton={() => closeModal()}
				/>
			</div>
		</>
	);
};
