import { EDataState } from '@kelvininc/shared-ui';
import { ESortDirection } from '@kelvininc/types';

import {
	EColumnDataType,
	IBaseTData,
	ISortInfo,
	ITableContext
} from '../../../core/components/AgGridTable/types';

import {
	SORTED_LABEL_PREFIX,
	SORTING_ASCENDING_LABEL_SUFFIXS,
	SORTING_DESCENDING_LABEL_SUFFIXS,
	UNSORTED_LABEL_PREFIX
} from './config';

const getSortingAscendingLabelSuffix = (columnDataType: EColumnDataType) =>
	SORTING_ASCENDING_LABEL_SUFFIXS[columnDataType];

const getSortingDescendingLabelSuffix = (columnDataType: EColumnDataType) =>
	SORTING_DESCENDING_LABEL_SUFFIXS[columnDataType];

const getSortingLabel = (
	columnDataType?: EColumnDataType,
	isSorting?: boolean,
	isSortAscending?: boolean
) => {
	const labels: string[] = [];

	labels.push(isSorting ? SORTED_LABEL_PREFIX : UNSORTED_LABEL_PREFIX);

	if (columnDataType === undefined) {
		return labels.join();
	}

	labels.push(
		isSortAscending || !isSorting
			? getSortingAscendingLabelSuffix(columnDataType)
			: getSortingDescendingLabelSuffix(columnDataType)
	);

	return labels.join(' ');
};

export const getSortingStatus = (
	displayName?: string,
	columnDataType?: EColumnDataType,
	isSorting?: boolean,
	isSortAscending?: boolean
) => {
	const labels: string[] = [];

	if (displayName) {
		labels.push(displayName);
	}

	labels.push(getSortingLabel(columnDataType, isSorting, isSortAscending));

	return labels.join(' • ');
};

export const toggleSortInfo = (
	id: string,
	sortBy?: string[],
	sortDirection?: ESortDirection
): ISortInfo => {
	const isSorting = sortBy !== undefined;

	if (!isSorting) {
		return {
			sortBy: [id],
			sortDirection: ESortDirection.Asc
		};
	}

	const isSortAscending = sortDirection === ESortDirection.Asc;

	// Check if the table is already sorted by the clicked column
	if (sortBy?.includes(id)) {
		return {
			sortBy: [id],
			sortDirection: isSortAscending ? ESortDirection.Desc : ESortDirection.Asc
		};
	}

	return {
		sortBy: [id],
		sortDirection: ESortDirection.Asc
	};
};

export const isStateNotHasResults = <TData extends IBaseTData>(
	context: ITableContext<TData> | undefined
): boolean => {
	if (context === undefined) {
		return false;
	}

	const { state } = context;
	return state !== EDataState.HasResults;
};
