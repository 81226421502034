import {
	EParameterScheduleState,
	EParametersScheduleApplyDataType
} from '@kelvininc/node-client-sdk';
import { EToasterType, IToaster } from '@kelvininc/react-ui-components';

export const SCHEDULE_OPERATION_SUCCESS_TOASTER: IToaster = {
	header: 'Schedule operation sent successfully.',
	type: EToasterType.Success
};

export const SCHEDULE_OPERATION_ERROR_TOASTER: IToaster = {
	header: "Something went wrong. We can't send the schedule operation.",
	type: EToasterType.Error
};

export const SCHEDULE_APPLY_TYPE = {
	[EParameterScheduleState.Scheduled]: EParametersScheduleApplyDataType.Schedule,
	[EParameterScheduleState.ScheduledRevert]: EParametersScheduleApplyDataType.ScheduleRevert
};
