import { buildUrl } from '@kelvininc/tsutils';
import { URLSearchParamsInit } from '@kelvininc/types';

import { CONTEXTUALIZATION_ROUTE_BASE_PATH } from '../config';

const ROUTE_PATH = `${CONTEXTUALIZATION_ROUTE_BASE_PATH}/asset-management`;

export const buildAssetManagementRoute = (query?: URLSearchParamsInit): string =>
	buildUrl(ROUTE_PATH, query);

export const buildAssetManagementAssetsRoute = (query?: string): string =>
	buildUrl([ROUTE_PATH, 'assets'], query);

export const buildAssetManagementAssetTypesRoute = (query?: string): string =>
	buildUrl([ROUTE_PATH, 'asset-types'], query);
