import {
	EIconName,
	EInputFieldType,
	ETooltipPosition,
	EValidationState,
	KvIcon,
	KvTextField,
	KvTooltip
} from '@kelvininc/react-ui-components';

import { FieldErrors } from 'react-hook-form';

import { RadioButtons } from '../../../RadioButtons';

import { EGuardrailValueType, GuardrailIncreaseDecrease } from '../../types';

import { transformStringToNumber } from '../../utils';
import { GuardrailValueInput } from '../GuardrailValueInput';

import { GUARDRAIL_VALUE_TYPE_RADIO_BUTTONS } from './config';
import styles from './styles.module.scss';
import { buildHelpText } from './utils';

type GuardrailAdvancedValueFieldProps = {
	label: string;
	tip?: string;
	disabled: boolean;
	value: GuardrailIncreaseDecrease['min'] | GuardrailIncreaseDecrease['max'];
	errors?:
		| FieldErrors<GuardrailIncreaseDecrease>['min']
		| FieldErrors<GuardrailIncreaseDecrease>['max'];
	onChangeType: (type: EGuardrailValueType.Number | EGuardrailValueType.Percentage) => void;
	onChangeValue: (value: string | number | undefined) => void;
	onChangeInclusive: (inclusive: boolean) => void;
	onBlur: () => void;
};

export const GuardrailAdvancedValueField = ({
	label,
	tip,
	disabled,
	value,
	errors,
	onChangeType,
	onChangeValue,
	onChangeInclusive,
	onBlur
}: GuardrailAdvancedValueFieldProps) => {
	const currentValue = value[value.type]?.value;
	const currentInclusive = value[value.type]?.inclusive;
	const currentError = errors?.[value.type]?.value?.message;

	const showError = !!currentError && currentValue !== undefined;

	const onChange = (newValue: string) => {
		try {
			onChangeValue(transformStringToNumber(newValue));
		} catch (error) {
			onChangeValue(newValue || undefined);
		}
	};

	return (
		<div className={styles.AdvancedGuardrailValuesForm}>
			<div className={styles.Label}>
				{label}
				{tip && (
					<KvTooltip text={tip} position={ETooltipPosition.Right}>
						<KvIcon name={EIconName.Info} />
					</KvTooltip>
				)}
			</div>
			<div className={styles.Type}>
				<RadioButtons
					value={value.type}
					buttons={GUARDRAIL_VALUE_TYPE_RADIO_BUTTONS}
					disabled={disabled}
					onChange={(newType) => onChangeType(newType)}
				/>
			</div>
			<div className={styles.Value}>
				<GuardrailValueInput
					disabled={disabled}
					inclusive={currentInclusive}
					onChangeInclusive={onChangeInclusive}>
					<KvTextField
						type={EInputFieldType.Number}
						placeholder="Define the value here"
						value={currentValue}
						helpText={showError ? currentError : buildHelpText(currentInclusive)}
						state={showError ? EValidationState.Invalid : undefined}
						onTextChange={({ detail: newValue }) => onChange(newValue)}
						inputDisabled={disabled}
						onTextFieldBlur={() => onBlur()}
					/>
				</GuardrailValueInput>
			</div>
		</div>
	);
};
