import { useMemo } from 'react';

import { IDraggableItem } from '../../types';

export const useSplitListByDisabled = (
	items: IDraggableItem[]
): [IDraggableItem[], IDraggableItem[]] =>
	useMemo(
		() =>
			items.reduce<[IDraggableItem[], IDraggableItem[]]>(
				([draggableItems, disabledItems], value) => {
					if (value.disabled) {
						disabledItems.push(value);
					} else {
						draggableItems.push(value);
					}

					return [draggableItems, disabledItems];
				},
				[[], []]
			),
		[items]
	);
