'use client';

import { DEFAULT_BANNER_ROOT_ID, useNavigationSideBar, withBoundaries } from '@kelvininc/shared-ui';
import classnames from 'classnames';
import { PropsWithChildren } from 'react';

import { useLayoutResizer } from './hooks';
import styles from './styles.module.scss';

const Component = ({ children }: PropsWithChildren<{}>) => {
	const {
		widthState: [{ width, fixed }],
		resizingState: [isResizing]
	} = useNavigationSideBar();

	const layoutRef = useLayoutResizer(fixed, width);

	return (
		<div
			ref={layoutRef}
			data-test-id="e2e-content-page"
			className={classnames(styles.Content, {
				[styles.ContentNotResizing]: !isResizing
			})}>
			<div id={DEFAULT_BANNER_ROOT_ID} />
			<div className={classnames(styles.PageContent)}>{children}</div>
		</div>
	);
};

export const ContentPage = withBoundaries(Component);
