export const VALIDATING_PROCESS_MESSAGE =
	'Please wait, we are validating your .csv file structure and content...';

export const VALIDATION_PROCESS_COMPLETE_MESSAGE = 'File validation is complete.';

export const VALID_FILE_MESSAGE = 'This is a valid file, no errors were found.';

export const INVALID_FILE_MESSAGE =
	'Some errors were found. Please re-upload the .csv file with the fixed errors.';

export const EMPTY_CSV_FILE_MESSAGE = 'The provided file has no content.';

export const SCHEMA_ROW_KEY = 'row';
