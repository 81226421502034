import { EToasterType, IToaster } from '@kelvininc/react-ui-components';

import { EManageRowSettingKey } from '../../../../../../../core/components/Table/types';

import { IManageRowSetting } from './types';

export const MANAGE_ROWS_SETTINGS_CONFIG: Record<EManageRowSettingKey, IManageRowSetting> = {
	[EManageRowSettingKey.FROZEN_ROWS]: {
		id: EManageRowSettingKey.FROZEN_ROWS,
		label: 'Allow frozen rows',
		hint: "The number of available frozen rows will depend from table's height. The frozen rows' content will not be affected by sorting, filtering, or searching."
	}
};

export const FROZEN_ROWS_SETTING_DISABLED_TOASTER: IToaster = {
	header: 'You disabled Frozen Row successfully.',
	type: EToasterType.Success
};
