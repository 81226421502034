import {
	DataStream,
	DatastreamsService,
	EDatastreamExtraFieldComputationDataAgg
} from '@kelvininc/node-client-sdk';
import { SchemaFormProps } from '@kelvininc/react-ui-components';
import { isDataStreamDataTypeString } from '@kelvininc/tsutils';
import { DataStreamAggregationFunction } from '@kelvininc/types';
import { useEffect, useState } from 'react';
import { map, switchMap, tap } from 'rxjs/operators';

import { DatastreamAdvancedFilterSchemaFormData } from '../../types';

import { buildSchemaForm, buildSchemaFormWithLastValues, getLastDatastreamValues } from './utils';

export const useSchemaFormBuilder = (
	datastream: DataStream,
	aggregation?: DataStreamAggregationFunction
): SchemaFormProps<DatastreamAdvancedFilterSchemaFormData>['schema'] | undefined => {
	const [schema, setSchema] =
		useState<SchemaFormProps<DatastreamAdvancedFilterSchemaFormData>['schema']>();

	useEffect(() => {
		if (
			!isDataStreamDataTypeString(datastream.dataTypeName) ||
			aggregation === EDatastreamExtraFieldComputationDataAgg.Count
		) {
			setSchema(buildSchemaForm(datastream, aggregation));
			return;
		}

		const checkStringLastValues = () => {
			return DatastreamsService.getDataStreamContext({
				dataStreamName: datastream.name
			})
				.pipe(
					map(({ context = [] }) => context.map(({ resource }) => resource)),
					switchMap((resources: string[]) =>
						getLastDatastreamValues(resources, datastream.name)
					),
					tap((data) => setSchema(buildSchemaFormWithLastValues(data)))
				)
				.subscribe();
		};

		const sub = checkStringLastValues();

		return () => sub.unsubscribe();
	}, [aggregation, datastream]);

	return schema;
};
