import { EPropertyType } from '@kelvininc/node-client-sdk';
import { EIconName, EInputFieldType, EValidationState } from '@kelvininc/react-ui-components';
import {
	ActionsCellRenderer,
	ICellComponentSelector,
	ICellEditorSelector,
	IColumnDef,
	ISelectCellEditorOption,
	SelectCellEditor,
	SelectCellRenderer,
	TextCellEditor,
	TextCellRenderer,
	getActionColumnOptions
} from '@kelvininc/table';
import { isEmpty } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { validateNameId } from '../ValidatedTextField/utils';

import {
	BOOLEAN_VALUE_OPTIONS,
	KEY_VALUE_TYPE_OPTIONS,
	NAME_ID_ERROR_MESSAGES,
	REQUIRED_FIELD_MESSAGE
} from './config';
import { IKeyValueItem, KeyMapping } from './types';

import { APP_REGEX, NAME_MAX_LENGTH_INPUT_CHARACTERS } from '@/src/config';

export const getEmptyData = (nEmptyValues: number): IKeyValueItem[] =>
	Array(nEmptyValues).fill({}).map(newEmptyLine);

export const newEmptyLine = (): IKeyValueItem => {
	return {
		key: undefined,
		value: undefined,
		type: undefined,
		id: uuidv4()
	};
};

export const getOptionLabel = (
	type?: string,
	options: Record<string, ISelectCellEditorOption> = {}
) => {
	return type && options[type] ? options[type].label : '';
};

const getValueCellEditor = (data: IKeyValueItem): ICellEditorSelector<IKeyValueItem> => {
	switch (data.type) {
		case EPropertyType.Boolean:
			return {
				component: SelectCellEditor,
				params: {
					selectConfig: {
						placeholder: 'Select an option'
					},
					values: Object.values(BOOLEAN_VALUE_OPTIONS)
				}
			};
		case EPropertyType.Number:
			return {
				component: TextCellEditor,
				params: {
					inputProps: {
						placeholder: 'Write here the value',
						type: EInputFieldType.Number
					}
				}
			};
		default:
			return {
				component: TextCellEditor,
				params: {
					inputProps: {
						placeholder: 'Write here the value',
						type: EInputFieldType.Text
					}
				}
			};
	}
};

const getValueCellComponent = (data?: IKeyValueItem): ICellComponentSelector<IKeyValueItem> => {
	const cellState = (value: string, data?: IKeyValueItem) => {
		if (value !== undefined && !!value) {
			return { valid: true };
		} else {
			return {
				valid: isEmpty(data?.type) && isEmpty(data?.key),
				message: REQUIRED_FIELD_MESSAGE
			};
		}
	};

	switch (data?.type) {
		case EPropertyType.Boolean:
			return {
				component: SelectCellRenderer,
				params: {
					placeholder: 'Select an option',
					cellState
				}
			};
		default:
			return {
				component: TextCellRenderer,
				params: {
					placeholder: 'Write here the value',
					cellState
				}
			};
	}
};
const getTypeCellEditor = (
	data: IKeyValueItem,
	keyTypeMapping: Record<string, KeyMapping>
): ICellEditorSelector<IKeyValueItem> => {
	return {
		component: SelectCellEditor,
		params: {
			selectConfig: {
				placeholder: 'Select an option'
			},
			values: Object.values(KEY_VALUE_TYPE_OPTIONS),
			disabled: !!(data?.key && keyTypeMapping[data.key]?.type)
		}
	};
};

const getTypeCellComponent = (
	data?: IKeyValueItem,
	keyTypeMapping?: Record<string, KeyMapping>
): ICellComponentSelector<IKeyValueItem> => {
	const isDisabled = !!(data?.key && keyTypeMapping?.[data.key]?.type);
	return {
		component: isDisabled ? TextCellRenderer : SelectCellRenderer,
		params: {
			placeholder: 'Select an option',
			disabled: isDisabled,
			cellState: (type, data) => {
				if (type !== undefined && !isEmpty(type)) {
					return { valid: true };
				} else {
					return {
						valid: isEmpty(data?.value) && isEmpty(data?.key),
						message: REQUIRED_FIELD_MESSAGE
					};
				}
			}
		}
	};
};

const getTitleCellComponent = (
	data?: IKeyValueItem,
	keyTypeMapping?: Record<string, KeyMapping>
): ICellComponentSelector<IKeyValueItem> => {
	return {
		component: TextCellRenderer,
		params: {
			placeholder: 'Write here the display name',
			disabled: !!(data?.key && keyTypeMapping?.[data.key]?.title)
		}
	};
};

export const buildKeyValueEditColumnDefs = (
	keyName: string,
	deleteColumn: (item?: IKeyValueItem) => void,
	keyTypeMapping: Record<string, KeyMapping>,
	tableData: IKeyValueItem[]
): IColumnDef<IKeyValueItem>[] => [
	{
		id: 'key',
		title: 'Name',
		accessor: 'key',
		editable: true,
		singleClickEdit: true,
		cellEditor: TextCellEditor,
		cellEditorParams: {
			inputProps: {
				placeholder: `Write here the ${keyName} name`,
				maxLength: NAME_MAX_LENGTH_INPUT_CHARACTERS
			}
		},
		cellComponent: TextCellRenderer,
		cellComponentParams: {
			placeholder: `Write here the ${keyName} name`,
			cellState: (key, data) => {
				if (key !== undefined && !isEmpty(key)) {
					const { state, helpText } = validateNameId(
						key,
						NAME_ID_ERROR_MESSAGES,
						APP_REGEX.nameExtended,
						tableData
							.filter((row) => row.key && row.id !== data?.id)
							.map((row) => row.key as string)
					);
					return { valid: state !== EValidationState.Invalid, message: helpText };
				} else {
					return {
						valid: isEmpty(data?.value) && isEmpty(data?.type),
						message: REQUIRED_FIELD_MESSAGE
					};
				}
			}
		}
	},
	{
		id: 'title',
		title: 'Display Name',
		accessor: 'title',
		editable: (data) => !data?.key || !keyTypeMapping[data.key]?.title,
		singleClickEdit: true,
		cellEditor: TextCellEditor,
		cellEditorParams: {
			inputProps: {
				placeholder: 'Write here the display name',
				maxLength: NAME_MAX_LENGTH_INPUT_CHARACTERS
			}
		},
		cellComponentSelector: (data) => getTitleCellComponent(data, keyTypeMapping)
	},
	{
		id: 'type',
		title: 'Type',
		accessor: 'type',
		valueFormatter: (_, value) => getOptionLabel(value.type, KEY_VALUE_TYPE_OPTIONS),
		editable: (data) => !data?.key || !keyTypeMapping[data.key]?.type,
		singleClickEdit: true,
		cellComponentSelector: (data) => getTypeCellComponent(data, keyTypeMapping),
		cellEditorSelector: (data) => getTypeCellEditor(data, keyTypeMapping)
	},
	{
		id: 'value',
		title: 'Value',
		accessor: 'value',
		editable: true,
		singleClickEdit: true,
		valueFormatter: (_, value) => {
			if (value.type === EPropertyType.Boolean) {
				return getOptionLabel(value.value, BOOLEAN_VALUE_OPTIONS);
			}
			return value.value ?? '';
		},
		cellEditorSelector: getValueCellEditor,
		cellComponentSelector: getValueCellComponent
	},
	{
		id: 'actions',
		title: '',
		cellComponent: ActionsCellRenderer,
		cellComponentParams: {
			actions: () => [
				{
					id: 'delete',
					icon: EIconName.Delete,
					onClick: deleteColumn
				}
			]
		},
		...getActionColumnOptions()
	}
];
