import {
	DataStream,
	EKrnResource,
	GuardrailAllOfNumberAllOfMin,
	GuardrailValueUpdater,
	KvKRNParser,
	Unit
} from '@kelvininc/node-client-sdk';
import { KvTooltip } from '@kelvininc/react-ui-components';
import { formatGuardrailUpdatedAt, formatStaticGuardrailValue } from '@kelvininc/tsutils';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { isNil } from 'lodash-es';

import { useTimeFromNow } from '../../hooks';
import { GuardrailValue } from '../GuardrailValue';

import styles from './styles.module.scss';
import { getLastValueTooltip } from './utils';

export type GuardrailAbsoluteValueProps = {
	updater?: GuardrailValueUpdater;
	guardrail?: GuardrailAllOfNumberAllOfMin;
	datastream?: DataStream;
	unit?: Unit;
	datastreams?: Record<string, DataStream>;
	datastreamUnits?: Record<string, Unit>;
	customClasses?: ClassNamesProp;
};

export const GuardrailAbsoluteValue = ({
	updater,
	guardrail,
	datastream,
	unit,
	datastreams,
	datastreamUnits,
	customClasses
}: GuardrailAbsoluteValueProps) => {
	const lastUpdated = useTimeFromNow(guardrail?.updatedAt, formatGuardrailUpdatedAt);

	const hasUpdater = !isNil(updater);
	if (!hasUpdater) {
		if (!guardrail) {
			return <KvTooltip text="No value available to display">N/A</KvTooltip>;
		}

		const { value, inclusive } = guardrail;
		const formattedValue = formatStaticGuardrailValue(value, datastream, unit);

		return (
			<div className={classNames(styles.GuardrailAbsoluteValue, customClasses)}>
				<GuardrailValue value={formattedValue} inclusive={inclusive} />
			</div>
		);
	}

	const { inclusive, source } = updater;
	const {
		resourceContent: { datastream: sourceDatastreamName }
	} = KvKRNParser.parseKRN<EKrnResource.AssetDatastream>(source, EKrnResource.AssetDatastream);

	const sourceDatastream = datastreams?.[sourceDatastreamName];
	const sourceUnit = sourceDatastream && datastreamUnits?.[sourceDatastream?.unitName];

	const lastValue = formatStaticGuardrailValue(guardrail?.value, sourceDatastream, sourceUnit);
	const label = sourceDatastream?.title ?? sourceDatastreamName;

	return (
		<div className={classNames(styles.GuardrailAbsoluteValue, customClasses)}>
			<GuardrailValue value={label} inclusive={inclusive} />
			<KvTooltip text={getLastValueTooltip(lastValue)}>
				<div
					className={
						styles.Description
					}>{`Last value: ${lastValue} | ${lastUpdated}`}</div>
			</KvTooltip>
		</div>
	);
};
