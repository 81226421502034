import { EIconName, ETagState, KvTagStatus, KvTooltip } from '@kelvininc/react-ui-components';

import styles from './styles.module.scss';

type TooltipErrorTagProps = {
	message: string;
};

export const TooltipErrorTag = ({ message }: TooltipErrorTagProps) => {
	return (
		<KvTooltip text={message} customClass={styles.CustomTooltip}>
			<KvTagStatus icon={EIconName.Error} state={ETagState.Error} />
		</KvTooltip>
	);
};
