'use client';
import { ComputePositionConfig, flip } from '@floating-ui/dom';

import { floatingUIHelper } from '@kelvininc/react-ui-components';

import { DEFAULT_TOOLTIP_POSITION_CONFIG } from '@/src/config';

export const buildToggleTipPositionConfig = (
	element?: HTMLElement | HTMLKvTooltipElement | null
): Partial<ComputePositionConfig> => {
	if (!element) {
		return DEFAULT_TOOLTIP_POSITION_CONFIG;
	}

	return floatingUIHelper.mergeComputePositionConfigs(DEFAULT_TOOLTIP_POSITION_CONFIG, {
		middleware: [
			flip({
				padding: 16,
				boundary: element
			})
		]
	});
};
