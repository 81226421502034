import { getRecommendationStatusName, getTimestampFormat } from '@kelvininc/tsutils';

import { IAssetInsightsRecommendation } from './types';

export const getRecommendationTooltip = (
	recommendation: IAssetInsightsRecommendation | undefined
): string | undefined => {
	if (!recommendation) {
		return;
	}

	const status = getRecommendationStatusName(recommendation.state);
	const timestamp = getTimestampFormat(recommendation.updated_at);

	// TODO: Once the logs are returned through the asset-insights endpoint
	// we should add the last error message
	return `${status} - at ${timestamp}`;
};
