import { Guardrail } from '@kelvininc/node-client-sdk';

import { GUARDRAILS_SETTINGS_KEY_PREFIX, GUARDRAILS_TABLE_DEFAULT_SETTINGS } from './config';

import { IUseTableSettings, useTableSettings } from '@/src/hooks';
import { guardrailsListTableSettingsSettingAtomFamily } from '@/src/recoil/settings/atoms';

export const useSettings = (): IUseTableSettings<Guardrail> => {
	const atom = guardrailsListTableSettingsSettingAtomFamily(GUARDRAILS_SETTINGS_KEY_PREFIX);

	return useTableSettings(
		GUARDRAILS_SETTINGS_KEY_PREFIX,
		GUARDRAILS_TABLE_DEFAULT_SETTINGS,
		atom
	);
};
