import {
	EActionButtonType,
	EComponentSize,
	KvActionButtonText
} from '@kelvininc/react-ui-components';
import {
	Card,
	IllustrationMessage,
	Link,
	Skeleton,
	closeModal,
	useScrolling,
	withBoundaries,
	withRecoilRoot
} from '@kelvininc/shared-ui';
import classNames from 'classnames';
import { useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { ApplicationScheduleCard } from '../ApplicationScheduleCard';

import { EMPTY_SCHEDULES_ILLUSTRATION_CONFIG, ERROR_ILLUSTRATION_CONFIG } from './config';
import { useAssetsParametersSchedulesPolling } from './hooks';
import { assetsParametersSchedulesAtomFamily } from './recoil/atoms';
import styles from './styles.module.scss';

import { assetsMapSelector } from '@/src/recoil/assets/selectors';
import { buildAssetDetailsRoute } from '@/src/utils/routes';

type AssetScheduledParametersModalProps = {
	assetName: string;
};

const AssetScheduledParametersModalError = () => {
	return (
		<div className={styles.AssetScheduledParametersModal}>
			<div className={styles.Body}>
				<div className={styles.Error}>
					<IllustrationMessage
						customClasses={styles.Illustration}
						{...ERROR_ILLUSTRATION_CONFIG}
					/>
				</div>
			</div>
			<div className={styles.Footer}>
				<KvActionButtonText
					type={EActionButtonType.Primary}
					text="Close"
					onClickButton={() => closeModal()}
				/>
			</div>
		</div>
	);
};

const AssetScheduledParametersModalSkeleton = () => {
	return (
		<div
			className={classNames(
				styles.AssetScheduledParametersModal,
				styles.AssetScheduledParametersModalSkeleton
			)}>
			<div className={styles.Header}>
				<div className={styles.Details}>
					<Skeleton className={styles.Skeleton} />
				</div>
				<div className={styles.Action}>
					<Skeleton className={styles.Skeleton} />
				</div>
			</div>
			<div className={styles.Body}>
				<Skeleton className={styles.Skeleton} />
			</div>
			<div className={styles.Footer}>
				<Skeleton className={styles.Skeleton} />
			</div>
		</div>
	);
};

const Component = ({ assetName }: AssetScheduledParametersModalProps) => {
	const asset = useRecoilValue(assetsMapSelector)[assetName];
	const schedules = useRecoilValue(assetsParametersSchedulesAtomFamily(assetName));
	const listRef = useRef<HTMLDivElement>(null);
	const isScrolling = useScrolling(listRef);

	useAssetsParametersSchedulesPolling(assetName);

	return (
		<div
			className={classNames(styles.AssetScheduledParametersModal, {
				[styles.Scrolling]: isScrolling
			})}>
			<div className={styles.Header}>
				<div className={styles.Details}>
					<div className={styles.Label}>Asset</div>
					<div className={styles.Title}>{asset.title}</div>
				</div>
				<div className={styles.Action}>
					<Link href={buildAssetDetailsRoute(asset.name)}>
						<KvActionButtonText
							type={EActionButtonType.Tertiary}
							text="See Asset Detail"
							size={EComponentSize.Small}
							onClickButton={() => closeModal()}
						/>
					</Link>
				</div>
			</div>
			<div ref={listRef} className={styles.Body}>
				{schedules.length === 0 ? (
					<div className={styles.Empty}>
						<IllustrationMessage
							customClasses={styles.Illustration}
							{...EMPTY_SCHEDULES_ILLUSTRATION_CONFIG}
						/>
					</div>
				) : (
					schedules.map((schedule) => (
						<Card key={schedule.id}>
							<ApplicationScheduleCard
								schedule={schedule}
								assetName={assetName}
								hasAppColumn
								hasActions={false}
							/>
						</Card>
					))
				)}
			</div>
			<div className={styles.Footer}>
				<KvActionButtonText
					type={EActionButtonType.Primary}
					text="Close"
					onClickButton={() => closeModal()}
				/>
			</div>
		</div>
	);
};

export const AssetScheduledParametersModal = withRecoilRoot(
	withBoundaries(Component, {
		SuspenseFallbackComponent: <AssetScheduledParametersModalSkeleton />,
		ErrorFallbackComponent: <AssetScheduledParametersModalError />
	})
);
