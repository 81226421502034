import { AssetService, EKvHttpStatusCode, EPaginatorType } from '@kelvininc/node-client-sdk';
import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { of } from 'rxjs';

export const getAssetPropertyDefinitions = (all = false) =>
	AssetService.listAssetPropertyDefinitions({
		assetPropertyDefinitionsListData: {},
		all,
		paginationType: EPaginatorType.Stream
	})
		.pipe(
			catchHttpError((error) => {
				if (error.status === EKvHttpStatusCode.NotFound) {
					return of([]);
				}

				return throwHttpError(error);
			})
		)
		.toPromise();
