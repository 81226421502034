import {
	DataStream,
	EAssetInsightsFilterDataOperator,
	EParameterType,
	EPropertyType,
	IAssetInsightsFilterData,
	IAssetPropertyExtraFieldData,
	IDatastreamExtraFieldComputationData,
	IDatastreamExtraFieldData,
	IParameterExtraFieldData,
	Property
} from '@kelvininc/node-client-sdk';
import {
	ETableAdvancedFilterType,
	IApplicationParameterTableAdvancedFilter,
	IAssetPropertyTableAdvancedFilter,
	IDatastreamTableAdvancedFilter,
	ITableAdvancedFilter,
	ITableAdvancedFilters,
	ITableFilters,
	convertFilterToPrimitiveType,
	getInlineFilterKey,
	isRelationalOperator
} from '@kelvininc/table';
import { filterObject, getRelativeTimeRange } from '@kelvininc/tsutils';
import {
	EDataStreamCustomAgg,
	EInlineFilterType,
	IApplicationParameterDefinition
} from '@kelvininc/types';
import { get, isEmpty } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { FILTERABLE_DATASTREAM_DATA_TYPES } from './config';

import { IAssetInsightsFilters } from '@/src/types/asset-insights';

const getAppParameterFilterKey = (application: string, parameter: string): string =>
	`${application}@${parameter}`;

const getDatastreamFilterKey = ({
	datastream,
	aggregation,
	timeRange
}: IDatastreamTableAdvancedFilter): string =>
	`${datastream}@${aggregation}${timeRange ? `@${timeRange}` : ''}`;

const getAdvancedFilterKey = (filter: ITableAdvancedFilter): string => {
	switch (filter.type) {
		case ETableAdvancedFilterType.ApplicationParameter:
			return getAppParameterFilterKey(filter.application, filter.parameter);
		case ETableAdvancedFilterType.AssetProperty:
			return filter.property;
		case ETableAdvancedFilterType.Datastream:
			return getDatastreamFilterKey(filter);
	}
};

const pickAssetInsightsFilter = (
	filter: ITableAdvancedFilter,
	accFilters: IAssetInsightsFilterData[] = []
): IAssetInsightsFilterData[] => {
	const filterOperator: IAssetInsightsFilterData[] = [
		...accFilters,
		{
			value: filter.value,
			operator: filter.operator as EAssetInsightsFilterDataOperator
		}
	];

	// Ensure that relational operator filters
	// are applied only to existing records.
	if (isRelationalOperator(filter.operator)) {
		filterOperator.push({
			operator: EAssetInsightsFilterDataOperator.Has
		});
	}

	return filterOperator;
};

export const buildDatastreamComputation = ({
	aggregation,
	timeRange
}: IDatastreamTableAdvancedFilter): IDatastreamExtraFieldComputationData | undefined => {
	if (aggregation === EDataStreamCustomAgg.Last || !timeRange) return;

	const [start_time, end_time] = getRelativeTimeRange(timeRange);

	return {
		agg: aggregation,
		start_time,
		end_time
	};
};

export const buildAdvancedDatastreamFilters = (
	filters: IDatastreamTableAdvancedFilter[] = []
): Record<string, IDatastreamExtraFieldData> => {
	return filters.reduce<Record<string, IDatastreamExtraFieldData>>((acc, filter) => {
		const key = getAdvancedFilterKey(filter);

		acc[key] = {
			name: key,
			filters: pickAssetInsightsFilter(filter, acc[key]?.filters),
			datastream_name: filter.datastream,
			computation: buildDatastreamComputation(filter)
		};

		return acc;
	}, {});
};

export const buildAdvancedParameterFilters = (
	filters: IApplicationParameterTableAdvancedFilter[] = [],
	appParameters: IApplicationParameterDefinition
) => {
	return filters.reduce<Record<string, IParameterExtraFieldData>>((acc, filter) => {
		const key = getAdvancedFilterKey(filter);

		acc[key] = {
			name: key,
			filters: pickAssetInsightsFilter(filter, acc[key]?.filters),
			app_name: filter.application,
			parameter_name: filter.parameter,
			primitive_type: get(
				appParameters,
				[filter.application, filter.parameter, 'primitiveType'],
				EParameterType.String
			)
		};

		return acc;
	}, {});
};

export const buildAdvancedAssetFilters = (
	filters: IAssetPropertyTableAdvancedFilter[] = [],
	properties: Record<string, Property>
) => {
	return filters.reduce<Record<string, IAssetPropertyExtraFieldData>>((acc, filter) => {
		const key = getAdvancedFilterKey(filter);

		acc[key] = {
			name: key,
			filters: pickAssetInsightsFilter(filter, acc[key]?.filters),
			property_name: filter.property,
			primitive_type: properties[filter.property]?.primitiveType ?? EPropertyType.String
		};

		return acc;
	}, {});
};

export const buildAssetInsightsFilters = ({
	filters = {},
	advancedFilters = {},
	properties,
	appParameters
}: {
	filters?: ITableFilters;
	advancedFilters?: ITableAdvancedFilters;
	properties: Record<string, Property>;
	appParameters: IApplicationParameterDefinition;
}): IAssetInsightsFilters => {
	const assetInsightsFilters: IAssetInsightsFilters = {
		assetProperties: buildAdvancedAssetFilters(
			advancedFilters[ETableAdvancedFilterType.AssetProperty],
			properties
		),
		appParameters: buildAdvancedParameterFilters(
			advancedFilters[ETableAdvancedFilterType.ApplicationParameter],
			appParameters
		),
		datastreams: buildAdvancedDatastreamFilters(
			advancedFilters[ETableAdvancedFilterType.Datastream]
		)
	};

	return Object.keys(filters).reduce((acc, filterKey) => {
		if (!filters[filterKey] || isEmpty(filters[filterKey])) {
			return acc;
		}

		const { type, name, appName } = getInlineFilterKey(filterKey);

		switch (type) {
			case EInlineFilterType.Property:
				const propertyValue = convertFilterToPrimitiveType(
					filters[filterKey],
					ETableAdvancedFilterType.AssetProperty,
					properties[name].primitiveType
				);

				acc.assetProperties = {
					...acc.assetProperties,
					[name]: {
						primitive_type: properties[name].primitiveType,
						property_name: name,
						name: `${name}-property-filter-${uuidv4()}`,
						filters: [
							...(acc.assetProperties?.[name]?.filters ?? []),
							{
								value: propertyValue,
								operator: EAssetInsightsFilterDataOperator.Equal
							}
						]
					}
				};
				break;

			case EInlineFilterType.Parameter:
				const key = getAppParameterFilterKey(appName, name);
				const appValue = convertFilterToPrimitiveType(
					filters[filterKey],
					ETableAdvancedFilterType.ApplicationParameter,
					appParameters[appName][name].primitiveType
				);

				acc.appParameters = {
					...acc.appParameters,
					[key]: {
						parameter_name: name,
						app_name: appName,
						name: `${name}-parameter-filter-${uuidv4()}`,
						filters: [
							...(acc.appParameters?.[key]?.filters ?? []),
							{
								value: appValue,
								operator: EAssetInsightsFilterDataOperator.Equal
							}
						]
					}
				};
				break;
		}

		return acc;
	}, assetInsightsFilters);
};

export const filterAcceptedDatastreams = (
	datastreams: Record<string, DataStream>
): Record<string, DataStream> => {
	return filterObject(datastreams, (_name, datastream) =>
		FILTERABLE_DATASTREAM_DATA_TYPES.includes(datastream.dataTypeName)
	);
};
