export const getEnabledDroppableType = (type: string) => `${type}.drop-enable`;
export const getDisabledDroppableType = (type: string) => `${type}.drop-disable`;

import { DragStart, DragUpdate } from '@hello-pangea/dnd';
import { CSSProperties } from 'react';

import { HANDLE_ATTRIBUTE } from './config';
import { IDraggableItem } from './types';

const getElementHeightWithBorder = (element: Element): number => {
	const elementStyle = window.getComputedStyle(element);
	const { clientHeight: elementHeight } = element;

	return (
		elementHeight +
		parseFloat(elementStyle.borderTopWidth) +
		parseFloat(elementStyle.borderBottomWidth)
	);
};

const getElementWidthWithBorder = (element: Element): number => {
	const elementStyle = window.getComputedStyle(element);
	const { clientWidth: elementWidth } = element;

	return (
		elementWidth +
		parseFloat(elementStyle.borderRightWidth) +
		parseFloat(elementStyle.borderLeftWidth)
	);
};

const getElementVerticalMargin = (element: Element): number => {
	const elementStyle = window.getComputedStyle(element);
	return parseFloat(elementStyle.marginTop) + parseFloat(elementStyle.marginBottom);
};

const getPlaceholderStyle = (
	draggedElement: Element,
	targetIndex: number,
	draggableElements: Element[]
): CSSProperties | undefined => {
	const elementHeight = getElementHeightWithBorder(draggedElement);
	const elementWidth = getElementWidthWithBorder(draggedElement);
	const siblingsSumHeight = draggableElements
		.slice(0, targetIndex)
		.reduce((accumulator, sibling) => accumulator + getElementHeightWithBorder(sibling), 0);

	const siblingsSumVerticalMargin = draggableElements
		.slice(0, targetIndex)
		.reduce((accumulator, sibling) => accumulator + getElementVerticalMargin(sibling), 0);

	return {
		height: elementHeight,
		width: elementWidth,
		top: siblingsSumHeight + siblingsSumVerticalMargin
	};
};

const getDraggedElement = (draggableId: string): Element | null => {
	const domQuery = `[${HANDLE_ATTRIBUTE}='${draggableId}']`;
	return document.querySelector(domQuery);
};

export const getPlaceholderStyleOnDragStart = (
	event: DragStart,
	items: IDraggableItem[],
	draggableItems: IDraggableItem[]
): CSSProperties | undefined => {
	// Add a little vibration if the browser supports it.
	// Add's a nice little physical feedback
	if (window.navigator.vibrate) {
		window.navigator.vibrate(100);
	}

	const {
		draggableId,
		source: { index: sourceIndex }
	} = event;
	const draggedElement = getDraggedElement(draggableId);

	// Get source index on the draggable items array
	const sourceItem = items[sourceIndex];
	const draggableSourceIndex = draggableItems.findIndex(
		({ index }) => index === sourceItem.index
	);

	if (!draggedElement || !draggedElement.parentNode) {
		return;
	}

	const { parentNode } = draggedElement;
	const draggableElements = [...parentNode.children];

	return getPlaceholderStyle(draggedElement, draggableSourceIndex, draggableElements);
};

export const getPlaceholderStyleOnDragUpdate = (
	event: DragUpdate,
	items: IDraggableItem[],
	draggableItems: IDraggableItem[]
): CSSProperties | undefined => {
	if (!event.destination) {
		return;
	}

	const {
		draggableId,
		destination: { index: destinationIndex },
		source: { index: sourceIndex }
	} = event;
	const draggedElement = getDraggedElement(draggableId);

	// Get source and destination indexes on the draggable items array
	const sourceItem = items[sourceIndex];
	const destinationItem = items[destinationIndex];
	const draggableSourceIndex = items.findIndex(({ index }) => index === sourceItem.index);
	const draggableDestinationIndex = draggableItems.findIndex(
		({ index }) => index === destinationItem.index
	);

	if (!draggedElement || !draggedElement.parentNode) {
		return;
	}

	const { parentNode } = draggedElement;
	const siblingsArray = [...parentNode.children];
	const movedItem = siblingsArray[draggableSourceIndex];

	siblingsArray.splice(draggableSourceIndex, 1);
	const updatedArray = [
		...siblingsArray.slice(0, draggableDestinationIndex),
		movedItem,
		...siblingsArray.slice(draggableDestinationIndex + 1)
	];

	return getPlaceholderStyle(draggedElement, draggableDestinationIndex, updatedArray);
};
