import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';

import styles from './styles.module.scss';

export const OverlayWrapper = ({
	children,
	customClasses
}: PropsWithChildren<{ customClasses?: ClassNamesProp }>) => {
	return (
		<div className={classNames(styles.Overlay, customClasses)}>
			<div className={styles.OverlayPanel}>
				<div className={styles.OverlayWrapper}>{children}</div>
			</div>
		</div>
	);
};

export const withOverlayWrapper = <ComponentProps,>(
	Component: FunctionComponent<ComponentProps>
) => {
	return function OverlayWrapperWrapper(componentProps: PropsWithChildren<ComponentProps>) {
		return (
			<OverlayWrapper {...componentProps}>
				<Component {...componentProps} />
			</OverlayWrapper>
		);
	};
};
