import { ClassNamesProp, PropsWithForwardedRef } from '@kelvininc/types';
import classNames from 'classnames';
import { identity, isFunction } from 'lodash-es';
import {
	CSSProperties,
	ComponentType,
	ForwardedRef,
	createElement,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef
} from 'react';
import {
	FixedSizeList,
	FixedSizeListProps,
	ListChildComponentProps,
	ListItemKeySelector,
	VariableSizeList,
	VariableSizeListProps
} from 'react-window';

import { useResizeSensor } from '../../hooks';

import { DEFAULT_ROW_HEIGHT } from './config';
import { IVirtualizedListApi } from './types';
import { getItemKey as defaultGetItemKey } from './utils';

type ListItemProps<T> = {
	children: ComponentType<ListChildComponentProps<T>>;
	customClasses?: ClassNamesProp;
	customStyles?: CSSProperties;
	itemKey?: ListItemKeySelector<T>;
	onRowHeightChange?: (index: number, height: number) => void;
} & ListChildComponentProps<T>;

export type VirtualizedListProps<T> = Omit<
	FixedSizeListProps<T> | VariableSizeListProps<T>,
	'itemSize'
> & {
	children: ComponentType<ListChildComponentProps<T>>;
	customClasses?: ClassNamesProp;
	customStyles?: CSSProperties;
	itemsGap?: number;
	itemSize?: number | ((index: number) => number);
};

const ListItem = <T,>({
	index,
	data,
	isScrolling,
	style,
	itemKey = defaultGetItemKey,
	customClasses,
	customStyles,
	onRowHeightChange,
	children
}: ListItemProps<T>) => {
	const rowRef = useRef<HTMLDivElement>(null);

	// Used to update the clientHeight on element resize
	useResizeSensor(rowRef, identity, 0);

	useEffect(() => {
		if (rowRef.current) {
			onRowHeightChange?.(index, rowRef.current.clientHeight);
		}
	}, [index, onRowHeightChange, rowRef.current?.clientHeight]);

	return (
		<div
			key={itemKey(index, data)}
			className={classNames(customClasses)}
			style={{
				...style,
				...customStyles
			}}>
			<div ref={rowRef}>
				{createElement(children, {
					style,
					data,
					index,
					isScrolling
				})}
			</div>
		</div>
	);
};

const Component = <T,>({
	itemKey,
	customClasses,
	customStyles,
	itemSize,
	itemsGap = 0,
	children,
	forwardedRef,
	...otherProps
}: PropsWithForwardedRef<VirtualizedListProps<T>, IVirtualizedListApi<T>>) => {
	const isItemSizeVariable = itemSize === undefined || isFunction(itemSize);
	const rowHeights = useRef<number[]>([]); // to have heights of every row item
	const listRef = useRef<FixedSizeList<T> | VariableSizeList<T> | null>(null);

	useImperativeHandle(forwardedRef, () => ({
		list: listRef.current
	}));

	if (isItemSizeVariable) {
		const getRowHeight = (index: number) => {
			return rowHeights.current[index] || DEFAULT_ROW_HEIGHT;
		};

		const setRowHeight = (index: number, size: number) => {
			(listRef.current as VariableSizeList<T>)?.resetAfterIndex(index);
			rowHeights.current = { ...rowHeights.current, [index]: size };
		};

		return (
			<VariableSizeList
				ref={listRef as ForwardedRef<VariableSizeList<T>>}
				itemSize={(index) => (itemSize ?? getRowHeight)(index) + itemsGap}
				{...otherProps}>
				{(listProps) => {
					return (
						<ListItem
							itemKey={itemKey}
							customClasses={customClasses}
							customStyles={customStyles}
							onRowHeightChange={setRowHeight}
							{...listProps}>
							{children}
						</ListItem>
					);
				}}
			</VariableSizeList>
		);
	} else if (itemSize !== undefined) {
		return (
			<FixedSizeList
				ref={listRef as ForwardedRef<FixedSizeList<T>>}
				itemSize={itemSize + itemsGap}
				{...otherProps}>
				{(listProps) => (
					<ListItem
						itemKey={itemKey}
						customClasses={customClasses}
						customStyles={customStyles}
						{...listProps}>
						{children}
					</ListItem>
				)}
			</FixedSizeList>
		);
	}
};

export const VirtualizedList = forwardRef(function ProcessMapWithRef<T>(
	props: VirtualizedListProps<T>,
	ref: ForwardedRef<IVirtualizedListApi<T>>
) {
	return <Component {...props} forwardedRef={ref} />;
}) as <T>(
	props: VirtualizedListProps<T> & {
		ref?: ForwardedRef<IVirtualizedListApi<T>>;
	}
) => ReturnType<typeof Component<T>>;
