import { PropsWithChildren } from 'react';

import { IDrafAdvancedFilter } from '../../../../../../../../../core/components/Table/contexts/TableAdvancedFiltersContext/types';
import { AdvancedFilterFormOptionsDropdown } from '../AdvancedFilterFormOptionsDropdown';

import styles from './styles.module.scss';
import { getFilterFormPrefix } from './utils';

type AdvancedFilterFormProps = {
	index: number;
	onDelete: () => void;
	onDuplicate: () => void;
	filters: IDrafAdvancedFilter[];
	isFormEmpty: boolean;
};

export const AdvancedFilterForm = ({
	index,
	onDelete,
	onDuplicate,
	isFormEmpty,
	children
}: PropsWithChildren<AdvancedFilterFormProps>) => {
	return (
		<>
			<div className={styles.Prefix}>{getFilterFormPrefix(index)}</div>
			{children}
			<div className={styles.Options}>
				<AdvancedFilterFormOptionsDropdown
					onDelete={onDelete}
					onDuplicate={onDuplicate}
					isFormEmpty={isFormEmpty}
				/>
			</div>
		</>
	);
};
