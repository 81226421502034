import {
	EAlertType,
	EValidationState,
	KvCheckbox,
	KvSingleSelectDropdown,
	KvSingleSelectDropdownCustomEvent,
	KvTextField
} from '@kelvininc/react-ui-components';
import { IExpandableApi, useMount, usePanelsManager } from '@kelvininc/shared-ui';
import { ReactState } from '@kelvininc/types';
import classNames from 'classnames';
import { isNil, merge } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
	ECustomColumnType,
	IApplicationParameterColumnData,
	IAssetPropertyColumnData,
	IBaseTData,
	IColumnOptions,
	IDatastreamColumnData,
	ILastControlChangeColumnData,
	IRecommendationColumnData,
	IScheduleApplicationParametersColumnData
} from '../../../../../AgGridTable';

import { DEFAULT_MANAGEMENT_TABLE_ALERT } from '../../../../config';
import { useTableManagementAddColumnContext } from '../../../../contexts/TableManagementAddColumnContext';
import { useTableManagementStateContext } from '../../../../contexts/TableManagementStateContext';
import { EManagementPanel, ICustomColumnsConfig, TableProps } from '../../../../types';

import {
	ApplicationParameterSettings,
	AssetPropertySettings,
	ColumnFormSection,
	DatastreamsSettings,
	LastControlChangeSettings,
	RecommendationSettings,
	ScheduleApplicationParametersSettings
} from './components';
import {
	COLUMN_OPTIONS_EXPANDABLE_LABEL,
	COLUMN_OPTIONS_HIDE_LABEL,
	COLUMN_SETTINGS_EXPANDABLE_LABEL,
	COLUMN_TITLE_LABEL,
	COLUMN_TITLE_PLACEHOLDER,
	COLUMN_TITLE_SUGGESTION_PREFIX,
	COLUMN_TYPE_LABEL,
	COLUMN_TYPE_PLACEHOLDER
} from './config';
import styles from './styles.module.scss';
import {
	buildColumnTitleSuggestion,
	buildColumnTypesOptions,
	getColumnTypeTitle,
	isSelectedColumnTypeValid
} from './utils';

export type ColumnFormProps<TData extends IBaseTData> = {
	config: ICustomColumnsConfig<TData>;
	columnTypeState: ReactState<ECustomColumnType | undefined>;
	columnOptionsState: ReactState<IColumnOptions<TData>>;
	columnTitleState: ReactState<string | undefined>;
	assetPropertyDataState: ReactState<IAssetPropertyColumnData>;
	datastreamDataState: ReactState<IDatastreamColumnData>;
	lastControlChangeDataState: ReactState<ILastControlChangeColumnData>;
	recommendationDataState: ReactState<IRecommendationColumnData>;
	applicationParameterDataState: ReactState<IApplicationParameterColumnData>;
	scheduleApplicationParametersDataState: ReactState<IScheduleApplicationParametersColumnData>;
	hasAlert?: boolean;
	titleError?: string;
	initiallyExpanded?: boolean;
} & Pick<TableProps<TData>, 'columnDefs'>;

export const ColumnForm = <TData extends IBaseTData>({
	columnDefs,
	config,
	columnTypeState: [columnType, onChangeColumnType],
	columnOptionsState: [columnOptions, onChangeColumnOptions],
	columnTitleState: [columnTitle, onChangeColumnTitle],
	assetPropertyDataState: [assetPropertyData, onChangeAssetPropertyData],
	datastreamDataState: [datastreamData, onChangeDatastreamData],
	lastControlChangeDataState: [lastControlChangeData, onChangeLastControlChangeData],
	recommendationDataState: [recommendationData, onChangeRecommendationData],
	applicationParameterDataState: [applicationParameterData, onChangeApplicationParameterData],
	scheduleApplicationParametersDataState: [
		scheduleApplicationParametersData,
		onChangeScheduleApplicationParametersData
	],
	hasAlert,
	titleError,
	initiallyExpanded
}: ColumnFormProps<TData>) => {
	const { panel } = usePanelsManager();
	const { setAlert } = useTableManagementStateContext();

	const {
		hasAddColumnChanges: [hasAddColumnChanges, setHasAddColumnChanges]
	} = useTableManagementAddColumnContext();

	const settingsExpandableRef = useRef<IExpandableApi>(null);
	const optionsExpandableRef = useRef<IExpandableApi>(null);

	const areExpandablesDisabled = useMemo(() => isNil(columnType), [columnType]);
	const columnTypeOptions = useMemo(() => buildColumnTypesOptions(config), [config]);
	const titleSuggestion = useMemo(
		() =>
			buildColumnTitleSuggestion(
				columnType,
				columnDefs,
				config,
				assetPropertyData,
				datastreamData,
				lastControlChangeData,
				recommendationData,
				applicationParameterData,
				scheduleApplicationParametersData
			),
		[
			assetPropertyData,
			columnDefs,
			columnType,
			config,
			lastControlChangeData,
			datastreamData,
			recommendationData,
			applicationParameterData,
			scheduleApplicationParametersData
		]
	);

	const onChangeTitle = useCallback(
		({ detail: newTitle }: CustomEvent<string>) => onChangeColumnTitle(newTitle.trim()),
		[onChangeColumnTitle]
	);

	const onOptionSelected = useCallback(
		(selectedOption: ECustomColumnType) => {
			setAlert(undefined);
			if (panel === EManagementPanel.Add && !hasAddColumnChanges) {
				setHasAddColumnChanges(true);
			}

			if (!isSelectedColumnTypeValid(selectedOption, config)) {
				setAlert(
					merge({}, DEFAULT_MANAGEMENT_TABLE_ALERT[EAlertType.Warning], {
						alertConfig: {
							label: getColumnTypeTitle(selectedOption)
						}
					})
				);
			}
		},
		[config, hasAddColumnChanges, panel, setAlert, setHasAddColumnChanges]
	);
	const onChangeType = useCallback(
		({ detail: newColumnType }: KvSingleSelectDropdownCustomEvent<string>) => {
			onOptionSelected(newColumnType as ECustomColumnType);
			onChangeColumnType(newColumnType as ECustomColumnType);
		},
		[onChangeColumnType, onOptionSelected]
	);
	const onChangeHide = useCallback(
		() =>
			onChangeColumnOptions((previousData) => ({
				...previousData,
				hide: !previousData?.hide
			})),
		[onChangeColumnOptions]
	);
	const applySuggestionTitle = useCallback(() => {
		onChangeColumnTitle(titleSuggestion);
	}, [onChangeColumnTitle, titleSuggestion]);

	useEffect(() => {
		if (columnType !== undefined) {
			settingsExpandableRef.current?.open();
			optionsExpandableRef.current?.open();
		}
	}, [columnType]);

	useMount(() => {
		// Select the first column type when there's only one available
		if (Object.keys(columnTypeOptions).length === 1) {
			const newColumnType = Object.keys(columnTypeOptions)[0] as ECustomColumnType;
			onOptionSelected(newColumnType);
			onChangeColumnType(newColumnType);
		}
	});

	const columnScrollRef = useRef<HTMLDivElement>(null);

	return (
		<div
			className={classNames(styles.ColumnFormScroll, { [styles.WithAlert]: hasAlert })}
			ref={columnScrollRef}>
			<div className={styles.ColumnTypeContent}>
				<KvSingleSelectDropdown
					required
					label={COLUMN_TYPE_LABEL}
					placeholder={COLUMN_TYPE_PLACEHOLDER}
					selectedOption={columnType}
					options={columnTypeOptions}
					onOptionSelected={onChangeType}
				/>
			</div>
			<div className={styles.ContentSettingContent}>
				<ColumnFormSection
					label={COLUMN_SETTINGS_EXPANDABLE_LABEL}
					disabled={areExpandablesDisabled}
					ref={optionsExpandableRef}
					defaultState={initiallyExpanded}>
					{columnType === ECustomColumnType.AssetProperty && (
						<AssetPropertySettings
							state={[assetPropertyData, onChangeAssetPropertyData]}
							properties={config[columnType]?.metadata.properties}
						/>
					)}
					{columnType === ECustomColumnType.Datastream && (
						<DatastreamsSettings
							state={[datastreamData, onChangeDatastreamData]}
							datastreams={config[columnType]?.metadata.datastreams}
						/>
					)}
					{columnType === ECustomColumnType.LastControlChange && (
						<LastControlChangeSettings
							state={[lastControlChangeData, onChangeLastControlChangeData]}
							datastreams={config[columnType]?.metadata.datastreams}
						/>
					)}
					{columnType === ECustomColumnType.Recommendation && (
						<RecommendationSettings
							state={[recommendationData, onChangeRecommendationData]}
							recommendationTypes={config[columnType]?.metadata.recommendationTypes}
							applications={config[columnType]?.metadata.applications}
						/>
					)}
					{columnType === ECustomColumnType.ApplicationParameter && (
						<ApplicationParameterSettings
							state={[applicationParameterData, onChangeApplicationParameterData]}
							closedLoopSettings={config[columnType]?.metadata.closedLoopSettings}
							parameters={config[columnType]?.metadata.parameters}
							applications={config[columnType]?.metadata.applications}
						/>
					)}
					{columnType === ECustomColumnType.ScheduleApplicationParameters && (
						<ScheduleApplicationParametersSettings
							state={[
								scheduleApplicationParametersData,
								onChangeScheduleApplicationParametersData
							]}
						/>
					)}
				</ColumnFormSection>
			</div>
			<div className={styles.ColumnOptionsContent}>
				<ColumnFormSection
					label={COLUMN_OPTIONS_EXPANDABLE_LABEL}
					disabled={areExpandablesDisabled}
					ref={settingsExpandableRef}
					defaultState={initiallyExpanded}>
					<KvTextField
						label={COLUMN_TITLE_LABEL}
						placeholder={COLUMN_TITLE_PLACEHOLDER}
						onTextChange={onChangeTitle}
						value={columnTitle}
						helpText={titleError}
						state={titleError ? EValidationState.Invalid : EValidationState.None}
					/>
					<div className={styles.Suggestion} onClick={applySuggestionTitle}>
						{`${COLUMN_TITLE_SUGGESTION_PREFIX} ${titleSuggestion}`}
					</div>
					<div className={styles.CheckboxContent}>
						<KvCheckbox checked={columnOptions?.hide} onClickCheckbox={onChangeHide} />
						<span>{COLUMN_OPTIONS_HIDE_LABEL}</span>
					</div>
				</ColumnFormSection>
			</div>
		</div>
	);
};
