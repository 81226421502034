import {
	EKrnResource,
	EPaginatorType,
	Guardrail,
	GuardrailsService,
	KvKRNParser,
	KvNodeHttpRequestOptions
} from '@kelvininc/node-client-sdk';
import {
	Datasource,
	IAbortableDatasourcePlugin,
	withAbortRequestController,
	withDataPolling,
	withPreviousPageFallback
} from '@kelvininc/table';
import { compose } from '@kelvininc/tsutils';
import { useMemo } from 'react';

export const useDatasource = (): Datasource<Guardrail, IAbortableDatasourcePlugin> => {
	return useMemo(
		() =>
			compose<Datasource<Guardrail, IAbortableDatasourcePlugin>>(
				withPreviousPageFallback,
				withAbortRequestController,
				withDataPolling
			)(({ page, pageSize, sortBy, sortDirection, filters = {}, search, signal }) => {
				const assetsFilter = (filters.assets as string[]) ?? [];
				const datastreamsFilter = (filters.datastreams as string[]) ?? [];

				const assets = assetsFilter.map((asset) =>
					KvKRNParser.buildKRN(EKrnResource.Asset, { asset })
				);
				const datastreams = datastreamsFilter.map((datastream) =>
					KvKRNParser.buildKRN(EKrnResource.Datastream, { datastream })
				);

				return GuardrailsService.listGuardrails(
					{
						guardrailsListData: {
							control_disabled: filters?.control
								? filters?.control === 'disabled'
								: undefined,
							resources: [...assets, ...datastreams]
						},
						paginationType: EPaginatorType.Limits,
						sortBy,
						direction: sortDirection,
						page,
						pageSize,
						search
					},
					{
						signal
					} as KvNodeHttpRequestOptions<RequestInit>
				).toPromise();
			}),
		[]
	);
};
