import { AppItem, ParameterDefinitionItem } from '@kelvininc/node-client-sdk';
import { APPLICATION_CLOSED_LOOP_KEY } from '@kelvininc/shared-ui';
import { IApplicationParameterDefinition, IClosedLoopSettings } from '@kelvininc/types';

export const getApplicationsList = (
	applications: Record<string, AppItem>,
	parameters: IApplicationParameterDefinition
): AppItem[] => {
	return Object.keys(parameters).map((applicationName) => applications[applicationName]);
};

export const getParametersList = ({
	application,
	parameters,
	closedLoopSettings
}: {
	application?: string;
	parameters: IApplicationParameterDefinition;
	closedLoopSettings: IClosedLoopSettings;
}): ParameterDefinitionItem[] => {
	if (!application) {
		return [];
	}

	let appParameters = parameters[application] ?? {};
	if (appParameters[APPLICATION_CLOSED_LOOP_KEY]) {
		appParameters = {
			...appParameters,
			[APPLICATION_CLOSED_LOOP_KEY]: new ParameterDefinitionItem({
				...appParameters[APPLICATION_CLOSED_LOOP_KEY].toJSONObject(),
				last_title: closedLoopSettings.displayLabel
			})
		};
	}

	return Object.values(appParameters ?? {});
};
