import { FunctionComponent } from '@kelvininc/types';
import { ComponentProps } from 'react';

import { Boundaries, WizardErrorFallback } from '../../components';

export const withWizardBoundariesConfig =
	<Props,>(
		boundariesProps: ComponentProps<typeof Boundaries> = {}
	): ((
		Component: FunctionComponent<Props & JSX.IntrinsicAttributes>
	) => FunctionComponent<Props & JSX.IntrinsicAttributes>) =>
	(Component) => {
		return function BoundariesWrapper(
			componentProps: ComponentProps<typeof Component>
		): JSX.Element {
			return (
				<Boundaries ErrorFallbackComponent={<WizardErrorFallback />} {...boundariesProps}>
					<Component {...componentProps} />
				</Boundaries>
			);
		};
	};

export const withWizardBoundaries = <Props,>(
	Component: FunctionComponent<Props & JSX.IntrinsicAttributes>
): FunctionComponent<Props & JSX.IntrinsicAttributes> => {
	return withWizardBoundariesConfig<Props & JSX.IntrinsicAttributes>()(Component);
};
