export const CREATE_IMAGE_TIP_DESCRIPTION =
	'If the workload is deleted, you will need to create a new workload image using this process.';

export const CREATING_IMAGE_INFO = {
	title: 'Your workload image is currently being generated.',
	description: 'Feel free to leave this page and come back at a later time to download the file.'
};

export const IMAGE_READY_INFO = {
	title: 'Your workload image is ready to be downloaded.',
	description:
		'After downloading, use the Kelvin Edge UI to connect to your cluster and start the deployment process.'
};
