'use client';
import { ITabNavigationItem } from '@kelvininc/react-ui-components';
import {
	BreadcrumbItem,
	ExpandableButton,
	NavLinks,
	NavLinksSkeleton,
	PersistentExpandableProvider,
	Skeleton,
	useExpandable
} from '@kelvininc/shared-ui';
import { buildUrl } from '@kelvininc/tsutils';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { usePathname, useSearchParams } from 'next/navigation';
import { PropsWithChildren } from 'react';

import { EntityModelHeader, EntityModelHeaderProps, EntityModelTopbar } from './components';

import styles from './styles.module.scss';

import { useNavigate } from '@/src/hooks';
import { entityModelExpandedHeaderUserSettingAtomFamily } from '@/src/recoil/user-settings/atoms';
import { ENTITY_MODEL_EXPANDABLE_HEADER_USER_SETTING_DEFAULT } from '@/src/recoil/user-settings/config';
import { getEntityModelExpandableHeaderUserSettingKey } from '@/src/recoil/user-settings/utils';

type EntityModelSkeletonProps = {
	customClasses?: ClassNamesProp;
};

export const EntityModelSkeleton = ({ customClasses }: EntityModelSkeletonProps) => {
	return (
		<div className={classNames(styles.EntityViewerSkeleton, customClasses)}>
			<div className={styles.Topbar}>
				<Skeleton />
			</div>
			<div className={styles.Title}>
				<Skeleton />
			</div>
			<div className={styles.Info}>
				<Skeleton />
				<Skeleton />
			</div>
			<div className={styles.Info}>
				<Skeleton />
				<Skeleton />
			</div>
			<div className={styles.Tabs}>
				<NavLinksSkeleton amount={2} />
			</div>
			<div className={styles.ActionRow}>
				<Skeleton />
			</div>
			<div className={styles.Panel}>
				<Skeleton />
			</div>
		</div>
	);
};

type NavigationTabsProps = {
	tabItems?: ITabNavigationItem[];
	onTabClick?: (tabKey: string) => void;
};

const NavigationTabs = ({ tabItems, onTabClick }: NavigationTabsProps) => {
	const { isExpanded, toggle } = useExpandable();
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const navigate = useNavigate();

	return (
		<div className={styles.NavigationTabsWrapper}>
			{tabItems && !isEmpty(tabItems) && (
				<NavLinks
					items={tabItems}
					selectedTab={decodeURIComponent(buildUrl(pathname, searchParams))}
					onClickTab={(tab) => {
						navigate(tab);
						onTabClick?.(tab);
					}}
				/>
			)}
			<ExpandableButton
				isExpanded={isExpanded}
				onButtonClick={toggle}
				customClasses={classNames(styles.ExpandableButton, {
					[styles.ExpandableButtonEmptyTabs]: isEmpty(tabItems)
				})}
			/>
		</div>
	);
};

type EntityModelViewerProps = {
	id: string;
	breadcrumbItems: BreadcrumbItem[];
	tabItems?: ITabNavigationItem[];
	headerProps: EntityModelHeaderProps;
	onTabClick?: (tabKey: string) => void;
};

export const EntityModelViewer = ({
	id,
	breadcrumbItems,
	tabItems,
	headerProps,
	children,
	onTabClick
}: EntityModelViewerProps & PropsWithChildren<{}>) => {
	return (
		<>
			<EntityModelTopbar items={breadcrumbItems} />
			<div className={styles.EntityModelViewContainer} data-analytics-id="entity">
				<PersistentExpandableProvider
					defaultState={ENTITY_MODEL_EXPANDABLE_HEADER_USER_SETTING_DEFAULT}
					statePersistanceKey={getEntityModelExpandableHeaderUserSettingKey(id)}
					recoilState={entityModelExpandedHeaderUserSettingAtomFamily(id)}>
					<EntityModelHeader
						customClasses={classNames(styles.EntityModelViewHeader, {
							[styles.NoTabs]: isEmpty(tabItems)
						})}
						{...headerProps}
					/>
					<NavigationTabs tabItems={tabItems} onTabClick={onTabClick} />
					<div className={styles.EntityModelViewContent}>{children}</div>
				</PersistentExpandableProvider>
			</div>
		</>
	);
};
