import { AppItem } from '@kelvininc/node-client-sdk';

import { EComponentSize, KvSingleSelectDropdown } from '@kelvininc/react-ui-components';
import { useDropdownSearchOptions } from '@kelvininc/shared-ui';
import { IApplicationParameterDefinition } from '@kelvininc/types';
import { useMemo } from 'react';

import {
	DEFAULT_DROPDOWN_FILTER_MAX_WIDTH,
	DEFAULT_DROPDOWN_FILTER_MIN_WIDTH
} from '../../../../../../../../../../../config';
import { IApplicationParameterTableAdvancedFilter } from '../../../../../../../../../../../core/components/AgGridTable/types';
import { DraftAdvancedFilter } from '../../../../../../../../contexts/TableAdvancedFiltersContext/types';
import {
	SELECT_APPLICATION_DROPDOWN_OPTIONS,
	SELECT_APPLICATION_NO_DATA_AVAILABLE_CONFIG,
	SELECT_APPLICATION_PLACEHOLDER,
	SELECT_APPLICATION_SEARCH_PLACEHOLDER
} from '../../config';

import { USE_DROPDOWN_OPTIONS } from './config';
import styles from './styles.module.scss';

type ApplicationDropdownProps = {
	application?: string;
	filter: DraftAdvancedFilter<IApplicationParameterTableAdvancedFilter>;
	onChangeApplication: (newApplication: string) => void;
	parameters: IApplicationParameterDefinition;
	definitions: Record<string, AppItem>;
};

export const ApplicationDropdown = ({
	application,
	parameters,
	definitions,
	onChangeApplication
}: ApplicationDropdownProps) => {
	const onlyAppsWithParams = useMemo(
		() => Object.keys(parameters).map((appName) => definitions[appName]),
		[definitions, parameters]
	);

	const { options, filteredOptions, setSearchTerm } = useDropdownSearchOptions(
		onlyAppsWithParams,
		USE_DROPDOWN_OPTIONS
	);

	return (
		<KvSingleSelectDropdown
			className={styles.ApplicationInput}
			options={options}
			dropdownOptions={SELECT_APPLICATION_DROPDOWN_OPTIONS}
			placeholder={SELECT_APPLICATION_PLACEHOLDER}
			noDataAvailableConfig={SELECT_APPLICATION_NO_DATA_AVAILABLE_CONFIG}
			searchPlaceholder={SELECT_APPLICATION_SEARCH_PLACEHOLDER}
			filteredOptions={filteredOptions}
			selectedOption={application}
			onSearchChange={({ detail: newSearchTerm }) => setSearchTerm(newSearchTerm)}
			onOptionSelected={({ detail: newApplication }) => onChangeApplication(newApplication)}
			inputSize={EComponentSize.Small}
			maxWidth={DEFAULT_DROPDOWN_FILTER_MAX_WIDTH}
			minWidth={DEFAULT_DROPDOWN_FILTER_MIN_WIDTH}
		/>
	);
};
