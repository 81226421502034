import { pick } from 'lodash-es';

import { IAssetPropertyTableAdvancedFilter } from '../../../../../../../AgGridTable';
import { DraftAdvancedFilter } from '../../../../../../contexts/TableAdvancedFiltersContext/types';

import { ASSET_PROPERTY_SCHEMA_FORM_PROPERTIES } from './config';

import { AssetPropertyAdvancedFilterSchemaFormData } from './types';

export const getSchemaFormData = (
	filter: DraftAdvancedFilter<IAssetPropertyTableAdvancedFilter>
): AssetPropertyAdvancedFilterSchemaFormData => {
	return pick(filter, ASSET_PROPERTY_SCHEMA_FORM_PROPERTIES);
};
