import {
	EKvHttpStatusCode,
	EWorkloadDownloadStatus,
	IKvHttpResponse,
	WorkloadService
} from '@kelvininc/node-client-sdk';
import {
	EActionButtonType,
	EIconName,
	KvActionButtonText,
	KvIcon,
	KvLoader
} from '@kelvininc/react-ui-components';
import { Tip, closeModal, openToaster } from '@kelvininc/shared-ui';
import { PropsWithChildren, useCallback, useMemo } from 'react';

import { WORKLOAD_OPERATION_ERROR_TOASTER } from '../../hooks/useWorkloadController/config';

import { CREATE_IMAGE_TIP_DESCRIPTION, CREATING_IMAGE_INFO, IMAGE_READY_INFO } from './config';
import { useCreateImageState } from './hooks';
import styles from './styles.module.scss';
import { buildCreateImageDescription } from './utils';

export type CreateImageModalProps = {
	workloadName: string;
	clusterName: string;
	onClickDownload: (workloadName: string) => void;
};

export const CreateImageModal = ({
	workloadName,
	clusterName
}: PropsWithChildren<CreateImageModalProps>) => {
	const createImageState = useCreateImageState(workloadName);

	const onDownloadImage = useCallback(
		() =>
			WorkloadService.downloadWorkload({
				workloadName,
				address: true
			})
				.toPromise()
				.then((data: IKvHttpResponse) => {
					if (data) {
						if (data.status === EKvHttpStatusCode.Ok) {
							data.body.text().then((text: string) => {
								// Todo!: Replace all usages of downloadWorkload using blob (application/zip)
								const url: string = JSON.parse(text).url;
								window.open(url, '_blank');
							});
						}
						return;
					}
					openToaster(WORKLOAD_OPERATION_ERROR_TOASTER);
				})
				.catch(() => openToaster(WORKLOAD_OPERATION_ERROR_TOASTER)),
		[workloadName]
	);

	const { title, description } = useMemo(() => {
		return createImageState === EWorkloadDownloadStatus.Ready
			? IMAGE_READY_INFO
			: CREATING_IMAGE_INFO;
	}, [createImageState]);
	return (
		<div>
			<div className={styles.CreateImageContent}>
				<div className={styles.Description}>{buildCreateImageDescription(clusterName)}</div>
				<Tip customClass={styles.Tip} description={CREATE_IMAGE_TIP_DESCRIPTION} />
				<div className={styles.ImageStateContainer}>
					<div className={styles.State}>
						{createImageState === EWorkloadDownloadStatus.Ready ? (
							<KvIcon name={EIconName.Success} customClass="icon-24" />
						) : (
							<KvLoader isLoading />
						)}
					</div>
					<div className={styles.StateInfoContainer}>
						<div className={styles.Title}>{title}</div>
						<div className={styles.Description}>{description}</div>
						<KvActionButtonText
							text="Download Image"
							type={EActionButtonType.Tertiary}
							icon={EIconName.Download}
							onClickButton={onDownloadImage}
							disabled={createImageState !== EWorkloadDownloadStatus.Ready}
						/>
					</div>
				</div>
			</div>
			<div data-test-id="e2e-modal-actions" className={styles.CreateImageFooter}>
				<KvActionButtonText
					key="close"
					text="Close"
					type={EActionButtonType.Primary}
					onClickButton={() => closeModal()}
				/>
			</div>
		</div>
	);
};
