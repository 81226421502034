import { isNil } from 'lodash-es';

import { IBaseTData, ICellRendererParams } from '../../../core/components/AgGridTable/types';

export const isCellClickable = <TData extends IBaseTData>({
	onClick,
	getLink,
	value,
	valueFormatted,
	data,
	column,
	api,
	context
}: Pick<
	ICellRendererParams<TData>,
	'onClick' | 'getLink' | 'value' | 'valueFormatted' | 'data' | 'column' | 'api' | 'context'
>): boolean => {
	const formattedValue = valueFormatted ?? value;
	const link = getLink?.(
		formattedValue,
		data as TData,
		column?.getColId() as string,
		api,
		context
	);

	return !isNil(onClick) || !isNil(link);
};
