import {
	BulkGuardrailsCreate,
	Guardrail,
	IBulkGuardrailsDeleteData,
	IKvHttpResponse
} from '@kelvininc/node-client-sdk';

export enum EGuardrailOperationType {
	Edit = 'edit',
	Delete = 'delete',
	Create = 'create',
	Import = 'import',
	BulkDelete = 'bulk-delete'
}

type GuardrailOperationsMap = {
	[EGuardrailOperationType.Edit]: {
		type: EGuardrailOperationType.Edit;
		guardrail: Guardrail;
	};
	[EGuardrailOperationType.Delete]: {
		type: EGuardrailOperationType.Delete;
		guardrail: Guardrail;
	};
	[EGuardrailOperationType.Create]: {
		type: EGuardrailOperationType.Create;
	};
	[EGuardrailOperationType.Import]: {
		type: EGuardrailOperationType.Import;
	};
	[EGuardrailOperationType.BulkDelete]: {
		type: EGuardrailOperationType.BulkDelete;
		payload: IBulkGuardrailsDeleteData;
	};
};

export type GuardrailOperation = GuardrailOperationsMap[EGuardrailOperationType];

export type GuardrailOperationResultMap = {
	[EGuardrailOperationType.Edit]: Guardrail;
	[EGuardrailOperationType.Delete]: IKvHttpResponse;
	[EGuardrailOperationType.Create]: Guardrail;
	[EGuardrailOperationType.Import]: BulkGuardrailsCreate;
	[EGuardrailOperationType.BulkDelete]: IKvHttpResponse;
};

export type GuardrailOperationResult = GuardrailOperationResultMap[EGuardrailOperationType];
