import {
	AssetDatastreamKRN,
	DataStream,
	EDataType,
	EDatastreamExtraFieldComputationDataAgg,
	EKrnResource,
	IAssetKrnResource,
	ISelectorData,
	TimeseriesData,
	TimeseriesService
} from '@kelvininc/node-client-sdk';
import { SchemaFormProps } from '@kelvininc/react-ui-components';
import { getResourceContent } from '@kelvininc/tsutils';
import { DataStreamAggregationFunction, EDataStreamCustomAgg } from '@kelvininc/types';
import { merge, uniq } from 'lodash-es';
import { of } from 'rxjs';

import {
	PRIMITIVE_TYPE_BOOLEAN_FILTER_SCHEMA,
	PRIMITIVE_TYPE_NUMBER_FILTER_SCHEMA,
	PRIMITIVE_TYPE_STRING_FILTER_SCHEMA
} from '../../../../config';

import { DatastreamAdvancedFilterSchemaFormData } from '../../types';
import { isSetpointSemanticType } from '../../utils';

import { STRING_DATASTREAM_PROPERTY_SCHEMA, TIME_RANGE_PROPERTY_SCHEMA } from './config';

export const buildNumberSchemaForm = (
	datastream: DataStream,
	agg?: DataStreamAggregationFunction
): SchemaFormProps<DatastreamAdvancedFilterSchemaFormData>['schema'] => {
	if (!agg || agg === EDataStreamCustomAgg.Last) {
		return PRIMITIVE_TYPE_NUMBER_FILTER_SCHEMA;
	}

	if (isSetpointSemanticType(datastream)) {
		return PRIMITIVE_TYPE_NUMBER_FILTER_SCHEMA;
	}

	return TIME_RANGE_PROPERTY_SCHEMA;
};

export const getAssetName = (resource: string): string => {
	const { asset } = getResourceContent<EKrnResource.Asset>(resource) as IAssetKrnResource;

	return asset;
};

export const buildTimeseriesSelector = (
	assetResources: string[],
	datastream: string
): ISelectorData[] => {
	return assetResources.map((resource) => ({
		resource: AssetDatastreamKRN.toKRN({
			datastream: datastream,
			asset: getAssetName(resource)
		})
	}));
};

export const getLastDatastreamValues = (assetResources: string[], datastream: string) => {
	if (assetResources.length === 0) {
		return of([]);
	}

	return TimeseriesService.getTimeseriesLast({
		timeseriesLastGetData: {
			selectors: buildTimeseriesSelector(assetResources, datastream)
		}
	});
};

export const buildSchemaFormWithLastValues = (data: TimeseriesData[]) => {
	if (data.length === 0) {
		return PRIMITIVE_TYPE_STRING_FILTER_SCHEMA;
	}

	const oneOf = uniq(data.map(({ lastValue }) => lastValue)).map((value) => ({
		const: value,
		title: `${value}`
	}));

	return merge({}, STRING_DATASTREAM_PROPERTY_SCHEMA, {
		definitions: {
			string_options_value: {
				type: 'array',
				items: {
					type: 'string',
					oneOf
				},
				title: '',
				uniqueItems: true
			}
		}
	});
};

export const buildSchemaForm = (
	datastream: DataStream,
	agg?: DataStreamAggregationFunction
): SchemaFormProps<DatastreamAdvancedFilterSchemaFormData>['schema'] => {
	if (
		datastream.dataTypeName === EDataType.String &&
		agg === EDatastreamExtraFieldComputationDataAgg.Count
	) {
		return buildNumberSchemaForm(datastream, agg);
	}

	switch (datastream.dataTypeName) {
		case EDataType.String:
			return PRIMITIVE_TYPE_STRING_FILTER_SCHEMA;
		case EDataType.Boolean:
			return PRIMITIVE_TYPE_BOOLEAN_FILTER_SCHEMA;
		case EDataType.Number:
			return buildNumberSchemaForm(datastream, agg);
		default:
			throw new Error(`Unsupported data type: ${datastream.dataTypeName}`);
	}
};
