'use client';
import { AppItem, AppVersion, EAppType } from '@kelvininc/node-client-sdk';
import { get, isEmpty } from 'lodash-es';
import { useMemo } from 'react';

import { DeployAppScopeWorkloadData, DeployWorkloadData } from '../types';

export const useAppPayload = (
	applications: Record<string, AppItem>,
	data: Partial<DeployAppScopeWorkloadData | DeployWorkloadData>,
	appName?: string,
	appPayload?: AppVersion
): [boolean, object | undefined] => {
	const isSelectedAppBridgeType = useMemo(() => {
		if (!appName) return false;

		return applications[appName]?.type === EAppType.Bridge;
	}, [applications, appName]);

	const payloadObject = useMemo(() => {
		const payload = isEmpty(data.payload) ? appPayload?.payload : data.payload;

		if (isSelectedAppBridgeType) {
			return get(payload, 'app.bridge', data.payload);
		}

		return payload;
	}, [data.payload, appPayload?.payload, isSelectedAppBridgeType]);

	return [isSelectedAppBridgeType, payloadObject];
};
