import { Property } from '@kelvininc/node-client-sdk';

import { KvSingleSelectDropdown } from '@kelvininc/react-ui-components';
import { useDropdownSearchOptions } from '@kelvininc/shared-ui';
import { ReactState } from '@kelvininc/types';
import { useCallback, useMemo } from 'react';

import { IAssetPropertyColumnData } from '../../../../../../../AgGridTable';

import {
	ASSET_PROPERTY_COLUMN_LABEL,
	ASSET_PROPERTY_COLUMN_PLACEHOLDER,
	USE_DROPDOWN_OPTIONS
} from './config';

type AssetPropertySettings = {
	state: ReactState<IAssetPropertyColumnData>;
	properties?: Record<string, Property>;
};

export const AssetPropertySettings = ({ state, properties = {} }: AssetPropertySettings) => {
	const [settings, setState] = state;

	const data = useMemo(() => Object.values(properties), [properties]);
	const { options, filteredOptions, setSearchTerm } = useDropdownSearchOptions(
		data,
		USE_DROPDOWN_OPTIONS
	);
	const onSelectProperty = useCallback(
		({ detail: newPropertyName }: CustomEvent<string>) =>
			setState({ property: newPropertyName }),
		[setState]
	);
	const onSearchChange = useCallback(
		({ detail: searchedAssetProperty }: CustomEvent<string>) =>
			setSearchTerm(searchedAssetProperty),
		[setSearchTerm]
	);

	return (
		<div>
			<KvSingleSelectDropdown
				required
				placeholder={ASSET_PROPERTY_COLUMN_PLACEHOLDER}
				label={ASSET_PROPERTY_COLUMN_LABEL}
				options={options}
				filteredOptions={filteredOptions}
				selectedOption={settings.property}
				onOptionSelected={onSelectProperty}
				onSearchChange={onSearchChange}
			/>
		</div>
	);
};
