import { EIllustrationName } from '@kelvininc/react-ui-components';

import { BaseOverlay } from '../BaseOverlay';

import {
	NO_RESULTS_OVERLAY_DESCRIPTION,
	NO_RESULTS_OVERLAY_ILLUSTRATION,
	NO_RESULTS_OVERLAY_TITLE
} from './config';
import styles from './styles.module.scss';

type NoResultsOverlayProps = {
	title?: string;
	description?: string;
	illustration?: EIllustrationName;
};

export const NoResultsOverlay = ({
	title = NO_RESULTS_OVERLAY_TITLE,
	description = NO_RESULTS_OVERLAY_DESCRIPTION,
	illustration = NO_RESULTS_OVERLAY_ILLUSTRATION
}: NoResultsOverlayProps) => {
	return (
		<BaseOverlay
			title={title}
			description={description}
			illustration={illustration}
			customClasses={styles.NoResultsContainer}
		/>
	);
};
