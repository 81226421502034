import {
	Guardrail,
	GuardrailAllOfNumberAllOfMin,
	GuardrailRelativeValue,
	GuardrailValueUpdater
} from '@kelvininc/node-client-sdk';

import { EGuardrailCellValueType } from './types';

export const isGuardrailValueTypeAbsolute = (
	type: EGuardrailCellValueType
): type is EGuardrailCellValueType.Min | EGuardrailCellValueType.Max => {
	return [EGuardrailCellValueType.Min, EGuardrailCellValueType.Max].includes(type);
};

export const getGuardrailAbsoluteValue = (
	guardrail: Guardrail,
	type: EGuardrailCellValueType.Min | EGuardrailCellValueType.Max
): GuardrailAllOfNumberAllOfMin | undefined => {
	return type === EGuardrailCellValueType.Min ? guardrail.number?.min : guardrail.number?.max;
};

export const getGuardrailUpdater = (
	guardrail: Guardrail,
	type: EGuardrailCellValueType.Min | EGuardrailCellValueType.Max
): GuardrailValueUpdater | undefined => {
	return type === EGuardrailCellValueType.Min
		? guardrail.updater?.number?.min
		: guardrail.updater?.number?.max;
};

export const getGuardrailRelativeValue = (
	guardrail: Guardrail,
	type:
		| EGuardrailCellValueType.DecreaseMax
		| EGuardrailCellValueType.DecreaseMin
		| EGuardrailCellValueType.IncreaseMax
		| EGuardrailCellValueType.IncreaseMin
): GuardrailRelativeValue | undefined => {
	return {
		[EGuardrailCellValueType.DecreaseMax]: guardrail.number?.relative?.decrease?.max,
		[EGuardrailCellValueType.DecreaseMin]: guardrail.number?.relative?.decrease?.min,
		[EGuardrailCellValueType.IncreaseMax]: guardrail.number?.relative?.increase?.max,
		[EGuardrailCellValueType.IncreaseMin]: guardrail.number?.relative?.increase?.min
	}[type];
};
