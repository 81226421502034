import { ParsedUrlQuery } from 'querystring';

import { EDataExplorerQueryParams, EDataSourceScope } from '@kelvininc/data-explorer';
import { buildUrl, convertToSearchParams } from '@kelvininc/tsutils';
import { URLSearchParamsInit } from '@kelvininc/types';

import { OPERATIONS_ROUTE_BASE_PATH } from '../config';

const ROUTE_PATH = `${OPERATIONS_ROUTE_BASE_PATH}/data-explorer`;

export const buildDataExplorerRoute = (query: URLSearchParamsInit): string =>
	buildUrl(ROUTE_PATH, query);

export const buildDataExplorerAssetRoute = (assetName: string) =>
	buildDataExplorerAssetsRoute([assetName]);

export const buildDataExplorerAssetsRoute = (assetNames: string[]) => {
	const queryParams = {
		[EDataExplorerQueryParams.Scope]: EDataSourceScope.All,
		[EDataExplorerQueryParams.Assets]: assetNames
	};

	return buildDataExplorerRoute(convertToSearchParams(queryParams));
};

export const buildDataExplorerApplicationsRoute = (appName: string, assetNames?: string[]) => {
	const queryParams: ParsedUrlQuery = {
		[EDataExplorerQueryParams.Scope]: EDataSourceScope.Applications,
		[EDataExplorerQueryParams.ScopeName]: appName
	};

	if (assetNames) {
		queryParams[EDataExplorerQueryParams.Assets] = assetNames;
	}

	return buildDataExplorerRoute(convertToSearchParams(queryParams));
};
