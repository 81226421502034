import {
	EPaginatorType,
	OrchestrationClustersCreateItem,
	OrchestrationService
} from '@kelvininc/node-client-sdk';
import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { keyBy } from 'lodash-es';
import { selector } from 'recoil';

export const clustersListSelector = selector<OrchestrationClustersCreateItem[]>({
	key: 'clustersListSelector',
	get: () => {
		return OrchestrationService.listOrchestrationClusters({
			paginationType: EPaginatorType.Stream
		})
			.pipe(catchHttpError(throwHttpError))
			.toPromise();
	}
});

export const clustersMapSelector = selector<Record<string, OrchestrationClustersCreateItem>>({
	key: 'clustersMapSelector',
	get: ({ get }) => keyBy(get(clustersListSelector), 'name')
});
