import { PanelsManager, Sidepanel, useSidepanel } from '@kelvininc/shared-ui';
import { Dispatch } from 'react';

import { IBaseTData, IColumnDef } from '../../../AgGridTable';
import { useTableManagementStateContext } from '../../contexts';
import { useTableResetContext } from '../../contexts/TableResetContext';
import { EManagementPanel, TableProps } from '../../types';

import { AddColumnPanel, EditColumnPanel, ManagePanel } from './components';

import { SIDEPANEL_WIDTH_IN_PX } from './config';
import styles from './styles.module.scss';

import { getPanelConfig, getSidepanelWidthCssProperty } from './utils';

export type TableManagementSidepanelProps<TData extends IBaseTData> = {
	setColumnDefs: Dispatch<React.SetStateAction<IColumnDef<TData>[]>>;
} & Pick<
	TableProps<TData>,
	'columnDefs' | 'panelsConfig' | 'customColumnsConfig' | 'rowsSettings' | 'onRowsSettingsChange'
>;

export const TableManagementSidepanel = <TData extends IBaseTData>({
	columnDefs,
	setColumnDefs,
	panelsConfig,
	customColumnsConfig,
	rowsSettings,
	onRowsSettingsChange
}: TableManagementSidepanelProps<TData>) => {
	const { sidepanelKey } = useTableManagementStateContext();
	const { reset } = useTableResetContext();
	const { close } = useSidepanel();

	const panels = {
		[EManagementPanel.Manage]: (
			<ManagePanel
				columnDefs={columnDefs}
				columnsConfig={customColumnsConfig}
				panelConfig={getPanelConfig(EManagementPanel.Manage, panelsConfig)}
				onChange={setColumnDefs}
				rowsSettings={rowsSettings}
				onRowsSettingsChange={onRowsSettingsChange}
				onResetSettings={reset}
			/>
		),
		...(customColumnsConfig
			? {
					[EManagementPanel.Add]: (
						<AddColumnPanel
							setColumnDefs={setColumnDefs}
							columnDefs={columnDefs}
							columnsConfig={customColumnsConfig}
							panelConfig={getPanelConfig(EManagementPanel.Add, panelsConfig)}
						/>
					),
					[EManagementPanel.Edit]: (
						<EditColumnPanel
							setColumnDefs={setColumnDefs}
							columnDefs={columnDefs}
							columnsConfig={customColumnsConfig}
							panelConfig={getPanelConfig(EManagementPanel.Edit, panelsConfig)}
						/>
					)
				}
			: {})
	};

	return (
		<Sidepanel
			customClasses={styles.Sidepanel}
			style={getSidepanelWidthCssProperty()}
			name={sidepanelKey}
			useOverlay
			onClickOverlay={() => close(sidepanelKey)}>
			<PanelsManager
				initialPanel={EManagementPanel.Manage}
				width={SIDEPANEL_WIDTH_IN_PX}
				panels={panels}
			/>
		</Sidepanel>
	);
};
