'use client';
import { EIconName, KvIcon } from '@kelvininc/react-ui-components';
import { Link, useNavigationSideBar } from '@kelvininc/shared-ui';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';

import Confetti from 'react-dom-confetti';

import { StepContent } from './components';
import {
	TUTORIAL_CONFETTI_CONFIG,
	TUTORIAL_DESCRIPTION,
	TUTORIAL_HEADER,
	TUTORIAL_HEADER_SUCCESS,
	TUTORIAL_SIDEBAR_LEFT_OFFSET,
	TUTORIAL_SKIP_TEXT
} from './config';
import styles from './styles.module.scss';
import { IExperienceGuideStep } from './types';
import { getHeaderActionIcon } from './utils';

import { getFadeAnimationProps } from '@/src/utils/motion';

export type ExperienceGuidePortalProps = {
	title?: string;
	description?: string;
	steps: IExperienceGuideStep[];
	stepStates?: Record<string, boolean>;
	isExpanded: boolean;
	isCompleted?: boolean;
	customClasses?: ClassNamesProp;
	onHeaderActionClick: () => void;
	onTutorialSkip: () => void;
	onStepClick?: (id: string) => void;
};

export const ExperienceGuidePortal = ({
	title,
	description = TUTORIAL_DESCRIPTION,
	steps,
	stepStates = {},
	isCompleted = false,
	isExpanded,
	customClasses,
	onHeaderActionClick,
	onStepClick,
	onTutorialSkip
}: ExperienceGuidePortalProps) => {
	const {
		widthState: [{ width }]
	} = useNavigationSideBar();
	const tutorialLeftOffset = width + TUTORIAL_SIDEBAR_LEFT_OFFSET;

	return createPortal(
		<motion.div
			{...getFadeAnimationProps()}
			className={classNames(styles.ExperienceGuide, customClasses, {
				[styles.Expanded]: isExpanded,
				[styles.Completed]: isCompleted
			})}
			style={{ left: tutorialLeftOffset }}>
			<div className={styles.Header}>
				<div
					className={classNames(styles.HeaderTitle, {
						[styles.Expanded]: isExpanded
					})}>
					<KvIcon
						name={EIconName.TaskCheckmark}
						customClass={!isExpanded ? 'icon-20' : 'icon-24'}
					/>
					{title ?? (!isCompleted ? TUTORIAL_HEADER : TUTORIAL_HEADER_SUCCESS)}
				</div>
				<div className={styles.HeaderAction} onClick={onHeaderActionClick}>
					<KvIcon
						name={getHeaderActionIcon(isCompleted, isExpanded)}
						customClass={'icon-24'}
					/>
				</div>
			</div>
			<div className={styles.Content}>
				{!isCompleted && <div className={styles.DescriptionText}>{description}</div>}
				<div className={styles.Steps}>
					{steps.map((step) => (
						<StepContent
							key={step.id}
							step={step}
							stepState={stepStates[step.id]}
							onStepClick={onStepClick}
						/>
					))}
				</div>
				{!isCompleted && (
					<div className={styles.SkipExperience}>
						<Link className={styles.LinkText} onClick={onTutorialSkip}>
							{TUTORIAL_SKIP_TEXT}
						</Link>
					</div>
				)}
			</div>
			<div className={styles.ConfettiWrapper}>
				<Confetti active={isCompleted} config={TUTORIAL_CONFETTI_CONFIG} />
			</div>
		</motion.div>,
		document.body
	);
};
