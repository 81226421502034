import { ISelectSingleOptions } from '@kelvininc/react-ui-components';
import { buildDropdownOptionsFromArray } from '@kelvininc/tsutils';
import { isEmpty } from 'lodash-es';

import {
	ECustomColumnType,
	IApplicationParameterColumnData,
	IAssetPropertyColumnData,
	IBaseTData,
	IColumnDef,
	IDatastreamColumnData,
	ILastControlChangeColumnData,
	IRecommendationColumnData,
	IScheduleApplicationParametersColumnData
} from '../../../../../AgGridTable';
import { ICustomColumnsConfig } from '../../../../types';

import {
	APPLICATION_PARAMETER_CONTENT_TYPE_TITLE,
	ASSET_PROPERTY_CONTENT_TYPE_TITLE,
	DATASTREAM_CONTENT_TYPE_TITLE,
	LAST_CONTROL_CHANGE_CONTENT_TYPE_TITLE,
	RECOMMENDATION_CONTENT_TYPE_TITLE,
	SCHEDULE_APPLICATION_PARAMETERS_CONTENT_TYPE_TITLE
} from './config';

export const buildColumnTypesOptions = <TData extends IBaseTData>(
	config: ICustomColumnsConfig<TData>
): ISelectSingleOptions =>
	buildDropdownOptionsFromArray({
		data: Object.values(config),
		key: 'type',
		value: 'type',
		label: ({ type }) => getColumnTypeTitle(type as ECustomColumnType)
	});
export const getColumnTypeTitle = (contentType: ECustomColumnType): string =>
	({
		[ECustomColumnType.AssetProperty]: ASSET_PROPERTY_CONTENT_TYPE_TITLE,
		[ECustomColumnType.Datastream]: DATASTREAM_CONTENT_TYPE_TITLE,
		[ECustomColumnType.LastControlChange]: LAST_CONTROL_CHANGE_CONTENT_TYPE_TITLE,
		[ECustomColumnType.Recommendation]: RECOMMENDATION_CONTENT_TYPE_TITLE,
		[ECustomColumnType.ApplicationParameter]: APPLICATION_PARAMETER_CONTENT_TYPE_TITLE,
		[ECustomColumnType.ScheduleApplicationParameters]:
			SCHEDULE_APPLICATION_PARAMETERS_CONTENT_TYPE_TITLE
	})[contentType];

export const buildColumnTitleSuggestion = <TData extends IBaseTData>(
	columnType: ECustomColumnType | undefined,
	columnDefs: IColumnDef<TData>[],
	config: ICustomColumnsConfig<TData>,
	assetPropertyData: IAssetPropertyColumnData,
	datastreamData: IDatastreamColumnData,
	lastControlChangeData: ILastControlChangeColumnData,
	recommendationData: IRecommendationColumnData,
	applicationParameterData: IApplicationParameterColumnData,
	scheduleApplicationParametersData: IScheduleApplicationParametersColumnData
): string | undefined => {
	if (!columnType) {
		return;
	}

	const columnConfig = config[columnType];
	if (!columnConfig) {
		return;
	}

	const { type } = columnConfig;

	if (type === ECustomColumnType.AssetProperty) {
		if (!assetPropertyData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, metadata } = columnConfig;
		return (
			columnTitleBuilder?.(columnDefs, assetPropertyData, metadata) ??
			columnIdBuilder(columnDefs, assetPropertyData, metadata)
		);
	}

	if (type === ECustomColumnType.Datastream) {
		if (!datastreamData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, metadata } = columnConfig;
		return (
			columnTitleBuilder?.(columnDefs, datastreamData, metadata) ??
			columnIdBuilder(columnDefs, datastreamData, metadata)
		);
	}

	if (type === ECustomColumnType.LastControlChange) {
		if (!lastControlChangeData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, metadata } = columnConfig;
		return (
			columnTitleBuilder?.(columnDefs, lastControlChangeData, metadata) ??
			columnIdBuilder(columnDefs, lastControlChangeData, metadata)
		);
	}

	if (type === ECustomColumnType.Recommendation) {
		if (!recommendationData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, metadata } = columnConfig;
		return (
			columnTitleBuilder?.(columnDefs, recommendationData, metadata) ??
			columnIdBuilder(columnDefs, recommendationData, metadata)
		);
	}

	if (type === ECustomColumnType.ApplicationParameter) {
		if (!applicationParameterData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, metadata } = columnConfig;
		return (
			columnTitleBuilder?.(columnDefs, applicationParameterData, metadata) ??
			columnIdBuilder(columnDefs, applicationParameterData, metadata)
		);
	}

	if (type === ECustomColumnType.ScheduleApplicationParameters) {
		if (!scheduleApplicationParametersData) {
			return;
		}

		const { columnIdBuilder, columnTitleBuilder, metadata } = columnConfig;
		return (
			columnTitleBuilder?.(columnDefs, scheduleApplicationParametersData, metadata) ??
			columnIdBuilder(columnDefs, scheduleApplicationParametersData, metadata)
		);
	}
};

export const isSelectedColumnTypeValid = <TData extends IBaseTData>(
	columnType: ECustomColumnType,
	config: ICustomColumnsConfig<TData>
): boolean => {
	const columnConfig = config[columnType];
	if (!columnConfig) {
		return false;
	}

	if (columnConfig.type === ECustomColumnType.Datastream) {
		return !isEmpty(columnConfig.metadata.datastreams);
	}

	if (columnConfig.type === ECustomColumnType.AssetProperty) {
		return !isEmpty(columnConfig.metadata.properties);
	}

	if (columnConfig.type === ECustomColumnType.ApplicationParameter) {
		const {
			metadata: { application, parameters }
		} = columnConfig;
		if (isEmpty(parameters)) {
			return false;
		}

		if (application) {
			return !isEmpty(parameters[application]);
		}

		return true;
	}

	return true;
};
