'use client';

import { AppItem, EAppType } from '@kelvininc/node-client-sdk';
import { Link } from '@kelvininc/shared-ui';

import { getApplicationTypeTitle } from '@kelvininc/tsutils';

import styles from '../styles.module.scss';

type AlertModalDescriptionProps = {
	title: string;
	href: string;
	target: string;
	app: AppItem;
};

export const AlertModalDescription = ({ title, href, target, app }: AlertModalDescriptionProps) => {
	return (
		<div className={styles.AlertModalDescription}>
			{`To take full advantage of ${getApplicationTypeTitle(
				app
			)} applications please consider deploying this SmartApp
			through the ${
				app.type === EAppType.Kelvin ? 'SmartApp Management' : 'Connections'
			} interface. Click `}
			<Link href={href} title={title} target={target} className={styles.Link}>
				here
			</Link>
			{` to go the the ${
				app.type === EAppType.Kelvin ? 'SmartApp' : 'connections'
			} page. Proceed with the deployment if you are sure you are in
			the right place.`}
		</div>
	);
};
