'use client';

import {
	ComputePositionConfig,
	IRelativeTimePickerOption,
	ITimePickerRelativeTime,
	KvDropdown,
	KvRelativeTimePicker
} from '@kelvininc/react-ui-components';
import { useDropdown, useUpdate, useWindowSize, withDropdownProvider } from '@kelvininc/shared-ui';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { merge } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import {
	DEFAULT_RELATIVE_TIME_DROPDOWN_POSITION_OPTIONS,
	DEFAULT_RELATIVE_TIME_INPUT_CONFIG
} from './config';

import styles from './styles.module.scss';
import { getRelativeTimeLabel } from './utils';

type RelativeTimeDropdownProps = {
	disabled?: boolean;
	options?: IRelativeTimePickerOption[][];
	selectedItem?: string;
	customClasses?: ClassNamesProp;
	dropdownPositionOptions?: Partial<ComputePositionConfig>;
	onClickItem?: (item: ITimePickerRelativeTime) => void;
};

const Component = ({
	selectedItem,
	disabled = false,
	options,
	customClasses,
	dropdownPositionOptions = DEFAULT_RELATIVE_TIME_DROPDOWN_POSITION_OPTIONS,
	onClickItem
}: RelativeTimeDropdownProps) => {
	const { isOpen, close, setOpen } = useDropdown();
	const windowSize = useWindowSize();
	const inputConfig = useMemo(() => {
		return merge({}, DEFAULT_RELATIVE_TIME_INPUT_CONFIG, {
			value: getRelativeTimeLabel(selectedItem, options)
		});
	}, [options, selectedItem]);

	useUpdate(() => {
		close();
	}, [windowSize]);

	const openChange = useCallback(
		({ detail }: CustomEvent<boolean>) => {
			setOpen(detail);
		},
		[setOpen]
	);
	const onSelectedRelativeTime = useCallback(
		({ detail }: CustomEvent<ITimePickerRelativeTime>) => {
			onClickItem?.(detail);
			close();
		},
		[close, onClickItem]
	);

	return (
		<div className={classNames(styles.RelativeTimeDropdown, customClasses)}>
			<KvDropdown
				isOpen={isOpen}
				onOpenStateChange={openChange}
				inputConfig={inputConfig}
				options={dropdownPositionOptions}
				disabled={disabled}>
				<div className={styles.RelativeTimeDropdownPicker}>
					<KvRelativeTimePicker
						selectedTimeKey={selectedItem}
						options={options}
						customIntervalOptionEnabled={false}
						timezoneSelectionEnabled={false}
						onSelectedRelativeTimeChange={onSelectedRelativeTime}
					/>
				</div>
			</KvDropdown>
		</div>
	);
};

export const RelativeTimeDropdown = withDropdownProvider(Component);
