import { ASSETS_STATUS_INLINE_FILTER_NAME } from '@kelvininc/tsutils';
import { EClosedLoopMode, EInlineFilterType, IClosedLoopSettings } from '@kelvininc/types';

import {
	IAssetDetailSettings,
	IAssetTypeGroupSetting,
	IInlineFilterSetting,
	ISupportItemsSetting,
	IToggleFeatureSetting
} from './types';

export const ASSET_TYPE_GROUPS_INSTANCE_SETTING_KEY = 'core.ui.datastreams.asset-type.groups';
export const ASSET_TYPE_GROUPS_INSTANCE_SETTING_DEFAULT: Record<string, IAssetTypeGroupSetting[]> =
	{};

export const APPLICATION_PARAMETER_ORDER_KEY = 'core.ui.application.parameters.order';
export const APPLICATION_PARAMETER_ORDER_DEFAULT: Record<string, string[]> = {};

export const APPLICATION_HIDDEN_PARAMETERS_KEY = 'core.ui.application.parameters.hidden';
export const APPLICATION_HIDDEN_PARAMETERS_DEFAULT: Record<string, string[]> = {};

export const CLOSED_LOOP_SETTINGS_INSTANCE_SETTING_KEY = 'core.ui.closed-loop.settings';

export const SMART_APPS_CATALOG_INSTANCE_SETTING_KEY = 'core.ui.smartapps-catalog.settings';

export const SUPPORT_ITEMS_MENU_INSTANCE_SETTING_KEY = 'core.ui.support-items-menu.settings';

export const CLOSED_LOOP_SETTINGS_INSTANCE_SETTING_DEFAULT: IClosedLoopSettings = {
	displayLabel: 'Mode',
	mapping: {
		[EClosedLoopMode.True]: 'Closed Loop',
		[EClosedLoopMode.False]: 'Open Loop'
	}
};

export const ASSET_DETAIL_SETTINGS_SETTING_KEY = 'core.ui.asset-detail.settings';
export const ASSET_DETAIL_SETTINGS_SETTING_DEFAULT: IAssetDetailSettings = {
	properties: []
};

export const ASSETS_LIST_ASSET_TYPE_INLINE_FILTERS_KEY = 'core.ui.assets.inline_filters';
export const ASSETS_LIST_ASSET_TYPE_INLINE_FILTERS_DEFAULT: IInlineFilterSetting = {
	filters: [
		{
			name: ASSETS_STATUS_INLINE_FILTER_NAME,
			type: EInlineFilterType.Entity,
			multiple: true
		}
	]
};

export const APPLICATION_PARAMETER_COMMENT_REQUIRED_KEY =
	'core.ui.application.parameters.comment-required';
export const APPLICATION_PARAMETER_COMMENT_REQUIRED_DEFAULT: IToggleFeatureSetting = {
	enabled: true
};

export const SMART_APPS_CATALOG_SETTING_DEFAULT: IToggleFeatureSetting = {
	enabled: false
};

export const SUPPORT_ITEMS_MENU_SETTING_DEFAULT: ISupportItemsSetting = {
	items: []
};
