import { RefObject, useEffect } from 'react';

export const useSortAttributeSync = (
	columnRef: RefObject<HTMLDivElement>,
	isSorting: boolean,
	isSortAscending: boolean
) => {
	useEffect(() => {
		if (columnRef.current !== null) {
			const parentElement = columnRef.current.closest(
				'.ag-header-cell.ag-header-cell-sortable'
			);

			if (parentElement !== null) {
				if (isSorting) {
					parentElement.setAttribute(
						'aria-sort',
						isSortAscending ? 'ascending' : 'descending'
					);
				} else {
					parentElement.removeAttribute('aria-sort');
				}
			}
		}
	}, [columnRef, isSortAscending, isSorting]);
};
