import { SchemaFormProps } from '@kelvininc/react-ui-components';
import { convertToValidName } from '@kelvininc/tsutils';
import { IChangeEvent } from '@rjsf/core';
import { ErrorSchema, ErrorSchemaBuilder, FormValidation, RJSFValidationError } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { assign, isEmpty } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DeployWorkloadData, EDeployWorkloadType } from '../../../../types';
import { NAME_ERROR_MESSAGES, WORKLOAD_NAME_FORM_ID, WORKLOAD_TITLE_FORM_ID } from '../../config';

import { buildDeployWorkloadData } from '../../utils';
import { WorkloadFormData } from '../../WorkloadForm';

import { validateNameId } from '@/src/components/client/ValidatedTextField';
import { APP_REGEX } from '@/src/config';

export const useSchemaDataChange = (
	workloadSchema: SchemaFormProps<WorkloadFormData>['schema'] = {},
	data: WorkloadFormData,
	workloadNames: string[],
	flowType: EDeployWorkloadType,
	onChange?: (data: Partial<DeployWorkloadData>, valid: boolean) => void
) => {
	const [extraErrors, setExtraErrors] = useState<ErrorSchema<WorkloadFormData>>({});
	const [nameChanged, setNameChanged] = useState(
		!isEmpty(data.workloadInfo.name) &&
			convertToValidName(data.workloadInfo.title) !== data.workloadInfo.name
	);

	const workloadFormSchemaValidator = useCallback(
		(
			schema: SchemaFormProps<WorkloadFormData>['schema'],
			formData: WorkloadFormData
		): RJSFValidationError[] => {
			const { errors } = validator.validateFormData(
				formData,
				schema,
				(
					formDataToValidate: WorkloadFormData | undefined,
					validationErrors: FormValidation
				) => {
					if (
						flowType === EDeployWorkloadType.Deploy ||
						flowType === EDeployWorkloadType.AppScopeDeploy
					) {
						const { helpText } = validateNameId(
							formDataToValidate?.workloadInfo.name as string,
							NAME_ERROR_MESSAGES,
							APP_REGEX.name,
							workloadNames
						);

						if (helpText) {
							const errorSchemaBuilder = new ErrorSchemaBuilder();
							errorSchemaBuilder.addErrors(helpText, 'workloadInfo.name');

							setExtraErrors(errorSchemaBuilder.ErrorSchema);
							validationErrors.workloadInfo?.name?.addError(helpText);
						} else {
							setExtraErrors({});
						}
					}
					return validationErrors;
				}
			);
			return errors;
		},
		[flowType, workloadNames]
	);

	const validateForm = useCallback(
		(formData: WorkloadFormData) => {
			const errors: RJSFValidationError[] = [];
			errors.push(...workloadFormSchemaValidator(workloadSchema, formData));

			onChange?.(buildDeployWorkloadData(formData), isEmpty(errors));
		},
		[onChange, workloadFormSchemaValidator, workloadSchema]
	);

	const onSchemaDataChange = useCallback(
		({ formData }: IChangeEvent<WorkloadFormData>, id?: string) => {
			if (id === WORKLOAD_NAME_FORM_ID) {
				setNameChanged(true);
			}

			if (
				id === WORKLOAD_TITLE_FORM_ID &&
				flowType !== EDeployWorkloadType.UpdateWorkload &&
				!nameChanged
			) {
				assign(formData, {
					workloadInfo: {
						...formData?.workloadInfo,
						name: convertToValidName(formData?.workloadInfo.title)
					}
				});
			}

			if (formData) {
				validateForm(formData);
			}
		},
		[flowType, nameChanged, validateForm]
	);

	// Validate the form state on mount the form when the flow type is Edit or UpdateWorkload
	useEffect(() => {
		if (
			[EDeployWorkloadType.Edit, EDeployWorkloadType.UpdateWorkload].includes(flowType) &&
			!isEmpty(data)
		) {
			validateForm(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return useMemo(
		() => ({
			onSchemaDataChange,
			extraErrors
		}),
		[extraErrors, onSchemaDataChange]
	);
};
