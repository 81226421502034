import { EKrnResource, IAssetDatastreamKrnResource, KvKRNParser } from '@kelvininc/node-client-sdk';

export const getResourceContent = (resource: string): IAssetDatastreamKrnResource => {
	const { resourceContent } = KvKRNParser.parseKRN<EKrnResource.AssetDatastream>(
		resource,
		EKrnResource.AssetDatastream
	);

	return resourceContent;
};
