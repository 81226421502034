import { EIconName } from '@kelvininc/react-ui-components';

import { EApplicationEntity, EntityAction } from './types';

import { IExperienceGuideStep } from '@/src/components/client';

import {
	buildAssetManagementAssetsRoute,
	buildClustersRoute,
	buildConnectionRoute,
	buildDataStreamsRoute
} from '@/src/utils/routes';

export const FIRST_EXPERIENCE_STEPS: IExperienceGuideStep[] = [
	{
		id: EApplicationEntity.Assets,
		label: 'Add assets',
		icon: EIconName.Expand,
		customClasses: 'rotate-270'
	},
	{
		id: EApplicationEntity.Datastreams,
		label: 'Create Data Streams',
		icon: EIconName.Expand,
		customClasses: 'rotate-270'
	},
	{
		id: EApplicationEntity.Clusters,
		label: 'Register Clusters',
		icon: EIconName.Expand,
		customClasses: 'rotate-270'
	}
];

export const CONNECTIONS_TOOLTIP_TEXT =
	"Before you add Connections, you'll need to register Assets, Data Streams, and Clusters first.";

export const CONNECTIONS_PENDING_CONFIG: Pick<
	IExperienceGuideStep,
	'icon' | 'customClasses' | 'tooltipText'
> = {
	icon: EIconName.HelpCircle,
	customClasses: 'icon-20',
	tooltipText: CONNECTIONS_TOOLTIP_TEXT
};

export const CONNECTIONS_READY_CONFIG: Pick<IExperienceGuideStep, 'icon' | 'customClasses'> = {
	icon: EIconName.Expand,
	customClasses: 'rotate-270'
};

export const ENTITY_ROUTES: Record<EApplicationEntity, string> = {
	[EApplicationEntity.Assets]: buildAssetManagementAssetsRoute(),
	[EApplicationEntity.Datastreams]: buildDataStreamsRoute(),
	[EApplicationEntity.Clusters]: buildClustersRoute(),
	[EApplicationEntity.Connections]: buildConnectionRoute()
};

export const REQUIRED_ACTION_ENTITIES: Record<EntityAction, EApplicationEntity[]> = {
	[EntityAction.AddAssetToApp]: [EApplicationEntity.Assets],
	[EntityAction.AddConnection]: [
		EApplicationEntity.Assets,
		EApplicationEntity.Datastreams,
		EApplicationEntity.Clusters
	]
};
