import { KvDropdownBase } from '@kelvininc/react-ui-components';
import { useClickOutsideAlerter } from '@kelvininc/shared-ui';
import { ForwardedRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import { ITableAdvancedFilters } from '../../../../../AgGridTable';

import {
	useTableAdvancedFormFiltersActions,
	withTableAdvancedFiltersContextProvider
} from '../../../../contexts/TableAdvancedFiltersContext';
import { IAdvancedFiltersConfig } from '../../../../types';

import { AdvancedFilterDropdownAction, AdvancedFilterTabs } from './components';
import { ADVANCED_FILTER_DROPDOWN_OPTIONS, ADVANCED_FILTER_DROPDOWN_Z_INDEX } from './config';
import styles from './styles.module.scss';

import { IAdvancedFilterDropdownApi } from './types';
import { isOnPortal } from './utils';

type AdvancedFilterDropdownProps = {
	config: IAdvancedFiltersConfig;
	disabled: boolean;
	filters?: ITableAdvancedFilters;
	onChange: (newFilters?: ITableAdvancedFilters) => void;
	apiRef: ForwardedRef<IAdvancedFilterDropdownApi>;
};

const Component = ({
	config,
	disabled,
	filters: submittedFilters,
	apiRef
}: AdvancedFilterDropdownProps) => {
	const listRef = useRef<HTMLDivElement>(null);
	const actionRef = useRef<HTMLDivElement>(null);
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const { clearFilters } = useTableAdvancedFormFiltersActions();

	const openDropdown = useCallback(() => setDropdownOpen(true), []);
	const closeDropdown = useCallback(() => setDropdownOpen(false), []);
	const toggleDropdown = useCallback(
		() => (isDropdownOpen ? closeDropdown() : openDropdown()),
		[closeDropdown, isDropdownOpen, openDropdown]
	);

	const onClickOutside = useCallback(
		(event: MouseEvent) => {
			if (!isDropdownOpen || !listRef.current || !actionRef.current) {
				return;
			}

			const target = event.target as HTMLElement;

			// Clicked on actions ref
			if (actionRef.current.contains(target)) {
				return;
			}

			// Since dropdowns are render inside portals,
			// we need ignore clicks on these components
			if (isOnPortal(target)) {
				return;
			}

			toggleDropdown();
		},
		[isDropdownOpen, toggleDropdown]
	);

	useClickOutsideAlerter(listRef, onClickOutside);
	useImperativeHandle(apiRef, () => ({
		clearFilters
	}));

	return (
		<div className={styles.AdvancedFilterDropdown}>
			<KvDropdownBase
				isOpen={isDropdownOpen}
				onOpenStateChange={({ detail: newOpenState }) => setDropdownOpen(newOpenState)}
				clickOutsideClose={false}
				options={ADVANCED_FILTER_DROPDOWN_OPTIONS}
				zIndex={ADVANCED_FILTER_DROPDOWN_Z_INDEX}>
				<div ref={actionRef} slot="action" className={styles.Actions}>
					<AdvancedFilterDropdownAction
						isOpen={isDropdownOpen}
						onClick={toggleDropdown}
						onClear={clearFilters}
						disabled={disabled}
						submittedFilters={submittedFilters}
					/>
				</div>
				<div ref={listRef} slot="list" className={styles.Form}>
					<AdvancedFilterTabs config={config} submittedFilters={submittedFilters} />
				</div>
			</KvDropdownBase>
		</div>
	);
};

export const AdvancedFilterDropdown = withTableAdvancedFiltersContextProvider(Component);
