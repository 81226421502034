import { DataStream, EKrnResource, Guardrail, KvKRNParser, Unit } from '@kelvininc/node-client-sdk';
import { KvTooltip } from '@kelvininc/react-ui-components';

import { GuardrailAbsoluteValue, GuardrailRelativeValue } from '@kelvininc/shared-ui';

import classNames from 'classnames';

import { IBaseTData, ICellRendererParams } from '../../../core/components/AgGridTable/types';
import { withBaseCellRenderer } from '../BaseCellRenderer';

import styles from './styles.module.scss';

import { EGuardrailCellValueType } from './types';
import {
	getGuardrailAbsoluteValue,
	getGuardrailRelativeValue,
	getGuardrailUpdater,
	isGuardrailValueTypeAbsolute
} from './utils';

export type GuardrailValueCellRendererParams = {
	datastreams?: Record<string, DataStream>;
	datastreamUnits?: Record<string, Unit>;
	valueType?: EGuardrailCellValueType;
};

const Component = <TData extends IBaseTData>({
	value,
	valueFormatted,
	datastreams,
	datastreamUnits,
	valueType
}: ICellRendererParams<TData, Guardrail> & GuardrailValueCellRendererParams) => {
	const guardrail = (valueFormatted ?? value) as Guardrail | null | undefined;
	if (!guardrail || !valueType) {
		return <KvTooltip text="No data available to display">N/A</KvTooltip>;
	}

	const {
		resourceContent: { datastream: datastreamName }
	} = KvKRNParser.parseKRN<EKrnResource.AssetDatastream>(
		guardrail.resource,
		EKrnResource.AssetDatastream
	);

	const datastream = datastreams?.[datastreamName];
	const unit = datastream && datastreamUnits?.[datastream?.unitName];

	if (isGuardrailValueTypeAbsolute(valueType)) {
		return (
			<div
				className={classNames(styles.GuardrailValueCellRenderer, {
					[styles.ControlDisabled]: !!guardrail.controlDisabled
				})}>
				<GuardrailAbsoluteValue
					updater={getGuardrailUpdater(guardrail, valueType)}
					guardrail={getGuardrailAbsoluteValue(guardrail, valueType)}
					datastream={datastream}
					unit={unit}
					datastreams={datastreams}
					datastreamUnits={datastreamUnits}
					customClasses={styles.GuardrailAbsoluteValue}
				/>
			</div>
		);
	}

	return (
		<div
			className={classNames(styles.GuardrailValueCellRenderer, {
				[styles.ControlDisabled]: !!guardrail.controlDisabled
			})}>
			<GuardrailRelativeValue
				guardrail={getGuardrailRelativeValue(guardrail, valueType)}
				datastream={datastream}
				unit={unit}
			/>
		</div>
	);
};

export const GuardrailValueCellRenderer = withBaseCellRenderer(Component);
