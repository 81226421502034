import { syncUserSettingInitialValue } from '@kelvininc/shared-ui';
import { IExpandableSetting } from '@kelvininc/types';
import { atom } from 'recoil';

import {
	NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_KEY,
	NAVIGATION_SIDEBAR_EXPANDED_CONTEXTUALIZATION_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_CONTEXTUALIZATION_USER_SETTING_KEY,
	NAVIGATION_SIDEBAR_EXPANDED_KELVIN_AI_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_KELVIN_AI_USER_SETTING_KEY,
	NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_KEY,
	NAVIGATION_SIDEBAR_EXPANDED_ORCHESTRATION_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_ORCHESTRATION_USER_SETTING_KEY
} from './config';

export const navigationSidebarExpandedAdminUserSettingAtom = atom<IExpandableSetting>({
	key: 'navigationSidebarExpandedAdminUserSettingAtom',
	effects: [
		syncUserSettingInitialValue(
			NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_KEY,
			NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_DEFAULT
		)
	]
});

export const navigationSidebarExpandedContextualizationUserSettingAtom = atom<IExpandableSetting>({
	key: 'navigationSidebarExpandedContextualizationUserSettingAtom',
	effects: [
		syncUserSettingInitialValue(
			NAVIGATION_SIDEBAR_EXPANDED_CONTEXTUALIZATION_USER_SETTING_KEY,
			NAVIGATION_SIDEBAR_EXPANDED_CONTEXTUALIZATION_USER_SETTING_DEFAULT
		)
	]
});

export const navigationSidebarExpandedOrchestrationUserSettingAtom = atom<IExpandableSetting>({
	key: 'navigationSidebarExpandedOrchestrationUserSettingAtom',
	effects: [
		syncUserSettingInitialValue(
			NAVIGATION_SIDEBAR_EXPANDED_ORCHESTRATION_USER_SETTING_KEY,
			NAVIGATION_SIDEBAR_EXPANDED_ORCHESTRATION_USER_SETTING_DEFAULT
		)
	]
});

export const navigationSidebarExpandedOperationsUserSettingAtom = atom<IExpandableSetting>({
	key: 'navigationSidebarExpandedOperationsUserSettingAtom',
	effects: [
		syncUserSettingInitialValue(
			NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_KEY,
			NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_DEFAULT
		)
	]
});

export const navigationSidebarExpandedKelvinAIUserSettingAtom = atom<IExpandableSetting>({
	key: 'navigationSidebarExpandedKelvinAIUserSettingAtom',
	effects: [
		syncUserSettingInitialValue(
			NAVIGATION_SIDEBAR_EXPANDED_KELVIN_AI_USER_SETTING_KEY,
			NAVIGATION_SIDEBAR_EXPANDED_KELVIN_AI_USER_SETTING_DEFAULT
		)
	]
});
