import { EActionButtonType, EAlertType, KvActionButtonText } from '@kelvininc/react-ui-components';
import { CSSProperties } from 'react';

import { IModalAlert, closeAlertInModal } from '../Modal';

export const DROP_MESSAGE_STYLE: CSSProperties = {
	opacity: 0.1,
	border: 'unset',
	borderRadius: '0px'
} as CSSProperties;

export const PREPARE_DOWNLOAD = 'Preparing download...';

export const PREPARE_DOWNLOAD_ERROR: IModalAlert = {
	label: 'Something went wrong',
	description: 'We were unable to retrieve the file',
	type: EAlertType.Error,
	actions: (
		<KvActionButtonText
			text="Cancel"
			type={EActionButtonType.Secondary}
			onClickButton={closeAlertInModal}
		/>
	)
};
