import { Guardrail } from '@kelvininc/node-client-sdk';
import { EActionButtonType, EIconName } from '@kelvininc/react-ui-components';
import { ISelectionAction } from '@kelvininc/table';

import { useMemo } from 'react';

import {
	EGuardrailOperationType,
	GuardrailOperation
} from '@/src/services/guardrail-service/types';

export const useSelectionActions = (
	executeOperation: (operation: GuardrailOperation) => Promise<void>
): ISelectionAction<Guardrail>[] => {
	return useMemo<ISelectionAction<Guardrail>[]>(
		() => [
			{
				id: 'delete-all',
				icon: EIconName.Delete,
				text: 'Delete',
				type: EActionButtonType.Tertiary,
				onClick: (guardrails: Guardrail[]) =>
					executeOperation({
						type: EGuardrailOperationType.BulkDelete,
						payload: { resources: guardrails.map(({ resource }) => resource) }
					})
			}
		],
		[executeOperation]
	);
};
