import { IColumnOptions } from '@kelvininc/table';

import { IDetailValue } from './types';

export const TABLE_ROW_HEIGHT = 40;

export const DEFAULT_COLUMN_DEF: IColumnOptions<IDetailValue> = {
	resizable: false,
	sortable: false
};
