'use client';
import { ICodeTranslatorEditorChangeData } from '@kelvininc/shared-ui';
import classNames from 'classnames';

import { TAB_LANGUAGES } from './config';
import styles from './styles.module.scss';

import { TabbedCodeTranslator } from '@/src/components/client/TabbedCodeTranslator';

interface WorkloadConfigurationProps {
	isEditMode: boolean;
	payloadObject?: object;
	onChange: (changes: Partial<ICodeTranslatorEditorChangeData>) => void;
}
export const WorkloadConfiguration = ({
	isEditMode,
	payloadObject = {},
	onChange
}: WorkloadConfigurationProps) => (
	<div
		className={classNames(styles.ConfigurationStep, {
			[styles.EditMode]: isEditMode
		})}>
		<TabbedCodeTranslator
			languages={TAB_LANGUAGES}
			object={payloadObject}
			readOnly={false}
			onChange={onChange}
		/>
	</div>
);
