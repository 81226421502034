import { Link } from '@kelvininc/shared-ui';
import { PropsWithChildren, useCallback, useMemo } from 'react';

import { IBaseTData, ICellRendererParams } from '../../../../../core/components/AgGridTable';
import { isCellClickable } from '../../utils';

import styles from './styles.module.scss';

export const CellClickContainer = <TData extends IBaseTData>(
	props: PropsWithChildren<ICellRendererParams<TData>>
) => {
	const {
		onClick,
		getLink,
		value,
		valueFormatted,
		data,
		target,
		column,
		api,
		context,
		children,
		disabled,
		prefetchLink = false
	} = props;

	const formattedValue = useMemo(() => valueFormatted ?? value, [value, valueFormatted]);
	const link = useMemo(
		() => getLink?.(formattedValue, data as TData, column?.getColId() as string, api, context),
		[getLink, formattedValue, data, column, api, context]
	);
	const onClickCell = useCallback(
		() => onClick?.(formattedValue, data as TData, column?.getColId() as string, api, context),
		[onClick, formattedValue, data, column, api, context]
	);
	const isClickable = useMemo(
		() =>
			isCellClickable({
				onClick,
				getLink,
				value,
				valueFormatted,
				data,
				column,
				api,
				context
			}),
		[onClick, getLink, value, valueFormatted, data, column, api, context]
	);

	if (isClickable) {
		return (
			<Link
				onClick={onClickCell}
				href={link}
				target={target}
				className={styles.Clickable}
				disabled={disabled}
				prefetch={prefetchLink}>
				{children}
			</Link>
		);
	}

	return <>{children}</>;
};
