import { IBridgeDeployData, IWorkloadDeployData } from '@kelvininc/node-client-sdk';

import { IBridgeAppParameters, IDockerAppParameters, IKelvinAppParameters } from './types';

export const buildIDeployBridgeData = (parameters: IBridgeAppParameters): IBridgeDeployData => ({
	cluster_name: parameters.clusterName,
	name: parameters.name,
	payload: parameters.payload,
	app_name: parameters.appName,
	title: parameters.title
});

export const buildIDeployKelvinAppWorkloadData = (
	parameters: IKelvinAppParameters
): IWorkloadDeployData => ({
	app_name: parameters.appName,
	app_version: parameters.appVersion,
	acp_name: parameters.acpName,
	cluster_name: parameters.clusterName,
	name: parameters.name,
	payload: parameters.payload,
	title: parameters.title,
	staged: parameters.staged,
	instantly_apply: parameters.instantlyApply
});

export const buildIDeployDockerAppWorkloadData = (
	parameters: IDockerAppParameters
): IWorkloadDeployData => ({
	app_name: parameters.appName,
	app_version: parameters.appVersion,
	acp_name: parameters.acpName,
	cluster_name: parameters.clusterName,
	name: parameters.name,
	payload: parameters.payload,
	title: parameters.title,
	staged: parameters.staged,
	instantly_apply: parameters.instantlyApply
});
