'use client';
import { EIconName } from '@kelvininc/react-ui-components';

import { ECopyState } from './types';

export const COPY_STATE_TRANSITION_DURATION_MS = 2000;
export const UNABLE_TO_COPY_ERROR = 'Unable to copy to clipboard';

export const ICON_CONFIGS: Record<ECopyState, EIconName> = {
	[ECopyState.ReadyToCopy]: EIconName.Copy,
	[ECopyState.Copied]: EIconName.Success
};

export const TOOLTIP_LABELS: Record<ECopyState, string> = {
	[ECopyState.ReadyToCopy]: 'Copy',
	[ECopyState.Copied]: 'Copied!'
};
