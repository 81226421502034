import { ComponentProps } from 'react';

import { UploadFile } from '../UploadFile';

import { DEFAULT_MAX_FILE_SIZE, SUPPORTED_FILE_TYPES } from './config';

export const UploadCSVFile = ({
	maxSize = DEFAULT_MAX_FILE_SIZE,
	...props
}: Omit<ComponentProps<typeof UploadFile>, 'types'>) => {
	return <UploadFile maxSize={maxSize} types={SUPPORTED_FILE_TYPES} {...props} />;
};
