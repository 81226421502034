import { Link } from '../../../Link';

import styles from './styles.module.scss';
import { FooterInfoProps } from './types';

const UserDropdownFooter = (footerInfo: FooterInfoProps) => {
	return (
		<div className={styles.FooterContainer}>
			<span>{footerInfo.version}</span>
			<div className={styles.FooterSeparator} />
			<Link
				className={styles.FooterTextDocumentation}
				href={footerInfo.url}
				target={footerInfo.target}
				rel="noreferrer">
				{footerInfo.text}
			</Link>
		</div>
	);
};

export default UserDropdownFooter;
