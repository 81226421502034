import { OrchestrationClustersCreateItem } from '@kelvininc/node-client-sdk';
import { SchemaFormProps } from '@kelvininc/react-ui-components';

import { isEmpty } from 'lodash-es';

import { DeployWorkloadData, EDeployWorkloadType } from '../../types';

import styles from './styles.module.scss';

import { WorkloadFormData } from './WorkloadForm';

import {
	FORM_HELP_TEXT,
	NAME_MAX_LENGTH_INPUT_CHARACTERS,
	NAME_REDUCED_MAX_LENGTH_INPUT_CHARACTERS
} from '@/src/config';
export const getWorkloadConfigurationFormSchema = (
	clusters: OrchestrationClustersCreateItem[],
	flowType: EDeployWorkloadType,
	hideWorkloadType = false
): SchemaFormProps<WorkloadFormData>['schema'] => {
	return {
		type: 'object',
		properties: {
			workloadInfo: {
				type: 'object',
				title: 'Workload Info',
				properties: {
					title: {
						type: 'string',
						title: 'Display Name'
					},
					name: {
						type: 'string',
						title: 'Name ID',
						readOnly: flowType === EDeployWorkloadType.UpdateWorkload,
						description: FORM_HELP_TEXT.name
					},
					clusterName: {
						type: 'string',
						title: 'Clusters',
						oneOf: !isEmpty(clusters)
							? clusters.map((item) => ({ title: item.title, const: item.name }))
							: undefined
					}
				},
				required: ['title', 'name', 'clusterName']
			},
			workloadType: {
				type: 'object',
				title: 'Deployment Type',
				properties: {
					staged: {
						type: 'boolean',
						title: 'Type',
						default: false,
						oneOf: [
							{
								title: 'Standard',
								const: false
							},
							{
								title: 'Staged',
								const: true
							}
						]
					},
					instantlyApply: {
						type: 'boolean',
						title: 'Staging Mode',
						readOnly: true,
						oneOf: [
							{
								title: 'Stage Only',
								description:
									'The cloud notifies the edge of a workload, downloads it, but requires human intervention for application.',
								const: false
							},
							{
								title: 'Stage and Apply',
								description:
									'The cloud alerts the edge of a workload, downloads, and automatically applies it, no human intervention needed.',
								const: true
							}
						]
					}
				},
				if: {
					properties: { staged: { const: true } }
				},
				then: {
					required: ['staged', 'instantlyApply'],
					properties: {
						instantlyApply: {
							readOnly: false
						}
					}
				},
				else: {
					required: ['staged'],
					properties: {
						instantlyApply: {
							readOnly: true
						}
					}
				}
			}
		},
		required: hideWorkloadType ? ['workloadInfo'] : ['workloadInfo', 'workloadType']
	};
};

export const getWorkloadConfigurationFormUiSchema = (
	flowType: EDeployWorkloadType,
	hideWorkloadType = false
): SchemaFormProps<WorkloadFormData>['uiSchema'] => ({
	workloadInfo: {
		'ui:hideError': true,
		'ui:titleCustomClass': styles.SectionTitleContainer,
		title: {
			'ui:placeholder': 'Write here the display name',
			'ui:hideError': false,
			maxLength: NAME_MAX_LENGTH_INPUT_CHARACTERS
		},
		name: {
			'ui:placeholder': 'Write here the workload name id',
			'ui:hideError': false,
			maxLength: NAME_REDUCED_MAX_LENGTH_INPUT_CHARACTERS
		},
		clusterName: {
			'ui:widget': flowType !== EDeployWorkloadType.AppScopeDeploy ? 'hidden' : undefined
		}
	},
	workloadType: {
		'ui:widget': hideWorkloadType ? 'hidden' : undefined,
		'ui:hideError': true,
		'ui:titleCustomClass': styles.SectionTitleContainer,
		instantlyApply: {
			'ui:hideError': true,
			'ui:widget': 'RadioListWidget',
			'ui:inline': false
		}
	},
	'ui:hideError': true,
	'ui:submitButtonOptions': {
		norender: true
	}
});

export const buildWorkloadFormData = (data: Partial<DeployWorkloadData>): WorkloadFormData => {
	const { name, title, staged = false, instantlyApply, clusterName } = data;

	return {
		workloadInfo: { name, title, clusterName },
		workloadType: { staged, instantlyApply }
	};
};

export const buildDeployWorkloadData = (
	formData: WorkloadFormData
): Partial<DeployWorkloadData> => {
	const { workloadInfo, workloadType } = formData;
	const { name, title, clusterName } = workloadInfo;
	const { staged, instantlyApply } = workloadType;
	return {
		name,
		title,
		staged,
		clusterName,
		instantlyApply
	};
};
