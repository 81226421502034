import { APP_DEPLOYMENT_SERVICES } from './config';
import {
	AppDeploymentParameters,
	AppDeploymentServiceParameters,
	IAppDeploymentServiceProps
} from './types';

export abstract class AppDeploymentService {
	static deployApp({
		appType,
		...otherProps
	}: IAppDeploymentServiceProps<AppDeploymentParameters>): Promise<boolean> {
		return APP_DEPLOYMENT_SERVICES[appType].deploy(otherProps);
	}

	static undeployApp({
		appType,
		...otherProps
	}: IAppDeploymentServiceProps<AppDeploymentServiceParameters>): Promise<boolean> {
		return APP_DEPLOYMENT_SERVICES[appType].undeploy(otherProps);
	}

	static startApp({
		appType,
		...otherProps
	}: IAppDeploymentServiceProps<AppDeploymentServiceParameters>): Promise<boolean> {
		return APP_DEPLOYMENT_SERVICES[appType].start(otherProps);
	}

	static stopApp({
		appType,
		...otherProps
	}: IAppDeploymentServiceProps<AppDeploymentServiceParameters>): Promise<boolean> {
		return APP_DEPLOYMENT_SERVICES[appType].stop(otherProps);
	}

	static applyApp({
		appType,
		...otherProps
	}: IAppDeploymentServiceProps<AppDeploymentServiceParameters>): Promise<boolean> {
		return APP_DEPLOYMENT_SERVICES[appType].apply(otherProps);
	}
}
