import { set } from 'lodash-es';

import { ImportGuardrailFileSchema } from '../../types';

import { ICSVMap } from '@/src/types';
import {
	isValidCSVHeader,
	parseValueToFieldType,
	removeEmptyTrailLinesFromCsvFile
} from '@/src/utils/schema-csv';

export const buildGuardrailsFromCSV = (
	csvData: string,
	csvMap: ICSVMap[]
): {
	data: ImportGuardrailFileSchema[];
	fileHeader: ICSVMap[];
	missingHeaderTitle: boolean;
} => {
	const lines = csvData.split(/\r?\n/);
	const header = lines[0].split(',').map((line) => line.trim());

	if (lines.length === 0 || !isValidCSVHeader(header, csvMap)) {
		return { data: [], fileHeader: csvMap, missingHeaderTitle: false };
	}

	// Discard the header line
	lines.shift();

	// Discard empty trail lines
	const sanitizedLines = removeEmptyTrailLinesFromCsvFile(lines);

	let missingHeaderTitle = false;
	const data = sanitizedLines.map<ImportGuardrailFileSchema>((line) => {
		const lineValues = line.split(',');

		return lineValues.reduce<ImportGuardrailFileSchema>(
			(accumulator, columnValue, columnNumber) => {
				if (!csvMap[columnNumber]) {
					missingHeaderTitle = true;
					return accumulator;
				}

				const { path, type } = csvMap[columnNumber];
				const value = parseValueToFieldType(columnValue.trim(), type);

				set(accumulator, path, value);

				return accumulator;
			},
			{} as ImportGuardrailFileSchema
		);
	});

	return {
		data,
		fileHeader: csvMap,
		missingHeaderTitle
	};
};
