import { DataStream, EControlChangeState, Unit } from '@kelvininc/node-client-sdk';
import { EIconName, ETooltipPosition, KvIcon, KvTooltip } from '@kelvininc/react-ui-components';
import { ControlChangeStatus } from '@kelvininc/shared-ui';
import {
	formatDatastreamValue,
	getDatastreamUnitSymbol,
	isDataStreamDataTypeObject
} from '@kelvininc/tsutils';
import { useMemo } from 'react';

import { IBaseTData, ICellRendererParams } from '../../../core/components/AgGridTable/types';
import { withBaseCellRenderer } from '../BaseCellRenderer';

import styles from './styles.module.scss';

import { IControlChangeCellData } from './types';
import { getControlChangeTooltip } from './utils';

export type ControlChangeCellRendererParams = {
	datastreams?: Record<string, DataStream>;
	datastreamUnits?: Record<string, Unit>;
};

const Component = <TData extends IBaseTData>({
	value: controlChange,
	datastreams = {},
	datastreamUnits = {}
}: ControlChangeCellRendererParams & ICellRendererParams<TData, IControlChangeCellData>) => {
	const datastream = useMemo(
		() => (controlChange ? datastreams[controlChange.datastream_name] : undefined),
		[controlChange, datastreams]
	);

	const formattedOldValue = useMemo(
		() =>
			(controlChange?.reported?.before?.value ?? controlChange?.from?.value) &&
			datastream &&
			!isDataStreamDataTypeObject(datastream.dataTypeName)
				? formatDatastreamValue(
						controlChange?.reported?.before?.value ?? controlChange?.from?.value,
						datastream,
						getDatastreamUnitSymbol(datastream, datastreamUnits)
					)
				: undefined,
		[controlChange, datastream, datastreamUnits]
	);
	const formattedNewValue = useMemo(
		() =>
			controlChange && datastream && !isDataStreamDataTypeObject(datastream.dataTypeName)
				? formatDatastreamValue(
						controlChange.reported?.after?.value ?? controlChange.payload,
						datastream,
						getDatastreamUnitSymbol(datastream, datastreamUnits)
					)
				: undefined,
		[controlChange, datastream, datastreamUnits]
	);

	if (!datastream || !controlChange) {
		return <>N/A</>;
	}

	return (
		<div className={styles.ControlChangeContainer}>
			<div className={styles.Title}>{datastream.title}</div>
			<div className={styles.Description}>
				<div className={styles.Change}>
					{formattedOldValue && (
						<>
							<div className={styles.OldPayload}>{`From ${formattedOldValue}`}</div>
							<div className={styles.Arrow}>
								<KvIcon name={EIconName.SlimRight} />
							</div>
						</>
					)}
					{formattedNewValue && (
						<div className={styles.newPayload}>{`To ${formattedNewValue}`}</div>
					)}
				</div>
				<KvTooltip
					text={getControlChangeTooltip(controlChange)}
					position={ETooltipPosition.BottomStart}>
					<div className={styles.Status}>
						<ControlChangeStatus
							status={controlChange.last_state as EControlChangeState}
							customClasses={styles.TagStatus}
						/>
					</div>
				</KvTooltip>
			</div>
		</div>
	);
};

export const ControlChangeCellRenderer = withBaseCellRenderer(Component, {
	customClasses: styles.ControlChangeCell
});
