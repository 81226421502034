import { EParametersScheduleExtraFieldDataScheduleType } from '@kelvininc/node-client-sdk';
import { KvSingleSelectDropdown } from '@kelvininc/react-ui-components';

import { buildRelativeTimeRange } from '@kelvininc/tsutils';
import { ReactState } from '@kelvininc/types';

import { IScheduleApplicationParametersColumnData } from '../../../../../../../AgGridTable';

import {
	SCHEDULE_TYPE_ALL_OPTION_VALUE,
	SCHEDULE_TYPE_DROPDOWN_OPTIONS,
	SCHEDULE_TYPE_LABEL,
	SCHEDULE_TYPE_PLACEHOLDER,
	TIME_RANGE_DROPDOWN_OPTIONS,
	TIME_RANGE_LABEL,
	TIME_RANGE_PLACEHOLDER
} from './config';
import styles from './styles.module.scss';

type ScheduleApplicationParametersSettings = {
	state: ReactState<IScheduleApplicationParametersColumnData>;
};

export const ScheduleApplicationParametersSettings = ({
	state
}: ScheduleApplicationParametersSettings) => {
	const [settings, setState] = state;

	return (
		<div className={styles.ScheduleApplicationParametersSettings}>
			<KvSingleSelectDropdown
				placeholder={SCHEDULE_TYPE_PLACEHOLDER}
				label={SCHEDULE_TYPE_LABEL}
				options={SCHEDULE_TYPE_DROPDOWN_OPTIONS}
				selectedOption={settings.type ?? SCHEDULE_TYPE_ALL_OPTION_VALUE}
				onOptionSelected={({ detail: newType }) =>
					setState((previousState) => ({
						...previousState,
						type:
							newType === SCHEDULE_TYPE_ALL_OPTION_VALUE
								? undefined
								: (newType as EParametersScheduleExtraFieldDataScheduleType)
					}))
				}
			/>
			<KvSingleSelectDropdown
				required
				placeholder={TIME_RANGE_PLACEHOLDER}
				label={TIME_RANGE_LABEL}
				options={TIME_RANGE_DROPDOWN_OPTIONS}
				selectedOption={settings.range?.time.payload?.key}
				onOptionSelected={({ detail: newTime }) =>
					setState((previousState) => ({
						...previousState,
						range: buildRelativeTimeRange(newTime)
					}))
				}
				searchable={false}
			/>
		</div>
	);
};
