import { EIllustrationName } from '@kelvininc/react-ui-components';

import { BaseOverlay } from '../BaseOverlay';

import { IOverlayAction } from '../BaseOverlay/types';

import {
	EMPTY_OVERLAY_DESCRIPTION,
	EMPTY_OVERLAY_ILLUSTRATION,
	EMPTY_OVERLAY_TITLE
} from './config';
import styles from './styles.module.scss';

type EmptyOverlayProps = {
	title?: string;
	description?: string;
	illustration?: EIllustrationName;
	action?: IOverlayAction;
};

export const EmptyOverlay = ({
	title = EMPTY_OVERLAY_TITLE,
	description = EMPTY_OVERLAY_DESCRIPTION,
	illustration = EMPTY_OVERLAY_ILLUSTRATION,
	action
}: EmptyOverlayProps) => {
	return (
		<BaseOverlay
			title={title}
			description={description}
			illustration={illustration}
			action={action}
			customClasses={styles.EmptyOverlay}
		/>
	);
};
