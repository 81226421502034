import { RecoilCallback } from '@kelvininc/types';

import { assetsParametersSchedulesAtomFamily } from './atoms';
import { getAssetParametersSchedules } from './requesters';

export const refreshAssetParametersSchedulesCallback: RecoilCallback<string, Promise<void>> =
	({ set }) =>
	async (assetName) => {
		set(
			assetsParametersSchedulesAtomFamily(assetName),
			await getAssetParametersSchedules(assetName)
		);
	};
