import { useDebounce } from '@kelvininc/shared-ui';
import { omit } from 'lodash-es';

import { EXISTENCE_OPERATORS_OPERATORS } from '../../../../../../../../../utils';
import {
	ETableAdvancedFilterType,
	IAssetPropertyTableAdvancedFilter
} from '../../../../../../../AgGridTable';

import {
	DraftAdvancedFilter,
	IDrafAdvancedFilterMap
} from '../../../../../../contexts/TableAdvancedFiltersContext/types';
import { IAssetPropertyAdvancedFilterConfigMetadata } from '../../../../../../types';
import { AdvancedFilterForm } from '../AdvancedFilterForm';
import { AdvancedFiltersForm } from '../AdvancedFiltersForm';

import { AssetPropertyDropdown, AssetPropertySchemaForm } from './components';
import { ASSET_PROPERTY_SCHEMA_FORM_PROPERTIES } from './config';
import styles from './styles.module.scss';

import { AssetPropertyAdvancedFilterSchemaFormData } from './types';
import { getSchemaFormData } from './utils';

type AssetPropertyFiltersFormProps = {
	isFormEmpty: boolean;
	metadata: IAssetPropertyAdvancedFilterConfigMetadata;
	filters: DraftAdvancedFilter<IAssetPropertyTableAdvancedFilter>[];
	createFilter: (type: ETableAdvancedFilterType) => void;
	updateFilter: (
		type: ETableAdvancedFilterType,
		index: number,
		newFilter: IDrafAdvancedFilterMap[typeof type]
	) => void;
	deleteFilter: (type: ETableAdvancedFilterType, index: number) => void;
	duplicateFilter: (type: ETableAdvancedFilterType, index: number) => void;
};

export const AssetPropertyFiltersForm = ({
	isFormEmpty,
	metadata: { properties },
	filters,
	createFilter,
	updateFilter,
	deleteFilter,
	duplicateFilter
}: AssetPropertyFiltersFormProps) => {
	const debouncedUpdateFilter = useDebounce(updateFilter);

	const onChange = (
		index: number,
		newFilter: DraftAdvancedFilter<IAssetPropertyTableAdvancedFilter>,
		update = updateFilter
	): void => update(ETableAdvancedFilterType.AssetProperty, index, newFilter);

	const onChangeProperty = (
		index: number,
		newProperty: string,
		filter: DraftAdvancedFilter<IAssetPropertyTableAdvancedFilter>
	): void =>
		onChange(index, {
			...omit(filter, ASSET_PROPERTY_SCHEMA_FORM_PROPERTIES),
			property: newProperty
		});

	const onChangeForm = (
		index: number,
		form: AssetPropertyAdvancedFilterSchemaFormData,
		filter: DraftAdvancedFilter<IAssetPropertyTableAdvancedFilter>
	): void => {
		const operator = form?.operator ?? '';
		const value = EXISTENCE_OPERATORS_OPERATORS.includes(operator) ? undefined : form?.value;

		onChange(
			index,
			{
				...omit(filter, ASSET_PROPERTY_SCHEMA_FORM_PROPERTIES),
				operator,
				value
			},
			debouncedUpdateFilter
		);
	};

	return (
		<AdvancedFiltersForm
			onAdd={() => createFilter(ETableAdvancedFilterType.AssetProperty)}
			customClasses={styles.AssetPropertyFiltersForm}>
			{filters.map((filter, index) => (
				<AdvancedFilterForm
					key={filter.id}
					index={index}
					onDuplicate={() =>
						duplicateFilter(ETableAdvancedFilterType.AssetProperty, index)
					}
					onDelete={() => deleteFilter(ETableAdvancedFilterType.AssetProperty, index)}
					filters={filters}
					isFormEmpty={isFormEmpty}>
					<AssetPropertyDropdown
						property={filter.property}
						properties={properties.definitions}
						onChangeProperty={(newProperty) =>
							onChangeProperty(index, newProperty, filter)
						}
					/>
					<AssetPropertySchemaForm
						formData={getSchemaFormData(filter)}
						property={filter.property}
						properties={properties.definitions}
						values={properties.values}
						onChangeForm={(formData) => onChangeForm(index, formData, filter)}
					/>
				</AdvancedFilterForm>
			))}
		</AdvancedFiltersForm>
	);
};
