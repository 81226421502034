import { EPropertyType, Property } from '@kelvininc/node-client-sdk';
import { SchemaFormProps } from '@kelvininc/react-ui-components';
import { isEmpty, merge } from 'lodash-es';

import {
	MINIMUM_MULTI_SELECTABLE_OPTIONS,
	PRIMITIVE_TYPE_BOOLEAN_FILTER_SCHEMA,
	PRIMITIVE_TYPE_NUMBER_FILTER_SCHEMA,
	PRIMITIVE_TYPE_STRING_ARRAY_FILTER_SCHEMA,
	PRIMITIVE_TYPE_STRING_FILTER_SCHEMA,
	PRIMITIVE_TYPE_TIMESTAMP_FILTER_SCHEMA
} from '../../../../config';
import { AssetPropertyAdvancedFilterSchemaFormData } from '../../types';

export const getPropertySchemaForm = (
	property: Property,
	values: string[] | boolean[] = []
): SchemaFormProps<AssetPropertyAdvancedFilterSchemaFormData>['schema'] => {
	switch (property.primitiveType) {
		case EPropertyType.Number:
			return PRIMITIVE_TYPE_NUMBER_FILTER_SCHEMA;

		case EPropertyType.Boolean:
			return PRIMITIVE_TYPE_BOOLEAN_FILTER_SCHEMA;

		case EPropertyType.Timestamp:
			return PRIMITIVE_TYPE_TIMESTAMP_FILTER_SCHEMA;

		case EPropertyType.String:
			if (isEmpty(values)) {
				return PRIMITIVE_TYPE_STRING_FILTER_SCHEMA;
			}

			if (values.length < MINIMUM_MULTI_SELECTABLE_OPTIONS) {
				return merge({}, PRIMITIVE_TYPE_STRING_ARRAY_FILTER_SCHEMA, {
					definitions: {
						string_options_value: {
							type: 'string',
							enum: values
						}
					}
				});
			}

			return merge({}, PRIMITIVE_TYPE_STRING_ARRAY_FILTER_SCHEMA, {
				definitions: {
					string_options_value: {
						type: 'array',
						uniqueItems: true,
						items: {
							type: 'string',
							enum: values
						}
					}
				}
			});

		default:
			return PRIMITIVE_TYPE_STRING_FILTER_SCHEMA;
	}
};
