import { EToasterType, IToaster } from '@kelvininc/react-ui-components';

export const SCROLL_OFFSET = 20;
export const COLUMNS_TOOLTIP_TEXT =
	'Remember to reorder columns using drag & drop and freeze them to the left or right to a maximum of four. Locked columns cannot be changed from a place or reconfigured.';
export const INVISIBLE_COLUMNS_HIDDED_TOASTER_CONFIG: IToaster = {
	header: 'You hidden some columns successfully.',
	type: EToasterType.Success
};
export const SEARCH_COLUMN_PLACEHOLDER = 'Search column';
export const MAXIMUM_NON_FEATURE_COLUMNS_FROZEN = 4;
export const MAXIMUM_TABLE_COLUMNS = 20;

export const MAXIMUM_TABLE_COLUMNS_REACHED_TOOLTIP = '20 columns limit reached';
