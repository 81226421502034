import { EStepState } from '@kelvininc/react-ui-components';
import { isEmpty } from 'lodash-es';
import { Dispatch, SetStateAction, useMemo } from 'react';

import { FieldValues, FormState } from 'react-hook-form';

import { IWizardStepConfig } from '../../components/WizardStep';
import { useWizardFormContext } from '../WizardFormContextProvider/hooks';

import { useCurrentStepContext } from './CurrentStepContext';

export const useCurrentStepState = <R extends FieldValues>(
	isStepValid: (step: IWizardStepConfig, values: R, formState?: FormState<R>) => boolean
): {
	currentStep: IWizardStepConfig;
	setCurrentStep: Dispatch<SetStateAction<IWizardStepConfig>>;
	currentStepState: EStepState | undefined;
} => {
	const { watch, formState } = useWizardFormContext<R>();
	const values = watch();
	const {
		currentStepState: [currentStep, setCurrentStep]
	} = useCurrentStepContext();

	const currentStepState = useMemo(() => {
		const { errors } = formState;
		if (!isEmpty(errors)) {
			return EStepState.Error;
		}

		if (isStepValid && isStepValid(currentStep, values, formState)) {
			return EStepState.Success;
		}
	}, [currentStep, formState, isStepValid, values]);

	return {
		currentStep,
		setCurrentStep,
		currentStepState
	};
};
