import { Property } from '@kelvininc/node-client-sdk';
import { UseDropdownOptions } from '@kelvininc/types';

export const ASSET_PROPERTY_COLUMN_LABEL = 'Asset Property Name';
export const ASSET_PROPERTY_COLUMN_PLACEHOLDER = 'Select an option';
export const USE_DROPDOWN_OPTIONS: UseDropdownOptions<Property> = {
	key: 'name',
	label: 'title',
	value: 'name'
};
