import { ITimePickerTimeState } from '@kelvininc/react-ui-components';

import { CUSTOMIZE_INTERVAL_KEY, TIME_PICKER_RELATIVE_TIME_KEYS_RANGE } from './config';
import { RelativeTimePickerRange } from './types';

const buildGrafanaUrl = (url: string, time: RelativeTimePickerRange): string => {
	return `${url}from=${encodeURIComponent(time.from)}&to=${encodeURIComponent(time.to)}`;
};

export const buildGrafanaDashboardUrl = (url: string, time: ITimePickerTimeState): string => {
	const [from, to] = time.range;
	if (time.key === CUSTOMIZE_INTERVAL_KEY && from && to) {
		return buildGrafanaUrl(url, { from, to });
	}

	return buildGrafanaUrl(url, TIME_PICKER_RELATIVE_TIME_KEYS_RANGE[time.key]);
};
