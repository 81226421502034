import { atom } from 'recoil';

import { workloadListAtom } from '@/src/recoil/workloads/atom';

export const workloadListNamesAtom = atom<string[]>({
	key: 'workloadListNamesAtom',
	effects: [
		({ setSelf, getPromise }) => {
			setSelf(
				getPromise(workloadListAtom).then((data) => data.map((workload) => workload.name))
			);
		}
	]
});
