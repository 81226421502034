import { ITableFiltersSchema } from '@kelvininc/table';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { buildFiltersSchema } from './utils';

import { assetsListAtom } from '@/src/recoil/assets/atoms';
import { datastreamsListAtom } from '@/src/recoil/data-streams/atoms';

export const useFilters = (): ITableFiltersSchema => {
	const assets = useRecoilValue(assetsListAtom);
	const datastreams = useRecoilValue(datastreamsListAtom);

	return useMemo(() => buildFiltersSchema(assets, datastreams), [assets, datastreams]);
};
