import {
	IApplicationParameterColumnData,
	IAssetPropertyColumnData,
	IDatastreamColumnData,
	ILastControlChangeColumnData,
	IRecommendationColumnData,
	IScheduleApplicationParametersColumnData
} from '../../../AgGridTable';
import { EManagementPanel, IPanelConfig } from '../../types';

export const DEFAULT_ADD_COLUMN_ALERT_ID = 'AddColumn';
export const DEFAULT_EDIT_COLUMN_ALERT_ID = 'EditColumn';

export const DEFAULT_ASSET_PROPERTY_DATA: IAssetPropertyColumnData = {};
export const DEFAULT_DATASTREAM_DATA: IDatastreamColumnData = {};
export const DEFAULT_LAST_CONTROL_CHANGE_DATA: ILastControlChangeColumnData = {
	allSetpoints: true,
	datastreams: []
};
export const DEFAULT_RECOMMENDATION_DATA: IRecommendationColumnData = {};
export const DEFAULT_APPLICATION_PARAMETER_DATA: IApplicationParameterColumnData = {};
export const DEFAULT_SCHEDULE_APPLICATION_PARAMETERS_DATA: IScheduleApplicationParametersColumnData =
	{};

export const DEFAULT_PANELS_CONFIG: Record<EManagementPanel, IPanelConfig> = {
	[EManagementPanel.Manage]: {
		title: 'Table Configurations',
		tooltip:
			'Here, you can manage the configuration of columns and rows, such as reordering, hiding, and freezing.'
	},
	[EManagementPanel.Edit]: {
		title: 'Edit Column',
		description:
			'To be able to create a new column, you need to choose the type of content you would want to configure.'
	},
	[EManagementPanel.Add]: {
		title: 'Add Column',
		description:
			'To be able to create a new column, you need to choose the type of content you would want to configure.'
	}
} as const;

export const SIDEPANEL_WIDTH_IN_PX = 360;
