import { Asset } from '@kelvininc/node-client-sdk';
import { EIllustrationName, IIllustrationMessage } from '@kelvininc/react-ui-components';

import { UseDropdownOptions } from '@kelvininc/types';

export const DEFAULT_SELECT_ASSET_LABEL = 'Asset';
export const DEFAULT_SELECT_ASSET_PLACEHOLDER = 'Select an Asset';
export const DEFAULT_SELECT_ASSET_SEARCH_PLACEHOLDER = 'Search for an Asset';
export const DEFAULT_SELECT_ASSET_NO_DATA_AVAILABLE_CONFIG: IIllustrationMessage = {
	illustration: EIllustrationName.NoDataAvailable,
	header: 'No Data Available',
	description: 'There are no Assets to display at the moment.'
};

export const USE_DROPDOWN_OPTIONS: UseDropdownOptions<Asset> = {
	key: 'name',
	label: 'title',
	value: 'name'
};
