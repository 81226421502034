'use client';

import { EParameterScheduleState } from '@kelvininc/node-client-sdk';

import {
	EActionButtonType,
	EComponentSize,
	KvActionButtonText
} from '@kelvininc/react-ui-components';
import { InfoLabelList, InfoLabelListSkeleton, Skeleton } from '@kelvininc/shared-ui';

import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import {
	ScheduleParameterTable,
	ScheduleParameterTableSkeleton
} from './components/ScheduleParameterTable';
import { useScheduleActionController } from './hooks/useScheduleActionController';
import { applicationScheduleDependenciesSelector } from './recoil/selectors';
import styles from './styles.module.scss';

import { EScheduleAction, ParameterScheduleAttributes } from './types';
import { buildScheduleHeaderInfoItems, isSingleAssetSchedule } from './utils';

import { appUserPermissionsMapSelector } from '@/src/recoil/users/selectors';

export const ApplicationScheduleCardSkeleton = () => {
	return (
		<div className={styles.ApplicationScheduleCard}>
			<div className={classNames(styles.ScheduleHeader, styles.HasActions)}>
				<div className={styles.InfoContainer}>
					<InfoLabelListSkeleton
						customClasses={[styles.InfoList, styles.ShowAllAssets]}
					/>
				</div>
				<div className={styles.HeaderActions}>
					<Skeleton height={28} width={62} />
					<Skeleton height={28} width={85} />
				</div>
			</div>
			<div className={styles.ScheduleBody}>
				<ScheduleParameterTableSkeleton />
			</div>
		</div>
	);
};

type ApplicationScheduleCardProps = {
	schedule: ParameterScheduleAttributes;
	closableParameters?: boolean;
	assetName?: string; //Allow show only the values for the specific asset
	refreshSchedules?: () => void;
	customClasses?: ClassNamesProp;
	hasActions?: boolean;
	hasAppColumn?: boolean;
};

export const ApplicationScheduleCard = ({
	schedule,
	closableParameters,
	assetName,
	refreshSchedules,
	customClasses,
	hasActions = true,
	hasAppColumn = false
}: ApplicationScheduleCardProps) => {
	const { users, assets, applications } = useRecoilValue(applicationScheduleDependenciesSelector);

	const { hasParameterUpdatePermission, hasParameterDeletePermission } = useRecoilValue(
		appUserPermissionsMapSelector
	);
	const actionFnSwitcher = useScheduleActionController(refreshSchedules);
	const allowActions = useMemo(
		() => (isEmpty(assetName) || isSingleAssetSchedule(schedule)) && hasActions,
		[assetName, schedule, hasActions]
	);
	const infoList = useMemo(
		() =>
			buildScheduleHeaderInfoItems({
				schedule,
				showAllAssets: isEmpty(assetName),
				showApp: hasAppColumn,
				users,
				assets,
				applications
			}),
		[applications, assetName, assets, hasAppColumn, schedule, users]
	);
	const buttonText =
		schedule.state === EParameterScheduleState.ScheduledRevert ? 'Revert Now' : 'Apply Now';

	return (
		<div className={classNames(styles.ApplicationScheduleCard, customClasses)}>
			<div
				className={classNames(styles.ScheduleHeader, {
					[styles.HasActions]: allowActions
				})}>
				<div className={styles.InfoContainer}>
					<InfoLabelList
						items={infoList}
						customClasses={classNames(styles.InfoList, {
							[styles.ShowAllAssets]: isEmpty(assetName),
							[styles.HasAppColumn]: hasAppColumn
						})}
					/>
				</div>
				{allowActions && (
					<div className={styles.HeaderActions}>
						{hasParameterDeletePermission && (
							<KvActionButtonText
								size={EComponentSize.Small}
								type={EActionButtonType.Ghost}
								text="Delete"
								onClickButton={() =>
									actionFnSwitcher(EScheduleAction.Delete, schedule)
								}
							/>
						)}
						{hasParameterUpdatePermission && (
							<KvActionButtonText
								size={EComponentSize.Small}
								type={EActionButtonType.Tertiary}
								text={buttonText}
								disabled={
									![
										EParameterScheduleState.Scheduled,
										EParameterScheduleState.ScheduledRevert
									].includes(schedule.state)
								}
								onClickButton={() =>
									actionFnSwitcher(EScheduleAction.ApplyNow, schedule)
								}
							/>
						)}
					</div>
				)}
			</div>
			<div className={styles.ScheduleBody}>
				<ScheduleParameterTable
					schedule={schedule}
					closableParameters={closableParameters}
					assetName={assetName}
					hasAlerts={hasActions}
				/>
			</div>
		</div>
	);
};
