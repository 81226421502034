import { ComputePositionConfig, flip, offset } from '@floating-ui/dom';

export const OPTIONS: Partial<ComputePositionConfig> = {
	placement: 'right-end',
	strategy: 'fixed',
	middleware: [
		offset({ mainAxis: 4, alignmentAxis: 8 }),
		flip({
			padding: 16,
			fallbackPlacements: ['right-start', 'bottom-end', 'bottom-start']
		})
	]
};
