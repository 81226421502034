import { EIconName, KvIcon } from '@kelvininc/react-ui-components';

import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './styles.module.scss';

const DEFAULT_TIP_TITLE = 'Keep in mind:';

type TipProps = {
	title?: string;
	description?: string;
	customClass?: ClassNamesProp;
};

export const Tip = ({
	title = DEFAULT_TIP_TITLE,
	description,
	customClass,
	children
}: PropsWithChildren<TipProps>) => (
	<div className={classNames(styles.TipContainer, customClass)}>
		<KvIcon name={EIconName.Protip} customClass="icon-20" />
		<div className={styles.TipTextContainer}>
			<span className={styles.TipTextBold}>{title}</span>
			<span className={styles.TipText}>{children ?? description}</span>
		</div>
	</div>
);
