import {
	AppManagerApp,
	AppManagerAppPlannerRules,
	AppManagerAppVersionDataMappingGet,
	AppManagerResource,
	AppManagerResourceContext,
	AppManagerService,
	EKvHttpStatusCode,
	EPaginatorType,
	IGetLastParametersResourcesParams,
	ParameterValueItem,
	ParametersService,
	ParamtersAppVersionSchemaGetPlain
} from '@kelvininc/node-client-sdk';
import {
	ECustomColumnType,
	ETableAdvancedFilterType,
	IAdvancedFiltersConfig
} from '@kelvininc/table';
import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { ESortDirection } from '@kelvininc/types';
import { keyBy } from 'lodash-es';
import { selector, selectorFamily } from 'recoil';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { kelvinApplicationsMapSelector } from '../app-registry/selectors';
import { appUserPermissionsMapSelector } from '../users/selectors';

import {
	appResourceAppProjectionsAtomFamily,
	appsAtomFamily,
	selectedAppNameAtom,
	selectedAppVersionAtom
} from './atoms';

import { filterParametersDefinitions } from './utils';

import {
	applicationParameterDefinitionsMapSelector,
	applicationParameterValuesMapSelector
} from '@/src/recoil/application-parameters/selectors';
import {
	assetPropertyDefinitionsMapSelector,
	assetPropertyValuesMapSelector
} from '@/src/recoil/asset-properties/selectors';
import {
	datastreamsMapSelector,
	datastreamsUnitMapSelector,
	nativeDataStreamsMapSelector
} from '@/src/recoil/data-streams/selectors';
import { closedLoopInstanceSettingSelector } from '@/src/recoil/instance-settings/selectors';
import { recommendationTypesMapSelector } from '@/src/recoil/recommendations/selectors';
import { IAssetInsightsCustomColumnsConfigMetadata } from '@/src/types/asset-insights';
import { filterAcceptedDatastreams } from '@/src/utils/asset-insights';

// TODO: This must be transformed into a react hook
export const selectedAppSelector = selector<AppManagerApp>({
	key: 'selectedAppSelector',
	get: ({ get }) => {
		const appName = get(selectedAppNameAtom);
		return get(appsAtomFamily(appName));
	}
});

// TODO: This must be transformed into a react hook
export const selectedAppResourceAppProjectionsSelector = selector<AppManagerResourceContext[]>({
	key: 'selectedAppResourceAppProjectionsSelector',
	get: ({ get }) => {
		const appName = get(selectedAppNameAtom);
		return get(appResourceAppProjectionsAtomFamily(appName));
	}
});

// TODO: This must be transformed into a react hook
export const selectedAppResourcesSelector = selector<AppManagerResource[]>({
	key: 'selectedAppResourcesSelector',
	get: ({ get }) => {
		const resourceAppProjections = get(selectedAppResourceAppProjectionsSelector);
		return resourceAppProjections.map(({ resource }) => resource);
	}
});

export const selectedAppParamsSchemaSelector = selector<
	ParamtersAppVersionSchemaGetPlain | undefined
>({
	key: 'selectedAppParamsSchemaSelector',
	get: ({ get }) => {
		const appName = get(selectedAppNameAtom);
		const appVersion = get(selectedAppVersionAtom);

		return ParametersService.getParamtersAppVersionSchema({ appName, version: appVersion })
			.pipe(
				map((data) => data.toJSONObject()),
				catchHttpError((error) => {
					if (error.status === EKvHttpStatusCode.NotFound) {
						return of(undefined);
					}

					return throwHttpError(error);
				})
			)
			.toPromise();
	}
});

// TODO: This must be transformed into a react hook
export const selectedAppVersionsResourceAppProjectionsMapSelector = selector<
	Record<string, AppManagerResourceContext[]>
>({
	key: 'selectedAppVersionsResourceAppProjectionsMapSelector',
	get: ({ get }) => {
		const appResourceAppProjections = get(selectedAppResourceAppProjectionsSelector);
		return appResourceAppProjections.reduce<Record<string, AppManagerResourceContext[]>>(
			(acc, appResource) => {
				(acc[appResource.app.version] || (acc[appResource.app.version] = [])).push(
					appResource
				);
				return acc;
			},
			{}
		);
	}
});

export const selectedAppPlannerRulesSelector = selector<AppManagerAppPlannerRules | undefined>({
	key: 'selectedAppPlannerRulesSelector',
	get: ({ get }) => {
		const appName = get(selectedAppNameAtom);

		return AppManagerService.getAppManagerAppPlannerRules({ appName })
			.pipe(
				catchHttpError((error) => {
					if (error.status === EKvHttpStatusCode.NotFound) {
						return of(undefined);
					}

					return throwHttpError(error);
				})
			)
			.toPromise();
	}
});

export const assetsParametersDetailsSelector = selector<Record<string, ParameterValueItem>>({
	key: 'assetsParametersDetailsSelector',
	get: ({ get }) => {
		const selectedApp = get(selectedAppNameAtom);
		const selectedVersion = get(selectedAppVersionAtom);
		const params: IGetLastParametersResourcesParams<EPaginatorType.Stream> = {
			sortBy: ['updated'],
			direction: ESortDirection.Asc,
			paginationType: EPaginatorType.Stream,
			lastParametersResourcesGetData: {
				apps: [
					{
						name: selectedApp,
						version: selectedVersion
					}
				]
			}
		};
		return ParametersService.getLastParametersResources(params)
			.pipe(map((data) => keyBy(data, 'resource')))
			.toPromise();
	}
});
export const selectedAppVersionMappingSelectorFamily = selectorFamily<
	AppManagerAppVersionDataMappingGet,
	string
>({
	key: 'selectedAppVersionMappingSelectorFamily',
	get:
		(version: string) =>
		async ({ get }) => {
			const appName = get(selectedAppNameAtom);

			return AppManagerService.getAppManagerAppVersionDataMapping({
				appName,
				version,
				appManagerAppVersionDataMappingGetData: {}
			})
				.pipe(catchHttpError(throwHttpError))
				.toPromise();
		}
});

export const appAssetsListTableCustomColumnsConfigMetadataSelector =
	selector<IAssetInsightsCustomColumnsConfigMetadata>({
		key: 'appAssetsListTableCustomColumnsConfigMetadataSelector',
		get: ({ get }) => {
			const modelApp = get(selectedAppSelector);
			const properties = get(assetPropertyDefinitionsMapSelector);
			const recommendationTypes = get(recommendationTypesMapSelector);
			const datastreams = get(nativeDataStreamsMapSelector);
			const datastreamUnits = get(datastreamsUnitMapSelector);
			const parameters = get(applicationParameterDefinitionsMapSelector);
			const closedLoopSettings = get(closedLoopInstanceSettingSelector);
			const permissions = get(appUserPermissionsMapSelector);

			return {
				[ECustomColumnType.AssetProperty]: { properties },
				[ECustomColumnType.Datastream]: { datastreams, datastreamUnits },
				[ECustomColumnType.LastControlChange]: { datastreams, datastreamUnits },
				[ECustomColumnType.Recommendation]: {
					closedLoopSettings,
					recommendationTypes,
					applications: {},
					application: modelApp.app.name,
					permissions
				},
				[ECustomColumnType.ApplicationParameter]: {
					closedLoopSettings,
					parameters,
					applications: {},
					application: modelApp.app.name
				},
				[ECustomColumnType.ScheduleApplicationParameters]: {
					parameters,
					closedLoopSettings
				}
			};
		}
	});

export const appAssetsListTableAdvancedFiltersConfigSelector = selector<IAdvancedFiltersConfig>({
	key: 'appAssetsListTableAdvancedFiltersConfigSelector',
	get: ({ get }) => {
		const schema = get(selectedAppParamsSchemaSelector);
		const propertyDefinitions = get(assetPropertyDefinitionsMapSelector);
		const propertyValues = get(assetPropertyValuesMapSelector);
		const applications = get(kelvinApplicationsMapSelector);
		const parametersDefinitions = get(applicationParameterDefinitionsMapSelector);
		const parametersValues = get(applicationParameterValuesMapSelector);
		const closedLoopSettings = get(closedLoopInstanceSettingSelector);
		const modelApp = get(selectedAppSelector);
		const datastreams = get(datastreamsMapSelector);

		const filteredParametersDefinitions = filterParametersDefinitions(
			schema,
			parametersDefinitions,
			modelApp
		);

		return {
			[ETableAdvancedFilterType.AssetProperty]: {
				type: ETableAdvancedFilterType.AssetProperty,
				metadata: {
					properties: {
						definitions: propertyDefinitions,
						values: propertyValues
					}
				}
			},
			[ETableAdvancedFilterType.ApplicationParameter]: {
				type: ETableAdvancedFilterType.ApplicationParameter,
				metadata: {
					applications: {
						definitions: applications,
						parameters: filteredParametersDefinitions,
						values: parametersValues
					},
					application: modelApp.app.name,
					closedLoopSettings
				}
			},
			[ETableAdvancedFilterType.Datastream]: {
				type: ETableAdvancedFilterType.Datastream,
				metadata: {
					datastreams: {
						definitions: filterAcceptedDatastreams(datastreams)
					}
				}
			}
		};
	}
});
