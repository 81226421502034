import { ISettingStorageConfig, useSetting } from '@kelvininc/shared-ui';
import {
	EDensityLevel,
	ETableSettingKey,
	IBaseTData,
	IColumnDef,
	IPaginationInfo,
	IRowsSettingsConfig,
	ISortInfo,
	ITableAdvancedFilters,
	ITableFilters,
	ITableSettings,
	ITablesSettings
} from '@kelvininc/table';
import { isFunction, merge, pick } from 'lodash-es';
import { SetStateAction, useCallback, useMemo } from 'react';

import { RecoilState } from 'recoil';

import { IUseTableSettings } from '../useTableSettings';
import {
	buildColumnsHidden,
	buildColumnsOrder,
	buildColumnsPinned,
	filterCustomColumns
} from '../useTableSettings/utils';

import { deserializeTablesSettings, serializeTablesSettings } from './utils';

import { getTablesSettingsSettingKey } from '@/src/recoil/settings/utils';

export const useTablesSettings = <
	TData extends IBaseTData = IBaseTData,
	TTableSerialized = ITableSettings<TData>
>(
	key: string,
	tableKey: string,
	defaultValue: ITableSettings<TData> = {},
	recoilState: RecoilState<ITablesSettings<TData>>,
	config: ISettingStorageConfig<ITablesSettings<TData>, Record<string, TTableSerialized>> = {
		serializer: serializeTablesSettings,
		deserializer: deserializeTablesSettings
	}
): IUseTableSettings<TData> => {
	const {
		value: setting = {},
		setValue: setTablesSettings,
		resetValue
	} = useSetting<ITablesSettings<TData>, Record<string, TTableSerialized>>(
		getTablesSettingsSettingKey(key),
		{},
		recoilState,
		config
	);

	const settings = useMemo(
		() => pick(merge({}, defaultValue, setting[tableKey]), Object.values(ETableSettingKey)),
		[defaultValue, setting, tableKey]
	);

	const setSettings = useCallback(
		(stateAction: SetStateAction<ITableSettings<TData>>) =>
			setTablesSettings((previousTablesSettings: ITablesSettings<TData>) => {
				const newTableSettings: ITableSettings<TData> = isFunction(stateAction)
					? stateAction(merge({}, defaultValue, previousTablesSettings[tableKey]))
					: stateAction;

				return {
					...previousTablesSettings,
					[tableKey]: newTableSettings
				};
			}),
		[defaultValue, setTablesSettings, tableKey]
	);

	const setSettingsValue = useCallback(
		<SettingKey extends keyof ITableSettings<TData>>(
			settingKey: SettingKey,
			newValue: ITableSettings<TData>[SettingKey]
		) =>
			setSettings((previousSettings) => ({
				...previousSettings,
				[settingKey]: newValue
			})),
		[setSettings]
	);

	const onColumnsChange = useCallback(
		(newColumns: IColumnDef<TData>[]) =>
			setSettings((previousSettings) => ({
				...previousSettings,
				[ETableSettingKey.ColumnsOrder]: buildColumnsOrder(newColumns),
				[ETableSettingKey.ColumnsPinned]: buildColumnsPinned(newColumns),
				[ETableSettingKey.ColumnsHidden]: buildColumnsHidden(newColumns),
				[ETableSettingKey.ColumnsCustom]: filterCustomColumns(newColumns)
			})),
		[setSettings]
	);
	const onPaginationChange = useCallback(
		(newPageInfo: IPaginationInfo = {}) =>
			setSettingsValue(ETableSettingKey.PageSize, newPageInfo.pageSize),
		[setSettingsValue]
	);
	const onDensityChange = useCallback(
		(newDensity?: EDensityLevel) => setSettingsValue(ETableSettingKey.Density, newDensity),
		[setSettingsValue]
	);
	const onSortChange = useCallback(
		(newSort: ISortInfo = {}) =>
			setSettings((previousSettings) => ({
				...previousSettings,
				[ETableSettingKey.SortBy]: newSort.sortBy,
				[ETableSettingKey.SortDirection]: newSort.sortDirection
			})),
		[setSettings]
	);
	const onRowsSettingsChange = useCallback(
		(newRowsSettings: IRowsSettingsConfig) =>
			setSettingsValue(ETableSettingKey.RowsSettings, newRowsSettings),
		[setSettingsValue]
	);
	const onRowsPinnedChange = useCallback(
		(newRowsPinned: TData[]) => setSettingsValue(ETableSettingKey.RowsPinned, newRowsPinned),
		[setSettingsValue]
	);
	const onFiltersChange = useCallback(
		(newFilters?: ITableFilters) => setSettingsValue(ETableSettingKey.Filters, newFilters),
		[setSettingsValue]
	);
	const onAdvancedFiltersChange = useCallback(
		(newFilters?: ITableAdvancedFilters) =>
			setSettingsValue(ETableSettingKey.AdvancedFilters, newFilters),
		[setSettingsValue]
	);

	const onResetSettings = useCallback(() => resetValue(), [resetValue]);

	const callbacks = useMemo(
		() => ({
			onColumnsChange,
			onPaginationChange,
			onDensityChange,
			onSortChange,
			onRowsSettingsChange,
			onRowsPinnedChange,
			onFiltersChange,
			onAdvancedFiltersChange,
			onResetSettings
		}),
		[
			onColumnsChange,
			onDensityChange,
			onPaginationChange,
			onRowsPinnedChange,
			onRowsSettingsChange,
			onSortChange,
			onFiltersChange,
			onAdvancedFiltersChange,
			onResetSettings
		]
	);

	return {
		settings,
		callbacks
	};
};
