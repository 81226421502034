import {
	Context,
	DataStream,
	DataStreamDataType,
	DataStreamSemanticType,
	DatastreamsService,
	EKvHttpStatusCode,
	Unit
} from '@kelvininc/node-client-sdk';
import { catchHttpError, isDataStreamDataTypeObject, throwHttpError } from '@kelvininc/tsutils';
import { keyBy } from 'lodash-es';
import { selector, selectorFamily } from 'recoil';

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
	datastreamsDataTypeListAtom,
	datastreamsListAtom,
	datastreamsSemanticTypeListAtom,
	datastreamsUnitListAtom
} from './atoms';

export const datastreamContextsSelectorFamily = selectorFamily<Context[], string>({
	key: 'datastreamContextsSelectorFamily',
	get: (id: string) => () =>
		DatastreamsService.getDataStreamContext({
			dataStreamName: id
		})
			.pipe(
				map((data) => data.context),
				catchHttpError((error) => {
					if (error.status === EKvHttpStatusCode.NotFound) {
						return of([]);
					}

					return throwHttpError(error);
				})
			)
			.toPromise()
});

export const datastreamsMapSelector = selector<Record<string, DataStream>>({
	key: 'datastreamsMapSelector',
	get: ({ get }) => keyBy(get(datastreamsListAtom), 'name')
});

export const nativeDataStreamsListSelector = selector<DataStream[]>({
	key: 'nativeDataStreamsListSelector',
	get: ({ get }) => {
		const datastreams = get(datastreamsListAtom);

		return datastreams.filter(
			(datastream) => !isDataStreamDataTypeObject(datastream.dataTypeName)
		);
	}
});

export const nativeDataStreamsMapSelector = selector<Record<string, DataStream>>({
	key: 'nativeDataStreamsMapSelector',
	get: ({ get }) => keyBy(get(nativeDataStreamsListSelector), 'name')
});

export const datastreamsUnitMapSelector = selector<Record<string, Unit>>({
	key: 'datastreamsUnitMapSelector',
	get: ({ get }) => {
		const units = get(datastreamsUnitListAtom);
		return keyBy(units, 'name');
	}
});

export const datastreamsSemanticTypeMapSelector = selector<Record<string, DataStreamSemanticType>>({
	key: 'datastreamsSemanticTypesSelector',
	get: ({ get }) => {
		const semanticTypes = get(datastreamsSemanticTypeListAtom);
		return keyBy(semanticTypes, 'name');
	}
});

export const datastreamsDataTypeMapSelector = selector<Record<string, DataStreamDataType>>({
	key: 'datastreamsDataTypeMapSelector',
	get: ({ get }) => {
		const dataTypes = get(datastreamsDataTypeListAtom);
		return keyBy(dataTypes, 'name');
	}
});
