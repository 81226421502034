import { AppItem, ParameterDefinitionItem } from '@kelvininc/node-client-sdk';
import { KvSingleSelectDropdown } from '@kelvininc/react-ui-components';
import { useDropdownSearchOptions } from '@kelvininc/shared-ui';
import { getParameterTitle } from '@kelvininc/tsutils';
import {
	IApplicationParameterDefinition,
	IClosedLoopSettings,
	ReactState,
	UseDropdownOptions
} from '@kelvininc/types';
import { isEmpty } from 'lodash-es';

import { useMemo } from 'react';

import { DEFAULT_CLOSED_LOOP_SETTINGS } from '../../../../../../../../../config';

import { IApplicationParameterColumnData } from '../../../../../../../AgGridTable';

import {
	APPLICATION_PARAMETER_COLUMN_APPLICATION_LABEL,
	APPLICATION_PARAMETER_COLUMN_APPLICATION_PLACEHOLDER,
	APPLICATION_PARAMETER_COLUMN_PARAMETER_LABEL,
	APPLICATION_PARAMETER_COLUMN_PARAMETER_PLACEHOLDER,
	USE_DROPDOWN_APPLICATION_OPTIONS
} from './config';
import styles from './styles.module.scss';

import { getApplicationsList, getParametersList } from './utils';

type ApplicationParameterSettings = {
	state: ReactState<IApplicationParameterColumnData>;
	closedLoopSettings?: IClosedLoopSettings;
	parameters?: IApplicationParameterDefinition;
	applications?: Record<string, AppItem>;
};

export const ApplicationParameterSettings = ({
	state,
	parameters = {},
	applications = {},
	closedLoopSettings = DEFAULT_CLOSED_LOOP_SETTINGS
}: ApplicationParameterSettings) => {
	const [settings, setState] = state;

	const applicationsList = getApplicationsList(applications, parameters);
	const parametersList = getParametersList({
		application: settings.application,
		parameters,
		closedLoopSettings
	});

	const onSelectApplication = ({ detail: newApplication }: CustomEvent<string>) =>
		setState((previousState) => ({
			...previousState,
			application: newApplication
		}));

	const onSelectParameter = ({ detail: newParameter }: CustomEvent<string>) =>
		setState((previousState) => ({
			...previousState,
			parameter: newParameter
		}));

	const parametersDropdownOptions = useMemo<UseDropdownOptions<ParameterDefinitionItem>>(
		() => ({
			key: 'name',
			value: 'name',
			label: (item) => getParameterTitle(item, closedLoopSettings)
		}),
		[closedLoopSettings]
	);
	const {
		options: parametersOptions,
		filteredOptions: filteredParametersOptions,
		setSearchTerm: setParametersearch
	} = useDropdownSearchOptions(parametersList, parametersDropdownOptions);

	const {
		options: applicationsOptions,
		filteredOptions: filteredApplicationsOptions,
		setSearchTerm: setApplicationSearch
	} = useDropdownSearchOptions(applicationsList, USE_DROPDOWN_APPLICATION_OPTIONS);

	return (
		<div className={styles.ApplicationParameterSettings}>
			{!isEmpty(applications) && (
				<KvSingleSelectDropdown
					required
					label={APPLICATION_PARAMETER_COLUMN_APPLICATION_LABEL}
					placeholder={APPLICATION_PARAMETER_COLUMN_APPLICATION_PLACEHOLDER}
					options={applicationsOptions}
					filteredOptions={filteredApplicationsOptions}
					selectedOption={settings?.application}
					onOptionSelected={onSelectApplication}
					onSearchChange={({ detail: newSearchTerm }) =>
						setApplicationSearch(newSearchTerm)
					}
				/>
			)}
			<KvSingleSelectDropdown
				required
				label={APPLICATION_PARAMETER_COLUMN_PARAMETER_LABEL}
				placeholder={APPLICATION_PARAMETER_COLUMN_PARAMETER_PLACEHOLDER}
				options={parametersOptions}
				filteredOptions={filteredParametersOptions}
				selectedOption={settings?.parameter}
				onOptionSelected={onSelectParameter}
				onSearchChange={({ detail: newSearchTerm }) => setParametersearch(newSearchTerm)}
				disabled={!settings?.application}
			/>
		</div>
	);
};
