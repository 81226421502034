import { ComputePositionConfig, MiddlewareState, offset, size } from '@floating-ui/dom';

const TIME_PICKER_DROPDOWN_OFFSET = 8;

export const TIME_PICKER_DROPDOWN_POSITION_CONFIG: Partial<ComputePositionConfig> = {
	placement: 'bottom-end',
	middleware: [
		offset(TIME_PICKER_DROPDOWN_OFFSET),
		size({
			apply({ elements }: MiddlewareState) {
				Object.assign(elements.floating.style, {
					width: 'max-content'
				});
			}
		})
	]
};
