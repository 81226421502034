import { KvCheckbox } from '@kelvininc/react-ui-components';

import { useMemo } from 'react';

import { IBaseTData, IHeaderRendererParams } from '../../../core';
import { useIsStateNotHasResults } from '../../../core/components/Table/contexts';
import { withBaseHeaderCellRenderer } from '../BaseHeaderCellRenderer/hocs';

import { getSelectableDisplayedNodes, getSelectedDisplayedNodes } from './utils';

const Component = <TData extends IBaseTData>({ api, context }: IHeaderRendererParams<TData>) => {
	const isClickDisabled = useIsStateNotHasResults();
	const selectableDisplayedRows = useMemo(() => getSelectableDisplayedNodes(api), [api]);
	const selectedDisplayedRows = useMemo(
		() => getSelectedDisplayedNodes(api, context),
		[api, context]
	);

	const { selectionApi, maxSelectedRows } = context || {};
	if (!selectionApi || maxSelectedRows !== undefined) {
		return null;
	}

	const areAllRowsDisabled =
		selectedDisplayedRows.length === 0 && selectableDisplayedRows.length === 0;
	const areAllRowsSelected =
		!areAllRowsDisabled && selectedDisplayedRows.length === selectableDisplayedRows.length;
	const isAnyRowSelected = Object.keys(selectionApi.selectedRows).length > 0;
	const onClick = () => {
		if (!api || isClickDisabled) {
			return;
		}

		if (!isAnyRowSelected) {
			selectionApi.selectAll();
		} else {
			selectionApi.deselectAll();
		}
	};

	return (
		<KvCheckbox
			checked={areAllRowsSelected}
			indeterminate={isAnyRowSelected && !areAllRowsSelected}
			disabled={areAllRowsDisabled}
			onClickCheckbox={onClick}
		/>
	);
};

export const HeaderSelectionCellRenderer = withBaseHeaderCellRenderer(Component);
