import { KvIcon } from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import { Link } from '../../../Link';

import styles from './styles.module.scss';

import { IExpandableMenuItem } from './types';

type NavigationItemProps = {
	item: IExpandableMenuItem;
	isCollapsed: boolean;
	customClasses?: ClassNamesProp;
};

export const NavigationItem = ({ item, isCollapsed, customClasses }: NavigationItemProps) => {
	return (
		<Link
			key={item.id}
			href={item.url || ''}
			className={classNames(styles.Item, customClasses, {
				[styles.ItemActive]: item.active
			})}
			target={item.target}
			rel="noreferrer"
			onClick={item.onClick}
			prefetch={item.prefetch}
			disabled={item.disabled}>
			<div className={styles.ItemText}>
				{item.leftIcon && <KvIcon name={item.leftIcon} />}
				{!isCollapsed && <span className={styles.Text}>{item.text}</span>}
			</div>
			{!isCollapsed && item.rightIcon && (
				<div className={styles.RightIcon}>
					<KvIcon name={item.rightIcon} />
				</div>
			)}
		</Link>
	);
};
