'use client';

import { KvTabNavigation } from '@kelvininc/react-ui-components';
import {
	CodeTranslatorEditor,
	ECodeTranslatorEditorLanguage,
	ICodeTranslatorEditorChangeData
} from '@kelvininc/shared-ui';
import { useCallback, useState } from 'react';

import styles from './styles.module.scss';
import { ITabbedCodeTranslatorProps } from './types';
import { buildLanguageTabsConfig } from './utils';

export const TabbedCodeTranslator = ({
	object,
	language: initialLanguage = ECodeTranslatorEditorLanguage.YAML,
	readOnly = false,
	languages,
	onChange
}: ITabbedCodeTranslatorProps) => {
	const [language, setLanguage] = useState(initialLanguage);
	const [tabs, setTabs] = useState(buildLanguageTabsConfig(languages, language, true));

	const editorChange = useCallback(
		(changes: ICodeTranslatorEditorChangeData) => {
			setTabs(buildLanguageTabsConfig(languages, language, changes.valid));
			onChange(changes);
		},
		[languages, language, onChange]
	);

	const languageChange = useCallback(
		({ detail }: CustomEvent<string>) => {
			if (detail === language) return;
			setLanguage(detail as ECodeTranslatorEditorLanguage);
		},
		[language]
	);

	return (
		<div className={styles.EditorContent}>
			<KvTabNavigation tabs={tabs} selectedTabKey={language} onTabChange={languageChange} />
			<div className={styles.CodeEditor}>
				<CodeTranslatorEditor
					object={object}
					language={language}
					readOnly={readOnly}
					onChange={editorChange}
				/>
			</div>
		</div>
	);
};
