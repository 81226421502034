import { ComputePositionConfig, flip, offset } from '@floating-ui/dom';
import { EComponentSize, SchemaFormProps } from '@kelvininc/react-ui-components';

import {
	DEFAULT_DROPDOWN_FILTER_MAX_HEIGHT,
	DEFAULT_DROPDOWN_FILTER_MIN_WIDTH
} from '../../../../../../../config';

import {
	EQUALITY_OPTIONS,
	EXISTENCE_OPERATORS_OPTIONS,
	RELATIONAL_OPERATORS_OPTIONS,
	STRING_OPERATORS_OPTIONS
} from '../../../../../../../utils/filters';

import { ApplicationParameterAdvancedFilterSchemaFormData } from './components/ApplicationParameterFiltersForm/types';
import { AssetPropertyAdvancedFilterSchemaFormData } from './components/AssetPropertyFiltersForm/types';

import { DatastreamAdvancedFilterSchemaFormData } from './components/DatastreamFiltersForm/types';

export const ADVANCED_FILTER_DROPDOWN_Z_INDEX = 9;

export const MINIMUM_MULTI_SELECTABLE_OPTIONS = 3;

export const SELECT_OPERATOR_PLACEHOLDER = 'Operator';

export const SELECT_VALUE_PLACEHOLDER = 'Value';

export const ADVANCED_FILTER_DROPDOWN_OPTIONS: Partial<ComputePositionConfig> = {
	placement: 'bottom-start',
	middleware: [
		offset(8),
		flip({
			padding: 16
		})
	]
};
export const FILTER_UI_SCHEMA: SchemaFormProps<
	| AssetPropertyAdvancedFilterSchemaFormData
	| ApplicationParameterAdvancedFilterSchemaFormData
	| DatastreamAdvancedFilterSchemaFormData
>['uiSchema'] = {
	operator: {
		'ui:title': '',
		'ui:placeholder': SELECT_OPERATOR_PLACEHOLDER,
		searchable: false
	},
	value: {
		'ui:title': '',
		'ui:placeholder': SELECT_VALUE_PLACEHOLDER
	},
	'ui:submitButtonOptions': {
		norender: true
	},
	'ui:inline': true,
	'ui:componentSize': EComponentSize.Small,
	'ui:inputConfig': { width: 'fit-content', minWidth: '60px', maxWidth: '160px' },
	'ui:dropdownConfig': {
		zIndex: 10,
		minWidth: DEFAULT_DROPDOWN_FILTER_MIN_WIDTH,
		maxHeight: DEFAULT_DROPDOWN_FILTER_MAX_HEIGHT
	}
};

export const PRIMITIVE_TYPE_NUMBER_FILTER_SCHEMA: SchemaFormProps<
	AssetPropertyAdvancedFilterSchemaFormData | ApplicationParameterAdvancedFilterSchemaFormData
>['schema'] = {
	type: 'object',
	properties: {
		operator: {
			$ref: '#/definitions/number_operators'
		}
	},
	required: ['operator'],
	dependencies: {
		operator: {
			oneOf: [
				{
					type: 'object',
					properties: {
						operator: {
							oneOf: RELATIONAL_OPERATORS_OPTIONS
						},
						value: {
							$ref: '#/definitions/number_value'
						}
					},
					required: ['value']
				},
				{
					type: 'object',
					properties: {
						operator: {
							oneOf: EXISTENCE_OPERATORS_OPTIONS
						}
					}
				}
			]
		}
	},
	definitions: {
		number_operators: {
			type: 'string',
			oneOf: [...RELATIONAL_OPERATORS_OPTIONS, ...EXISTENCE_OPERATORS_OPTIONS]
		},
		number_value: {
			type: 'number'
		}
	}
};

export const PRIMITIVE_TYPE_STRING_FILTER_SCHEMA: SchemaFormProps<
	AssetPropertyAdvancedFilterSchemaFormData | ApplicationParameterAdvancedFilterSchemaFormData
>['schema'] = {
	type: 'object',
	properties: {
		operator: {
			$ref: '#/definitions/string_operators'
		}
	},
	required: ['operator'],
	dependencies: {
		operator: {
			oneOf: [
				{
					type: 'object',
					properties: {
						operator: {
							oneOf: EQUALITY_OPTIONS
						},
						value: {
							$ref: '#/definitions/string_options_value'
						}
					},
					required: ['value']
				},
				{
					type: 'object',
					properties: {
						operator: {
							oneOf: STRING_OPERATORS_OPTIONS
						},
						value: {
							$ref: '#/definitions/string_value'
						}
					},
					required: ['value']
				},
				{
					type: 'object',
					properties: {
						operator: {
							oneOf: EXISTENCE_OPERATORS_OPTIONS
						}
					}
				}
			]
		}
	},
	definitions: {
		string_operators: {
			type: 'string',
			oneOf: [
				...EQUALITY_OPTIONS,
				...STRING_OPERATORS_OPTIONS,
				...EXISTENCE_OPERATORS_OPTIONS
			]
		},
		string_value: {
			type: 'string'
		},
		string_options_value: {
			type: 'string'
		}
	}
};

export const PRIMITIVE_TYPE_STRING_ARRAY_FILTER_SCHEMA: SchemaFormProps<
	AssetPropertyAdvancedFilterSchemaFormData | ApplicationParameterAdvancedFilterSchemaFormData
>['schema'] = {
	type: 'object',
	properties: {
		operator: {
			$ref: '#/definitions/string_operators'
		}
	},
	required: ['operator'],
	dependencies: {
		operator: {
			oneOf: [
				{
					type: 'object',
					properties: {
						operator: {
							oneOf: EQUALITY_OPTIONS
						},
						value: {
							$ref: '#/definitions/string_options_value'
						}
					},
					required: ['value']
				},
				{
					type: 'object',
					properties: {
						operator: {
							oneOf: EXISTENCE_OPERATORS_OPTIONS
						}
					}
				}
			]
		}
	},
	definitions: {
		string_operators: {
			type: 'string',
			oneOf: [...EQUALITY_OPTIONS, ...EXISTENCE_OPERATORS_OPTIONS]
		},
		string_value: {
			type: 'string'
		},
		string_options_value: {
			type: 'string'
		}
	}
};

export const PRIMITIVE_TYPE_BOOLEAN_FILTER_SCHEMA: SchemaFormProps<
	AssetPropertyAdvancedFilterSchemaFormData | ApplicationParameterAdvancedFilterSchemaFormData
>['schema'] = {
	type: 'object',
	properties: {
		operator: {
			$ref: '#/definitions/boolean_operators'
		}
	},
	required: ['operator'],
	dependencies: {
		operator: {
			oneOf: [
				{
					type: 'object',
					properties: {
						operator: {
							oneOf: EQUALITY_OPTIONS
						},
						value: {
							$ref: '#/definitions/boolean_value'
						}
					},
					required: ['value']
				},
				{
					type: 'object',
					properties: {
						operator: {
							oneOf: EXISTENCE_OPERATORS_OPTIONS
						}
					}
				}
			]
		}
	},
	definitions: {
		boolean_operators: {
			type: 'string',
			oneOf: [...EQUALITY_OPTIONS, ...EXISTENCE_OPERATORS_OPTIONS]
		},
		boolean_value: {
			type: 'string',
			oneOf: [
				{
					title: 'True',
					const: true
				},
				{
					title: 'False',
					const: false
				}
			]
		}
	}
};

export const PRIMITIVE_TYPE_TIMESTAMP_FILTER_SCHEMA: SchemaFormProps<
	AssetPropertyAdvancedFilterSchemaFormData | ApplicationParameterAdvancedFilterSchemaFormData
>['schema'] = {
	type: 'object',
	properties: {
		operator: {
			$ref: '#/definitions/timestamp_operators'
		}
	},
	required: ['operator'],
	dependencies: {
		operator: {
			oneOf: [
				{
					type: 'object',
					properties: {
						operator: {
							oneOf: RELATIONAL_OPERATORS_OPTIONS
						},
						value: {
							$ref: '#/definitions/timestamp_value'
						}
					},
					required: ['value']
				},
				{
					type: 'object',
					properties: {
						operator: {
							oneOf: EXISTENCE_OPERATORS_OPTIONS
						}
					}
				}
			]
		}
	},
	definitions: {
		timestamp_operators: {
			type: 'string',
			oneOf: [...RELATIONAL_OPERATORS_OPTIONS, ...EXISTENCE_OPERATORS_OPTIONS]
		},
		timestamp_value: {
			type: 'string',
			format: 'date-time'
		}
	}
};
