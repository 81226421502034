import { DroppableZone, EDraggableItemsSpacing } from '@kelvininc/shared-ui';
import { useMemo } from 'react';

import { IBaseTData } from '../../../../../../../AgGridTable';
import { IIndexedColumnDef } from '../../types';

import { ColumnsPlaceholder } from '../ColumnsPlaceholder';

import {
	COLUMNS_DRAGGABLE_ZONE_TYPE,
	EMPTY_COLUMNS_SECTION_PLACEHOLDER,
	SEARCH_NO_RESULTS_TEXT,
	SEARCH_NO_RESULTS_TIP
} from './config';

import { useFilteredItems } from './hooks';
import styles from './styles.module.scss';

type ColumnsDroppableZoneProps<TData extends IBaseTData> = {
	id: string;
	items: IIndexedColumnDef<TData>[];
	searching?: boolean;
	hiding?: boolean;
	onClickEdit?: (itemIndex: number) => void;
	onClickVisibility?: (itemIndex: number) => void;
	disabled?: boolean;
};

export const ColumnsDroppableZone = <TData extends IBaseTData>({
	id,
	items,
	searching = false,
	hiding = false,
	disabled = false,
	onClickEdit,
	onClickVisibility
}: ColumnsDroppableZoneProps<TData>) => {
	const filteredItems = useFilteredItems(items, hiding, onClickEdit, onClickVisibility);
	const hasNoSearchResults = useMemo(
		() => filteredItems.length === 0 && searching,
		[filteredItems.length, searching]
	);
	const hasNoFilterResults = useMemo(
		() => items.length > 0 && filteredItems.length === 0 && hiding,
		[filteredItems.length, hiding, items.length]
	);

	if (hasNoSearchResults) {
		return (
			<div data-test-id="e2e-columns-droppable-search-empty" className={styles.SearchEmpty}>
				<span className={styles.Bold}>{SEARCH_NO_RESULTS_TEXT}</span>{' '}
				{SEARCH_NO_RESULTS_TIP}
			</div>
		);
	}

	return (
		<DroppableZone
			id={id}
			items={filteredItems}
			type={COLUMNS_DRAGGABLE_ZONE_TYPE}
			placeholderText={hasNoFilterResults ? undefined : EMPTY_COLUMNS_SECTION_PLACEHOLDER}
			CustomPlaceholder={ColumnsPlaceholder}
			disabled={disabled}
			dragDisabled={searching}
			spacing={EDraggableItemsSpacing.Small}
		/>
	);
};
