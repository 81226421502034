import {
	AssetKRN,
	EPaginatorType,
	EParameterScheduleState,
	ParameterSchedule,
	ParametersService
} from '@kelvininc/node-client-sdk';
import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';

export const getAssetParametersSchedules = (assetName: string): Promise<ParameterSchedule[]> =>
	ParametersService.listParametersSchedule({
		parametersScheduleListData: {
			resources: [AssetKRN.toKRN({ asset: assetName })],
			states: [EParameterScheduleState.Scheduled, EParameterScheduleState.ScheduledRevert]
		},
		sortBy: ['scheduled_for'],
		paginationType: EPaginatorType.Stream
	})
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
