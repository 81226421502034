import { EActionButtonType, EIconName, KvActionButtonIcon } from '@kelvininc/react-ui-components';
import classNames from 'classnames';

import { IBaseTData, ICellRendererParams } from '../../../core/components/AgGridTable';
import { withBaseCellRenderer } from '../BaseCellRenderer';

import styles from './styles.module.scss';

const Component = <TData extends IBaseTData>(props: ICellRendererParams<TData>) => {
	const { node, api } = props;

	const isExpanded = node?.expanded === true;
	const isExpandable = node?.isExpandable() === true;

	const onClickButton = () => {
		if (!api || !node) {
			return;
		}

		node.setExpanded(!isExpanded);
		api.refreshCells({ rowNodes: [node], force: true });
	};

	if (!isExpandable) {
		return null;
	}

	return (
		<div className={classNames(styles.ExpandCellRenderer, { [styles.Expanded]: isExpanded })}>
			<KvActionButtonIcon
				icon={EIconName.Collapse}
				onClickButton={onClickButton}
				type={EActionButtonType.Ghost}
			/>
		</div>
	);
};

export const ExpandCellRenderer = withBaseCellRenderer(Component);
