import { syncSettingInitialValue } from '@kelvininc/shared-ui';
import { atom } from 'recoil';

import {
	DEFAULT_DEPLOYED_SMART_APPS,
	DEFAULT_SMART_APPS,
	DEPLOYED_SMART_APPS_SETTING_KEY,
	SMART_APPS_SETTING_KEY
} from '../../hooks/useSmartAppsSettings/config';

import { ISmartAppCatalog } from '@/src/types/smart-app';

export const smartAppsCatalogAtom = atom<ISmartAppCatalog>({
	key: 'smartAppsCatalogAtom',
	effects: [syncSettingInitialValue(SMART_APPS_SETTING_KEY, DEFAULT_SMART_APPS)]
});

export const deployedSmartAppsCatalogAtom = atom<string[]>({
	key: 'deployedSmartAppsCatalogAtom',
	effects: [syncSettingInitialValue(DEPLOYED_SMART_APPS_SETTING_KEY, DEFAULT_DEPLOYED_SMART_APPS)]
});
