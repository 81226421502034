import { cancelableDelayMilliseconds, getRandomNumberInRange } from '@kelvininc/tsutils';
import AbortablePromise from 'promise-abortable';
import { useCallback, useEffect, useRef, useState } from 'react';

import { buildInitialRequirementsState } from './utils';

import { EValidationStep, IValidationItem } from '@/src/types';

export const useRequirementValidation = (
	canStartValidation: boolean,
	finalRequirementsState: Record<string, IValidationItem[]>,
	onValidationComplete?: () => void
): Record<string, IValidationItem[]> => {
	const [requirementsList, setRequirementsList] = useState(
		buildInitialRequirementsState(finalRequirementsState)
	);
	const delays = useRef<AbortablePromise<unknown>[]>([]);
	const updateItemState = useCallback((step: string, validationItem: IValidationItem) => {
		setRequirementsList((prevRequirements) => ({
			...prevRequirements,
			[step]: prevRequirements[step].map((requirement) => {
				if (requirement.description === validationItem.description) {
					return {
						...requirement,
						hidden: false,
						state:
							requirement.state === EValidationStep.Pending
								? EValidationStep.Validating
								: (validationItem.state as EValidationStep)
					};
				}

				return requirement;
			})
		}));
	}, []);

	const startValidationProcess = useCallback(async () => {
		setRequirementsList(buildInitialRequirementsState(finalRequirementsState));
		for (const step of Object.keys(finalRequirementsState)) {
			const stepItems = finalRequirementsState[step];

			for (const item of stepItems) {
				updateItemState(step, item);
				const delay = cancelableDelayMilliseconds(getRandomNumberInRange(0.5, 1) * 1000);
				delays.current.push(delay);

				await delay;
				updateItemState(step, item);

				if (item.isValidationBlocker && item.state === EValidationStep.Error) {
					onValidationComplete?.();
					return;
				}
			}
		}

		onValidationComplete?.();
	}, [onValidationComplete, finalRequirementsState, updateItemState]);

	useEffect(() => {
		if (canStartValidation) {
			delays.current.forEach((delay) => delay.abort());
			startValidationProcess();
		}
	}, [canStartValidation, finalRequirementsState, startValidationProcess]);

	useEffect(() => {
		const currentDelays = delays.current;
		return () => currentDelays.forEach((delay) => delay.abort());
	}, []);

	// Used when the status of list is updated from outside (not mocked animation)
	useEffect(() => {
		if (!canStartValidation) {
			setRequirementsList(finalRequirementsState);
		}
	}, [canStartValidation, finalRequirementsState]);

	return requirementsList;
};
