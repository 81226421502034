import { openModal } from '@kelvininc/shared-ui';

import { ComponentProps } from 'react';

import { AssetScheduledParametersModal } from './AssetScheduledParametersModal';
import { ASSET_SCHEDULED_PARAMETERS_MODAL_TITLE } from './config';
import styles from './styles.module.scss';

export const openAssetScheduledParametersModal = (
	props: ComponentProps<typeof AssetScheduledParametersModal>
): void => {
	openModal({
		ContentComponent: <AssetScheduledParametersModal {...props} />,
		config: {
			title: ASSET_SCHEDULED_PARAMETERS_MODAL_TITLE,
			customClass: styles.AssetScheduledParametersModalContainer
		}
	});
};
