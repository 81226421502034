import { useEffect, useRef, useState } from 'react';

import {
	NAVIGATION_COLLAPSED_WIDTH,
	NAVIGATION_EXPANDED_WIDTH,
	NAVIGATION_RESIZE_ANIMATION_TIME_IN_MILLIS
} from '../config';

export const useNavigationSideBarMinWidth = (fixed: boolean) => {
	const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

	const [minWidth, setMinWidth] = useState(
		fixed ? NAVIGATION_EXPANDED_WIDTH : NAVIGATION_COLLAPSED_WIDTH
	);

	useEffect(() => {
		clearTimeout(timeoutRef.current);

		if (fixed) {
			timeoutRef.current = setTimeout(() => {
				setMinWidth(NAVIGATION_EXPANDED_WIDTH);
			}, NAVIGATION_RESIZE_ANIMATION_TIME_IN_MILLIS);
		} else {
			setMinWidth(NAVIGATION_COLLAPSED_WIDTH);
		}
	}, [fixed]);

	return [minWidth];
};
