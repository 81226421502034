import { DataStream } from '@kelvininc/node-client-sdk';
import { IToggleButton } from '@kelvininc/react-ui-components';
import { UseDropdownOptions } from '@kelvininc/types';

export const LAST_CONTROL_CHANGE_COLUMN_TOGGLE_BUTTONS_DESCRIPTION =
	'Choose one of the following options:';
export const LAST_CONTROL_CHANGE_COLUMN_DATASTREAM_LABEL = 'Setpoint';
export const LAST_CONTROL_CHANGE_COLUMN_DATASTREAM_PLACEHOLDER = 'Select an option';
export const LAST_CONTROL_CHANGE_COLUMN_STATUSES_LABEL = 'Status';
export const LAST_CONTROL_CHANGE_COLUMN_STATUSES_PLACEHOLDER = 'Select an option';

export const ALL_SETPOINTS_KEY = 'all-setpoints';
export const SINGLE_SETPOINT_KEY = 'single-setpoint';
export const SETPOINTS_TOGGLE_BUTTONS: IToggleButton[] = [
	{
		value: ALL_SETPOINTS_KEY,
		label: 'All Setpoints'
	},
	{
		value: SINGLE_SETPOINT_KEY,
		label: 'Single Setpoint'
	}
];

export const USE_DROPDOWN_OPTIONS: UseDropdownOptions<DataStream> = {
	key: 'name',
	label: 'title',
	value: 'name'
};
