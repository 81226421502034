import { IBaseTData, ITableSettings } from '@kelvininc/table';
import { identity, mapValues } from 'lodash-es';

export const getSettingsKey = (key: string) => `${key}.tables.settings`;

export const serializeTablesSettings = <
	TData extends IBaseTData = IBaseTData,
	TTableSerialized = ITableSettings<TData>
>(
	settings: Record<string, ITableSettings<TData>>,
	serializer: (settings: ITableSettings<TData>) => TTableSerialized = identity
): Record<string, TTableSerialized> => mapValues(settings, (setting) => serializer(setting));

export const deserializeTablesSettings = <
	TData extends IBaseTData = IBaseTData,
	TTableSerialized = ITableSettings<TData>
>(
	settings: Record<string, TTableSerialized>,
	deserializer: (settings: TTableSerialized) => ITableSettings<TData> = identity
): Record<string, ITableSettings<TData>> =>
	mapValues(settings, (serializedColumn) => deserializer(serializedColumn));
