import {
	AppItem,
	EParameterType,
	EPropertyType,
	ParameterDefinitionItem,
	Property
} from '@kelvininc/node-client-sdk';
import {
	ASSETS_STATUS_INLINE_FILTER_NAME,
	ASSET_STATUSES_FILTER_OPTIONS,
	buildClosedLoopDefinition,
	getParameterTitle,
	isKelvinClosedLoopParameter
} from '@kelvininc/tsutils';
import { EInlineFilterType, IClosedLoopSettings, IInlineFilterConfig } from '@kelvininc/types';
import { RJSFSchema } from '@rjsf/utils';

import { ITableFiltersSchema } from '../../core';

import { BOOLEAN_FILTER_OPTIONS, DEFAULT_INLINE_FILTERS_UI_SCHEMA_CONFIG } from './config';

import { buildInlineFilterKey } from './filters';

const buildOptionsDefinition = (
	config: IInlineFilterConfig,
	primitiveType: EPropertyType | EParameterType,
	values: string[] | boolean[]
): RJSFSchema => {
	if (primitiveType === EPropertyType.Boolean) {
		return {
			type: 'string',
			oneOf: BOOLEAN_FILTER_OPTIONS
		};
	} else {
		return config.multiple
			? {
					type: 'array',
					items: {
						type: 'string',
						enum: values
					}
				}
			: {
					type: 'string',
					enum: values
				};
	}
};

export const buildEntityFilter = (config: IInlineFilterConfig): ITableFiltersSchema => {
	if (config.name === ASSETS_STATUS_INLINE_FILTER_NAME) {
		return {
			schema: {
				definitions: {
					statusOptions: config.multiple
						? {
								type: 'array',
								items: {
									type: 'string',
									oneOf: ASSET_STATUSES_FILTER_OPTIONS
								}
							}
						: {
								type: 'string',
								oneOf: ASSET_STATUSES_FILTER_OPTIONS
							}
				},
				type: 'object',
				properties: {
					[config.name]: {
						title: '',
						uniqueItems: true,
						$ref: '#/definitions/statusOptions'
					}
				}
			},
			uiSchema: {
				...DEFAULT_INLINE_FILTERS_UI_SCHEMA_CONFIG,
				[config.name]: {
					'ui:placeholder': 'Status',
					valuePrefix: 'Status:',
					searchable: true,
					selectionClearable: true
				}
			}
		};
	}
	return {
		schema: {},
		uiSchema: {}
	};
};

export const buildPropertyFilter = (
	config: IInlineFilterConfig,
	definition: Property,
	values: string[] | boolean[]
): ITableFiltersSchema => {
	const filterKey = buildInlineFilterKey(EInlineFilterType.Property, definition.name);
	return {
		schema: {
			definitions: {
				[`${filterKey}Options`]: buildOptionsDefinition(
					config,
					definition.primitiveType,
					values
				)
			},
			type: 'object',
			properties: {
				[filterKey]: {
					title: '',
					uniqueItems: true,
					$ref: `#/definitions/${filterKey}Options`
				}
			}
		},
		uiSchema: {
			...DEFAULT_INLINE_FILTERS_UI_SCHEMA_CONFIG,
			[filterKey]: {
				'ui:placeholder': config.title ?? definition.title,
				valuePrefix: `${config.title ?? definition.title}:`,
				searchable: true,
				selectionClearable: true
			}
		}
	};
};

export const buildParameterFilter = (
	config: IInlineFilterConfig,
	definition: ParameterDefinitionItem,
	values: string[] | boolean[],
	closedLoopSettings: IClosedLoopSettings,
	applications: Record<string, AppItem>
): ITableFiltersSchema => {
	const filterKey = buildInlineFilterKey(
		EInlineFilterType.Parameter,
		definition.name,
		definition.appName
	);

	const applicationTitle = applications[definition.appName].title;
	const parameterTitle = getParameterTitle(definition, closedLoopSettings);

	const fallbackTitle = `${applicationTitle} ${parameterTitle}`;
	const filterTitle = config.title ?? fallbackTitle;

	return {
		schema: {
			definitions: {
				[`${filterKey}Options`]: isKelvinClosedLoopParameter(definition.name)
					? buildClosedLoopDefinition(closedLoopSettings)
					: buildOptionsDefinition(config, definition.primitiveType, values)
			},
			type: 'object',
			properties: {
				[filterKey]: {
					title: '',
					uniqueItems: true,
					$ref: `#/definitions/${filterKey}Options`
				}
			}
		},
		uiSchema: {
			...DEFAULT_INLINE_FILTERS_UI_SCHEMA_CONFIG,
			[filterKey]: {
				'ui:placeholder': filterTitle,
				valuePrefix: `${filterTitle}:`,
				searchable: true,
				selectionClearable: true
			}
		}
	};
};
