'use client';
import { AppItem, Asset, EKrnResource, KvKRNParser, UserItem } from '@kelvininc/node-client-sdk';

import { KvTooltip } from '@kelvininc/react-ui-components';
import { getUserDisplayName } from '@kelvininc/tsutils';
import { get } from 'lodash-es';

import { ReactElement } from 'react';

import { ScheduleDataTime } from './components/ScheduleDataTime';

import { HEADER_INFO_TITLES } from './config';
import { ParameterScheduleAttributes } from './types';

import { InlineList } from '@/src/components/client';

const getUserFromKRN = (userKRN: string): string => {
	try {
		const { resourceContent } = KvKRNParser.parseKRN<EKrnResource.User>(userKRN);
		return resourceContent.user;
	} catch (error) {
		return userKRN;
	}
};
export const getAssetResourceFromKRN = (resource: string): string => {
	try {
		const { resourceContent } = KvKRNParser.parseKRN<EKrnResource.Asset>(resource);
		return resourceContent.asset;
	} catch (error) {
		return resource;
	}
};

const getAssetResourcesFromKRN = (resources: string[]): string[] => {
	return resources.map((resource) => {
		try {
			return getAssetResourceFromKRN(resource);
		} catch (error) {
			return resource;
		}
	});
};

const getUserLabelFromKRN = (userKRN: string, users: Record<string, UserItem>): string => {
	const userFromKRN = getUserFromKRN(userKRN);
	const user = get(users, [userFromKRN]);

	return user ? getUserDisplayName(user) : userFromKRN;
};

export const buildScheduleHeaderInfoItems = ({
	schedule,
	users,
	assets,
	applications,
	showAllAssets = true,
	showApp = false
}: {
	schedule: ParameterScheduleAttributes;
	users: Record<string, UserItem>;
	assets: Record<string, Asset>;
	applications: Record<string, AppItem>;
	showAllAssets?: boolean;
	showApp?: boolean;
}): Record<string, string | ReactElement | undefined> => {
	const { createdBy, resources } = schedule;
	const changedByLabel = getUserLabelFromKRN(createdBy, users);
	const assetNames = getAssetResourcesFromKRN(resources);
	const assetLabels = assetNames.map(
		(assetName, index) =>
			`${index > 0 && index < assetNames.length ? ', ' : ''}${
				assets[assetName]?.title ?? assetName
			}`
	);
	const assetLabelsTooltipText = assetLabels.join('');
	const app = applications[schedule.appName];

	const result: Record<string, string | ReactElement | undefined> = {
		[HEADER_INFO_TITLES.DATE_TIME]: <ScheduleDataTime {...schedule} />
	};

	if (showAllAssets) {
		result[HEADER_INFO_TITLES.ASSETS] = (
			<KvTooltip text={assetLabelsTooltipText}>
				<InlineList>
					{assetLabels.map((asset, index) => (
						<span key={index}>{asset}</span>
					))}
				</InlineList>
			</KvTooltip>
		);
	}

	if (showApp) {
		result[HEADER_INFO_TITLES.APPLICATION] = (
			<KvTooltip text={app.title} truncate>
				{app.title}
			</KvTooltip>
		);
	}

	return { ...result, [HEADER_INFO_TITLES.SCHEDULE_BY]: changedByLabel };
};

export const isSingleAssetSchedule = (schedule: ParameterScheduleAttributes): boolean => {
	return schedule.resources.length === 1;
};
