import {
	EActionButtonType,
	EComponentSize,
	KvActionButtonText,
	KvSchemaForm
} from '@kelvininc/react-ui-components';
import { areObjectPropertiesEmpty } from '@kelvininc/tsutils';
import { IChangeEvent } from '@rjsf/core';
import { isEmpty, merge } from 'lodash-es';

import { useCallback, useMemo, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import {
	IBaseTData,
	ITableAdvancedFilters,
	ITableFilters
} from '../../../../../../../core/components/AgGridTable/types';
import {
	useAdvancedFilters,
	useFilters
} from '../../../../../../../core/components/Table/contexts';
import { TableProps } from '../../../../types';

import { AdvancedFilterDropdown } from '../AdvancedFilterDropdown';

import { IAdvancedFilterDropdownApi } from '../AdvancedFilterDropdown/types';

import { FILTERS_UI_SCHEMA } from './config';
import styles from './styles.module.scss';

export type TableFiltersProps<TData extends IBaseTData> = Pick<
	TableProps<TData>,
	| 'filtersDisabled'
	| 'filtersSchema'
	| 'advancedFiltersDisabled'
	| 'advancedFiltersConfig'
	| 'unclearableFilters'
>;

export const TableFilters = <TData extends IBaseTData>({
	filtersSchema,
	advancedFiltersConfig,
	unclearableFilters,
	filtersDisabled = false,
	advancedFiltersDisabled = false
}: TableFiltersProps<TData>) => {
	const [filters, setFilters] = useFilters();
	const [advancedFilters, setAdvancedFilters] = useAdvancedFilters();
	const advancedFiltersRef = useRef<IAdvancedFilterDropdownApi>(null);

	const clearableFilters = useMemo(() => {
		return Object.entries(filters ?? {}).reduce<ITableFilters>((acc, [key, value]) => {
			if (isEmpty(unclearableFilters?.[key])) {
				acc[key] = value;
			}

			return acc;
		}, {});
	}, [filters, unclearableFilters]);

	const displayClearAllButton = useMemo(() => {
		if (isEmpty(unclearableFilters)) {
			return !areObjectPropertiesEmpty({ ...filters, ...advancedFilters });
		}

		return !areObjectPropertiesEmpty({ ...clearableFilters, ...advancedFilters });
	}, [advancedFilters, clearableFilters, filters, unclearableFilters]);

	const filtersUiSchema = useMemo(
		() => merge({}, FILTERS_UI_SCHEMA, filtersSchema?.uiSchema),
		[filtersSchema?.uiSchema]
	);

	const onChangeFilters = useCallback(
		({ formData }: IChangeEvent<ITableFilters>) => setFilters({ ...formData }),
		[setFilters]
	);
	const onChangeAdvancedFilters = useCallback(
		(newFilters?: ITableAdvancedFilters) => setAdvancedFilters({ ...newFilters }),
		[setAdvancedFilters]
	);
	const onClearFilters = () =>
		unstable_batchedUpdates(() => {
			setFilters({ ...unclearableFilters });
			advancedFiltersRef.current?.clearFilters();
		});

	return (
		<div className={styles.TableFilters}>
			{filtersSchema && (
				<div className={styles.Filters}>
					<KvSchemaForm
						disabled={filtersDisabled}
						customClass={styles.SchemaForm}
						formData={filters}
						submittedData={filters}
						onChange={onChangeFilters}
						schema={filtersSchema.schema}
						uiSchema={filtersUiSchema}
					/>
				</div>
			)}
			{advancedFiltersConfig && (
				<div className={styles.AdvancedFilter}>
					<AdvancedFilterDropdown
						apiRef={advancedFiltersRef}
						filters={advancedFilters}
						config={advancedFiltersConfig}
						disabled={advancedFiltersDisabled}
						onChange={onChangeAdvancedFilters}
					/>
				</div>
			)}
			{displayClearAllButton && (
				<div className={styles.Test}>
					<KvActionButtonText
						text="Clear All"
						type={EActionButtonType.Ghost}
						size={EComponentSize.Small}
						onClickButton={onClearFilters}
					/>
				</div>
			)}
		</div>
	);
};
