import { IWizardStepConfig, openModal } from '@kelvininc/shared-ui';
import { isEmpty, isNil } from 'lodash-es';

import { ComponentProps } from 'react';

import { IMPORT_WIZARD_TITLE } from './config';
import { ImportGuardrailsWizard } from './ImportGuardrailsWizard';
import styles from './styles.module.scss';
import { EImportGuardrailsWizardStep, ImportGuardrailsWizardFormValues } from './types';

export const openImportGuardrailsWizardModal = (
	props: ComponentProps<typeof ImportGuardrailsWizard>
): void => {
	openModal({
		ContentComponent: <ImportGuardrailsWizard {...props} />,
		config: {
			title: IMPORT_WIZARD_TITLE,
			customClass: styles.ImportGuardrailsWizardStep
		}
	});
};

const isUploadStepValid = (values: Partial<ImportGuardrailsWizardFormValues>): boolean => {
	return !isNil(values.file);
};

const isSummaryStepValid = (values: Partial<ImportGuardrailsWizardFormValues>): boolean => {
	return !isEmpty(values.guardrails);
};

export const isImportStepValid = (
	step: IWizardStepConfig,
	values: ImportGuardrailsWizardFormValues
): boolean => {
	const validator = {
		[EImportGuardrailsWizardStep.Upload]: isUploadStepValid,
		[EImportGuardrailsWizardStep.Summary]: isSummaryStepValid
	}[step.id];

	if (!validator) {
		return true;
	}

	return validator(values);
};
