import {
	EActionButtonType,
	EComponentSize,
	EIconName,
	ETooltipPosition,
	KvActionButtonText,
	KvIcon,
	KvSearch,
	KvSwitchButton,
	KvTooltip
} from '@kelvininc/react-ui-components';
import { openToaster, usePanelsManager, useScroll } from '@kelvininc/shared-ui';
import { updateArrayItem } from '@kelvininc/tsutils';
import classNames from 'classnames';

import { debounce, isEmpty } from 'lodash-es';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS } from '../../../../../../../config';
import { IBaseTData, IColumnDef } from '../../../../../../../core/components/AgGridTable/types';

import { useTableManagementEditColumnContext } from '../../../../../../../core/components/Table/contexts/TableManagementEditColumnContext';

import { useTableManagementStateContext } from '../../../../../../../core/components/Table/contexts/TableManagementStateContext';
import {
	EManagementPanel,
	ICustomColumnsConfig
} from '../../../../../../../core/components/Table/types';

import { DragDropColumns } from './components';
import {
	COLUMNS_TOOLTIP_TEXT,
	INVISIBLE_COLUMNS_HIDDED_TOASTER_CONFIG,
	MAXIMUM_TABLE_COLUMNS,
	MAXIMUM_TABLE_COLUMNS_REACHED_TOOLTIP,
	SCROLL_OFFSET,
	SEARCH_COLUMN_PLACEHOLDER
} from './config';
import { useFilteredColumnDefs } from './hooks';
import styles from './styles.module.scss';
import { IColumnsStatistic } from './types';
import { buildColumnStatistics, buildHideToasterHeader, getChangesFromColumns } from './utils';

type ManageColumnsProps<TData extends IBaseTData> = {
	columnDefs: IColumnDef<TData>[];
	onChange?: Dispatch<SetStateAction<IColumnDef<TData>[]>>;
	onReset?: () => void;
	config?: ICustomColumnsConfig<TData>;
};

export const ManageColumns = <TData extends IBaseTData>({
	columnDefs,
	onChange,
	onReset,
	config
}: ManageColumnsProps<TData>) => {
	const { push } = usePanelsManager();
	const [isHiding, setHiding] = useState(false);
	const [searchTerm, setSearchTerm] = useState<string>();
	const columnsPanelRef = useRef<HTMLDivElement>(null);
	const { scrollTop } = useScroll(columnsPanelRef);
	const {
		editColumn: [, setEditColumn]
	} = useTableManagementEditColumnContext<TData>();

	const { sidepanelKey, isOpen } = useTableManagementStateContext();

	const statistics = useMemo(() => buildColumnStatistics(columnDefs), [columnDefs]);
	const isScrolling = useMemo(() => scrollTop - SCROLL_OFFSET > 0, [scrollTop]);
	const hidenColumnsCount = useMemo(
		() => columnDefs.filter(({ hide }) => hide).length,
		[columnDefs]
	);
	const isSearchDisabled = useMemo(() => columnDefs.length === 0, [columnDefs]);
	const onChangeSearchTerm = useMemo(
		() =>
			debounce(({ detail: newSearchTerm }: CustomEvent<string>) => {
				setSearchTerm(newSearchTerm);
			}, DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS),
		[]
	);
	const isAddColumnDisabled = useMemo(
		() => columnDefs.length >= MAXIMUM_TABLE_COLUMNS,
		[columnDefs]
	);

	const onColumnsItemsChange = useCallback(
		(columns: IColumnDef<TData>[]) => {
			if (onChange) {
				const updatedColumns = getChangesFromColumns(columnDefs, columns);
				onChange(updatedColumns);
			}
		},
		[columnDefs, onChange]
	);

	const onColumnsVisibilityChange = useCallback(
		(columnItem: IColumnDef<TData>) => {
			if (onChange) {
				const columnIndex = columnDefs.findIndex(({ id }) => id === columnItem.id);
				if (columnIndex === -1) {
					return;
				}
				const column = columnDefs[columnIndex];

				const newItems = updateArrayItem(columnDefs, columnIndex, {
					...column,
					hide: !!!column.hide
				});
				onChange(newItems);
			}
		},
		[columnDefs, onChange]
	);

	const toggleHidingInvisible = useCallback(() => {
		if (!isHiding) {
			openToaster({
				...INVISIBLE_COLUMNS_HIDDED_TOASTER_CONFIG,
				header: buildHideToasterHeader(hidenColumnsCount)
			});
		}

		setHiding(!isHiding);
	}, [hidenColumnsCount, isHiding]);

	const filteredColumnDefs = useFilteredColumnDefs(columnDefs, searchTerm);

	const onClickEditColumn = useCallback(
		(column: IColumnDef<TData>) => {
			if (!config) {
				return;
			}

			setEditColumn(column);
			push(EManagementPanel.Edit);
		},
		[config, setEditColumn, push]
	);

	const onClickAddColumn = useCallback(() => {
		if (!config) {
			return;
		}

		push(EManagementPanel.Add);
	}, [config, push]);

	useEffect(() => {
		if (!isOpen(sidepanelKey) && !isEmpty(searchTerm)) {
			setSearchTerm('');
		}
	}, [isOpen, searchTerm, sidepanelKey]);

	return (
		<div className={styles.ManageColumns}>
			<div className={styles.Content}>
				<div className={styles.SearchInput}>
					<KvSearch
						value={searchTerm}
						placeholder={SEARCH_COLUMN_PLACEHOLDER}
						onTextChange={onChangeSearchTerm}
						inputDisabled={isSearchDisabled}
						size={EComponentSize.Large}
					/>
					<div className={styles.SearchToggle}>
						<KvSwitchButton
							checked={isHiding}
							onSwitchChange={toggleHidingInvisible}
							disabled={hidenColumnsCount === 0}>
							<div className={styles.SwitchLabel} slot="right-slot">
								Hide invisible columns
							</div>
						</KvSwitchButton>
					</div>
				</div>
				<div
					className={classNames(styles.ColumnsPanelScroll, {
						[styles.ColumnsPanelScrolling]: isScrolling
					})}
					ref={columnsPanelRef}>
					<div className={styles.ColumnsPanel}>
						<div className={styles.ColumnTitle}>
							Columns
							<div className={styles.TooltipIcon}>
								<KvTooltip
									text={COLUMNS_TOOLTIP_TEXT}
									position={ETooltipPosition.Right}>
									<KvIcon name={EIconName.Info} />
								</KvTooltip>
							</div>
						</div>
						<div className={styles.ColumnStatistics}>
							{statistics.map((statistic: IColumnsStatistic) => (
								<div key={statistic.id} className={styles.Statistic}>
									<KvIcon name={statistic.icon} />
									<div className={styles.Text}>{statistic.text}</div>
								</div>
							))}
						</div>
						<div className={styles.ColumnsEdit}>
							<DragDropColumns
								columnDefs={filteredColumnDefs}
								onChangeItems={onColumnsItemsChange}
								onVisibilityChange={onColumnsVisibilityChange}
								onEdit={onClickEditColumn}
								isSearching={!isEmpty(searchTerm)}
								isHiding={isHiding}
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				data-test-id="e2e-manage-table-columns-actions"
				className={classNames(styles.Actions, {
					[styles.ActionsScrolling]: isScrolling
				})}>
				<div className={styles.ActionsGroup}>
					<KvActionButtonText
						icon={EIconName.Reset}
						text="Reset"
						type={EActionButtonType.Tertiary}
						onClickButton={onReset}
					/>
				</div>
				<div className={styles.ActionsGroup}>
					{config && (
						<KvTooltip
							text={MAXIMUM_TABLE_COLUMNS_REACHED_TOOLTIP}
							position={ETooltipPosition.Left}
							disabled={!isAddColumnDisabled}>
							<KvActionButtonText
								icon={EIconName.Add}
								text="Add Column"
								type={EActionButtonType.Primary}
								onClickButton={onClickAddColumn}
								disabled={isAddColumnDisabled}
							/>
						</KvTooltip>
					)}
				</div>
			</div>
		</div>
	);
};
