import { isFunction, isString } from 'lodash-es';

import { IBaseTData } from '../../../core/components/AgGridTable/types';

export const getDescriptionValue = <TData extends IBaseTData>(
	description?: string | ((data: TData) => string),
	data?: TData
): string | undefined => {
	if (isString(description)) {
		return description;
	}

	if (isFunction(description) && data) {
		return description(data);
	}

	return undefined;
};
