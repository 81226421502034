import { getHumanReadableFormat } from '@kelvininc/tsutils';

import { ParameterScheduleAttributes } from '../../types';

export const getFormattedDates = (
	schedule: ParameterScheduleAttributes
): { scheduledFor: string; revertedAt?: string } => {
	const { scheduledFor, revert } = schedule;
	return {
		scheduledFor: getHumanReadableFormat(scheduledFor),
		revertedAt: revert?.scheduledFor ? getHumanReadableFormat(revert.scheduledFor) : undefined
	};
};
