import { EComponentSize } from '@kelvininc/react-ui-components';

import { ERelativeTimeRangeKey } from '@kelvininc/types';

import { DEFAULT_DROPDOWN_FILTER_MAX_HEIGHT } from '../../config';

export const DEFAULT_INLINE_FILTERS_UI_SCHEMA_CONFIG = {
	'ui:inline': true,
	'ui:componentSize': EComponentSize.Small,
	'ui:inputConfig': { width: 'fit-content', minWidth: '132px', maxWidth: '252px' },
	'ui:dropdownConfig': {
		zIndex: 10,
		maxHeight: DEFAULT_DROPDOWN_FILTER_MAX_HEIGHT
	}
};

export const BOOLEAN_FILTER_OPTIONS = [
	{
		title: 'True',
		const: true
	},
	{
		title: 'False',
		const: false
	}
];

export const DATASTREAM_TIME_RANGES_FILTER = [
	ERelativeTimeRangeKey.Last_7_D,
	ERelativeTimeRangeKey.Last_14_D,
	ERelativeTimeRangeKey.Last_1_M
];
