import { DataStream } from '@kelvininc/node-client-sdk';

import { EValidationState } from '@kelvininc/react-ui-components';
import { ComponentProps } from 'react';

import { DatastreamSelector } from '../../../DatastreamSelector';
import { GuardrailRadioValueField } from '../GuardrailRadioValueField';

import { buildHelpText } from './utils';

type GuardrailDatastreamValueFieldProps = {
	value: string | number | undefined;
	datastreams: DataStream[];
	inclusive?: boolean;
	error?: string;
	onChangeValue: (value: string) => void;
	onBlur: () => void;
} & Omit<ComponentProps<typeof GuardrailRadioValueField>, 'label' | 'inclusive'>;

export const GuardrailDatastreamValueField = ({
	inclusive = false,
	value,
	datastreams,
	checked,
	disabled,
	error,
	onChangeValue,
	onBlur,
	...otherProps
}: GuardrailDatastreamValueFieldProps) => {
	const showError = !!error && checked && value !== undefined;

	return (
		<GuardrailRadioValueField
			label="Defined by a Data Stream’s last value"
			inclusive={inclusive}
			checked={checked}
			disabled={disabled}
			{...otherProps}>
			<DatastreamSelector
				label=""
				placeholder="Please select a Data Stream"
				value={checked ? value?.toString() : undefined}
				datastreams={datastreams}
				onValueChange={(datastream: string) => {
					onChangeValue(datastream);
				}}
				onOpenStateChange={({ detail: isOpen }) => {
					if (!isOpen) {
						onBlur();
					}
				}}
				helpText={showError ? error : buildHelpText(inclusive)}
				errorState={showError ? EValidationState.Invalid : undefined}
				disabled={!checked || disabled}
			/>
		</GuardrailRadioValueField>
	);
};
