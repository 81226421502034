import { ClassNamesProp } from '@kelvininc/types';
import { merge } from 'lodash-es';
import { memo, useMemo } from 'react';

import { OverlayWrapper } from '../OverlayWrapper';

import { EDataState, OverlaysComponents, OverlaysConfig } from '../types';

import { DEFAULT_OVERLAYS_COMPONENTS } from './config';

type OverlaysContainerProps = {
	state: EDataState;
	config?: Partial<OverlaysConfig>;
	components?: Partial<OverlaysComponents>;
	customClasses?: ClassNamesProp;
};

const Component = ({ state, config = {}, components, customClasses }: OverlaysContainerProps) => {
	const overlayComponents = useMemo(
		() => merge({}, DEFAULT_OVERLAYS_COMPONENTS, components ?? {}),
		[components]
	);

	if (state === EDataState.HasResults || state === EDataState.OnInit) {
		return null;
	}

	const StateComponent = overlayComponents[state];

	return (
		<OverlayWrapper {...config[state]} customClasses={customClasses}>
			<StateComponent {...config[state]} />
		</OverlayWrapper>
	);
};

export const OverlaysContainer = memo(
	Component,
	(prevProps, nextProps) => prevProps.state === nextProps.state
);
