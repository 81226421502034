import { IWizardStepConfig } from '@kelvininc/shared-ui';

import { merge, pick } from 'lodash-es';
import { DefaultValues } from 'react-hook-form';

import { GuardrailInfoStep, GuardrailValuesStep } from './components';
import styles from './styles.module.scss';

import {
	EGuardrailCraftWizardStep,
	EGuardrailCraftWizardValueType,
	EGuardrailValueType,
	NonNumericGuardrailCraftWizardFormValues,
	NumericGuardrailCraftWizardFormValues
} from './types';

const WIZARD_STEPS_CONFIG = {
	[EGuardrailCraftWizardStep.Info]: {
		id: EGuardrailCraftWizardStep.Info,
		title: 'Guardrail info',
		modalClass: styles.GuardrailInfoStep,
		Component: GuardrailInfoStep,
		cancelable: true
	},
	[EGuardrailCraftWizardStep.Values]: {
		id: EGuardrailCraftWizardStep.Values,
		title: 'Guardrail values',
		modalClass: styles.GuardrailValuesStep,
		Component: GuardrailValuesStep,
		cancelable: true,
		allowGoBack: true
	}
} as const satisfies Record<EGuardrailCraftWizardStep, IWizardStepConfig>;

export const CREATE_GUARDRAIL_CRAFT_WIZARD_STEPS = WIZARD_STEPS_CONFIG;

export const EDIT_GUARDRAIL_CRAFT_WIZARD_STEPS = merge(
	{},
	pick(WIZARD_STEPS_CONFIG, [EGuardrailCraftWizardStep.Values]),
	{
		[EGuardrailCraftWizardStep.Values]: {
			allowGoBack: false
		}
	}
);

export const CREATE_WIZARD_TITLE = 'Create a guardrail';

export const EDIT_WIZARD_TITLE = 'Edit guardrail';

export const NUMERIC_GUARDRAIL_DEFAULT_VALUES = {
	control: true,
	type: EGuardrailCraftWizardValueType.Numeric,
	value: {
		min: {
			type: EGuardrailValueType.Number,
			number: {
				inclusive: true
			},
			datastream: {
				inclusive: true
			}
		},
		max: {
			type: EGuardrailValueType.Number,
			number: {
				inclusive: true
			},
			datastream: {
				inclusive: true
			}
		},
		decrease: {
			min: {
				type: EGuardrailValueType.Number,
				number: {
					inclusive: true
				},
				percentage: {
					inclusive: true
				}
			},
			max: {
				type: EGuardrailValueType.Number,
				number: {
					inclusive: true
				},
				percentage: {
					inclusive: true
				}
			}
		},
		increase: {
			min: {
				type: EGuardrailValueType.Number,
				number: {
					inclusive: true
				},
				percentage: {
					inclusive: true
				}
			},
			max: {
				type: EGuardrailValueType.Number,
				number: {
					inclusive: true
				},
				percentage: {
					inclusive: true
				}
			}
		}
	}
} as const satisfies DefaultValues<NumericGuardrailCraftWizardFormValues>;

export const NON_NUMERIC_GUARDRAIL_DEFAULT_VALUES = {
	control: true,
	type: EGuardrailCraftWizardValueType.NonNumeric
} as const satisfies DefaultValues<NonNumericGuardrailCraftWizardFormValues>;
