import { getTimeFromNowFormat } from '@kelvininc/tsutils';
import { DateInput } from '@kelvininc/types';

import { useTimeFromNow } from '../../hooks';

type TimeFromNowProps = {
	date: DateInput;
	formatter?: (date: DateInput) => string;
};

export const TimeFromNow = ({ date, formatter = getTimeFromNowFormat }: TimeFromNowProps) => {
	const time = useTimeFromNow(date, formatter);

	return <>{time}</>;
};
