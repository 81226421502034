import { NavigationItem, useNavigationSideBar } from '@kelvininc/shared-ui';

import styles from './styles.module.scss';

import { useCatalogItem } from './utils';

export const CatalogItem = () => {
	const { isCollapsed } = useNavigationSideBar();
	const item = useCatalogItem();

	return (
		<div className={styles.ItemWrapper}>
			<NavigationItem item={item} isCollapsed={isCollapsed} />
		</div>
	);
};
