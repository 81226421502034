import {
	AppManagerApp,
	AppManagerResourceContext,
	ParameterSchedule
} from '@kelvininc/node-client-sdk';
import { atom, atomFamily, selector } from 'recoil';

import { EApplicationFilter } from '../../page-components/ApplicationsPage/ApplicationsList/types';

import { IAssetParameterDetails } from '../../page-components/ApplicationsPage/types';

import { selectedAppSelector } from './selectors';

export const appSearchAtom = atom<string | undefined>({
	key: 'appSearchAtom',
	default: undefined
});

export const appDropdownFilterAtom = atom<EApplicationFilter>({
	key: 'appDropdownFilterAtom',
	default: EApplicationFilter.status
});

export const applicationListAtom = atom<AppManagerApp[]>({
	key: 'applicationListAtom'
});

export const appLastPollingAtAtom = atom<Date>({
	key: 'appLastPollingAtAtom',
	default: undefined
});

export const selectedAppNameAtom = atom<string>({
	key: 'selectedAppNameAtom'
});

export const appsAtomFamily = atomFamily<AppManagerApp, string>({
	key: 'appsAtomFamily'
});

export const appResourceAppProjectionsAtomFamily = atomFamily<AppManagerResourceContext[], string>({
	key: 'appResourceAppProjectionsAtomFamily'
});

export const appParameterSchedulesAtomFamily = atomFamily<ParameterSchedule[], string>({
	key: 'appParameterSchedulesFamily'
});

export const selectedAppVersionAtom = atom<string>({
	key: 'selectedAppVersionAtom',
	default: selector<string>({
		key: 'selectedAppLastestVersionSelector',
		get: ({ get }) => {
			const {
				app: { latestVersion }
			} = get(selectedAppSelector);

			return latestVersion;
		}
	})
});

export const selectedAppSelectedAssetsAtom = atom<IAssetParameterDetails[]>({
	key: 'selectedAppSelectedAssetsAtom',
	default: []
});
