import { KvCheckbox } from '@kelvininc/react-ui-components';

import classNames from 'classnames';

import styles from './styles.module.scss';

type InclusiveCheckboxProps = {
	value: boolean;
	onChange: (value: boolean) => void;
	disabled: boolean;
};

export const InclusiveCheckbox = ({
	value,
	onChange,
	disabled = false
}: InclusiveCheckboxProps) => {
	return (
		<div
			className={classNames(styles.InclusiveCheckbox, {
				[styles.InclusiveCheckboxDisabled]: disabled
			})}
			onClick={() => onChange(!value)}>
			<div className={styles.Checkbox}>
				<KvCheckbox checked={value} disabled={disabled} />
			</div>
			<div className={styles.Label}>Inclusive</div>
		</div>
	);
};
