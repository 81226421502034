import { IPanelConfig, Panel, TabPanel, Tabs } from '@kelvininc/shared-ui';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { IBaseTData, IColumnDef } from '../../../../../AgGridTable';
import { useTableManagementStateContext } from '../../../../contexts';
import { ICustomColumnsConfig, IRowsSettingsConfig } from '../../../../types';
import { ManageColumns } from '../ManageColumns';
import { ManageRows } from '../ManageRows';

import { ETableManagementTab } from './types';

import { getTableManagementTabs } from './utils';

type ManagePanelProps<TData extends IBaseTData> = {
	columnDefs: IColumnDef<TData>[];
	onChange?: Dispatch<SetStateAction<IColumnDef<TData>[]>>;
	columnsConfig?: ICustomColumnsConfig<TData>;
	rowsSettings?: IRowsSettingsConfig;
	panelConfig: IPanelConfig;
	onRowsSettingsChange?: (newSettings: IRowsSettingsConfig) => void;
	onResetSettings?: () => void;
};

export const ManagePanel = <TData extends IBaseTData>({
	columnDefs,
	onChange,
	columnsConfig,
	panelConfig,
	rowsSettings,
	onRowsSettingsChange,
	onResetSettings
}: ManagePanelProps<TData>) => {
	const [activeTab, setActiveTab] = useState(ETableManagementTab.Columns);
	const onClickTab = useCallback((key: ETableManagementTab) => setActiveTab(key), []);
	const { closeSidePanel, alert } = useTableManagementStateContext();

	return (
		<Panel {...panelConfig} onRequestClose={closeSidePanel} alert={alert}>
			<Tabs
				tabs={getTableManagementTabs(rowsSettings)}
				activeTab={activeTab}
				onClickTab={onClickTab}>
				<TabPanel tab={ETableManagementTab.Columns} activeTab={activeTab}>
					<ManageColumns
						columnDefs={columnDefs}
						onChange={onChange}
						onReset={onResetSettings}
						config={columnsConfig}
					/>
				</TabPanel>
				{rowsSettings && (
					<TabPanel tab={ETableManagementTab.Rows} activeTab={activeTab}>
						<ManageRows settings={rowsSettings} onChange={onRowsSettingsChange} />
					</TabPanel>
				)}
			</Tabs>
		</Panel>
	);
};
