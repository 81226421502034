import { Column } from '@ag-grid-community/core';
import { ESortDirection } from '@kelvininc/types';
import { useMemo } from 'react';

import { IBaseTData, ITableContext } from '../../../../../core/components/AgGridTable';

export const useSortStatus = <TData extends IBaseTData>(
	column?: Column,
	context?: ITableContext<TData>
) => {
	const isSorting = useMemo(() => {
		if (!column || !context?.sortBy) {
			return false;
		}

		return context.sortBy.includes(column.getColId());
	}, [column, context?.sortBy]);
	const isSortAscending = useMemo(
		() => context?.sortDirection === ESortDirection.Asc,
		[context?.sortDirection]
	);

	return { isSorting, isSortAscending };
};
