import { EClosedLoopMode, IClosedLoopSettings } from '@kelvininc/types';

export const DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS = 500;

export const DEFAULT_TABLE_POLLING_INTERVAL = 30_000;

export const DEFAULT_DROPDOWN_FILTER_MAX_HEIGHT = '280px';

export const DEFAULT_DROPDOWN_FILTER_MAX_WIDTH = '280px';

export const DEFAULT_DROPDOWN_FILTER_MIN_WIDTH = '240px';

export const DEFAULT_CLOSED_LOOP_SETTINGS: IClosedLoopSettings = {
	displayLabel: 'Mode',
	mapping: {
		[EClosedLoopMode.True]: 'Closed Loop',
		[EClosedLoopMode.False]: 'Open Loop'
	}
};

export const LICENSE_KEY =
	'CompanyName=Kelvin, Inc.,LicensedApplication=Kelvin,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-038861,SupportServicesEnd=29_February_2024_[v2]_MTcwOTE2NDgwMDAwMA==d2d89f67ab925e298f8aa7625edceb17';
