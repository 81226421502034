'use client';
import { EActionButtonType, KvActionButtonText } from '@kelvininc/react-ui-components';

type SubmitErrorActionsProps = {
	onTryAgain: () => void;
	onCancel: () => void;
};

export const SubmitErrorActions = ({ onTryAgain, onCancel }: SubmitErrorActionsProps) => {
	return (
		<>
			<KvActionButtonText
				text="Cancel"
				type={EActionButtonType.Secondary}
				onClickButton={onCancel}
			/>
			<KvActionButtonText
				text="Try Again"
				type={EActionButtonType.Danger}
				onClickButton={onTryAgain}
			/>
		</>
	);
};
