import { EIconName, KvIcon } from '@kelvininc/react-ui-components';
import classNames from 'classnames';

import styles from './styles.module.scss';

type FixedButtonProps = {
	onClick: () => void;
	collapsed: boolean;
	fixed: boolean;
};

const FixedButton = ({ onClick, fixed, collapsed }: FixedButtonProps) => {
	return (
		<div
			className={classNames(styles.FixedButton, {
				[styles.FixedButtonShow]: !fixed || !collapsed
			})}>
			<div className={styles.ButtonContainer}>
				<div className={styles.Button} onClick={onClick}>
					<KvIcon
						name={EIconName.NavClose}
						customClass={classNames({
							['rotate-180']: !fixed
						})}
					/>
				</div>
			</div>
		</div>
	);
};

export default FixedButton;
