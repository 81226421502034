import { EIconName } from '@kelvininc/react-ui-components';
import { IExpandableMenuItem } from '@kelvininc/shared-ui';
import { useAtomValue } from 'jotai';

import { useRecoilValue } from 'recoil';

import { supportItemsInstanceSettingSelector } from '@/src/recoil/instance-settings/selectors';
import { appSettingsAtom } from '@/src/state';

export const useSupportItems = (): IExpandableMenuItem[] => {
	const customMenuItems = useRecoilValue(supportItemsInstanceSettingSelector);
	const appSettings = useAtomValue(appSettingsAtom);

	const supportItems = [];

	customMenuItems.items.forEach((item) => {
		const { url, id, text, leftIcon } = item;
		supportItems.push({
			url: url,
			id: id,
			text: text,
			leftIcon: leftIcon,
			rightIcon: EIconName.ExternalLink,
			target: '_blank'
		});
	});

	supportItems.push(
		{
			url: appSettings.documentationLink,
			id: appSettings.documentationLink,
			text: 'Documentation',
			leftIcon: EIconName.Documentation,
			rightIcon: EIconName.ExternalLink,
			target: '_blank'
		},
		{
			url: appSettings.supportLink,
			id: appSettings.supportLink,
			text: 'Support',
			leftIcon: EIconName.Support,
			rightIcon: EIconName.ExternalLink,
			target: '_blank'
		},
		{
			url: appSettings.api.swaggerUrl,
			id: appSettings.api.swaggerUrl,
			text: 'API Specification',
			leftIcon: EIconName.Swagger,
			rightIcon: EIconName.ExternalLink,
			target: '_blank'
		}
	);

	return supportItems;
};
