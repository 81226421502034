import { EActionButtonType, EIconName, KvActionButtonIcon } from '@kelvininc/react-ui-components';
import classNames from 'classnames';

import { flushSync } from 'react-dom';

import {
	IBaseTData,
	ICellRendererParams,
	ITableContext
} from '../../../core/components/AgGridTable/types';
import { refreshDatasource } from '../../../core/components/AgGridTable/utils';
import { withBaseCellRenderer } from '../BaseCellRenderer';

import styles from './styles.module.scss';

import { isRowPinned } from './utils';

const Component = <TData extends IBaseTData>({
	node,
	api,
	context
}: ICellRendererParams<TData>) => {
	const { rowsPinned, onRowsPinnedChange, getRowId, maxRowsPinned } =
		context as ITableContext<TData>;

	const isPinned = isRowPinned(rowsPinned, getRowId, node);
	const isActionDisabled = !isPinned && rowsPinned.length >= maxRowsPinned;
	const onClick = () => {
		if (api && node?.data) {
			if (isPinned) {
				// Detach this node data from the pinned rows
				const newPinned = rowsPinned.filter(
					(item) => getRowId(item) !== getRowId(node.data as TData)
				);
				flushSync(() => {
					onRowsPinnedChange(newPinned);
				});

				// Add row to table's unpinned rows section
				api.updateGridOptions({ pinnedTopRowData: newPinned });
				api.applyServerSideTransaction({
					addIndex: 0,
					add: [node.data]
				});
			} else {
				const newPinned = [...rowsPinned, node.data as TData];

				// Attach this node data to the pinned rows
				flushSync(() => {
					onRowsPinnedChange(newPinned);
				});

				// Remove row from table's unpinned rows section
				api.updateGridOptions({ pinnedTopRowData: newPinned });
				api.applyServerSideTransaction({
					remove: [node.data]
				});
			}

			// Refresh data
			refreshDatasource(api);
		}
	};

	return (
		<KvActionButtonIcon
			className={classNames(styles.FavouriteAction, {
				[styles.FavouriteActionActive]: isPinned
			})}
			type={EActionButtonType.Ghost}
			onClickButton={onClick}
			disabled={isActionDisabled}
			icon={EIconName.PushPin}
		/>
	);
};

export const FavouriteCellRenderer = withBaseCellRenderer(Component);
