import { AppItem, RecommendationType } from '@kelvininc/node-client-sdk';

import { KvMultiSelectDropdown, KvSingleSelectDropdown } from '@kelvininc/react-ui-components';
import {
	RECOMMENDATIONS_STATUSES_DROPDOWN_OPTIONS,
	buildDropdownOptionsFromArray,
	buildRelativeTimeRange,
	searchDropdownOptions
} from '@kelvininc/tsutils';
import { ReactState } from '@kelvininc/types';
import { isEmpty } from 'lodash-es';

import { useCallback, useMemo, useState } from 'react';

import { IRecommendationColumnData } from '../../../../../../../AgGridTable';

import {
	RECOMMENDATION_COLUMN_APPLICATION_LABEL,
	RECOMMENDATION_COLUMN_APPLICATION_PLACEHOLDER,
	RECOMMENDATION_COLUMN_RANGE_COLUMN_LABEL,
	RECOMMENDATION_COLUMN_RANGE_COLUMN_PLACEHOLDER,
	RECOMMENDATION_COLUMN_RECOMMENDATION_TYPE_LABEL,
	RECOMMENDATION_COLUMN_RECOMMENDATION_TYPE_PLACEHOLDER,
	RECOMMENDATION_COLUMN_STATUSES_LABEL,
	RECOMMENDATION_COLUMN_STATUSES_PLACEHOLDER,
	RECOMMENDATION_COLUMN_TIME_RANGE_DROPDOWN_OPTIONS
} from './config';
import styles from './styles.module.scss';

import {
	buildRecommendationTypesList,
	buildRecommendationTypesOptions,
	buildStatusesList,
	buildStatusesOptions
} from './utils';

type RecommendationSettings = {
	state: ReactState<IRecommendationColumnData>;
	recommendationTypes?: Record<string, RecommendationType>;
	applications?: Record<string, AppItem>;
};

export const RecommendationSettings = ({
	state,
	recommendationTypes = {},
	applications = {}
}: RecommendationSettings) => {
	const [settings, setState] = state;
	const [searchTerm, setSearchTerm] = useState<string | null>(null);
	const recommendationTypesList = Object.values(recommendationTypes);
	const applicationsList = Object.values(applications);

	const onSelectApplication = useCallback(
		({ detail: newApplication }: CustomEvent<string>) =>
			setState((previousState) => ({
				...previousState,
				application: newApplication
			})),
		[setState]
	);
	const onSelectRecommendationType = useCallback(
		({ detail: newTypes }: CustomEvent<Record<string, boolean>>) => {
			setState((previousState) => ({
				...previousState,
				recommendationTypes: buildRecommendationTypesList(newTypes)
			}));
		},
		[setState]
	);
	const onSelectStatus = useCallback(
		({ detail: newStatuses }: CustomEvent<Record<string, boolean>>) => {
			setState((previousState) => ({
				...previousState,
				statuses: buildStatusesList(newStatuses)
			}));
		},
		[setState]
	);
	const onSelectRange = useCallback(
		({ detail: newRange }: CustomEvent<string>) =>
			setState((previousState) => ({
				...previousState,
				range: buildRelativeTimeRange(newRange)
			})),
		[setState]
	);
	const onSearchChange = useCallback(
		({ detail: searchedRecommendation }: CustomEvent<string>) =>
			setSearchTerm(searchedRecommendation),
		[]
	);

	const applicationsOptions = useMemo(
		() =>
			buildDropdownOptionsFromArray({
				data: applicationsList,
				key: 'name',
				label: 'title',
				value: 'name'
			}),
		[applicationsList]
	);
	const filteredApplicationsOptions = useMemo(() => {
		if (searchTerm !== null && searchTerm.length > 0) {
			return searchDropdownOptions(searchTerm, applicationsOptions);
		}

		return applicationsOptions;
	}, [applicationsOptions, searchTerm]);
	const typesOptions = useMemo(
		() =>
			buildDropdownOptionsFromArray({
				data: recommendationTypesList,
				key: 'name',
				label: 'title',
				value: 'name'
			}),
		[recommendationTypesList]
	);
	const filteredRecommendationTypesOptions = useMemo(() => {
		if (searchTerm !== null && searchTerm.length > 0) {
			return searchDropdownOptions(searchTerm, typesOptions);
		}

		return typesOptions;
	}, [typesOptions, searchTerm]);
	const recommendationTypesOptions = useMemo(
		() => buildRecommendationTypesOptions(settings.recommendationTypes),
		[settings.recommendationTypes]
	);

	const statusesOptions = useMemo(
		() => buildStatusesOptions(settings.statuses),
		[settings.statuses]
	);

	const selectedTime = useMemo(() => {
		return settings?.range?.time.payload?.key;
	}, [settings]);

	return (
		<div className={styles.RecommendationsSettings}>
			{!isEmpty(applications) && (
				<KvSingleSelectDropdown
					label={RECOMMENDATION_COLUMN_APPLICATION_LABEL}
					placeholder={RECOMMENDATION_COLUMN_APPLICATION_PLACEHOLDER}
					options={applicationsOptions}
					filteredOptions={filteredApplicationsOptions}
					selectedOption={settings?.application}
					onOptionSelected={onSelectApplication}
					onSearchChange={onSearchChange}
					selectionClearable
					searchable
				/>
			)}
			<KvMultiSelectDropdown
				placeholder={RECOMMENDATION_COLUMN_RECOMMENDATION_TYPE_PLACEHOLDER}
				label={RECOMMENDATION_COLUMN_RECOMMENDATION_TYPE_LABEL}
				options={typesOptions}
				filteredOptions={filteredRecommendationTypesOptions}
				selectedOptions={recommendationTypesOptions}
				onOptionsSelected={onSelectRecommendationType}
				onSearchChange={onSearchChange}
				searchable
			/>
			<KvMultiSelectDropdown
				placeholder={RECOMMENDATION_COLUMN_STATUSES_PLACEHOLDER}
				label={RECOMMENDATION_COLUMN_STATUSES_LABEL}
				options={RECOMMENDATIONS_STATUSES_DROPDOWN_OPTIONS}
				selectedOptions={statusesOptions}
				onOptionsSelected={onSelectStatus}
			/>
			<KvSingleSelectDropdown
				required
				placeholder={RECOMMENDATION_COLUMN_RANGE_COLUMN_PLACEHOLDER}
				label={RECOMMENDATION_COLUMN_RANGE_COLUMN_LABEL}
				options={RECOMMENDATION_COLUMN_TIME_RANGE_DROPDOWN_OPTIONS}
				selectedOption={selectedTime}
				onOptionSelected={onSelectRange}
				searchable={false}
			/>
		</div>
	);
};
