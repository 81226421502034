import { ExpandableMenu, useNavigationSideBar, withExpandableProvider } from '@kelvininc/shared-ui';

import React from 'react';

import { navigationSidebarExpandedContextualizationUserSettingAtom } from '../../atom';
import {
	NAVIGATION_SIDEBAR_EXPANDED_CONTEXTUALIZATION_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_CONTEXTUALIZATION_USER_SETTING_KEY
} from '../../config';

import { useContextualizationExpandableItems } from './utils';

const Component = () => {
	const { isCollapsed } = useNavigationSideBar();
	const items = useContextualizationExpandableItems();

	return <ExpandableMenu title="Assets and Data" items={items} collapsed={isCollapsed} />;
};

export const ContextualizationMenu = withExpandableProvider(
	Component,
	NAVIGATION_SIDEBAR_EXPANDED_CONTEXTUALIZATION_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_CONTEXTUALIZATION_USER_SETTING_KEY,
	navigationSidebarExpandedContextualizationUserSettingAtom
);
