'use client';
import { AppItem, EAppType } from '@kelvininc/node-client-sdk';
import { KvSchemaForm } from '@kelvininc/react-ui-components';
import { withSuspense } from '@kelvininc/shared-ui';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { DeployWorkloadData, EDeployWorkloadType } from '../../types';
import { workloadListNamesAtom } from '../recoil/atoms';
import { WorkloadFormInfo } from '../types';

import { FORM_ID_PREFIX, FORM_KEY_SEPARATOR } from './config';
import { useSchemaDataChange } from './hooks/useSchemaDataChange';
import styles from './styles.module.scss';

import {
	buildWorkloadFormData,
	getWorkloadConfigurationFormSchema,
	getWorkloadConfigurationFormUiSchema
} from './utils';

import { clustersListAtom } from '@/src/recoil/clusters/atoms';
import { useApplicationService } from '@/src/services/application-service';

export type WorkloadFormData = Pick<WorkloadFormInfo, 'workloadInfo' | 'workloadType'>;

export type WorkloadFormProps = Partial<DeployWorkloadData> & {
	onChange: (data: Partial<DeployWorkloadData>, valid: boolean) => void;
	flowType: EDeployWorkloadType;
	applicationsMap: Record<string, AppItem>;
};

const Component = ({ onChange, flowType, applicationsMap, ...initialData }: WorkloadFormProps) => {
	const { isProfessionalEdition } = useApplicationService();
	const clusters = useRecoilValue(clustersListAtom);
	const workloadNames = useRecoilValue(workloadListNamesAtom);
	const formData = useMemo<WorkloadFormData>(
		() => buildWorkloadFormData(initialData),
		[initialData]
	);
	const hideWorkloadType = useMemo(
		() =>
			(!!initialData.appName &&
				applicationsMap[initialData.appName]?.type === EAppType.Bridge) ||
			isProfessionalEdition,
		[applicationsMap, initialData.appName, isProfessionalEdition]
	);

	const formProps = useMemo(
		() => ({
			schema: getWorkloadConfigurationFormSchema(clusters, flowType, hideWorkloadType),
			uiSchema: getWorkloadConfigurationFormUiSchema(flowType, hideWorkloadType)
		}),
		[hideWorkloadType, clusters, flowType]
	);

	const { extraErrors, onSchemaDataChange } = useSchemaDataChange(
		formProps.schema,
		formData,
		workloadNames,
		flowType,
		onChange
	);

	return (
		<div className={styles.WorkloadFormContainer}>
			<KvSchemaForm
				customClass={styles.WorkloadSchemaForm}
				{...formProps}
				showErrorList={false}
				extraErrors={extraErrors}
				formData={formData}
				disabled={isEmpty(clusters)}
				idPrefix={FORM_ID_PREFIX}
				idSeparator={FORM_KEY_SEPARATOR}
				onChange={onSchemaDataChange}
			/>
		</div>
	);
};

export const WorkloadForm = withSuspense(Component);
