import { buildUrl } from '@kelvininc/tsutils';
import { URLSearchParamsInit } from '@kelvininc/types';

import { USER_MANAGEMENT_ROUTE_BASE_PATH } from './config';

const ROUTE_PATH = `${USER_MANAGEMENT_ROUTE_BASE_PATH}`;

export const buildUserManagementRoute = (query?: URLSearchParamsInit): string =>
	buildUrl(ROUTE_PATH, query);

export const buildUserManagementGroupsRoute = (query?: URLSearchParamsInit): string =>
	buildUrl([ROUTE_PATH, 'groups'], query);
