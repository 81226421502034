import { EIconName, KvIcon } from '@kelvininc/react-ui-components';
import classNames from 'classnames';

import { useEffect } from 'react';

import { useExpandable } from '../../../Expandable';

import styles from './styles.module.scss';

type MenuExpandableActionProps = {
	title: string;
	collapsed: boolean;
};

export const MenuExpandableAction = ({ title, collapsed }: MenuExpandableActionProps) => {
	const { isExpanded, toggle } = useExpandable();

	useEffect(() => {
		if (!isExpanded && collapsed) {
			toggle();
		}

		return;
	}, [collapsed, isExpanded, toggle]);

	return (
		<div
			className={classNames(styles.Label, {
				[styles.LabelExpanded]: isExpanded
			})}
			onClick={toggle}>
			{!collapsed && (
				<>
					<span>{title}</span>
					<KvIcon name={EIconName.Expand} />
				</>
			)}
		</div>
	);
};
