import { KvIcon, KvTooltip } from '@kelvininc/react-ui-components';
import { useInterval } from '@kelvininc/shared-ui';

import { getParameterScheduleCalendarIcon } from '@kelvininc/tsutils';
import { useState } from 'react';

import styles from './styles.module.scss';
import { getScheduleParametersFormattedDateTime } from './utils';

type ScheduleDateTimeProps = {
	scheduleDate: string;
	revertDate?: string;
};

export const ScheduleDateTime = ({ scheduleDate, revertDate }: ScheduleDateTimeProps) => {
	const [dateFormat, setDateFormat] = useState<string>(
		getScheduleParametersFormattedDateTime(scheduleDate, revertDate)
	);

	useInterval(() => {
		setDateFormat(getScheduleParametersFormattedDateTime(scheduleDate, revertDate));
	});

	return (
		<div className={styles.ScheduleDateTime}>
			<KvIcon name={getParameterScheduleCalendarIcon(revertDate)} />
			<KvTooltip text={dateFormat} truncate>
				<div className={styles.DateTime}>{dateFormat}</div>
			</KvTooltip>
		</div>
	);
};
