import { EIconName } from '@kelvininc/react-ui-components';
import { isSubString } from '@kelvininc/tsutils';
import pluralize from 'pluralize';

import { EColumnPinAlignment, IBaseTData, IColumnDef } from '../../../../../AgGridTable';

import { MAXIMUM_NON_FEATURE_COLUMNS_FROZEN } from './config';
import { EColumnsStatisticKey, IColumnsStatistic, IIndexedColumnDef } from './types';

const buildColumnStatisticsCount = <TData extends IBaseTData>(
	columnDefs: IColumnDef<TData>[]
): Record<EColumnsStatisticKey, number> => {
	return columnDefs.reduce<Record<EColumnsStatisticKey, number>>(
		(accumulator, columnDef) => {
			if (columnDef.feature) {
				return accumulator;
			}

			const {
				[EColumnsStatisticKey.Total]: total,
				[EColumnsStatisticKey.Frozen]: frozen,
				[EColumnsStatisticKey.Locked]: locked,
				[EColumnsStatisticKey.Hidden]: hidden
			} = accumulator;

			const newTotal = total + 1;
			let newFrozen = frozen;
			let newLocked = locked;
			let newHidden = hidden;

			if (columnDef.pinned) {
				newFrozen++;
			}

			if (columnDef.lockPinned) {
				newLocked++;
			}

			if (columnDef.hide) {
				newHidden++;
			}

			const newActive = newTotal - newHidden;

			return {
				[EColumnsStatisticKey.Total]: newTotal,
				[EColumnsStatisticKey.Frozen]: newFrozen,
				[EColumnsStatisticKey.Locked]: newLocked,
				[EColumnsStatisticKey.Active]: newActive,
				[EColumnsStatisticKey.Hidden]: newHidden
			};
		},
		{
			[EColumnsStatisticKey.Total]: 0,
			[EColumnsStatisticKey.Frozen]: 0,
			[EColumnsStatisticKey.Locked]: 0,
			[EColumnsStatisticKey.Active]: 0,
			[EColumnsStatisticKey.Hidden]: 0
		}
	);
};

export const getMaxFrozenColumns = <TData extends IBaseTData>(
	columnDefs: IColumnDef<TData>[]
): number =>
	columnDefs.filter(({ feature }) => feature).length + MAXIMUM_NON_FEATURE_COLUMNS_FROZEN;

export const buildColumnStatistics = <TData extends IBaseTData>(
	columnDefs: IColumnDef<TData>[]
): IColumnsStatistic[] => {
	const statistics = buildColumnStatisticsCount(columnDefs);

	return [
		{
			id: 'total',
			icon: EIconName.Column,
			text: `Total: ${statistics[EColumnsStatisticKey.Total]}`
		},
		{
			id: 'frozen',
			icon: EIconName.Fixed,
			text: `Frozen: ${statistics[EColumnsStatisticKey.Frozen]}/${getMaxFrozenColumns(
				columnDefs
			)}`
		},
		{
			id: 'locked',
			icon: EIconName.Lock,
			text: `Locked: ${statistics[EColumnsStatisticKey.Locked]}`
		},
		{
			id: 'active',
			icon: EIconName.Eye,
			text: `Active: ${statistics[EColumnsStatisticKey.Active]}`
		},
		{
			id: 'hidden',
			icon: EIconName.EyeClosed,
			text: `Hidden: ${statistics[EColumnsStatisticKey.Hidden]}`
		}
	];
};

export const splitColumnDefsByPinAlignment = <TData extends IBaseTData>(
	columnDefs: IColumnDef<TData>[]
): [IIndexedColumnDef<TData>[], IIndexedColumnDef<TData>[], IIndexedColumnDef<TData>[]] =>
	columnDefs.reduce<
		[IIndexedColumnDef<TData>[], IIndexedColumnDef<TData>[], IIndexedColumnDef<TData>[]]
	>(
		([pinnedLeft, unpinned, pinnedRight], column, index) => {
			if (!column.pinned) {
				unpinned.push({ ...column, index });
			} else if (column.pinned === EColumnPinAlignment.Right) {
				pinnedRight.push({ ...column, index });
			} else {
				pinnedLeft.push({ ...column, index });
			}

			return [pinnedLeft, unpinned, pinnedRight];
		},
		[[], [], []]
	);

export const sortColumnDefsByLockPin = <TData extends IBaseTData>(
	columnDefs: IIndexedColumnDef<TData>[]
): IIndexedColumnDef<TData>[] =>
	columnDefs.sort((columnDefA, columnDefB) => {
		if (columnDefA.lockPinned === columnDefB.lockPinned) {
			if (columnDefA.index === columnDefB.index) {
				return 0;
			}

			if (columnDefA.index < columnDefB.index) {
				return -1;
			}

			return 1;
		}

		if (columnDefA.lockPinned) {
			return -1;
		}

		return 1;
	});

export const buildHideToasterHeader = (hiddenColumnsCount: number): string =>
	`You hidden ${pluralize('column', hiddenColumnsCount, true)} successfully.`;

export const searchColumnDefs = <TData extends IBaseTData>(
	searchTerm: string,
	columnDefs: IColumnDef<TData>[]
): IColumnDef<TData>[] => {
	return columnDefs.filter((columnDef) => {
		if (!columnDef.title) {
			return false;
		}

		return isSubString(searchTerm, columnDef.title);
	});
};

export const getChangesFromColumns = <TData extends IBaseTData>(
	allColumns: IColumnDef<TData>[],
	updatedColumns: IColumnDef<TData>[]
): IColumnDef<TData>[] => {
	const updatedItems = allColumns.reduce<IColumnDef<TData>[]>((acc, column) => {
		const updatedItem = updatedColumns.find((item) => item.id === column.id);
		acc.push(updatedItem ? updatedItem : column);

		return acc;
	}, []);

	return updatedItems.sort((a, b) => updatedColumns.indexOf(a) - updatedColumns.indexOf(b));
};
