export enum EApplicationAction {
	Start = 'start',
	Stop = 'stop',
	Remove = 'remove',
	UpdateVersion = 'update-version'
}

export enum EApplicationActionResult {
	Success = 'success',
	Failed = 'failed',
	Warning = 'warning'
}

export enum EApplicationQueryParams {
	Trial = 'trial',
	Schedule = 'schedule'
}
