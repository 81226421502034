import { ITableFilters, TableFilterValue } from '@kelvininc/table';

export const useRemoveOutdatedFilters = (
	filters: ITableFilters | undefined,
	allowedDataMap: Record<string, Set<string | number | boolean>>
): ITableFilters | undefined => {
	if (!filters) return;

	return Object.entries(filters).reduce<ITableFilters>((acc, [key, value]) => {
		const allowedValues = allowedDataMap[key];

		if (allowedValues) {
			if (Array.isArray(value)) {
				acc[key] = value.filter((v) => allowedValues.has(v)) as TableFilterValue;
			} else {
				acc[key] = allowedValues.has(value) ? value : '';
			}
		}

		return acc;
	}, filters);
};
