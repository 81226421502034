import { useCallback, useEffect } from 'react';

import { setModalConfig } from '../../../Modal';
import { useWizardFormContext } from '../../../WizardFormContext';

import { openConfirmationCloseModalAlert } from './utils';

export const useWizardCloseModalConfirmationAlert = (): void => {
	const {
		reset,
		formState: { isDirty }
	} = useWizardFormContext();

	const onCloseModal = useCallback(() => {
		if (isDirty) {
			openConfirmationCloseModalAlert(reset);
			return false;
		}

		return true;
	}, [isDirty, reset]);

	useEffect(() => {
		const timeID = setTimeout(() => {
			setModalConfig({
				onClickClose: onCloseModal
			});
		});

		return () => {
			clearTimeout(timeID);
		};
	}, [onCloseModal]);
};
