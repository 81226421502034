import { keyBy } from 'lodash-es';
import { selector } from 'recoil';

import { IApplicationScheduleDependencies } from '../types';

import { appRegistryAppsMapSelector } from '@/src/recoil/app-registry/selectors';
import { applicationParameterDefinitionsListSelector } from '@/src/recoil/application-parameters/selectors';
import { assetsMapSelector } from '@/src/recoil/assets/selectors';
import { userListSelector } from '@/src/recoil/users/selectors';

export const applicationScheduleDependenciesSelector = selector<IApplicationScheduleDependencies>({
	key: 'applicationScheduleDependenciesSelector',
	get: ({ get }) => {
		const applications = get(appRegistryAppsMapSelector);
		const assets = get(assetsMapSelector);
		const users = get(userListSelector);
		const parameters = get(applicationParameterDefinitionsListSelector);

		return {
			assets,
			applications,
			users: keyBy(users, 'username'),
			parameters: keyBy(parameters, 'name')
		};
	}
});
