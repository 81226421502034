import { getParameterTitle, isKelvinClosedLoopParameter } from '@kelvininc/tsutils';
import {
	EClosedLoopMode,
	IApplicationParameterDefinition,
	IClosedLoopSettings,
	ParameterValue
} from '@kelvininc/types';

import { IAssetInsightsScheduleParameters } from './types';

const getParameterValue = (
	parameterName: string,
	parameterValue: ParameterValue,
	closedLoopSettings: IClosedLoopSettings
): ParameterValue | string => {
	if (isKelvinClosedLoopParameter(parameterName)) {
		if (parameterValue) {
			return closedLoopSettings.mapping[EClosedLoopMode.True];
		}

		return closedLoopSettings.mapping[EClosedLoopMode.False];
	}

	return parameterValue;
};

export const getParametersLabel = (
	schedule: IAssetInsightsScheduleParameters,
	definitions: IApplicationParameterDefinition,
	closedLoopSettings: IClosedLoopSettings
): string => {
	const { app_name: appName } = schedule;
	const parameters = Object.keys(schedule.parameters);
	const [parameterName] = parameters;

	const definition = definitions[appName]?.[parameterName];
	const parameter = definition
		? getParameterTitle(definition, closedLoopSettings)
		: parameterName;
	const value = getParameterValue(
		parameterName,
		schedule.parameters[parameterName],
		closedLoopSettings
	);

	if (parameters.length === 1) {
		return `${parameter} to ${value}`;
	}

	return `Multiple Parameters`;
};

export const getDetailsActionLabel = (schedule: IAssetInsightsScheduleParameters): string => {
	if (schedule.total_schedules_count > 1) {
		return `Details (+${schedule.total_schedules_count - 1})`;
	}

	return 'Details';
};
