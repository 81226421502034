export const COLUMN_TITLE_LABEL = 'Display Name';
export const COLUMN_TITLE_PLACEHOLDER = 'Write here the Display Name';
export const COLUMN_OPTIONS_EXPANDABLE_LABEL = 'Column Settings';
export const COLUMN_OPTIONS_HIDE_LABEL = 'Make the column invisible.';
export const COLUMN_SETTINGS_EXPANDABLE_LABEL = 'Content Settings';
export const COLUMN_TYPE_LABEL = 'Content Type';
export const COLUMN_TYPE_PLACEHOLDER = 'Select an option';
export const COLUMN_TITLE_SUGGESTION_PREFIX = 'Example:';

export const DATASTREAM_CONTENT_TYPE_TITLE = 'Data Stream';
export const ASSET_PROPERTY_CONTENT_TYPE_TITLE = 'Asset Property';
export const LAST_CONTROL_CHANGE_CONTENT_TYPE_TITLE = 'Last Control Change';
export const RECOMMENDATION_CONTENT_TYPE_TITLE = 'Recommendation';
export const APPLICATION_PARAMETER_CONTENT_TYPE_TITLE = 'Asset Parameter';
export const SCHEDULE_APPLICATION_PARAMETERS_CONTENT_TYPE_TITLE = 'Schedule';
