import { OrchestrationCluster, OrchestrationClustersCreateItem } from '@kelvininc/node-client-sdk';
import { atom, atomFamily } from 'recoil';

import { getClusterRequester, getClustersRequester } from './requests';

export const clusterAtomFamily = atomFamily<OrchestrationCluster, string>({
	key: 'clusterAtomFamily',
	default: (id: string) => getClusterRequester(id)
});

export const clustersListAtom = atom<OrchestrationClustersCreateItem[]>({
	key: 'clustersListAtom',
	effects: [
		({ setSelf }) => {
			setSelf(getClustersRequester());
		}
	]
});
