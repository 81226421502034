import { EAlertType } from '@kelvininc/react-ui-components';
import {
	IModalAlert,
	closeAlertInModal,
	openAlertInModal,
	setModalOverlay
} from '@kelvininc/shared-ui';

import { SubmitErrorActions } from '../../../SubmitErrorActions';
import { ImportGuardrailsWizardFormValues } from '../../types';

export const buildSubmitErrorAlert = (
	onCancel: () => void,
	onTryAgain: () => void
): IModalAlert => ({
	label: 'Something went wrong',
	description: 'The system was not able to proceed, please try again.',
	type: EAlertType.Error,
	actions: <SubmitErrorActions onCancel={onCancel} onTryAgain={onTryAgain} />
});

export const onSubmitForm = async (
	values: ImportGuardrailsWizardFormValues,
	submit: (form: ImportGuardrailsWizardFormValues) => Promise<void>
): Promise<void> => {
	setModalOverlay(true);

	try {
		await submit(values);

		setModalOverlay(false);
	} catch (_error) {
		openAlertInModal(
			buildSubmitErrorAlert(
				() => {
					setModalOverlay(false);
					closeAlertInModal();
				},
				() => {
					setModalOverlay(false);
					closeAlertInModal();
					onSubmitForm(values, submit);
				}
			)
		);
	}
};
