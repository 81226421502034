'use client';

import { KvTagStatus } from '@kelvininc/react-ui-components';

import { getValidationTagStatus } from './utils';

import { EValidationStep } from '@/src/types';

type ValidationStatusIconProps = {
	state: EValidationStep;
};

export const ValidationStatusIcon = ({ state }: ValidationStatusIconProps) => {
	return <KvTagStatus {...getValidationTagStatus(state)} />;
};
