import { buildUrl } from '@kelvininc/tsutils';
import { URLSearchParamsInit } from '@kelvininc/types';

import { OPERATIONS_ROUTE_BASE_PATH } from '../config';

const ROUTE_PATH = `${OPERATIONS_ROUTE_BASE_PATH}/smartapps`;

export const buildApplicationsRoute = (query?: URLSearchParamsInit): string =>
	buildUrl(ROUTE_PATH, query);

export const buildApplicationDetailsImpactRoute = (appName: string, query?: URLSearchParamsInit) =>
	buildUrl([ROUTE_PATH, appName, 'impact'], query);

export const buildApplicationDetailsAssetsRoute = (appName: string, query?: URLSearchParamsInit) =>
	buildUrl([ROUTE_PATH, appName, 'assets'], query);

export const buildApplicationDetailsConfigurationsRoute = (
	appName: string,
	query?: URLSearchParamsInit
) => buildUrl([ROUTE_PATH, appName, 'configurations'], query);

export const buildApplicationDetailsManagementRoute = (
	appName: string,
	query?: URLSearchParamsInit
) => buildUrl([ROUTE_PATH, appName, 'management'], query);

export const buildApplicationDetailsScheduleRoute = (
	appName: string,
	query?: URLSearchParamsInit
) => {
	return buildUrl([ROUTE_PATH, appName, 'schedule'], query);
};
