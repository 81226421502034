import { ETooltipPosition, KvTooltip } from '@kelvininc/react-ui-components';

import { IBaseTData, IHeaderRendererParams } from '../../../core/components/AgGridTable/types';
import { withBaseHeaderCellRenderer } from '../BaseHeaderCellRenderer/hocs';

const Component = <TData extends IBaseTData>({
	displayName,
	column
}: IHeaderRendererParams<TData>) => {
	return (
		<KvTooltip
			text={displayName}
			truncate
			position={ETooltipPosition.Bottom}
			disabled={column?.isSortable()}>
			<span className="ag-header-cell-text">{displayName}</span>
		</KvTooltip>
	);
};

export const HeaderTextCellRenderer = withBaseHeaderCellRenderer(Component);
