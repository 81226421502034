import {
	EListSettingKey,
	IBaseLData,
	IListSettings,
	ISettingStorageConfig,
	ISortInfo,
	useSetting
} from '@kelvininc/shared-ui';

import { isFunction, merge, pick } from 'lodash-es';
import { SetStateAction, useCallback, useMemo } from 'react';

import { RecoilState } from 'recoil';

import { IUseListSettings } from './types';

import { getListSettingsSettingKey } from '@/src/recoil/settings/utils';

export const useListSettings = <LData extends IBaseLData = IBaseLData, TSerialized = LData>(
	key: string,
	defaultValue: IListSettings<LData> = {},
	recoilState: RecoilState<IListSettings<LData>>,
	config: ISettingStorageConfig<IListSettings<LData>, TSerialized> = {}
): IUseListSettings<LData> => {
	const {
		value: setting = defaultValue,
		setValue: setSetting,
		resetValue
	} = useSetting<IListSettings<LData>, TSerialized>(
		getListSettingsSettingKey(key),
		defaultValue,
		recoilState,
		config
	);

	const settings = useMemo(
		() => pick(merge({}, defaultValue, setting), Object.values(EListSettingKey)),
		[defaultValue, setting]
	);

	const setSettings = useCallback(
		(newValue: SetStateAction<IListSettings<LData>>) =>
			setSetting((previousSettings) => {
				const newSettingValue = isFunction(newValue)
					? newValue(previousSettings)
					: newValue;

				return newSettingValue;
			}),
		[setSetting]
	);

	const setSettingsValue = useCallback(
		<SettingKey extends keyof IListSettings<LData>>(
			settingKey: SettingKey,
			newValue: IListSettings<LData>[SettingKey]
		) =>
			setSettings((previousSettings) => ({
				...previousSettings,
				[settingKey]: newValue
			})),
		[setSettings]
	);

	const onSortChange = useCallback(
		(newSort: ISortInfo<LData> = {}) =>
			setSettings((previousSettings) => ({
				...previousSettings,
				[EListSettingKey.SortBy]: newSort.sortBy,
				[EListSettingKey.SortDirection]: newSort.sortDirection
			})),
		[setSettings]
	);
	const onRowsHiddenChange = useCallback(
		(newRowsHidden: string[]) => setSettingsValue(EListSettingKey.RowsHidden, newRowsHidden),
		[setSettingsValue]
	);
	const onResetSettings = useCallback(() => resetValue(), [resetValue]);

	const callbacks = useMemo(
		() => ({
			onSortChange,
			onRowsHiddenChange,
			onResetSettings
		}),
		[onSortChange, onRowsHiddenChange, onResetSettings]
	);

	return {
		settings,
		callbacks
	};
};
