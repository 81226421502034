import { KvToggleButtonGroup } from '@kelvininc/react-ui-components';
import { useCallback, useMemo } from 'react';

import {
	useDensityLevel,
	useDensityOptions,
	useDisabledOptions,
	useIsStateNotHasResults
} from '../../../../contexts';
import { EDensityLevel } from '../../../../types';

import styles from './styles.module.scss';

import { buildDensityToggleButtons, buildSelectedToggleButtons } from './utils';

export const DensityManager = () => {
	const [densityLevel, setDensityLevel] = useDensityLevel();
	const options = useDensityOptions();
	const disabledOptions = useDisabledOptions();
	const isDensityDisabled = useIsStateNotHasResults();
	const densityToggleButtons = useMemo(() => buildDensityToggleButtons(options), [options]);
	const selectedButtons = useMemo(() => buildSelectedToggleButtons(densityLevel), [densityLevel]);
	const onClickDensityLevel = useCallback(
		({ detail: clickedDensityLevel }: CustomEvent<string>) =>
			setDensityLevel(clickedDensityLevel as EDensityLevel),
		[setDensityLevel]
	);

	return (
		<div className={styles.DensityManager}>
			<KvToggleButtonGroup
				disabled={isDensityDisabled}
				disabledButtons={disabledOptions ?? {}}
				buttons={densityToggleButtons}
				selectedButtons={selectedButtons}
				onCheckedChange={onClickDensityLevel}
			/>
		</div>
	);
};
