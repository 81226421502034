'use client';
import { EActionButtonType, EIconName, EOtherIconName } from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';

export interface IEntityHeaderAction {
	id?: string;
	url?: string;
	text?: string;
	target?: string;
	icon?: EIconName | EOtherIconName;
	actionType?: EEntityHeaderActionType;
	disabled?: boolean;
	tooltip?: string;
	buttonType?: EActionButtonType;
	customClasses?: ClassNamesProp;
	onClickAction?: () => void;
}

export enum EEntityHeaderActionType {
	Hyperlink = 'hyperlink',
	Button = 'button'
}
