import { EControlChangeState } from '@kelvininc/node-client-sdk';
import { getSelectedKeys } from '@kelvininc/tsutils';

export const buildStatusesList = (
	statusesOptions: Record<string, boolean>
): EControlChangeState[] => {
	return getSelectedKeys(statusesOptions) as EControlChangeState[];
};

export const buildStatusesOptions = (
	statusesList: EControlChangeState[] = []
): Record<string, boolean> =>
	statusesList.reduce<Record<string, boolean>>((accumulator, key) => {
		accumulator[key] = true;

		return accumulator;
	}, {});
