import { BaseService, IKvHttpResponse } from '@kelvininc/node-client-sdk';
import { openToaster } from '@kelvininc/shared-ui';
import { capitalizeFirstLetter, isHttpError } from '@kelvininc/tsutils';
import { KelvinPermission } from '@kelvininc/types';
import { useRef } from 'react';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { useHasPermissions } from '../useHasPermissions';

import { ERROR_TOASTER } from '@/src/utils/toaster';

type KelvinRequester<T, K extends {}> = (params: K) => Observable<T>;

export const useInlineEditableField = <T, K extends {}>(
	Requester: KelvinRequester<T, K>,
	permissions: KelvinPermission[] = []
) => {
	const fieldRef = useRef<HTMLKvInlineEditableFieldElement>(null);
	const hasPermission = useHasPermissions(permissions);

	const updateField = (params: K) => {
		return Requester.call(BaseService, params)
			.pipe(
				catchError((err: IKvHttpResponse) => {
					fieldRef.current?.resetContent();
					openToaster({
						...ERROR_TOASTER,
						description: isHttpError(err)
							? capitalizeFirstLetter(err.httpResponse.solution)
							: undefined
					});

					throw err;
				})
			)
			.toPromise();
	};

	return {
		fieldRef,
		fieldEditable: hasPermission,
		updateField
	};
};
