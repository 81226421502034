import { Draggable, DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd';

import { DraggableItem, DraggableItemProps } from '../DraggableItem';

type DraggableZoneProps = {
	id: string;
	index: number;
	onClick?: () => void;
	isDragDisabled?: boolean;
} & DraggableItemProps;

export const DraggableZone = ({
	id,
	index,
	onClick,
	isDragDisabled,
	...otherProps
}: DraggableZoneProps) => {
	return (
		<Draggable key={id} draggableId={id} index={index} isDragDisabled={isDragDisabled}>
			{(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
				<DraggableItem
					key={id}
					{...otherProps}
					dragging={dragSnapshot.isDragging}
					onClick={onClick}
					ref={dragProvided.innerRef}
					{...dragProvided.draggableProps}
					{...dragProvided.dragHandleProps}
				/>
			)}
		</Draggable>
	);
};
