'use client';

import { NavigationSidebar, useNavigationSideBar } from '@kelvininc/shared-ui';

import classNames from 'classnames';
import { useRecoilValue } from 'recoil';

import {
	AdminMenu,
	CatalogItem,
	ContextualizationMenu,
	OperationsMenu,
	OrchestrationMenu,
	SupportItems
} from './components';
import { useFooterInfo, useUserDropdownItems } from './hooks';

import styles from './styles.module.scss';

import { useCurrentUser, useHasPermissions } from '@/src/hooks';

import { smartAppsCatalogInstanceSettingSelector } from '@/src/recoil/instance-settings/selectors';
import { useApplicationService } from '@/src/services/application-service';
import { AREA_PERMISSIONS } from '@/src/services/permissions/config';
import { EPlatformArea } from '@/src/types';

export const CoreNavigationSidebar = () => {
	const { isCollapsed } = useNavigationSideBar();
	const { isProfessionalEdition } = useApplicationService();
	const user = useCurrentUser();
	const userDropdown = useUserDropdownItems();
	const footer = useFooterInfo();

	const hasOperationsPermissions = useHasPermissions(AREA_PERMISSIONS[EPlatformArea.Operations]);
	const hasAdminPermissions = useHasPermissions(AREA_PERMISSIONS[EPlatformArea.Admin]);

	const { enabled: smartAppsCatalogEnabled } = useRecoilValue(
		smartAppsCatalogInstanceSettingSelector
	);

	return (
		<NavigationSidebar
			user={user}
			userDropdown={userDropdown}
			footer={footer}
			instanceLabel={isProfessionalEdition ? 'Professional Edition' : ''}>
			<div className={classNames(styles.MenuSection, { [styles.Collapsed]: isCollapsed })}>
				{smartAppsCatalogEnabled && <CatalogItem />}
				{hasOperationsPermissions && <OperationsMenu />}
				{hasAdminPermissions && <ContextualizationMenu />}
				{hasAdminPermissions && <OrchestrationMenu />}
				{hasAdminPermissions && <AdminMenu />}
			</div>
			<SupportItems />
		</NavigationSidebar>
	);
};
