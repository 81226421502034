import {
	AppManagerApp,
	EParameterScheduleState,
	IParametersScheduleListData,
	ParameterDefinitionItem,
	ParamtersAppVersionSchemaGetPlain
} from '@kelvininc/node-client-sdk';
import { IApplicationParameterDefinition } from '@kelvininc/types';
import { isEmpty } from 'lodash-es';

export const buildParametersScheduleListDataParams = (
	states: EParameterScheduleState[],
	appName: string,
	appVersion?: string
): IParametersScheduleListData => {
	const version = !isEmpty(appVersion) ? { appVersion } : undefined;

	return {
		states,
		apps: [
			{
				name: appName,
				...version
			}
		]
	};
};

export const filterParametersDefinitions = (
	schema: ParamtersAppVersionSchemaGetPlain | undefined,
	parametersDefinitions: IApplicationParameterDefinition,
	modelApp: AppManagerApp
): IApplicationParameterDefinition => {
	if (isEmpty(schema)) return parametersDefinitions;

	const appSchemaProperties = Object.keys(schema?.properties ?? []).reduce<
		Record<string, ParameterDefinitionItem>
	>((acc, schemaProperty) => {
		acc[schemaProperty] = parametersDefinitions[modelApp.app.name][schemaProperty];

		return acc;
	}, {});

	return Object.keys(parametersDefinitions).reduce<IApplicationParameterDefinition>(
		(acc, key) => {
			if (key === modelApp.app.name && schema) {
				acc[key] = appSchemaProperties;
			}

			return acc;
		},
		{}
	);
};
