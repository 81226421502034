'use client';
import { ResponseErrorObject } from '@kelvininc/node-client-sdk';
import {
	EActionButtonType,
	ECodeEditorTheme,
	EIconName,
	KvActionButtonText,
	KvCodeEditor,
	KvTagStatus
} from '@kelvininc/react-ui-components';
import { ECodeTranslatorEditorLanguage, jsonTranslator } from '@kelvininc/shared-ui';
import { RJSFValidationError } from '@rjsf/utils';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { RequirementValidationList } from '../RequirementValidationList';

import { VALIDATING_PROCESS_MESSAGE, VALIDATION_PROCESS_COMPLETE_MESSAGE } from './config';
import { FileErrorsList } from './FileErrorsList';

import { IFileMappingError } from './FileErrorsList/types';

import styles from './styles.module.scss';
import {
	buildFileErrorsListTableData,
	getErrorsListDescriptionMessage,
	getKvTagStatusProps,
	getRequirementListErrorMessage
} from './utils';

import { ICSVMap, IValidationItem } from '@/src/types';

type CSVValidationSummaryViewerProps<T extends {}> = {
	csvFileHeaders: ICSVMap[];
	csvData?: T[];
	isValidCSV?: boolean;
	fileHasContent?: boolean;
	csvErrors?: RJSFValidationError[];
	validationItems: Record<string, IValidationItem[]>;
	serverValidation?: ResponseErrorObject[];
	documentationLink: string;
	onValidationComplete?: () => void;
};

export const CSVValidationSummaryViewerSkeleton = ({
	validationItems
}: {
	validationItems: Record<string, IValidationItem[]>;
}) => {
	return (
		<div className={styles.ValidationSummaryContainer}>
			<div className={classNames(styles.RequirementsListContainer, styles.ErrorsListReady)}>
				<div className={styles.ValidationListContainer}>
					<div className={styles.ValidationState}>{VALIDATING_PROCESS_MESSAGE}</div>
					<RequirementValidationList
						canStartValidation={false}
						finalRequirementsState={validationItems}
					/>
				</div>
			</div>
		</div>
	);
};

export const CSVValidationSummaryViewer = <T extends {}>({
	csvFileHeaders = [],
	csvData = [],
	csvErrors = [],
	isValidCSV = false,
	fileHasContent = false,
	validationItems,
	serverValidation = [],
	onValidationComplete,
	documentationLink,
	children
}: PropsWithChildren<CSVValidationSummaryViewerProps<T>>) => {
	const [isMockValidationComplete, setIsMockValidationComplete] = useState(false);
	const [showErrorsList, setShowErrorsList] = useState(false);

	const hasContent = fileHasContent || csvData.length > 0;
	const fileErrorsListData: Record<string, IFileMappingError> = buildFileErrorsListTableData(
		csvData,
		csvErrors
	);

	const tagState = getKvTagStatusProps(
		isValidCSV && isEmpty(fileErrorsListData) && hasContent && isEmpty(serverValidation)
	);
	const requirementListResultErrorMessage = getRequirementListErrorMessage(
		isValidCSV && isEmpty(fileErrorsListData) && isEmpty(serverValidation),
		hasContent,
		documentationLink
	);

	const tableHeaderErrorMessage = getErrorsListDescriptionMessage(csvErrors.length);

	const onMockValidationCompleted = useCallback(() => {
		setIsMockValidationComplete(true);
		onValidationComplete?.();
	}, [onValidationComplete]);

	useEffect(() => {
		setIsMockValidationComplete(false);
	}, [validationItems]);

	return (
		<div className={styles.ValidationSummaryContainer}>
			{!isEmpty(fileErrorsListData) ? (
				<div className={styles.ErrorPreviewContainer}>
					<div
						className={classNames(styles.ErrorCount, {
							[styles.DisplayErrors]: showErrorsList && hasContent
						})}>
						{tableHeaderErrorMessage}
					</div>
					<FileErrorsList headers={csvFileHeaders} errors={fileErrorsListData} />
				</div>
			) : (
				!isEmpty(serverValidation) && (
					<div className={styles.ErrorPreviewContainer}>
						<KvCodeEditor
							code={jsonTranslator(serverValidation)}
							language={ECodeTranslatorEditorLanguage.JSON}
							customOptions={{ readOnly: true }}
							theme={ECodeEditorTheme.Custom}
						/>
					</div>
				)
			)}

			<div
				className={classNames(styles.RequirementsListContainer, {
					[styles.ErrorsListReady]:
						isMockValidationComplete && hasContent && csvErrors.length > 0,
					[styles.HideRequirementsList]: showErrorsList && hasContent
				})}>
				<div className={styles.ValidationListContainer}>
					<div className={styles.ValidationState}>
						{isMockValidationComplete
							? VALIDATION_PROCESS_COMPLETE_MESSAGE
							: VALIDATING_PROCESS_MESSAGE}
					</div>
					<RequirementValidationList
						canStartValidation
						finalRequirementsState={validationItems}
						onValidationComplete={onMockValidationCompleted}
					/>
					{children}
				</div>
				{isMockValidationComplete && (
					<div className={styles.ValidationResult}>
						<KvTagStatus {...tagState} />
						<div className={styles.ValidationResultMessage}>
							{requirementListResultErrorMessage}
						</div>
						{isValidCSV &&
							(!isEmpty(fileErrorsListData) || !isEmpty(serverValidation)) && (
								<KvActionButtonText
									icon={EIconName.Error}
									text="Show Errors"
									type={EActionButtonType.Tertiary}
									onClickButton={() => setShowErrorsList(true)}
								/>
							)}
					</div>
				)}
			</div>
		</div>
	);
};
