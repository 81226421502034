import { RecoilState } from 'recoil';

import { getInstanceSetting, setInstanceSetting } from '../../services/settings';
import { IAsyncStoragePayload, useAsyncStorage } from '../useAsyncStorage';

import { IInstanceSettingStorageConfig } from './types';

export const useInstanceSetting = <T, M = T>(
	key: string,
	defaultValue: T,
	recoilState: RecoilState<T>,
	config: IInstanceSettingStorageConfig<T, M> = {}
): IAsyncStoragePayload<T> => {
	return useAsyncStorage(key, defaultValue, recoilState, {
		...config,
		getStorageValue: getInstanceSetting,
		setStorageValue: setInstanceSetting
	});
};
