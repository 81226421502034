import { DataStream } from '@kelvininc/node-client-sdk';
import { isDataStreamDataTypeNumber, stringToNumber } from '@kelvininc/tsutils';

import {
	EGuardrailCraftWizardValueType,
	EGuardrailValueType,
	GuardrailCraftWizardFormValues,
	GuardrailIncreaseDecrease,
	GuardrailMinMax
} from '../../../GuardrailCraftWizard/types';
import { ImportGuardrailFileSchema } from '../../types';

export const buildFileErrorMessage = (
	hasStructureErrors: boolean,
	hasValidationErrors: boolean
): string | undefined => {
	if (hasStructureErrors) {
		return 'Invalid csv file structure';
	}

	if (hasValidationErrors) {
		return 'Invalid csv file content data';
	}
};

export const buildGuardrailsForm = (
	data: ImportGuardrailFileSchema[],
	datastreamsMap: Record<string, DataStream>
): GuardrailCraftWizardFormValues[] => {
	return data.map<GuardrailCraftWizardFormValues>((item) => {
		const { asset: assetName, datastream: datastreamName, control } = item;

		const datastream = datastreamsMap[datastreamName];
		const isTypeNumber = isDataStreamDataTypeNumber(datastream.dataTypeName);

		if (!isTypeNumber) {
			return {
				asset: assetName,
				datastream: datastreamName,
				control,
				type: EGuardrailCraftWizardValueType.NonNumeric
			};
		}

		const {
			min_inclusive,
			min_value,
			min_value_type,
			max_inclusive,
			max_value,
			max_value_type,
			relative_decrease_min_inclusive,
			relative_decrease_min_value,
			relative_decrease_min_value_type,
			relative_decrease_max_inclusive,
			relative_decrease_max_value,
			relative_decrease_max_value_type,
			relative_increase_min_inclusive,
			relative_increase_min_value,
			relative_increase_min_value_type,
			relative_increase_max_inclusive,
			relative_increase_max_value,
			relative_increase_max_value_type
		} = item;

		return {
			asset: assetName,
			datastream: datastreamName,
			control,
			type: EGuardrailCraftWizardValueType.Numeric,
			value: {
				min: buildGuardrailMinMaxValue({
					inclusive: min_inclusive,
					value: min_value,
					value_type: min_value_type
				}),
				max: buildGuardrailMinMaxValue({
					inclusive: max_inclusive,
					value: max_value,
					value_type: max_value_type
				}),
				decrease: {
					min: buildGuardrailIncreaseDecreaseValue({
						inclusive: relative_decrease_min_inclusive,
						value: relative_decrease_min_value,
						value_type: relative_decrease_min_value_type
					}),
					max: buildGuardrailIncreaseDecreaseValue({
						inclusive: relative_decrease_max_inclusive,
						value: relative_decrease_max_value,
						value_type: relative_decrease_max_value_type
					})
				},
				increase: {
					min: buildGuardrailIncreaseDecreaseValue({
						inclusive: relative_increase_min_inclusive,
						value: relative_increase_min_value,
						value_type: relative_increase_min_value_type
					}),
					max: buildGuardrailIncreaseDecreaseValue({
						inclusive: relative_increase_max_inclusive,
						value: relative_increase_max_value,
						value_type: relative_increase_max_value_type
					})
				}
			}
		};
	});
};

const buildGuardrailMinMaxValue = ({
	inclusive,
	value,
	value_type
}: {
	inclusive: boolean;
	value: string;
	value_type: EGuardrailValueType.Number | EGuardrailValueType.Datastream;
}): GuardrailMinMax => {
	if (value_type === EGuardrailValueType.Datastream) {
		return {
			datastream: {
				inclusive,
				value
			},
			type: EGuardrailValueType.Datastream
		};
	}

	return {
		number: {
			inclusive,
			value: stringToNumber(value)
		},
		type: EGuardrailValueType.Number
	};
};

const buildGuardrailIncreaseDecreaseValue = ({
	inclusive,
	value,
	value_type
}:
	| {
			inclusive: boolean;
			value: string;
			value_type: EGuardrailValueType.Number | EGuardrailValueType.Percentage;
	  }
	| {
			inclusive?: boolean;
			value?: string;
			value_type?: EGuardrailValueType.Number | EGuardrailValueType.Percentage;
	  }): GuardrailIncreaseDecrease['min'] | GuardrailIncreaseDecrease['max'] => {
	if (inclusive === undefined || value === undefined || value_type === undefined) {
		return {
			number: {
				inclusive: true
			},
			type: EGuardrailValueType.Number
		};
	}

	if (value_type === EGuardrailValueType.Percentage) {
		return {
			percentage: {
				value: stringToNumber(value),
				inclusive
			},
			type: EGuardrailValueType.Percentage
		};
	}

	return {
		[EGuardrailValueType.Number]: {
			value: stringToNumber(value),
			inclusive
		},
		type: EGuardrailValueType.Number
	};
};
