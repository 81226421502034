import { Guardrail } from '@kelvininc/node-client-sdk';
import { EIconName } from '@kelvininc/react-ui-components';
import {
	ActionsCellRenderer,
	ECellAlignment,
	EColumnDataType,
	EGuardrailCellValueType,
	GuardrailValueCellRenderer,
	IColumnDef,
	getActionColumnOptions,
	getNameColumnOptions
} from '@kelvininc/table';
import { useState } from 'react';

import { useRecoilValue } from 'recoil';

import { ETableColumnKey } from '../../types';

import { getResourceContent } from './utils';

import { assetsMapSelector } from '@/src/recoil/assets/selectors';
import {
	datastreamsMapSelector,
	datastreamsUnitMapSelector
} from '@/src/recoil/data-streams/selectors';
import {
	EGuardrailOperationType,
	GuardrailOperation
} from '@/src/services/guardrail-service/types';
import { buildAssetDetailsRoute, buildDataStreamViewRoute } from '@/src/utils/routes';

export const useColumnDefs = (
	executeOperation: (operation: GuardrailOperation) => void
): IColumnDef<Guardrail>[] => {
	const assets = useRecoilValue(assetsMapSelector);
	const datastreams = useRecoilValue(datastreamsMapSelector);
	const datastreamUnits = useRecoilValue(datastreamsUnitMapSelector);

	const [columnDefs] = useState<IColumnDef<Guardrail>[]>(() => [
		{
			id: ETableColumnKey.Asset,
			title: 'Asset',
			accessor: 'resource',
			valueFormatter: (resource: string) => {
				const { asset: assetName } = getResourceContent(resource);

				return assets[assetName]?.title ?? assetName;
			},
			cellComponentParams: {
				getTitleLink: (_value, guardrail) => {
					const { asset: assetName } = getResourceContent(guardrail.resource);

					return buildAssetDetailsRoute(assetName);
				},
				hasCellTooltip: true,
				tooltipTruncate: true,
				description: (data: Guardrail) => {
					const { asset: assetName } = getResourceContent(data.resource);

					return assetName;
				},
				hasDescriptionCopyClipboard: true
			},
			sortable: true,
			...getNameColumnOptions()
		},
		{
			id: ETableColumnKey.Datastream,
			title: 'Data Stream',
			accessor: 'resource',
			valueFormatter: (resource: string) => {
				const { datastream: datastreamName } = getResourceContent(resource);

				return datastreams[datastreamName]?.title ?? datastreamName;
			},
			cellComponentParams: {
				getTitleLink: (_value, guardrail) => {
					const { datastream: datastreamName } = getResourceContent(guardrail.resource);

					return buildDataStreamViewRoute(datastreamName);
				},
				hasCellTooltip: true,
				tooltipTruncate: true,
				description: (data: Guardrail) => {
					const { datastream: datastreamName } = getResourceContent(data.resource);

					return datastreamName;
				},
				hasDescriptionCopyClipboard: true
			},
			sortable: true,
			...getNameColumnOptions()
		},
		{
			id: ETableColumnKey.Control,
			title: 'Control',
			accessor: 'controlDisabled',
			valueFormatter: (isControlDisabled: boolean) =>
				isControlDisabled ? 'Disabled' : 'Enabled'
		},
		{
			id: ETableColumnKey.MinValue,
			title: 'Min Value',
			valueFormatter: (_value, data) => data,
			cellComponent: GuardrailValueCellRenderer,
			cellComponentParams: {
				valueType: EGuardrailCellValueType.Min,
				datastreams,
				datastreamUnits
			},
			cellAlignment: ECellAlignment.Right,
			headerAlignment: ECellAlignment.Right,
			headerComponentParams: {
				columnDataType: EColumnDataType.Numeric
			}
		},
		{
			id: ETableColumnKey.MaxValue,
			title: 'Max Value',
			valueFormatter: (_value, data) => data,
			cellComponent: GuardrailValueCellRenderer,
			cellComponentParams: {
				valueType: EGuardrailCellValueType.Max,
				datastreams,
				datastreamUnits
			},
			cellAlignment: ECellAlignment.Right,
			headerAlignment: ECellAlignment.Right,
			headerComponentParams: {
				columnDataType: EColumnDataType.Numeric
			}
		},
		{
			id: ETableColumnKey.IncreaseMinValue,
			title: 'Increase Min Value',
			valueFormatter: (_value, data) => data,
			cellComponent: GuardrailValueCellRenderer,
			cellComponentParams: {
				valueType: EGuardrailCellValueType.IncreaseMin,
				datastreams,
				datastreamUnits
			},
			cellAlignment: ECellAlignment.Right,
			headerAlignment: ECellAlignment.Right,
			headerComponentParams: {
				columnDataType: EColumnDataType.Numeric
			}
		},
		{
			id: ETableColumnKey.IncreaseMaxValue,
			title: 'Increase Max Value',
			valueFormatter: (_value, data) => data,
			cellComponent: GuardrailValueCellRenderer,
			cellComponentParams: {
				valueType: EGuardrailCellValueType.IncreaseMax,
				datastreams,
				datastreamUnits
			},
			cellAlignment: ECellAlignment.Right,
			headerAlignment: ECellAlignment.Right,
			headerComponentParams: {
				columnDataType: EColumnDataType.Numeric
			}
		},
		{
			id: ETableColumnKey.DecreaseMinValue,
			title: 'Decrease Min Value',
			valueFormatter: (_value, data) => data,
			cellComponent: GuardrailValueCellRenderer,
			cellComponentParams: {
				valueType: EGuardrailCellValueType.DecreaseMin,
				datastreams,
				datastreamUnits
			},
			cellAlignment: ECellAlignment.Right,
			headerAlignment: ECellAlignment.Right,
			headerComponentParams: {
				columnDataType: EColumnDataType.Numeric
			}
		},
		{
			id: ETableColumnKey.DecreaseMaxValue,
			title: 'Decrease Max Value',
			valueFormatter: (_value, data) => data,
			cellComponent: GuardrailValueCellRenderer,
			cellComponentParams: {
				valueType: EGuardrailCellValueType.DecreaseMax,
				datastreams,
				datastreamUnits
			},
			cellAlignment: ECellAlignment.Right,
			headerAlignment: ECellAlignment.Right,
			headerComponentParams: {
				columnDataType: EColumnDataType.Numeric
			}
		},
		{
			id: ETableColumnKey.Actions,
			title: 'Actions',
			cellComponent: ActionsCellRenderer,
			cellComponentParams: {
				actions: (data: Guardrail) => [
					{
						id: 'edit',
						icon: EIconName.Edit,
						onClick: () =>
							executeOperation({
								type: EGuardrailOperationType.Edit,
								guardrail: data
							})
					},
					{
						id: 'delete',
						icon: EIconName.Delete,
						onClick: () =>
							executeOperation({
								type: EGuardrailOperationType.Delete,
								guardrail: data
							})
					}
				]
			},
			...getActionColumnOptions()
		}
	]);

	return columnDefs;
};
