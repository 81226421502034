import { IApplicationTutorial } from './types';

import { EAppTutorialStep } from '@/src/page-components/ApplicationsPage/ApplicationDetails/context/ApplicationTutorialContext/types';

export const DEFAULT_STEP_STATES = {
	[EAppTutorialStep.ImportData]: false,
	[EAppTutorialStep.ActOnRecommendation]: false,
	[EAppTutorialStep.AssessConsequencesOfChoices]: false
};
export const APPLICATION_TUTORIAL_SETTING_KEY = 'application-tutorial';
export const APPLICATION_TUTORIAL_SETTING_DEFAULT: IApplicationTutorial = {
	stepStates: DEFAULT_STEP_STATES,
	isSkipped: false,
	isExpanded: false,
	isCompleted: false
};
