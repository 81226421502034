'use client';
import { KvInfoLabel, KvInlineEditableField } from '@kelvininc/react-ui-components';
import { Expandable, InfoLabelList } from '@kelvininc/shared-ui';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import { RefObject } from 'react';

import { EntityHeaderActions } from '../EntityHeaderActions';
import { IEntityHeaderAction } from '../EntityHeaderActions/types';

import { INFO_LABEL_DEFAULT_PROPS } from './config';
import styles from './styles.module.scss';

export type EntityModelHeaderProps = {
	title: string;
	titleRef?: RefObject<HTMLKvInlineEditableFieldElement>;
	titleEditable?: boolean;
	subtitle?: string;
	descriptionText?: string;
	descriptionItems?: Record<string, string | undefined>;
	actions?: IEntityHeaderAction[];
	moreActions?: JSX.Element;
	modelHeaderFilters?: JSX.Element;
	customClasses?: ClassNamesProp;
	onTitleEdited?: (newTitle: string) => void;
};

export const EntityModelHeader = ({
	title,
	titleRef,
	subtitle,
	descriptionText,
	descriptionItems,
	customClasses,
	modelHeaderFilters,
	titleEditable = false,
	actions = [],
	moreActions,
	onTitleEdited
}: EntityModelHeaderProps) => {
	return (
		<div
			className={classNames(styles.HeaderContainer, customClasses)}
			data-analytics-id="entity-header">
			<div className={styles.TitleActionsContainer}>
				<div className={styles.TitleContainer}>
					<KvInlineEditableField
						ref={titleRef}
						value={title}
						disabled={!titleEditable}
						onContentEdited={({ detail }) => onTitleEdited?.(detail)}>
						<div className={styles.Title}>{title}</div>
					</KvInlineEditableField>
					{subtitle && <div className={styles.Subtitle}>{subtitle}</div>}
				</div>
				{actions && <EntityHeaderActions actions={actions} />}
				{moreActions && moreActions}
			</div>
			<Expandable>
				<div className={styles.DescriptionContainer}>
					{descriptionText && (
						<KvInfoLabel
							className={styles.DescriptionText}
							description={descriptionText}
							{...INFO_LABEL_DEFAULT_PROPS}
						/>
					)}
					<InfoLabelList items={descriptionItems} />
				</div>
			</Expandable>
			{modelHeaderFilters}
		</div>
	);
};
