import { NavigationItem, useNavigationSideBar } from '@kelvininc/shared-ui';

import styles from './styles.module.scss';

import { useSupportItems } from './utils';

export const SupportItems = () => {
	const { isCollapsed } = useNavigationSideBar();
	const items = useSupportItems();

	return (
		<div className={styles.ItemsContainer}>
			{items.map((item) => (
				<NavigationItem key={item.id} item={item} isCollapsed={isCollapsed} />
			))}
		</div>
	);
};
