import { DataStream } from '@kelvininc/node-client-sdk';
import { EComponentSize, KvSingleSelectDropdown } from '@kelvininc/react-ui-components';
import { useDropdownSearchOptions } from '@kelvininc/shared-ui';

import { useMemo } from 'react';

import {
	DEFAULT_DROPDOWN_FILTER_MAX_WIDTH,
	DEFAULT_DROPDOWN_FILTER_MIN_WIDTH
} from '../../../../../../../../../../../config';

import {
	SELECT_DATASTREAMS_DROPDOWN_OPTIONS,
	SELECT_DATASTREAMS_NO_DATA_AVAILABLE_CONFIG,
	SELECT_DATASTREAMS_PLACEHOLDER,
	SELECT_DATASTREAMS_SEARCH_PLACEHOLDER,
	USE_DROPDOWN_OPTIONS
} from './config';

import styles from './styles.module.scss';

type DatastreamDropdownProps = {
	datastreamName?: string;
	datastreams: Record<string, DataStream>;
	onChangeDatastream: (datastream: string) => void;
};

export const DatastreamDropdown = ({
	datastreams,
	datastreamName,
	onChangeDatastream
}: DatastreamDropdownProps) => {
	const data = useMemo(() => Object.values(datastreams), [datastreams]);

	const { options, filteredOptions, setSearchTerm } = useDropdownSearchOptions(
		data,
		USE_DROPDOWN_OPTIONS
	);

	return (
		<KvSingleSelectDropdown
			className={styles.DatastreamsDropdownInput}
			options={options}
			placeholder={SELECT_DATASTREAMS_PLACEHOLDER}
			dropdownOptions={SELECT_DATASTREAMS_DROPDOWN_OPTIONS}
			searchPlaceholder={SELECT_DATASTREAMS_SEARCH_PLACEHOLDER}
			noDataAvailableConfig={SELECT_DATASTREAMS_NO_DATA_AVAILABLE_CONFIG}
			filteredOptions={filteredOptions}
			selectedOption={datastreamName}
			onSearchChange={({ detail: newSearchTerm }) => setSearchTerm(newSearchTerm)}
			onOptionSelected={({ detail: newProperty }) => onChangeDatastream(newProperty)}
			inputSize={EComponentSize.Small}
			maxWidth={DEFAULT_DROPDOWN_FILTER_MAX_WIDTH}
			minWidth={DEFAULT_DROPDOWN_FILTER_MIN_WIDTH}
		/>
	);
};
