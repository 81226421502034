import classNames from 'classnames';
import { PropsWithChildren, useRef } from 'react';

import { IBaseTData, IHeaderRendererParams } from '../../../core/components/AgGridTable/types';

import { HeaderTooltipContainer, SortingIndicator } from './components';
import { useSortAttributeSync, useSortStatus } from './hooks';
import styles from './styles.module.scss';
import { isStateNotHasResults, toggleSortInfo } from './utils';

export const BaseHeaderCellRenderer = <TData extends IBaseTData>({
	children,
	...params
}: PropsWithChildren<IHeaderRendererParams<TData>>) => {
	const { enableSorting: isSortable, column, context, customClasses } = params;
	const columnRef = useRef<HTMLDivElement>(null);
	const isClickDisabled = isStateNotHasResults(context);
	const { isSorting, isSortAscending } = useSortStatus(column, context);

	const onClickHeader = () => {
		if (!isSortable || isClickDisabled) {
			return;
		}

		if (context && column) {
			const newSortInfo = toggleSortInfo(
				column.getColId(),
				context.sortBy,
				context.sortDirection
			);

			context?.onSortChange?.(newSortInfo);
		}
	};

	useSortAttributeSync(columnRef, isSorting, isSortAscending);

	return (
		<div
			className={classNames('ag-cell-label-container', customClasses, styles.Header, {
				[styles.HeaderNonClickable]: isClickDisabled
			})}
			onClick={onClickHeader}
			ref={columnRef}>
			<HeaderTooltipContainer {...params}>
				<div className="ag-header-cell-label">
					{children}
					{isSortable && (
						<SortingIndicator isSorting={isSorting} isSortAscending={isSortAscending} />
					)}
				</div>
			</HeaderTooltipContainer>
		</div>
	);
};
