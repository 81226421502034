import { Property } from '@kelvininc/node-client-sdk';

import { EComponentSize, KvSingleSelectDropdown } from '@kelvininc/react-ui-components';
import { useDropdownSearchOptions } from '@kelvininc/shared-ui';

import { useMemo } from 'react';

import {
	DEFAULT_DROPDOWN_FILTER_MAX_WIDTH,
	DEFAULT_DROPDOWN_FILTER_MIN_WIDTH
} from '../../../../../../../../../../../config';
import {
	SELECT_ASSET_PROPERTY_DROPDOWN_OPTIONS,
	SELECT_ASSET_PROPERTY_NO_DATA_AVAILABLE_CONFIG,
	SELECT_ASSET_PROPERTY_PLACEHOLDER,
	SELECT_ASSET_PROPERTY_SEARCH_PLACEHOLDER
} from '../../config';

import { USE_DROPDOWN_OPTIONS } from './config';

import styles from './styles.module.scss';

type AssetPropertyDropdownProps = {
	property?: string;
	properties?: Record<string, Property>;
	onChangeProperty: (newProperty: string) => void;
};

export const AssetPropertyDropdown = ({
	property,
	properties = {},
	onChangeProperty
}: AssetPropertyDropdownProps) => {
	const data = useMemo(() => Object.values(properties), [properties]);

	const { options, filteredOptions, setSearchTerm } = useDropdownSearchOptions(
		data,
		USE_DROPDOWN_OPTIONS
	);

	return (
		<KvSingleSelectDropdown
			className={styles.PropertyInput}
			options={options}
			placeholder={SELECT_ASSET_PROPERTY_PLACEHOLDER}
			dropdownOptions={SELECT_ASSET_PROPERTY_DROPDOWN_OPTIONS}
			searchPlaceholder={SELECT_ASSET_PROPERTY_SEARCH_PLACEHOLDER}
			noDataAvailableConfig={SELECT_ASSET_PROPERTY_NO_DATA_AVAILABLE_CONFIG}
			filteredOptions={filteredOptions}
			selectedOption={property}
			onSearchChange={({ detail: newSearchTerm }) => setSearchTerm(newSearchTerm)}
			onOptionSelected={({ detail: newProperty }) => onChangeProperty(newProperty)}
			inputSize={EComponentSize.Small}
			maxWidth={DEFAULT_DROPDOWN_FILTER_MAX_WIDTH}
			minWidth={DEFAULT_DROPDOWN_FILTER_MIN_WIDTH}
		/>
	);
};
