import { WorkloadService } from '@kelvininc/node-client-sdk';

import { mapTo } from 'rxjs/operators';

import { AppDeploymentService } from './AppDeploymentService';
import {
	AppNameParameter,
	AppUndeployParameters,
	IAppDeploymentActionProps,
	IKelvinAppParameters
} from './types';
import { buildIDeployKelvinAppWorkloadData } from './utils';

export class KelvinAppDeployment implements AppDeploymentService {
	deploy({ parameters }: IAppDeploymentActionProps<IKelvinAppParameters>): Promise<boolean> {
		return WorkloadService.deployWorkload({
			workloadDeployData: buildIDeployKelvinAppWorkloadData(parameters)
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	start({ parameters }: IAppDeploymentActionProps<AppNameParameter>): Promise<boolean> {
		return WorkloadService.startWorkload({
			workloadName: parameters.name
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	stop({ parameters }: IAppDeploymentActionProps<AppNameParameter>): Promise<boolean> {
		return WorkloadService.stopWorkload({
			workloadName: parameters.name
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	apply({ parameters }: IAppDeploymentActionProps<AppNameParameter>): Promise<boolean> {
		return WorkloadService.applyWorkload({
			workloadApplyData: {
				workload_names: [parameters.name]
			}
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	undeploy({ parameters }: IAppDeploymentActionProps<AppUndeployParameters>): Promise<boolean> {
		return WorkloadService.undeployWorkload({
			workloadName: parameters.name,
			staged: parameters.staged
		})
			.pipe(mapTo(true))
			.toPromise();
	}
}
