import { useEffect, useMemo, useState } from 'react';

import { FILE_MAPPING } from '../../config';

import { UseFileContent } from './types';
import { buildGuardrailsFromCSV } from './utils';

import { isValidCSVFile } from '@/src/utils/schema-csv';

export const useFileContent = (file?: File): UseFileContent => {
	const [content, setContent] = useState<string>();
	const [isLoading, setLoading] = useState(false);

	const { isValidCSVHeader, noDuplicatedColumns } = useMemo(
		() => isValidCSVFile(content ?? '', FILE_MAPPING),
		[content]
	);

	const { data, missingHeaderTitle } = useMemo(() => {
		if (!isValidCSVHeader || !noDuplicatedColumns) {
			return { data: [], missingHeaderTitle: false };
		}

		return buildGuardrailsFromCSV(content ?? '', FILE_MAPPING);
	}, [content, isValidCSVHeader, noDuplicatedColumns]);

	const errors = useMemo(
		() => ({
			duplicatedColumns: !noDuplicatedColumns,
			invalidCSVHeader: !isValidCSVHeader,
			noContent: data.length === 0,
			missingHeaderTitle
		}),
		[data.length, isValidCSVHeader, missingHeaderTitle, noDuplicatedColumns]
	);

	useEffect(() => {
		let ignore = false;

		const readFileContent = async () => {
			setLoading(true);

			try {
				if (!file) {
					return;
				}

				const fileContent = await file.text();
				if (ignore) {
					return;
				}

				setContent(fileContent);
			} finally {
				setLoading(false);
			}
		};

		readFileContent();

		return () => {
			ignore = true;
		};
	}, [file]);

	return {
		loading: isLoading,
		data,
		errors
	};
};
