import { RefObject } from 'react';

import { useScroll } from '../useScroll';

import { DEFAULT_SCROLL_TOP_THRESHOLD_IN_PX } from './config';
import { UseScrollingOptions } from './types';

export const useScrolling = <T extends HTMLElement>(
	ref: RefObject<T>,
	{ threshold = DEFAULT_SCROLL_TOP_THRESHOLD_IN_PX }: UseScrollingOptions = {}
): boolean => {
	const { scrollTop } = useScroll(ref);

	return scrollTop > threshold;
};
