'use client';
import { ITabNavigationItem } from '@kelvininc/react-ui-components';

import { ECodeTranslatorEditorLanguage } from '@kelvininc/shared-ui';

import { LANGUAGE_TABS_LABELS } from './config';

export const buildLanguageTabsConfig = (
	languages: ECodeTranslatorEditorLanguage[],
	activeLanguage: ECodeTranslatorEditorLanguage,
	valid: boolean
): ITabNavigationItem[] => {
	return languages.map((language) => ({
		tabKey: language,
		label: LANGUAGE_TABS_LABELS[language],
		disabled: language !== activeLanguage && !valid
	}));
};
