import { DataStream } from '@kelvininc/node-client-sdk';

import { ISelectSingleOptions } from '@kelvininc/react-ui-components';
import {
	AGGREGATION_FUNCTION_DROPDOWN_OPTIONS,
	NON_NUMERIC_AGGREGATION_FUNCTION_DROPDOWN_OPTIONS,
	isDataStreamDataTypeNumber
} from '@kelvininc/tsutils';

export const isSelectedDatastreamNonNumeric = (
	selectedDatastreamName: string,
	datastreams: Record<string, DataStream>
): boolean => {
	const selectedDatastream = datastreams[selectedDatastreamName];

	if (!selectedDatastream) {
		// Non existing datastream should be handled like text
		return true;
	}

	return !isDataStreamDataTypeNumber(selectedDatastream.dataTypeName);
};

export const buildFiltersAggregationFunctions = (
	selectedDatastreamName: string | undefined,
	datastreams: Record<string, DataStream>
): ISelectSingleOptions => {
	if (!selectedDatastreamName) {
		return AGGREGATION_FUNCTION_DROPDOWN_OPTIONS;
	}

	if (isSelectedDatastreamNonNumeric(selectedDatastreamName, datastreams)) {
		return NON_NUMERIC_AGGREGATION_FUNCTION_DROPDOWN_OPTIONS;
	}

	return AGGREGATION_FUNCTION_DROPDOWN_OPTIONS;
};
