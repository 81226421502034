import { ControlChangeGet, EKrnResource } from '@kelvininc/node-client-sdk';
import { getWorkloadLink } from '@kelvininc/tsutils';

export const useControlChangeCreatorLink = (
	controlChange: ControlChangeGet
): string | undefined => {
	if (controlChange.createdType !== EKrnResource.Workload) {
		return;
	}

	//! To do - update this
	return getWorkloadLink(controlChange.createdBy, '');
};
