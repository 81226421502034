import { ICellEditor } from '@ag-grid-community/core';
import { EValidationState, ITextField, KvTextField } from '@kelvininc/react-ui-components';
import { PropsWithForwardedRef } from '@kelvininc/types';
import classNames from 'classnames';

import { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { IBaseTData, ITableCellEditorParams } from '../../../core/components/AgGridTable/types';

import styles from './styles.module.scss';
import { getInputState, getStartValue } from './utils';

type TextCellEditorProps<TData extends IBaseTData> = ITableCellEditorParams<TData, string> &
	TextCellEditorParams<TData>;

export type TextCellEditorParams<TData extends IBaseTData> = {
	/* If `true`, the editor will use the provided colDef.valueFormatter to format
	the value displayed in the editor. Used when the cell value needs formatting
	prior to editing, such as when using reference data and you want to display
	text rather than code. */
	shouldFormatter?: boolean;
	validator?: (newValue: string | undefined | null, data: TData) => boolean;
	inputProps?: Omit<ITextField, 'value' | 'state' | 'size'>;
};

const Component = <TData extends IBaseTData>({
	value,
	data,
	shouldFormatter,
	stopEditing,
	parseValue,
	formatValue,
	validator,
	inputProps,
	forwardedRef
}: PropsWithForwardedRef<TextCellEditorProps<TData>, ICellEditor>) => {
	const [inputValue, setInputValue] = useState(
		getStartValue(value, formatValue, shouldFormatter) ?? value
	);
	const [inputState, setInputState] = useState<EValidationState>(
		getInputState(value, data, validator)
	);

	/* Component Editor Lifecycle methods */
	useImperativeHandle(forwardedRef, () => {
		return {
			getValue: () => (inputValue ? parseValue(inputValue) : inputValue),
			isCancelBeforeStart: () => false,
			isCancelAfterEnd: () => false,
			focusOut: () => stopEditing(),
			isPopup: () => false
		};
	});

	const onTextChange = useCallback(
		({ detail }: CustomEvent<string>) => {
			setInputValue(detail);
			setInputState(getInputState(detail, data, validator));
		},
		[data, validator]
	);

	return (
		<div className={classNames('ag-cell-edit-wrapper', styles.TextCellEditorContainer)}>
			<KvTextField
				className="ag-cell-editor"
				forcedFocus
				value={inputValue}
				state={inputState}
				{...inputProps}
				onTextChange={onTextChange}
				onTextFieldBlur={() => stopEditing()}
			/>
		</div>
	);
};

export const TextCellEditor = forwardRef(function TextCellEditor<TData extends IBaseTData>(
	props: TextCellEditorProps<TData>,
	ref: ForwardedRef<ICellEditor>
) {
	return <Component {...props} forwardedRef={ref} />;
}) as <TData extends IBaseTData>(
	props: TextCellEditorProps<TData> & { ref?: ForwardedRef<ICellEditor> }
) => ReturnType<typeof Component<TData>>;
