'use client';

import { isPromise } from '@kelvininc/tsutils';
import {
	EqualAtomFamilyOptions,
	EqualAtomOptions,
	EqualSelectorFamilyOptions,
	EqualSelectorOptions
} from '@kelvininc/types';
import { isEqual } from 'lodash-es';
import {
	CallbackInterface,
	Loadable,
	RecoilState,
	RecoilValue,
	RecoilValueReadOnly,
	SerializableParam,
	atom,
	atomFamily,
	selector,
	selectorFamily,
	useRecoilValue
} from 'recoil';

export const useRecoilOptionalValue = <T>(recoilValue: RecoilValue<T>): T | undefined => {
	try {
		return useRecoilValue(recoilValue);
	} catch (error) {
		if (isPromise(error)) {
			throw error;
		}

		return;
	}
};

export const equalAtom = <T>(options: EqualAtomOptions<T>): RecoilState<T> => {
	const { key, equals = isEqual, ...otherOptions } = options;

	const innerAtom = atom<T>({
		key: `${key}_inner`,
		...otherOptions
	});

	return selector<T>({
		key,
		get: ({ get }) => get(innerAtom),
		set: ({ get, set }, newValue) => {
			const lastest = get(innerAtom);

			if (!equals(newValue as T, lastest)) {
				set(innerAtom, newValue);
			}
		}
	});
};

export const equalAtomFamily = <T, P extends SerializableParam>(
	options: EqualAtomFamilyOptions<T, P>
): ((param: P) => RecoilState<T>) => {
	const { key, equals = isEqual, ...otherOptions } = options;

	const innerAtom = atomFamily<T, P>({
		key: `${key}_inner`,
		...otherOptions
	});

	return selectorFamily<T, P>({
		key,
		get:
			(param) =>
			({ get }) =>
				get(innerAtom(param)),
		set:
			(param) =>
			({ get, set }, newValue) => {
				const lastest = get(innerAtom(param));

				if (!equals(newValue as T, lastest)) {
					set(innerAtom(param), newValue);
				}
			}
	});
};

export function equalSelector<T>(options: EqualSelectorOptions<T>): RecoilValueReadOnly<T> {
	const { key, equals = isEqual, ...otherOptions } = options;

	const innerSelector = selector({
		key: `${key}_inner`,
		...otherOptions
	});

	let prior: T;

	return selector({
		key,
		get: ({ get }) => {
			const lastest = get(innerSelector);

			if (equals(prior, lastest)) {
				return prior;
			}

			prior = lastest;

			return lastest;
		}
	});
}

export function equalSelectorFamily<T, P extends SerializableParam>(
	options: EqualSelectorFamilyOptions<T, P>
): (param: P) => RecoilValueReadOnly<T> {
	const { key, equals = isEqual, ...otherOptions } = options;

	const innerSelector = selectorFamily<T, P>({
		key: `${key}_inner`,
		...otherOptions
	});

	const priors = new Map<string, T>();

	return selectorFamily<T, P>({
		key: options.key,
		get:
			(param) =>
			({ get }) => {
				const paramSelector = innerSelector(param);
				const latest = get(paramSelector);
				const priorKey = paramSelector.key;

				if (priors.has(priorKey)) {
					const prior = priors.get(priorKey) as T;

					if (equals(latest, prior)) {
						return prior;
					}
				}

				priors.set(priorKey, latest);

				return latest;
			}
	});
}

export function setEqual<T>(
	set: CallbackInterface['set'],
	atomState: RecoilState<T>,
	newValue: T
): void {
	set(atomState, (prev) =>
		isEqual(JSON.stringify(prev), JSON.stringify(newValue)) ? prev : newValue
	);
}

export function isLoadableValueArrayEmpty<T>(loadable: Loadable<Array<T>>): boolean {
	return loadable.state === 'hasValue' && loadable.contents.length === 0;
}

export function isLoadableValueArrayWithContent<T>(loadable: Loadable<Array<T>>): boolean {
	return loadable.state === 'hasValue' && loadable.contents.length > 0;
}
