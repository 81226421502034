import { WorkloadService } from '@kelvininc/node-client-sdk';

import { mapTo } from 'rxjs/operators';

import { AppDeploymentService } from './AppDeploymentService';
import { AppNameParameter, IAppDeploymentActionProps, IDockerAppParameters } from './types';
import { buildIDeployDockerAppWorkloadData } from './utils';

export class DockerAppDeployment implements AppDeploymentService {
	deploy({ parameters }: IAppDeploymentActionProps<IDockerAppParameters>): Promise<boolean> {
		return WorkloadService.deployWorkload({
			workloadDeployData: buildIDeployDockerAppWorkloadData(parameters)
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	start({ parameters }: IAppDeploymentActionProps<AppNameParameter>): Promise<boolean> {
		return WorkloadService.startWorkload({
			workloadName: parameters.name
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	stop({ parameters }: IAppDeploymentActionProps<AppNameParameter>): Promise<boolean> {
		return WorkloadService.stopWorkload({
			workloadName: parameters.name
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	apply({ parameters }: IAppDeploymentActionProps<AppNameParameter>): Promise<boolean> {
		return WorkloadService.applyWorkload({
			workloadApplyData: {
				workload_names: [parameters.name]
			}
		})
			.pipe(mapTo(true))
			.toPromise();
	}

	undeploy({ parameters }: IAppDeploymentActionProps<AppNameParameter>): Promise<boolean> {
		return WorkloadService.undeployWorkload({
			workloadName: parameters.name
		})
			.pipe(mapTo(true))
			.toPromise();
	}
}
