import { withSuspense } from '@kelvininc/shared-ui';

import { isDataStreamDataTypeNumber } from '@kelvininc/tsutils';
import { useRecoilValue } from 'recoil';

import {
	NON_NUMERIC_GUARDRAIL_DEFAULT_VALUES,
	NUMERIC_GUARDRAIL_DEFAULT_VALUES
} from '../../config';
import { useGuardrailCraftWizardFormContext } from '../../hooks';
import {
	AssetDatastreamSelector,
	AssetDatastreamSelectorDisabled,
	AssetDatastreamSelectorSkeleton
} from '../AssetDatastreamSelector';

import styles from './styles.module.scss';

import { AssetSelector, AssetSelectorSkeleton } from '@/src/components/client/AssetSelector';
import { assetsListAtom } from '@/src/recoil/assets/atoms';
import { datastreamsMapSelector } from '@/src/recoil/data-streams/selectors';

const GuardrailInfoStepSkeleton = () => {
	return (
		<div className={styles.GuardrailInfoStep}>
			<div className={styles.Asset}>
				<AssetSelectorSkeleton />
			</div>
			<div className={styles.Datastream}>
				<AssetDatastreamSelectorSkeleton />
			</div>
		</div>
	);
};

const Component = () => {
	const { watch, reset } = useGuardrailCraftWizardFormContext();
	const { asset, datastream } = watch();

	const assets = useRecoilValue(assetsListAtom);
	const datastreamsMap = useRecoilValue(datastreamsMapSelector);

	const onSelectAsset = (name: string) => {
		reset({ asset: name });
	};

	const onSelectDatastream = (name: string) => {
		const isTypeNumber = isDataStreamDataTypeNumber(datastreamsMap[name].dataTypeName);

		reset({
			...(isTypeNumber
				? NUMERIC_GUARDRAIL_DEFAULT_VALUES
				: NON_NUMERIC_GUARDRAIL_DEFAULT_VALUES),
			asset,
			datastream: name
		});
	};

	return (
		<div className={styles.GuardrailInfoStep}>
			<div className={styles.Asset}>
				<AssetSelector
					required
					value={asset}
					assets={assets}
					onValueChange={onSelectAsset}
				/>
			</div>
			<div className={styles.Datastream}>
				{asset ? (
					<AssetDatastreamSelector
						asset={asset}
						value={datastream}
						onValueChange={onSelectDatastream}
					/>
				) : (
					<AssetDatastreamSelectorDisabled />
				)}
			</div>
		</div>
	);
};

export const GuardrailInfoStep = withSuspense(Component, <GuardrailInfoStepSkeleton />);
