import { Guardrail } from '@kelvininc/node-client-sdk';
import { keyBy } from 'lodash-es';
import { selector } from 'recoil';

import { guardrailsListAtom } from './atoms';

export const guardrailsMapSelector = selector<Record<string, Guardrail>>({
	key: 'guardrailsMapSelector',
	get: ({ get }) => keyBy(get(guardrailsListAtom), 'resource')
});
