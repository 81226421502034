import { pick } from 'lodash-es';

import { IApplicationParameterTableAdvancedFilter } from '../../../../../../../AgGridTable';
import { DraftAdvancedFilter } from '../../../../../../contexts/TableAdvancedFiltersContext/types';

import { APPLICATION_PARAMETER_SCHEMA_FORM_PROPERTIES } from './config';

import { ApplicationParameterAdvancedFilterSchemaFormData } from './types';

export const getSchemaFormData = (
	filter: DraftAdvancedFilter<IApplicationParameterTableAdvancedFilter>
): ApplicationParameterAdvancedFilterSchemaFormData => {
	return pick(filter, APPLICATION_PARAMETER_SCHEMA_FORM_PROPERTIES);
};
