import { DataStream } from '@kelvininc/node-client-sdk';

import { EComponentSize, KvTextField } from '@kelvininc/react-ui-components';

import { DataStreamAggregationFunction } from '@kelvininc/types';

import { IDatastreamTableAdvancedFilter } from '../../../../../../../../../AgGridTable';
import { DraftAdvancedFilter } from '../../../../../../../../contexts/TableAdvancedFiltersContext/types';
import { SELECT_AGGREGATION_PLACEHOLDER, SELECT_OPERATOR_PLACEHOLDER } from '../../config';
import { DatastreamAdvancedFilterSchemaFormData } from '../../types';
import { getDatastreamSchemaFormData } from '../../utils';
import { DatastreamAggregationDropdown } from '../DatastreamAggregationDropdown';
import { DatastreamSchemaForm } from '../DatastreamSchemaForm';

import styles from './styles.module.scss';

type DatastreamFormProps = {
	datastream?: DataStream;
	formData: DraftAdvancedFilter<IDatastreamTableAdvancedFilter>;
	onChangeAggregation: (aggregation: DataStreamAggregationFunction) => void;
	onChangeForm: (newForm: DatastreamAdvancedFilterSchemaFormData) => void;
};

export const DatastreamForm = ({
	datastream,
	formData,
	onChangeAggregation,
	onChangeForm
}: DatastreamFormProps) => {
	if (!datastream) {
		return (
			<>
				<KvTextField
					className={styles.AggregationInput}
					placeholder={SELECT_AGGREGATION_PLACEHOLDER}
					size={EComponentSize.Small}
					inputDisabled
				/>
				<KvTextField
					className={styles.AggregationInput}
					placeholder={SELECT_OPERATOR_PLACEHOLDER}
					size={EComponentSize.Small}
					inputDisabled
				/>
			</>
		);
	}

	return (
		<>
			<DatastreamAggregationDropdown
				aggregation={formData.aggregation}
				datastream={datastream}
				onAggregationChange={onChangeAggregation}
			/>

			<DatastreamSchemaForm
				datastream={datastream}
				aggregation={formData.aggregation}
				formData={getDatastreamSchemaFormData(formData, datastream)}
				onChangeForm={onChangeForm}
			/>
		</>
	);
};
