'use client';
import { Workload } from '@kelvininc/node-client-sdk';

export enum EDeployWorkloadType {
	AppScopeDeploy = 'AppScopeDeploy',
	Deploy = 'Deploy',
	UpdateWorkload = 'UpdateWorkload',
	Edit = 'Edit'
}

export enum EWorkloadStep {
	AppInfo = 'AppInfo',
	WorkloadInfo = 'WorkloadInfo',
	Configuration = 'Configuration'
}

export type DeployWorkloadData = Pick<
	Workload,
	'appName' | 'appVersion' | 'payload' | 'name' | 'title' | 'clusterName'
> & { staged?: boolean; instantlyApply?: boolean };

export type DeployAppScopeWorkloadData = Pick<
	Workload,
	'appVersion' | 'payload' | 'name' | 'clusterName'
>;
