'use client';

import { useUnmount } from '@kelvininc/shared-ui';
import classNames from 'classnames';
import { useMemo } from 'react';

import { useRecoilValue, useResetRecoilState } from 'recoil';

import { DashboardFrame, DashboardHeader } from './components';
import { selectedDashboardTimeAtom } from './recoil/atoms';

import styles from './styles.module.scss';

import { buildGrafanaDashboardUrl } from './utils';

type EmbeddedDashboardProps = {
	title?: string;
	customClasses?: string;
	baseUrl: string;
};

export const EmbeddedDashboard = ({ title, customClasses, baseUrl }: EmbeddedDashboardProps) => {
	const time = useRecoilValue(selectedDashboardTimeAtom);
	const resetTime = useResetRecoilState(selectedDashboardTimeAtom);
	const dashboardUrl = useMemo(() => buildGrafanaDashboardUrl(baseUrl, time), [baseUrl, time]);

	useUnmount(() => {
		resetTime();
	});

	return (
		<div className={classNames(styles.ContentContainer, customClasses)}>
			<DashboardHeader title={title} />
			<DashboardFrame url={dashboardUrl} />
		</div>
	);
};
