import { EIconName, ETooltipPosition, KvIcon, KvTooltip } from '@kelvininc/react-ui-components';

import styles from './styles.module.scss';

type InclusiveIconProps = {
	inclusive: boolean;
};

export const InclusiveIcon = ({ inclusive }: InclusiveIconProps) => {
	return (
		<div className={styles.InclusiveIcon}>
			<KvTooltip
				text={inclusive ? 'Inclusive value' : 'Exclusive value'}
				position={ETooltipPosition.Top}>
				<KvIcon name={inclusive ? EIconName.Included : EIconName.NotIncluded} />
			</KvTooltip>
		</div>
	);
};
