'use client';
import { ParamtersAppVersionSchemaGetPlain } from '@kelvininc/node-client-sdk';
import { uniq } from 'lodash-es';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { applicationParametersOrderInstanceSettingSelector } from '@/src/recoil/instance-settings/selectors';

export const useAssetParameterOrder = (
	appName: string,
	appSchema?: ParamtersAppVersionSchemaGetPlain
) => {
	const appsParameterOrder = useRecoilValue(applicationParametersOrderInstanceSettingSelector);

	const uiSchemaParameterOrder = useMemo(() => {
		const appParameterOrder = appsParameterOrder[appName];

		if (!appSchema || !appParameterOrder) return [];
		const schemaProperties = Object.keys(appSchema.properties);
		const schemaPropertiesOrder = [
			...appParameterOrder.filter((property) => schemaProperties.includes(property)),
			...schemaProperties
		];

		return uniq(schemaPropertiesOrder);
	}, [appSchema, appsParameterOrder, appName]);

	return uiSchemaParameterOrder;
};
