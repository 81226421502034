import { ETooltipPosition } from '@kelvininc/react-ui-components';
import { PropsWithChildren, useMemo } from 'react';

import { EColumnDataType, IBaseTData, IHeaderRendererParams } from '../../../../../core';
import { TableTooltip } from '../../../../../core/components';
import { useSortStatus } from '../../hooks';
import { getSortingStatus } from '../../utils';

export const HeaderTooltipContainer = <TData extends IBaseTData>({
	displayName,
	enableSorting: isSortable,
	column,
	context,
	columnDataType = EColumnDataType.Text,
	tooltipHeaderField,
	tooltipHeaderPosition = ETooltipPosition.BottomStart,
	eGridHeader,
	children
}: PropsWithChildren<IHeaderRendererParams<TData>>) => {
	const { isSorting, isSortAscending } = useSortStatus(column, context);

	const tooltipText = useMemo(() => {
		if (tooltipHeaderField) {
			return tooltipHeaderField;
		}

		if (isSortable) {
			return getSortingStatus(displayName, columnDataType, isSorting, isSortAscending);
		}
	}, [tooltipHeaderField, isSortable, displayName, columnDataType, isSorting, isSortAscending]);

	return (
		<TableTooltip element={eGridHeader} text={tooltipText} position={tooltipHeaderPosition}>
			{children}
		</TableTooltip>
	);
};
