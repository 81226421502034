import { EPropertyType } from '@kelvininc/node-client-sdk';
import { IColumnOptions, ISelectCellEditorOption } from '@kelvininc/table';

import { ValidatedTextFieldMessages } from '../ValidatedTextField';

import { IKeyValueItem } from './types';

import { FORM_HELP_TEXT } from '@/src/config';

export const DEFAULT_COLUMN_DEF: IColumnOptions<IKeyValueItem> = {
	sortable: false,
	draggable: false,
	resizable: false
};

export const INITIAL_EMPTY_ROWS = 5;
export const KEY_NAME = 'key';

export const KEY_VALUE_TYPE_OPTIONS: Record<string, ISelectCellEditorOption> = {
	[EPropertyType.String]: {
		label: 'String',
		value: EPropertyType.String
	},
	[EPropertyType.Number]: {
		label: 'Number',
		value: EPropertyType.Number
	},
	[EPropertyType.Boolean]: {
		label: 'Boolean',
		value: EPropertyType.Boolean
	}
};

export const BOOLEAN_VALUE_OPTIONS: Record<string, ISelectCellEditorOption> = {
	true: {
		label: 'True',
		value: 'true'
	},
	false: {
		label: 'False',
		value: 'false'
	}
};

export const REQUIRED_FIELD_MESSAGE = 'Required field';

export const NAME_ID_ERROR_MESSAGES: ValidatedTextFieldMessages = {
	notMatchRegex: FORM_HELP_TEXT.nameExtended,
	duplicatedValue: 'Duplicated property'
};
