'use client';
import { EActionButtonType, EIconName, KvActionButtonText } from '@kelvininc/react-ui-components';
import { useUpdate } from '@kelvininc/shared-ui';
import { Table } from '@kelvininc/table';
import { convertToValidTitle, removeArrayItem, updateArrayItem } from '@kelvininc/tsutils';
import { isEmpty, isNull } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import { DEFAULT_COLUMN_DEF, INITIAL_EMPTY_ROWS, KEY_NAME } from './config';

import styles from './styles.module.scss';

import { IKeyValueItem, KeyMapping } from './types';

import { buildKeyValueEditColumnDefs, getEmptyData, newEmptyLine } from './utils';

type KeyValueTableEditableProps = {
	keyName?: string;
	initialData?: IKeyValueItem[];
	emptyRows?: number;
	keyTypeMapping?: Record<string, KeyMapping>;
	onDataChange?: (data: IKeyValueItem[]) => void;
};

export const KeyValueTableEditable = ({
	initialData,
	keyName = KEY_NAME,
	emptyRows = INITIAL_EMPTY_ROWS,
	keyTypeMapping = {},
	onDataChange
}: KeyValueTableEditableProps) => {
	const [data, setData] = useState<IKeyValueItem[]>(
		!initialData || isEmpty(initialData) ? getEmptyData(emptyRows) : initialData
	);
	const getRowId = useCallback(({ id }: IKeyValueItem) => id, []);
	const deleteRow = useCallback((rowDeleteData?: IKeyValueItem) => {
		setData((previousData) => {
			let newData = previousData;
			const index = previousData.findIndex((item) => item.id === rowDeleteData?.id);
			if (index >= 0 && index < previousData.length) {
				newData = removeArrayItem(previousData, index);
			}
			return newData.length > 0 ? newData : getEmptyData(1);
		});
	}, []);
	const columnDefs = useMemo(
		() => buildKeyValueEditColumnDefs(keyName, deleteRow, keyTypeMapping, data),
		[deleteRow, keyName, keyTypeMapping, data]
	);
	const onDataChangeHandle = useCallback(
		(rowIndex: number | null, newValue: IKeyValueItem) => {
			if (isNull(rowIndex)) {
				return;
			}
			setData((previousData) => {
				if (previousData[rowIndex].key !== newValue.key) {
					newValue.value = undefined;
					newValue.type = undefined;
					newValue.title = undefined;
				}
				if (newValue.key && keyTypeMapping[newValue.key]?.type) {
					newValue.type = keyTypeMapping[newValue.key].type;
				}
				if (newValue.key && keyTypeMapping[newValue.key]?.title) {
					newValue.title = keyTypeMapping[newValue.key].title;
				}
				if (previousData[rowIndex].type !== newValue.type) {
					newValue.value = undefined;
				}
				if (newValue.key && !newValue.title) {
					newValue.title = convertToValidTitle(newValue.key);
				}
				return updateArrayItem(previousData, rowIndex, newValue);
			});
		},
		[keyTypeMapping]
	);
	const addNewLine = useCallback(() => {
		setData((previousData) => [...previousData, newEmptyLine()]);
	}, []);

	useUpdate(() => onDataChange?.(data), [data, onDataChange]);

	return (
		<div className={styles.AssetPropertiesEditTable}>
			<Table
				customClasses={styles.Table}
				columnDefs={columnDefs}
				defaultColumnDef={DEFAULT_COLUMN_DEF}
				getRowId={getRowId}
				data={data}
				readOnlyEdit
				onDataChange={onDataChangeHandle}
			/>

			<KvActionButtonText
				text={`Add ${keyName}`}
				icon={EIconName.Add}
				type={EActionButtonType.Tertiary}
				onClickButton={addNewLine}
			/>
		</div>
	);
};
