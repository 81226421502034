import { AssetInsightsItem, Guardrail } from '@kelvininc/node-client-sdk';
import { getSetting } from '@kelvininc/shared-ui';
import { ITableSettings, ITablesSettings } from '@kelvininc/table';
import { AtomEffect } from 'recoil';

import { TABLES_SETTINGS_SETTING_DEFAULT, TABLE_SETTINGS_SETTING_DEFAULT } from './config';

import { ISerializedAssetInsightsTableSettings } from '@/src/hooks/useAssetInsightsTableSettings/types';
import { deserializeAssetInsightsTableSettings } from '@/src/hooks/useAssetInsightsTableSettings/utils';
import { deserializeAssetInsightsTablesSettings } from '@/src/hooks/useAssetInsightsTablesSettings/utils';
import {
	assetsListTableAdvancedFiltersConfigSelector,
	assetsListTableCustomColumnsConfigMetadataSelector
} from '@/src/page-components/AssetsPage/AssetsList/recoil/selectors';
import {
	appAssetsListTableAdvancedFiltersConfigSelector,
	appAssetsListTableCustomColumnsConfigMetadataSelector
} from '@/src/recoil/application/selectors';
import { filterValidAdvancedFiltersSettings } from '@/src/utils/asset-insights';

export const syncAssetsListTableSettingsSettingInitialValue: (
	settingKey: string
) => AtomEffect<ITableSettings<AssetInsightsItem> | undefined> =
	(settingKey) =>
	({ setSelf, getPromise }) => {
		return setSelf(
			(async () => {
				const metadata = await getPromise(
					assetsListTableCustomColumnsConfigMetadataSelector
				);
				const config = await getPromise(assetsListTableAdvancedFiltersConfigSelector);

				return getSetting(
					settingKey,
					TABLE_SETTINGS_SETTING_DEFAULT as ITableSettings<AssetInsightsItem> | undefined,
					{
						deserializer: (serializedSettings: ISerializedAssetInsightsTableSettings) =>
							deserializeAssetInsightsTableSettings(
								serializedSettings,
								metadata,
								(settings) => filterValidAdvancedFiltersSettings(settings, config)
							)
					}
				);
			})()
		);
	};

export const syncAppAssetsListTablesSettingsSettingInitialValue: (
	settingKey: string
) => AtomEffect<ITablesSettings<AssetInsightsItem>> =
	(settingKey) =>
	({ setSelf, getPromise }) => {
		return setSelf(
			(async () => {
				const metadata = await getPromise(
					appAssetsListTableCustomColumnsConfigMetadataSelector
				);
				const config = await getPromise(appAssetsListTableAdvancedFiltersConfigSelector);

				return getSetting(
					settingKey,
					TABLES_SETTINGS_SETTING_DEFAULT as unknown as ITablesSettings<AssetInsightsItem>,
					{
						deserializer: (
							serializedSettings: Record<string, ITablesSettings<AssetInsightsItem>>
						) =>
							deserializeAssetInsightsTablesSettings(
								serializedSettings,
								metadata,
								config
							)
					}
				);
			})()
		);
	};

export const syncGuardrailsListTableSettingsSettingInitialValue: (
	settingKey: string
) => AtomEffect<ITableSettings<Guardrail>> =
	(settingKey) =>
	({ setSelf }) =>
		setSelf(
			getSetting(
				settingKey,
				TABLE_SETTINGS_SETTING_DEFAULT as unknown as ITableSettings<Guardrail>
			)
		);
