import { Asset, BridgeItem, DataStream } from '@kelvininc/node-client-sdk';

export const buildDisabledTooltip = (
	datastreams: DataStream[],
	assets: Asset[],
	connections: BridgeItem[]
): string | undefined => {
	if (assets.length === 0) {
		return 'No assets available';
	}

	if (datastreams.length === 0) {
		return 'No data streams available';
	}

	if (connections.length === 0) {
		return 'No connections available';
	}
};
