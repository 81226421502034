import { IDraggableItem } from '@kelvininc/shared-ui';
import { useMemo } from 'react';

import { IBaseTData } from '../../../../../../../../../AgGridTable';
import { IIndexedColumnDef } from '../../../../types';

import { buildDraggableColumnItems } from '../../utils';

export const useFilteredItems = <TData extends IBaseTData>(
	items: IIndexedColumnDef<TData>[],
	shouldHideInvisible: boolean,
	onClickEdit?: (itemIndex: number) => void,
	onClickVisibility?: (itemIndex: number) => void
): IDraggableItem[] => {
	return useMemo(() => {
		let filteredItems = items;

		if (shouldHideInvisible) {
			filteredItems = filteredItems.filter(({ hide }) => !hide);
		}

		return buildDraggableColumnItems(filteredItems, onClickEdit, onClickVisibility);
	}, [items, onClickEdit, onClickVisibility, shouldHideInvisible]);
};
