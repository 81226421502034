import { DataStream } from '@kelvininc/node-client-sdk';
import { KvTabNavigation } from '@kelvininc/react-ui-components';

import { useState } from 'react';

import { UseFormReturn } from 'react-hook-form';

import {
	EGuardrailCraftWizardValueType,
	GuardrailCraftWizardFormValues,
	NumericGuardrailCraftWizardFormValues
} from '../../types';
import { AdvancedGuardrailValuesForm } from '../AdvancedGuardrailValuesForm';
import { BasicGuardrailValuesForm } from '../BasicGuardrailValuesForm';

import { GUARDRAIL_VALUES_FORM_TABS } from './config';
import styles from './styles.module.scss';
import { EGuardrailValuesFormTabKey } from './types';

type GuardrailValuesFormProps = {
	datastreamsMap: Record<string, DataStream>;
	disabled?: boolean;
	form: UseFormReturn<GuardrailCraftWizardFormValues>;
};

export const GuardrailValuesForm = ({
	datastreamsMap,
	disabled = false,
	form
}: GuardrailValuesFormProps) => {
	const [selectedTab, setSelectedTab] = useState<EGuardrailValuesFormTabKey>(
		EGuardrailValuesFormTabKey.Basic
	);
	const { watch } = form;
	const { type } = watch();

	const datastreamsList = Object.values(datastreamsMap);
	const isTypeNumeric = type === EGuardrailCraftWizardValueType.Numeric;

	if (!isTypeNumeric) {
		return null;
	}

	const numericForm = form as UseFormReturn<NumericGuardrailCraftWizardFormValues>;

	const FormComponent = {
		[EGuardrailValuesFormTabKey.Basic]: (
			<BasicGuardrailValuesForm
				datastreams={datastreamsList}
				disabled={disabled}
				form={numericForm}
			/>
		),
		[EGuardrailValuesFormTabKey.Advanced]: (
			<AdvancedGuardrailValuesForm disabled={disabled} form={numericForm} />
		)
	};

	return (
		<div className={styles.GuardrailValuesForm}>
			<div className={styles.Tabs}>
				<KvTabNavigation
					tabs={GUARDRAIL_VALUES_FORM_TABS}
					selectedTabKey={selectedTab}
					onTabChange={({ detail: newTab }) =>
						setSelectedTab(newTab as EGuardrailValuesFormTabKey)
					}
				/>
			</div>
			<div className={styles.Form}>{FormComponent[selectedTab]}</div>
		</div>
	);
};
