'use client';
import { ParametersService } from '@kelvininc/node-client-sdk';
import {
	buildDeleteModalConfig,
	closeModal,
	openModal,
	openToaster,
	setModalOverlay
} from '@kelvininc/shared-ui';
import { useCallback, useMemo } from 'react';

import { EScheduleAction, ParameterScheduleAttributes } from '../../types';

import {
	SCHEDULE_APPLY_TYPE,
	SCHEDULE_OPERATION_ERROR_TOASTER,
	SCHEDULE_OPERATION_SUCCESS_TOASTER
} from './config';

import { getDeleteScheduleModalConfig, isScheduled } from './util';

export const useScheduleActionController = (reloadListFn?: () => void) => {
	const onApplySchedule = useCallback(
		(schedule?: ParameterScheduleAttributes) =>
			schedule &&
			isScheduled(schedule.state) &&
			ParametersService.applyParametersSchedule({
				scheduleId: schedule.id,
				parametersScheduleApplyData: {
					type: SCHEDULE_APPLY_TYPE[schedule.state]
				}
			})
				.toPromise()
				.then(() => {
					openToaster(SCHEDULE_OPERATION_SUCCESS_TOASTER);
					reloadListFn?.();
				})
				.catch(() => openToaster(SCHEDULE_OPERATION_ERROR_TOASTER)),
		[reloadListFn]
	);

	const onScheduleDelete = useCallback(
		(schedule: ParameterScheduleAttributes) => {
			setModalOverlay(true);
			ParametersService.deleteParametersSchedule({ scheduleId: schedule.id })
				.toPromise()
				.then(() => {
					openToaster(SCHEDULE_OPERATION_SUCCESS_TOASTER);
					reloadListFn?.();
				})
				.catch(() => openToaster(SCHEDULE_OPERATION_ERROR_TOASTER))
				.finally(() => closeModal());
		},
		[reloadListFn]
	);

	const openScheduleDeleteModal = useCallback(
		(schedule?: ParameterScheduleAttributes) => {
			if (!schedule) return;

			openModal(
				buildDeleteModalConfig(
					getDeleteScheduleModalConfig(async () => onScheduleDelete(schedule))
				)
			);
		},
		[onScheduleDelete]
	);

	const operationsFn = useMemo(
		() => ({
			[EScheduleAction.ApplyNow]: onApplySchedule,
			[EScheduleAction.Delete]: openScheduleDeleteModal
		}),
		[openScheduleDeleteModal, onApplySchedule]
	);

	return useCallback(
		(action: EScheduleAction, schedule?: ParameterScheduleAttributes) => {
			operationsFn[action](schedule);
		},
		[operationsFn]
	);
};
