import { KvIcon, KvTag } from '@kelvininc/react-ui-components';
import { getParameterScheduleCalendarIcon } from '@kelvininc/tsutils';
import classNames from 'classnames';

import { ParameterScheduleAttributes } from '../../types';

import styles from './styles.module.scss';
import { getFormattedDates } from './utils';

export const ScheduleDataTime = (schedule: ParameterScheduleAttributes) => {
	const { scheduledFor, revertedAt } = getFormattedDates(schedule);
	return (
		<div className={styles.DateTimeContainer}>
			<span>{scheduledFor}</span>
			{revertedAt && (
				<>
					<div className={styles.Separator} />
					<span>{revertedAt}</span>
				</>
			)}
			<KvTag
				className={classNames({ [styles.Irreversible]: !revertedAt })}
				label={revertedAt ? 'Change and Revert' : 'Change Once'}>
				<div slot="left-slot">
					<KvIcon name={getParameterScheduleCalendarIcon(revertedAt)} />
				</div>
			</KvTag>
		</div>
	);
};
