import { DataStream } from '@kelvininc/node-client-sdk';
import { isDataStreamDataTypeNumber, isDataStreamDataTypeString } from '@kelvininc/tsutils';

import { DataStreamAggregationFunction, EDataStreamCustomAgg } from '@kelvininc/types';
import { isNil, pick } from 'lodash-es';

import { IDatastreamTableAdvancedFilter } from '../../../../../../../../../core/components/AgGridTable/types';
import { DraftAdvancedFilter } from '../../../../../../../../../core/components/Table/contexts/TableAdvancedFiltersContext/types';
import { EQUALITY_OPERATORS } from '../../../../../../../../../utils';

import { DATASTREAM_SCHEMA_FORM_PROPERTIES, SETPOINT_SEMANTIC_TYPE_NAMES } from './config';
import { DatastreamAdvancedFilterSchemaFormData, TableFilterValueList } from './types';

export const isSetpointSemanticType = (datastream: DataStream): boolean =>
	isDataStreamDataTypeNumber(datastream.dataTypeName) &&
	SETPOINT_SEMANTIC_TYPE_NAMES.includes(datastream.semanticTypeName);

export const isStringValueList = (
	datastream: DataStream,
	{ value, operator }: DatastreamAdvancedFilterSchemaFormData
) =>
	isDataStreamDataTypeString(datastream.dataTypeName) &&
	!isNil(operator) &&
	!isNil(value) &&
	EQUALITY_OPERATORS.includes(operator) &&
	Array.isArray(value);

export const getDatastreamSchemaFormData = (
	filter: DraftAdvancedFilter<IDatastreamTableAdvancedFilter>,
	datastream: DataStream
): DatastreamAdvancedFilterSchemaFormData => {
	const options = pick(filter, DATASTREAM_SCHEMA_FORM_PROPERTIES);

	if (isStringValueList(datastream, options)) {
		return { ...options, value: undefined, valueList: options.value as TableFilterValueList };
	}

	return options;
};

export const setDatastreamAggregation = (
	datastreams: Record<string, DataStream>,
	datastreamName: string
): DataStreamAggregationFunction | undefined => {
	const datastream = datastreams[datastreamName];

	if (
		isSetpointSemanticType(datastream) ||
		!isDataStreamDataTypeNumber(datastream.dataTypeName)
	) {
		return EDataStreamCustomAgg.Last;
	}
};
