import { DataStream } from '@kelvininc/node-client-sdk';

import { assetNameFromResource, isDataStreamDataTypeNumber } from '@kelvininc/tsutils';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { assetDatastreamContextsListAtomFamily } from '@/src/recoil/data-streams/atoms';
import { datastreamsMapSelector } from '@/src/recoil/data-streams/selectors';

export const useAssetAvailableDatastreams = (
	assetName: string,
	guardrailName: string
): DataStream[] => {
	const contexts = useRecoilValue(assetDatastreamContextsListAtomFamily(assetName));
	const datastreamsMap = useRecoilValue(datastreamsMapSelector);

	return useMemo(() => {
		return contexts.reduce<DataStream[]>((accumulator, { datastreamName, context }) => {
			const datastream = datastreamsMap[datastreamName];
			const available = context.some(({ resource }) => {
				const contextAsset = assetNameFromResource(resource);
				const isTypeNumber = isDataStreamDataTypeNumber(datastream.dataTypeName);

				return (
					isTypeNumber && assetName === contextAsset && datastreamName !== guardrailName
				);
			});

			if (available) {
				accumulator.push(datastreamsMap[datastreamName]);
			}

			return accumulator;
		}, []);
	}, [assetName, contexts, datastreamsMap, guardrailName]);
};
