'use client';

import { withBoundaries } from '@kelvininc/shared-ui';
import { PropsWithChildren } from 'react';

import './styles.scss';

const Component = (props: PropsWithChildren<{}>) => {
	const { children } = props;

	return <div className="page">{children}</div>;
};

export const PageWithoutBoundaries = Component;
export const Page = withBoundaries(Component);
