import { EErrorType } from '@kelvininc/types';

import { useMemo } from 'react';

import {
	VALIDATION_ITEMS,
	VALIDATION_ITEMS_CONTENT_KEY,
	VALIDATION_ITEMS_STRUCTURE_KEY,
	VALIDATION_ITEMS_SYSTEM_KEY
} from '../../components/ImportSummaryStep/config';
import { UseFileContent } from '../useFileContent/types';
import {
	VALIDATION_CONTENT_INVALID_MAX_TYPE,
	VALIDATION_CONTENT_INVALID_MAX_VALUE_RANGE,
	VALIDATION_CONTENT_INVALID_MIN_TYPE,
	VALIDATION_CONTENT_INVALID_MIN_VALUE_RANGE,
	VALIDATION_CONTENT_INVALID_NON_NUMERIC_FIELDS,
	VALIDATION_CONTENT_MISSING_REQUIRED_FIELDS_FOR_DATASTREAM_TYPE,
	VALIDATION_CONTENT_RESOURCE_NOT_IN_METRICS_MAP,
	VALIDATION_SYSTEM_DUPLICATED_RESOURCE_NAME,
	VALIDATION_SYSTEM_RESOURCE_ALREADY_EXITS,
	VALIDATION_SYSTEM_UNEXISTING_ASSET_NAME,
	VALIDATION_SYSTEM_UNEXISTING_DATASTREAM_NAME
} from '../useFileValidation/config';
import { UseFileValidation } from '../useFileValidation/types';

import { UseValidationItems } from './types';

import { EValidationStep } from '@/src/types';

export const useValidationItems = (
	structureErrors: UseFileContent['errors'],
	validationErrors: UseFileValidation['errors']
): UseValidationItems => {
	const {
		contentPatternMismatch,
		missingRequiredFields,
		duplicatedContent,
		duplicatedGuardrails,
		unexistingAssets,
		unexistingDatastreams,
		invalidLimits
	} = validationErrors.reduce(
		(accumulator, item) => {
			const name = item.name as EErrorType;
			const message = item.message as string;

			if (
				[
					EErrorType.MissingEntity,
					EErrorType.ColumnType,
					EErrorType.AdditionalProperties,
					EErrorType.Dependencies
				].includes(name) ||
				message === VALIDATION_CONTENT_INVALID_NON_NUMERIC_FIELDS
			) {
				accumulator['contentPatternMismatch'] = true;
			}

			if (
				EErrorType.MissingColumn === name ||
				message === VALIDATION_CONTENT_MISSING_REQUIRED_FIELDS_FOR_DATASTREAM_TYPE
			) {
				accumulator['missingRequiredFields'] = true;
				return accumulator;
			}

			if (
				EErrorType.DuplicatedContent === name ||
				VALIDATION_SYSTEM_DUPLICATED_RESOURCE_NAME === message
			) {
				accumulator['duplicatedContent'] = true;
				return accumulator;
			}

			if (message === VALIDATION_SYSTEM_RESOURCE_ALREADY_EXITS) {
				accumulator['duplicatedGuardrails'] = true;
			}

			if (
				[
					VALIDATION_SYSTEM_UNEXISTING_ASSET_NAME,
					VALIDATION_CONTENT_RESOURCE_NOT_IN_METRICS_MAP
				].includes(message)
			) {
				accumulator['unexistingAssets'] = true;
			}

			if (
				[
					VALIDATION_SYSTEM_UNEXISTING_DATASTREAM_NAME,
					VALIDATION_CONTENT_RESOURCE_NOT_IN_METRICS_MAP
				].includes(message)
			) {
				accumulator['unexistingDatastreams'] = true;
			}

			if (
				[
					VALIDATION_CONTENT_INVALID_MIN_VALUE_RANGE,
					VALIDATION_CONTENT_INVALID_MAX_VALUE_RANGE,
					VALIDATION_CONTENT_INVALID_MIN_TYPE,
					VALIDATION_CONTENT_INVALID_MAX_TYPE
				].includes(message)
			) {
				accumulator['invalidLimits'] = true;
			}

			return accumulator;
		},
		{
			contentPatternMismatch: false,
			missingRequiredFields: false,
			duplicatedContent: false,
			duplicatedGuardrails: false,
			unexistingAssets: false,
			unexistingDatastreams: false,
			invalidLimits: false
		}
	);

	const { invalidCSVHeader, missingHeaderTitle, noContent, duplicatedColumns } = structureErrors;

	const invalidColumns = duplicatedColumns || missingHeaderTitle;

	return useMemo(
		() => ({
			[VALIDATION_ITEMS_STRUCTURE_KEY]: [
				{
					...VALIDATION_ITEMS[VALIDATION_ITEMS_STRUCTURE_KEY][0],
					state: noContent ? EValidationStep.Error : EValidationStep.Success
				},
				{
					...VALIDATION_ITEMS[VALIDATION_ITEMS_STRUCTURE_KEY][1],
					state: invalidCSVHeader ? EValidationStep.Error : EValidationStep.Success
				},
				{
					...VALIDATION_ITEMS[VALIDATION_ITEMS_STRUCTURE_KEY][2],
					state: invalidColumns ? EValidationStep.Error : EValidationStep.Success
				}
			],
			[VALIDATION_ITEMS_CONTENT_KEY]: [
				{
					...VALIDATION_ITEMS[VALIDATION_ITEMS_CONTENT_KEY][0],
					state: missingRequiredFields ? EValidationStep.Error : EValidationStep.Success
				},
				{
					...VALIDATION_ITEMS[VALIDATION_ITEMS_CONTENT_KEY][1],
					state: contentPatternMismatch ? EValidationStep.Error : EValidationStep.Success
				},
				{
					...VALIDATION_ITEMS[VALIDATION_ITEMS_CONTENT_KEY][2],
					state: duplicatedContent ? EValidationStep.Error : EValidationStep.Success
				},
				{
					...VALIDATION_ITEMS[VALIDATION_ITEMS_CONTENT_KEY][3],
					state: invalidLimits ? EValidationStep.Error : EValidationStep.Success
				}
			],
			[VALIDATION_ITEMS_SYSTEM_KEY]: [
				{
					...VALIDATION_ITEMS[VALIDATION_ITEMS_SYSTEM_KEY][0],
					state: duplicatedGuardrails ? EValidationStep.Error : EValidationStep.Success
				},
				{
					...VALIDATION_ITEMS[VALIDATION_ITEMS_SYSTEM_KEY][1],
					state: unexistingAssets ? EValidationStep.Error : EValidationStep.Success
				},
				{
					...VALIDATION_ITEMS[VALIDATION_ITEMS_SYSTEM_KEY][2],
					state: unexistingDatastreams ? EValidationStep.Error : EValidationStep.Success
				}
			]
		}),
		[
			contentPatternMismatch,
			duplicatedContent,
			duplicatedGuardrails,
			invalidCSVHeader,
			invalidColumns,
			invalidLimits,
			missingRequiredFields,
			noContent,
			unexistingAssets,
			unexistingDatastreams
		]
	);
};
