import { KvCopyToClipboard } from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import { ComponentProps, PropsWithChildren } from 'react';

import { DEFAULT_COPY_TOOLTIP_DELAY_MS, DEFAULT_TOOLTIP_CONFIG } from './config';
import styles from './styles.module.scss';

type CopyToClipboardProps = {
	text: string;
	enabled?: boolean;
	tooltipSuffix?: string;
	tooltipDelay?: number;
	tooltipConfig?: ComponentProps<typeof KvCopyToClipboard>['tooltipConfig'];
	customClasses?: ClassNamesProp;
};

export const CopyToClipboard = ({
	children,
	text,
	enabled = true,
	tooltipSuffix,
	tooltipDelay = DEFAULT_COPY_TOOLTIP_DELAY_MS,
	tooltipConfig = DEFAULT_TOOLTIP_CONFIG,
	customClasses
}: PropsWithChildren<CopyToClipboardProps>) => {
	if (enabled) {
		return (
			<KvCopyToClipboard
				copiableText={text}
				tooltipSuffix={tooltipSuffix}
				tooltipDelay={tooltipDelay}
				tooltipConfig={tooltipConfig}
				className={classNames(styles.CopyClipboard, customClasses)}>
				<div className={styles.CopyClipboardContent}>{children}</div>
			</KvCopyToClipboard>
		);
	}

	return <>{children}</>;
};
