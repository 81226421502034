import { KvBreadcrumbItem } from '@kelvininc/react-ui-components';
import { ComponentProps, ReactNode } from 'react';

import { Link } from '../../../Link';

import styles from './styles.module.scss';

type BreadcrumbsItemProps = {
	icon?: ReactNode;
	label?: ReactNode;
	value: string;
} & Pick<ComponentProps<typeof KvBreadcrumbItem>, 'active'> &
	Pick<ComponentProps<typeof Link>, 'href'>;

export const BreadcrumbsItem = ({ href, value, label, active, icon }: BreadcrumbsItemProps) => {
	return (
		<KvBreadcrumbItem active={active} label={value}>
			<Link href={active ? undefined : href}>
				<div className={styles.Content}>
					{label ?? value}
					{icon}
				</div>
			</Link>
		</KvBreadcrumbItem>
	);
};
