'use client';
import { AppItem } from '@kelvininc/node-client-sdk';
import { EAlertType, IModalConfig, IWizard, IWizardStep } from '@kelvininc/react-ui-components';
import { IModalAlert, openAlertInModal } from '@kelvininc/shared-ui';

import { isKelvinApp } from '@kelvininc/tsutils';

import { AlertModalDescription } from './AlertModalDescription';
import { DEPLOY_KELVIN_APP_CONFIRMATION_ALERT } from './config';
import styles from './styles.module.scss';

import { EDeployWorkloadType, EWorkloadStep } from './types';

import { buildApplicationDetailsImpactRoute, buildConnectionRoute } from '@/src/utils/routes';

export const getWizardStepsConfig = (
	flowType: EDeployWorkloadType
): { [key in EWorkloadStep]: IWizardStep } => ({
	[EWorkloadStep.AppInfo]: {
		title: 'SmartApp Info',
		cancelable: true
	},
	[EWorkloadStep.WorkloadInfo]: {
		title: 'Workload Settings',
		allowGoBack: true,
		cancelable: true
	},
	[EWorkloadStep.Configuration]: {
		title: 'Configuration',
		allowGoBack: flowType !== EDeployWorkloadType.Edit,
		cancelable: true
	}
});

const getWizardSteps = (flowType: EDeployWorkloadType): EWorkloadStep[] => {
	switch (flowType) {
		case EDeployWorkloadType.Edit:
			return [EWorkloadStep.Configuration];
		default:
			return [EWorkloadStep.AppInfo, EWorkloadStep.WorkloadInfo, EWorkloadStep.Configuration];
	}
};

const getCompleteButtonLabel = (
	stagedEnabled: boolean,
	instantlyApply: boolean,
	isProfessionalEdition = false
): string => {
	if (stagedEnabled && instantlyApply) {
		return 'Stage & Apply';
	} else if (stagedEnabled) {
		return 'Stage';
	} else {
		return isProfessionalEdition ? 'Create Image' : 'Deploy';
	}
};

export const getInitialWizardConfig = (
	flowType: EDeployWorkloadType,
	stagedEnabled: boolean,
	instantlyApply: boolean,
	isProfessionalEdition = false
): IWizard => ({
	steps: getWizardSteps(flowType).map((stepId) => getWizardStepsConfig(flowType)[stepId]),
	currentStep: 0,
	showStepBar: flowType !== EDeployWorkloadType.Edit,
	showHeader: flowType !== EDeployWorkloadType.Edit,
	completeBtnLabel: getCompleteButtonLabel(stagedEnabled, instantlyApply, isProfessionalEdition)
});

export const getModalConfig = (currentWorkloadStep: EWorkloadStep): IModalConfig => ({
	customClass: styles[currentWorkloadStep]
});

export const getWorkloadStep = (step: number, flowType: EDeployWorkloadType): EWorkloadStep =>
	getWizardSteps(flowType)[step];

export const getDeployErrorAlert = (description?: string): IModalAlert => {
	return {
		label: 'Something went wrong with the deployment.',
		description,
		type: EAlertType.Error
	};
};

export const handleAppTypeAlert = (name: string, app: AppItem) => {
	openAlertInModal({
		...DEPLOY_KELVIN_APP_CONFIRMATION_ALERT,
		descriptionSlot: (
			<AlertModalDescription
				title="here"
				href={
					isKelvinApp(app)
						? buildApplicationDetailsImpactRoute(name)
						: buildConnectionRoute()
				}
				target="_blank"
				app={app}
			/>
		)
	});
};

export const buildDeployConfirmationAlert = (isProfessionalEdition: boolean): IModalAlert => ({
	label: `Are you sure you want to ${isProfessionalEdition ? 'create' : 'deploy'} this workload?`,
	description: `Please confirm that you really want to ${
		isProfessionalEdition ? 'create' : 'deploy'
	} the workload.`,
	type: EAlertType.Info
});
