import { EActionButtonType } from '@kelvininc/react-ui-components';
import { useMemo } from 'react';

import { ActionButton } from '../../../core/components/ActionButton';
import { IBaseTData, ICellRendererParams } from '../../../core/components/AgGridTable';
import { withBaseCellRenderer } from '../BaseCellRenderer';

import styles from './styles.module.scss';
import { ActionsCellButtons } from './types';
import { getActionsButtonProps } from './utils';

export type ActionsCellRendererParams<TData extends IBaseTData> = {
	actions?: ActionsCellButtons<TData>;
};
const Component = <TData extends IBaseTData>({
	actions = [],
	data
}: ActionsCellRendererParams<TData> & ICellRendererParams<TData>) => {
	const actionsConfig = useMemo(() => getActionsButtonProps(actions, data), [actions, data]);

	return (
		<div className={styles.ActionsContainer} data-analytics-id="table-actions-row">
			{actionsConfig.map((action) => (
				<ActionButton
					key={action.id}
					{...action}
					onClick={() => action.onClick?.(data)}
					type={EActionButtonType.Ghost}
				/>
			))}
		</div>
	);
};
export const ActionsCellRenderer = withBaseCellRenderer(Component, {
	hasCellTooltip: false,
	displayEmptyState: false
});
