import { AppItem } from '@kelvininc/node-client-sdk';
import { openAlertInModal } from '@kelvininc/shared-ui';
import { isEmpty } from 'lodash-es';
import { useEffect, useLayoutEffect } from 'react';
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil';

import { applicationVersionsListSelector } from '../../../recoil/selectors';
import { NO_APPLICATION_DATA_AVAILABLE } from '../config';

export const useApplicationVersions = (applications: AppItem[], appName?: string) => {
	const appVersionsList = useRecoilValueLoadable(applicationVersionsListSelector(appName));
	const refreshAppVersionsList = useRecoilRefresher_UNSTABLE(
		applicationVersionsListSelector(appName)
	);

	useEffect(() => {
		if (isEmpty(applications)) {
			openAlertInModal(NO_APPLICATION_DATA_AVAILABLE);
		}
	}, [applications]);

	useLayoutEffect(() => {
		if (appName) {
			refreshAppVersionsList();
		}
	}, [appName, refreshAppVersionsList]);

	return {
		appVersionsList: appVersionsList.valueMaybe() ?? [],
		appVersionsLoading: appVersionsList.state === 'loading'
	};
};
