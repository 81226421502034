import { ComputePositionConfig, offset, shift } from '@floating-ui/dom';

import { CodeEditorOptions } from '@kelvininc/react-ui-components';

export const DEFAULT_DROPDOWN_FILTER_MAX_HEIGHT = '280px';

export const DEFAULT_DEBOUNCE_TIME_IN_MS = 500;

export const UNIT_MAX_LENGTH_INPUT_CHARACTERS = 16;
export const NAME_MAX_LENGTH_INPUT_CHARACTERS = 64;
export const NAME_REDUCED_MAX_LENGTH_INPUT_CHARACTERS = 32;

export const PAGINATED_LIST_MAX_SIZE = 1000;

export const FORM_HELP_TEXT = {
	name: `Use only lowercase alphanumeric characters (letters and numbers) and dashes ( - ). The entry should start with an alphabetic character and end with an alphanumeric character. For example: 'my-name-01'.`,
	nameExtended: `Use only lowercase alphanumeric characters (letters and numbers), dashes ( - ), dots ( . ) or underscores ( _ ). The entry should start and end with an alphanumeric character. For example: 'my-name-01'.`
};

export const APP_REGEX = {
	name: '^[a-z]([-a-z0-9]*[a-z0-9])?$',
	nameExtended: '^[a-z0-9]([-_.a-z0-9]*[a-z0-9])?$'
};

export const DEFAULT_POLLING_TIME_IN_MILLIS = 30000;
export const DEFAULT_CODE_EDITOR_CONFIG: CodeEditorOptions = {
	readOnly: true,
	scrollBeyondLastLine: true
};

export const DEFAULT_TOOLTIP_POSITION_CONFIG: Partial<ComputePositionConfig> = {
	strategy: 'fixed',
	middleware: [offset(5), shift({ padding: 5 })]
};

export enum EDocumentationLinks {
	AssetBulkImport = 'asset-bulk-import',
	AssetPropertyBulkImport = 'asset-property-bulk-import',
	DatastreamBulkImport = 'datastream-bulk-import',
	GuardrailBulkImport = 'guardrail-bulk-import',
	OPCUAConnection = 'opc-ua-connection',
	ModbusConnection = 'modbus-connection',
	CSVPublisherConnection = 'csv-publisher-connection',
	ROCConnection = 'roc-connection',
	ProvisionK3S = 'provision-k3s',
	ProvisionK8S = 'provision-kubernetes',
	ProvisionEKS = 'provision-eks',
	ProvisionAKS = 'provision-aks'
}

export const DOCUMENTATION_LINKS: { [key in EDocumentationLinks]: string } = {
	[EDocumentationLinks.AssetBulkImport]:
		'platform-administration/how-to/asset/create-assets-bulk/',
	[EDocumentationLinks.AssetPropertyBulkImport]:
		'platform-administration/how-to/asset/create-asset/',
	[EDocumentationLinks.DatastreamBulkImport]:
		'developer-tools/quick-start/how-to/configure-kelvin-cloud/?import-assets#import-data-streams',
	[EDocumentationLinks.GuardrailBulkImport]:
		'developer-tools/how-to/deploy/guardrails/bulk-create-guardrails/',
	[EDocumentationLinks.OPCUAConnection]:
		'platform-administration/how-to/connect/connect-to-opc-ua/',
	[EDocumentationLinks.ModbusConnection]:
		'platform-administration/how-to/connect/connect-to-modbus/',
	[EDocumentationLinks.CSVPublisherConnection]:
		'platform-administration/how-to/connect/csv-publisher/',
	[EDocumentationLinks.ROCConnection]: 'platform-administration/how-to/connect/connect-to-roc/',
	[EDocumentationLinks.ProvisionK8S]:
		'platform-administration/how-to/cluster/provision-a-cloud-cluster/',
	[EDocumentationLinks.ProvisionK3S]:
		'platform-administration/how-to/cluster/provision-a-k3s-cluster/',
	[EDocumentationLinks.ProvisionEKS]:
		'platform-administration/how-to/cluster/provision-an-eks-cluster/',
	[EDocumentationLinks.ProvisionAKS]:
		'platform-administration/how-to/cluster/provision-a-aks-cluster/'
};
