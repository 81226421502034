import { KvFormLabel, KvRadio } from '@kelvininc/react-ui-components';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { RadioButton } from './types';

type RadioButtonsProps<T> = {
	value: T;
	label?: string;
	required?: boolean;
	buttons: RadioButton<T>[];
	disabled?: boolean;
	onChange: (value: T) => void;
};

export const RadioButtons = <T,>({
	value,
	label,
	required,
	buttons,
	disabled = false,
	onChange
}: RadioButtonsProps<T>) => {
	const onClickButton = (button: RadioButton<T>) => {
		if (!disabled && !button.disabled) {
			onChange(button.value);
		}
	};

	return (
		<div className={styles.RadioButtons}>
			{label && <KvFormLabel required={required} label={label} />}
			<div className={styles.Buttons}>
				{buttons.map((button) => (
					<div
						key={button.label}
						className={classNames(styles.Button, {
							[styles.ButtonChecked]: button.value === value,
							[styles.ButtonDisabled]: button.disabled || disabled
						})}
						onClick={() => onClickButton(button)}>
						<div className={styles.Radio}>
							<KvRadio checked={button.value === value && !disabled} />
						</div>
						<div className={styles.Label}>{button.label}</div>
					</div>
				))}
			</div>
		</div>
	);
};
