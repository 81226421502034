import { isString } from '@kelvininc/tsutils';
import { isNil } from 'lodash-es';

import { ReactNode } from 'react';

import { DEFAULT_EMPTY_LAST_SEEN_LABEL, DEFAULT_EMPTY_LAST_SEEN_TOOLTIP_LABEL } from './config';
import { IInfoLabelItemValue } from './types';

export const getItemValue = (value?: ReactNode): IInfoLabelItemValue => {
	if (isNil(value)) {
		return {
			label: DEFAULT_EMPTY_LAST_SEEN_LABEL,
			tooltip: DEFAULT_EMPTY_LAST_SEEN_TOOLTIP_LABEL
		};
	}

	return {
		label: value,
		tooltip: isString(value) ? value : ''
	};
};
