export const TUTORIAL_SIDEBAR_LEFT_OFFSET = 24;
export const TUTORIAL_HEADER = 'Complete Missing Tasks!';
export const TUTORIAL_HEADER_SUCCESS = 'All set! Welcome to new heights.';
export const TUTORIAL_DESCRIPTION =
	"Let's set everything up! Just a few clicks away from a seamless experience.";
export const TUTORIAL_SKIP_TEXT = 'Click here to skip.';
export const TUTORIAL_CONFETTI_CONFIG = {
	angle: 45,
	spread: 360,
	startVelocity: 50,
	elementCount: 1000,
	dragFriction: 0.2,
	duration: 3000,
	width: '6px',
	height: '5px',
	perspective: '618px',
	colors: ['#FFB639', '#CE488D', '#83B9D1', '#8E4899', '#46266A']
};
