import { syncUserSettingInitialValue } from '@kelvininc/shared-ui';
import { atom, atomFamily } from 'recoil';

import {
	APPLICATIONS_PINNED_USER_SETTING_DEFAULT,
	APPLICATIONS_PINNED_USER_SETTING_KEY,
	ASSETS_SEARCH_HISTORY_USER_SETTING_DEFAULT,
	ASSETS_SEARCH_HISTORY_USER_SETTING_KEY,
	ASSETS_SEARCH_MAXIMAZED_USER_SETTING_DEFAULT,
	ASSETS_SEARCH_MAXIMAZED_USER_SETTING_KEY,
	ENTITY_MODEL_EXPANDABLE_HEADER_USER_SETTING_DEFAULT,
	FIRST_EXPERIENCE_SKIPPED_USER_SETTING_DEFAULT,
	FIRST_EXPERIENCE_SKIPPED_USER_SETTING_KEY
} from './config';
import { IExpandableSetting } from './types';
import { getEntityModelExpandableHeaderUserSettingKey } from './utils';

import { APPLICATION_TUTORIAL_SETTING_DEFAULT } from '@/src/page-components/SmartAppsCatalogPage/hooks/useApplicationTutorialSetting/config';
import { IApplicationTutorial } from '@/src/page-components/SmartAppsCatalogPage/hooks/useApplicationTutorialSetting/types';
import { IAssetsSearchHistory } from '@/src/types/assets';

export const applicationsPinnedUserSettingAtom = atom<string[]>({
	key: 'applicationsPinnedUserSettingAtom',
	effects: [
		syncUserSettingInitialValue(
			APPLICATIONS_PINNED_USER_SETTING_KEY,
			APPLICATIONS_PINNED_USER_SETTING_DEFAULT
		)
	]
});

export const assetsSearchHistoryUserSettingAtom = atom<IAssetsSearchHistory>({
	key: 'assetsSearchHistoryUserSettingAtom',
	effects: [
		syncUserSettingInitialValue(
			ASSETS_SEARCH_HISTORY_USER_SETTING_KEY,
			ASSETS_SEARCH_HISTORY_USER_SETTING_DEFAULT
		)
	]
});

export const assetsSearchMaximazedUserSettingAtom = atom<IExpandableSetting>({
	key: 'assetsSearchMaximazedUserSettingAtom',
	effects: [
		syncUserSettingInitialValue(
			ASSETS_SEARCH_MAXIMAZED_USER_SETTING_KEY,
			ASSETS_SEARCH_MAXIMAZED_USER_SETTING_DEFAULT
		)
	]
});

export const entityModelExpandedHeaderUserSettingAtomFamily = atomFamily<
	IExpandableSetting,
	string
>({
	key: 'entityModelExpandedHeaderUserSettingAtomFamily',
	effects: (settingKey) => [
		syncUserSettingInitialValue(
			getEntityModelExpandableHeaderUserSettingKey(settingKey),
			ENTITY_MODEL_EXPANDABLE_HEADER_USER_SETTING_DEFAULT
		)
	]
});

export const firstExperienceSkippedUserSettingAtom = atom<boolean>({
	key: 'firstExperienceSkippedUserSettingAtom',
	effects: [
		syncUserSettingInitialValue(
			FIRST_EXPERIENCE_SKIPPED_USER_SETTING_KEY,
			FIRST_EXPERIENCE_SKIPPED_USER_SETTING_DEFAULT
		)
	]
});

export const applicationTutorialUserSettingAtomFamily = atomFamily<IApplicationTutorial, string>({
	key: 'applicationTutorialUserSettingAtomFamily',
	effects: (settingKey) => [
		syncUserSettingInitialValue(settingKey, APPLICATION_TUTORIAL_SETTING_DEFAULT)
	]
});
