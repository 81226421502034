import { EIllustrationName } from '@kelvininc/react-ui-components';
import { IllustrationMessageProps } from '@kelvininc/shared-ui';

export const ASSET_SCHEDULED_PARAMETERS_MODAL_TITLE = 'Details';
export const EMPTY_SCHEDULES_ILLUSTRATION_CONFIG: IllustrationMessageProps = {
	illustration: EIllustrationName.NoContentHere,
	title: 'No Content Here... Yet!',
	description: 'Currently, there are no schedules created for this asset.'
};

export const ERROR_ILLUSTRATION_CONFIG: IllustrationMessageProps = {
	illustration: EIllustrationName.ErrorState,
	title: 'Sorry, something went wrong.',
	description: "We're trying to fix the problem. You may also try again later."
};
