'use client';

import { Skeleton } from '@kelvininc/shared-ui';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import styles from './styles.module.scss';

type EntityModelLayoutSkeletonProps = {
	customClasses?: ClassNamesProp;
};

export const EntityModelLayoutSkeleton = ({ customClasses }: EntityModelLayoutSkeletonProps) => {
	return (
		<div className={classNames(styles.EntityModelViewSkeleton, customClasses)}>
			<div className={styles.TitleRow}>
				<div className={styles.Title}>
					<Skeleton />
				</div>
				<div className={styles.Actions}>
					<Skeleton />
				</div>
			</div>
			<div className={styles.Panel}>
				<Skeleton />
			</div>
		</div>
	);
};
