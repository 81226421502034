import { DataStream } from '@kelvininc/node-client-sdk';

import { Controller, UseFormReturn } from 'react-hook-form';

import { EGuardrailValueType, NumericGuardrailCraftWizardFormValues } from '../../types';
import { GuardrailDatastreamValueField } from '../GuardrailDatastreamValueField';
import { GuardrailNumberValueField } from '../GuardrailNumberValueField';

import styles from './styles.module.scss';

type BasicGuardrailValuesFormProps = {
	datastreams: DataStream[];
	disabled: boolean;
	form: UseFormReturn<NumericGuardrailCraftWizardFormValues>;
};

export const BasicGuardrailValuesForm = ({
	datastreams,
	disabled,
	form
}: BasicGuardrailValuesFormProps) => {
	const { trigger, control, watch, clearErrors } = form;

	const formValues = watch();

	return (
		<div className={styles.BasicGuardrailValuesForm}>
			<div className={styles.Field}>
				<div className={styles.Label}>
					<span className={styles.Required}>*</span>Minimum Value is
				</div>
				<div className={styles.Options}>
					<Controller
						name="value.min"
						control={control}
						render={({ field: { value, onChange, onBlur }, formState: { errors } }) => (
							<GuardrailNumberValueField
								checked={value.type === EGuardrailValueType.Number}
								inclusive={value.number?.inclusive}
								value={value.number?.value}
								disabled={disabled}
								error={errors?.value?.min?.number?.message}
								placeholder="Please define here the minimum value"
								onChecked={() => {
									onChange({
										...value,
										type: EGuardrailValueType.Number
									});

									if (formValues.value.max.number?.value !== undefined) {
										trigger('value.max.number');
									}
								}}
								onChangeInclusive={(inclusive) =>
									onChange({
										...value,
										number: {
											...value.number,
											inclusive
										}
									})
								}
								onChangeValue={(newValue) => {
									onChange({
										...value,
										number: { ...value.number, value: newValue }
									});

									if (formValues.value.max.number?.value !== undefined) {
										trigger('value.max.number');
									}
								}}
								onBlur={onBlur}
							/>
						)}
					/>
					<Controller
						name="value.min"
						control={control}
						render={({ field: { value, onChange, onBlur }, formState: { errors } }) => (
							<GuardrailDatastreamValueField
								checked={value.type === EGuardrailValueType.Datastream}
								inclusive={value.datastream?.inclusive}
								value={value.datastream?.value}
								disabled={disabled}
								datastreams={datastreams}
								error={errors?.value?.min?.datastream?.message}
								onChecked={() => {
									onChange({
										...value,
										type: EGuardrailValueType.Datastream
									});

									clearErrors('value.max.number');
								}}
								onChangeInclusive={(inclusive) =>
									onChange({
										...value,
										datastream: {
											...value.datastream,
											inclusive
										}
									})
								}
								onChangeValue={(newValue) =>
									onChange({
										...value,
										datastream: { ...value.datastream, value: newValue }
									})
								}
								onBlur={onBlur}
							/>
						)}
					/>
				</div>
			</div>
			<div className={styles.Field}>
				<div className={styles.Label}>
					<span className={styles.Required}>*</span>Maximum Value is
				</div>
				<div className={styles.Options}>
					<Controller
						name="value.max"
						control={control}
						render={({ field: { value, onChange, onBlur }, formState: { errors } }) => (
							<GuardrailNumberValueField
								checked={value.type === EGuardrailValueType.Number}
								inclusive={value.number?.inclusive}
								value={value.number?.value}
								disabled={disabled}
								error={errors?.value?.max?.number?.message}
								placeholder="Please define here the maximum value"
								onChecked={() => {
									onChange({
										...value,
										type: EGuardrailValueType.Number
									});

									if (formValues.value.min.number?.value !== undefined) {
										trigger('value.min.number');
									}
								}}
								onChangeInclusive={(inclusive) =>
									onChange({
										...value,
										number: {
											...value.number,
											inclusive
										}
									})
								}
								onChangeValue={(newValue) => {
									onChange({
										...value,
										number: { ...value.number, value: newValue }
									});

									if (formValues.value.min.number?.value !== undefined) {
										trigger('value.min.number');
									}
								}}
								onBlur={onBlur}
							/>
						)}
					/>
					<Controller
						name="value.max"
						control={control}
						render={({ field: { value, onChange, onBlur }, formState: { errors } }) => (
							<GuardrailDatastreamValueField
								checked={value.type === EGuardrailValueType.Datastream}
								inclusive={value.datastream?.inclusive}
								value={value.datastream?.value}
								disabled={disabled}
								datastreams={datastreams}
								error={errors?.value?.max?.datastream?.message}
								onChecked={() => {
									onChange({
										...value,
										type: EGuardrailValueType.Datastream
									});

									clearErrors('value.min.number');
								}}
								onChangeInclusive={(inclusive) =>
									onChange({
										...value,
										datastream: {
											...value.datastream,
											inclusive
										}
									})
								}
								onChangeValue={(newValue) =>
									onChange({
										...value,
										datastream: { ...value.datastream, value: newValue }
									})
								}
								onBlur={onBlur}
							/>
						)}
					/>
				</div>
			</div>
		</div>
	);
};
