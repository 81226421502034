import { EValidationState } from '@kelvininc/react-ui-components';

import { IBaseTData, IDefaultTValue } from '../../../core/components/AgGridTable/types';

export const getStartValue = <TValue = IDefaultTValue>(
	value: TValue,
	formatValue: (value: TValue) => string,
	shouldFormatter?: boolean
) => {
	return shouldFormatter ? formatValue(value) : (value as string);
};

export const getInputState = <TData extends IBaseTData>(
	value: string | undefined | null,
	data: TData,
	validator?: (newValue: string | undefined | null, data: TData) => boolean
): EValidationState => {
	if (!validator) {
		return EValidationState.None;
	}

	if (validator(value, data)) {
		return EValidationState.None;
	}

	return EValidationState.Invalid;
};
