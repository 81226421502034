'use client';
import { IPaginationParams, Model } from '@kelvininc/node-client-sdk';
import { IPaginator, IVirtualizedListApi, Skeleton, VirtualizedList } from '@kelvininc/shared-ui';
import { ClassNamesProp, FunctionComponent, PropsWithForwardedRef } from '@kelvininc/types';
import {
	CSSProperties,
	ForwardedRef,
	forwardRef,
	useCallback,
	useImperativeHandle,
	useRef
} from 'react';
import AutoSizer, { HorizontalSize, VerticalSize } from 'react-virtualized-auto-sizer';
import { Layout, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {
	DEFAULT_INITIAL_SKELETONS_AMOUNT,
	DEFAULT_LOADER_BATCH_SIZE,
	DEFAULT_LOADER_THRESHOLD,
	DEFAULT_NEXT_SKELETONS_AMOUNT
} from './config';
import styles from './styles.module.scss';
import { IInfiniteListScroll } from './types';
import { getItemKey as defaultGetItemKey } from './utils';

type InfiniteListScrollProps<T extends Model, K extends IPaginationParams> = {
	paginator: IPaginator<T, K>;
	itemSize: number | ((index: number) => number);
	itemsGap?: number;
	Item: FunctionComponent<{ index: number; data: T }>;
	layout?: Layout | undefined;
	nextSkeletonsAmount?: number;
	initialSkeletonsAmount?: number;
	minimumBatchSize?: number;
	threshold?: number;
	customClasses?: ClassNamesProp;
	customStyles?: CSSProperties;
	Skeleton?: FunctionComponent;
	Empty?: FunctionComponent;
	getItemKey?: (index: number, data: T[]) => string;
};

type InfiniteListItemProps<T extends Model> = {
	isItemLoaded: (index: number) => boolean;
	Item: FunctionComponent<{ index: number; data: T }>;
	Skeleton: FunctionComponent;
} & ListChildComponentProps<T[]>;

const DefaultSkeletonComponent = () => {
	return <Skeleton />;
};

const DefaultEmptyComponent = () => {
	return (
		<div className={styles.EmptyData}>
			<h1>It seems that there is no content here.</h1>
		</div>
	);
};

const InfiniteListItem = <T extends Model>({
	isItemLoaded,
	Item: ItemComponent,
	Skeleton: SkeletonComponent,
	data,
	index
}: InfiniteListItemProps<T>) => {
	if (!isItemLoaded(index)) {
		return <SkeletonComponent />;
	}

	return <ItemComponent index={index} data={data[index]} />;
};

const Component = <T extends Model, K extends IPaginationParams>({
	paginator,
	itemSize,
	itemsGap = 0,
	Item: ItemComponent,
	layout,
	initialSkeletonsAmount = DEFAULT_INITIAL_SKELETONS_AMOUNT,
	nextSkeletonsAmount = DEFAULT_NEXT_SKELETONS_AMOUNT,
	minimumBatchSize = DEFAULT_LOADER_BATCH_SIZE,
	threshold = DEFAULT_LOADER_THRESHOLD,
	customClasses,
	customStyles = {},
	Skeleton: SkeletonComponent = DefaultSkeletonComponent,
	Empty: EmptyComponent = DefaultEmptyComponent,
	getItemKey = defaultGetItemKey,
	forwardedRef
}: PropsWithForwardedRef<InfiniteListScrollProps<T, K>, IInfiniteListScroll<T>>) => {
	const { handler, data: itemData, loading: isLoading } = paginator;

	const isDataEmpty = itemData.length === 0;
	const itemCount = handler.hasNext() ? itemData.length + nextSkeletonsAmount : itemData.length;

	const loadMoreItems = useCallback(() => handler.next(), [handler]);
	const isItemLoaded = useCallback(
		(index: number) => !handler.hasNext() || index < itemData.length,
		[itemData.length, handler]
	);

	const loaderRef = useRef<InfiniteLoader>(null);
	const listRef = useRef<IVirtualizedListApi<T[]>>(null);

	useImperativeHandle(forwardedRef, () => ({
		loader: loaderRef.current,
		list: listRef.current
	}));

	if (isLoading && isDataEmpty) {
		return (
			<>
				{Array(initialSkeletonsAmount)
					.fill({})
					.map((_, idx) => (
						<SkeletonComponent key={idx} />
					))}
			</>
		);
	}

	if (isDataEmpty) {
		return <EmptyComponent />;
	}

	return (
		<InfiniteLoader
			ref={loaderRef}
			isItemLoaded={isItemLoaded}
			itemCount={itemCount}
			loadMoreItems={loadMoreItems}
			minimumBatchSize={minimumBatchSize}
			threshold={threshold}>
			{({ onItemsRendered, ref }) => (
				<AutoSizer ref={ref}>
					{({ height, width }: VerticalSize & HorizontalSize) => (
						<VirtualizedList
							ref={listRef}
							onItemsRendered={onItemsRendered}
							itemCount={itemCount}
							height={height}
							width={width}
							itemData={itemData}
							itemKey={getItemKey}
							itemSize={itemSize as number}
							itemsGap={itemsGap}
							layout={layout}
							customClasses={customClasses}
							customStyles={customStyles}>
							{(itemProps) => (
								<InfiniteListItem
									isItemLoaded={isItemLoaded}
									Item={ItemComponent}
									Skeleton={SkeletonComponent}
									{...itemProps}
								/>
							)}
						</VirtualizedList>
					)}
				</AutoSizer>
			)}
		</InfiniteLoader>
	);
};

export const InfiniteListScroll = forwardRef(function ProcessMapWithRef<
	T extends Model,
	K extends IPaginationParams
>(props: InfiniteListScrollProps<T, K>, ref: ForwardedRef<IInfiniteListScroll<T>>) {
	return <Component {...props} forwardedRef={ref} />;
}) as <T extends Model, K extends IPaginationParams>(
	props: InfiniteListScrollProps<T, K> & { ref?: ForwardedRef<IInfiniteListScroll<T>> }
) => ReturnType<typeof Component<T, K>>;
