'use client';
import { useCallback } from 'react';

import { ExperienceGuidePortal } from '../ExperienceGuidePortal';

import { useFirstExperienceContext } from '@/src/providers/FirstExperienceProvider';

export const FirstExperienceGuide = () => {
	const { stepStates, onStepClick } = useFirstExperienceContext();

	const {
		steps,
		platformConfigured,
		setVisibility,
		expanded: [isExpanded, setIsExpanded],
		setFirstExperienceSkipped
	} = useFirstExperienceContext();

	const onTutorialSkip = useCallback(() => {
		setFirstExperienceSkipped(true);
		setVisibility(false);
	}, [setFirstExperienceSkipped, setVisibility]);

	const onHeaderActionClick = useCallback(() => {
		if (platformConfigured) {
			setVisibility(false);
		} else {
			setIsExpanded((prev) => !prev);
		}
	}, [platformConfigured, setIsExpanded, setVisibility]);

	return (
		<ExperienceGuidePortal
			isExpanded={isExpanded}
			isCompleted={platformConfigured}
			steps={steps}
			stepStates={stepStates}
			onHeaderActionClick={onHeaderActionClick}
			onTutorialSkip={onTutorialSkip}
			onStepClick={onStepClick}
		/>
	);
};
