import { GridApi, IRowNode } from '@ag-grid-community/core';

import { IBaseTData, ITableContext } from '../../../core';
import { getDisplayedNodes } from '../../../core/components/AgGridTable/utils';

export const getSelectableDisplayedNodes = (api?: GridApi): IRowNode[] => {
	return getDisplayedNodes(api).filter(({ selectable }) => selectable);
};

export const getSelectedDisplayedNodes = <TData extends IBaseTData>(
	api?: GridApi,
	context?: ITableContext<TData>
): IRowNode[] => {
	if (api && context) {
		const { getRowId, selectionApi } = context;
		const selectedRowsIds: string[] = Object.keys(selectionApi.selectedRows);

		return getDisplayedNodes(api).filter((displayedNodeId) => {
			const displayedRowId = displayedNodeId.stub
				? 'row-stub'
				: getRowId(displayedNodeId.data as TData);

			return selectedRowsIds.includes(displayedRowId);
		});
	}

	return [];
};
