import { IOpenModalConfig } from '@kelvininc/shared-ui';

import { CreateImageModal, CreateImageModalProps } from './CreateImageModal';

export const buildCreateImageModalConfig = (props: CreateImageModalProps): IOpenModalConfig => ({
	ContentComponent: <CreateImageModal {...props} />,
	config: {
		title: 'Workload Image'
	}
});

export const buildCreateImageDescription = (clusterName: string) =>
	`You will soon be able to download the workload image. Once downloaded, you can connect to your cluster ${clusterName} through the Kelvin Edge UI and deploy it.`;
