import { ETooltipPosition, KvTooltip } from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import { PropsWithChildren, useRef } from 'react';

import { buildTableTooltipPositionConfig } from './utils';

type TableTooltipProps = {
	element?: HTMLElement;
	text?: string;
	truncate?: boolean;
	position?: ETooltipPosition;
	customClasses?: ClassNamesProp;
};

export const TableTooltip = ({
	element,
	text,
	truncate = false,
	position = ETooltipPosition.BottomStart,
	customClasses,
	children
}: PropsWithChildren<TableTooltipProps>) => {
	const ref = useRef<HTMLKvTooltipElement>(null);

	return (
		<KvTooltip
			ref={ref}
			className={classNames(customClasses)}
			text={text}
			position={position}
			truncate={truncate}
			options={buildTableTooltipPositionConfig(element ?? ref.current)}>
			{children}
		</KvTooltip>
	);
};
