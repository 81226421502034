import {
	EIconName,
	EIllustrationName,
	KvIcon,
	KvIllustration
} from '@kelvininc/react-ui-components';

import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import { Link } from '../Link';

import styles from './styles.module.scss';

type KelvinLogoProps = {
	isCollapsed?: boolean;
	linkTo?: string;
	extraLabel?: string;
	customClasses?: ClassNamesProp;
};

export const KelvinLogo = ({
	isCollapsed = false,
	linkTo = '/',
	extraLabel,
	customClasses
}: KelvinLogoProps) => {
	return (
		<div className={classNames(styles.LogoContainer, customClasses)}>
			<Link href={linkTo} className={styles.Logo}>
				{isCollapsed ? (
					<KvIcon name={EIconName.LogoKelvin} />
				) : (
					<div className={styles.IllustrationContainer}>
						<KvIllustration name={EIllustrationName.EsKelvinLogotype} />
						{extraLabel && <span className={styles.ExtraLabel}>{extraLabel}</span>}
					</div>
				)}
			</Link>
		</div>
	);
};
