'use client';
import {
	EActionButtonType,
	EIconName,
	KvActionButtonText,
	KvTextArea
} from '@kelvininc/react-ui-components';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import { MANDATORY_DESCRIPTION, MAX_NOTE_LENGTH, NON_MANDATORY_DESCRIPTION } from './config';
import styles from './styles.module.scss';

import { IToggleFeatureSetting } from '@/src/recoil/instance-settings/types';

export type ParametersConfirmationModalProps = {
	commentRequiredSetting: IToggleFeatureSetting;
	description: string;
	onConfirm: (note: string) => void;
	onCancel: () => void;
};

export const ParametersConfirmationModal = ({
	commentRequiredSetting,
	description,
	onConfirm,
	onCancel
}: ParametersConfirmationModalProps) => {
	const [value, setValue] = useState<string>('');

	const onChange = useCallback(({ detail }: CustomEvent<string>) => {
		setValue(detail);
	}, []);

	const onConfirmClick = useCallback(() => {
		onConfirm?.(value);
	}, [onConfirm, value]);

	const isConfirmButtonDisabled = useMemo(() => {
		const trimmedDescription = value.trim();
		return isEmpty(trimmedDescription) && commentRequiredSetting.enabled;
	}, [value, commentRequiredSetting]);

	return (
		<>
			<div className={styles.ParametersConfirmationModalContent}>
				<div className={styles.Title}>Changes confirmation</div>
				{description && <div className={styles.Description}>{description}</div>}
				<div className={styles.MandatoryDescription}>
					{commentRequiredSetting.enabled
						? MANDATORY_DESCRIPTION
						: NON_MANDATORY_DESCRIPTION}
				</div>
				<KvTextArea
					icon={EIconName.Notes}
					maxCharLength={MAX_NOTE_LENGTH}
					placeholder="Add a note"
					onTextChange={onChange}
				/>
			</div>
			<div className={styles.ParametersConfirmationModalFooter}>
				<div className={styles.RightContainer}>
					<KvActionButtonText
						text="Cancel"
						type={EActionButtonType.Tertiary}
						onClickButton={onCancel}
					/>
					<KvActionButtonText
						text="Confirm"
						type={EActionButtonType.Primary}
						disabled={isConfirmButtonDisabled}
						onClickButton={onConfirmClick}
					/>
				</div>
			</div>
		</>
	);
};
