import {
	EKvHttpStatusCode,
	EPaginatorType,
	EWorkloadDownloadStatus,
	IListWorkloadsParams,
	Workload,
	WorkloadItem,
	WorkloadService
} from '@kelvininc/node-client-sdk';

import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { ESortDirection } from '@kelvininc/types';
import { of } from 'rxjs';

export const getWorkloadRequester = (id: string): Promise<Workload> => {
	return WorkloadService.getWorkload({ workloadName: id })
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};

export const getWorkloadImagesPreparingListRequester = (
	clusterName?: string,
	nodeName?: string,
	appName?: string,
	appVersion?: string
): Promise<WorkloadItem[]> => {
	const params: IListWorkloadsParams<EPaginatorType.Stream> = {
		clusterName: clusterName ? [clusterName] : undefined,
		nodeName: nodeName ? [nodeName] : undefined,
		appName: appName ? [appName] : undefined,
		appVersion: appVersion ? [appVersion] : undefined,
		downloadStatuses: [EWorkloadDownloadStatus.Processing, EWorkloadDownloadStatus.Scheduled],
		paginationType: EPaginatorType.Stream,
		sortBy: ['download_status'],
		direction: ESortDirection.Asc
	};
	return WorkloadService.listWorkloads(params)
		.pipe(
			catchHttpError((error) => {
				if (error.status === EKvHttpStatusCode.NotFound) {
					return of([] as WorkloadItem[]);
				}

				return throwHttpError(error);
			})
		)
		.toPromise();
};
