import { IWizardStepConfig } from '@kelvininc/shared-ui';
import { FormState } from 'react-hook-form';
import { z } from 'zod';

import {
	GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA,
	GUARDRAIL_DATASTREAM_VALUE_SCHEMA,
	GUARDRAIL_INCREASE_DECREASE_MIN_MAX_SCHEMA,
	GUARDRAIL_INCREASE_DECREASE_SCHEMA,
	GUARDRAIL_MIN_MAX_SCHEMA,
	GUARDRAIL_NUMBER_VALUE_SCHEMA,
	NON_NUMERIC_GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA,
	NUMERIC_GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA
} from './schemas/form';

export enum EGuardrailCraftWizardStep {
	Info = 'info',
	Values = 'values'
}

export enum EGuardrailValueType {
	Number = 'number',
	Datastream = 'datastream',
	Percentage = 'percentage'
}

export enum EGuardrailCraftWizardValueType {
	Numeric = 'numeric',
	NonNumeric = 'non-numeric'
}

export type GuardrailNumberValue = z.infer<typeof GUARDRAIL_NUMBER_VALUE_SCHEMA>;
export type GuardrailDatastreamValue = z.infer<typeof GUARDRAIL_DATASTREAM_VALUE_SCHEMA>;
export type GuardrailMinMax = z.infer<typeof GUARDRAIL_MIN_MAX_SCHEMA>;
export type GuardrailIncreaseDecrease = z.infer<typeof GUARDRAIL_INCREASE_DECREASE_SCHEMA>;
export type GuardrailIncreaseDecreaseMinMax = z.infer<
	typeof GUARDRAIL_INCREASE_DECREASE_MIN_MAX_SCHEMA
>;
export type NonNumericGuardrailCraftWizardFormValues = z.infer<
	typeof NON_NUMERIC_GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA
>;
export type NumericGuardrailCraftWizardFormValues = z.infer<
	typeof NUMERIC_GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA
>;
export type GuardrailCraftWizardFormValues = z.infer<
	typeof GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA
>;

export type StepValidator = (
	values: GuardrailCraftWizardFormValues,
	formState?: FormState<GuardrailCraftWizardFormValues>
) => boolean;

export type StepValidatorCallback = (
	step: IWizardStepConfig,
	values: GuardrailCraftWizardFormValues,
	formState?: FormState<GuardrailCraftWizardFormValues>
) => boolean;
