'use client';
import { EIconName, ETooltipPosition, KvIcon, KvToggleTip } from '@kelvininc/react-ui-components';
import { InfoAlert } from '@kelvininc/shared-ui';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import { PropsWithChildren, useRef } from 'react';

import styles from './styles.module.scss';
import { buildToggleTipPositionConfig } from './utils';

export type InfoCardProps = {
	title: string;
	titleTip?: string;
	subtitle?: string;
	headerActions?: JSX.Element;
	alertMessage?: string;
	customClasses?: ClassNamesProp;
};

export const InfoCard = ({
	title,
	titleTip,
	subtitle,
	headerActions,
	alertMessage,
	customClasses,
	children
}: PropsWithChildren<InfoCardProps>) => {
	const contentRef = useRef<HTMLDivElement>(null);
	const toggleTipOptions = buildToggleTipPositionConfig(contentRef.current?.parentElement);

	return (
		<div
			ref={contentRef}
			data-test-id="e2e-info-card"
			className={classNames(styles.InfoCard, customClasses)}>
			<div className={styles.InfoCardHeader}>
				<div className={styles.TitlesContainer}>
					<div className={styles.Title}>
						{title}
						{titleTip && (
							<KvToggleTip
								options={toggleTipOptions}
								text={titleTip}
								position={ETooltipPosition.BottomStart}>
								<KvIcon name={EIconName.Info} slot="open-element-slot" />
							</KvToggleTip>
						)}
					</div>
					{subtitle && <div className={styles.Subtitle}>{subtitle}</div>}
				</div>
				{headerActions && (
					<div
						data-test-id="e2e-info-card-header-actions"
						className={styles.HeaderActions}>
						{headerActions}
					</div>
				)}
			</div>
			{alertMessage && <InfoAlert alertMessage={alertMessage} />}
			{children}
		</div>
	);
};
