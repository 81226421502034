import { ClassNamesProp, PropsWithForwardedRef } from '@kelvininc/types';
import classNames from 'classnames';
import { HTMLAttributes, Ref, forwardRef } from 'react';

import styles from './styles.module.scss';

export type DraggableItemPlaceholderProps = {
	text?: string;
};

export const DraggableItemPlaceholder = ({ text }: DraggableItemPlaceholderProps) => {
	if (text) {
		return <DraggableItem placeholder content={text} />;
	}

	return null;
};

export type DraggableItemProps = {
	content?: string | JSX.Element | null;
	inactive?: boolean;
	disabled?: boolean;
	dragging?: boolean;
	placeholder?: boolean;
	left?: JSX.Element | null;
	right?: JSX.Element | null;
	customClasses?: ClassNamesProp;
};

const Component = ({
	content,
	inactive = false,
	disabled = false,
	dragging = false,
	placeholder = false,
	left,
	right,
	forwardedRef,
	customClasses,
	...otherProps
}: PropsWithForwardedRef<
	DraggableItemProps & Omit<HTMLAttributes<HTMLDivElement>, 'placeholder' | 'content'>,
	HTMLDivElement
>) => {
	return (
		<div
			className={classNames(styles.Item, customClasses, {
				[styles.ItemInactive]: inactive,
				[styles.ItemDragging]: dragging,
				[styles.ItemDisabled]: disabled,
				[styles.ItemPlaceholder]: placeholder
			})}
			ref={forwardedRef}
			{...otherProps}>
			{left && <div className={styles.LeftContent}>{left}</div>}
			{content && <div className={styles.MiddleContent}>{content}</div>}
			{right && <div className={styles.RightContent}>{right}</div>}
		</div>
	);
};

export const DraggableItem = forwardRef(function DraggableItemWithRef(
	props: DraggableItemProps & Omit<HTMLAttributes<HTMLDivElement>, 'placeholder' | 'content'>,
	ref: Ref<HTMLDivElement>
) {
	return <Component {...props} forwardedRef={ref} />;
});
