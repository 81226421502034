import { v4 as uuidv4 } from 'uuid';

import { ETableAdvancedFilterType } from '../../../AgGridTable/types';

import { ITableAdvancedFormFilters } from './types';

export const TABLE_ADVANCED_FILTERS_FORM_DEFAULT_VALUE: ITableAdvancedFormFilters = {
	[ETableAdvancedFilterType.AssetProperty]: [
		{ id: uuidv4(), type: ETableAdvancedFilterType.AssetProperty }
	],
	[ETableAdvancedFilterType.ApplicationParameter]: [
		{ id: uuidv4(), type: ETableAdvancedFilterType.ApplicationParameter }
	],
	[ETableAdvancedFilterType.Datastream]: [
		{ id: uuidv4(), type: ETableAdvancedFilterType.Datastream }
	]
};
