'use client';
import { EIconName, ETooltipPosition, KvIcon, KvTooltip } from '@kelvininc/react-ui-components';
import classNames from 'classnames';

import { IExperienceGuideStep } from '../../types';

import styles from './styles.module.scss';

export type StepContentProps = {
	step: IExperienceGuideStep;
	stepState?: boolean;
	onStepClick?: (id: string) => void;
};

export const StepContent = ({ step, stepState, onStepClick }: StepContentProps) => {
	return (
		<div
			key={step.id}
			className={classNames(styles.Step, {
				[styles.Disabled]: step.disabled,
				[styles.Blocked]: step.blocked || onStepClick === undefined
			})}
			onClick={() => !step.disabled && onStepClick?.(step.id)}>
			<div
				className={classNames(styles.StateLabel, {
					[styles.Success]: stepState
				})}>
				<KvIcon name={EIconName.Success} customClass={'icon-24'} />
				{step.label}
			</div>
			{!stepState && step.icon && (
				<KvTooltip
					customClass={styles.CustomTooltip}
					text={step.tooltipText}
					position={ETooltipPosition.Right}>
					<KvIcon
						name={step.icon}
						customClass={classNames('icon-24', step.customClasses)}
					/>
				</KvTooltip>
			)}
		</div>
	);
};
