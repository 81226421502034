import { useMemo } from 'react';

import { IBaseTData, ICellRendererParams } from '../../../../../core/components/AgGridTable';

export const useTooltipCellValue = <TData extends IBaseTData>({
	tooltipCellField,
	tooltipCellFieldFormatter,
	column,
	data,
	valueFormatted,
	value,
	api,
	context
}: ICellRendererParams<TData>): string => {
	const cellValue = useMemo(() => valueFormatted ?? value, [valueFormatted, value]);

	return useMemo(
		() =>
			tooltipCellFieldFormatter?.(
				cellValue,
				data as TData,
				column?.getColId() as string,
				api,
				context
			) ??
			tooltipCellField ??
			cellValue?.toString(),
		[tooltipCellFieldFormatter, cellValue, data, column, api, context, tooltipCellField]
	);
};
