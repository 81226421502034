import { withSuspense } from '@kelvininc/shared-ui';

import { useAssetNonGuardrailedDatastreams } from '../../hooks';

import { SELECT_DATASTREAM_LABEL, SELECT_DATASTREAM_NO_DATA_AVAILABLE_CONFIG } from './config';

import {
	DatastreamSelector,
	DatastreamSelectorSkeleton
} from '@/src/components/client/DatastreamSelector';

type AssetDatastreamSelectorProps = {
	asset: string;
	value?: string;
	onValueChange: (datastream: string) => void;
};

export const AssetDatastreamSelectorSkeleton = () => {
	return <DatastreamSelectorSkeleton />;
};

export const AssetDatastreamSelectorDisabled = () => {
	return <DatastreamSelector required disabled />;
};

const Component = ({ asset, value, onValueChange }: AssetDatastreamSelectorProps) => {
	const datastreams = useAssetNonGuardrailedDatastreams(asset);

	return (
		<DatastreamSelector
			required
			label={SELECT_DATASTREAM_LABEL}
			value={value}
			datastreams={datastreams}
			onValueChange={onValueChange}
			noDataAvailableConfig={SELECT_DATASTREAM_NO_DATA_AVAILABLE_CONFIG}
		/>
	);
};

export const AssetDatastreamSelector = withSuspense(Component, <AssetDatastreamSelectorSkeleton />);
