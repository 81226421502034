import { buildUrl } from '@kelvininc/tsutils';

import { CONTEXTUALIZATION_ROUTE_BASE_PATH } from '../config';

const ROUTE_PATH = `${CONTEXTUALIZATION_ROUTE_BASE_PATH}/connections`;

export const buildConnectionRoute = (query?: string): string => buildUrl(ROUTE_PATH, query);

export const buildConnectionDetailsRoute = (connection: string, query?: string): string =>
	buildUrl([ROUTE_PATH, connection], query);
