import { AgGridTable, AgGridTableProps } from '../../../AgGridTable';
import { IBaseTData } from '../../../AgGridTable/types';
import { useApiRef } from '../../contexts/TableApiRefContext';
import { useRowHeight } from '../../contexts/TableDensityContext';

import {
	useAdvancedFilters,
	useFilters,
	usePaginationInfo,
	usePaginationTotalInfo,
	useSearchTerm,
	useSortInfo
} from '../../contexts/TableParamsContext';

import { useSelectedRows } from '../../contexts/TableSelectionContext';
import { useTableState } from '../../contexts/TableStateContext';

export const TableBody = <TData extends IBaseTData, TDetail extends IBaseTData = IBaseTData>({
	...otherProps
}: AgGridTableProps<TData, TDetail>) => {
	const [searchTerm] = useSearchTerm();
	const [filters] = useFilters();
	const [advancedFilters] = useAdvancedFilters();
	const rowHeight = useRowHeight();
	const [paginationInfo, setPaginationInfo] = usePaginationInfo();
	const [, setPaginationTotalInfo] = usePaginationTotalInfo();
	const [sortInfo, setSortInfo] = useSortInfo();
	const [selectedRows, setSelectedRows] = useSelectedRows<TData>();
	const [, setState] = useTableState();
	const ref = useApiRef();

	return (
		<AgGridTable
			filters={filters}
			advancedFilters={advancedFilters}
			page={paginationInfo?.page}
			pageSize={paginationInfo?.pageSize}
			sortByInitial={sortInfo?.sortBy}
			sortDirectionInitial={sortInfo?.sortDirection}
			searchTerm={searchTerm}
			rowHeight={rowHeight}
			selectedRowsInitial={selectedRows}
			onPaginationChange={setPaginationInfo}
			onPaginationTotalChange={setPaginationTotalInfo}
			onSortChange={setSortInfo}
			onSelectionChange={setSelectedRows}
			onStateChange={setState}
			ref={ref}
			{...otherProps}
		/>
	);
};
