import { IWizardStep } from '@kelvininc/react-ui-components';

import { IWizardStepConfig } from './components/WizardStep/types';

export const getStep = (
	steps: Record<string, IWizardStepConfig>,
	stepIndex: number
): IWizardStepConfig => Object.values(steps)[stepIndex];

export const getStepIndex = (
	steps: Record<string, IWizardStepConfig>,
	step: IWizardStepConfig
): number => Object.values(steps).findIndex(({ id }) => id === step.id);

export const buildWizardSteps = (steps: Record<string, IWizardStepConfig>): IWizardStep[] => {
	return Object.values(steps).map((step, index) => ({
		...step,
		index
	}));
};
