import { EAlertType } from '@kelvininc/react-ui-components';

import { FieldValues, UseFormReset } from 'react-hook-form';

import { AlertActions } from '../../../AlertActions';
import { closeAlertInModal, closeModal, openAlertInModal, setModalOverlay } from '../../../Modal';

export const openConfirmationCloseModalAlert = (resetForm: UseFormReset<FieldValues>): void => {
	setModalOverlay(true);

	openAlertInModal({
		label: 'Are you sure you want to leave?',
		type: EAlertType.Warning,
		description:
			'By leaving, this process will be discarded and your changes will not be saved.',
		actions: (
			<AlertActions
				onClickConfirm={() => {
					resetForm();
					closeModal({ force: true });
				}}
				onClickCancel={() => {
					setModalOverlay(false);
					closeAlertInModal();
				}}
			/>
		)
	});
};
