import { ITabNavigationItem, KvTabNavigation } from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import { Skeleton } from '../Skeleton';

import { DEFAULT_AMOUNT } from './config';
import styles from './styles.module.scss';

type NavLinksSkeletonProps = {
	customClasses?: ClassNamesProp;
	amount?: number;
};

export const NavLinksSkeleton = ({
	amount = DEFAULT_AMOUNT,
	customClasses
}: NavLinksSkeletonProps) => {
	return (
		<div className={classNames(styles.NavLinksContainer, customClasses)}>
			{new Array(amount).fill({}).map((_, index) => (
				<div className={styles.Tab} key={index}>
					<Skeleton />
				</div>
			))}
			<div className={styles.TabBorder}></div>
		</div>
	);
};

type NavLinksProps = {
	items: ITabNavigationItem[];
	onClickTab: (clickedTab: string) => void;
	selectedTab: string;
};

export const NavLinks = ({ items, onClickTab, selectedTab }: NavLinksProps) => {
	const onTabChange = ({ detail: clickedTab }: CustomEvent<string | number>) =>
		onClickTab(clickedTab as string);

	return <KvTabNavigation tabs={items} selectedTabKey={selectedTab} onTabChange={onTabChange} />;
};
