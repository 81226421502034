export const VALIDATION_CONTENT_INVALID_MIN_VALUE_RANGE =
	'The minimum value must be lesser than the maximum value';
export const VALIDATION_CONTENT_INVALID_MAX_VALUE_RANGE =
	'The maximum value must be greater than the minimum value';
export const VALIDATION_CONTENT_INVALID_MIN_TYPE =
	'This type does not match the data stream data type';
export const VALIDATION_CONTENT_INVALID_MAX_TYPE =
	'This type does not match the data stream data type';
export const VALIDATION_CONTENT_INVALID_NON_NUMERIC_FIELDS =
	'Guardrails for datastreams of data type not "number" can only have the following fields: control';
export const VALIDATION_CONTENT_MISSING_REQUIRED_FIELDS_FOR_DATASTREAM_TYPE =
	'This field is required for datastreams of data type number';
export const VALIDATION_CONTENT_RESOURCE_NOT_IN_METRICS_MAP =
	'This pair asset and data stream is not in the connection mapping file';
export const VALIDATION_SYSTEM_DUPLICATED_RESOURCE_NAME =
	'The pair asset and data stream is duplicated';
export const VALIDATION_SYSTEM_RESOURCE_ALREADY_EXITS =
	'A guardrail for this asset and data stream pair already exists in system';
export const VALIDATION_SYSTEM_UNEXISTING_ASSET_NAME = 'The asset does not exist in the system';
export const VALIDATION_SYSTEM_UNEXISTING_DATASTREAM_NAME =
	'The datastream does not exist in the system';

export const NUMERIC_DATASTREAM_REQUIRED_PROPS = [
	'min_value',
	'min_value_type',
	'min_inclusive',
	'max_value',
	'max_value_type',
	'max_inclusive'
] as const;

export const NON_NUMERIC_DATASTREAM_BLOCKED_PROPS = [
	'min_value',
	'min_value_type',
	'min_inclusive',
	'max_value',
	'max_value_type',
	'max_inclusive',
	'relative_increase_min_value',
	'relative_increase_min_value_type',
	'relative_increase_min_inclusive',
	'relative_increase_max_value',
	'relative_increase_max_value_type',
	'relative_increase_max_inclusive',
	'relative_decrease_min_value',
	'relative_decrease_min_value_type',
	'relative_decrease_min_inclusive',
	'relative_decrease_max_value',
	'relative_decrease_max_value_type',
	'relative_decrease_max_inclusive'
] as const;
