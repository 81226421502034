import { EIconName, KvIcon, KvSwitchButton } from '@kelvininc/react-ui-components';
import { closeToaster, openToaster } from '@kelvininc/shared-ui';
import { useCallback } from 'react';

import { EManageRowSettingKey, IRowsSettingsConfig } from '../../../../types';

import { FROZEN_ROWS_SETTING_DISABLED_TOASTER, MANAGE_ROWS_SETTINGS_CONFIG } from './config';
import styles from './styles.module.scss';

type ManageRowsProps = {
	settings: IRowsSettingsConfig;
	onChange?: (newSettings: IRowsSettingsConfig) => void;
};

export const ManageRows = ({ settings, onChange }: ManageRowsProps) => {
	const onSettingChange = useCallback(
		(settingKey: EManageRowSettingKey) => {
			closeToaster();

			if (settings[settingKey]) {
				openToaster(FROZEN_ROWS_SETTING_DISABLED_TOASTER);
			}

			onChange?.({
				...settings,
				[settingKey]: !settings[settingKey]
			});
		},
		[onChange, settings]
	);

	return (
		<div className={styles.ManageRows}>
			<div className={styles.Title}>Frozen Rows</div>
			<div className={styles.Settings}>
				{Object.keys(settings).map((settingKey) => {
					const settingValue = settings[settingKey as EManageRowSettingKey];
					const setting = MANAGE_ROWS_SETTINGS_CONFIG[settingKey as EManageRowSettingKey];

					return (
						<div key={setting.id} className={styles.Setting}>
							<KvSwitchButton
								checked={settingValue}
								className={styles.Checkbox}
								onSwitchChange={() => onSettingChange(setting.id)}>
								<div className={styles.Label} slot="right-slot">
									{setting.label}
								</div>
							</KvSwitchButton>
							{setting.hint && (
								<div className={styles.Hint}>
									<div className={styles.Icon}>
										<KvIcon name={EIconName.Protip} />
									</div>
									<div className={styles.Text}>{setting.hint}</div>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};
