import { EDataType } from '@kelvininc/node-client-sdk';
import {
	AlertActions,
	closeAlertInModal,
	openAlertInModal,
	setModalOverlay
} from '@kelvininc/shared-ui';

import { EGuardrailValueType } from '../../../GuardrailCraftWizard/types';
import { IMPORT_GUARDRAILS_SCHEMA } from '../../config';

import { ImportGuardrailFileSchema } from '../../types';

import {
	IConnectionFormValues,
	IPublisherMapping
} from '@/src/page-components/ConnectionsPage/ConnectionWizard/types';
import { EAlertType } from '@/src/ui-components-transformer';
import { buildSchemaCsvTemplateFile } from '@/src/utils/schema-csv';

export const openReplacingModalAlert = (onConfirm: () => void) => {
	setModalOverlay(true);

	openAlertInModal({
		label: 'Replacing File?',
		type: EAlertType.Warning,
		description: 'Do you want to replace the uploaded file?',
		actions: (
			<AlertActions
				onClickConfirm={() => {
					onConfirm();
					setModalOverlay(false);
					closeAlertInModal();
				}}
				onClickCancel={() => {
					setModalOverlay(false);
					closeAlertInModal();
				}}
			/>
		)
	});
};

export const buildTemplateFile = (connection?: IConnectionFormValues): File => {
	const data: string[] = [];

	if (connection && connection.payload.metrics_map.length > 0) {
		const guardrails = connection.payload.metrics_map.reduce<
			Partial<ImportGuardrailFileSchema>[]
		>((accumulator, map) => {
			const guardrail = buildGuardrailFromMetricsMap(map as IPublisherMapping);
			if (guardrail) {
				accumulator.push(guardrail);
			}

			return accumulator;
		}, []);

		for (const guardrail of guardrails) {
			data.push(Object.values(guardrail).join(','));
		}
	}

	return buildSchemaCsvTemplateFile(IMPORT_GUARDRAILS_SCHEMA, 'guardrails', data);
};

const buildGuardrailFromMetricsMap = (
	map: IPublisherMapping
): Partial<ImportGuardrailFileSchema> | undefined => {
	if (map.access === 'RO') {
		return;
	}

	if (map.data_type !== EDataType.Number) {
		return {
			asset: map.asset_name,
			datastream: map.name,
			control: true
		};
	}

	return {
		asset: map.asset_name,
		datastream: map.name,
		control: true,
		min_value: undefined,
		min_value_type: EGuardrailValueType.Number,
		min_inclusive: true,
		max_value: undefined,
		max_value_type: EGuardrailValueType.Number,
		max_inclusive: true
	};
};
