import { InclusiveIcon } from '../InclusiveIcon';

import styles from './styles.module.scss';

type GuardrailValueProps = {
	value: string | number;
	inclusive: boolean;
};

export const GuardrailValue = ({ value, inclusive }: GuardrailValueProps) => {
	return (
		<div className={styles.Value}>
			<div className={styles.Label}>{value}</div>
			<div className={styles.Icon}>
				<InclusiveIcon inclusive={inclusive} />
			</div>
		</div>
	);
};
