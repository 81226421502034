import { InfoAlert, useDebounce } from '@kelvininc/shared-ui';
import { DataStreamAggregationFunction } from '@kelvininc/types';
import { omit } from 'lodash-es';

import {
	ETableAdvancedFilterType,
	IDatastreamTableAdvancedFilter
} from '../../../../../../../../../core/components/AgGridTable/types';
import {
	DraftAdvancedFilter,
	IDrafAdvancedFilterMap
} from '../../../../../../../../../core/components/Table/contexts/TableAdvancedFiltersContext/types';
import { IDatastreamAdvancedFilterConfigMetadata } from '../../../../../../../../../core/components/Table/types';
import { isValidDatastreamFilter } from '../../../../../../../../../utils';
import { AdvancedFilterForm } from '../AdvancedFilterForm';
import { AdvancedFiltersForm } from '../AdvancedFiltersForm';

import { DatastreamDropdown } from './components/DatastreamDropdown';

import { DatastreamForm } from './components/DatastreamForm';
import {
	ALERT_INFO_MESSAGE,
	DATASTREAM_SCHEMA_FORM_PROPERTIES,
	UPDATE_FILTER_DEBOUNCE_TIME
} from './config';
import styles from './styles.module.scss';

import { DatastreamAdvancedFilterSchemaFormData } from './types';

import { setDatastreamAggregation } from './utils';

type DatastreamFiltersFormProps = {
	isFormEmpty: boolean;
	metadata: IDatastreamAdvancedFilterConfigMetadata;
	filters: DraftAdvancedFilter<IDatastreamTableAdvancedFilter>[];
	createFilter: (type: ETableAdvancedFilterType) => void;
	updateFilter: (
		type: ETableAdvancedFilterType,
		index: number,
		newFilter: IDrafAdvancedFilterMap[typeof type]
	) => void;
	deleteFilter: (type: ETableAdvancedFilterType, index: number) => void;
	duplicateFilter: (type: ETableAdvancedFilterType, index: number) => void;
};

export const DatastreamFiltersForm = ({
	isFormEmpty,
	metadata,
	filters,
	createFilter,
	updateFilter,
	deleteFilter,
	duplicateFilter
}: DatastreamFiltersFormProps) => {
	const { datastreams } = metadata;
	const debouncedUpdateFilter = useDebounce(updateFilter, UPDATE_FILTER_DEBOUNCE_TIME);

	const onChange = (
		index: number,
		newFilter: DraftAdvancedFilter<IDatastreamTableAdvancedFilter>,
		update = updateFilter
	): void => update(ETableAdvancedFilterType.Datastream, index, newFilter);

	const onChangeDatastream = (
		index: number,
		newDatastream: string,
		filter: DraftAdvancedFilter<IDatastreamTableAdvancedFilter>
	): void => {
		const aggregation = setDatastreamAggregation(datastreams.definitions, newDatastream);
		onChange(index, {
			...omit(filter, DATASTREAM_SCHEMA_FORM_PROPERTIES),
			datastream: newDatastream,
			aggregation,
			timeRange: undefined
		});
	};

	const onChangeAggregation = (
		index: number,
		newAggregation: DataStreamAggregationFunction,
		filter: DraftAdvancedFilter<IDatastreamTableAdvancedFilter>
	) => {
		onChange(index, {
			...omit(filter, DATASTREAM_SCHEMA_FORM_PROPERTIES),
			aggregation: newAggregation,
			timeRange: undefined
		});
	};

	const onChangeForm = (
		index: number,
		newForm: DatastreamAdvancedFilterSchemaFormData,
		filter: DraftAdvancedFilter<IDatastreamTableAdvancedFilter>
	): void => {
		onChange(
			index,
			{
				...omit(filter, DATASTREAM_SCHEMA_FORM_PROPERTIES),
				...newForm
			},
			debouncedUpdateFilter
		);
	};

	const hasDatastreamFilters = filters.some((filter) =>
		isValidDatastreamFilter(filter, metadata)
	);

	return (
		<>
			{hasDatastreamFilters && (
				<InfoAlert
					customClasses={styles.DatastreamAlert}
					alertMessage={ALERT_INFO_MESSAGE}
				/>
			)}
			<AdvancedFiltersForm
				onAdd={() => createFilter(ETableAdvancedFilterType.Datastream)}
				customClasses={styles.DatastreamFiltersForm}>
				{filters.map((filter, index) => (
					<div key={filter.id} className={styles.FilterForm}>
						<AdvancedFilterForm
							key={filter.id}
							index={index}
							onDuplicate={() =>
								duplicateFilter(ETableAdvancedFilterType.Datastream, index)
							}
							onDelete={() =>
								deleteFilter(ETableAdvancedFilterType.Datastream, index)
							}
							filters={filters}
							isFormEmpty={isFormEmpty}>
							<div className={styles.FilterForm}>
								<DatastreamDropdown
									datastreamName={filter.datastream}
									datastreams={datastreams.definitions}
									onChangeDatastream={(newDatastream) =>
										onChangeDatastream(index, newDatastream, filter)
									}
								/>
								<DatastreamForm
									datastream={datastreams.definitions[filter.datastream ?? '']}
									formData={filter}
									onChangeAggregation={(newAggregation) =>
										onChangeAggregation(index, newAggregation, filter)
									}
									onChangeForm={(newForm) => onChangeForm(index, newForm, filter)}
								/>
							</div>
						</AdvancedFilterForm>
					</div>
				))}
			</AdvancedFiltersForm>
		</>
	);
};
