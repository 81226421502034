import { ParameterSchedule } from '@kelvininc/node-client-sdk';
import { atomFamily } from 'recoil';

import { getAssetParametersSchedules } from './requesters';
import { getRecoilKey } from './utils';

export const assetsParametersSchedulesAtomFamily = atomFamily<ParameterSchedule[], string>({
	key: getRecoilKey('assetParametersSchedulesAtomFamily'),
	effects: (assetName: string) => [
		({ setSelf }) => {
			setSelf(getAssetParametersSchedules(assetName));
		}
	]
});
