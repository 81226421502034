import { EAlertType } from '@kelvininc/react-ui-components';
import { IModalAlert } from '@kelvininc/shared-ui';

export const FORM_ID_PREFIX = 'root';
export const FORM_KEY_SEPARATOR = '/';
export const APP_NAME_FORM_ID = `${FORM_ID_PREFIX}${FORM_KEY_SEPARATOR}appName`;
export const NO_APPLICATION_DATA_AVAILABLE: IModalAlert = {
	label: 'Unable to create a workload to deploy.',
	description:
		"Seems that there's no available applications in the platform to create this workload.",
	type: EAlertType.Warning
};
