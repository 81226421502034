import { NAVIGATION_COLLAPSED_WIDTH } from '@kelvininc/shared-ui';

import { IExpandableSetting, INavigationSidebarState } from './types';

export const APPLICATIONS_PINNED_USER_SETTING_KEY = 'core.ui.applications.pinned';
export const APPLICATIONS_PINNED_USER_SETTING_DEFAULT: string[] = [];

export const ASSETS_SEARCH_HISTORY_USER_SETTING_KEY = 'core.ui.assets.search.history';
export const ASSETS_SEARCH_HISTORY_USER_SETTING_DEFAULT = {};

export const ASSETS_SEARCH_MAXIMAZED_USER_SETTING_KEY = 'core.ui.assets.search.maximized';
export const ASSETS_SEARCH_MAXIMAZED_USER_SETTING_DEFAULT: IExpandableSetting = {
	isExpanded: true
};

export const FIRST_EXPERIENCE_SKIPPED_USER_SETTING_KEY = 'core.ui.first-experience.settings';
export const FIRST_EXPERIENCE_SKIPPED_USER_SETTING_DEFAULT: boolean = false;

export const NAVIGATION_SIDEBAR_STATE_USER_SETTING_KEY = 'core.ui.navigation-sidebar.width-state';
export const NAVIGATION_SIDEBAR_STATE_USER_SETTING_DEFAULT: INavigationSidebarState = {
	width: NAVIGATION_COLLAPSED_WIDTH,
	fixed: false
};

export const NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_KEY =
	'core.ui.navigation-sidebar.admin';
export const NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_DEFAULT: IExpandableSetting = {
	isExpanded: true
};

export const NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_KEY =
	'core.ui.navigation-sidebar.operations';
export const NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_DEFAULT: IExpandableSetting = {
	isExpanded: true
};

export const ENTITY_MODEL_EXPANDABLE_HEADER_USER_SETTING_DEFAULT: IExpandableSetting = {
	isExpanded: true
};
