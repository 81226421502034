import { EIconName, KvIcon } from '@kelvininc/react-ui-components';

import { IDraggableItem } from '@kelvininc/shared-ui';

import { IBaseTData } from '../../../../../../../AgGridTable';
import { IIndexedColumnDef } from '../../types';
import { ColumnItemContent } from '../ColumnItemContent';
import { VisibilityToggle } from '../VisibilityToggle';

export const buildDraggableColumnItems = <TData extends IBaseTData>(
	columnDefs: IIndexedColumnDef<TData>[],
	onClickEdit?: (itemIndex: number) => void,
	onClickVisibility?: (itemIndex: number) => void
): IDraggableItem[] =>
	columnDefs.map((columnDef) => {
		const leftIconName = columnDef.lockPinned ? EIconName.Lock : EIconName.DragDrop;
		const isColumnNotPinned = columnDef.pinned === undefined || columnDef.pinned === false;

		return {
			id: columnDef.id,
			content: (
				<ColumnItemContent
					onClick={() => onClickEdit?.(columnDef.index)}
					title={columnDef.title}
					disabled={
						columnDef.inalterable ||
						onClickEdit === undefined ||
						columnDef.metadata === undefined
					}
				/>
			),
			index: columnDef.index,
			inactive: columnDef.hide,
			disabled: columnDef.lockPinned,
			left: <KvIcon name={leftIconName} />,
			right: columnDef.lockPinned ? null : (
				<VisibilityToggle
					visible={!columnDef.hide}
					onClick={() => onClickVisibility?.(columnDef.index)}
					disabled={!isColumnNotPinned}
				/>
			)
		};
	});
