import { withSuspense } from '@kelvininc/shared-ui';

import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	CSVValidationSummaryViewer,
	CSVValidationSummaryViewerSkeleton
} from '../../../CSVValidationSummaryViewer';
import { FILE_MAPPING } from '../../config';
import {
	useDocsLink,
	useFileContent,
	useFileValidation,
	useImportGuardrailsWizardFormContext
} from '../../hooks';

import { useValidationItems } from '../../hooks/useValidationItems/useValidationItems';

import { VALIDATION_ITEMS } from './config';
import styles from './styles.module.scss';
import { buildFileErrorMessage, buildGuardrailsForm } from './utils';

import { assetsMapSelector } from '@/src/recoil/assets/selectors';
import { datastreamsMapSelector } from '@/src/recoil/data-streams/selectors';
import { guardrailsMapSelector } from '@/src/recoil/guardrails/selectors';

const ImportSummaryStepSkeleton = () => {
	return (
		<div className={styles.ImportSummaryStep}>
			<CSVValidationSummaryViewerSkeleton validationItems={VALIDATION_ITEMS} />
		</div>
	);
};

const Component = () => {
	const { watch, setError, setValue, clearErrors } = useImportGuardrailsWizardFormContext();
	const [file, connection] = watch(['file', 'connection']);
	const link = useDocsLink();
	const guardrailsMap = useRecoilValue(guardrailsMapSelector);
	const assetsMap = useRecoilValue(assetsMapSelector);
	const datastreamsMap = useRecoilValue(datastreamsMapSelector);

	const { loading: isLoading, data, errors: structureErrors } = useFileContent(file);
	const { validating: isValidating, errors: validationErrors } = useFileValidation(
		data,
		guardrailsMap,
		assetsMap,
		datastreamsMap,
		connection
	);
	const validationItems = useValidationItems(structureErrors, validationErrors);

	const hasStructureErrors = useMemo(
		() => Object.values(structureErrors).some((error) => error === true),
		[structureErrors]
	);
	const hasValidationErrors = useMemo(
		() => validationErrors.length > 0,
		[validationErrors.length]
	);
	const hasErrors = useMemo(
		() => hasStructureErrors || hasValidationErrors,
		[hasStructureErrors, hasValidationErrors]
	);

	const onValidate = useCallback(() => {
		if (hasErrors) {
			setError('file', {
				message: buildFileErrorMessage(hasStructureErrors, hasValidationErrors)
			});
		} else {
			clearErrors('file');
			setValue('guardrails', buildGuardrailsForm(data, datastreamsMap));
		}
	}, [
		clearErrors,
		data,
		datastreamsMap,
		hasErrors,
		hasStructureErrors,
		hasValidationErrors,
		setError,
		setValue
	]);

	if (isLoading || isValidating) {
		return <ImportSummaryStepSkeleton />;
	}

	return (
		<div className={styles.ImportSummaryStep}>
			<CSVValidationSummaryViewer
				csvData={data}
				csvFileHeaders={FILE_MAPPING}
				csvErrors={validationErrors}
				isValidCSV={!hasStructureErrors}
				validationItems={validationItems}
				onValidationComplete={onValidate}
				documentationLink={link}
			/>
		</div>
	);
};

export const ImportSummaryStep = withSuspense(Component, <ImportSummaryStepSkeleton />);
