import { App, AppItem } from '@kelvininc/node-client-sdk';
import { atom, atomFamily } from 'recoil';

import { getAppRegistryAppsListRequester, getAppRequester } from './requests';

export const appRegistryAtomFamily = atomFamily<App, string>({
	key: 'appRegistryAtomFamily',
	default: (id: string) => getAppRequester(id)
});

export const appRegistryAppsListAtom = atom<AppItem[]>({
	key: 'appRegistryAppsListAtom',
	effects: [
		({ setSelf }) => {
			setSelf(getAppRegistryAppsListRequester());
		}
	]
});
