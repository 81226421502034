import { useUpdate } from '@kelvininc/shared-ui';
import { PropsWithForwardedRef } from '@kelvininc/types';
import { ForwardedRef, forwardRef, useState } from 'react';

import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS } from '../../../config';
import { IBaseTData, IColumnDef } from '../AgGridTable/types';

import {
	TableApi,
	TableBody,
	TableHeader,
	TableManagementProviders,
	TableManagementSidepanel,
	TablePagination,
	TableProviders
} from './components';
import {
	DEFAULT_COLUMNS_HIDDEN,
	DEFAULT_COLUMNS_ORDER,
	DEFAULT_COLUMNS_PINNED,
	DEFAULT_NUMBER_OF_PAGE_SHORTCUT,
	DEFAULT_PAGE_SIZE_OPTIONS
} from './config';

import styles from './styles.module.scss';
import { EManageRowSettingKey, ITableApi, TableProps } from './types';

import { buildTableColumnDefs } from './utils';

const Component = <TData extends IBaseTData, TDetail extends IBaseTData = IBaseTData>({
	id = '',
	title,
	actions,
	subtitle,
	searchable = false,
	searchPlaceholder,
	searchDebounce = DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS,
	filterable = false,
	unclearableFilters,
	filtersSchema,
	advancedFiltersConfig,
	manageable = false,
	densityPickable = false,
	pagination = false,
	pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
	numberOfPageShortcuts = DEFAULT_NUMBER_OF_PAGE_SHORTCUT,
	checkboxSelectable = false,
	onColumnsChange,
	columnsOrder = DEFAULT_COLUMNS_ORDER,
	columnsPinned = DEFAULT_COLUMNS_PINNED,
	columnsHidden = DEFAULT_COLUMNS_HIDDEN,
	columnDefs,
	rowsPinnable = false,
	customColumnsConfig,
	panelsConfig,
	rowsSettings,
	onRowsSettingsChange,
	masterDetail,
	detailColumnDefs,
	forwardedRef,
	...tableProps
}: PropsWithForwardedRef<TableProps<TData, TDetail>, ITableApi<TData>>): JSX.Element => {
	const rowFrozenSetting = rowsSettings?.[EManageRowSettingKey.FROZEN_ROWS];
	const areRowsPinnable =
		rowFrozenSetting !== undefined ? rowFrozenSetting && rowsPinnable : rowsPinnable;
	const [tableColumnDefs, setTableColumnDefs] = useState<IColumnDef<TData>[]>(() =>
		buildTableColumnDefs(
			columnDefs,
			columnsOrder,
			columnsPinned,
			columnsHidden,
			checkboxSelectable,
			areRowsPinnable,
			masterDetail
		)
	);
	const [tableDetailColumnDefs] = useState<IColumnDef<TDetail>[] | undefined>(() =>
		detailColumnDefs
			? buildTableColumnDefs(
					detailColumnDefs,
					columnsOrder,
					columnsPinned,
					columnsHidden,
					false,
					false,
					false
				)
			: undefined
	);

	useUpdate(() => {
		onColumnsChange?.(tableColumnDefs);
	}, [tableColumnDefs]);

	useUpdate(() => {
		setTableColumnDefs(
			buildTableColumnDefs(
				columnDefs,
				columnsOrder,
				columnsPinned,
				columnsHidden,
				checkboxSelectable,
				areRowsPinnable,
				masterDetail
			)
		);
	}, [areRowsPinnable, columnDefs, checkboxSelectable, masterDetail]);

	return (
		<>
			<TableApi ref={forwardedRef} />
			{manageable && (
				<TableManagementProviders id={id}>
					<TableManagementSidepanel
						columnDefs={tableColumnDefs}
						setColumnDefs={setTableColumnDefs}
						customColumnsConfig={customColumnsConfig}
						panelsConfig={panelsConfig}
						rowsSettings={rowsSettings}
						onRowsSettingsChange={onRowsSettingsChange}
					/>
				</TableManagementProviders>
			)}
			<div data-test-id="e2e-table-widget" className={styles.Table}>
				<TableHeader
					id={id}
					title={title}
					subtitle={subtitle}
					searchable={searchable}
					searchPlaceholder={searchPlaceholder}
					searchDebounce={searchDebounce}
					filterable={filterable}
					filtersSchema={filtersSchema}
					unclearableFilters={unclearableFilters}
					advancedFiltersConfig={advancedFiltersConfig}
					manageable={manageable}
					densityPickable={densityPickable}
					checkboxSelectable={checkboxSelectable}
					actions={actions}
				/>
				<TableBody
					{...tableProps}
					masterDetail={masterDetail}
					columnDefs={tableColumnDefs}
					detailColumnDefs={tableDetailColumnDefs}
				/>
				{pagination && (
					<TablePagination
						pageSizeOptions={pageSizeOptions}
						numberOfPageShortcuts={numberOfPageShortcuts}
					/>
				)}
			</div>
		</>
	);
};

export const Table = forwardRef(function TableRefWrapper<TData extends IBaseTData>(
	props: TableProps<TData>,
	ref: ForwardedRef<ITableApi<TData>>
) {
	return (
		<TableProviders {...props}>
			<Component {...props} forwardedRef={ref} />
		</TableProviders>
	);
}) as <TData extends IBaseTData, TDetail extends IBaseTData = IBaseTData>(
	props: TableProps<TData, TDetail> & { ref?: ForwardedRef<ITableApi<TData>> }
) => ReturnType<typeof Component<TData, TDetail>>;
