import { EActionButtonType, EComponentSize, KvActionButton } from '@kelvininc/react-ui-components';
import { TabPanel, Tabs } from '@kelvininc/shared-ui';
import { useState } from 'react';

import {
	ETableAdvancedFilterType,
	ITableAdvancedFilters
} from '../../../../../../../../../core/components/AgGridTable/types';
import {
	useTableAdvancedFormFilters,
	useTableAdvancedFormFiltersActions
} from '../../../../../../../../../core/components/Table/contexts/TableAdvancedFiltersContext';
import { IAdvancedFiltersConfig } from '../../../../../../../../../core/components/Table/types';
import { areFiltersEmpty } from '../../utils';
import { ApplicationParameterFiltersForm } from '../ApplicationParameterFiltersForm';
import { AssetPropertyFiltersForm } from '../AssetPropertyFiltersForm';

import { DatastreamFiltersForm } from '../DatastreamFiltersForm';

import styles from './styles.module.scss';
import { getFiltersTabs } from './utils';

type ClearFiltersActionProps = {
	isFormEmpty: boolean;
	onClear: () => void;
};

type AdvancedFilterTabsProps = {
	config: IAdvancedFiltersConfig;
	submittedFilters?: ITableAdvancedFilters;
};

const ClearFiltersAction = ({ onClear, isFormEmpty }: ClearFiltersActionProps) => {
	return (
		<KvActionButton
			className={styles.ClearAction}
			onClickButton={onClear}
			size={EComponentSize.Small}
			type={EActionButtonType.Ghost}
			disabled={isFormEmpty}>
			Delete All Filters
		</KvActionButton>
	);
};

export const AdvancedFilterTabs = ({ config, submittedFilters }: AdvancedFilterTabsProps) => {
	const tabs = getFiltersTabs(config, submittedFilters);
	const [activeTab, setActiveTab] = useState<ETableAdvancedFilterType>(tabs[0].key);
	const [form] = useTableAdvancedFormFilters();
	const actions = useTableAdvancedFormFiltersActions();
	const { clearFilters } = useTableAdvancedFormFiltersActions();
	const isFormEmpty = areFiltersEmpty(submittedFilters, form);

	return (
		<Tabs
			tabs={tabs}
			activeTab={activeTab}
			onClickTab={setActiveTab}
			customClasses={styles.TabsBorder}
			actions={<ClearFiltersAction onClear={clearFilters} isFormEmpty={isFormEmpty} />}>
			{config[ETableAdvancedFilterType.AssetProperty] && (
				<TabPanel tab={ETableAdvancedFilterType.AssetProperty} activeTab={activeTab}>
					<AssetPropertyFiltersForm
						isFormEmpty={isFormEmpty}
						filters={form[ETableAdvancedFilterType.AssetProperty]}
						metadata={config[ETableAdvancedFilterType.AssetProperty].metadata}
						{...actions}
					/>
				</TabPanel>
			)}
			{config[ETableAdvancedFilterType.ApplicationParameter] && (
				<TabPanel tab={ETableAdvancedFilterType.ApplicationParameter} activeTab={activeTab}>
					<ApplicationParameterFiltersForm
						isFormEmpty={isFormEmpty}
						filters={form[ETableAdvancedFilterType.ApplicationParameter]}
						metadata={config[ETableAdvancedFilterType.ApplicationParameter].metadata}
						{...actions}
					/>
				</TabPanel>
			)}
			{config[ETableAdvancedFilterType.Datastream] && (
				<TabPanel tab={ETableAdvancedFilterType.Datastream} activeTab={activeTab}>
					<DatastreamFiltersForm
						isFormEmpty={isFormEmpty}
						filters={form[ETableAdvancedFilterType.Datastream]}
						metadata={config[ETableAdvancedFilterType.Datastream].metadata}
						{...actions}
					/>
				</TabPanel>
			)}
		</Tabs>
	);
};
