'use client';
import { IColumnDef, Table } from '@kelvininc/table';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';

import { DEFAULT_COLUMNS_DEFS, DENSITY_CONFIG } from './config';
import styles from './styles.module.scss';

import { IFileMappingError } from './types';
import { buildTableColumnDefs, getRowId } from './utils';

import { ICSVMap } from '@/src/types';

type FileErrorsListProps = {
	headers: ICSVMap[];
	errors: Record<string, IFileMappingError>;
};

export const FileErrorsList = ({ headers, errors }: FileErrorsListProps) => {
	const columnDefs: IColumnDef<IFileMappingError>[] = useMemo(
		() => buildTableColumnDefs(headers),
		[headers]
	);

	const data = useMemo(() => (!isEmpty(errors) ? Object.values(errors) : []), [errors]);
	return (
		<div className={styles.ErrorsListContainer}>
			<Table
				densityOptions={DENSITY_CONFIG}
				defaultColumnDef={DEFAULT_COLUMNS_DEFS}
				columnDefs={columnDefs}
				data={data}
				getRowId={getRowId}
			/>
		</div>
	);
};
