import { useUserSetting } from '@kelvininc/shared-ui';

import { DispatchPersistStateAction, PersistState } from '@kelvininc/types';

import { navigationSidebarStateUserSettingAtom } from './atom';

import {
	NAVIGATION_SIDEBAR_STATE_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_STATE_USER_SETTING_KEY,
	USER_SETTING_DEBOUNCE_CONFIG
} from './config';

import { INavigationSidebarState } from '@/src/recoil/user-settings/types';

export const useWidthSidebarSetting = (): PersistState<INavigationSidebarState> => {
	const { value, setValue } = useUserSetting<INavigationSidebarState>(
		NAVIGATION_SIDEBAR_STATE_USER_SETTING_KEY,
		NAVIGATION_SIDEBAR_STATE_USER_SETTING_DEFAULT,
		navigationSidebarStateUserSettingAtom,
		USER_SETTING_DEBOUNCE_CONFIG
	);

	return [value, setValue as DispatchPersistStateAction<INavigationSidebarState>];
};
