import { EAlertType } from '@kelvininc/react-ui-components';
import { Subject } from 'rxjs';

import { BannerProps } from '../Banner';

import { BannerEventPayload } from './types';

export const bannerController$ = new Subject<BannerEventPayload>();
export const DEFAULT_BANNER_ROOT_ID = 'banner-root';
export const DEFAULT_INITIAL_BANNER: BannerProps = {
	alertConfig: {
		label: '',
		type: EAlertType.Info
	}
};
