import { Asset, AssetType } from '@kelvininc/node-client-sdk';
import { keyBy } from 'lodash-es';
import { selector, selectorFamily } from 'recoil';

import { assetTypesListAtom, assetsListAtom } from './atoms';
import { getAssetRequester } from './requests';

export const assetsSelectorFamily = selectorFamily<Asset, string>({
	key: 'assetsSelectorFamily',
	get: (id: string) => () => getAssetRequester(id)
});

export const assetsMapSelector = selector<Record<string, Asset>>({
	key: 'assetsMapSelector',
	get: ({ get }) => keyBy(get(assetsListAtom), 'name')
});

export const assetTypesMapSelector = selector<Record<string, AssetType>>({
	key: 'assetTypesMapSelector',
	get: ({ get }) => keyBy(get(assetTypesListAtom), 'name')
});
