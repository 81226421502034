import { type IToaster } from '@kelvininc/react-ui-components';

import { EGuardrailOperationType } from './types';

export const OPERATION_SUCCESS_TOASTER_CONFIGS = {
	[EGuardrailOperationType.Create]: {
		header: 'Guardrail created successfully.'
	},
	[EGuardrailOperationType.Delete]: {
		header: 'Guardrail deleted successfully.'
	},
	[EGuardrailOperationType.Edit]: {
		header: 'Guardrail updated successfully.'
	},
	[EGuardrailOperationType.Import]: {
		header: 'Guardrails created successfully.'
	},
	[EGuardrailOperationType.BulkDelete]: {
		header: 'Guardrails deleted successfully.'
	}
} as const satisfies Record<EGuardrailOperationType, Partial<IToaster>>;

export const OPERATION_ERROR_TOASTER_CONFIGS = {
	[EGuardrailOperationType.Create]: {
		header: 'Something went wrong',
		description: 'The guardrail was not properly created. Please try again later.'
	},
	[EGuardrailOperationType.Delete]: {
		header: 'Something went wrong',
		description: 'The guardrail was not deleted. Please try again later.'
	},
	[EGuardrailOperationType.BulkDelete]: {
		header: 'Something went wrong',
		description: 'The guardrails were not properly created. Please try again later.'
	},
	[EGuardrailOperationType.Edit]: {
		header: 'Something went wrong',
		description: 'The guardrail was not properly updated. Please try again later.'
	},
	[EGuardrailOperationType.Import]: {
		header: 'Something went wrong',
		description: 'The guardrails were not properly created. Please try again later.'
	}
} as const satisfies Record<EGuardrailOperationType, Partial<IToaster>>;
