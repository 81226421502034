import { AppItem } from '@kelvininc/node-client-sdk';
import { UseDropdownOptions } from '@kelvininc/types';

export const APPLICATION_PARAMETER_COLUMN_APPLICATION_LABEL = 'SmartApp';
export const APPLICATION_PARAMETER_COLUMN_APPLICATION_PLACEHOLDER = 'Select an option';
export const APPLICATION_PARAMETER_COLUMN_PARAMETER_LABEL = 'Parameter Name';
export const APPLICATION_PARAMETER_COLUMN_PARAMETER_PLACEHOLDER = 'Select an option';
export const USE_DROPDOWN_APPLICATION_OPTIONS: UseDropdownOptions<AppItem> = {
	key: 'name',
	label: 'title',
	value: 'name'
};
