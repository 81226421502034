import { SchemaFormProps } from '@kelvininc/react-ui-components';
import { getRelativeTimeRangeName } from '@kelvininc/tsutils';

import { ERelativeTimeRangeKey } from '@kelvininc/types';

import {
	EQUALITY_OPTIONS,
	EXISTENCE_OPERATORS_OPTIONS,
	RELATIONAL_OPERATORS_OPTIONS,
	STRING_OPERATORS_OPTIONS
} from '../../../../../../../../../../../utils';
import {
	PRIMITIVE_TYPE_NUMBER_FILTER_SCHEMA,
	PRIMITIVE_TYPE_STRING_FILTER_SCHEMA
} from '../../../../config';

import { DatastreamAdvancedFilterSchemaFormData } from '../../types';

export const DATASTREAM_TIME_RANGES_FILTER = [
	ERelativeTimeRangeKey.Last_7_D,
	ERelativeTimeRangeKey.Last_14_D,
	ERelativeTimeRangeKey.Last_1_M
];

export const SCHEMA_TIME_RANGES_OPTIONS = DATASTREAM_TIME_RANGES_FILTER.map((constValue) => ({
	const: constValue,
	title: getRelativeTimeRangeName(constValue)
}));

export const TIME_RANGE_PROPERTY_SCHEMA: SchemaFormProps<DatastreamAdvancedFilterSchemaFormData>['schema'] =
	{
		type: 'object',
		properties: {
			timeRange: {
				type: 'string',
				oneOf: [...SCHEMA_TIME_RANGES_OPTIONS]
			}
		},
		required: ['timeRange'],
		dependencies: {
			timeRange: {
				properties: {
					operator: {
						$ref: '#/definitions/number_operators'
					}
				},
				required: ['operator']
			},
			operator: {
				oneOf: [
					{
						type: 'object',
						properties: {
							operator: {
								oneOf: RELATIONAL_OPERATORS_OPTIONS
							},
							value: {
								$ref: '#/definitions/number_value'
							}
						},
						required: ['value']
					},
					{
						type: 'object',
						properties: {
							operator: {
								oneOf: EXISTENCE_OPERATORS_OPTIONS
							}
						}
					}
				]
			}
		},
		definitions: PRIMITIVE_TYPE_NUMBER_FILTER_SCHEMA.definitions
	};

export const STRING_DATASTREAM_PROPERTY_SCHEMA: SchemaFormProps<DatastreamAdvancedFilterSchemaFormData>['schema'] =
	{
		...PRIMITIVE_TYPE_STRING_FILTER_SCHEMA,
		dependencies: {
			operator: {
				oneOf: [
					{
						type: 'object',
						properties: {
							operator: {
								oneOf: EQUALITY_OPTIONS
							},
							valueList: {
								$ref: '#/definitions/string_options_value'
							}
						},
						required: ['valueList']
					},
					{
						type: 'object',
						properties: {
							operator: {
								oneOf: STRING_OPERATORS_OPTIONS
							},
							value: {
								$ref: '#/definitions/string_value'
							}
						},
						required: ['value']
					},
					{
						type: 'object',
						properties: {
							operator: {
								oneOf: EXISTENCE_OPERATORS_OPTIONS
							}
						}
					}
				]
			}
		}
	};
