import { DataStream } from '@kelvininc/node-client-sdk';
import { EIllustrationName, IIllustrationMessage } from '@kelvininc/react-ui-components';
import { UseDropdownOptions } from '@kelvininc/types';

export const DEFAULT_SELECT_DATASTREAM_LABEL = 'Data Stream';
export const DEFAULT_SELECT_DATASTREAM_PLACEHOLDER = 'Select a Data Stream';
export const DEFAULT_SELECT_DATASTREAM_SEARCH_PLACEHOLDER = 'Search for a Data Stream';
export const DEFAULT_SELECT_DATASTREAM_NO_DATA_AVAILABLE_CONFIG: IIllustrationMessage = {
	illustration: EIllustrationName.NoDataAvailable,
	header: 'No Data Available',
	description: 'There are no Data Streams to display at the moment.'
};

export const USE_DROPDOWN_OPTIONS: UseDropdownOptions<DataStream> = {
	key: 'name',
	label: 'title',
	value: 'name'
};
