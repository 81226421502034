import { KvRadio } from '@kelvininc/react-ui-components';

import classNames from 'classnames';

import { PropsWithChildren } from 'react';

import { GuardrailValueInput } from '../GuardrailValueInput';

import styles from './styles.module.scss';

type GuardrailRadioValueFieldProps = {
	label: string;
	checked: boolean;
	inclusive: boolean;
	disabled: boolean;
	onChecked: () => void;
	onChangeInclusive: (inclusive: boolean) => void;
};

export const GuardrailRadioValueField = ({
	children,
	label,
	checked,
	disabled,
	inclusive,
	onChecked,
	onChangeInclusive
}: PropsWithChildren<GuardrailRadioValueFieldProps>) => {
	return (
		<div
			className={classNames(styles.GuardrailRadioValueField, {
				[styles.GuardrailRadioValueFieldUnchecked]: !checked,
				[styles.GuardrailRadioValueFieldDisabled]: disabled
			})}
			onClick={() => !checked && onChecked()}>
			<div className={styles.Radio}>
				<KvRadio checked={checked && !disabled} />
			</div>
			<div className={styles.Field}>
				<div className={styles.Label}>{label}</div>
				<GuardrailValueInput
					disabled={!checked || disabled}
					inclusive={inclusive}
					onChangeInclusive={onChangeInclusive}>
					{children}
				</GuardrailValueInput>
			</div>
		</div>
	);
};
