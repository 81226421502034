import {
	EComponentSize,
	EIconName,
	KvIcon,
	KvSingleSelectDropdown
} from '@kelvininc/react-ui-components';
import { isNil } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { IBaseTData, IPaginationInfo } from '../../../AgGridTable';
import { usePaginationInfo, usePaginationTotalInfo } from '../../contexts/TableParamsContext';
import { useIsStateHasResults, useIsStateLoading } from '../../contexts/TableStateContext';
import { TableProps } from '../../types';

import { PaginationButton } from './components';

import styles from './styles.module.scss';
import {
	buildItemsRange,
	buildItemsRangeText,
	buildPageSizeOptions,
	buildShortcutPages,
	getSingleSelectDropdownDisabled
} from './utils';

type TablePaginationProps<TData extends IBaseTData> = Pick<
	TableProps<TData>,
	'pageSizeOptions' | 'numberOfPageShortcuts'
>;

export const TablePagination = <TData extends IBaseTData>({
	pageSizeOptions = [],
	numberOfPageShortcuts
}: TablePaginationProps<TData>) => {
	const [paginationInfo = {}, setPaginationInfo] = usePaginationInfo();
	const [paginationTotalInfo = {}] = usePaginationTotalInfo();
	const { page, pageSize } = paginationInfo;
	const { totalItems, totalPages } = paginationTotalInfo;
	const hasResults = useIsStateHasResults();
	const isLoading = useIsStateLoading();

	const hasPaginationInfo = !(
		isNil(page) ||
		isNil(pageSize) ||
		isNil(totalItems) ||
		isNil(totalPages)
	);
	const hasPages = useMemo(
		() => hasPaginationInfo && totalPages > 0,
		[hasPaginationInfo, totalPages]
	);
	const options = useMemo(() => buildPageSizeOptions(pageSizeOptions), [pageSizeOptions]);
	const itemsRange = useMemo<[number, number] | undefined>(() => {
		if (!hasPaginationInfo || !hasPages) {
			return;
		}

		return buildItemsRange(page, pageSize, totalItems);
	}, [hasPaginationInfo, hasPages, page, pageSize, totalItems]);
	const hasPreviousPage = useMemo(() => hasPaginationInfo && page > 1, [hasPaginationInfo, page]);
	const hasNextPage = useMemo(
		() => hasPaginationInfo && page < totalPages,
		[hasPaginationInfo, page, totalPages]
	);
	const isOnFirstPage = useMemo(() => hasPaginationInfo && page === 1, [hasPaginationInfo, page]);
	const isOnLastPage = useMemo(
		() => hasPaginationInfo && page === totalPages,
		[hasPaginationInfo, page, totalPages]
	);
	const shortcutPages = useMemo(
		() =>
			hasPaginationInfo && numberOfPageShortcuts
				? buildShortcutPages(numberOfPageShortcuts, page, totalPages)
				: [],
		[hasPaginationInfo, numberOfPageShortcuts, page, totalPages]
	);

	const onChange = useCallback(
		(newPaginationInfo: IPaginationInfo) => setPaginationInfo(newPaginationInfo),
		[setPaginationInfo]
	);
	const setPage = useCallback(
		(newPage?: number) => onChange({ ...paginationInfo, page: newPage }),
		[onChange, paginationInfo]
	);
	const setPageSize = useCallback(
		(newPageSize?: number) => onChange({ ...paginationInfo, page: 1, pageSize: newPageSize }),
		[onChange, paginationInfo]
	);
	const onPageSizeChange = useCallback(
		({ detail: newPageSize }: CustomEvent<string>) => setPageSize(Number.parseInt(newPageSize)),
		[setPageSize]
	);
	const onClickPage = useCallback((newPage?: number) => setPage(newPage), [setPage]);
	const onClickNext = useCallback(() => setPage(page ? page + 1 : 0), [page, setPage]);
	const onClickPrevious = useCallback(() => setPage(page ? page - 1 : 0), [page, setPage]);

	const isSingleSelectDropdownDisabled = useMemo(
		() => getSingleSelectDropdownDisabled(totalItems, options, !hasResults),
		[totalItems, options, hasResults]
	);

	return (
		<div data-test-id="e2e-table-paginator" className={styles.TablePaginator}>
			<div className={styles.PageSize}>
				Show
				<div className={styles.Dropdown}>
					<KvSingleSelectDropdown
						disabled={isSingleSelectDropdownDisabled}
						displayValue={`${pageSize} items`}
						selectedOption={pageSize?.toString()}
						options={options}
						onOptionSelected={onPageSizeChange}
						inputSize={EComponentSize.Small}
					/>
				</div>
				per page
			</div>
			<div className={styles.Navigator}>
				{(hasResults || isLoading) && (
					<div className={styles.NavigatorInfo}>
						{buildItemsRangeText(itemsRange, totalItems)}
					</div>
				)}
				<div className={styles.NavigatorActions}>
					<PaginationButton
						onClick={() => onClickPage(1)}
						disabled={!hasPages || isOnFirstPage || !hasResults}
						active>
						<KvIcon name={EIconName.DoubleArrowLeft} />
					</PaginationButton>
					<PaginationButton
						onClick={() => onClickPrevious()}
						disabled={!hasPages || !hasPreviousPage || !hasResults}
						active>
						<KvIcon name={EIconName.NavClose} />
					</PaginationButton>
					{(hasResults || isLoading) &&
						shortcutPages.map((shortcutPage) => (
							<PaginationButton
								key={shortcutPage}
								onClick={() => onClickPage(shortcutPage)}
								active={shortcutPage === page}>
								{shortcutPage}
							</PaginationButton>
						))}
					<PaginationButton
						onClick={() => onClickNext()}
						disabled={!hasPages || !hasNextPage || !hasResults}
						active>
						<KvIcon name={EIconName.NavOpen} />
					</PaginationButton>
					<PaginationButton
						onClick={() => onClickPage(totalPages)}
						disabled={!hasPages || isOnLastPage || !hasResults}
						active>
						<KvIcon name={EIconName.DoubleArrowRight} />
					</PaginationButton>
				</div>
			</div>
		</div>
	);
};
