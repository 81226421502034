import { AssetInsightsItem } from '@kelvininc/node-client-sdk';

import { ITableSettings } from '@kelvininc/table';
import { RecoilState } from 'recoil';

import { IUseTableSettings, useTableSettings } from '../useTableSettings';

import {
	deserializeAssetInsightsTableSettings,
	serializeAssetInsightsTableSettings
} from './utils';

import { assetInsightsTableSettingsSettingAtomFamily } from '@/src/recoil/settings/atoms';

export const useAssetInsightsTableSettings = (
	key: string,
	defaultValue: ITableSettings<AssetInsightsItem> = {}
): IUseTableSettings<AssetInsightsItem> => {
	const atom = assetInsightsTableSettingsSettingAtomFamily(key) as unknown as RecoilState<
		ITableSettings<AssetInsightsItem>
	>;

	return useTableSettings(key, defaultValue, atom, {
		serializer: serializeAssetInsightsTableSettings,
		deserializer: (serializedSettings: string) =>
			deserializeAssetInsightsTableSettings(serializedSettings)
	});
};
