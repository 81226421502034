import { IKvHttpResponse } from '@kelvininc/node-client-sdk';
import { EAlertType, EToasterType, IToaster } from '@kelvininc/react-ui-components';
import { IModalAlert } from '@kelvininc/shared-ui';
import { isMultiStatusResponse } from '@kelvininc/tsutils';

export const ERROR_TOASTER: IToaster = {
	header: 'Something went wrong.',
	type: EToasterType.Error
};

export const WARNING_TOASTER: IToaster = {
	header: 'Attention Needed',
	type: EToasterType.Warning
};

export const ERROR_ALERT_TOASTER: IModalAlert = {
	label: 'Something went wrong.',
	type: EAlertType.Error
};

export const buildToasterByResponse = (error: IKvHttpResponse): IToaster =>
	isMultiStatusResponse(error) ? WARNING_TOASTER : ERROR_TOASTER;
