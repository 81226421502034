'use client';
import { EValidationState, ITextField, KvTextField } from '@kelvininc/react-ui-components';

import { merge } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';

import { ERROR_MESSAGES } from './config';
import { ValidatedTextFieldMessages } from './types';
import { validateNameId } from './utils';

interface ValidatedTextFieldProps extends ITextField {
	existingNames?: string[];
	regex?: string;
	ignoreValidation?: boolean;
	errorMessages?: ValidatedTextFieldMessages;
	onTextChange?: (nameId: string) => void;
	onStateChange?: (isValid: boolean) => void;
}

export const ValidatedTextField = ({
	existingNames = [],
	regex,
	ignoreValidation = false,
	errorMessages,
	onTextChange,
	onStateChange,
	value,
	helpText,
	...inputProps
}: ValidatedTextFieldProps) => {
	const messages = useMemo(() => merge(ERROR_MESSAGES, errorMessages), [errorMessages]);
	const inputState = useMemo(
		() => validateNameId(value as string, messages, regex, existingNames, ignoreValidation),
		[existingNames, ignoreValidation, messages, regex, value]
	);

	const textChangeHandler = useCallback(
		({ detail: newValue }: CustomEvent<string>) => {
			onTextChange?.(newValue);
		},
		[onTextChange]
	);

	useEffect(() => {
		onStateChange?.(inputState.state !== EValidationState.Invalid);
	}, [inputState.state, onStateChange]);

	return (
		<KvTextField
			{...inputProps}
			value={value}
			state={inputState?.state}
			helpText={inputState?.helpText || helpText}
			onTextChange={textChangeHandler}
		/>
	);
};
