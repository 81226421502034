import { buildUrl } from '@kelvininc/tsutils';
import { URLSearchParamsInit } from '@kelvininc/types';

import { ADMINISTRATION_ROUTE_BASE_PATH } from '../config';

const ROUTE_PATH = `${ADMINISTRATION_ROUTE_BASE_PATH}/roles-permissions`;

export const buildRolesAndPermissionsRoute = (query?: URLSearchParamsInit): string =>
	buildUrl(ROUTE_PATH, query);

export const buildRolesAndPermissionsDetailRoute = (
	roleId: string,
	query?: URLSearchParamsInit
): string => buildUrl([ROUTE_PATH, roleId], query);
