import { EIconName, KvIcon } from '@kelvininc/react-ui-components';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';

import styles from './styles.module.scss';

type ExpandableButtonProps = {
	isExpanded: boolean;
	disabled?: boolean;
	customClasses?: ClassNamesProp;
	onButtonClick: () => void;
};

export const ExpandableButton = ({
	isExpanded,
	disabled = false,
	customClasses,
	onButtonClick
}: ExpandableButtonProps) => {
	return (
		<div
			className={classNames(styles.ExpandableButton, customClasses, {
				[styles.ExpandableButtonCollapse]: !isExpanded,
				[styles.ExpandableButtonDisabled]: disabled
			})}
			onClick={onButtonClick}>
			<KvIcon name={EIconName.Collapse} />
		</div>
	);
};
