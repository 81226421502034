import { AssetInsightsItem } from '@kelvininc/node-client-sdk';
import { IBaseLData, IListSettings } from '@kelvininc/shared-ui';

import { IBaseTData, ITableSettings, ITablesSettings } from '@kelvininc/table';

export const ASSETS_LIST_ASSET_TYPE_SETTING_KEY = 'core.ui.assets.asset_type';
export const ASSETS_LIST_ASSET_TYPE_SETTING_DEFAULT: string | undefined = undefined;

export const LIST_SETTINGS_SETTING_DEFAULT: IListSettings<IBaseLData> | undefined = undefined;

export const TABLE_SETTINGS_SETTING_DEFAULT: ITableSettings<IBaseTData> | undefined = undefined;
export const TABLES_SETTINGS_SETTING_DEFAULT: ITablesSettings<IBaseTData> = {};

export const ASSET_INSIGHTS_TABLE_SETTINGS_SETTING_DEFAULT:
	| ITableSettings<AssetInsightsItem>
	| undefined = undefined;
export const ASSET_INSIGHTS_TABLES_SETTINGS_SETTING_DEFAULT: ITablesSettings<AssetInsightsItem> =
	{};

export const OPERATIONS_OVERVIEW_ENABLED_SETTING_KEY = 'core.ui.operations.overview';
export const OPERATIONS_OVERVIEW_ENABLED_SETTING_DEFAULT = { enabled: false };
