import { IColumnDef, SchemaFormErrorCellRenderer } from '@kelvininc/table';
import { capitalizeFirstLetter } from '@kelvininc/tsutils';

import { SCHEMA_ROW_KEY } from '../config';

import { IFileMappingError } from './types';

import { ICSVMap } from '@/src/types';

export const buildTableColumnDefs = (headers: ICSVMap[]): IColumnDef<IFileMappingError>[] => {
	return headers.reduce<IColumnDef<IFileMappingError>[]>(
		(acc, item) => {
			acc.push({
				id: item.path,
				title: item.title,
				cellComponent: SchemaFormErrorCellRenderer,
				cellComponentParams: {
					cellContentAccessor: `content.${item.path}`,
					cellErrorAccessor: item.path
				}
			} as IColumnDef<IFileMappingError>);

			return acc;
		},
		[
			{
				id: SCHEMA_ROW_KEY,
				title: capitalizeFirstLetter(SCHEMA_ROW_KEY),
				cellComponent: SchemaFormErrorCellRenderer,
				cellComponentParams: {
					cellContentAccessor: SCHEMA_ROW_KEY,
					cellErrorAccessor: SCHEMA_ROW_KEY
				}
			} as IColumnDef<IFileMappingError>
		]
	);
};

export const getRowId = ({ item: { row } }: IFileMappingError) => row;
