import { buildUrl } from '@kelvininc/tsutils';
import { URLSearchParamsInit } from '@kelvininc/types';

import { CONTEXTUALIZATION_ROUTE_BASE_PATH } from '../config';

const ROUTE_PATH = `${CONTEXTUALIZATION_ROUTE_BASE_PATH}/data-streams`;

export const buildDataStreamsRoute = (query?: URLSearchParamsInit): string =>
	buildUrl(ROUTE_PATH, query);

export const buildDataStreamViewRoute = (
	dataStreamId: string,
	query?: URLSearchParamsInit
): string => buildUrl([ROUTE_PATH, dataStreamId], query);
