import {
	isValidApplicationParameterFilter,
	isValidAssetPropertyFilter,
	isValidDatastreamFilter
} from '../../../../../utils';
import {
	ETableAdvancedFilterType,
	IApplicationParameterTableAdvancedFilter,
	IAssetPropertyTableAdvancedFilter,
	IDatastreamTableAdvancedFilter,
	ITableAdvancedFilters
} from '../../../AgGridTable';
import { IAdvancedFiltersConfig } from '../../types';

import { TABLE_ADVANCED_FILTERS_FORM_DEFAULT_VALUE } from './config';

import { ITableAdvancedFormFilters } from './types';

export const filterValidFormFilters = (
	values: ITableAdvancedFormFilters,
	config: IAdvancedFiltersConfig
): ITableAdvancedFilters => {
	const {
		[ETableAdvancedFilterType.AssetProperty]: propertyFilters,
		[ETableAdvancedFilterType.ApplicationParameter]: applicationFilters,
		[ETableAdvancedFilterType.Datastream]: datastreamFilters
	} = values;

	return {
		[ETableAdvancedFilterType.AssetProperty]: propertyFilters.filter(
			(filter): filter is IAssetPropertyTableAdvancedFilter =>
				isValidAssetPropertyFilter(
					filter,
					config[ETableAdvancedFilterType.AssetProperty]!.metadata
				)
		),
		[ETableAdvancedFilterType.ApplicationParameter]: applicationFilters.filter(
			(filter): filter is IApplicationParameterTableAdvancedFilter =>
				isValidApplicationParameterFilter(
					filter,
					config[ETableAdvancedFilterType.ApplicationParameter]!.metadata
				)
		),
		[ETableAdvancedFilterType.Datastream]: datastreamFilters.filter(
			(filter): filter is IDatastreamTableAdvancedFilter =>
				isValidDatastreamFilter(
					filter,
					config[ETableAdvancedFilterType.Datastream]!.metadata
				)
		)
	};
};

export const buildFormFilters = (
	submittedFilters?: ITableAdvancedFilters
): ITableAdvancedFormFilters => {
	if (!submittedFilters) {
		return TABLE_ADVANCED_FILTERS_FORM_DEFAULT_VALUE;
	}

	const {
		[ETableAdvancedFilterType.AssetProperty]: propertyFilters,
		[ETableAdvancedFilterType.ApplicationParameter]: parametersFilters,
		[ETableAdvancedFilterType.Datastream]: datastreamFilters
	} = submittedFilters;
	const {
		[ETableAdvancedFilterType.AssetProperty]: propertyFiltersDefault,
		[ETableAdvancedFilterType.ApplicationParameter]: parametersFiltersDefault,
		[ETableAdvancedFilterType.Datastream]: datastreamFiltersDefault
	} = TABLE_ADVANCED_FILTERS_FORM_DEFAULT_VALUE;

	return {
		[ETableAdvancedFilterType.AssetProperty]: propertyFilters?.length
			? propertyFilters
			: propertyFiltersDefault,
		[ETableAdvancedFilterType.ApplicationParameter]: parametersFilters?.length
			? parametersFilters
			: parametersFiltersDefault,
		[ETableAdvancedFilterType.Datastream]: datastreamFilters?.length
			? datastreamFilters
			: datastreamFiltersDefault
	};
};
