import { EIconName, KvIcon } from '@kelvininc/react-ui-components';
import { TimeFromNow } from '@kelvininc/shared-ui';
import { getDateFormat } from '@kelvininc/tsutils';
import { useMemo } from 'react';

import { IBaseTData, ICellRendererParams } from '../../../core/components/AgGridTable/types';
import { withBaseCellRenderer } from '../BaseCellRenderer';

import styles from './styles.module.scss';

const Component = <TData extends IBaseTData>({
	valueFormatted,
	value
}: ICellRendererParams<TData>) => {
	const cellValue = useMemo(() => valueFormatted ?? value, [valueFormatted, value]);
	const date = useMemo(() => getDateFormat(value), [value]);

	return (
		<div className={styles.DateTimeContainer}>
			<div className={styles.Date}>
				<div className={styles.DateIcon}>
					<KvIcon name={EIconName.Calendar} />
				</div>
				<div className={styles.DateText}>
					<span>{date}</span>
				</div>
			</div>
			<div className={styles.Time}>
				<div className={styles.TimeIcon}>
					<KvIcon name={EIconName.Time} />
				</div>
				<div className={styles.TimeText}>
					<span>
						<TimeFromNow date={cellValue} />
					</span>
				</div>
			</div>
		</div>
	);
};

export const DateTimeCellRenderer = withBaseCellRenderer(Component);
