import { useUpdate } from '@kelvininc/shared-ui';
import { FunctionComponent, ReactState } from '@kelvininc/types';
import { isFunction } from 'lodash-es';
import { Context, PropsWithChildren, Provider, createContext, useContext, useState } from 'react';

import { IBaseTData } from '../../../AgGridTable';
import { ISelectionAction, TableProps } from '../../types';

import { ITableSelectionContextValues } from './types';

const TableSelectionContext: Context<null | ITableSelectionContextValues<IBaseTData>> =
	createContext<null | ITableSelectionContextValues<IBaseTData>>(null);

export type TableSelectionProviderProps<TData extends IBaseTData> = Pick<
	TableProps<TData>,
	| 'checkboxSelectable'
	| 'rowSelectable'
	| 'selectedRowsInitial'
	| 'selectionActions'
	| 'onSelectionChange'
>;

export const TableSelectionProvider = <TData extends IBaseTData>({
	children,
	checkboxSelectable = false,
	rowSelectable = false,
	selectedRowsInitial = [],
	selectionActions = [],
	onSelectionChange: onSelectionChangeCallback
}: PropsWithChildren<TableSelectionProviderProps<TData>>) => {
	const [selectedRows, setSelectedRows] = useState(selectedRowsInitial);

	useUpdate(() => {
		onSelectionChangeCallback?.(selectedRows);
	}, [selectedRows]);

	const ContextProvider = useSelectionContextProvider<TData>();

	return (
		<ContextProvider
			value={{
				selectedRows: [selectedRows, setSelectedRows],
				selectionActions,
				checkboxSelectable,
				rowSelectable
			}}>
			{children}
		</ContextProvider>
	);
};

export const useSelectionContext = <
	TData extends IBaseTData
>(): ITableSelectionContextValues<TData> => {
	const context = useContext(
		TableSelectionContext as unknown as Context<null | ITableSelectionContextValues<TData>>
	);

	if (!context) {
		throw new Error('Missing table selection context');
	}

	return context;
};

export const useSelectionContextProvider = <
	TData extends IBaseTData
>(): Provider<ITableSelectionContextValues<TData> | null> => {
	return TableSelectionContext.Provider as Provider<ITableSelectionContextValues<TData> | null>;
};

export const useSelectedRows = <TData extends IBaseTData>(): ReactState<TData[]> => {
	const { selectedRows } = useSelectionContext<TData>();

	return selectedRows;
};

export const useSelectionActions = <TData extends IBaseTData>(): ISelectionAction<TData>[] => {
	const { selectionActions } = useSelectionContext<TData>();
	const [selectedRows] = useSelectedRows<TData>();

	if (isFunction(selectionActions)) {
		return selectionActions(selectedRows);
	}

	return selectionActions;
};

export const withSelectionProvider = <ComponentProps, TData extends IBaseTData>(
	Component: FunctionComponent<ComponentProps>,
	providerProps: TableSelectionProviderProps<TData>
) => {
	return function TableSelectionProviderWrapper(
		componentProps: PropsWithChildren<ComponentProps>
	) {
		return (
			<TableSelectionProvider {...providerProps}>
				<Component {...componentProps} />
			</TableSelectionProvider>
		);
	};
};
