import { openModal } from '@kelvininc/shared-ui';
import { stringToNumber } from '@kelvininc/tsutils';
import { isEqual, isNil } from 'lodash-es';
import { DefaultValues, FormState } from 'react-hook-form';

import {
	CREATE_GUARDRAIL_CRAFT_WIZARD_STEPS,
	CREATE_WIZARD_TITLE,
	EDIT_GUARDRAIL_CRAFT_WIZARD_STEPS,
	EDIT_WIZARD_TITLE
} from './config';
import { GuardrailCraftWizard } from './GuardrailCraftWizard';
import { GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA } from './schemas/form';
import styles from './styles.module.scss';
import {
	EGuardrailCraftWizardStep,
	EGuardrailCraftWizardValueType,
	GuardrailCraftWizardFormValues,
	StepValidator,
	StepValidatorCallback
} from './types';

import { buildEditGuardrailPayload } from '@/src/services/guardrail-service/utils';

const isInfoStepValid = (
	values: Partial<GuardrailCraftWizardFormValues>
): values is Required<Pick<GuardrailCraftWizardFormValues, 'asset' | 'datastream'>> &
	Partial<GuardrailCraftWizardFormValues> => {
	return !isNil(values.asset) && !isNil(values.datastream);
};

const isCreateValuesStepValid = (
	values: Partial<GuardrailCraftWizardFormValues>,
	formState?: FormState<GuardrailCraftWizardFormValues>
): values is GuardrailCraftWizardFormValues => {
	if (formState) {
		return formState.isValid;
	}

	return isValuesStepValid(values);
};

const isEditFormDirty = (
	currentData: Partial<GuardrailCraftWizardFormValues>,
	initialData?: DefaultValues<GuardrailCraftWizardFormValues>
): boolean => {
	const currentDataParsed = GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA.safeParse(currentData);
	const initialDataParsed = GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA.safeParse(initialData);

	if (!currentDataParsed.success || !initialDataParsed.success) {
		return false;
	}

	return !isEqual(
		buildEditGuardrailPayload(currentDataParsed.data),
		buildEditGuardrailPayload(initialDataParsed.data)
	);
};

const isEditValuesStepValid = (
	values: Partial<GuardrailCraftWizardFormValues>,
	formState?: FormState<GuardrailCraftWizardFormValues>
): values is GuardrailCraftWizardFormValues => {
	if (formState) {
		if (!formState.isValid) {
			return false;
		}

		return isEditFormDirty(values, formState.defaultValues);
	}

	return isValuesStepValid(values);
};

const isValuesStepValid = (
	values: Partial<GuardrailCraftWizardFormValues>
): values is GuardrailCraftWizardFormValues => {
	if (values.type && values.type === EGuardrailCraftWizardValueType.Numeric) {
		if (isNil(values.value?.max) || isNil(values.value?.min)) {
			return false;
		}

		return Number(values.value.max) > Number(values.value.min);
	}

	return true;
};

const getGuardrailStepValidator =
	(
		validators: Partial<Record<EGuardrailCraftWizardStep, StepValidator>>
	): StepValidatorCallback =>
	(step, values, formState) => {
		const validator = validators[step.id as EGuardrailCraftWizardStep];
		if (!validator) {
			return true;
		}

		return validator(values, formState);
	};

const createGuardrailStepValidator = getGuardrailStepValidator({
	[EGuardrailCraftWizardStep.Info]: isInfoStepValid,
	[EGuardrailCraftWizardStep.Values]: isCreateValuesStepValid
});

const editGuardrailStepValidator = getGuardrailStepValidator({
	[EGuardrailCraftWizardStep.Values]: isEditValuesStepValid
});

export const openCreateGuardrailCraftWizardModal = (
	submit: (form: GuardrailCraftWizardFormValues) => Promise<void>
): void => {
	openModal({
		ContentComponent: (
			<GuardrailCraftWizard
				submit={submit}
				submitLabel="Create"
				steps={CREATE_GUARDRAIL_CRAFT_WIZARD_STEPS}
				stepValidator={createGuardrailStepValidator}
			/>
		),
		config: {
			title: CREATE_WIZARD_TITLE,
			customClass: styles.GuardrailCraftWizardStep
		}
	});
};

export const openEditGuardrailCraftWizardModal = (
	initialData: DefaultValues<GuardrailCraftWizardFormValues>,
	submit: (form: GuardrailCraftWizardFormValues) => Promise<void>
): void => {
	openModal({
		ContentComponent: (
			<GuardrailCraftWizard
				initialData={initialData}
				submit={submit}
				submitLabel="Save"
				steps={EDIT_GUARDRAIL_CRAFT_WIZARD_STEPS}
				stepValidator={editGuardrailStepValidator}
			/>
		),
		config: {
			title: EDIT_WIZARD_TITLE,
			customClass: styles.GuardrailCraftWizardStep
		}
	});
};

export const transformStringToNumber = (value: string): number => {
	const number = stringToNumber(value);
	if (number.toString() !== value) {
		throw new Error('Invalid number');
	}

	return number;
};
