import { PropsWithChildren } from 'react';

import { InclusiveCheckbox } from '../InclusiveCheckbox';

import styles from './styles.module.scss';

type GuardrailValueInputProps = {
	inclusive?: boolean;
	disabled?: boolean;
	onChangeInclusive: (inclusive: boolean) => void;
};

export const GuardrailValueInput = ({
	children,
	disabled = false,
	inclusive = false,
	onChangeInclusive
}: PropsWithChildren<GuardrailValueInputProps>) => {
	return (
		<div className={styles.Value}>
			<div className={styles.Input}>{children}</div>
			<InclusiveCheckbox value={inclusive} onChange={onChangeInclusive} disabled={disabled} />
		</div>
	);
};
