import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
	CellEditRequestEvent,
	ColDef,
	ColumnMovedEvent,
	ColumnPinnedEvent,
	ColumnResizedEvent,
	ColumnVisibleEvent,
	ComponentStateChangedEvent,
	FirstDataRenderedEvent,
	GetRowIdParams,
	GridOptions,
	GridSizeChangedEvent,
	IRowNode,
	GetDetailRowData as InternalGetDetailRowData,
	ModuleRegistry,
	NewColumnsLoadedEvent,
	RowClickedEvent
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import {
	EDataState,
	OverlaysComponents,
	OverlaysConfig,
	OverlaysContainer,
	useEqualState,
	useUpdate
} from '@kelvininc/shared-ui';
import { ClassNamesProp, ESortDirection, PropsWithForwardedRef } from '@kelvininc/types';
import classNames from 'classnames';
import { cloneDeep, merge, set } from 'lodash-es';
import {
	ForwardedRef,
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState
} from 'react';

import { LICENSE_KEY } from '../../../config';
import { useContextSync, useRowHeightSync, useSelectionApi } from '../../hooks';

import {
	DEFAULT_AG_GRID_OPTIONS,
	DEFAULT_COLUMN_DEF,
	DEFAULT_MAX_ROWS_PINNED,
	DEFAULT_OVERLAY_CONFIG,
	DEFAULT_ROW_HEIGHT_IN_PIXELS
} from './config';

import styles from './styles.module.scss';
import {
	Datasource,
	DetailCellRendererParams,
	ESelectionType,
	GetDetailRowData,
	IAgGridTableApi,
	IBaseTData,
	IColumnDef,
	IColumnOptions,
	IColumnsHiddenConfig,
	IColumnsPinConfig,
	IGridOptions,
	IPaginationInfo,
	IPaginationTotalInfo,
	ISortInfo,
	ITableAdvancedFilters,
	ITableContext,
	ITableFilters
} from './types';
import {
	buildAgGridColumnDefs,
	buildAgGridColumnOptions,
	buildServerDatasource,
	executeGridAPICall,
	getColumnsHidden,
	getColumnsIds,
	getColumnsPinned,
	getRowModelType,
	getState,
	refreshDatasource,
	refreshMasterProperty,
	sizeColumnsToFit
} from './utils';

import './ag-grid.scss';

ModuleRegistry.registerModules([
	ClientSideRowModelModule,
	ServerSideRowModelModule,
	MasterDetailModule
]);

LicenseManager.setLicenseKey(LICENSE_KEY);

export type AgGridTableProps<TData extends IBaseTData, TDetail extends IBaseTData = IBaseTData> = {
	id?: string;
	customClasses?: ClassNamesProp;
	maxHeight?: number | 'none';
	defaultColumnDef?: IColumnOptions<TData>;
	defaultDetailColumnDef?: IColumnOptions<TDetail>;
	agGridOptions?: IGridOptions;
	columnDefs: IColumnDef<TData>[];
	detailColumnDefs?: IColumnDef<TDetail>[];
	data?: TData[];
	datasource?: Datasource<TData>;
	filters?: ITableFilters;
	advancedFilters?: ITableAdvancedFilters;
	searchTerm?: string;
	rowHeight?: number;
	page?: number;
	pageSize?: number;
	sortable?: boolean;
	sortByInitial?: string[];
	sortDirectionInitial?: ESortDirection;
	selectionType?: ESelectionType;
	overlaysConfig?: Partial<OverlaysConfig>;
	overlaysComponents?: Partial<OverlaysComponents>;
	rowsPinnedInitial?: TData[];
	maxRowsPinned?: number;
	maxSelectedRows?: number;
	selectedRowsInitial?: TData[];
	/** Set to `true` to stop the grid updating data after `Edit`, `Clipboard` and `Fill Handle` operations.
	 * When this is set, it is intended the application will update the data, eg in an external immutable store,
	 * and then pass the new dataset to the grid.
	 * **Note:** `rowNode.setDataValue()` does not update the value of the cell when this is `True`, it fires `onDataChange` instead.
	 * Default: `false`.
	 * */
	readOnlyEdit?: boolean;
	onPaginationChange?: (newPagination: IPaginationInfo | undefined) => void;
	onPaginationTotalChange?: (newPaginationTotal: IPaginationTotalInfo | undefined) => void;
	onSortChange?: (newSort: ISortInfo | undefined) => void;
	onColumnMoved?: (newColumns: string[]) => void;
	onColumnPinned?: (newColumns: IColumnsPinConfig) => void;
	onColumnHidden?: (newColumns: IColumnsHiddenConfig) => void;
	onSelectionChange?: (newSelection: TData[]) => void;
	onStateChange?: (newState: EDataState | undefined) => void;
	onRowsPinnedChange?: (newRowsPinned: TData[]) => void;
	onDataChange?: (rowIndex: number | null, newData: TData) => void;
	isRowDisabled?: (data: TData, selectedRows: TData[]) => boolean;
	getRowId: (data: TData) => string;
	masterDetail?: boolean;
	isRowMaster?: (data: TData) => boolean;
	getDetailRowData?: GetDetailRowData<TData, TDetail>;
	getDetailRowId?: (data: TDetail) => string;
	detailCellRenderer?: (params: DetailCellRendererParams<TData>) => JSX.Element;
};

const Component = <TData extends IBaseTData, TDetail extends IBaseTData = IBaseTData>({
	defaultColumnDef,
	defaultDetailColumnDef,
	agGridOptions = {},
	columnDefs,
	detailColumnDefs,
	maxHeight = 'none',
	data,
	datasource,
	filters,
	advancedFilters,
	searchTerm,
	page,
	pageSize,
	sortable = false,
	sortByInitial,
	sortDirectionInitial,
	rowHeight = DEFAULT_ROW_HEIGHT_IN_PIXELS,
	selectionType,
	overlaysConfig = {},
	overlaysComponents = {},
	rowsPinnedInitial = [],
	maxRowsPinned = DEFAULT_MAX_ROWS_PINNED,
	selectedRowsInitial = [],
	maxSelectedRows,
	customClasses,
	readOnlyEdit,
	onPaginationChange: onPaginationChangeCallback,
	onPaginationTotalChange: onPaginationTotalChangeCallback,
	onSortChange: onSortChangeCallback,
	onColumnMoved: onColumnMovedCallback,
	onColumnPinned: onColumnPinnedCallback,
	onColumnHidden: onColumnHiddenCallback,
	onSelectionChange: onSelectionChangeCallback,
	onStateChange: onStateChangeCallback,
	onRowsPinnedChange: onRowsPinnedChangeCallback,
	onDataChange: onDataChangeCallback,
	isRowDisabled,
	getRowId,
	masterDetail,
	isRowMaster,
	getDetailRowData,
	getDetailRowId,
	detailCellRenderer,
	forwardedRef
}: PropsWithForwardedRef<AgGridTableProps<TData, TDetail>, IAgGridTableApi<TData>>) => {
	const gridRef = useRef<AgGridReact<TData> | null>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const [state, setState] = useState<EDataState>(getState(data));

	const [rowsPinned, setRowsPinned] = useEqualState(rowsPinnedInitial);
	const [sortInfo, setSortInfo] = useEqualState<ISortInfo | undefined>({
		sortBy: sortByInitial,
		sortDirection: sortDirectionInitial
	});
	const selectionApi = useSelectionApi({
		getRowId,
		initialSelectedRows: selectedRowsInitial,
		gridApi: gridRef.current,
		maxSelectedRows,
		isRowDisabled
	});
	const { selectedRows, setSelectedBulk, deselectAll } = selectionApi;

	const tableColumnDefs = useMemo(
		() => buildAgGridColumnDefs(columnDefs, sortable),
		[columnDefs, sortable]
	);
	const tableDatasource = useMemo(
		() => (datasource ? buildServerDatasource(datasource) : undefined),
		[datasource]
	);

	const tableContext = useMemo<ITableContext<TData>>(
		() => ({
			searchTerm,
			filters,
			advancedFilters,
			pageSize: pageSize,
			page: page,
			sortBy: sortInfo?.sortBy,
			sortDirection: sortInfo?.sortDirection,
			state,
			onStateChange: setState,
			rowsPinned,
			maxRowsPinned,
			maxSelectedRows,
			onRowsPinnedChange: setRowsPinned,
			onPaginationChange: onPaginationChangeCallback,
			onPaginationTotalChange: onPaginationTotalChangeCallback,
			onSortChange: setSortInfo,
			getRowId,
			columnDefs,
			selectionApi,
			isRowMaster
		}),
		[
			advancedFilters,
			columnDefs,
			filters,
			getRowId,
			maxRowsPinned,
			maxSelectedRows,
			onPaginationChangeCallback,
			onPaginationTotalChangeCallback,
			page,
			pageSize,
			rowsPinned,
			searchTerm,
			selectionApi,
			setRowsPinned,
			setSortInfo,
			sortInfo?.sortBy,
			sortInfo?.sortDirection,
			state,
			isRowMaster
		]
	);

	const mergedDefaultColumnDef = useMemo(
		() => merge({}, DEFAULT_COLUMN_DEF, buildAgGridColumnOptions(defaultColumnDef)),
		[defaultColumnDef]
	);

	const mergedDefaultOverlayConfig = useMemo(
		() => merge({}, DEFAULT_OVERLAY_CONFIG, overlaysConfig),
		[overlaysConfig]
	);

	const mergedDefaultDetailColumnDef = useMemo(
		() => merge({}, DEFAULT_COLUMN_DEF, buildAgGridColumnOptions(defaultDetailColumnDef)),
		[defaultDetailColumnDef]
	);

	const mergeAgGridOptions = merge({}, DEFAULT_AG_GRID_OPTIONS, agGridOptions);
	const customStyle = {
		'--ag-row-number': pageSize,
		'--ag-row-height': `${rowHeight}px`
	};

	const onTableColumnMoved = useCallback(
		(event: ColumnMovedEvent) => {
			if (!event.api) {
				return;
			}

			const newColumnDefs = event.api.getColumnDefs() as ColDef<TData>[] | undefined;

			if (newColumnDefs) {
				onColumnMovedCallback?.(getColumnsIds(newColumnDefs));
			}
		},
		[onColumnMovedCallback]
	);

	const onTableColumnPinned = useCallback(
		(event: ColumnPinnedEvent) => {
			if (!event.api) {
				return;
			}

			const newColumnDefs = event.api.getColumnDefs() as ColDef<TData>[] | undefined;

			if (newColumnDefs) {
				onColumnPinnedCallback?.(getColumnsPinned(newColumnDefs));
			}
		},
		[onColumnPinnedCallback]
	);

	const onTableColumnVisible = useCallback(
		(event: ColumnVisibleEvent) => {
			if (!event.api) {
				return;
			}

			const newColumnDefs = event.api.getColumnDefs() as
				| ColDef<TData | TDetail>[]
				| undefined;

			if (newColumnDefs) {
				onColumnHiddenCallback?.(getColumnsHidden(newColumnDefs));
			}
		},
		[onColumnHiddenCallback]
	);

	const onFirstDataRendered = useCallback(
		(event: FirstDataRenderedEvent) => {
			if (!event.api) {
				return;
			}

			event.api.updateGridOptions({ pinnedTopRowData: rowsPinnedInitial });
			event.api.refreshHeader();
			event.api.refreshCells();
			sizeColumnsToFit({
				gridApi: event.api,
				container: containerRef.current,
				fitContent: true
			});
		},
		[rowsPinnedInitial]
	);

	const onNewColumnsLoaded = useCallback((event: NewColumnsLoadedEvent) => {
		event.api.refreshHeader();
		sizeColumnsToFit({ gridApi: event.api, container: containerRef.current, fitContent: true });
	}, []);

	const onComponentStateChanged = useCallback(
		(event: ComponentStateChangedEvent) => {
			refreshMasterProperty(event.api, isRowMaster);
			sizeColumnsToFit({
				gridApi: event.api,
				container: containerRef.current,
				fitContent: true
			});
		},
		[isRowMaster]
	);

	const onColumnResized = useCallback((event: ColumnResizedEvent) => {
		if (!event.finished || event.source !== 'uiColumnResized') {
			return;
		}

		sizeColumnsToFit({ gridApi: event.api, container: containerRef.current });
	}, []);

	const onGridSizeChanged = useCallback((event: GridSizeChangedEvent) => {
		sizeColumnsToFit({ gridApi: event.api, container: containerRef.current, fitContent: true });
	}, []);

	const getRowIdWrapper = useCallback(
		({ data }: GetRowIdParams<TData>): string => getRowId(data),
		[getRowId]
	);

	const getDetailRowIdWrapper = useCallback(
		({ data }: GetRowIdParams<TDetail>): string =>
			getDetailRowId?.(data) ?? JSON.stringify(data),
		[getDetailRowId]
	);

	const onRowClicked = useCallback(
		({ node }: RowClickedEvent) => {
			if (!node.data || node.stub || !node.selectable || !selectionType) return;

			if (selectionType !== ESelectionType.Single) return;

			if (selectionApi.isSelected(node)) {
				setSelectedBulk([]);
			} else {
				setSelectedBulk([node.data]);
			}
		},
		[selectionApi, selectionType, setSelectedBulk]
	);

	useContextSync(gridRef.current, tableContext, setState);
	useRowHeightSync(gridRef.current, rowHeight);

	useUpdate(() => {
		executeGridAPICall(gridRef.current?.api, (api) =>
			api.updateGridOptions({ pinnedTopRowData: rowsPinned })
		);

		onRowsPinnedChangeCallback?.(rowsPinned);
	}, [rowsPinned]);

	useUpdate(() => {
		onSortChangeCallback?.(sortInfo);
	}, [sortInfo]);
	useUpdate(() => {
		onSelectionChangeCallback?.(Object.values(selectedRows));
	}, [selectedRows]);
	useUpdate(() => {
		onStateChangeCallback?.(state);
	}, [state]);

	useEffect(() => {
		return () => {
			gridRef.current = null;
		};
	}, []);

	useEffect(() => {
		setState(getState(data));
	}, [data]);

	useImperativeHandle(forwardedRef, () => ({
		refreshDatasource: () => {
			if (gridRef.current?.api) {
				refreshDatasource(gridRef.current.api);
			}
		},
		setSelectedRows: (rows: TData[]) => {
			setSelectedBulk(rows);
		},
		clearSelectedRows: () => {
			deselectAll();
		},
		setPinnedRows: (rows: TData[]) => {
			executeGridAPICall(gridRef.current?.api, (api) =>
				api.updateGridOptions({ pinnedTopRowData: rows })
			);
		},
		clearPinnedRows: () => {
			if (gridRef.current?.api) {
				const { api } = gridRef.current;

				// Add rows to table's unpinned rows section
				api.applyServerSideTransaction({
					addIndex: 0,
					add: rowsPinned
				});

				setRowsPinned([]);
			}
		}
	}));

	const onCellEditRequestHandler = useCallback(
		(event: CellEditRequestEvent) => {
			const field = event.column.getColDef().field ?? event.column.getColId();
			const newData = cloneDeep(event.data);
			onDataChangeCallback?.(event.rowIndex, set(newData, [field], event.value));
		},
		[onDataChangeCallback]
	);

	useUpdate(() => {
		// Ag Grid does not re-render the cells when the isRowSelectable prop changes.
		// Therefore, we need to manually set this property to force every cell to re-render.
		// We need this to dynamically enable or disable a row selection.
		//
		// https://stackoverflow.com/questions/64014770/aggridreact-grid-does-not-update-when-isrowselectable-changes
		executeGridAPICall(gridRef.current?.api, (api) =>
			api.forEachNode(
				(rowNode: IRowNode<TData>) =>
					(rowNode.selectable = selectionApi.isSelectable(rowNode))
			)
		);
	}, [selectionApi.isSelectable]);

	const getDetailCellRendererParams = useMemo(() => {
		if (!getDetailRowData || !detailColumnDefs) return;

		const detailsParams: {
			detailGridOptions: GridOptions<TDetail>;
			getDetailRowData: InternalGetDetailRowData<TData, TDetail>;
		} = {
			detailGridOptions: {
				defaultColDef: mergedDefaultDetailColumnDef,
				columnDefs: buildAgGridColumnDefs(detailColumnDefs),
				context: {
					getRowId: getDetailRowData,
					columnDefs: detailColumnDefs,
					selectionApi
				},
				rowHeight,
				getRowId: getDetailRowIdWrapper,
				...DEFAULT_AG_GRID_OPTIONS
			},
			getDetailRowData: ({ data, successCallback }) => successCallback(getDetailRowData(data))
		};

		return detailsParams;
	}, [
		detailColumnDefs,
		getDetailRowData,
		getDetailRowIdWrapper,
		mergedDefaultDetailColumnDef,
		rowHeight,
		selectionApi
	]);

	return (
		<div
			id="table"
			data-test-id="e2e-table-component"
			className={classNames('ag-theme-kelvin', styles.Table, customClasses, {
				['ag-row-single-selectable']: selectionType === ESelectionType.Single
			})}
			style={{ ...customStyle, maxHeight }}
			ref={containerRef}>
			<OverlaysContainer
				state={state}
				config={mergedDefaultOverlayConfig}
				components={overlaysComponents}
			/>
			<AgGridReact
				ref={(ref) => {
					if (ref) gridRef.current = ref;
				}}
				defaultColDef={mergedDefaultColumnDef}
				{...mergeAgGridOptions}
				onColumnMoved={onTableColumnMoved}
				onColumnPinned={onTableColumnPinned}
				onColumnVisible={onTableColumnVisible}
				onNewColumnsLoaded={onNewColumnsLoaded}
				onFirstDataRendered={onFirstDataRendered}
				onColumnResized={onColumnResized}
				onGridSizeChanged={onGridSizeChanged}
				onComponentStateChanged={onComponentStateChanged}
				isRowSelectable={selectionApi.isSelectable}
				serverSideDatasource={tableDatasource}
				columnDefs={tableColumnDefs}
				context={tableContext}
				rowHeight={rowHeight}
				rowSelection={selectionType}
				getRowId={getRowIdWrapper}
				rowData={data}
				rowModelType={getRowModelType(data)}
				onRowClicked={onRowClicked}
				readOnlyEdit={readOnlyEdit}
				onCellEditRequest={onCellEditRequestHandler}
				masterDetail={masterDetail}
				isRowMaster={isRowMaster}
				detailCellRendererParams={getDetailCellRendererParams}
				detailRowAutoHeight
				detailRowHeight={rowHeight}
				detailCellRenderer={detailCellRenderer}
			/>
		</div>
	);
};

export const AgGridTable = forwardRef(function AgGridTable<TData extends IBaseTData>(
	props: AgGridTableProps<TData>,
	ref: ForwardedRef<IAgGridTableApi<TData>>
) {
	return <Component {...props} forwardedRef={ref} />;
}) as <TData extends IBaseTData, TDetail extends IBaseTData = IBaseTData>(
	props: AgGridTableProps<TData, TDetail> & { ref?: ForwardedRef<IAgGridTableApi<TData>> }
) => ReturnType<typeof Component<TData, TDetail>>;
