'use client';
import { EBadgeState, KvBadge } from '@kelvininc/react-ui-components';
import { useResizeSensor } from '@kelvininc/shared-ui';
import { PropsWithChildren, useRef, useState } from 'react';

import { ELLIPSIS_OFFSET } from './config';
import styles from './styles.module.scss';

export const InlineList = ({ children: childrenNodes }: PropsWithChildren) => {
	const parentRef = useRef<HTMLDivElement>(null);
	const childrenRef = useRef<HTMLDivElement>(null);
	const [hiddenChildren, setHiddenChildren] = useState<number>(0);

	// Used to update the hidden children elements on resize
	useResizeSensor(parentRef, () => calculateHiddenIndexes(), 0);

	const calculateHiddenIndexes = () => {
		const parent = parentRef.current;
		const children = childrenRef.current;
		if (!parent || !children) return;

		const parentRight = parent.getBoundingClientRect().right - ELLIPSIS_OFFSET;
		let countHidden = 0;
		Array.from(children.children).forEach((child) => {
			const childRec = child.getBoundingClientRect();
			if (childRec.right > parentRight) {
				countHidden++;
			}
		});
		setHiddenChildren(countHidden > 0 ? countHidden - 1 : countHidden);
	};

	return (
		<div className={styles.InlineList}>
			<div className={styles.ListChildrenContainer} ref={parentRef}>
				<div className={styles.Children} ref={childrenRef}>
					{childrenNodes}
				</div>
			</div>
			{hiddenChildren > 0 && (
				<KvBadge className={styles.Badge} state={EBadgeState.Info}>
					{`+${hiddenChildren}`}
				</KvBadge>
			)}
		</div>
	);
};
