import { ParameterValue } from '@kelvininc/node-client-sdk';
import { EIconName, ETagState, KvIcon } from '@kelvininc/react-ui-components';
import {
	Expandable,
	InfoAlert,
	Link,
	SeeMoreLink,
	Skeleton,
	useExpandable,
	withExpandableProvider
} from '@kelvininc/shared-ui';
import { convertToSearchParams, getParameterTitle } from '@kelvininc/tsutils';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { PropsWithChildren, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { applicationScheduleDependenciesSelector } from '../../recoil/selectors';
import { ParameterScheduleAttributes } from '../../types';

import { isSingleAssetSchedule } from '../../utils';

import { DIFFERENT_VALUES_ALERT, MULTI_ASSET_ALERT } from './config';
import styles from './styles.module.scss';
import {
	buildDetailValuesData,
	buildParameterValues,
	convertToParameterMap,
	getValueInfo,
	hasDifferentValues,
	openValuesDetailModal,
	sortParametersByName
} from './utils';

import { EApplicationQueryParams } from '@/src/page-components/ApplicationsPage/ApplicationDetails/types';
import { closedLoopInstanceSettingSelector } from '@/src/recoil/instance-settings/selectors';
import { buildApplicationDetailsScheduleRoute } from '@/src/utils/routes';

const MultiAssetAlertContent = ({ schedule }: { schedule: ParameterScheduleAttributes }) => {
	return (
		<div>
			{MULTI_ASSET_ALERT}{' '}
			<Link
				href={buildApplicationDetailsScheduleRoute(
					schedule.appName,
					convertToSearchParams({ [EApplicationQueryParams.Schedule]: schedule.id })
				)}
				target={'_blank'}
				className={styles.CustomLink}>
				View them all here.
			</Link>
		</div>
	);
};

type ExpandableActionProps = {
	isOpen: boolean;
	toggle: () => void;
	disabled?: boolean;
};

const ExpandableAction = ({ isOpen, toggle, disabled = false }: ExpandableActionProps) => {
	return (
		<div
			className={classNames(styles.ExpandableAction, { [styles.Disabled]: disabled })}
			onClick={toggle}>
			<div className={styles.Title}>{isOpen ? 'See less' : 'See More'}</div>
			<KvIcon name={isOpen ? EIconName.Collapse : EIconName.Expand} />
		</div>
	);
};

const AssetParameterRowSkeleton = () => {
	return (
		<div className={styles.ScheduleParameterRow}>
			<Skeleton height={18} width={210} />
			<Skeleton containerClassName={styles.ValueColumn} height={18} width={40} />
			<Skeleton containerClassName={styles.ValueColumn} height={18} width={40} />
		</div>
	);
};

const AssetParameterRow = ({
	parameterName,
	parameterValue,
	initialValuesMap = {},
	revertValuesMap = {},
	isReversible = false,
	assetName
}: {
	parameterName?: string;
	parameterValue: ParameterValue;
	initialValuesMap: Record<string, Record<string, ParameterValue>>;
	revertValuesMap: Record<string, Record<string, ParameterValue>>;
	isReversible: boolean;
	assetName?: string;
}) => {
	const { parameters, assets } = useRecoilValue(applicationScheduleDependenciesSelector);
	const closedLoopSettings = useRecoilValue(closedLoopInstanceSettingSelector);
	const { mapping } = closedLoopSettings;

	const initialValueInfo = useMemo(
		() => getValueInfo(parameterName ? initialValuesMap[parameterName] : {}, assetName),
		[assetName, initialValuesMap, parameterName]
	);

	const revertValueInfo = useMemo(
		() => getValueInfo(parameterName ? revertValuesMap[parameterName] : {}, assetName),
		[assetName, parameterName, revertValuesMap]
	);
	const parameterTitle = useMemo(() => {
		if (parameterName && parameters[parameterName]) {
			return getParameterTitle(parameters[parameterName], closedLoopSettings);
		}

		return '';
	}, [closedLoopSettings, parameterName, parameters]);

	const parameterValues = useMemo(
		() =>
			buildParameterValues(
				parameterName,
				initialValueInfo.firstValue,
				parameterValue,
				revertValueInfo.firstValue,
				mapping
			),
		[
			parameterName,
			initialValueInfo.firstValue,
			parameterValue,
			revertValueInfo.firstValue,
			mapping
		]
	);

	return (
		parameterName && (
			<div className={styles.ScheduleParameterRow}>
				<span>{parameterTitle}</span>
				<span className={styles.ValueColumn}>
					{initialValueInfo.hasDifferentValues && isEmpty(assetName) ? (
						<SeeMoreLink
							text="Multiple Values"
							onSeeMoreClicked={() =>
								openValuesDetailModal({
									title: 'Initial',
									parameterTitle,
									data: buildDetailValuesData(
										parameterName,
										initialValuesMap[parameterName],
										mapping,
										assets
									)
								})
							}
						/>
					) : (
						parameterValues.initial
					)}
				</span>
				<span className={styles.ValueColumn}>{parameterValues.scheduled}</span>
				{isReversible && (
					<span className={styles.ValueColumn}>
						{revertValueInfo.hasDifferentValues && isEmpty(assetName) ? (
							<SeeMoreLink
								text="Multiple Values"
								onSeeMoreClicked={() =>
									openValuesDetailModal({
										title: 'Revert to',
										parameterTitle,
										data: buildDetailValuesData(
											parameterName,
											revertValuesMap[parameterName],
											mapping,
											assets
										)
									})
								}
							/>
						) : (
							parameterValues.revert
						)}
					</span>
				)}
			</div>
		)
	);
};

const NextLinesTag = ({ children, isExpandable }: PropsWithChildren<{ isExpandable: boolean }>) => {
	if (isExpandable) {
		return <Expandable>{children}</Expandable>;
	}

	return <div>{children}</div>;
};

export const ScheduleParameterTableSkeleton = () => {
	return (
		<div className={styles.ScheduleParameterTable}>
			<AssetParameterRowSkeleton />
			<AssetParameterRowSkeleton />
		</div>
	);
};

type ScheduleParameterTableProps = {
	schedule: ParameterScheduleAttributes;
	closableParameters?: boolean;
	assetName?: string; //Allow show only the values for the specific asset
	hasAlerts?: boolean;
};

export const ParameterTable = ({
	schedule,
	closableParameters = true,
	assetName,
	hasAlerts = true
}: ScheduleParameterTableProps) => {
	const { isExpanded, toggle } = useExpandable();
	const { revert, parameters, originalResourceParameters } = schedule;
	const initialValuesMap = convertToParameterMap(originalResourceParameters ?? []);
	const revertValuesMap = convertToParameterMap(schedule.revert?.resourceParameters ?? []);
	const isSingleAsset = useMemo(() => isSingleAssetSchedule(schedule), [schedule]);

	const differentValues = useMemo(
		() => hasDifferentValues(initialValuesMap, revertValuesMap),
		[initialValuesMap, revertValuesMap]
	);
	const [firstParameterRow, ...tailParameterRows] = useMemo(
		() => sortParametersByName(parameters),
		[parameters]
	);

	return (
		<div
			className={classNames(styles.ScheduleParameterTable, {
				[styles.HasRevertAction]: !isEmpty(revert)
			})}>
			{hasAlerts && (
				<>
					{!isSingleAsset && !isEmpty(assetName) && (
						<InfoAlert
							customClasses={styles.InfoAlert}
							icon={EIconName.Info}
							state={ETagState.Info}
							alertMessage={<MultiAssetAlertContent schedule={schedule} />}
						/>
					)}
					{differentValues && isEmpty(assetName) && (
						<InfoAlert
							customClasses={styles.InfoAlert}
							alertMessage={DIFFERENT_VALUES_ALERT}
						/>
					)}
				</>
			)}
			<div className={classNames(styles.ScheduleParameterRow, styles.Header)}>
				<span>Asset Parameter</span>
				<span className={styles.ValueColumn}>Initial</span>
				<span className={styles.ValueColumn}>Scheduled</span>
				{!isEmpty(revert) && <span className={styles.ValueColumn}>Revert to</span>}
			</div>
			<AssetParameterRow
				parameterName={firstParameterRow?.[0]}
				parameterValue={firstParameterRow?.[1]}
				initialValuesMap={initialValuesMap}
				revertValuesMap={revertValuesMap}
				isReversible={!isEmpty(revert)}
				assetName={assetName}
			/>
			<NextLinesTag isExpandable={closableParameters}>
				{(tailParameterRows ?? []).map(([parameterName, parameterValue]) => (
					<AssetParameterRow
						key={parameterName}
						parameterName={parameterName}
						parameterValue={parameterValue}
						initialValuesMap={initialValuesMap}
						revertValuesMap={revertValuesMap}
						isReversible={!isEmpty(revert)}
						assetName={assetName}
					/>
				))}
			</NextLinesTag>
			{closableParameters && Object.keys(parameters).length > 1 && (
				<div className={styles.ExpandableActionRow}>
					<ExpandableAction isOpen={isExpanded} toggle={toggle} />
				</div>
			)}
		</div>
	);
};

export const ScheduleParameterTable = withExpandableProvider(ParameterTable, { isExpanded: false });
