import { useMemo } from 'react';

import {
	CONNECTIONS_PENDING_CONFIG,
	CONNECTIONS_READY_CONFIG,
	FIRST_EXPERIENCE_STEPS,
	REQUIRED_ACTION_ENTITIES
} from '../config';
import { EApplicationEntity, EntityAction, IFirstExperienceStepStates } from '../types';

import { IExperienceGuideStep } from '@/src/components/client';

export const useTutorialSteps = (
	stepStates: IFirstExperienceStepStates
): IExperienceGuideStep[] => {
	const hasRequiredEntities = REQUIRED_ACTION_ENTITIES[EntityAction.AddConnection].every(
		(requiredEntity) => stepStates[requiredEntity]
	);

	return useMemo(() => {
		const connectionsStep: IExperienceGuideStep = {
			id: EApplicationEntity.Connections,
			label: 'Create Connections',
			disabled: !hasRequiredEntities && !stepStates[EApplicationEntity.Connections],
			...(hasRequiredEntities ? CONNECTIONS_READY_CONFIG : CONNECTIONS_PENDING_CONFIG)
		};

		return [...FIRST_EXPERIENCE_STEPS, connectionsStep];
	}, [hasRequiredEntities, stepStates]);
};
