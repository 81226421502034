import { RadioButton } from '../../../RadioButtons';
import { EGuardrailValueType } from '../../types';

export const GUARDRAIL_VALUE_TYPE_RADIO_BUTTONS = [
	{
		label: 'A static value',
		value: EGuardrailValueType.Number
	},
	{
		label: '% of last value',
		value: EGuardrailValueType.Percentage
	}
] as const satisfies RadioButton<EGuardrailValueType.Number | EGuardrailValueType.Percentage>[];
