import { EWorkloadDownloadStatus } from '@kelvininc/node-client-sdk';
import { useInterval } from '@kelvininc/shared-ui';
import { useEffect, useState } from 'react';

import { DOWNLOAD_STATUS_POOLING_INTERVAL } from './config';

import { getWorkloadRequester } from '@/src/recoil/workloads/requests';

export const useCreateImageState = (workloadName: string) => {
	const [downloadWorkloadStatus, setDownloadWorkloadStatus] = useState<EWorkloadDownloadStatus>();

	const { start, stop } = useInterval(
		async () => {
			const newWorkload = await getWorkloadRequester(workloadName);
			setDownloadWorkloadStatus(newWorkload.downloadStatus);
		},
		{ delay: DOWNLOAD_STATUS_POOLING_INTERVAL }
	);

	useEffect(() => {
		start();

		if (downloadWorkloadStatus === EWorkloadDownloadStatus.Ready) {
			stop();
		}
		return () => stop();
	}, [downloadWorkloadStatus, start, stop]);

	return downloadWorkloadStatus;
};
