import { BulkGuardrailsCreate, Guardrail, GuardrailsService } from '@kelvininc/node-client-sdk';
import { buildDeleteModalConfig, closeModal, openModal, openToaster } from '@kelvininc/shared-ui';

import pluralize from 'pluralize';

import { EGuardrailOperationType, GuardrailOperation, GuardrailOperationResult } from './types';
import {
	buildCreateGuardrailPayload,
	buildEditGuardrailPayload,
	buildGuardrailFormValues,
	buildImportGuardrailsPayload,
	buildOperationErrorToaster,
	buildOperationSuccessToaster
} from './utils';

import { GuardrailCraftWizardFormValues } from '@/src/components/client/GuardrailCraftWizard/types';
import {
	openCreateGuardrailCraftWizardModal,
	openEditGuardrailCraftWizardModal
} from '@/src/components/client/GuardrailCraftWizard/utils';
import { ImportGuardrailsWizardFormValues } from '@/src/components/client/ImportGuardrailsWizard/types';
import { openImportGuardrailsWizardModal } from '@/src/components/client/ImportGuardrailsWizard/utils';

export class GuardrailOperationService {
	private static openDeleteModal(resources: string[] = []): Promise<void> {
		return new Promise<void>((resolve) =>
			openModal(
				buildDeleteModalConfig({
					title: `Delete ${pluralize('Guardrail', resources.length, resources.length > 1)}?`,
					description: `Are you sure you want to delete ${resources.length > 1 ? 'these guardrails' : 'this guardrail'}? Deleting ${resources.length > 1 ? 'them' : 'it'} will allow all control changes to be applied to the related asset-data stream pairs without any security safeguards.`,
					onClickDelete: () => resolve()
				})
			)
		);
	}

	private static openCreateWizardModal(
		submit: (form: GuardrailCraftWizardFormValues) => Promise<Guardrail>
	): Promise<Guardrail> {
		return new Promise<Guardrail>((resolve) =>
			openCreateGuardrailCraftWizardModal((form) =>
				submit(form).then((newGuardrail) => resolve(newGuardrail))
			)
		);
	}

	private static openEditWizardModal(
		guardrail: Guardrail,
		submit: (form: GuardrailCraftWizardFormValues) => Promise<Guardrail>
	): Promise<Guardrail> {
		return new Promise<Guardrail>((resolve) =>
			openEditGuardrailCraftWizardModal(buildGuardrailFormValues(guardrail), (form) =>
				submit(form).then((newGuardrail) => resolve(newGuardrail))
			)
		);
	}

	private static openImportWizardModal(
		submit: (form: ImportGuardrailsWizardFormValues) => Promise<BulkGuardrailsCreate>
	): Promise<BulkGuardrailsCreate> {
		return new Promise<BulkGuardrailsCreate>((resolve) =>
			openImportGuardrailsWizardModal({
				submit: (form) => submit(form).then((guardrail) => resolve(guardrail))
			})
		);
	}

	private static openSuccessToaster(
		operation: GuardrailOperation,
		result: GuardrailOperationResult
	): void {
		openToaster(buildOperationSuccessToaster(operation, result));
	}

	private static openErrorToaster({ operation }: { operation: GuardrailOperation }): void {
		openToaster(buildOperationErrorToaster(operation));
	}

	private static async execute(operation: GuardrailOperation): Promise<GuardrailOperationResult> {
		const { type } = operation;
		switch (type) {
			case EGuardrailOperationType.Create:
				return GuardrailOperationService.openCreateWizardModal((form) =>
					GuardrailsService.createGuardrail({
						guardrailConfigWithResource: buildCreateGuardrailPayload(form)
					}).toPromise()
				).finally(() => closeModal());

			case EGuardrailOperationType.Edit:
				try {
					const guardrail = await GuardrailsService.getGuardrail({
						resource: operation.guardrail.resource
					}).toPromise();

					return await GuardrailOperationService.openEditWizardModal(guardrail, (form) =>
						GuardrailsService.updateGuardrail({
							guardrailConfig: buildEditGuardrailPayload(form),
							resource: operation.guardrail.resource
						}).toPromise()
					);
				} finally {
					closeModal();
				}

			case EGuardrailOperationType.Import:
				return await GuardrailOperationService.openImportWizardModal((form) =>
					GuardrailsService.createBulkGuardrails({
						bulkGuardrailsCreateData: buildImportGuardrailsPayload(form)
					}).toPromise()
				).finally(() => closeModal());

			case EGuardrailOperationType.Delete:
				return GuardrailOperationService.openDeleteModal([operation.guardrail.resource])
					.then(() =>
						GuardrailsService.deleteGuardrail({
							resource: operation.guardrail.resource
						}).toPromise()
					)
					.finally(() => closeModal());

			case EGuardrailOperationType.BulkDelete:
				return GuardrailOperationService.openDeleteModal(operation.payload.resources)
					.then(() =>
						GuardrailsService.deleteBulkGuardrails({
							bulkGuardrailsDeleteData: operation.payload
						}).toPromise()
					)
					.finally(() => closeModal());

			default:
				throw new Error(`Unknown operation type: ${type}`);
		}
	}

	public static executeGuardrailOperation(
		operation: GuardrailOperation,
		callbacks?: {
			success?: () => void;
			fail?: (error: unknown) => void;
		}
	): Promise<void> {
		return GuardrailOperationService.execute(operation)
			.then((result) => {
				GuardrailOperationService.openSuccessToaster(operation, result);
				callbacks?.success?.();
			})
			.catch((error) => {
				GuardrailOperationService.openErrorToaster({
					operation
				});
				callbacks?.fail?.(error);
			});
	}
}
