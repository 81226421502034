import { ITabNavigationItem } from '@kelvininc/react-ui-components';

import { EGuardrailValuesFormTabKey } from './types';

export const GUARDRAIL_VALUES_FORM_TABS = [
	{
		tabKey: EGuardrailValuesFormTabKey.Basic,
		label: 'Basic'
	},
	{
		tabKey: EGuardrailValuesFormTabKey.Advanced,
		label: 'Advanced'
	}
] as const satisfies ITabNavigationItem[];
