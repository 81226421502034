import { ExpandableMenu, useNavigationSideBar, withExpandableProvider } from '@kelvininc/shared-ui';

import { navigationSidebarExpandedAdminUserSettingAtom } from '../../atom';
import {
	NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_KEY
} from '../../config';

import { ADMIN_TITLE } from './config';
import { useAdminExpandableItems } from './utils';

const Component = () => {
	const { isCollapsed } = useNavigationSideBar();
	const adminExpandableItems = useAdminExpandableItems();

	return (
		<ExpandableMenu title={ADMIN_TITLE} items={adminExpandableItems} collapsed={isCollapsed} />
	);
};

export const AdminMenu = withExpandableProvider(
	Component,
	NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_KEY,
	navigationSidebarExpandedAdminUserSettingAtom
);
