import { AssetInsightsItem } from '@kelvininc/node-client-sdk';
import { IColumnDef, ICustomColumnMetadata, ITableSettings } from '@kelvininc/table';
import { identity } from 'lodash-es';

import {
	ISerializedAssetInsightsCustomColumn,
	ISerializedAssetInsightsTableSettings
} from './types';

import { IAssetInsightsCustomColumnsConfigMetadata } from '@/src/types/asset-insights';
import { buildAssetInsightsCustomColumnDef } from '@/src/utils/asset-insights/asset-insights-table';

const serializeAssetInsightsCustomColumns = (
	columnDefs: IColumnDef<AssetInsightsItem>[]
): ISerializedAssetInsightsCustomColumn[] =>
	columnDefs.reduce<ISerializedAssetInsightsCustomColumn[]>(
		(accumulator, { id, title, metadata, ...options }) => {
			accumulator.push({
				id,
				title: title as string,
				metadata: metadata as ICustomColumnMetadata,
				options: {
					hide: options.hide,
					pinned: options.pinned
				}
			});

			return accumulator;
		},
		[]
	);

const deserializeAssetInsightsCustomColumns = (
	serializedColumnDefs: ISerializedAssetInsightsCustomColumn[],
	configMetadata?: IAssetInsightsCustomColumnsConfigMetadata
): IColumnDef<AssetInsightsItem>[] =>
	serializedColumnDefs.reduce<IColumnDef<AssetInsightsItem>[]>(
		(accumulator, { id, title, metadata, options }) => {
			accumulator.push(
				buildAssetInsightsCustomColumnDef(id, title, metadata, options, configMetadata)
			);

			return accumulator;
		},
		[]
	);

export const serializeAssetInsightsTableSettings = <
	TTableSerialized = ITableSettings<AssetInsightsItem>
>(
	settings: ITableSettings<AssetInsightsItem> = {},
	serializer: (settings: ITableSettings<AssetInsightsItem>) => TTableSerialized = identity
): TTableSerialized =>
	serializer({
		...settings,
		columnsCustom: serializeAssetInsightsCustomColumns(settings.columnsCustom ?? [])
	});

export const deserializeAssetInsightsTableSettings = <
	TTableSerialized = ITableSettings<AssetInsightsItem>
>(
	settings: TTableSerialized,
	configMetadata?: IAssetInsightsCustomColumnsConfigMetadata,
	deserializer: (settings: TTableSerialized) => ISerializedAssetInsightsTableSettings = identity
): ITableSettings<AssetInsightsItem> => {
	const parsedSettings = deserializer(settings);

	return {
		...parsedSettings,
		columnsCustom: parsedSettings.columnsCustom
			? deserializeAssetInsightsCustomColumns(parsedSettings.columnsCustom, configMetadata)
			: []
	};
};
