import { EBadgeState } from '@kelvininc/react-ui-components';

import { ITab } from '@kelvininc/shared-ui';

import { ETableAdvancedFilterType, ITableAdvancedFilters } from '../../../../../../../AgGridTable';
import { IAdvancedFilterConfig, IAdvancedFiltersConfig } from '../../../../../../types';

import { FILTER_TAB_TILTE } from './config';

export const getFiltersTabs = (
	config: IAdvancedFiltersConfig,
	values: ITableAdvancedFilters = {}
): ITab<ETableAdvancedFilterType>[] => {
	return Object.values(config).map<ITab<ETableAdvancedFilterType>>(
		({ type }: IAdvancedFilterConfig) => {
			const typeFilters = values[type];

			return {
				key: type,
				title: FILTER_TAB_TILTE[type],
				badge: typeFilters?.length ? typeFilters.length.toString() : '',
				state: EBadgeState.Info
			};
		}
	);
};
