import classNames from 'classnames';
import { PropsWithChildren, useCallback } from 'react';

import styles from './styles.module.scss';

type PaginationButtonProps = {
	onClick?: () => void;
	disabled?: boolean;
	active?: boolean;
};

export const PaginationButton = ({
	onClick,
	disabled = false,
	active = false,
	children
}: PropsWithChildren<PaginationButtonProps>) => {
	const onClickButton = useCallback(() => !disabled && onClick?.(), [disabled, onClick]);

	return (
		<div
			className={classNames(styles.PaginationButton, {
				[styles.PaginationButtonActive]: active,
				[styles.PaginationButtonDisabled]: disabled
			})}
			onClick={onClickButton}>
			{children}
		</div>
	);
};
