import { isArray } from 'lodash-es';

import { IBaseTData } from '../../../core/components/AgGridTable';

import { ActionsCellButtons, IActionsCellButton } from './types';

export const getActionsButtonProps = <TData extends IBaseTData>(
	actions: ActionsCellButtons<TData>,
	data?: TData
): IActionsCellButton<TData>[] => {
	if (isArray(actions)) {
		return actions;
	} else if (actions && data) {
		return actions(data);
	}
	return [];
};
