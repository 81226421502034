import { EKvHttpStatusCode, FilestorageService } from '@kelvininc/node-client-sdk';
import {
	EActionButtonType,
	EComponentSize,
	EIconName,
	KvActionButtonIcon,
	KvActionButtonText,
	KvIcon,
	KvTooltip
} from '@kelvininc/react-ui-components';
import { getFileIdFromUrl } from '@kelvininc/tsutils';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

import { openAlertInModal } from '..';
import { Tip } from '../Tip';
import { openToaster } from '../ToasterObserver';

import { DROP_MESSAGE_STYLE, PREPARE_DOWNLOAD, PREPARE_DOWNLOAD_ERROR } from './config';
import styles from './styles.module.scss';

import { IDownloadFileInfo } from './types';
import {
	buildFileSizeErrorToaster,
	buildFileTypeErrorToaster,
	buildTipDescription,
	getFileDescription
} from './utils';

type UploadItemProps = {
	file: File | IDownloadFileInfo;
	disabled?: boolean;
	remove?: () => void;
	downloadStateChange?: (download: boolean) => void;
};

const UploadItem = ({ file, disabled, remove, downloadStateChange }: UploadItemProps) => {
	const [downloading, setDownloading] = useState(false);

	const downloadChange = useCallback(
		(isDownloading: boolean) => {
			setDownloading(isDownloading);
			downloadStateChange?.(isDownloading);
		},
		[downloadStateChange]
	);

	const fileInfoDownload = useCallback(
		(fileInfo: IDownloadFileInfo) => {
			const fileId = getFileIdFromUrl(fileInfo?.url);
			if (!fileId) return;

			downloadChange(true);
			try {
				FilestorageService.downloadFile({ fileId }).subscribe(
					(response) => {
						if (response.status === EKvHttpStatusCode.Ok) {
							saveAs(new Blob([response.body]), fileInfo.name);
						}
						downloadChange(false);
					},
					() => {
						openAlertInModal(PREPARE_DOWNLOAD_ERROR);
						downloadChange(false);
					}
				);
			} catch (error) {
				openAlertInModal(PREPARE_DOWNLOAD_ERROR);
				downloadChange(false);
			}
		},
		[downloadChange]
	);

	const downloadFile = useCallback(() => {
		if (file instanceof File) {
			saveAs(file);
		} else {
			fileInfoDownload(file);
		}
	}, [file, fileInfoDownload]);

	return (
		<div className={styles.UploadItemContainer}>
			<div className={styles.FileDetails}>
				<KvIcon name={EIconName.FileCheck} customClass="icon-20" />
				<KvTooltip text={downloading ? PREPARE_DOWNLOAD : file.name} truncate>
					<div className={styles.FileInfoTitle}>
						{downloading ? PREPARE_DOWNLOAD : file.name}
					</div>
				</KvTooltip>
			</div>
			<div className={styles.FileActions}>
				<KvActionButtonIcon
					type={EActionButtonType.Ghost}
					icon={EIconName.Download}
					disabled={disabled || downloading}
					onClickButton={() => downloadFile()}
				/>
				{remove && (
					<KvActionButtonIcon
						type={EActionButtonType.Ghost}
						icon={EIconName.Delete}
						disabled={disabled || downloading}
						onClickButton={() => remove()}
					/>
				)}
			</div>
		</div>
	);
};

type UploadFileProps = {
	file?: File | IDownloadFileInfo;
	templateFile?: File;
	documentationLink?: string;
	types?: string[];
	maxSize?: number;
	disabled?: boolean;
	onFileChange?: (file?: File) => void;
	onDownloadStateChange?: (download: boolean) => void;
};

export const UploadFile = ({
	file,
	templateFile,
	documentationLink,
	types,
	maxSize,
	disabled = false,
	onFileChange,
	onDownloadStateChange
}: UploadFileProps) => {
	const [downloading, setDownloading] = useState(false);
	const downloadTemplate = useCallback(() => {
		if (templateFile) {
			saveAs(templateFile, templateFile?.name);
		}
	}, [templateFile]);

	const removeFile = () => {
		onFileChange?.(undefined);
	};

	const onFileHandleError = () => {
		openToaster(buildFileTypeErrorToaster(types));
	};

	const onSizeFileHandleError = () => {
		openToaster(buildFileSizeErrorToaster(maxSize));
	};

	const uploadChanged = (fileUploaded: File) => {
		onFileChange?.(fileUploaded);
	};

	const downloadStateChange = (isDownloading: boolean) => {
		setDownloading(isDownloading);
		onDownloadStateChange?.(isDownloading);
	};

	return (
		<div className={classNames(styles.UploadFileContainer, { [styles.Disabled]: disabled })}>
			{documentationLink && <Tip>{buildTipDescription(documentationLink)}</Tip>}
			{templateFile && (
				<div className={styles.Template}>
					<span>For the best results, please build your file using this template.</span>
					<KvActionButtonText
						type={EActionButtonType.Tertiary}
						text="Download Template"
						icon={EIconName.Download}
						size={EComponentSize.Small}
						disabled={disabled || downloading}
						onClickButton={downloadTemplate}
					/>
				</div>
			)}
			<div className={styles.FileUploadContainer}>
				<FileUploader
					dropMessageStyle={DROP_MESSAGE_STYLE}
					hoverTitle=" "
					disabled={disabled || downloading}
					maxSize={maxSize}
					handleChange={uploadChanged}
					onSizeError={onSizeFileHandleError}
					onTypeError={onFileHandleError}
					types={types}>
					<div className={styles.DropArea}>
						<KvIcon name={EIconName.File} />
						<span className={styles.Label}>
							<u>Click to upload</u> or Drag and Drop your file here
						</span>
						<span className={styles.Description}>
							{getFileDescription(types, maxSize)}
						</span>
					</div>
				</FileUploader>
			</div>
			{file && (
				<UploadItem
					file={file}
					disabled={disabled || downloading}
					remove={removeFile}
					downloadStateChange={downloadStateChange}
				/>
			)}
		</div>
	);
};
