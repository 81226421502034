import { EAppType } from '@kelvininc/node-client-sdk';

import { BridgeAppDeployment } from './BridgeAppDeployment';
import { DockerAppDeployment } from './DockerAppDeployment';
import { KelvinAppDeployment } from './KelvinAppDeployment';
import { AppDeploymentServiceClass } from './types';

export const APP_DEPLOYMENT_SERVICES: { [K in EAppType]: AppDeploymentServiceClass } = {
	[EAppType.Docker]: new DockerAppDeployment(),
	[EAppType.Bridge]: new BridgeAppDeployment(),
	[EAppType.Kelvin]: new KelvinAppDeployment()
};
