'use client';
import classNames from 'classnames';

import { ValidationStatusIcon } from './components';
import styles from './styles.module.scss';

import { useRequirementValidation } from '@/src/hooks';
import { EValidationStep, IValidationItem } from '@/src/types';

type RequirementValidationListProps = {
	canStartValidation: boolean;
	finalRequirementsState: Record<string, IValidationItem[]>;
	onValidationComplete?: () => void;
};

export const RequirementValidationList = ({
	canStartValidation,
	finalRequirementsState,
	onValidationComplete
}: RequirementValidationListProps) => {
	const requirementsList: Record<string, IValidationItem[]> = useRequirementValidation(
		canStartValidation,
		finalRequirementsState,
		onValidationComplete
	);

	return (
		<div className={styles.ValidationItemsContainer}>
			{Object.entries(requirementsList).map(
				([validationItemKey, validationItems]) =>
					validationItems.filter((item) => !item.hidden).length > 0 && (
						<div key={validationItemKey}>
							<div className={styles.Title}>{validationItemKey}</div>
							{validationItems.map((item, index) => (
								<div
									key={`${validationItemKey}${index}`}
									className={styles.ItemWrapper}>
									<div
										className={classNames(styles.Description, {
											[styles.DescriptionInactive]:
												item.state === EValidationStep.Pending
										})}>
										{item.description}
									</div>
									{item.state !== EValidationStep.Pending && (
										<ValidationStatusIcon state={item.state} />
									)}
								</div>
							))}
						</div>
					)
			)}
		</div>
	);
};
