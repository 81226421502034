import { AssetInsightsItem } from '@kelvininc/node-client-sdk';

import { ITableSettings, ITablesSettings } from '@kelvininc/table';

import { ISerializedAssetInsightsTableSettings } from '../useAssetInsightsTableSettings/types';
import { IUseTableSettings } from '../useTableSettings';

import { useTablesSettings } from '../useTablesSettings';

import {
	deserializeAssetInsightsTablesSettings,
	serializeAssetInsightsTablesSettings
} from './utils';

import { assetInsightsTablesSettingsSettingAtomFamily } from '@/src/recoil/settings/atoms';

export const useAssetInsightsTablesSettings = (
	key: string,
	tableKey: string,
	defaultValue: ITableSettings<AssetInsightsItem> = {}
): IUseTableSettings<AssetInsightsItem> => {
	const atom = assetInsightsTablesSettingsSettingAtomFamily(key);

	return useTablesSettings(key, tableKey, defaultValue, atom, {
		serializer: (settings: ITablesSettings<AssetInsightsItem>) =>
			serializeAssetInsightsTablesSettings(settings),
		deserializer: (serializedSettings: Record<string, ISerializedAssetInsightsTableSettings>) =>
			deserializeAssetInsightsTablesSettings(serializedSettings)
	});
};
