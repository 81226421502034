import { IExpandableSetting } from '@kelvininc/types';

export const NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_KEY =
	'core.ui.navigation-sidebar.admin';
export const NAVIGATION_SIDEBAR_EXPANDED_ADMIN_USER_SETTING_DEFAULT: IExpandableSetting = {
	isExpanded: true
};

export const NAVIGATION_SIDEBAR_EXPANDED_CONTEXTUALIZATION_USER_SETTING_KEY =
	'core.ui.navigation-sidebar.contextualization';
export const NAVIGATION_SIDEBAR_EXPANDED_CONTEXTUALIZATION_USER_SETTING_DEFAULT: IExpandableSetting =
	{
		isExpanded: true
	};

export const NAVIGATION_SIDEBAR_EXPANDED_ORCHESTRATION_USER_SETTING_KEY =
	'core.ui.navigation-sidebar.orchestration';
export const NAVIGATION_SIDEBAR_EXPANDED_ORCHESTRATION_USER_SETTING_DEFAULT: IExpandableSetting = {
	isExpanded: true
};

export const NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_KEY =
	'core.ui.navigation-sidebar.operations';
export const NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_DEFAULT: IExpandableSetting = {
	isExpanded: true
};

export const NAVIGATION_SIDEBAR_EXPANDED_KELVIN_AI_USER_SETTING_KEY =
	'core.ui.navigation-sidebar.kelvin-ai';
export const NAVIGATION_SIDEBAR_EXPANDED_KELVIN_AI_USER_SETTING_DEFAULT: IExpandableSetting = {
	isExpanded: true
};
