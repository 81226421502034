import {
	DataStream,
	DataStreamContext,
	DataStreamDataType,
	DataStreamSemanticType,
	Unit
} from '@kelvininc/node-client-sdk';
import { atom, atomFamily } from 'recoil';

import {
	getAssetDatastreamContextsRequester,
	getDatastreamContextsRequester,
	getDatastreamUnitsRequester,
	getDatastreamsDataTypes,
	getDatastreamsRequester,
	getDatastreamsSemanticTypes
} from './requests';

export const datastreamsListAtom = atom<DataStream[]>({
	key: 'datastreamsListAtom',
	effects: [({ setSelf }) => setSelf(getDatastreamsRequester())]
});

export const datastreamsUnitListAtom = atom<Unit[]>({
	key: 'datastreamsUnitListAtom',
	effects: [({ setSelf }) => setSelf(getDatastreamUnitsRequester())]
});

export const datastreamsSemanticTypeListAtom = atom<DataStreamSemanticType[]>({
	key: 'datastreamsSemanticTypeListAtom',
	effects: [({ setSelf }) => setSelf(getDatastreamsSemanticTypes())]
});

export const datastreamsDataTypeListAtom = atom<DataStreamDataType[]>({
	key: 'datastreamsDataTypeListAtom',
	effects: [({ setSelf }) => setSelf(getDatastreamsDataTypes())]
});

export const datastreamContextsListAtom = atom<DataStreamContext[]>({
	key: 'datastreamContextsListAtom',
	effects: [({ setSelf }) => setSelf(getDatastreamContextsRequester())]
});

export const assetDatastreamContextsListAtomFamily = atomFamily<DataStreamContext[], string>({
	key: 'assetDatastreamContextsListAtomFamily',
	effects: (id: string) => [({ setSelf }) => setSelf(getAssetDatastreamContextsRequester(id))]
});
