'use client';
import { Boundaries, Scrollable } from '@kelvininc/shared-ui';
import { ClassNamesProp } from '@kelvininc/types';
import classNames from 'classnames';
import { ComponentProps, PropsWithChildren, useMemo } from 'react';

import styles from './styles.module.scss';

type LayoutProps = {
	header?: JSX.Element;
	footer?: JSX.Element;
	boundariesProps?: ComponentProps<typeof Boundaries>;
	customClasses?: ClassNamesProp;
	showHeader?: boolean;
	showFooter?: boolean;
	showSidebar?: boolean;
};

export const Layout = ({
	header,
	footer,
	children,
	customClasses,
	boundariesProps = {},
	showHeader = true,
	showFooter = true
}: PropsWithChildren<LayoutProps>) => {
	const hasHeader = useMemo(() => header !== undefined && showHeader, [header, showHeader]);
	const hasFooter = useMemo(() => footer !== undefined && showFooter, [footer, showFooter]);

	return (
		<Scrollable customClasses={styles.Scrollable}>
			<div className={styles.LayoutContainer}>
				<div className={styles.Layout}>
					{hasHeader && <div className={styles.LayoutHeader}>{header}</div>}
					<Boundaries {...boundariesProps}>
						<div className={classNames(styles.LayoutContent, customClasses)}>
							{children}
						</div>
					</Boundaries>
					{hasFooter && (
						<div data-test-id="e2e-layout-footer" className={styles.LayoutFooter}>
							{footer}
						</div>
					)}
				</div>
			</div>
		</Scrollable>
	);
};
