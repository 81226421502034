import { DragStart, DragUpdate, DropResult } from '@hello-pangea/dnd';

import { DraggableItemProps } from '../DraggableItem';

export interface IDraggableItem extends Omit<DraggableItemProps, 'dragging'> {
	id: string;
	index: number;
	isDragDisabled?: boolean;
}

export interface IDroppableZoneApi {
	onDragStart: (event: DragStart, data: IDraggableItem[]) => void;
	onDragUpdate: (event: DragUpdate, data: IDraggableItem[]) => void;
	onDragEnd: (event: DropResult) => void;
}

export enum EDraggableItemsSpacing {
	Small = 'Small',
	Normal = 'Normal'
}
