import { useProgressPercentage } from '../../../hooks';

import styles from './styles.module.scss';

export const LoadingOverlay = () => {
	const percentage = useProgressPercentage();

	return (
		<div data-test-id="e2e-loading-overlay" className={styles.LoadingOverlay}>
			<div className={styles.Container}>
				<div className={styles.Indicator}>
					<div className={styles.Progress} style={{ width: `${percentage}%` }} />
				</div>
			</div>
		</div>
	);
};
