import { DraggableItemPlaceholder, DraggableItemPlaceholderProps } from '@kelvininc/shared-ui';

import styles from './styles.module.scss';

export const ColumnsPlaceholder = ({ text }: DraggableItemPlaceholderProps) => {
	if (text) {
		return <DraggableItemPlaceholder text={text} />;
	}

	return <div className={styles.ColumnPlaceholder} />;
};
