import { DataStream } from '@kelvininc/node-client-sdk';

import { KvSingleSelectDropdown } from '@kelvininc/react-ui-components';
import {
	buildDropdownOptionsFromArray,
	buildRelativeTimeRange,
	searchDropdownOptions
} from '@kelvininc/tsutils';
import { DataStreamAggregationFunction, EDataStreamCustomAgg, ReactState } from '@kelvininc/types';
import { useCallback, useMemo, useState } from 'react';

import { IDatastreamColumnData } from '../../../../../../../AgGridTable';

import {
	DATASTREAM_COLUMN_AGGREGATION_FUNCTION_COLUMN_LABEL,
	DATASTREAM_COLUMN_AGGREGATION_FUNCTION_COLUMN_PLACEHOLDER,
	DATASTREAM_COLUMN_NAME_LABEL,
	DATASTREAM_COLUMN_NAME_PLACEHOLDER,
	DATASTREAM_COLUMN_TIME_RANGE_COLUMN_LABEL,
	DATASTREAM_COLUMN_TIME_RANGE_COLUMN_PLACEHOLDER,
	DATASTREAM_COLUMN_TIME_RANGE_DROPDOWN_OPTIONS
} from './config';
import styles from './styles.module.scss';

import { buildFiltersAggregationFunctions } from './utils';

type DatastreamsSettings = {
	state: ReactState<IDatastreamColumnData>;
	datastreams?: Record<string, DataStream>;
};

export const DatastreamsSettings = ({ state, datastreams = {} }: DatastreamsSettings) => {
	const [settings, setState] = state;
	const [searchTerm, setSearchTerm] = useState<string | null>(null);
	const datastreamsList = Object.values(datastreams);

	const onSelectDatastream = useCallback(
		({ detail: newDatastreamName }: CustomEvent<string>) =>
			setState((previousState) => ({ ...previousState, datastream: newDatastreamName })),
		[setState]
	);
	const onSelectAggregation = useCallback(
		({ detail: newAggregation }: CustomEvent<string>) =>
			setState((previousState) => ({
				...previousState,
				aggregation: newAggregation as DataStreamAggregationFunction
			})),
		[setState]
	);
	const onSelectRange = useCallback(
		({ detail: newRange }: CustomEvent<string>) =>
			setState((previousState) => ({
				...previousState,
				range: buildRelativeTimeRange(newRange)
			})),
		[setState]
	);
	const onSearchChange = useCallback(
		({ detail: searchedDatastream }: CustomEvent<string>) => setSearchTerm(searchedDatastream),
		[]
	);

	const datastreamOptions = useMemo(
		() =>
			buildDropdownOptionsFromArray({
				data: datastreamsList,
				key: 'name',
				label: 'title',
				value: 'name'
			}),
		[datastreamsList]
	);
	const filteredDatastreamOptions = useMemo(() => {
		if (searchTerm !== null && searchTerm.length > 0) {
			return searchDropdownOptions(searchTerm, datastreamOptions);
		}

		return datastreamOptions;
	}, [datastreamOptions, searchTerm]);

	const aggregationFunctionsOptions = useMemo(
		() => buildFiltersAggregationFunctions(settings.datastream, datastreams),
		[datastreams, settings.datastream]
	);

	const selectedTime = useMemo(() => {
		return settings?.range?.time.payload?.key;
	}, [settings]);

	const isTimeDropdownDisabled = useMemo(() => {
		if (!settings.datastream || !settings.aggregation) {
			return true;
		}

		if (settings.aggregation === EDataStreamCustomAgg.Last) {
			return true;
		}
		return false;
	}, [settings.aggregation, settings.datastream]);

	return (
		<div className={styles.DatastreamSettings}>
			<KvSingleSelectDropdown
				required
				placeholder={DATASTREAM_COLUMN_NAME_PLACEHOLDER}
				label={DATASTREAM_COLUMN_NAME_LABEL}
				options={datastreamOptions}
				filteredOptions={filteredDatastreamOptions}
				selectedOption={settings.datastream}
				onOptionSelected={onSelectDatastream}
				onSearchChange={onSearchChange}
				searchable
			/>
			<KvSingleSelectDropdown
				required
				placeholder={DATASTREAM_COLUMN_AGGREGATION_FUNCTION_COLUMN_PLACEHOLDER}
				label={DATASTREAM_COLUMN_AGGREGATION_FUNCTION_COLUMN_LABEL}
				options={aggregationFunctionsOptions}
				selectedOption={settings.aggregation}
				onOptionSelected={onSelectAggregation}
				searchable={false}
			/>
			<KvSingleSelectDropdown
				required={!isTimeDropdownDisabled}
				placeholder={DATASTREAM_COLUMN_TIME_RANGE_COLUMN_PLACEHOLDER}
				label={DATASTREAM_COLUMN_TIME_RANGE_COLUMN_LABEL}
				disabled={isTimeDropdownDisabled}
				options={DATASTREAM_COLUMN_TIME_RANGE_DROPDOWN_OPTIONS}
				selectedOption={selectedTime}
				onOptionSelected={onSelectRange}
				searchable={false}
			/>
		</div>
	);
};
