import { isEmpty } from 'lodash-es';
import { PropsWithChildren, useMemo } from 'react';

import { TableTooltip } from '../../../../../core/components';
import { IBaseTData, ICellRendererParams } from '../../../../../core/components/AgGridTable';

import { DEFAULT_EMPTY_CELL_LABEL, DEFAULT_EMPTY_CELL_TOOLTIP_LABEL } from './config';
import styles from './styles.module.scss';
import { useTooltipCellValue } from './utils';

export const CellTooltipContainer = <TData extends IBaseTData>(
	props: PropsWithChildren<ICellRendererParams<TData>>
) => {
	const {
		value,
		valueFormatted,
		hasCellTooltip = false,
		tooltipTruncate = true,
		displayEmptyState = true,
		tooltipPosition,
		children,
		eGridCell,
		placeholder
	} = props;
	const cellValue = useMemo(() => valueFormatted ?? value, [valueFormatted, value]);
	const tooltipCellValue = useTooltipCellValue(props);

	const shouldDisplayEmptyState = useMemo(
		() => displayEmptyState && isEmpty(cellValue?.toString()),
		[cellValue, displayEmptyState]
	);

	if (hasCellTooltip || shouldDisplayEmptyState) {
		return (
			<TableTooltip
				text={
					tooltipCellValue ||
					(shouldDisplayEmptyState && !placeholder
						? DEFAULT_EMPTY_CELL_TOOLTIP_LABEL
						: '')
				}
				truncate={tooltipTruncate}
				position={tooltipPosition}
				element={eGridCell}
				customClasses={styles.CellTooltip}>
				{!shouldDisplayEmptyState ? (
					<>{children}</>
				) : (
					<div>
						{placeholder ? (
							<span className={styles.Placeholder}>{placeholder}</span>
						) : (
							DEFAULT_EMPTY_CELL_LABEL
						)}
					</div>
				)}
			</TableTooltip>
		);
	}

	return <>{children}</>;
};
