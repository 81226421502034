import { Column, ILoadingCellRendererParams } from '@ag-grid-community/core';
import { Skeleton, useDebounce } from '@kelvininc/shared-ui';
import { useCallback, useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

export const LoadingCellRenderer = (props?: ILoadingCellRendererParams) => {
	const isDestroyed = useRef(false);
	const [columns, setColumns] = useState<Column[]>([]);

	const onColumnResize = useCallback(() => {
		if (!isDestroyed.current) {
			setColumns(props?.api.getColumns() ?? []);
		}
	}, [props?.api]);

	const columnResizeFn = useDebounce(onColumnResize);

	useEffect(() => {
		if (!props) return;

		onColumnResize();
		props.api.addEventListener('columnResized', columnResizeFn);

		return () => {
			isDestroyed.current = true;
			if (!props.api.isDestroyed()) {
				props.api.removeEventListener('columnResized', () => null);
			}
		};
	}, [columnResizeFn, onColumnResize, props]);

	return (
		<div className={styles.LoadingCellContainer}>
			{columns.map((column) => (
				<div
					key={column.getUniqueId()}
					className={styles.Rect}
					style={{ width: column.getActualWidth() }}>
					<Skeleton />
				</div>
			))}
		</div>
	);
};
