import { KELVIN_PERMISSIONS as Permission } from '@kelvininc/node-client-sdk';
import { KelvinPermission } from '@kelvininc/types';

import { EPlatformArea } from '@/src/types';

export const AREA_PERMISSIONS: Record<EPlatformArea, KelvinPermission[]> = {
	[EPlatformArea.Admin]: [
		Permission.AppRegistry.Read,
		Permission.Appmanager.Read,
		Permission.AssetInsights.Read,
		Permission.Asset.Read,
		Permission.AssetType.Read,
		Permission.ControlChange.Read,
		Permission.Datastreams.Read,
		Permission.Datatag.Read,
		Permission.Instance.Read,
		Permission.Parameter.Read,
		Permission.Recommendation.Read,
		Permission.Threads.Create,
		Permission.Threads.Read,
		Permission.Users.Delete,
		Permission.Users.Read,
		Permission.Users.Update,
		Permission.AuditLog.Read,
		Permission.Bridge.Read,
		Permission.Cluster.Read,
		Permission.Storage.Read,
		Permission.Workload.Read
		// Permission.Authorization.Groups, // TODO: uncomment when all users have this permission
		// Permission.Authorization.Roles // TODO: uncomment when all users have this permission
	],
	[EPlatformArea.Operations]: [
		Permission.AppRegistry.Read,
		Permission.Appmanager.Read,
		Permission.AssetInsights.Read,
		Permission.Asset.Read,
		Permission.AssetType.Read,
		Permission.ControlChange.Read,
		Permission.Datastreams.Read,
		Permission.Datatag.Read,
		Permission.Instance.Read,
		Permission.Parameter.Read,
		Permission.Recommendation.Read,
		Permission.Threads.Create,
		Permission.Threads.Read,
		Permission.Users.Delete,
		Permission.Users.Read,
		Permission.Users.Update
	]
};
