import { ITab } from '@kelvininc/shared-ui';
import { isEmpty } from 'lodash-es';

import { IRowsSettingsConfig } from '../../../../types';

import { ETableManagementTab } from './types';

export const getTableManagementTabs = (
	settings?: IRowsSettingsConfig
): ITab<ETableManagementTab>[] => {
	const tabs: ITab<ETableManagementTab>[] = [
		{
			key: ETableManagementTab.Columns,
			title: 'Columns'
		}
	];

	if (!isEmpty(settings)) {
		tabs.push({
			key: ETableManagementTab.Rows,
			title: 'Rows'
		});
	}

	return tabs;
};
