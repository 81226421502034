import {
	EKvHttpStatusCode,
	EPaginatorType,
	EParameterType,
	ParameterDefinitionItem,
	ParametersService
} from '@kelvininc/node-client-sdk';
import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { IApplicationParameterDefinition, IApplicationParameterValues } from '@kelvininc/types';
import { selector } from 'recoil';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

export const applicationParameterDefinitionsMapSelector = selector<IApplicationParameterDefinition>(
	{
		key: 'applicationParameterDefinitionsMapSelector',
		get: ({ get }) => {
			const definitionsList = get(applicationParameterDefinitionsListSelector);
			return definitionsList.reduce<IApplicationParameterDefinition>(
				(acc, definitionItem) => {
					acc[definitionItem.appName] = {
						...acc[definitionItem.appName],
						[definitionItem.name]: definitionItem
					};
					return acc;
				},
				{}
			);
		}
	}
);

export const applicationParameterDefinitionsListSelector = selector<ParameterDefinitionItem[]>({
	key: 'applicationParameterDefinitionsListSelector',
	get: () =>
		ParametersService.listParametersDefinitions({
			parametersDefinitionsListData: {},
			paginationType: EPaginatorType.Stream
		})
			.pipe(
				catchHttpError((error) => {
					if (error.status === EKvHttpStatusCode.NotFound) {
						return of([]);
					}

					return throwHttpError(error);
				})
			)
			.toPromise()
});

export const applicationParameterValuesMapSelector = selector<IApplicationParameterValues>({
	key: 'applicationParameterValuesMapSelector',
	get: () =>
		ParametersService.getParametersValues({
			parametersValuesGetData: {
				primitive_types: [
					EParameterType.Boolean,
					EParameterType.RawBoolean,
					EParameterType.String,
					EParameterType.RawText
				]
			}
		})
			.pipe(
				map(({ appParameterValues }) => appParameterValues as IApplicationParameterValues),
				catchHttpError((error) => {
					if (error.status === EKvHttpStatusCode.NotFound) {
						return of({});
					}

					return throwHttpError(error);
				})
			)
			.toPromise()
});
