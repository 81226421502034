import { EmptyOverlay } from '../EmptyOverlay';
import { ErrorOverlay } from '../ErrorOverlay';
import { LoadingOverlay } from '../LoadingOverlay';
import { NoFiltersResultsOverlay } from '../NoFiltersResultsOverlay';
import { NoResultsOverlay } from '../NoResultsOverlay';
import { EDataState, OverlaysComponents } from '../types';

export const DEFAULT_OVERLAYS_COMPONENTS: OverlaysComponents = {
	[EDataState.IsEmpty]: EmptyOverlay,
	[EDataState.IsLoading]: LoadingOverlay,
	[EDataState.NoResults]: NoResultsOverlay,
	[EDataState.NoFiltersResults]: NoFiltersResultsOverlay,
	[EDataState.HasError]: ErrorOverlay
};
