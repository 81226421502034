import {
	EGuardrailRelativeValueType,
	EKrnResource,
	Guardrail,
	GuardrailAllOfNumberAllOfMin,
	GuardrailCommonConfigRelativeIncrease,
	GuardrailValueUpdater,
	IBulkGuardrailsCreateData,
	IGuardrailCommonConfigRelative,
	IGuardrailConfig,
	IGuardrailConfigWithResource,
	IGuardrailRelativeValue,
	IGuardrailUpdater,
	IGuardrailValue,
	KvKRNParser
} from '@kelvininc/node-client-sdk';
import { EToasterType, type IToaster } from '@kelvininc/react-ui-components';
import { RecursivePartial } from '@kelvininc/types';
import { isNil, merge } from 'lodash-es';
import pluralize from 'pluralize';
import { DefaultValues } from 'react-hook-form';

import { OPERATION_ERROR_TOASTER_CONFIGS, OPERATION_SUCCESS_TOASTER_CONFIGS } from './config';
import { EGuardrailOperationType, GuardrailOperation, GuardrailOperationResult } from './types';

import {
	NON_NUMERIC_GUARDRAIL_DEFAULT_VALUES,
	NUMERIC_GUARDRAIL_DEFAULT_VALUES
} from '@/src/components/client/GuardrailCraftWizard/config';
import {
	EGuardrailCraftWizardValueType,
	EGuardrailValueType,
	GuardrailCraftWizardFormValues,
	GuardrailIncreaseDecrease,
	GuardrailMinMax,
	NumericGuardrailCraftWizardFormValues
} from '@/src/components/client/GuardrailCraftWizard/types';
import { ImportGuardrailsWizardFormValues } from '@/src/components/client/ImportGuardrailsWizard/types';

export const buildOperationSuccessToaster = (
	operation: GuardrailOperation,
	result: GuardrailOperationResult
): IToaster => {
	const config = OPERATION_SUCCESS_TOASTER_CONFIGS[operation.type];

	if (operation.type === EGuardrailOperationType.Import && 'data' in result) {
		return {
			...config,
			header: `${pluralize('Guardrail', result.data?.length)} created successfully.`,
			type: EToasterType.Success
		};
	}

	if (operation.type === EGuardrailOperationType.BulkDelete) {
		return {
			...config,
			header: `${pluralize('Guardrail', operation.payload.resources?.length)} deleted successfully.`,
			type: EToasterType.Success
		};
	}

	return {
		...config,
		type: EToasterType.Success
	};
};

export const buildOperationErrorToaster = (operation: GuardrailOperation): IToaster => {
	const config = OPERATION_ERROR_TOASTER_CONFIGS[operation.type];

	if (operation.type === EGuardrailOperationType.Import) {
		return {
			...config,
			header: pluralize(config.header),
			type: EToasterType.Error
		};
	}

	if (operation.type === EGuardrailOperationType.BulkDelete) {
		return {
			...config,
			header: pluralize(config.header, operation.payload.resources?.length),
			type: EToasterType.Error
		};
	}

	return {
		...config,
		type: EToasterType.Error
	};
};

export const buildCreateGuardrailPayload = (
	form: GuardrailCraftWizardFormValues
): IGuardrailConfigWithResource => {
	const { asset, datastream, control } = form;

	const resource = KvKRNParser.buildKRN(EKrnResource.AssetDatastream, { asset, datastream });

	if (form.type === EGuardrailCraftWizardValueType.NonNumeric) {
		return {
			resource,
			control_disabled: !!!control
		};
	}

	const { value } = form;

	return {
		resource,
		control_disabled: !!!control,
		updater: buildGuardrailUpdaterConfig(value, asset),
		number: {
			relative: buildGuardrailRelativeConfig(value),
			max: buildGuardrailStaticValueConfig(value.max),
			min: buildGuardrailStaticValueConfig(value.min)
		}
	};
};

export const buildEditGuardrailPayload = (
	form: GuardrailCraftWizardFormValues
): IGuardrailConfig => {
	const { resource: _resource, ...otherProps } = buildCreateGuardrailPayload(form);

	return otherProps;
};

export const buildImportGuardrailsPayload = (
	form: ImportGuardrailsWizardFormValues
): IBulkGuardrailsCreateData => {
	return {
		guardrails: form.guardrails.map(buildCreateGuardrailPayload)
	};
};

export const buildGuardrailFormValues = (
	guardrail: Guardrail
): DefaultValues<GuardrailCraftWizardFormValues> => {
	const {
		resourceContent: { asset: assetName, datastream: datastreamName }
	} = KvKRNParser.parseKRN<EKrnResource.AssetDatastream>(
		guardrail.resource,
		EKrnResource.AssetDatastream
	);

	const isTypeNumber = !isNil(guardrail.number);
	if (!isTypeNumber) {
		return merge({}, NON_NUMERIC_GUARDRAIL_DEFAULT_VALUES, {
			asset: assetName,
			datastream: datastreamName,
			control: !!!guardrail.controlDisabled
		});
	}

	const { number, updater } = guardrail;
	const { max, min, relative } = number ?? {};

	return merge({}, NUMERIC_GUARDRAIL_DEFAULT_VALUES, {
		asset: assetName,
		datastream: datastreamName,
		control: !!!guardrail.controlDisabled,
		value: {
			max: buildGuardrailMaxMinValue(max, updater?.number?.max),
			min: buildGuardrailMaxMinValue(min, updater?.number?.min),
			increase: buildGuardrailIncreaseDecreaseValue(relative?.increase),
			decrease: buildGuardrailIncreaseDecreaseValue(relative?.decrease)
		}
	});
};

const buildGuardrailMaxMinValue = (
	value?: GuardrailAllOfNumberAllOfMin,
	updater?: GuardrailValueUpdater
): RecursivePartial<GuardrailMinMax> => {
	if (updater) {
		const {
			resourceContent: { datastream }
		} = KvKRNParser.parseKRN<EKrnResource.AssetDatastream>(
			updater.source,
			EKrnResource.AssetDatastream
		);

		return {
			datastream: {
				value: datastream,
				inclusive: updater.inclusive
			},
			type: EGuardrailValueType.Datastream
		};
	}

	if (value) {
		return {
			number: {
				value: value.value,
				inclusive: value.inclusive
			},
			type: EGuardrailValueType.Number
		};
	}

	return {
		number: {
			inclusive: true
		},
		type: EGuardrailValueType.Number
	};
};

const buildGuardrailIncreaseDecreaseValue = (
	value?: GuardrailCommonConfigRelativeIncrease
): GuardrailIncreaseDecrease => {
	let config: GuardrailIncreaseDecrease = {
		min: {
			number: {
				inclusive: true
			},
			type: EGuardrailValueType.Number
		},
		max: {
			number: {
				inclusive: true
			},
			type: EGuardrailValueType.Number
		}
	};

	if (!value) {
		return config;
	}

	if (value.min) {
		config = merge({}, config, {
			min:
				value.min.type === EGuardrailRelativeValueType.Percentage
					? {
							percentage: {
								value: value.min.value,
								inclusive: value.min.inclusive
							},
							type: EGuardrailValueType.Percentage
						}
					: {
							number: {
								value: value.min.value,
								inclusive: value.min.inclusive
							},
							type: EGuardrailValueType.Number
						}
		});
	}

	if (value.max) {
		config = merge({}, config, {
			max:
				value.max.type === EGuardrailRelativeValueType.Percentage
					? {
							percentage: {
								value: value.max.value,
								inclusive: value.max.inclusive
							},
							type: EGuardrailValueType.Percentage
						}
					: {
							number: {
								value: value.max.value,
								inclusive: value.max.inclusive
							},
							type: EGuardrailValueType.Number
						}
		});
	}

	return config;
};

const buildGuardrailUpdaterConfig = (
	value: NumericGuardrailCraftWizardFormValues['value'],
	assetName: string
): IGuardrailUpdater | undefined => {
	if (!value) {
		return;
	}

	let config: IGuardrailUpdater | undefined;
	const { max, min } = value;

	if (max.type === EGuardrailValueType.Datastream) {
		const source = KvKRNParser.buildKRN(EKrnResource.AssetDatastream, {
			asset: assetName,
			datastream: max.datastream.value
		});

		config = merge({}, config, {
			number: {
				max: {
					source,
					inclusive: max.datastream.inclusive
				}
			}
		});
	}

	if (min.type === EGuardrailValueType.Datastream) {
		const source = KvKRNParser.buildKRN(EKrnResource.AssetDatastream, {
			asset: assetName,
			datastream: min.datastream.value
		});

		config = merge({}, config, {
			number: {
				min: {
					source,
					inclusive: min.datastream.inclusive
				}
			}
		});
	}

	return config;
};

const buildGuardrailRelativeValue = (
	value: number,
	type: EGuardrailValueType,
	inclusive: boolean
): IGuardrailRelativeValue => {
	if (type === EGuardrailValueType.Percentage) {
		return {
			value: value,
			type: EGuardrailRelativeValueType.Percentage,
			inclusive: inclusive
		};
	}

	return {
		value: value,
		type: EGuardrailRelativeValueType.Value,
		inclusive: inclusive
	};
};

const buildGuardrailRelativeConfig = (
	value: NumericGuardrailCraftWizardFormValues['value']
): IGuardrailCommonConfigRelative | undefined => {
	if (!value) {
		return;
	}

	let config: IGuardrailCommonConfigRelative = {};
	const { increase, decrease } = value;

	if (increase) {
		const { max, min } = increase;

		if (max.type === EGuardrailValueType.Percentage) {
			const maxValue = max[max.type];
			if (maxValue?.value !== undefined) {
				config = merge({}, config, {
					increase: {
						max: buildGuardrailRelativeValue(
							maxValue.value,
							max.type,
							maxValue.inclusive
						)
					}
				});
			}
		} else {
			const maxValue = max[max.type];
			if (maxValue?.value !== undefined) {
				config = merge({}, config, {
					increase: {
						max: buildGuardrailRelativeValue(
							maxValue.value,
							max.type,
							maxValue.inclusive
						)
					}
				});
			}
		}

		if (min.type === EGuardrailValueType.Percentage) {
			const minValue = min[min.type];
			if (minValue?.value !== undefined) {
				config = merge({}, config, {
					increase: {
						min: buildGuardrailRelativeValue(
							minValue.value,
							min.type,
							minValue.inclusive
						)
					}
				});
			}
		} else {
			const minValue = min[min.type];
			if (minValue?.value !== undefined) {
				config = merge({}, config, {
					increase: {
						min: buildGuardrailRelativeValue(
							minValue.value,
							min.type,
							minValue.inclusive
						)
					}
				});
			}
		}
	}

	if (decrease) {
		const { max, min } = decrease;

		if (max.type === EGuardrailValueType.Percentage) {
			const maxValue = max[max.type];
			if (maxValue?.value !== undefined) {
				config = merge({}, config, {
					decrease: {
						max: buildGuardrailRelativeValue(
							maxValue.value,
							max.type,
							maxValue.inclusive
						)
					}
				});
			}
		} else {
			const maxValue = max[max.type];
			if (maxValue?.value !== undefined) {
				config = merge({}, config, {
					decrease: {
						max: buildGuardrailRelativeValue(
							maxValue.value,
							max.type,
							maxValue.inclusive
						)
					}
				});
			}
		}

		if (min.type === EGuardrailValueType.Percentage) {
			const minValue = min[min.type];
			if (minValue?.value !== undefined) {
				config = merge({}, config, {
					decrease: {
						min: buildGuardrailRelativeValue(
							minValue.value,
							min.type,
							minValue.inclusive
						)
					}
				});
			}
		} else {
			const minValue = min[min.type];
			if (minValue?.value !== undefined) {
				config = merge({}, config, {
					decrease: {
						min: buildGuardrailRelativeValue(
							minValue.value,
							min.type,
							minValue.inclusive
						)
					}
				});
			}
		}
	}

	return config;
};

const buildGuardrailStaticValueConfig = (value?: GuardrailMinMax): IGuardrailValue | undefined => {
	if (!value || value.type !== EGuardrailValueType.Number) {
		return;
	}

	return {
		value: value.number.value,
		inclusive: value.number.inclusive
	};
};
