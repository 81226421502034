import { useAtomValue } from 'jotai';

import { appSettingsAtom } from '@/src/state';
import { CoreAppSettings } from '@/src/types';

export const useApplicationService = (): CoreAppSettings => {
	const appSettings = useAtomValue(appSettingsAtom);

	return appSettings;
};
