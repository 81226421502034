import {
	EActionButtonType,
	EAlertType,
	EIconName,
	KvActionButtonText
} from '@kelvininc/react-ui-components';
import { IExpandableApi, Panel, usePanelsManager, useUpdate } from '@kelvininc/shared-ui';
import { removeArrayItem, updateArrayItem } from '@kelvininc/tsutils';

import { isUndefined, merge } from 'lodash-es';
import { Dispatch, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import {
	ECustomColumnType,
	IAssetPropertyColumnData,
	IBaseTData,
	IColumnDef,
	IColumnOptions,
	IDatastreamColumnData,
	ILastControlChangeColumnData,
	IRecommendationColumnData
} from '../../../../../AgGridTable';
import { getColumnOptions } from '../../../../../AgGridTable/utils';
import { DEFAULT_MANAGEMENT_TABLE_ALERT } from '../../../../config';
import { useTableManagementEditColumnContext } from '../../../../contexts/TableManagementEditColumnContext';
import { useTableManagementStateContext } from '../../../../contexts/TableManagementStateContext';
import {
	EManagementPanel,
	ICustomColumnsConfig,
	IPanelConfig,
	TableProps
} from '../../../../types';
import {
	DEFAULT_APPLICATION_PARAMETER_DATA,
	DEFAULT_ASSET_PROPERTY_DATA,
	DEFAULT_DATASTREAM_DATA,
	DEFAULT_LAST_CONTROL_CHANGE_DATA,
	DEFAULT_RECOMMENDATION_DATA,
	DEFAULT_SCHEDULE_APPLICATION_PARAMETERS_DATA
} from '../../config';
import { buildColumnDef, isTitleValid, validateData } from '../../utils';
import { COLUMN_TITLE_UNIQUE_ERROR } from '../AddColumnPanel/config';

import { ColumnForm } from '../ColumnForm';

import styles from './styles.module.scss';
import { getEditedColumnIndex, hasColumnChanges } from './utils';

type EditColumnPanelProps<TData extends IBaseTData> = {
	setColumnDefs: Dispatch<React.SetStateAction<IColumnDef<TData>[]>>;
	columnsConfig: ICustomColumnsConfig<TData>;
	panelConfig: IPanelConfig;
} & Pick<TableProps<TData>, 'columnDefs'>;

export const EditColumnPanel = <TData extends IBaseTData>({
	setColumnDefs,
	columnDefs: totalColumnDefs,
	columnsConfig,
	panelConfig
}: EditColumnPanelProps<TData>) => {
	const { push } = usePanelsManager();
	const { alert, closeSidePanel, setAlert } = useTableManagementStateContext();
	const {
		editColumn: [editColumn],
		hasEditColumnChanges: [hasEditColumnChanges, setHasEditColumnChanges]
	} = useTableManagementEditColumnContext<TData>();

	const [columnType, setColumnType] = useState<ECustomColumnType | undefined>(
		editColumn?.metadata?.type
	);
	const [columnOptions, setColumnOptions] = useState<IColumnOptions<TData>>(
		editColumn ? getColumnOptions(editColumn) : {}
	);
	const [columnTitle, setColumnTitle] = useState<string | undefined>(editColumn?.title);
	const [assetPropertyData, setAssetPropertyData] = useState<IAssetPropertyColumnData>(
		editColumn?.metadata && editColumn.metadata.type === ECustomColumnType.AssetProperty
			? editColumn.metadata.data
			: DEFAULT_ASSET_PROPERTY_DATA
	);
	const [datastreamData, setDatastreamData] = useState<IDatastreamColumnData>(
		editColumn?.metadata && editColumn.metadata.type === ECustomColumnType.Datastream
			? editColumn.metadata.data
			: DEFAULT_DATASTREAM_DATA
	);
	const [lastControlChangeData, setLastControlChangeData] =
		useState<ILastControlChangeColumnData>(
			editColumn?.metadata && editColumn.metadata.type === ECustomColumnType.LastControlChange
				? editColumn.metadata.data
				: DEFAULT_LAST_CONTROL_CHANGE_DATA
		);
	const [recommendationData, setRecommendationData] = useState<IRecommendationColumnData>(
		editColumn?.metadata && editColumn.metadata.type === ECustomColumnType.Recommendation
			? editColumn.metadata.data
			: DEFAULT_RECOMMENDATION_DATA
	);
	const [applicationParameterData, setApplicationParameterData] =
		useState<IRecommendationColumnData>(
			editColumn?.metadata &&
				editColumn.metadata.type === ECustomColumnType.ApplicationParameter
				? editColumn.metadata.data
				: DEFAULT_APPLICATION_PARAMETER_DATA
		);
	const [scheduleApplicationParametersData, setScheduleApplicationParametersData] =
		useState<IRecommendationColumnData>(
			editColumn?.metadata &&
				editColumn.metadata.type === ECustomColumnType.ScheduleApplicationParameters
				? editColumn.metadata.data
				: DEFAULT_SCHEDULE_APPLICATION_PARAMETERS_DATA
		);
	const settingsExpandableRef = useRef<IExpandableApi>(null);
	const optionsExpandableRef = useRef<IExpandableApi>(null);

	const filteredColumnDefs = useMemo(() => {
		if (!editColumn) {
			return totalColumnDefs;
		}

		return totalColumnDefs.filter(({ id }) => id !== editColumn.id);
	}, [editColumn, totalColumnDefs]);
	const isColumnTitleValid = useMemo(
		() => isTitleValid(columnTitle, filteredColumnDefs),
		[columnTitle, filteredColumnDefs]
	);
	const titleUniqueError = useMemo(
		() => (isColumnTitleValid ? '' : COLUMN_TITLE_UNIQUE_ERROR),
		[isColumnTitleValid]
	);

	const isDataValid = useMemo(
		() =>
			isColumnTitleValid &&
			validateData(
				columnType,
				columnsConfig,
				assetPropertyData,
				datastreamData,
				lastControlChangeData,
				recommendationData,
				applicationParameterData,
				scheduleApplicationParametersData
			),
		[
			assetPropertyData,
			columnType,
			columnsConfig,
			isColumnTitleValid,
			lastControlChangeData,
			datastreamData,
			recommendationData,
			applicationParameterData,
			scheduleApplicationParametersData
		]
	);
	const hasChanges = useMemo(
		() =>
			hasColumnChanges(
				editColumn,
				columnTitle,
				columnType,
				columnOptions,
				assetPropertyData,
				datastreamData,
				lastControlChangeData,
				recommendationData,
				applicationParameterData,
				scheduleApplicationParametersData
			),
		[
			editColumn,
			columnTitle,
			columnType,
			columnOptions,
			assetPropertyData,
			datastreamData,
			lastControlChangeData,
			recommendationData,
			applicationParameterData,
			scheduleApplicationParametersData
		]
	);

	useEffect(() => {
		if (columnType !== undefined) {
			settingsExpandableRef.current?.open();
			optionsExpandableRef.current?.open();
		}
	}, [columnType]);

	useUpdate(() => {
		setColumnTitle((previousTitle) => {
			// Reset the title only if it has changed
			if (previousTitle !== editColumn?.title) {
				return '';
			}

			return previousTitle;
		});
	}, [columnType]);

	const onUpdateColumn = useCallback(
		(newColumnDef?: IColumnDef<TData>) => {
			const editedColumnIndex = getEditedColumnIndex(totalColumnDefs, editColumn?.id);

			if (editedColumnIndex === -1) {
				return;
			}

			const newColumnDefs =
				newColumnDef !== undefined
					? updateArrayItem(totalColumnDefs, editedColumnIndex, newColumnDef)
					: removeArrayItem(totalColumnDefs, editedColumnIndex);

			// TODO-REACT18:  Remove this after upgrade to React 18.
			// On this version all react state updates performed
			// inside a callback are automatically batched.
			unstable_batchedUpdates(() => {
				setColumnDefs(newColumnDefs);
				push(EManagementPanel.Manage);
			});
		},
		[totalColumnDefs, editColumn?.id, setColumnDefs, push]
	);

	const onClickSave = useCallback(() => {
		if (!columnType) {
			return;
		}

		const newColumnDef = buildColumnDef(
			columnType,
			filteredColumnDefs,
			columnsConfig,
			columnTitle,
			columnOptions,
			assetPropertyData,
			datastreamData,
			lastControlChangeData,
			recommendationData,
			applicationParameterData,
			scheduleApplicationParametersData
		);

		if (!newColumnDef) {
			return;
		}

		onUpdateColumn(newColumnDef);
	}, [
		columnType,
		filteredColumnDefs,
		columnsConfig,
		columnTitle,
		columnOptions,
		assetPropertyData,
		datastreamData,
		lastControlChangeData,
		recommendationData,
		applicationParameterData,
		scheduleApplicationParametersData,
		onUpdateColumn
	]);

	const onClickDelete = useCallback(() => {
		setAlert(
			merge({}, DEFAULT_MANAGEMENT_TABLE_ALERT[EAlertType.Error], {
				actionsConfig: {
					onClickConfirm: () => {
						setAlert(undefined);
						onUpdateColumn();
					},
					onClickCancel: () => setAlert(undefined)
				}
			})
		);
	}, [onUpdateColumn, setAlert]);

	const onRequestClose = useCallback(() => {
		if (hasEditColumnChanges) {
			setAlert(
				merge({}, DEFAULT_MANAGEMENT_TABLE_ALERT[EAlertType.Info], {
					actionsConfig: {
						onClickConfirm: () => {
							setAlert(undefined);
							closeSidePanel();
						},
						onClickCancel: () => setAlert(undefined)
					}
				})
			);
		} else {
			setAlert(undefined);
			closeSidePanel();
		}
	}, [closeSidePanel, hasEditColumnChanges, setAlert]);

	const onClickBack = useCallback(() => {
		if (hasEditColumnChanges) {
			setAlert(
				merge({}, DEFAULT_MANAGEMENT_TABLE_ALERT[EAlertType.Info], {
					actionsConfig: {
						onClickConfirm: () => {
							setAlert(undefined);
							push(EManagementPanel.Manage);
						},
						onClickCancel: () => setAlert(undefined)
					}
				})
			);
		} else {
			setAlert(undefined);
			push(EManagementPanel.Manage);
		}
	}, [setAlert, hasEditColumnChanges, push]);

	useEffect(() => {
		setHasEditColumnChanges(hasChanges);
	}, [setHasEditColumnChanges, hasChanges]);

	return (
		<Panel {...panelConfig} onRequestClose={onRequestClose} onBack={onClickBack} alert={alert}>
			<div className={styles.EditColumn}>
				<ColumnForm
					hasAlert={!isUndefined(alert)}
					columnTypeState={[columnType, setColumnType]}
					columnOptionsState={[columnOptions, setColumnOptions]}
					columnTitleState={[columnTitle, setColumnTitle]}
					assetPropertyDataState={[assetPropertyData, setAssetPropertyData]}
					datastreamDataState={[datastreamData, setDatastreamData]}
					lastControlChangeDataState={[lastControlChangeData, setLastControlChangeData]}
					recommendationDataState={[recommendationData, setRecommendationData]}
					applicationParameterDataState={[
						applicationParameterData,
						setApplicationParameterData
					]}
					scheduleApplicationParametersDataState={[
						scheduleApplicationParametersData,
						setScheduleApplicationParametersData
					]}
					columnDefs={filteredColumnDefs}
					config={columnsConfig}
					titleError={titleUniqueError}
					initiallyExpanded
				/>
				<div
					data-test-id="e2e-manage-table-columns-add-columns-actions"
					className={styles.Actions}>
					<div className={styles.ActionsGroup}>
						<KvActionButtonText
							type={EActionButtonType.Tertiary}
							text="Delete"
							icon={EIconName.Delete}
							onClickButton={onClickDelete}
						/>
					</div>
					<div className={styles.ActionsGroup}>
						<KvActionButtonText
							type={EActionButtonType.Tertiary}
							text="Cancel"
							onClickButton={onClickBack}
						/>
						<KvActionButtonText
							type={EActionButtonType.Primary}
							text="Save"
							disabled={!isDataValid || !hasChanges}
							onClickButton={onClickSave}
						/>
					</div>
				</div>
			</div>
		</Panel>
	);
};
