import { EComponentSize, ITimePickerTime, KvTimePicker } from '@kelvininc/react-ui-components';

import { useCallback, useState } from 'react';

import { useRecoilState } from 'recoil';

import { selectedDashboardTimeAtom } from '../../recoil/atoms';

import { TIME_PICKER_DROPDOWN_POSITION_CONFIG } from './config';
import styles from './styles.module.scss';

type DashboardHeaderProps = {
	title?: string;
};

export const DashboardHeader = ({ title }: DashboardHeaderProps) => {
	const [isCalendarShowing, setIsCalendarShowing] = useState(false);
	const [time, setTime] = useRecoilState(selectedDashboardTimeAtom);

	const onTimeApplied = useCallback(
		({ detail: { key, range, timezone } }: CustomEvent<ITimePickerTime>) => {
			setTime({
				key,
				range,
				timezone
			});
		},
		[setTime]
	);

	return (
		<div className={styles.DashboardHeaderContainer}>
			<div className={styles.Title}>{title}</div>
			<div className={styles.Actions}>
				<KvTimePicker
					selectedTimeOption={time}
					inputConfig={{ size: EComponentSize.Small }}
					showCalendar={isCalendarShowing}
					disableTimezoneSelection
					onShowCalendarStateChange={() => setIsCalendarShowing((prev) => !prev)}
					dropdownPositionOptions={TIME_PICKER_DROPDOWN_POSITION_CONFIG}
					onTimeRangeChange={onTimeApplied}
				/>
			</div>
		</div>
	);
};
