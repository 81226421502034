import { FunctionComponent } from '@kelvininc/types';
import { Context, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

import { FieldValues } from 'react-hook-form';

import { IWizardStepConfig } from '../../components/WizardStep';
import { getStep } from '../../utils';

import { WizardFormContextProps } from '../../WizardFormContext';

import { ICurrentStepContextValues } from './types';

export const CurrentStepContext: Context<null | ICurrentStepContextValues> =
	createContext<null | ICurrentStepContextValues>(null);

export const useCurrentStepContext = (): ICurrentStepContextValues => {
	const context = useContext(CurrentStepContext);

	if (!context) {
		throw new Error('Missing current step context');
	}

	return context;
};

export const CurrentStepContextProvider = ({
	children,
	initialStep
}: PropsWithChildren<{ initialStep: IWizardStepConfig }>) => {
	const [currentStepState, setCurrentStepState] = useState(initialStep);

	useEffect(() => {
		setCurrentStepState(initialStep);
	}, [initialStep]);

	return (
		<CurrentStepContext.Provider
			value={{ currentStepState: [currentStepState, setCurrentStepState] }}>
			{children}
		</CurrentStepContext.Provider>
	);
};

export const withCurrentStepContextProvider = <T extends FieldValues>(
	Component: FunctionComponent<WizardFormContextProps<T>>
) => {
	return function CurrentStepContextProviderWrapper(
		componentProps: PropsWithChildren<WizardFormContextProps<T>>
	) {
		return (
			<CurrentStepContextProvider initialStep={getStep(componentProps.steps, 0)}>
				<Component {...componentProps} />
			</CurrentStepContextProvider>
		);
	};
};
