import { AppItem } from '@kelvininc/node-client-sdk';
import { keyBy } from 'lodash-es';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { useSmartAppsSettings } from '@/src/hooks/useSmartAppsSettings';
import { appRegistryAppsListAtom } from '@/src/recoil/app-registry/atoms';

export const useFilteredApplications = (): {
	applicationsMap: Record<string, AppItem>;
	applications: AppItem[];
} => {
	const applications = useRecoilValue(appRegistryAppsListAtom);
	const { undeployedSmartApps } = useSmartAppsSettings();

	const filteredApplications = useMemo(
		() => applications.filter(({ name }) => !undeployedSmartApps.includes(name)),
		[applications, undeployedSmartApps]
	);

	return {
		applicationsMap: keyBy(filteredApplications, 'name'),
		applications: filteredApplications
	};
};
