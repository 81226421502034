import {
	AssetService,
	EKvHttpStatusCode,
	EPropertyType,
	Property
} from '@kelvininc/node-client-sdk';
import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';
import { keyBy } from 'lodash-es';
import { selector } from 'recoil';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { getAssetPropertyDefinitions } from './requests';

export const assetPropertyDefinitionsMapSelector = selector<Record<string, Property>>({
	key: 'assetPropertyDefinitionsMapSelector',
	get: ({ get }) => keyBy(get(assetPropertyDefinitionsListSelector), 'name')
});

export const assetPropertyDefinitionsListSelector = selector<Property[]>({
	key: 'assetPropertyDefinitionsListSelector',
	get: () => getAssetPropertyDefinitions()
});

export const assetPropertyDefinitionsAllMapSelector = selector<Record<string, Property>>({
	key: 'assetPropertyDefinitionsAllMapSelector',
	get: ({ get }) => keyBy(get(assetPropertyDefinitionsListAllSelector), 'name')
});

export const assetPropertyDefinitionsListAllSelector = selector<Property[]>({
	key: 'assetPropertyDefinitionsListAllSelector',
	get: () => getAssetPropertyDefinitions(true)
});

export const assetPropertyValuesMapSelector = selector<Record<string, string[] | boolean[]>>({
	key: 'assetPropertyValuesMapSelector',
	get: () =>
		AssetService.getAssetPropertyValues({
			assetPropertyValuesGetData: {
				primitive_types: [EPropertyType.Boolean, EPropertyType.String]
			}
		})
			.pipe(
				map(({ properties }) => properties),
				catchHttpError((error) => {
					if (error.status === EKvHttpStatusCode.NotFound) {
						return of({});
					}

					return throwHttpError(error);
				})
			)
			.toPromise()
});
