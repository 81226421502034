import { DEFAULT_TABLE_POLLING_INTERVAL } from '../../config';
import { Datasource, IBaseTData } from '../../core/components/AgGridTable';
import { refreshDatasource } from '../../core/components/AgGridTable/utils';

import { DataPollingConfig } from './types';

let timeoutId: ReturnType<typeof setTimeout>;

export const withDataPolling = <TData extends IBaseTData, Plugins = {}>(
	datasource: Datasource<TData, Plugins>
): Datasource<TData, Plugins> => {
	return withDataPollingConfig()(datasource);
};

export const withDataPollingConfig =
	({ rate = DEFAULT_TABLE_POLLING_INTERVAL, enabled = true }: DataPollingConfig = {}) =>
	<TData extends IBaseTData, Plugins = {}>(
		datasource: Datasource<TData, Plugins>
	): Datasource<TData, Plugins> => {
		return (params, api) =>
			datasource(params, api).finally(() => {
				clearTimeout(timeoutId);

				if (enabled) {
					timeoutId = setTimeout(() => {
						refreshDatasource(api);
					}, rate);
				}
			});
	};
