import { GridApi } from '@ag-grid-community/core';

import {
	Datasource,
	IBaseTData,
	IDatasourceParams,
	IDatasourceResult
} from '../../core/components/AgGridTable';
import { FIRST_PAGE } from '../../core/components/AgGridTable/config';

const callDatasourceWithFallback = <TData extends IBaseTData, Plugins = {}>(
	datasource: Datasource<TData, Plugins>,
	params: IDatasourceParams<TData> & Plugins,
	api: GridApi<TData>
): Promise<IDatasourceResult<TData>> =>
	datasource(params, api).then((result) => {
		const { data, pagination } = result;

		// If there's data, we don't to do anything
		if (data.length > 0) {
			return result;
		}

		// If there's no pagination information or is
		// already on the first page, we don't to do anything
		if (!pagination || pagination.page === undefined || pagination.page === FIRST_PAGE) {
			return result;
		}

		// Otherwise, fetch the previous page results
		return callDatasourceWithFallback(
			datasource,
			{
				...params,
				page: Math.max(pagination.page - 1, FIRST_PAGE)
			},
			api
		);
	});

export const withPreviousPageFallback = <TData extends IBaseTData, Plugins = {}>(
	datasource: Datasource<TData, Plugins>
): Datasource<TData, Plugins> => {
	return (params, api) => callDatasourceWithFallback(datasource, params, api);
};
