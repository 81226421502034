import { EActionButtonType } from '@kelvininc/react-ui-components';
import { EDataState, OverlaysConfig } from '@kelvininc/shared-ui';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { assetsListAtom } from '@/src/recoil/assets/atoms';
import { connectionsListSelector } from '@/src/recoil/connections/selectors';
import { datastreamsListAtom } from '@/src/recoil/data-streams/atoms';
import {
	EGuardrailOperationType,
	GuardrailOperation
} from '@/src/services/guardrail-service/types';

export const useOverlaysConfig = (
	execute: (operation: GuardrailOperation) => Promise<void>
): Partial<OverlaysConfig> => {
	const assets = useRecoilValue(assetsListAtom);
	const datastreams = useRecoilValue(datastreamsListAtom);
	const connections = useRecoilValue(connectionsListSelector);

	return useMemo<Partial<OverlaysConfig>>(() => {
		const hasResources = assets.length > 0 && datastreams.length > 0 && connections.length > 0;

		if (!hasResources) {
			return {
				[EDataState.IsEmpty]: {
					title: 'No Content Here... Yet!',
					description: 'Currently, there are no guardrails created in Kelvin.'
				}
			};
		}

		return {
			[EDataState.IsEmpty]: {
				title: 'No Content Here... Yet!',
				description:
					'Currently, there are no guardrails created in Kelvin. Go ahead and add some!',
				action: {
					text: 'Import Guardrails',
					type: EActionButtonType.Primary,
					onClick: () =>
						execute({
							type: EGuardrailOperationType.Import
						})
				}
			}
		};
	}, [assets.length, connections.length, datastreams.length, execute]);
};
