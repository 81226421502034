import { SchemaFormProps } from '@kelvininc/react-ui-components';
import { IWizardStepConfig } from '@kelvininc/shared-ui';

import { EGuardrailValueType } from '../GuardrailCraftWizard/types';

import { FileUploadStep, ImportSummaryStep } from './components';
import styles from './styles.module.scss';
import { EImportGuardrailsWizardStep, ImportGuardrailFileSchema } from './types';

import { buildCSVHeaderMapping, buildSchemaCsvTemplateFile } from '@/src/utils/schema-csv';

export const IMPORT_WIZARD_TITLE = 'Import Guardrails';

export const IMPORT_GUARDRAILS_WIZARD_STEPS = {
	[EImportGuardrailsWizardStep.Upload]: {
		id: EImportGuardrailsWizardStep.Upload,
		title: 'Import .csv file',
		modalClass: styles.FileUploadStep,
		Component: FileUploadStep,
		cancelable: true
	},
	[EImportGuardrailsWizardStep.Summary]: {
		id: EImportGuardrailsWizardStep.Summary,
		title: 'File preview & validation',
		modalClass: styles.ImportSummaryStep,
		Component: ImportSummaryStep,
		cancelable: true,
		allowGoBack: true
	}
} as const satisfies Record<EImportGuardrailsWizardStep, IWizardStepConfig>;

export const IMPORT_GUARDRAILS_SCHEMA: SchemaFormProps<ImportGuardrailFileSchema>['schema'] = {
	type: 'array',
	items: {
		type: 'object',
		properties: {
			asset: {
				title: 'Asset',
				type: 'string'
			},
			datastream: {
				title: 'Data Stream',
				type: 'string'
			},
			control: {
				title: 'Control',
				type: 'boolean'
			},
			min_value: {
				title: 'Minimum Value',
				type: 'string'
			},
			min_value_type: {
				title: 'Minimum Value Type',
				type: ['string', 'number'],
				enum: [EGuardrailValueType.Number, EGuardrailValueType.Datastream]
			},
			min_inclusive: {
				title: 'Minimum Value Included',
				type: 'boolean'
			},
			max_value: {
				title: 'Maximum Value',
				type: 'string'
			},
			max_value_type: {
				title: 'Maximum Value Type',
				type: ['string', 'number'],
				enum: [EGuardrailValueType.Number, EGuardrailValueType.Datastream]
			},
			max_inclusive: {
				title: 'Maximum Value Included',
				type: 'boolean'
			},
			relative_increase_min_value: {
				title: 'Relative Increase Minimum Value',
				type: 'string'
			},
			relative_increase_min_value_type: {
				title: 'Relative Increase Minimum Value Type',
				type: 'string',
				enum: [EGuardrailValueType.Number, EGuardrailValueType.Percentage]
			},
			relative_increase_min_inclusive: {
				title: 'Relative Increase Minimum Value Included',
				type: 'boolean'
			},
			relative_increase_max_value: {
				title: 'Relative Increase Maximum Value',
				type: 'string'
			},
			relative_increase_max_value_type: {
				title: 'Relative Increase Maximum Value Type',
				type: 'string',
				enum: [EGuardrailValueType.Number, EGuardrailValueType.Percentage]
			},
			relative_increase_max_inclusive: {
				title: 'Relative Increase Maximum Value Included',
				type: 'boolean'
			},
			relative_decrease_min_value: {
				title: 'Relative Decrease Minimum Value',
				type: 'string'
			},
			relative_decrease_min_value_type: {
				title: 'Relative Decrease Minimum Value Type',
				type: 'string',
				enum: [EGuardrailValueType.Number, EGuardrailValueType.Percentage]
			},
			relative_decrease_min_inclusive: {
				title: 'Relative Decrease Minimum Value Included',
				type: 'boolean'
			},
			relative_decrease_max_value: {
				title: 'Relative Decrease Maximum Value',
				type: 'string'
			},
			relative_decrease_max_value_type: {
				title: 'Relative Decrease Maximum Value Type',
				type: 'string',
				enum: [EGuardrailValueType.Number, EGuardrailValueType.Percentage]
			},
			relative_decrease_max_inclusive: {
				title: 'Relative Decrease Maximum Value Included',
				type: 'boolean'
			}
		},
		additionalProperties: false,
		required: ['asset', 'datastream', 'control'],
		dependencies: {
			min_value: ['min_value_type', 'min_inclusive'],
			min_value_type: ['min_value', 'min_inclusive'],
			min_inclusive: ['min_value_type', 'min_value'],
			max_value: ['max_value_type', 'max_inclusive'],
			max_value_type: ['max_value', 'max_inclusive'],
			max_inclusive: ['max_value_type', 'max_value'],
			relative_increase_min_inclusive: [
				'relative_increase_min_value',
				'relative_increase_min_value_type'
			],
			relative_increase_min_value: [
				'relative_increase_min_inclusive',
				'relative_increase_min_value_type'
			],
			relative_increase_min_value_type: [
				'relative_increase_min_inclusive',
				'relative_increase_min_value'
			],
			relative_increase_max_inclusive: [
				'relative_increase_max_value',
				'relative_increase_max_value_type'
			],
			relative_increase_max_value: [
				'relative_increase_max_inclusive',
				'relative_increase_max_value_type'
			],
			relative_increase_max_value_type: [
				'relative_increase_max_inclusive',
				'relative_increase_max_value'
			],
			relative_decrease_min_inclusive: [
				'relative_decrease_min_value',
				'relative_decrease_min_value_type'
			],
			relative_decrease_min_value: [
				'relative_decrease_min_inclusive',
				'relative_decrease_min_value_type'
			],
			relative_decrease_min_value_type: [
				'relative_decrease_min_inclusive',
				'relative_decrease_min_value'
			],
			relative_decrease_max_inclusive: [
				'relative_decrease_max_value',
				'relative_decrease_max_value_type'
			],
			relative_decrease_max_value: [
				'relative_decrease_max_inclusive',
				'relative_decrease_max_value_type'
			],
			relative_decrease_max_value_type: [
				'relative_decrease_max_inclusive',
				'relative_decrease_max_value'
			]
		}
	},
	uniqueItems: true
};

export const TEMPLATE_FILE = buildSchemaCsvTemplateFile(IMPORT_GUARDRAILS_SCHEMA, 'guardrails');

export const FILE_MAPPING = buildCSVHeaderMapping(IMPORT_GUARDRAILS_SCHEMA);
