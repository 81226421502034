'use client';
import {
	EActionButtonType,
	ETooltipPosition,
	KvActionButtonIcon,
	KvTooltip,
	clipboardHelper
} from '@kelvininc/react-ui-components';
import classNames from 'classnames';
import { useCallback, useState } from 'react';

import {
	COPY_STATE_TRANSITION_DURATION_MS,
	ICON_CONFIGS,
	TOOLTIP_LABELS,
	UNABLE_TO_COPY_ERROR
} from './config';
import styles from './styles.module.scss';
import { ECopyState, TerminalInstructionProps } from './types';

export const TerminalInstruction = ({ code }: TerminalInstructionProps) => {
	const [copyState, setCopyState] = useState<ECopyState>(ECopyState.ReadyToCopy);

	const copyToClipboard = useCallback(() => {
		clipboardHelper
			.copyTextToClipboard(code)
			.then(() => {
				setCopyState(ECopyState.Copied);
				setTimeout(
					() => setCopyState(ECopyState.ReadyToCopy),
					COPY_STATE_TRANSITION_DURATION_MS
				);
			})
			.catch(() => {
				throw new Error(UNABLE_TO_COPY_ERROR);
			});
	}, [code]);

	return (
		<div data-test-id="e2e-modal-terminal-instruction" className={styles.TerminalInstruction}>
			<div className={styles.Code}>{code}</div>
			<KvTooltip
				text={TOOLTIP_LABELS[copyState]}
				className={classNames(styles.CopyButton, {
					[styles.CopyButtonSuccess]: copyState === ECopyState.Copied
				})}
				position={ETooltipPosition.Bottom}>
				<KvActionButtonIcon
					icon={ICON_CONFIGS[copyState]}
					type={EActionButtonType.Ghost}
					onClickButton={copyToClipboard}
				/>
			</KvTooltip>
		</div>
	);
};
