import { zodResolver } from '@hookform/resolvers/zod';
import { WizardFormContextProvider, useWizardFormContext } from '@kelvininc/shared-ui';
import { FunctionComponent } from '@kelvininc/types';

import { DefaultValues } from 'react-hook-form';

import { GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA } from '../../schemas/form';
import { GuardrailCraftWizardFormValues } from '../../types';

export const useGuardrailCraftWizardFormContext = () => {
	return useWizardFormContext<GuardrailCraftWizardFormValues>();
};

export const withGuardrailCraftWizardFormProvider = <
	Props extends { initialData?: DefaultValues<GuardrailCraftWizardFormValues> }
>(
	Component: FunctionComponent<Props & JSX.IntrinsicAttributes>
) => {
	return function GuardrailCraftWizardFormProviderWrapper(
		props: Props & JSX.IntrinsicAttributes
	) {
		const { initialData } = props;

		return (
			<WizardFormContextProvider<GuardrailCraftWizardFormValues>
				resolver={zodResolver(GUARDRAIL_CRAFT_WIZARD_FORM_VALUES_SCHEMA)}
				defaultValues={initialData}
				mode="onChange">
				<Component {...props} />
			</WizardFormContextProvider>
		);
	};
};
