import { DragDropContext, DropResult } from '@kelvininc/shared-ui';
import { moveArrayItem, updateArrayItem } from '@kelvininc/tsutils';
import { useCallback, useMemo } from 'react';

import { EColumnPinAlignment, IBaseTData, IColumnDef } from '../../../../../../../AgGridTable';
import { useSplitAndSortedColumnDefs } from '../../hooks';
import { getMaxFrozenColumns } from '../../utils';
import { ColumnsDroppableZone } from '../ColumnsDroppableZone';

import {
	COLUMNS_LEFT_PINNED_DRAGGABLE_ZONE_ID,
	COLUMNS_RIGHT_PINNED_DRAGGABLE_ZONE_ID,
	COLUMNS_UNPINNED_DRAGGABLE_ZONE_ID
} from './config';

import styles from './styles.module.scss';

type DragDropColumnsProps<TData extends IBaseTData> = {
	columnDefs: IColumnDef<TData>[];
	onVisibilityChange?: (column: IColumnDef<TData>) => void;
	onChangeItems?: (columns: IColumnDef<TData>[]) => void;
	onEdit?: (column: IColumnDef<TData>) => void;
	isSearching?: boolean;
	isHiding?: boolean;
};

export const DragDropColumns = <TData extends IBaseTData>({
	columnDefs,
	onVisibilityChange,
	onChangeItems,
	onEdit,
	isSearching = false,
	isHiding = false
}: DragDropColumnsProps<TData>) => {
	const onClickEdit = useCallback(
		(itemIndex: number) => {
			onEdit?.(columnDefs[itemIndex]);
		},
		[columnDefs, onEdit]
	);
	const onClickVisibility = useCallback(
		(itemIndex: number) => {
			if (!onVisibilityChange) {
				return;
			}

			onVisibilityChange(columnDefs[itemIndex]);
		},
		[columnDefs, onVisibilityChange]
	);
	const [leftPinned, unpinned, rightPinned] = useSplitAndSortedColumnDefs(columnDefs);
	const isPinDropzoneDisabled = useMemo(
		() =>
			leftPinned.length + rightPinned.length >= getMaxFrozenColumns(columnDefs) ||
			isSearching,
		[columnDefs, isSearching, leftPinned.length, rightPinned.length]
	);

	const onDragEnd = useCallback(
		(event: DropResult) => {
			if (!onChangeItems) {
				return;
			}

			// Dropped outside the list
			if (!event.destination) {
				return;
			}

			const {
				destination: { index: destinationIndex, droppableId: destinationDroppableId },
				source: { index: sourceIndex, droppableId: sourceDroppableId }
			} = event;

			// Destination is the same as source
			if (destinationDroppableId === sourceDroppableId && destinationIndex === sourceIndex) {
				return;
			}

			let newItems = columnDefs;
			let offset = 0;

			// Check if droppable type is different
			if (destinationDroppableId !== sourceDroppableId) {
				let pinned: boolean | EColumnPinAlignment = false;

				if (destinationDroppableId === COLUMNS_LEFT_PINNED_DRAGGABLE_ZONE_ID) {
					pinned = EColumnPinAlignment.Left;
				} else if (destinationDroppableId === COLUMNS_RIGHT_PINNED_DRAGGABLE_ZONE_ID) {
					pinned = EColumnPinAlignment.Right;
				}

				// Update dragged item
				newItems = updateArrayItem(newItems, sourceIndex, {
					...columnDefs[sourceIndex],
					pinned,
					hide: false
				});
				offset = destinationIndex <= sourceIndex ? 0 : -1;
			}

			// Swap elements
			newItems = moveArrayItem(newItems, sourceIndex, destinationIndex + offset);

			onChangeItems(newItems);
		},
		[columnDefs, onChangeItems]
	);

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<div className={styles.EditSection}>
				<div className={styles.Title}>Freeze on the left</div>
				<div
					data-test-id="e2e-drag-drop-left-pinned-draggable-zone"
					className={styles.DraggableZone}>
					<ColumnsDroppableZone
						id={COLUMNS_LEFT_PINNED_DRAGGABLE_ZONE_ID}
						items={leftPinned}
						searching={isSearching}
						hiding={isHiding}
						disabled={isPinDropzoneDisabled}
						onClickEdit={onClickEdit}
						onClickVisibility={onClickVisibility}
					/>
				</div>
			</div>
			<div className={styles.EditSection}>
				<div className={styles.Title}>Scroll columns</div>
				<div
					data-test-id="e2e-drag-drop-unpinned-draggable-zone"
					className={styles.DraggableZone}>
					<ColumnsDroppableZone
						id={COLUMNS_UNPINNED_DRAGGABLE_ZONE_ID}
						items={unpinned}
						searching={isSearching}
						hiding={isHiding}
						disabled={isSearching}
						onClickEdit={onClickEdit}
						onClickVisibility={onClickVisibility}
					/>
				</div>
			</div>
			<div className={styles.EditSection}>
				<div className={styles.Title}>Freeze on the right</div>
				<div
					data-test-id="e2e-drag-drop-right-pinned-draggable-zone"
					className={styles.DraggableZone}>
					<ColumnsDroppableZone
						id={COLUMNS_RIGHT_PINNED_DRAGGABLE_ZONE_ID}
						items={rightPinned}
						searching={isSearching}
						hiding={isHiding}
						disabled={isPinDropzoneDisabled}
						onClickEdit={onClickEdit}
						onClickVisibility={onClickVisibility}
					/>
				</div>
			</div>
		</DragDropContext>
	);
};
