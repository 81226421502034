'use client';

import { EParameterScheduleState } from '@kelvininc/node-client-sdk';
import { IDeleteEntityModalProps } from '@kelvininc/shared-ui';

import { SCHEDULE_APPLY_TYPE } from './config';

export const getDeleteScheduleModalConfig = (
	onClickDelete: () => Promise<void>
): IDeleteEntityModalProps => ({
	title: 'Delete schedule?',
	description:
		'Are you sure you want to delete this schedule? All data related to this schedule will be lost.',
	onClickDelete
});

export const isScheduled = (
	state: EParameterScheduleState
): state is keyof typeof SCHEDULE_APPLY_TYPE => {
	return [EParameterScheduleState.Scheduled, EParameterScheduleState.ScheduledRevert].includes(
		state
	);
};
