import { ECellAlignment, IColumnDef } from '@kelvininc/table';

import styles from './styles.module.scss';
import { IDetailValue } from './types';

export const buildTableColumnDefs = (columnTitle: string): IColumnDef<IDetailValue>[] => [
	{
		id: 'Asset',
		title: 'Asset',
		accessor: 'asset'
	},
	{
		id: 'value',
		title: columnTitle,
		accessor: 'value',
		headerAlignment: ECellAlignment.Right,
		cellAlignment: ECellAlignment.Right
	}
];

export const buildDescription = (title: string, parameterTitle: string): JSX.Element => {
	return (
		<>
			<span>
				{`Below is a comprehensive list detailing the asset with different ${title.toLocaleLowerCase()}
				values of the`}
			</span>
			<span className={styles.ParameterTitle}> {parameterTitle}</span>
			<span> parameter.</span>
		</>
	);
};
