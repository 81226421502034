import { ComputePositionConfig, flip, offset } from '@floating-ui/dom';

import { EIllustrationName, IIllustrationMessage } from '@kelvininc/react-ui-components';

export const SELECT_APPLICATION_DROPDOWN_OPTIONS: Partial<ComputePositionConfig> = {
	placement: 'bottom-start',
	middleware: [
		offset(8),
		flip({
			padding: 16
		})
	]
};

export const SELECT_APPLICATION_PLACEHOLDER = 'SmartApps';
export const SELECT_APPLICATION_SEARCH_PLACEHOLDER = 'Search for SmartApps';
export const SELECT_APPLICATION_NO_DATA_AVAILABLE_CONFIG: IIllustrationMessage = {
	illustration: EIllustrationName.NoDataAvailable,
	header: 'No SmartApps Available',
	description: 'There are no SmartApps to display at the moment.'
};
export const SELECT_APPLICATION_EMPTY_LABEL = 'No SmartApps available';
export const SELECT_APPLICATION_PARAMETER_PLACEHOLDER = 'Parameters';
export const SELECT_APPLICATION_PARAMETER_SEARCH_PLACEHOLDER = 'Search for properties';
export const SELECT_APPLICATION_PARAMETER_NO_DATA_AVAILABLE_CONFIG: IIllustrationMessage = {
	illustration: EIllustrationName.NoDataAvailable,
	header: 'No Parameters Available',
	description: 'There are no parameters to display at the moment.'
};
export const APPLICATION_PARAMETER_SCHEMA_FORM_PROPERTIES = ['operator', 'value'] as const;
