import { getTimeFromNowFormat } from '@kelvininc/tsutils';
import { DateInput } from '@kelvininc/types';
import { useEffect, useState } from 'react';

import { useInterval } from '../useInterval';

export const useTimeFromNow = (date: DateInput, formatter = getTimeFromNowFormat) => {
	const [time, setTime] = useState(formatter(date));

	useEffect(() => {
		setTime(formatter(date));
	}, [date, formatter]);

	useInterval(() => setTime(formatter(date)));

	return time;
};
