import { Asset, DataStream, Guardrail } from '@kelvininc/node-client-sdk';
import { FormValidation, RJSFValidationError } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import AJV8Validator from '@rjsf/validator-ajv8/lib/validator';
import { useCallback, useEffect, useState } from 'react';

import { IMPORT_GUARDRAILS_SCHEMA } from '../../config';
import { ImportGuardrailFileSchema } from '../../types';

import { UseFileValidation } from './types';

import { validateContent } from './utils';

import { IConnectionFormValues } from '@/src/page-components/ConnectionsPage/ConnectionWizard/types';

export const useFileValidation = (
	data: ImportGuardrailFileSchema[],
	guardrailsMap: Record<string, Guardrail>,
	assetsMap: Record<string, Asset>,
	datastreamsMap: Record<string, DataStream>,
	connection?: IConnectionFormValues
): UseFileValidation => {
	const [isValidating, setValidating] = useState(false);
	const [errors, setErrors] = useState<RJSFValidationError[]>([]);

	const validate = useCallback(() => {
		setValidating(true);
		const result = (validator as AJV8Validator<ImportGuardrailFileSchema[]>).validateFormData(
			data,
			IMPORT_GUARDRAILS_SCHEMA,
			(
				formData: ImportGuardrailFileSchema[] | undefined,
				formErrors: FormValidation<ImportGuardrailFileSchema[]>
			) =>
				validateContent(
					formData,
					guardrailsMap,
					assetsMap,
					datastreamsMap,
					formErrors,
					connection
				)
		);

		setErrors(result.errors);
		setValidating(false);
	}, [assetsMap, connection, data, datastreamsMap, guardrailsMap]);

	const reset = useCallback(() => {
		setValidating(false);
		setErrors([]);
	}, []);

	useEffect(() => {
		validate();

		return reset;
	}, [reset, validate]);

	return {
		validating: isValidating,
		errors
	};
};
