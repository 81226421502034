import { EIconName, IToggleButton } from '@kelvininc/react-ui-components';

import { EDensityLevel } from '../../../../types';

export const getDensityLevelIconName = (level: EDensityLevel): EIconName =>
	({
		[EDensityLevel.Low]: EIconName.DensityLow,
		[EDensityLevel.Medium]: EIconName.DensityMedium,
		[EDensityLevel.High]: EIconName.DensityHigh
	})[level];

export const buildDensityToggleButtons = (
	options: Partial<Record<EDensityLevel, number>> | undefined
): IToggleButton[] => {
	if (options === undefined) {
		return [];
	}

	return Object.keys(options).map<IToggleButton>((level) => ({
		value: level as string,
		icon: getDensityLevelIconName(level as EDensityLevel)
	}));
};

export const buildSelectedToggleButtons = (
	densityLevel: EDensityLevel | undefined
): Record<string, boolean> => {
	if (densityLevel === undefined) {
		return {};
	}

	return {
		[densityLevel]: true
	};
};
