'use client';
import { useUserSetting } from '@kelvininc/shared-ui';
import { AnimatePresence } from 'framer-motion';
import {
	Context,
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react';

import { ENTITY_ROUTES, REQUIRED_ACTION_ENTITIES } from './config';

import { useExistingEntities, useTutorialSteps } from './hooks';

import { useEnableExperience } from './hooks/useEnableExperience';
import { EApplicationEntity, EntityAction, IFirstExperienceContextValues } from './types';

import { checkPlaformReadiness } from './utils';

import { FirstExperienceGuide } from '@/src/components/client';
import { useHasPermissions, useNavigate } from '@/src/hooks';
import { firstExperienceSkippedUserSettingAtom } from '@/src/recoil/user-settings/atoms';
import {
	FIRST_EXPERIENCE_SKIPPED_USER_SETTING_DEFAULT,
	FIRST_EXPERIENCE_SKIPPED_USER_SETTING_KEY
} from '@/src/recoil/user-settings/config';
import { AREA_PERMISSIONS } from '@/src/services/permissions/config';
import { EPlatformArea } from '@/src/types';

const FirstExperienceContext: Context<null | IFirstExperienceContextValues> =
	createContext<null | IFirstExperienceContextValues>(null);

export const FirstExperienceProvider = ({ children }: PropsWithChildren<{}>) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [forceHidden, setForceHidden] = useState<boolean>(false);

	const { existingEntities: stepStates, hasRequestErrors, isLoading } = useExistingEntities();
	const platformConfigured = useMemo<boolean>(
		() => checkPlaformReadiness(stepStates),
		[stepStates]
	);

	const steps = useTutorialSteps(stepStates);
	const navigate = useNavigate();

	const hasAdminPermissions = useHasPermissions(AREA_PERMISSIONS[EPlatformArea.Admin]);

	const { value: firstExperienceSkipped, setValue: setFirstExperienceSkipped } = useUserSetting(
		FIRST_EXPERIENCE_SKIPPED_USER_SETTING_KEY,
		FIRST_EXPERIENCE_SKIPPED_USER_SETTING_DEFAULT,
		firstExperienceSkippedUserSettingAtom
	);

	const { isVisible, setIsVisible } = useEnableExperience({
		firstExperienceSkipped,
		hasAdminPermissions,
		hasRequestErrors,
		isLoading,
		platformConfigured,
		forceHidden
	});

	const setVisibility = useCallback(
		(newState: boolean) => {
			if (!hasAdminPermissions) return;
			setIsVisible(newState);

			if (firstExperienceSkipped) {
				setFirstExperienceSkipped(false);
			}
		},
		[firstExperienceSkipped, hasAdminPermissions, setFirstExperienceSkipped, setIsVisible]
	);

	const onStepClick = useCallback(
		(stepName: string) => {
			// if the step is already completed, do nothing
			if (stepStates[stepName as EApplicationEntity]) {
				return;
			}
			setIsExpanded(false);
			navigate(ENTITY_ROUTES[stepName as EApplicationEntity]);
		},
		[navigate, stepStates]
	);

	const onEntityAdded = useCallback(() => {
		setIsExpanded(true);
	}, [setIsExpanded]);

	const checkEntityAction = useCallback(
		(action: EntityAction) => {
			const hasRequiredEntities = REQUIRED_ACTION_ENTITIES[action].every(
				(entity) => stepStates[entity]
			);

			if (!hasRequiredEntities) {
				setIsExpanded(true);
				setIsVisible(true);
			}

			return hasRequiredEntities;
		},
		[setIsVisible, setIsExpanded, stepStates]
	);

	return (
		<FirstExperienceContext.Provider
			value={{
				steps,
				stepStates,
				platformConfigured,
				setVisibility,
				expanded: [isExpanded, setIsExpanded],
				firstExperienceSkipped,
				setFirstExperienceSkipped,
				checkEntityAction,
				onEntityAdded,
				onStepClick,
				setForceHidden
			}}>
			<AnimatePresence>
				{isVisible && !hasRequestErrors && <FirstExperienceGuide />}
			</AnimatePresence>
			{children}
		</FirstExperienceContext.Provider>
	);
};

export const useFirstExperienceContext = (): IFirstExperienceContextValues => {
	const context = useContext(FirstExperienceContext);

	if (!context) {
		throw new Error('Missing first experience context');
	}

	return context;
};
