import { flip, offset } from '@floating-ui/dom';

import {
	ComputePositionConfig,
	EIllustrationName,
	IIllustrationMessage
} from '@kelvininc/react-ui-components';

export const SELECT_ASSET_PROPERTY_DROPDOWN_OPTIONS: Partial<ComputePositionConfig> = {
	placement: 'bottom-start',
	middleware: [
		offset(8),
		flip({
			padding: 16
		})
	]
};

export const SELECT_ASSET_PROPERTY_PLACEHOLDER = 'Properties';
export const SELECT_ASSET_PROPERTY_SEARCH_PLACEHOLDER = 'Search for properties';
export const SELECT_ASSET_PROPERTY_NO_DATA_AVAILABLE_CONFIG: IIllustrationMessage = {
	illustration: EIllustrationName.NoDataAvailable,
	header: 'No Properties Available',
	description: 'There are no properties to display at the moment.'
};

export const ASSET_PROPERTY_SCHEMA_FORM_PROPERTIES = ['operator', 'value'] as const;
