import { ERecommendationState } from '@kelvininc/node-client-sdk';
import { getSelectedKeys } from '@kelvininc/tsutils';

export const buildStatusesList = (
	statusesOptions: Record<string, boolean>
): ERecommendationState[] => {
	return getSelectedKeys(statusesOptions) as ERecommendationState[];
};

export const buildStatusesOptions = (
	statusesList: ERecommendationState[] = []
): Record<string, boolean> =>
	statusesList.reduce<Record<string, boolean>>((accumulator, key) => {
		accumulator[key] = true;

		return accumulator;
	}, {});

export const buildRecommendationTypesList = (typesOptions: Record<string, boolean>): string[] => {
	return getSelectedKeys(typesOptions);
};

export const buildRecommendationTypesOptions = (
	typesList: string[] = []
): Record<string, boolean> =>
	typesList.reduce<Record<string, boolean>>((accumulator, key) => {
		accumulator[key] = true;

		return accumulator;
	}, {});
