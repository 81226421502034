'use client';
import { IOpenModalConfig } from '@kelvininc/shared-ui';

import {
	ParametersConfirmationModal,
	ParametersConfirmationModalProps
} from './ParametersConfirmationModal';
import styles from './styles.module.scss';

export const buildParametersConfirmationModalConfig = (
	props: ParametersConfirmationModalProps
): IOpenModalConfig => ({
	ContentComponent: <ParametersConfirmationModal {...props} />,
	config: {
		showCloseButton: true,
		customClass: styles.ParametersModalContainer,
		showOverlay: false
	}
});
