import { Asset, DataStream } from '@kelvininc/node-client-sdk';
import { DEFAULT_INLINE_FILTERS_UI_SCHEMA_CONFIG, ITableFiltersSchema } from '@kelvininc/table';

export const buildFiltersSchema = (
	assets: Asset[],
	datastreams: DataStream[]
): ITableFiltersSchema => ({
	schema: {
		type: 'object',
		properties: {
			assets: {
				type: 'array',
				title: '',
				uniqueItems: true,
				items: {
					type: 'string',
					oneOf: assets.map(({ name, title }) => ({
						title,
						const: name
					}))
				}
			},
			datastreams: {
				type: 'array',
				title: '',
				uniqueItems: true,
				items: {
					type: 'string',
					oneOf: datastreams.map(({ name, title }) => ({
						title,
						const: name
					}))
				}
			},
			control: {
				type: 'string',
				title: '',
				oneOf: [
					{
						title: 'Enabled',
						const: 'enabled'
					},
					{
						title: 'Disabled',
						const: 'disabled'
					}
				]
			}
		}
	},
	uiSchema: {
		...DEFAULT_INLINE_FILTERS_UI_SCHEMA_CONFIG,
		assets: {
			'ui:placeholder': 'Assets',
			searchable: true,
			selectionClearable: true
		},
		datastreams: {
			'ui:placeholder': 'Data Streams',
			searchable: true,
			selectionClearable: true
		},
		control: {
			'ui:placeholder': 'Control'
		}
	}
});
