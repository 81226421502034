import { AppItem } from '@kelvininc/node-client-sdk';
import { RJSFSchema } from '@rjsf/utils';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';

import { getWorkloadAppInfoFormSchema, getWorkloadAppInfoFormUiSchema } from '../utils';

import { useApplicationVersions } from './useApplicationVersions';

import { EDeployWorkloadType } from '@/src/components/client';

export const useApplicationFormProps = (
	applications: AppItem[],
	flowType: EDeployWorkloadType,
	appName: string | undefined
) => {
	const { appVersionsList, appVersionsLoading } = useApplicationVersions(applications, appName);

	const formProps = useMemo(
		() => ({
			schema: getWorkloadAppInfoFormSchema(
				flowType,
				applications,
				appVersionsList
			) as RJSFSchema,
			uiSchema: getWorkloadAppInfoFormUiSchema(
				flowType,
				isEmpty(applications),
				isEmpty(appName) || appVersionsLoading
			)
		}),
		[appVersionsLoading, appVersionsList, flowType, applications, appName]
	);

	return { formProps, appVersionsLoading };
};
