import { EToasterType, IToaster } from '@kelvininc/react-ui-components';

export const WORKLOAD_DELETE_SUCCESS_TOASTER: IToaster = {
	header: 'The workload was deleted successfully!',
	type: EToasterType.Success
};

export const WORKLOAD_DELETE_ERROR_TOASTER: IToaster = {
	header: 'Something went wrong.',
	type: EToasterType.Error
};

export const WORKLOAD_OPERATION_SUCCESS_TOASTER: IToaster = {
	header: 'Workload operation sent successfully.',
	type: EToasterType.Success
};

export const WORKLOAD_OPERATION_ERROR_TOASTER: IToaster = {
	header: "Something went wrong. We can't send the workload operation.",
	type: EToasterType.Error
};
