import { EControlChangeState } from '@kelvininc/node-client-sdk';
import { getControlChangeStatusName, getTimestampFormat } from '@kelvininc/tsutils';
import { isEmpty } from 'lodash-es';

import { IControlChangeCellData } from './types';

const getControlChangeLastMessage = (controlChange: IControlChangeCellData): string | undefined => {
	if (controlChange.last_state !== EControlChangeState.Failed) {
		return;
	}

	return controlChange.last_message;
};

export const getControlChangeTooltip = (
	controlChange: IControlChangeCellData | undefined
): string | undefined => {
	if (!controlChange) {
		return;
	}

	const status = getControlChangeStatusName(controlChange.last_state as EControlChangeState);
	const timestamp = getTimestampFormat(controlChange.timestamp);
	const lastMessage = getControlChangeLastMessage(controlChange);

	return `${status} - at ${timestamp}${isEmpty(lastMessage) ? '' : `\n${lastMessage}`}`;
};
