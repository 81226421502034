import { ErrorFallback } from '@kelvininc/shared-ui';
import { captureException } from '@sentry/nextjs';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

type ErrorPageProps = {
	error?: Error;
	url?: string;
};

export const ErrorPage = ({ error, url }: ErrorPageProps) => {
	const router = useRouter();

	useEffect(() => {
		if (error) {
			captureException(error);
		}
	}, [error]);

	return (
		<ErrorFallback
			buttonConfig={
				url ? { text: 'Try Again', onClickButton: () => router.replace(url) } : undefined
			}
		/>
	);
};
