import { KvBreadcrumbList } from '@kelvininc/react-ui-components';

import { BreadcrumbsItem } from './components';
import { BreadcrumbItem } from './types';

type BreadcrumbsProps = {
	items: BreadcrumbItem[];
};

export const Breadcrumbs = ({ items }: BreadcrumbsProps) => (
	<nav>
		<KvBreadcrumbList>
			{items.map(({ value, ...otherProps }, index, array) => (
				<BreadcrumbsItem
					key={value}
					active={index === array.length - 1}
					value={value}
					{...otherProps}
				/>
			))}
		</KvBreadcrumbList>
	</nav>
);
