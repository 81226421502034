'use client';
import {
	EIconName,
	ETooltipPosition,
	IRange,
	KvFormLabel,
	KvIcon,
	KvRange,
	KvToggleTip
} from '@kelvininc/react-ui-components';
import { useCallback } from 'react';

import styles from './styles.module.scss';
import { getValidInputRangeValue } from './utils';

export type RangeInputProps = {
	label: string;
	value: number;
	config: IRange;
	disabled?: boolean;
	tip?: string;
	onValueChange: (newValue: number) => void;
};

export const RangeInput = ({
	label,
	value,
	config,
	disabled,
	tip,
	onValueChange
}: RangeInputProps) => {
	const validValue = getValidInputRangeValue(config, value);
	const valueChange = useCallback(
		({ detail }: CustomEvent<number>) => {
			onValueChange(detail);
		},
		[onValueChange]
	);

	return (
		<div className={styles.RangeInputContainer}>
			<div className={styles.Title}>
				<KvFormLabel label={label} />
				{tip && (
					<KvToggleTip text={tip} position={ETooltipPosition.TopStart}>
						<KvIcon name={EIconName.Info} slot="open-element-slot" />
					</KvToggleTip>
				)}
			</div>
			<div className={styles.RangeInput}>
				<KvRange
					{...config}
					value={validValue}
					disabled={disabled}
					onValueChange={valueChange}
				/>
			</div>
		</div>
	);
};
