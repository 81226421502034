import {
	IWizardStepConfig,
	WizardFormContext,
	closeModal,
	withRecoilRoot,
	withWizardBoundaries
} from '@kelvininc/shared-ui';
import { compose } from '@kelvininc/tsutils';
import { ComponentProps } from 'react';
import { DefaultValues } from 'react-hook-form';

import { useOnSubmitForm, withGuardrailCraftWizardFormProvider } from './hooks';
import { GuardrailCraftWizardFormValues, StepValidatorCallback } from './types';

type GuardrailCraftWizardProps = {
	submit: (form: GuardrailCraftWizardFormValues) => Promise<void>;
	initialData?: DefaultValues<GuardrailCraftWizardFormValues>;
	submitLabel: string;
	steps: Record<string, IWizardStepConfig>;
	stepValidator: StepValidatorCallback;
};

const Component = ({ submit, submitLabel, steps, stepValidator }: GuardrailCraftWizardProps) => {
	const onSubmitForm = useOnSubmitForm(submit);

	return (
		<WizardFormContext
			completeBtnLabel={submitLabel}
			steps={steps}
			isStepValid={stepValidator}
			onCancelClick={closeModal}
			onCompleteClick={onSubmitForm}
		/>
	);
};

export const GuardrailCraftWizard = compose(
	withWizardBoundaries,
	withRecoilRoot,
	withGuardrailCraftWizardFormProvider<ComponentProps<typeof Component>>
)(Component);
