import { ExpandableMenu, useNavigationSideBar, withExpandableProvider } from '@kelvininc/shared-ui';

import React from 'react';

import { navigationSidebarExpandedOrchestrationUserSettingAtom } from '../../atom';
import {
	NAVIGATION_SIDEBAR_EXPANDED_ORCHESTRATION_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_ORCHESTRATION_USER_SETTING_KEY
} from '../../config';

import { useOrchestrationExpandableItems } from './utils';

const Component = () => {
	const { isCollapsed } = useNavigationSideBar();
	const items = useOrchestrationExpandableItems();

	return <ExpandableMenu title="Orchestration" items={items} collapsed={isCollapsed} />;
};

export const OrchestrationMenu = withExpandableProvider(
	Component,
	NAVIGATION_SIDEBAR_EXPANDED_ORCHESTRATION_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_ORCHESTRATION_USER_SETTING_KEY,
	navigationSidebarExpandedOrchestrationUserSettingAtom
);
