import { EIconName, KvIcon } from '@kelvininc/react-ui-components';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';

import styles from './styles.module.scss';

type VisibilityToggleProps = {
	visible: boolean;
	onClick: () => void;
	disabled?: boolean;
};

export const VisibilityToggle = ({ visible, onClick, disabled }: VisibilityToggleProps) => {
	const iconName = useMemo(() => (visible ? EIconName.Eye : EIconName.EyeClosed), [visible]);
	const color = useMemo(() => {
		if (disabled) {
			return 'var(--color-neutral-5)';
		}

		if (visible) {
			return 'var(--color-neutral-2)';
		}

		return 'var(--color-neutral-0)';
	}, [disabled, visible]);
	const onClickToggle = useCallback(() => {
		if (!disabled) {
			onClick();
		}
	}, [disabled, onClick]);

	return (
		<div
			data-test-id="e2e-visibility-toggle"
			className={classNames(styles.VisibilityToggle, {
				[styles.VisibilityToggleDisabled]: disabled
			})}
			onClick={onClickToggle}>
			<KvIcon name={iconName} customColor={color} />
		</div>
	);
};
