import { AssetInsightsItem } from '@kelvininc/node-client-sdk';

import { IAdvancedFiltersConfig, ITableSettings, ITablesSettings } from '@kelvininc/table';

import { identity } from 'lodash-es';

import { ISerializedAssetInsightsTableSettings } from '../useAssetInsightsTableSettings/types';
import { deserializeTablesSettings, serializeTablesSettings } from '../useTablesSettings/utils';

import {
	deserializeAssetInsightsTableSettings,
	serializeAssetInsightsTableSettings
} from '@/src/hooks/useAssetInsightsTableSettings/utils';
import { IAssetInsightsCustomColumnsConfigMetadata } from '@/src/types/asset-insights';
import { filterValidAdvancedFiltersSettings } from '@/src/utils/asset-insights';

export const serializeAssetInsightsTablesSettings = (
	settings: ITablesSettings<AssetInsightsItem>
): Record<string, ISerializedAssetInsightsTableSettings> =>
	serializeTablesSettings(settings, (setting: ITableSettings<AssetInsightsItem>) =>
		serializeAssetInsightsTableSettings(setting)
	);

export const deserializeAssetInsightsTablesSettings = (
	settings: Record<string, ISerializedAssetInsightsTableSettings>,
	metadata?: IAssetInsightsCustomColumnsConfigMetadata,
	config?: IAdvancedFiltersConfig
): ITablesSettings<AssetInsightsItem> =>
	deserializeTablesSettings(
		settings,
		(serializedSetting: ISerializedAssetInsightsTableSettings) =>
			deserializeAssetInsightsTableSettings(serializedSetting, metadata, (setting) =>
				config ? filterValidAdvancedFiltersSettings(setting, config) : identity(setting)
			)
	);
