import { EComponentSize, KvSearch } from '@kelvininc/react-ui-components';
import { convertToValidSearch } from '@kelvininc/tsutils';
import { debounce } from 'lodash-es';

import { useMemo } from 'react';

import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS } from '../../../../../../../config';
import { IBaseTData } from '../../../../../../../core/components/AgGridTable/types';
import { DEFAULT_SEARCH_PLACEHOLDER } from '../../../../../../../core/components/Table/config';
import { useSearchTerm } from '../../../../../../../core/components/Table/contexts/TableParamsContext';
import { useIsStateEmptyOrError } from '../../../../../../../core/components/Table/contexts/TableStateContext';
import { TableProps } from '../../../../../../../core/components/Table/types';

export type SearchManagerProps<TData extends IBaseTData> = Pick<
	TableProps<TData>,
	'searchPlaceholder' | 'searchDebounce' | 'onSearchChange'
>;

export const SearchManager = <TData extends IBaseTData>({
	searchPlaceholder = DEFAULT_SEARCH_PLACEHOLDER,
	searchDebounce = DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS,
	onSearchChange
}: SearchManagerProps<TData>) => {
	const [searchTerm, setSearch] = useSearchTerm();
	const isSearchDisabled = useIsStateEmptyOrError();

	const onChangeSearchTerm = useMemo(
		() =>
			debounce(({ detail: term }: CustomEvent<string>) => {
				const validSearch = convertToValidSearch(term);
				setSearch(validSearch);
				onSearchChange?.(validSearch);
			}, searchDebounce),
		[onSearchChange, searchDebounce, setSearch]
	);

	return (
		<KvSearch
			value={searchTerm}
			placeholder={searchPlaceholder}
			onTextChange={onChangeSearchTerm}
			inputDisabled={isSearchDisabled}
			size={EComponentSize.Small}
		/>
	);
};
