import { Guardrail } from '@kelvininc/node-client-sdk';
import { withSuspense } from '@kelvininc/shared-ui';
import { ESelectionType, ITableApi, Table } from '@kelvininc/table';

import { useCallback, useRef } from 'react';

import { DEFAULT_COLUMN_DEF, TABLE_ID, TABLE_NAME } from './config';
import {
	useActions,
	useColumnDefs,
	useDatasource,
	useOverlaysConfig,
	useSelectionActions,
	useSettings
} from './hooks';
import { useExecuteGuardrailOperation } from './hooks/useExecuteOperation/useExecuteOperation';
import { useFilters } from './hooks/useFilters/useFilters';
import { getRowId } from './utils';

const Component = () => {
	const tableRef = useRef<ITableApi<Guardrail>>(null);
	const refreshDatasource = useCallback(() => tableRef.current?.refreshDatasource(), []);
	const { execute: executeOperation } = useExecuteGuardrailOperation(refreshDatasource);
	const columnDefs = useColumnDefs(executeOperation);
	const actions = useActions(executeOperation);
	const selectionActions = useSelectionActions(executeOperation);
	const datasource = useDatasource();
	const filters = useFilters();
	const overlays = useOverlaysConfig(executeOperation);
	const { settings, callbacks } = useSettings();

	return (
		<Table
			id={TABLE_ID}
			ref={tableRef}
			title={TABLE_NAME}
			columnDefs={columnDefs}
			defaultColumnDef={DEFAULT_COLUMN_DEF}
			datasource={datasource}
			getRowId={getRowId}
			actions={actions}
			selectionType={ESelectionType.Multiple}
			selectionActions={selectionActions}
			filtersSchema={filters}
			overlaysConfig={overlays}
			sortable
			filterable
			manageable
			searchable
			densityPickable
			checkboxSelectable
			pagination
			{...settings}
			{...callbacks}
		/>
	);
};

export const GuardrailsTable = withSuspense(Component);
