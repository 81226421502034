import { useMatch } from '@kelvininc/nextjs-utils/client';
import { EIconName } from '@kelvininc/react-ui-components';
import { IExpandableMenuItem } from '@kelvininc/shared-ui';
import { useMemo } from 'react';

import { buildSmartAppsCatalogRoute } from '@/src/utils/routes/smart-apps-routes';

export const useCatalogItem = (): IExpandableMenuItem => {
	const smartAppsCatalogURL = buildSmartAppsCatalogRoute();
	const matchSmartAppsCatalog = useMatch(`${smartAppsCatalogURL}/*`);

	return useMemo(
		() => ({
			id: 'smart-apps-catalog',
			text: 'SmartApps Catalog™',
			active: !!matchSmartAppsCatalog,
			url: smartAppsCatalogURL,
			leftIcon: EIconName.Catalog
		}),
		[matchSmartAppsCatalog, smartAppsCatalogURL]
	);
};
