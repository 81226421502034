import {
	EPaginatorType,
	OrchestrationCluster,
	OrchestrationClustersCreateItem,
	OrchestrationService
} from '@kelvininc/node-client-sdk';

import { catchHttpError, throwHttpError } from '@kelvininc/tsutils';

export const getClusterRequester = (id: string): Promise<OrchestrationCluster> => {
	return OrchestrationService.getOrchestrationClusters({ clusterName: id })
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};

export const getClustersRequester = (): Promise<OrchestrationClustersCreateItem[]> => {
	return OrchestrationService.listOrchestrationClusters({
		paginationType: EPaginatorType.Stream
	})
		.pipe(catchHttpError(throwHttpError))
		.toPromise();
};
