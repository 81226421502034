import { isEqual, isFunction } from 'lodash-es';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export const useEqualState = <S>(
	initialState: S | (() => S),
	comparator: (valueA: S, valueB: S) => boolean = isEqual
): [S, Dispatch<SetStateAction<S>>] => {
	const [value, setValue] = useState<S>(initialState);

	const setEqualValue: typeof setValue = useCallback(
		(newState) => {
			setValue((previousValue) => {
				const newValue = isFunction(newState) ? newState(previousValue) : newState;

				if (comparator(previousValue, newValue)) {
					return previousValue;
				}

				return newValue;
			});
		},
		[comparator]
	);

	return [value, setEqualValue];
};
