import { IRowNode } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useEqualState } from '@kelvininc/shared-ui';
import { keyBy, size } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { flushSync } from 'react-dom';

import { IBaseTData } from '../../components/AgGridTable';
import { executeGridAPICall } from '../../components/AgGridTable/utils';

export interface ISelectionApi<TData extends IBaseTData> {
	selectedRows: Record<string, TData>;
	setSelected: (newState: boolean, rowNode: IRowNode<TData>) => void;
	setSelectedBulk: (rows: TData[]) => void;
	isSelected: (rowNode: IRowNode<TData>) => boolean;
	isSelectable: (rowNode: IRowNode<TData>) => boolean;
	selectAll: () => void;
	deselectAll: () => void;
}

export const useSelectionApi = <TData extends IBaseTData>({
	getRowId,
	initialSelectedRows = [],
	gridApi,
	maxSelectedRows,
	isRowDisabled
}: {
	getRowId: (data: TData) => string;
	initialSelectedRows: TData[];
	gridApi: AgGridReact<TData> | null;
	maxSelectedRows?: number;
	isRowDisabled?: (data: TData, selectedRows: TData[]) => boolean;
}): ISelectionApi<TData> => {
	const [selectedRows, setSelectedRows] = useEqualState<Record<string, TData>>(
		keyBy(initialSelectedRows, getRowId)
	);

	const setSelected = useCallback(
		(newState: boolean, rowNode: IRowNode<TData>) => {
			if (!rowNode.data || rowNode.stub || !rowNode.selectable || !gridApi?.api) {
				return;
			}

			const { data } = rowNode;
			const id = getRowId(data);
			flushSync(() => {
				setSelectedRows((previousData) => {
					const newData = { ...previousData };

					if (newState) {
						newData[id] = data;
					} else {
						delete newData[id];
					}

					return newData;
				});
			});

			executeGridAPICall(gridApi.api, (api) => {
				api.refreshHeader();
				api.refreshCells({ force: true });
			});
		},
		[getRowId, gridApi?.api, setSelectedRows]
	);

	const isSelected = useCallback(
		(rowNode: IRowNode<TData>) => {
			if (!rowNode.data || rowNode.stub) {
				return false;
			}

			const { data } = rowNode;
			const id = getRowId(data);

			return selectedRows[id] !== undefined;
		},
		[getRowId, selectedRows]
	);

	const isSelectable = useCallback(
		(rowNode: IRowNode<TData>) => {
			const { data } = rowNode;
			const selectedRowsList = Object.values(selectedRows);
			if (maxSelectedRows) {
				if (selectedRowsList.length >= maxSelectedRows && !isSelected(rowNode)) {
					return false;
				}
			}

			if (isRowDisabled) {
				return !isRowDisabled(data as TData, selectedRowsList);
			}

			return true;
		},
		[isRowDisabled, isSelected, maxSelectedRows, selectedRows]
	);

	const selectAll = useCallback(() => {
		if (!gridApi) {
			return;
		}

		const nodes: Record<string, TData> = {};
		gridApi.api.forEachNode((rowNode) => {
			if (maxSelectedRows && size(nodes) >= maxSelectedRows) {
				return;
			}

			if (!rowNode.data || rowNode.stub || !rowNode.selectable) {
				return;
			}

			const { data } = rowNode;
			const id = getRowId(data);

			nodes[id] = data;
		});

		flushSync(() => {
			setSelectedRows((previousData) => ({ ...previousData, ...nodes }));
		});

		executeGridAPICall(gridApi.api, (api) => {
			api.refreshHeader();
			api.refreshCells({ force: true });
		});
	}, [getRowId, gridApi, maxSelectedRows, setSelectedRows]);

	const deselectAll = useCallback(() => {
		if (!gridApi?.api) return;

		flushSync(() => {
			setSelectedRows({});
		});

		executeGridAPICall(gridApi.api, (api) => {
			api.refreshHeader();
			api.refreshCells({ force: true });
		});
	}, [gridApi?.api, setSelectedRows]);

	const setSelectedBulk = useCallback(
		(rows: TData[]) => {
			if (!gridApi?.api) return;

			flushSync(() => {
				setSelectedRows(keyBy(rows, getRowId));
			});

			executeGridAPICall(gridApi.api, (api) => {
				api.refreshHeader();
				api.refreshCells({ force: true });
			});
		},
		[gridApi?.api, getRowId, setSelectedRows]
	);

	return useMemo<ISelectionApi<TData>>(
		() => ({
			selectedRows,
			setSelected,
			setSelectedBulk,
			isSelected,
			isSelectable,
			selectAll,
			deselectAll
		}),
		[
			deselectAll,
			isSelectable,
			isSelected,
			selectAll,
			selectedRows,
			setSelected,
			setSelectedBulk
		]
	);
};
