import { DataStream, EKrnResource, KvKRNParser } from '@kelvininc/node-client-sdk';

import { assetNameFromResource } from '@kelvininc/tsutils';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { assetGuardrailsAtomFamily } from '../../recoil/atoms';

import { assetDatastreamContextsListAtomFamily } from '@/src/recoil/data-streams/atoms';
import { datastreamsMapSelector } from '@/src/recoil/data-streams/selectors';

export const useAssetNonGuardrailedDatastreams = (assetName: string): DataStream[] => {
	const contexts = useRecoilValue(assetDatastreamContextsListAtomFamily(assetName));
	const datastreamsMap = useRecoilValue(datastreamsMapSelector);
	const guardrails = useRecoilValue(assetGuardrailsAtomFamily(assetName));

	return useMemo(() => {
		return contexts.reduce<DataStream[]>((accumulator, { datastreamName, context }) => {
			const isWritable = context.some(({ resource, writable = false }) => {
				const contextAsset = assetNameFromResource(resource);

				const guardrailExist = guardrails.some((guardrail) => {
					const {
						resourceContent: { datastream: guardrailDatastream }
					} = KvKRNParser.parseKRN<EKrnResource.AssetDatastream>(
						guardrail.resource,
						EKrnResource.AssetDatastream
					);

					return guardrailDatastream === datastreamName;
				});

				return assetName === contextAsset && writable && !guardrailExist;
			});

			if (isWritable) {
				accumulator.push(datastreamsMap[datastreamName]);
			}

			return accumulator;
		}, []);
	}, [assetName, contexts, datastreamsMap, guardrails]);
};
