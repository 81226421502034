import { isNumber, isString } from '@kelvininc/tsutils';
import { RefinementCtx, z } from 'zod';

import { transformStringToNumber } from '../utils';

export const transformValue = (value: string | number, context: RefinementCtx): number => {
	try {
		if (isString(value)) {
			transformStringToNumber(value);
		}

		if (isNumber(value)) {
			return value;
		}

		throw Error('Invalid value');
	} catch (error) {
		context.addIssue({
			code: z.ZodIssueCode.custom,
			message: 'Value must be a number'
		});

		return z.NEVER;
	}
};

export const isPercentageValueValid = (value: number | undefined): boolean => {
	if (value === undefined) {
		return true;
	}

	return value >= 0 && value <= 100;
};
