import { ClassNamesProp, FunctionComponent } from '@kelvininc/types';
import classNames from 'classnames';
import { PropsWithChildren, useEffect } from 'react';

import { IBaseTData, ICellRendererParams } from '../../../core/components/AgGridTable/types';

import { ISelectionApi } from '../../../core/hooks';

import { CellClickContainer } from './components/CellClickContainer';
import { CellCopyClipboard } from './components/CellCopyClipboard';
import { CellStateContainer } from './components/CellStateContainer';
import { CellTooltipContainer } from './components/CellTooltipContainer';

export type BaseCellRendererProps = {
	customClasses?: ClassNamesProp;
};

export const BaseCellRenderer = <TData extends IBaseTData>(
	props: PropsWithChildren<ICellRendererParams<TData> & BaseCellRendererProps>
) => {
	const { children, customClasses, eGridCell, context, node } = props;
	const selectionApi = context.selectionApi as ISelectionApi<TData>;
	const isSelected = node ? selectionApi.isSelected(node) : false;

	useEffect(() => {
		const classes = classNames(customClasses);

		if (eGridCell) {
			if (classes) {
				classes.split(' ').forEach((classe) => eGridCell.classList.add(classe));
			}

			if (isSelected) {
				eGridCell.parentElement?.classList.add('ag-row-selected');
			} else {
				eGridCell.parentElement?.classList.remove('ag-row-selected');
			}
		}
	}, [customClasses, eGridCell, isSelected]);

	return (
		<CellStateContainer {...props}>
			<CellTooltipContainer {...props}>
				<CellClickContainer {...props}>
					<CellCopyClipboard {...props}>{children}</CellCopyClipboard>
				</CellClickContainer>
			</CellTooltipContainer>
		</CellStateContainer>
	);
};

export const withBaseCellRenderer = <ComponentProps, TData extends IBaseTData>(
	Component: FunctionComponent<ComponentProps>,
	props?: ICellRendererParams<TData> & BaseCellRendererProps
) => {
	return function BaseCellRendererWrapper(componentProps: PropsWithChildren<ComponentProps>) {
		return (
			<BaseCellRenderer {...props} {...componentProps}>
				<Component {...componentProps} />
			</BaseCellRenderer>
		);
	};
};
