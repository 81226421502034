import { ComputePositionConfig, flip, offset } from '@floating-ui/dom';
import { ISelectOption } from '@kelvininc/react-ui-components';
import { UseDropdownOptions } from '@kelvininc/types';

export const OPTIONS: Partial<ComputePositionConfig> = {
	placement: 'bottom-start',
	middleware: [
		offset(0),
		flip({
			fallbackPlacements: ['bottom-end', 'top-end', 'top-start']
		})
	]
};

export const USE_DROPDOWN_OPTIONS: UseDropdownOptions<ISelectOption> = {
	key: 'value',
	label: 'label',
	value: 'value'
};
