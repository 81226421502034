import { useEqualState, useUpdate } from '@kelvininc/shared-ui';
import {
	Context,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useState
} from 'react';

import {
	IBaseTData,
	IPaginationInfo,
	IPaginationTotalInfo,
	ISortInfo,
	ITableAdvancedFilters,
	ITableFilters
} from '../../../AgGridTable';
import { DEFAULT_PAGE_SIZE } from '../../config';
import { TableProps } from '../../types';

import { DEFAULT_INITIAL_PAGE } from './config';

import { ITableParamsContextValues } from './types';

const TableParamsContext: Context<null | ITableParamsContextValues> =
	createContext<null | ITableParamsContextValues>(null);

export type TableParamsProviderProps<TData extends IBaseTData> = Pick<
	TableProps<TData>,
	| 'filtersInitial'
	| 'advancedFiltersInitial'
	| 'searchInitial'
	| 'pageInitial'
	| 'pageSizeInitial'
	| 'sortByInitial'
	| 'sortDirectionInitial'
	| 'onFiltersChange'
	| 'onAdvancedFiltersChange'
	| 'onPaginationChange'
	| 'onPaginationTotalChange'
	| 'onSortChange'
>;

export const TableParamsProvider = <TData extends IBaseTData>({
	children,
	advancedFiltersInitial,
	filtersInitial,
	searchInitial,
	pageInitial = DEFAULT_INITIAL_PAGE,
	pageSizeInitial = DEFAULT_PAGE_SIZE,
	sortByInitial,
	sortDirectionInitial,
	onFiltersChange: onFiltersChangeCallback,
	onAdvancedFiltersChange: onAdvancedFiltersChangeCallback,
	onPaginationChange: onPaginationChangeCallback,
	onPaginationTotalChange: onPaginationTotalChangeCallback,
	onSortChange: onSortChangeCallback
}: PropsWithChildren<TableParamsProviderProps<TData>>) => {
	const [filters, setFilters] = useEqualState<ITableFilters | undefined>(filtersInitial);
	const [advancedFilters, setAdvancedFilters] = useEqualState<ITableAdvancedFilters | undefined>(
		advancedFiltersInitial
	);
	const [paginationInfo, setPaginationInfo] = useEqualState<IPaginationInfo | undefined>({
		page: pageInitial,
		pageSize: pageSizeInitial
	});
	const [paginationTotalInfo, setPaginationTotalInfo] = useEqualState<
		IPaginationTotalInfo | undefined
	>(undefined);
	const [sortInfo, setSortInfo] = useEqualState<ISortInfo | undefined>({
		sortBy: sortByInitial,
		sortDirection: sortDirectionInitial
	});
	const [searchTerm, setSearchTerm] = useState(searchInitial);

	useUpdate(() => {
		onPaginationChangeCallback?.(paginationInfo);
	}, [paginationInfo]);
	useUpdate(() => {
		onPaginationTotalChangeCallback?.(paginationTotalInfo);
	}, [paginationTotalInfo]);
	useUpdate(() => {
		onSortChangeCallback?.(sortInfo);
	}, [sortInfo]);
	useUpdate(() => {
		onFiltersChangeCallback?.(filters);
	}, [filters]);
	useUpdate(() => {
		onAdvancedFiltersChangeCallback?.(advancedFilters);
	}, [advancedFilters]);

	return (
		<TableParamsContext.Provider
			value={{
				filtersState: [filters, setFilters],
				advancedFiltersState: [advancedFilters, setAdvancedFilters],
				paginationInfoState: [paginationInfo, setPaginationInfo],
				paginationTotalInfoState: [paginationTotalInfo, setPaginationTotalInfo],
				sortInfoState: [sortInfo, setSortInfo],
				searchTermState: [searchTerm, setSearchTerm]
			}}>
			{children}
		</TableParamsContext.Provider>
	);
};

export function useParamsContext(): ITableParamsContextValues {
	const context = useContext(TableParamsContext);

	if (!context) {
		throw new Error('Missing table params context');
	}

	return context;
}

export const useFilters = (): [
	ITableFilters | undefined,
	Dispatch<SetStateAction<ITableFilters | undefined>>
] => {
	const {
		filtersState: [stateFilters, setStateFilters],
		paginationInfoState: [, setStatePaginationInfo]
	} = useParamsContext();

	const setFilters = useCallback(
		(newFilters: SetStateAction<ITableFilters | undefined>) => {
			setStateFilters(newFilters);
			setStatePaginationInfo((prevState) => ({
				...prevState,
				page: 1
			}));
		},
		[setStateFilters, setStatePaginationInfo]
	);

	return [stateFilters, setFilters];
};

export const useAdvancedFilters = (): [
	ITableAdvancedFilters | undefined,
	Dispatch<SetStateAction<ITableAdvancedFilters | undefined>>
] => {
	const {
		advancedFiltersState: [stateAdvancedFilters, setStateAdvancedFilters],
		paginationInfoState: [, setStatePaginationInfo]
	} = useParamsContext();

	const setAdvancedFilters = useCallback(
		(newAdvancedFilters: SetStateAction<ITableAdvancedFilters | undefined>) => {
			setStateAdvancedFilters(newAdvancedFilters);
			setStatePaginationInfo((prevState) => ({
				...prevState,
				page: 1
			}));
		},
		[setStateAdvancedFilters, setStatePaginationInfo]
	);

	return [stateAdvancedFilters, setAdvancedFilters];
};

export const useSearchTerm = (): [
	string | undefined,
	Dispatch<SetStateAction<string | undefined>>
] => {
	const {
		searchTermState: [stateTermSearch, setStateTermSearch],
		paginationInfoState: [, setStatePaginationInfo]
	} = useParamsContext();

	const setSearchTerm = useCallback(
		(newSearch: SetStateAction<string | undefined>) => {
			setStateTermSearch(newSearch);
			setStatePaginationInfo((prevState) => ({
				...prevState,
				page: 1
			}));
		},
		[setStatePaginationInfo, setStateTermSearch]
	);

	return [stateTermSearch, setSearchTerm];
};

export const usePaginationInfo = (): [
	IPaginationInfo | undefined,
	Dispatch<SetStateAction<IPaginationInfo | undefined>>
] => {
	const { paginationInfoState } = useParamsContext();

	return paginationInfoState;
};

export const usePaginationTotalInfo = (): [
	IPaginationTotalInfo | undefined,
	Dispatch<SetStateAction<IPaginationTotalInfo | undefined>>
] => {
	const { paginationTotalInfoState } = useParamsContext();

	return paginationTotalInfoState;
};

export const useSortInfo = (): [
	ISortInfo | undefined,
	Dispatch<SetStateAction<ISortInfo | undefined>>
] => {
	const { sortInfoState } = useParamsContext();

	return sortInfoState;
};
