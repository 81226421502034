'use client';
import { ComputePositionConfig, flip, offset } from '@floating-ui/dom';
export const DEFAULT_ENTITY_DROPDOWN_Z_INDEX = 9004;

export const OPTIONS: Partial<ComputePositionConfig> = {
	placement: 'bottom',
	middleware: [
		offset(0),
		flip({
			fallbackPlacements: ['bottom-end', 'top-end', 'top-start']
		})
	]
};
