'use client';
import { ITabNavigationItem } from '@kelvininc/react-ui-components';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import { Layout } from '..';

import { EntityModelHeader, EntityModelLayoutSkeleton } from './components';

import styles from './styles.module.scss';

type EntityModelLayoutProps = {
	tabs?: ITabNavigationItem[];
	customClasses?: string;
};

export const EntityModelLayout = ({
	children,
	tabs,
	customClasses
}: PropsWithChildren<EntityModelLayoutProps>) => {
	return (
		<Layout
			header={<EntityModelHeader tabs={tabs} />}
			customClasses={classNames(styles.EntityModelLayout, customClasses, {
				[styles.withTabs]: tabs?.length
			})}
			boundariesProps={{
				SuspenseFallbackComponent: (
					<EntityModelLayoutSkeleton customClasses={styles.EntityModelSkeleton} />
				)
			}}>
			{children}
		</Layout>
	);
};
