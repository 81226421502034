import { ISelectSingleOptions } from '@kelvininc/react-ui-components';

export const buildPageSizeOptions = (options: number[]): ISelectSingleOptions => {
	return options.reduce<ISelectSingleOptions>((accumulator, value) => {
		accumulator[value] = {
			label: value.toString(),
			value: value.toString()
		};

		return accumulator;
	}, {});
};

export const buildShortcutPages = (
	numberOfShortcutPages: number,
	page: number,
	totalPages: number
): number[] => {
	const pages = [];
	const actualNumberOfShortcutPages = Math.min(numberOfShortcutPages, totalPages - 1);
	const startIndex = Math.max(
		1,
		page === totalPages
			? page - actualNumberOfShortcutPages
			: page - actualNumberOfShortcutPages + 1
	);
	const endIndex = Math.min(startIndex + actualNumberOfShortcutPages, totalPages);

	for (let index = startIndex; index <= endIndex; index++) {
		pages.push(index);
	}

	return pages;
};

export const buildItemsRange = (
	page: number,
	pageSize: number,
	totalItems: number
): [number, number] => {
	const startRange = pageSize * page + 1 - pageSize;
	const endRange = startRange + pageSize - 1;

	return [startRange, endRange > totalItems ? totalItems : endRange];
};

export const buildItemsRangeText = (
	itemsRange: [number, number] | undefined,
	totalItems: number | undefined
): string => {
	if (itemsRange === undefined) {
		return '';
	}

	return `Showing ${itemsRange[0]} to ${itemsRange[1]} items of ${totalItems}`;
};

export const getSingleSelectDropdownDisabled = (
	totalItems: number | undefined,
	options: ISelectSingleOptions,
	isDisabled: boolean
) => (totalItems && totalItems <= parseInt(Object.values(options)[0].label) ? true : isDisabled);
