import { isKelvinClosedLoopParameter } from '@kelvininc/tsutils';
import { EClosedLoopMode, IClosedLoopSettings } from '@kelvininc/types';
import { isNil } from 'lodash-es';

import { DEFAULT_CLOSED_LOOP_SETTINGS } from '../../../config';
import { IBaseTData, ICellRendererParams } from '../../../core';
import { TextCellRenderer, TextCellRendererParams } from '../TextCellRenderer';

export type ParameterCellRendererParams<TData extends IBaseTData> = {
	closedLoopSettings?: IClosedLoopSettings;
	parameter?: string;
} & TextCellRendererParams<TData>;

export const ParameterCellRenderer = <TData extends IBaseTData>({
	parameter,
	closedLoopSettings = DEFAULT_CLOSED_LOOP_SETTINGS,
	value,
	...otherProps
}: ICellRendererParams<TData, string> & ParameterCellRendererParams<TData>) => {
	let formattedValue = value;
	if (!isNil(value) && parameter && isKelvinClosedLoopParameter(parameter)) {
		formattedValue = value
			? closedLoopSettings.mapping[EClosedLoopMode.True]
			: closedLoopSettings.mapping[EClosedLoopMode.False];
	}

	return <TextCellRenderer value={formattedValue} {...otherProps} />;
};
