import { DispatchPersistStateAction, ReactState } from '@kelvininc/types';

import { IExperienceGuideStep } from '@/src/components/client';

export enum EApplicationEntity {
	Assets = 'assets',
	Datastreams = 'datastreams',
	Clusters = 'clusters',
	Connections = 'connections'
}

export enum EntityAction {
	AddAssetToApp,
	AddConnection
}

export type IFirstExperienceStepStates = Record<EApplicationEntity, boolean>;

export interface IFirstExperienceContextValues {
	steps: IExperienceGuideStep[];
	stepStates: IFirstExperienceStepStates;
	platformConfigured: boolean;
	firstExperienceSkipped: boolean;
	expanded: ReactState<boolean>;
	setVisibility: (visible: boolean) => void;
	setFirstExperienceSkipped: DispatchPersistStateAction<boolean>;
	onStepClick: (stepName: string) => void;
	checkEntityAction: (action: EntityAction) => boolean;
	onEntityAdded: () => void;
	setForceHidden: (hidden: boolean) => void;
}
