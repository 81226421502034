import {
	EIconName,
	ETagState,
	ETooltipPosition,
	KvTagStatus,
	KvTooltip
} from '@kelvininc/react-ui-components';
import classNames from 'classnames';
import { PropsWithChildren, useMemo } from 'react';

import {
	IBaseTData,
	ICellRendererParams,
	IDefaultTValue
} from '../../../../../core/components/AgGridTable/types';

import styles from './styles.module.scss';

export const CellStateContainer = <TData extends IBaseTData, TValue = IDefaultTValue>(
	props: PropsWithChildren<ICellRendererParams<TData, TValue>>
) => {
	const { value, data, children, cellState } = props;
	const state = useMemo(
		() => cellState?.(value, data) ?? { valid: true },
		[cellState, data, value]
	);

	if (!state.valid) {
		return (
			<div className={classNames('ag-cell-error', styles.CellStateContainer)}>
				<KvTooltip text={state.message} position={ETooltipPosition.Top}>
					<KvTagStatus state={ETagState.Error} icon={EIconName.Error} />
				</KvTooltip>
				<div className={styles.Content}>{children}</div>
			</div>
		);
	}

	return <>{children}</>;
};
