'use client';
import { AppItem, EAppType } from '@kelvininc/node-client-sdk';
import { closeAlertInModal } from '@kelvininc/shared-ui';
import { useEffect } from 'react';

import { handleAppTypeAlert } from '../utils';

export const useAppTypeModalAlert = (
	applications: Record<string, AppItem>,
	currentStep: number,
	editMode: boolean,
	appName?: string
) => {
	useEffect(() => {
		if (!appName) return;

		if (
			[EAppType.Kelvin, EAppType.Bridge].includes(applications[appName]?.type) &&
			currentStep === 0 &&
			!editMode
		) {
			handleAppTypeAlert(appName, applications[appName]);
		} else {
			closeAlertInModal();
		}
	}, [appName, applications, currentStep, editMode]);
};
