'use client';
import { EIconName, EOtherIconName, ETagState } from '@kelvininc/react-ui-components';
import { Link } from '@kelvininc/shared-ui';
import { capitalizeFirstLetter } from '@kelvininc/tsutils';
import { RJSFValidationError } from '@rjsf/utils';
import pluralize from 'pluralize';

import { EMPTY_CSV_FILE_MESSAGE, SCHEMA_ROW_KEY, VALID_FILE_MESSAGE } from './config';
import { IFileMappingError } from './FileErrorsList/types';

import styles from './styles.module.scss';

export const getErrorsListDescriptionMessage = (numberOfErrors: number) => {
	return `${numberOfErrors} ${pluralize('errors', numberOfErrors)} ${pluralize(
		'was',
		numberOfErrors
	)} found. Please fix ${
		numberOfErrors > 1 ? 'them' : 'it'
	} and upload the file again on the previous step.`;
};

export const getRequirementListErrorMessage = (
	validCsv: boolean,
	csvHasContent: boolean,
	documentationLink: string
): string | React.ReactNode => {
	if (validCsv) {
		if (!csvHasContent) {
			return EMPTY_CSV_FILE_MESSAGE;
		}

		return VALID_FILE_MESSAGE;
	}
	return buildInvalidFileMessage(documentationLink);
};

const buildInvalidFileMessage = (documentationLink: string) => {
	return (
		<span>
			Some errors were found. Please re-upload the .csv file with the fixed errors to be able
			to proceed. For more information, please consult the{' '}
			<Link className={styles.DocumentationLink} href={documentationLink} target="_blank">
				documentation.
			</Link>
		</span>
	);
};

export const getSchemaFormErrorPropertyFields = (property: string): [string, string] => {
	const splittedProperty = property.split('.');

	if (splittedProperty.length > 3) {
		const pathArray = splittedProperty.slice(2);

		return [splittedProperty[1], pathArray.join('.')];
	}

	return [splittedProperty[1], splittedProperty[2]];
};

export const getKvTagStatusProps = (
	isValid: boolean
): { icon: EIconName | EOtherIconName; state: ETagState } => {
	if (isValid)
		return {
			state: ETagState.Success,
			icon: EIconName.Success
		};

	return {
		state: ETagState.Error,
		icon: EIconName.Error
	};
};

export const buildFileErrorsListTableData = <T extends {}>(
	csvData: T[],
	validatedErrors: RJSFValidationError[]
): Record<string, IFileMappingError> => {
	return validatedErrors.reduce<Record<string, IFileMappingError>>((acc, item) => {
		if (item.property) {
			const [rowId, columnId] = getSchemaFormErrorPropertyFields(item.property);
			const csvFileRowId = Number.parseInt(rowId) + 2;
			const oldMessage = acc[rowId]?.errors[columnId];

			acc[rowId] = {
				item: {
					content: csvData[Number.parseInt(rowId)],
					row: csvFileRowId.toString()
				},
				errors: {
					...acc[rowId]?.errors,
					[columnId ?? SCHEMA_ROW_KEY]: `• ${capitalizeFirstLetter(item.message)}\n${
						oldMessage ?? ''
					}`
				}
			};
		}

		return acc;
	}, {});
};
