import { useEffect, useState } from 'react';

export interface IUseEnableExperience {
	firstExperienceSkipped: boolean;
	hasAdminPermissions: boolean;
	hasRequestErrors: boolean;
	isLoading: boolean;
	platformConfigured: boolean;
	forceHidden?: boolean;
}

export const useEnableExperience = ({
	firstExperienceSkipped,
	hasAdminPermissions,
	hasRequestErrors,
	isLoading,
	platformConfigured,
	forceHidden = false
}: IUseEnableExperience) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		if (isLoading || hasRequestErrors) return;

		setIsVisible(
			!forceHidden && hasAdminPermissions && !platformConfigured && !firstExperienceSkipped
		);
	}, [
		firstExperienceSkipped,
		forceHidden,
		hasAdminPermissions,
		hasRequestErrors,
		isLoading,
		platformConfigured
	]);

	return { isVisible, setIsVisible };
};
