import { CopyToClipboard } from '@kelvininc/shared-ui';
import { PropsWithChildren } from 'react';

import { IBaseTData, ICellRendererParams } from '../../../../../core/components/AgGridTable';
import { TextCellRendererParams } from '../../../TextCellRenderer';

export const CellCopyClipboard = <TData extends IBaseTData>({
	children,
	value,
	hasCopyClipboard = false,
	copyClipboardSuffix
}: PropsWithChildren<ICellRendererParams<TData> & TextCellRendererParams<TData>>) => {
	return (
		<CopyToClipboard
			enabled={hasCopyClipboard}
			text={value}
			tooltipSuffix={copyClipboardSuffix}>
			{children}
		</CopyToClipboard>
	);
};
