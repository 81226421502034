import {
	EActionButtonType,
	EComponentSize,
	ETooltipPosition,
	KvActionButtonIcon,
	KvActionButtonText
} from '@kelvininc/react-ui-components';

import { Link } from '@kelvininc/shared-ui';

import { ITableAction } from '../AgGridTable/types';
import { TableTooltip } from '../TableTooltip';

type ButtonProps = Pick<ITableAction, 'text' | 'type' | 'icon' | 'disabled' | 'active'>;

const Button = ({
	text,
	type = EActionButtonType.Primary,
	icon,
	disabled,
	active
}: ButtonProps) => {
	if (text !== undefined) {
		return (
			<KvActionButtonText
				type={type}
				text={text}
				icon={icon}
				size={EComponentSize.Small}
				disabled={disabled}
				active={active}
			/>
		);
	}

	if (icon !== undefined) {
		return (
			<KvActionButtonIcon
				type={type}
				icon={icon}
				size={EComponentSize.Small}
				disabled={disabled}
				active={active}
			/>
		);
	}

	return null;
};

export const ActionButton = (props: ITableAction) => {
	const {
		id,
		text,
		type = EActionButtonType.Primary,
		icon,
		disabled,
		active,
		href,
		tooltip,
		tooltipPosition = ETooltipPosition.BottomStart,
		prefetch = false,
		...otherProps
	} = props;

	if (text === undefined && icon === undefined) {
		return null;
	}

	return (
		<TableTooltip key={id} text={tooltip} position={tooltipPosition}>
			<Link disabled={disabled} href={href} prefetch={prefetch} {...otherProps}>
				<Button type={type} text={text} icon={icon} disabled={disabled} active={active} />
			</Link>
		</TableTooltip>
	);
};
