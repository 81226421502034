import {
	EActionButtonType,
	EIconName,
	EOtherIconName,
	ETooltipPosition,
	SchemaFormProps
} from '@kelvininc/react-ui-components';
import { ClassNamesProp, ESortDirection } from '@kelvininc/types';

import { Dispatch, HTMLAttributeAnchorTarget, SetStateAction } from 'react';

import { EDataState, OverlaysComponents, OverlaysConfig } from '../Overlays';

export interface IBaseLData {
	title?: string;
}

export enum EManagementPanel {
	Manage = 'manage'
}

export interface IListAction {
	id: string;
	type?: EActionButtonType;
	text?: string;
	icon?: EIconName | EOtherIconName;
	disabled?: boolean;
	active?: boolean;
	onClick?: () => void;
	href?: string;
	prefetch?: boolean;
	download?: string;
	target?: HTMLAttributeAnchorTarget;
	tooltip?: string;
	tooltipPosition?: ETooltipPosition;
}

export type ListDatasource<LData extends IBaseLData, Plugins = {}> = (
	params: IListDatasourceParams<LData> & Plugins
) => Promise<IListDatasourceResult<LData>>;

export interface IListDatasourceParams<LData extends IBaseLData> {
	sortBy?: (keyof LData)[];
	filters?: IListFilters;
	sortDirection?: ESortDirection;
	search?: string[];
}
export interface IAbortableListDatasourcePlugin {
	signal?: AbortSignal;
}
export interface IListDatasourceResult<LData extends IBaseLData> {
	data: LData[];
}

type BaseListProps<LData extends IBaseLData> = {
	id?: string;
	title?: JSX.Element | string;
	listItemCounter?: number;
	subtitle?: JSX.Element;
	actions?: IListAction[];
	data?: LData[];
	virtualized?: boolean;
	managementPanelData?: LData[];
	entityName?: string;
	datasource?: ListDatasource<LData>;
	getRowKey: (data: LData) => string;
	searchRow?: (term: string, rowKey: string, rowData: LData) => boolean;
	rowRenderer: (data: LData) => JSX.Element;
	customClasses?: ClassNamesProp;
	overlaysConfig?: Partial<OverlaysConfig>;
	overlaysComponents?: Partial<OverlaysComponents>;
};

type SearchListProps = {
	searchable?: boolean;
	searchInitial?: string;
	searchPlaceholder?: string;
	searchDebounce?: number;
	onSearchChange?: (newSearch: string | undefined) => void;
};

export interface ISortInfo<LData extends IBaseLData> {
	sortBy?: keyof LData;
	sortDirection?: ESortDirection;
}

type SortListProps<LData extends IBaseLData> = {
	sortable?: boolean;
	sortByInitial?: keyof LData;
	sortDirectionInitial?: ESortDirection;
	sortConfig?: Record<string, ISortInfo<LData>>;
	onSortChange?: (newSort: ISortInfo<LData> | undefined) => void;
};

export type ListFilterValue = string | number | boolean | string[] | number[] | boolean[];

export type IListFilters = Record<string, ListFilterValue>;
export type IListFiltersSchema = {
	schema: SchemaFormProps<IListFilters>['schema'];
	uiSchema: SchemaFormProps<IListFilters>['uiSchema'];
};

type FilterListProps = {
	filterable?: boolean;
	filtersDisabled?: boolean;
	filtersInitial?: IListFilters;
	filtersSchema?: IListFiltersSchema;
	filters?: IListFilters;
	onFiltersChange?: (newFilters: IListFilters | undefined) => void;
};

export interface IPanelConfig {
	title?: string;
	description?: string;
	tooltip?: string;
}

export type IPanelsConfig = Partial<Record<EManagementPanel, IPanelConfig>>;

type ManagementListProps<LData extends IBaseLData = IBaseLData> = {
	manageable?: boolean;
	panelsConfig?: IPanelsConfig;
	rowsHiddenInitial?: string[];
	onRowsHiddenChange?: (newSettings: string[]) => void;
	onResetSettings?: () => Promise<IListSettings<LData>>;
};

export type ListProps<LData extends IBaseLData> = BaseListProps<LData> &
	SearchListProps &
	FilterListProps &
	SortListProps<LData> &
	ManagementListProps<LData>;

export interface IListApi {
	refreshDatasource: () => void;
}

export enum EListSettingKey {
	SortBy = 'sortByInitial',
	SortDirection = 'sortDirectionInitial',
	RowsHidden = 'rowsHiddenInitial'
}

export interface IListSettings<LData extends IBaseLData = IBaseLData> {
	[EListSettingKey.SortBy]?: keyof LData;
	[EListSettingKey.SortDirection]?: ESortDirection;
	[EListSettingKey.RowsHidden]?: string[];
}

export interface IListContext<LData extends IBaseLData> {
	searchTerm?: string;
	sortBy?: keyof LData;
	sortDirection?: ESortDirection;
	rowsHidden?: string[];
	filters?: IListFilters;
	state: EDataState;
	onStateChange: Dispatch<SetStateAction<EDataState>>;
	onListDataChange: Dispatch<SetStateAction<LData[]>>;
	getRowKey: (data: LData) => string;
}
