import { ExpandableMenu, useNavigationSideBar, withExpandableProvider } from '@kelvininc/shared-ui';

import { navigationSidebarExpandedOperationsUserSettingAtom } from '../../atom';
import {
	NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_KEY
} from '../../config';

import { OPERATIONS_MENU_TITLE } from './config';

import { useOperationsItems } from './utils';

const Component = () => {
	const { isCollapsed } = useNavigationSideBar();
	const items = useOperationsItems();

	return <ExpandableMenu title={OPERATIONS_MENU_TITLE} items={items} collapsed={isCollapsed} />;
};

export const OperationsMenu = withExpandableProvider(
	Component,
	NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_DEFAULT,
	NAVIGATION_SIDEBAR_EXPANDED_OPERATIONS_USER_SETTING_KEY,
	navigationSidebarExpandedOperationsUserSettingAtom
);
